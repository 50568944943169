import { GET_CONFIGS } from '../actions/types';

const initialState = {
  configs: {
    name: 'Portal Executivo',
    email: 'email@email.pt',
    phone: '+351 912 345 678',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CONFIGS:
      return {
        ...state,
        configs: action.payload,
      };
    default:
      return state;
  }
};
