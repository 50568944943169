import {
  GET_PROPOSALS,
  GET_PROPOSAL,
  UPDATE_PROPOSAL,
  PROPOSALS_LOADING,
  PROPOSALS_NOT_LOADING,
  GET_PROPOSAL_VERSIONS,
  GET_PROPOSAL_MEETINGS,
  GET_PROPOSAL_DECISIONS,
  GET_PROPOSAL_FLOW_CHANGES,
  GET_PROPOSAL_STATUSES,
  CREATE_PROPOSAL,
  REMOVE_PROPOSAL,
  UPDATE_PROPOSAL_MEETING_DELIBERATION_NUMBER,
  UPDATE_PROPOSAL_MEETING_DELIBERATION_FILE_NAME,
} from '../actions/types';

const initialState = {
  proposals: [],
  versions: [],
  meetings: [],
  changes: [],
  statuses: [],
  proposal: {},
  decisions: {},
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PROPOSALS:
      return {
        ...state,
        proposals: action.payload,
        loading: false,
      };
    case GET_PROPOSAL:
      return {
        ...state,
        proposal: action.payload,
        loading: false,
      };
    case GET_PROPOSAL_VERSIONS:
      return {
        ...state,
        versions: action.payload,
        loading: false,
      };
    case GET_PROPOSAL_MEETINGS:
      return {
        ...state,
        meetings: action.payload,
        loading: false,
      };
    case GET_PROPOSAL_STATUSES:
      return {
        ...state,
        statuses: action.payload,
        loading: false,
      };
    case GET_PROPOSAL_FLOW_CHANGES:
      return {
        ...state,
        proposal: {
          ...state.proposal,
          changedFlow: action.payload,
        },
        loading: false,
      };
    case GET_PROPOSAL_DECISIONS:
      return {
        ...state,
        proposal: {
          ...state.proposal,
          ...action.payload,
        },
        decisions: {
          ...state.decisions,
          ...action.payload,
        },
        loading: false,
      };
    case CREATE_PROPOSAL:
      return {
        ...state,
        proposals: [action.payload, ...state.proposals],
        loading: false,
      };
    case UPDATE_PROPOSAL:
      return {
        ...state,
        proposal: {
          ...state.proposal,
          ...action.payload,
        },
        // proposals: state.proposals.map(x => (x.id === action.payload.id ? action.payload : x)),
        loading: false,
      };
    case REMOVE_PROPOSAL:
      return {
        ...state,
        proposals: state.proposals.filter(x => x.id !== action.payload.id),
        loading: false,
      };
    case PROPOSALS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PROPOSALS_NOT_LOADING:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_PROPOSAL_MEETING_DELIBERATION_NUMBER: {
      const updatedMeetings = state.meetings.map(meeting => {
        if (meeting.id === action.payload.id) {
          return { ...meeting, deliberation_number: action.payload.deliberationNumber };
        }

        return meeting;
      });

      return {
        ...state,
        meetings: updatedMeetings,
      };
    }
    case UPDATE_PROPOSAL_MEETING_DELIBERATION_FILE_NAME: {
      const updatedMeetings = state.meetings.map(meeting => {
        if (meeting.id === action.payload.id) {
          const meetingPointStatus = meeting.meeting_point_status || {};

          return {
            ...meeting,
            meeting_point_status: {
              ...meetingPointStatus,
              file_name: action.payload.fileName,
            },
          };
        }

        return meeting;
      });

      return {
        ...state,
        meetings: updatedMeetings,
      };
    }
    default:
      return state;
  }
};
