import React, { useMemo } from 'react';
import { makeStyles, Box, Button } from '@material-ui/core';
import { AddCircleOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.neutrals[4],
    marginLeft: '10px',
    width: '16px',
    height: '16px',
  },
  buttonText: {
    color: theme.palette.neutrals[5],
  },
  root: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      '& span': {
        transition: 'color 0.2s ease',
        color: theme.palette.neutrals[6],
      },
      '& svg': {
        transition: 'color 0.2s ease',
        color: theme.palette.neutrals[5],
      },
    },
  },
  input: {
    display: 'none',
  },
  box: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.neutrals[1],
  },
  padding: {
    padding: '13px',
  },
  start: {
    justifyContent: 'flex-start',
  },
}));

const BoxUpload = ({ filesRef, handleUpload, padding, labelBox, multiple, start }) => {
  const classes = useStyles();
  const inputId = useMemo(() => uuidv4(), []);

  return (
    <Box className={clsx(classes.box, { [classes.padding]: padding, [classes.start]: start })}>
      <label htmlFor={inputId}>
        <input
          accept="image/*,application/*,.xlsx,.xls,image/*,.doc,.docx,.ppt,.pptx,.txt,.pdf"
          multiple={multiple}
          className={classes.input}
          id={inputId}
          ref={filesRef}
          type="file"
          onChange={handleUpload}
        />
        <Button component="span" classes={{ label: classes.buttonText, root: classes.root }}>
          {labelBox}
          <AddCircleOutlined className={classes.icon} />
        </Button>
      </label>
    </Box>
  );
};

BoxUpload.defaultProps = {
  padding: false,
  labelBox: 'Carregar ficheiros',
  multiple: true,
  start: false,
};

BoxUpload.propTypes = {
  filesRef: PropTypes.object.isRequired,
  handleUpload: PropTypes.func.isRequired,
  padding: PropTypes.bool,
  labelBox: PropTypes.string,
  multiple: PropTypes.bool,
  start: PropTypes.bool,
};

export default React.memo(BoxUpload);
