import React, { useContext, useCallback } from 'react';
import { makeStyles, Divider, Grid } from '@material-ui/core';
import H6 from '../../../../common/typographys/H6';
import {
  GeneralConfigStateContext,
  GeneralConfigDispatchContext,
} from '../CreateEditGeneralConfigProvider';
import InputField from '../../../../common/forms/InputField';
import { AddCircleOutlined, RemoveCircle } from '@material-ui/icons';
import ButtonPrimaryIcon from '../../../../common/buttons/ButtonPrimaryIcon';

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: '8px',
    marginBottom: '20px',
  },
  deleteIcon: {
    marginTop: '-8px',
    color: theme.palette.neutrals[4],
    transition: 'color 0.2s ease',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.error.main,
    },
  },
}));

const EditGeneralInfo = () => {
  const classes = useStyles();
  const {
    state: { general = {} },
  } = useContext(GeneralConfigStateContext);
  const dispatch = useContext(GeneralConfigDispatchContext);
  const { name, phone, email, description, links = {} } = general;

  const handleChange = useCallback(
    (nameInput, value) => {
      dispatch({
        type: 'UPDATE_GENERAL_FIELD',
        payload: {
          name: nameInput,
          value,
        },
      });
    },
    [dispatch]
  );

  const handleAddUrl = useCallback(() => {
    dispatch({
      type: 'ADD_URL_FIELDS',
      payload: {},
    });
  }, [dispatch]);

  const handleRemoveUrl = useCallback(
    id => {
      dispatch({
        type: 'REMOVE_URL_FIELD',
        payload: { id },
      });
    },
    [dispatch]
  );

  const handleUrlChange = useCallback(
    (id, label, url) => {
      dispatch({
        type: 'UPDATE_URL_FIELD',
        payload: {
          id,
          label,
          url,
        },
      });
    },
    [dispatch]
  );

  return (
    <>
      <H6>Informação geral</H6>
      <Divider className={classes.divider} />
      <InputField
        name="name"
        value={name}
        label="Nome da plataforma"
        onChange={e => handleChange(e.target.name, e.target.value)}
      />
      <InputField
        name="email"
        value={email}
        label="Email de contacto"
        type="email"
        onChange={e => handleChange(e.target.name, e.target.value)}
      />
      <InputField
        name="phone"
        value={phone}
        label="Contacto telefónico"
        type="tel"
        onChange={e => handleChange(e.target.name, e.target.value)}
      />
      <InputField
        name="description"
        value={description}
        label="Descrição"
        type="text"
        onChange={e => handleChange(e.target.name, e.target.value)}
      />
      <H6>Ligações no ecrã de login</H6>
      <Divider className={classes.divider} />
      {Object.keys(links)
        .sort()
        .map(key => {
          const { url, label } = links[key];

          return (
            <Grid container spacing={2} alignItems="center" key={key}>
              <Grid item xs={6}>
                <InputField
                  name="label"
                  value={label}
                  placeholder="Descrição"
                  type="text"
                  onChange={e => handleUrlChange(key, e.target.value, url)}
                />
              </Grid>
              <Grid item xs={5}>
                <InputField
                  name="url"
                  value={url}
                  placeholder="Endereço"
                  type="url"
                  onChange={e => handleUrlChange(key, label, e.target.value)}
                />
              </Grid>
              <Grid item xs={1}>
                <RemoveCircle className={classes.deleteIcon} onClick={() => handleRemoveUrl(key)} />
              </Grid>
            </Grid>
          );
        })}
      <ButtonPrimaryIcon
        label="Adicionar ligação"
        icon={AddCircleOutlined}
        onClick={handleAddUrl}
      />
    </>
  );
};

export default React.memo(EditGeneralInfo);
