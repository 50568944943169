import React from 'react';
import { makeStyles, Dialog, DialogContent, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body1 from '../../../../common/typographys/Body1';
import Body2 from '../../../../common/typographys/Body2';
// import InputField from '../../../../common/forms/InputField';

const useStyles = makeStyles(() => ({
  content: {
    padding: '30px',
  },
  boxButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
  buttonConfirm: {
    marginLeft: '30px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  buttonCancel: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  marginName: {
    marginBottom: '30px',
  },
}));

const DialogPointStatus = ({ open, pointName, pointStatus, handleCancel, handleSubmit }) => {
  const classes = useStyles();
  // const [notes, setNotes] = useState('');

  // useEffect(() => {
  //   if (!open) {
  //     setNotes('');
  //   }
  // }, [open]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleCancel}
      // onClose={e => {
      //   setNotes('');
      //   handleCancel(e);
      // }}
    >
      <DialogContent className={classes.content}>
        <Body1 className={classes.marginName}>
          Deseja {pointStatus === 3 ? 'retirar' : 'adiar'} a proposta{' '}
          {pointName ? `${pointName}` : ''}?
        </Body1>
        {/* <InputField
          multiline
          value={notes}
          rows="1"
          rowsMax="4"
          label="Notas"
          placeholder="Adicione notas sobre a decisão (opcional)"
          name="notes"
          onChange={e => setNotes(e.target.value)}
        /> */}
        <Box className={classes.boxButtons}>
          <Body2 onClick={handleCancel} className={classes.buttonCancel}>
            Cancelar
          </Body2>
          <Body2 secondary className={classes.buttonConfirm} onClick={handleSubmit}>
            {pointStatus === 3 ? 'Retirar' : 'Adiar'}
          </Body2>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

DialogPointStatus.defaultProps = {
  pointName: null,
};

DialogPointStatus.propTypes = {
  open: PropTypes.bool.isRequired,
  pointStatus: PropTypes.number.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pointName: PropTypes.string,
};

export default React.memo(DialogPointStatus);
