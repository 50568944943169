import React from 'react';
import {
  makeStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  Paper,
  ExpansionPanelDetails,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import '@ckeditor/ckeditor5-build-classic/build/translations/pt';
import Subtitle2 from '../../../../../common/typographys/Subtitle2';
import TemplateBox from './TemplateBox';
import { ExpandMore } from '@material-ui/icons';
import Body2 from '../../../../../common/typographys/Body2';
import getEmailTemplateLabel from '../../../../../../utils/getEmailTemplateLabel';
import clsx from 'clsx';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-markup';
import 'prismjs/themes/prism.css';

const useStyles = makeStyles(theme => ({
  label: {
    marginBottom: '8px',
  },
  content: {
    margin: 0,
  },
  selected: {
    backgroundColor: theme.palette.neutrals[2],
  },
  tagLabel: {
    marginTop: '8px',
  },
  paper: {
    height: '500px',
    overflow: 'hidden',
    overflowY: 'auto',
  },
}));

const HTMLCodeEditor = ({ content, onChange, id, type, subExpanded, handleSubChange }) => {
  const classes = useStyles();

  return (
    <ExpansionPanel
      expanded={subExpanded}
      onChange={handleSubChange(`email-${id}`)}
      TransitionProps={{ unmountOnExit: true }}
      className={clsx({ [classes.selected]: subExpanded })}
    >
      <ExpansionPanelSummary
        classes={{ content: classes.content }}
        expandIcon={<ExpandMore />}
        aria-controls={`email-${id}-content`}
        id={`email-${id}-header`}
      >
        <Body2 secondary={subExpanded}>{getEmailTemplateLabel(type)}</Body2>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <TemplateBox type={type} hideType>
          <Subtitle2 className={classes.label}>Conteúdo</Subtitle2>
          <Paper elevation={0} className={classes.paper}>
            <Editor
              value={content}
              onValueChange={onChange}
              highlight={code => {
                return highlight(code, languages.html);
              }}
              padding={10}
              style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 12,
                minHeight: '100%',
              }}
            />
          </Paper>
          {type === 'Template' && (
            <Subtitle2 className={classes.tagLabel}>
              Deve existir uma <code>{'<div></div>'}</code> com id <code>wrapper</code> para injetar
              o conteúdo dos emails anteriores:
              <pre>{'<div id="wrapper"></div>'}</pre>
            </Subtitle2>
          )}
          {type === 'Button' && (
            <>
              <Subtitle2 className={classes.tagLabel}>
                Deve existir uma <code>{'<a></a>'}</code> com id <code>button</code> para injetar o
                link gerado pela plataforma na hiperligação:
                <pre>{'<a id="button"></a>'}</pre>
              </Subtitle2>
              <Subtitle2 className={classes.tagLabel}>
                Deve existir uma <code>{'<span></span>'}</code> com id <code>link</code> para
                injetar o link gerado pela plataforma no texto de ajuda com hiperligação:
                <pre>{'<span id="link"></span>'}</pre>
              </Subtitle2>
            </>
          )}
        </TemplateBox>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

HTMLCodeEditor.propTypes = {
  content: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  subExpanded: PropTypes.bool.isRequired,
  handleSubChange: PropTypes.func.isRequired,
};

export default React.memo(HTMLCodeEditor);
