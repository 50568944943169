import React from 'react';
import PropTypes from 'prop-types';
import Body1 from './typographys/Body1';
import getStatusBySlug from '../../utils/getStatusBySlug';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  status: {
    marginLeft: '10px',
    textAlign: 'right',
  },
}));

const MeetingPointStatus = ({ status, clean }) => {
  const classes = useStyles();
  const { label, slug } = status;
  const meetingSlug = getStatusBySlug(slug);

  return (
    <Body1
      className={clsx({ [classes.status]: !clean })}
      approved={!clean && meetingSlug === 'approved'}
      disapproved={!clean && meetingSlug === 'disapproved'}
      standby={!clean && meetingSlug === 'standby'}
    >
      {label}
    </Body1>
  );
};

MeetingPointStatus.defaultProps = {
  clean: false,
};

MeetingPointStatus.propTypes = {
  status: PropTypes.object.isRequired,
  clean: PropTypes.bool,
};

export default React.memo(MeetingPointStatus);
