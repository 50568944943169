import React, { useContext } from 'react';
import { makeStyles, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import H6 from '../../../../common/typographys/H6';
import {
  GeneralConfigStateContext,
  GeneralConfigDispatchContext,
} from '../CreateEditGeneralConfigProvider';
import SelectOutlined from '../../../../common/forms/SelectOutlined';

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: '8px',
    marginBottom: '20px',
  },
}));

const EditPresidentInfo = ({ content }) => {
  const classes = useStyles();
  const { users } = content;
  const {
    state: { president = {} },
  } = useContext(GeneralConfigStateContext);
  const dispatch = useContext(GeneralConfigDispatchContext);

  const handleChange = (value, option) => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: option.name,
        value,
      },
    });
  };

  return (
    <>
      <H6>Presidente</H6>
      <Divider className={classes.divider} />
      <SelectOutlined
        options={users}
        value={president}
        label="Nome do Presidente"
        placeholder="Seleccionar um utilizador"
        name="president"
        onChange={handleChange}
        getOptionValue={user => user.id}
        getOptionLabel={user => user.name}
        noOptionsMessage={() => 'Nenhum utilizador disponível'}
      />
    </>
  );
};

EditPresidentInfo.propTypes = {
  content: PropTypes.object.isRequired,
};

export default React.memo(EditPresidentInfo);
