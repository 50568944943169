import React from 'react';
import { makeStyles, Box, FormHelperText } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import isEmpty from '../../../utils/isEmpty';

const useStyles = makeStyles(theme => ({
  box: {
    padding: '20px',
    border: `solid 1px ${theme.palette.neutrals[3]}`,
    width: '100%',
    height: '100%',
  },
  densePadding: {
    padding: '10px 10px 0px 10px',
  },
  mediumPadding: {
    padding: '12px 10px',
  },
  fixed: {
    maxHeight: '280px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  error: {
    border: `solid 1px ${theme.palette.colorsPalette.RustRed}`,
  },
  borderLeft: {
    borderLeft: `solid 4px ${theme.palette.primary.A700}`,
    backgroundColor: theme.palette.neutrals[2],
    borderTop: 'unset',
    borderRight: 'unset',
    borderBottom: 'unset',
  },
}));

const TimeBox = ({ children, dense, fixed, medium, error, label, borderLeft }) => {
  const classes = useStyles();

  return (
    <>
      <Box
        className={clsx(classes.box, {
          [classes.densePadding]: dense,
          [classes.mediumPadding]: medium,
          [classes.borderLeft]: borderLeft,
          [classes.error]: !isEmpty(error),
        })}
      >
        {label}
        <Box className={clsx({ [classes.fixed]: fixed })}>{children}</Box>
      </Box>
      {!isEmpty(error) && <FormHelperText error>{error}</FormHelperText>}
    </>
  );
};

TimeBox.defaultProps = {
  dense: false,
  fixed: false,
  medium: false,
  error: null,
  label: null,
  borderLeft: false,
};

TimeBox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  dense: PropTypes.bool,
  fixed: PropTypes.bool,
  medium: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  borderLeft: PropTypes.bool,
};

export default React.memo(TimeBox);
