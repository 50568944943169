import React, { useState, useCallback } from 'react';
import { makeStyles, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import H6 from '../../../../common/typographys/H6';
import EmailTemplatePanel from './pieces/EmailTemplatePanel';
import Subtitle1 from '../../../../common/typographys/Subtitle1';

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: '8px',
    marginBottom: '20px',
  },
}));

const EmailsTemplates = ({ content }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const { templates = [] } = content;

  const handleChange = useCallback(
    panel => (_, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    },
    []
  );

  return (
    <>
      <H6>Templates de emails</H6>
      <Subtitle1>
        Os valores {'{{entre chavetas}}'} são substituídos pelo valor correspondente. Não altere o conteúdo {'{{entre chavetas}}'}.
      </Subtitle1>
      <Divider className={classes.divider} />
      {templates.length > 0 &&
        templates.map((template, idx) => (
          <EmailTemplatePanel key={template.name} expanded={expanded === `panel-${idx}`} idx={idx} handleChange={handleChange} template={template} />
        ))}
    </>
  );
};

EmailsTemplates.propTypes = {
  content: PropTypes.object.isRequired,
};

export default React.memo(EmailsTemplates);
