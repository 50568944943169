import React from 'react';
import isEmpty from '../../../utils/isEmpty';
import getInitials from '../../../utils/getInitials';
import { Avatar } from '@material-ui/core';
import PropTypes from 'prop-types';

const AvatarLogoLetters = ({ logo, name, ...props }) => {
  return isEmpty(logo) ? <Avatar {...props}>{getInitials(name)}</Avatar> : <Avatar src={logo} {...props} />;
};

AvatarLogoLetters.defaultProps = {
  logo: null,
  name: null,
};

AvatarLogoLetters.propTypes = {
  logo: PropTypes.string,
  name: PropTypes.string,
};

export default AvatarLogoLetters;
