import React from 'react';
import { Box, makeStyles, Input } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Body1 from '../../../../common/typographys/Body1';

const useStyles = makeStyles(theme => ({
  mainBox: {
    display: 'flex',
    marginBottom: '10px',
  },
  timeBox: {
    padding: '8px 15px',
    width: '92px',
    marginLeft: '8px',
    backgroundColor: theme.palette.neutrals[3],
    height: '40px',
  },
  availableTimeBox: {
    backgroundColor: theme.palette.primary[200],
  },
  memberBox: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    paddingLeft: '10px',
  },
  inputRoot: {
    ...theme.typography.body1,
    height: theme.typography.body1.lineHeight,
    minHeight: '0px',
  },
  input: {
    padding: 0,
    ...theme.typography.body1,
    height: theme.typography.body1.lineHeight,
    color: theme.palette.neutrals[6],
  },
  inputAvailable: {
    color: theme.palette.neutrals[7],
  },
  disabled: {
    pointerEvents: 'none',
    backgroundColor: theme.palette.neutrals[2],
  },
  disabledTimebox: {
    pointerEvents: 'none',
  },
}));

const MatrixMember = ({ name, time }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.mainBox, classes.disabled)}>
      <Box className={classes.memberBox}>
        <Body1>{name}</Body1>
      </Box>
      <Box className={clsx(classes.timeBox, classes.disabledTimebox)}>
        <Input
          classes={{ root: classes.inputRoot, input: clsx(classes.input, classes.inputAvailable) }}
          value={time}
          type="time"
          inputProps={{
            step: 5,
            disabled: true,
          }}
          disableUnderline
        />
      </Box>
    </Box>
  );
};

MatrixMember.propTypes = {
  name: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
};

export default React.memo(MatrixMember);
