import React from 'react';
import { makeStyles, Dialog, DialogContent, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body1 from '../typographys/Body1';
import Body2 from '../typographys/Body2';
import H5 from '../typographys/H5';

const useStyles = makeStyles(() => ({
  content: {
    padding: '30px',
  },
  boxButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '50px',
  },
  buttonConfirm: {
    marginLeft: '30px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  buttonCancel: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const DialogCheck = ({
  open,
  msg,
  labelCancel,
  labelSubmit,
  handleCancel,
  handleSubmit,
  biggerLabel,
  children,
  disableBackdropClick,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleCancel}
      disableEscapeKeyDown
      disableBackdropClick={disableBackdropClick}
    >
      <DialogContent className={classes.content}>
        {!biggerLabel && <Body1>{msg}</Body1>}
        {biggerLabel && <H5>{msg}</H5>}
        {children}
        <Box className={classes.boxButtons}>
          <Body2 onClick={handleCancel} className={classes.buttonCancel}>
            {labelCancel}
          </Body2>
          <Body2 secondary className={classes.buttonConfirm} onClick={handleSubmit}>
            {labelSubmit}
          </Body2>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

DialogCheck.defaultProps = {
  labelCancel: 'Cancelar',
  biggerLabel: false,
  disableBackdropClick: false,
  children: null,
};

DialogCheck.propTypes = {
  open: PropTypes.bool.isRequired,
  msg: PropTypes.string.isRequired,
  labelCancel: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  labelSubmit: PropTypes.string.isRequired,
  biggerLabel: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
    PropTypes.bool,
  ]),
};

export default DialogCheck;
