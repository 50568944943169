/* eslint-disable no-nested-ternary */
import React, { useContext, useMemo, useEffect, useCallback } from 'react';
import InputField from '../../../common/forms/InputField';
import { MatrixStateContext, MatrixDispatchContext } from './CreateEditMatrixProvider';
import Subtitle2 from '../../../common/typographys/Subtitle2';
import { makeStyles } from '@material-ui/core';
import Subtitle1 from '../../../common/typographys/Subtitle1';
import TimeBox from '../../pieces/TimeBox';
import TimeSlot from './pieces/TimeSlot';
import { useSelector } from 'react-redux';
import MatrixTime from './pieces/MatrixTime';
import CheckboxType from './pieces/CheckboxType';
import MatrixTotal from './pieces/MatrixTotal';
import { ConfigStateContext } from '../../MenuConfigProvider';

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: '6px',
  },
  caption: {
    marginBottom: '10px',
  },
}));

const FormInfoMatrix = () => {
  const classes = useStyles();
  const { users = [] } = useSelector(state => state.users);
  const { groups: groupsOptions = [] } = useSelector(state => state.groups);
  const { editing } = useContext(ConfigStateContext);
  const {
    state: {
      name,
      time,
      type,
      members: { individuals, groups },
      errors,
    },
  } = useContext(MatrixStateContext);
  const dispatch = useContext(MatrixDispatchContext);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'CLEAR_STATE',
      });
    };
  }, [dispatch]);

  const options = useMemo(
    () =>
      type === 'individual'
        ? users
            .map(x => ({ id: x.id, name: x.name, idSel: `user-${x.id}` }))
            .filter(x => individuals.find(ind => ind.id === x.id) === undefined)
        : type === 'grupo'
        ? groupsOptions
            .map(x => ({ id: x.id, name: x.name, idSel: `grupo-${x.id}` }))
            .filter(x => groups.find(xGroup => xGroup.id === x.id) === undefined)
        : [],
    [groupsOptions, type, users, individuals, groups]
  );

  const updateField = useCallback(
    (nameInput, value) => {
      dispatch({
        type: 'UPDATE_FIELD',
        payload: {
          name: nameInput,
          value,
        },
      });
    },
    [dispatch]
  );

  const renderOptions = () => {
    switch (type) {
      case 'individual':
        return (
          <>
            {individuals.map((item, idx) => (
              <TimeSlot
                key={item.id}
                options={options}
                error={errors.members}
                type={type}
                time={item.time}
                value={item}
                idx={idx}
              />
            ))}
            {!editing && <TimeSlot options={options} type={type} chooseOnly />}
          </>
        );
      case 'grupo':
        return (
          <>
            {groups.map((item, idx) => (
              <TimeSlot
                key={item.id}
                options={options}
                error={errors.members}
                type={type}
                time={item.time}
                value={item}
                idx={idx}
              />
            ))}
            {!editing && <TimeSlot options={options} type={type} chooseOnly />}
          </>
        );
      default:
        return null;
    }
  };

  const caption = () => {
    if (!type) return null;

    return `Atribua um tempo máximo para esta matriz e defina a divisão do tempo por cada ${
      type === 'grupo' ? 'grupo' : 'participante'
    }.`;
  };

  return (
    <>
      <InputField
        label="Nome"
        name="name"
        value={name}
        placeholder="Ex: Matriz - 60 minutos"
        onChange={e => updateField('name', e.target.value)}
        error={errors.name}
      />
      <Subtitle2 className={classes.label}>Natureza da matriz</Subtitle2>
      <CheckboxType value={type} error={errors.type} />
      <Subtitle2 className={classes.label}>Tempos</Subtitle2>
      <Subtitle1 className={classes.caption}>{caption()}</Subtitle1>
      <TimeBox>
        <MatrixTime time={time} />
        {renderOptions()}
        <MatrixTotal
          time={time}
          type={type}
          individuals={individuals}
          groups={groups}
          error={errors.total}
        />
      </TimeBox>
    </>
  );
};

export default FormInfoMatrix;
