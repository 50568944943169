import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import useCommonStyles from './useCommonStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.h6,
  },
  hoverable: {
    transition: 'color 0.1s ease',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  },
  hover: {
    color: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.primary.A700,
  },
  tertiary: {
    color: theme.palette.neutrals[6],
  },
}));

const H6 = ({
  children,
  center,
  right,
  white,
  inactive,
  hoverable,
  hover,
  oneLine,
  className,
  secondary,
  tertiary,
  approved,
  disapproved,
  ...props
}) => {
  const classes = useStyles();
  const common = useCommonStyles();

  return (
    <Typography
      variant="h6"
      classes={{ root: classes.root }}
      className={clsx({
        [common.center]: center,
        [common.right]: right,
        [common.white]: white,
        [common.inactive]: inactive,
        [classes.hoverable]: hoverable,
        [classes.hover]: hover,
        [common.wrapEllipsis]: oneLine,
        [classes.secondary]: secondary,
        [classes.tertiary]: tertiary,
        [common.approved]: approved,
        [common.disapproved]: disapproved,
        [className]: className.length > 0,
      })}
      {...props}
    >
      {children}
    </Typography>
  );
};

H6.defaultProps = {
  center: false,
  right: false,
  white: false,
  inactive: false,
  hoverable: false,
  hover: false,
  oneLine: false,
  secondary: false,
  tertiary: false,
  approved: false,
  disapproved: false,
  className: '',
};

H6.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]).isRequired,
  center: PropTypes.bool,
  right: PropTypes.bool,
  white: PropTypes.bool,
  inactive: PropTypes.bool,
  hoverable: PropTypes.bool,
  hover: PropTypes.bool,
  oneLine: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  approved: PropTypes.bool,
  disapproved: PropTypes.bool,
  className: PropTypes.string,
};

export default H6;
