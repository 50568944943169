import React from 'react';
import { Box, Grid, InputAdornment, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import CheckboxPicker from '../../../common/forms/CheckboxPicker';
import InputField from '../../../common/forms/InputField';
import CurrencyFormat from './CurrencyFormat';

const useStyles = makeStyles(theme => ({
  itemAmount: {
    backgroundColor: theme.palette.neutrals[3],
  },
}));

const Budget = ({ state, updateField }) => {
  const classes = useStyles();

  const { hasBudget, budgetNumber, amount, /* budgetValue, */ errors } = state;

  return (
    <>
      <Box height="20px" />
      <CheckboxPicker
        label="Proposta com cabimento"
        checked={hasBudget}
        onClick={() => updateField('hasBudget', !hasBudget)}
      />
      <Box height="20px" />
      {hasBudget && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputField
              label="Número do cabimento"
              name="budgetNumber"
              value={budgetNumber}
              onChange={e => updateField('budgetNumber', e.target.value)}
              error={errors.budgetNumber}
            />
          </Grid>
          {/* <Grid item xs={6}>
            <InputField
              label="Valor do cabimento"
              name="budgetValue"
              value={budgetValue}
              margin="none"
              placeholder="0.000,00"
              onChange={(nameInput, value) => updateField(nameInput, value)}
              InputProps={{
                startAdornment: <InputAdornment position="start">€</InputAdornment>,
                inputComponent: CurrencyFormat,
                className: classes.itemAmount,
              }}
            />
          </Grid> */}
          <Grid item xs={6}>
            <InputField
              label="Valor do cabimento"
              helperText="Valor associado à proposta"
              name="amount"
              value={amount}
              margin="none"
              placeholder="0 000.00"
              onChange={(nameInput, value) => updateField(nameInput, value)}
              InputProps={{
                startAdornment: <InputAdornment position="start">€</InputAdornment>,
                inputComponent: CurrencyFormat,
                className: classes.itemAmount,
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

Budget.propTypes = {
  state: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
};

export default React.memo(Budget);
