import React, { useContext, useCallback } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CheckboxPicker from '../../common/forms/CheckboxPicker';
import { MeetingDispatchContext } from '../CreateEditMeetingsProvider';
import ListPointsCheckbox from './ListPointsCheckbox';

const useStyles = makeStyles(theme => ({
  mainBox: {
    backgroundColor: theme.palette.neutrals[2],
    padding: '8px 10px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  marginBottom: {
    marginBottom: '12px',
  },
  smallMarginBottom: {
    marginBottom: '8px',
  },
  content: {
    padding: '0px 10px',
  },
  divider: {
    margin: '8px 0px',
  },
}));

const ThemeMeetingEntry = ({ theme, idx, last }) => {
  const classes = useStyles();
  const dispatch = useContext(MeetingDispatchContext);

  const toggleTheme = useCallback(
    (idxTheme, selected, pointsSelected, total) => e => {
      e.preventDefault();
      if (pointsSelected === 0 && total > 0) {
        // @ Nenhum selecionado, selecionar todos
        dispatch({
          type: 'SELECT_ALL_THEME_POINTS',
          payload: {
            idx: idxTheme,
          },
        });
      } else {
        // @ Pelo menos 1 está selecionado OU é um tema sem pontos associados
        dispatch({
          type: selected ? 'UNSELECT_THEME' : 'SELECT_THEME',
          payload: {
            idx: idxTheme,
          },
        });
      }
    },
    [dispatch]
  );

  const togglePoint = useCallback(
    (idxTheme, idxPoint, selected) => e => {
      e.preventDefault();
      dispatch({
        type: selected ? 'UNSELECT_THEME_POINT' : 'SELECT_THEME_POINT',
        payload: {
          idxTheme,
          idxPoint,
        },
      });
    },
    [dispatch]
  );

  return (
    <Box className={clsx({ [classes.marginBottom]: !last })}>
      <Box
        className={clsx(classes.mainBox, {
          [classes.smallMarginBottom]: theme.points && theme.points.length > 0,
        })}
      >
        <CheckboxPicker
          label={theme.name}
          onClick={toggleTheme(idx, theme.selected, theme.pointsSelected, theme.total)}
          checked={theme.selected}
          indeterminate={theme.pointsSelected > 0}
          allCheck={theme.pointsSelected === theme.total}
        />
      </Box>
      <Box className={classes.content}>
        {theme.points &&
          theme.points.map((point, idxPoint) => (
            <ListPointsCheckbox
              key={point.name}
              label={
                point.identifier_code ? `${point.identifier_code} - ${point.name}` : point.name
              }
              onClick={togglePoint(idx, idxPoint, point.selected)}
              checked={point.selected}
              divider={idxPoint < theme.points.length - 1}
            />
          ))}
      </Box>
    </Box>
  );
};

ThemeMeetingEntry.propTypes = {
  theme: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
  last: PropTypes.bool.isRequired,
};

export default React.memo(ThemeMeetingEntry);
