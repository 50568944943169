import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Subtitle2 from '../../common/typographys/Subtitle2';
import TimeBox from '../../configs/pieces/TimeBox';
import MeetingUsersList from './MeetingUsersList';

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: '6px',
  },
}));

const MeetingUsersGroups = ({ groups, editing }) => {
  const classes = useStyles();

  return groups.map((group, idx) => (
    <React.Fragment key={group.id}>
      <Subtitle2 className={classes.label}>{group.name}</Subtitle2>
      <TimeBox dense>
        <MeetingUsersList
          users={group.users.filter(x => x.in_meeting)}
          isGroup
          allUsers={group.users}
          idxGroup={idx}
          idGroup={group.id}
          editing={editing}
          groupLimit={group.num_users}
        />
      </TimeBox>
      {idx !== groups.length - 1 && <Box height="20px" />}
    </React.Fragment>
  ));
};

MeetingUsersGroups.propTypes = {
  groups: PropTypes.array.isRequired,
  editing: PropTypes.bool.isRequired,
};

export default React.memo(MeetingUsersGroups);
