import React from 'react';
import { makeStyles, Box, Divider } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Body1 from '../../../common/typographys/Body1';

const useStyles = makeStyles(theme => ({
  divider: {
    padding: '0px 10px',
  },
  point: {
    padding: '8px 10px',
  },
  group: {
    padding: '8px 10px',
    backgroundColor: theme.palette.neutrals[2],
    display: 'flex',
    justifyContent: 'space-between',
  },
  lastPoint: {
    marginBottom: '22px',
  },
  groupBox: {
    marginBottom: '12px',
  },
  noLimit: {
    marginLeft: '10px',
    whiteSpace: 'nowrap',
  },
}));

const ListParticipants = ({ users, isGroup }) => {
  const classes = useStyles();

  if (!users) return null;

  if (isGroup) {
    return users.map(user => (
      <Box key={user.id} className={classes.groupBox}>
        <Box className={classes.group}>
          <Body1 oneLine>{user.name}</Body1>
          {Boolean(user.no_limit) && (
            <Body1 secondary className={classes.noLimit}>
              sem tempo limite oratório
            </Body1>
          )}
        </Box>
        {Array.isArray(user.users) &&
          user.users
            .filter(x => x.in_meeting)
            .map((user2, idxP) => (
              <React.Fragment key={user2.id}>
                <Box
                  className={clsx(classes.point, {
                    [classes.lastPoint]: idxP + 1 === user.users.length,
                  })}
                >
                  <Body1
                    secondary={user2.accepted === null}
                    approved={Boolean(user2.check_in)}
                    disapproved={!user2.accepted && user2.accepted !== null}
                    oneLine
                  >
                    {user2.name}
                  </Body1>
                </Box>
                {idxP + 1 !== user.users.length && (
                  <Box className={classes.divider}>
                    <Divider />
                  </Box>
                )}
              </React.Fragment>
            ))}
      </Box>
    ));
  }

  return users
    .filter(user => user.in_meeting)
    .map(user => (
      <Box key={user.id} className={classes.groupBox}>
        <Box className={classes.group}>
          <Body1
            oneLine
            secondary={user.accepted === null}
            approved={Boolean(user.check_in)}
            disapproved={!user.accepted && user.accepted !== null}
          >
            {user.name}
          </Body1>
          {Boolean(user.no_limit) && (
            <Body1 secondary className={classes.noLimit}>
              sem tempo limite oratório
            </Body1>
          )}
        </Box>
      </Box>
    ));
};

ListParticipants.propTypes = {
  users: PropTypes.array.isRequired,
  isGroup: PropTypes.bool.isRequired,
};

export default React.memo(ListParticipants);
