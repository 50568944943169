import { useState, useCallback } from 'react';
import mapValues from 'lodash/mapValues';
import isPlainObject from 'lodash/isPlainObject';

const mapValuesDeep = (obj, fn) => mapValues(obj, (val, key) => (isPlainObject(val) ? mapValuesDeep(val, fn) : fn(val, key, obj)));

const useFormWithErrors = initialValues => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState(mapValuesDeep(initialValues, () => ''));

  const handleChange = useCallback(
    e => {
      e.persist();
      setValues(oldValues => ({
        ...oldValues,
        [e.target.name]: e.target.value,
      }));
    },
    [setValues]
  );

  const reset = useCallback(() => {
    setValues(old => ({ ...old, ...initialValues }));
    setErrors(oldErrors => ({ ...oldErrors, ...mapValuesDeep(initialValues, () => '') }));
  }, [initialValues]);

  return [values, handleChange, errors, setErrors, reset];
};

export default useFormWithErrors;
