import React, { useEffect, useCallback } from 'react';
import io from 'socket.io-client';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  GET_ERRORS,
  SOCKET_REUNIONS,
  GET_NOTIFICATIONS,
  DELETE_MEETING,
} from '../../store/actions/types';
import { removeNotification } from '../../store/actions/notificationsActions';
import { useSnackbar } from 'notistack';
import getGreetingTime from '../../utils/getGreetingTime';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '@material-ui/core';
import isEmpty from '../../utils/isEmpty';

export const SOCKET = React.createContext();

let socket;
let syncSocket;

const cleanSocket = () => {
  socket = null;
  syncSocket = null;
};

const SocketWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const {
    isAuthenticated,
    isEmailSet,
    user: { email, name, department, id },
  } = useSelector(state => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const clickNotification = useCallback(
    (link, idNot, key) => (
      <Button
        onClick={e => {
          e.preventDefault();
          history.push(link);
          closeSnackbar(key);
          dispatch(removeNotification(idNot));
        }}
      >
        Entrar
      </Button>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (
      isAuthenticated &&
      name.length > 3 &&
      location.pathname !== '/saml-auth' &&
      location.pathname.indexOf('/plateia') === -1
    ) {
      enqueueSnackbar(`${getGreetingTime()}, ${name}.`, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar, isAuthenticated, name]);

  if (
    !socket &&
    isEmailSet &&
    location.pathname !== '/saml-auth' &&
    !isEmpty(email) &&
    !isEmpty(id) &&
    email !== ''
  ) {
    socket = io(process.env.REACT_APP_SOCKET_URL, { query: { email, id } });
    syncSocket = io(`${process.env.REACT_APP_SOCKET_URL}/sync`, { query: { email, id } });

    syncSocket.on('reunion_deleted', msg => {
      dispatch({
        type: DELETE_MEETING,
        payload: msg,
      });
    });

    socket.on('not_found_or_error', msg => {
      dispatch({
        type: GET_ERRORS,
        payload: JSON.parse(msg),
      });
    });

    socket.on('reunions', reunions => {
      dispatch({
        type: SOCKET_REUNIONS,
        payload: JSON.parse(reunions),
      });
    });

    socket.on('notification', msg => {
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: [JSON.parse(msg)],
      });
      // dispatch(readNotifications(false));
    });

    socket.on('started_notification', msg => {
      const parsed = JSON.parse(msg);

      enqueueSnackbar(parsed.text, {
        variant: 'info',
        autoHideDuration: 5000,
        action: key => clickNotification(parsed.link, parsed.id, key),
      });
    });
  }

  return (
    <SOCKET.Provider value={{ socket, syncSocket, cleanSocket, email, name, department }}>
      {children}
    </SOCKET.Provider>
  );
};

SocketWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default SocketWrapper;
