import React, { useMemo, useCallback } from 'react';
import { makeStyles, Grid, Select, MenuItem, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import InputField from '../../common/forms/InputField';
import BoxCheckbox from '../../common/forms/BoxCheckbox';
import Subtitle1 from '../../common/typographys/Subtitle1';
// import Body2 from '../../common/typographys/Body2';
import { useSelector } from 'react-redux';
import canSeePools from '../../../utils/permissions/canSeePools';

const useStyles = makeStyles(() => ({
  filterContainer: {
    marginBottom: '22px',
  },
  itemFlexCenter: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '0px!important',
    paddingBottom: '0px!important',
  },
  input: {
    paddingTop: 0,
  },
  inputField: {
    marginTop: 0,
    width: '60%',
  },
  inputProponente: {
    marginTop: 0,
  },

  menuItem: {
    padding: '10px',
  },
  label: {
    marginRight: '10px',
  },
}));

const FilterContainer = ({
  value,
  onChange,
  status,
  setStatus,
  proponente,
  onChangeProponente,
}) => {
  const classes = useStyles();
  const { statuses } = useSelector(state => state.proposals);
  const { permissions } = useSelector(state => state.auth.user);
  const checkPools = canSeePools(permissions);
  const items = useMemo(() => statuses.map(({ label, slug }) => ({ label, slug })), [statuses]);

  const selectedName = useCallback(
    slug => {
      const item = items.find(x => x.slug === slug);
      return (item && item.label) || '1 filtro';
    },
    [items]
  );

  return (
    <Grid container spacing={3} className={classes.filterContainer}>
      <Grid item xs={checkPools ? 4 : 5} className={classes.itemFlexCenter}>
        <InputField
          name="typology"
          value={value}
          onChange={onChange}
          variant="standard"
          className={classes.inputField}
          InputProps={{
            classes: { input: classes.input },
          }}
          placeholder="Pesquisar proposta"
        />
      </Grid>
      <Grid item xs={2} className={classes.itemFlexCenter}>
        <InputField
          name="typology"
          value={proponente}
          onChange={onChangeProponente}
          variant="standard"
          className={classes.inputProponente}
          InputProps={{
            classes: { input: classes.input },
          }}
          placeholder="Filtrar proponente"
        />
      </Grid>
      <Grid item xs={checkPools ? 2 : 3} />
      {checkPools && <Grid item xs={2} />}
      <Grid item xs={2}>
        <Box width="100%">
          <Select
            multiple
            fullWidth
            value={status}
            onChange={e => {
              setStatus(e.target.value);
            }}
            MenuProps={{
              MenuListProps: {
                disablePadding: true,
              },
            }}
            inputProps={{
              placeholder: 'Filtrar por',
            }}
            renderValue={selected =>
              selected.length === 1 ? selectedName(selected[0]) : `${selected.length} filtros`
            }
          >
            {items.map(({ label, slug }) => (
              <MenuItem key={slug} value={slug} className={classes.menuItem}>
                <Subtitle1 selected={status.indexOf(slug) > -1} className={classes.label}>
                  {label}
                </Subtitle1>
                <BoxCheckbox checked={status.indexOf(slug) > -1} onClick={() => null} />
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Grid>
    </Grid>
  );
};

FilterContainer.propTypes = {
  value: PropTypes.string.isRequired,
  proponente: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeProponente: PropTypes.func.isRequired,
  status: PropTypes.array.isRequired,
  setStatus: PropTypes.func.isRequired,
};

export default React.memo(FilterContainer);
