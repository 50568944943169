import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    const { user } = this.props;
    this.setState(old => ({
      ...old,
      error,
      errorInfo,
    }));

    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const { id, name, email } = user;
      scope.setUser({ email, name, id });
      const eventId = Sentry.captureException(error);
      this.setState(old => ({ ...old, eventId }));
    });
  }

  render() {
    const { error, eventId, errorInfo } = this.state;
    const { children, user } = this.props;

    if (errorInfo) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <h2
            style={{
              textAlign: 'center',
            }}
          >
            Ups! Aconteceu um erro inesperado.
          </h2>
          {process.env.NODE_ENV === 'development' && (
            <Button
              variant="contained"
              color="primary"
              onClick={e => {
                e.preventDefault();
                Sentry.showReportDialog({
                  eventId,
                  user: { email: user.email, name: user.name },
                  title: 'Parece que ocorreu algum problema',
                  subtitle: 'A nossa equipa foi notificada.',
                  subtitle2: 'Se nos conseguir ajudar, conte o que aconteceu.',
                  labelName: 'Nome',
                  labelComments: 'O que aconteceu?',
                  labelClose: 'Fechar',
                  labelSubmit: 'Enviar',
                  errorGeneric:
                    'Ocorreu um erro ao submeter o seu relatório. Tente novamente por favor.',
                  errorFormEntry:
                    'Alguns campos foram preenchidos de forma errada. Corrija os erros e tente novamente.',
                  successMessage: 'O seu relatório foi enviado. Obrigado!',
                });
              }}
              style={{ width: '200px', margin: 'auto' }}
            >
              Reportar erro
            </Button>
          )}
          {process.env.NODE_ENV === 'development' && (
            <details style={{ whiteSpace: 'pre-wrap', margin: 'auto' }}>
              {error && error.toString()}
              <br />
              {errorInfo.componentStack}
            </details>
          )}
        </div>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ErrorBoundary);
