import React, { useMemo } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import H6 from '../../../common/typographys/H6';
import H2 from '../../../common/typographys/H2';
import { ReactComponent as Against } from '../../../../assets/images/againstPlateiaBig.svg';
import { ReactComponent as Favor } from '../../../../assets/images/checkPlateiaBig.svg';
import { ReactComponent as Abstention } from '../../../../assets/images/abstentionPlateiaBig.svg';
import { ReactComponent as NoVote } from '../../../../assets/images/noVoteBig.svg';

const useStyles = makeStyles(theme => ({
  mainBox: {
    display: 'flex',
    padding: '24px 10px 36px 10px',
    alignItems: 'center',
    // flexDirection: 'column',
    height: '100%',
    width: '100%',
    justifyContent: 'space-between',
  },
  borderFavor: {
    border: `solid 1px ${theme.palette.colorsPalette.TrueGreen}`,
  },
  borderAgainst: {
    border: `solid 1px ${theme.palette.colorsPalette.RustRed}`,
  },
  borderAbstention: {
    border: `solid 1px ${theme.palette.colorsPalette.Orange}`,
  },
  borderNoVote: {
    border: `solid 1px ${theme.palette.colorsPalette.TrueBlue}`,
  },
  count: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const VoteBottomBlock = ({ favor, against, abstention, novote, total }) => {
  const classes = useStyles();
  const label = useMemo(() => {
    if (favor) return 'A favor';
    if (against) return 'Contra';
    if (abstention) return 'Abstinências';
    if (novote) return 'Excusa de voto';

    return '';
  }, [favor, against, abstention, novote]);

  const icon = useMemo(() => {
    if (favor) return <Favor />;
    if (against) return <Against />;
    if (abstention) return <Abstention />;
    if (novote) return <NoVote />;

    return null;
  }, [favor, against, abstention, novote]);

  return (
    <Box
      className={clsx(classes.mainBox, {
        [classes.borderFavor]: favor,
        [classes.borderAgainst]: against,
        [classes.borderAbstention]: abstention,
        [classes.borderNoVote]: novote,
      })}
    >
      <Box className={classes.count}>
        <H6 tertiary>{label}</H6>
        <H2>{total}</H2>
      </Box>
      <Box display="flex" alignItems="center">
        {icon}
      </Box>
    </Box>
  );
};

VoteBottomBlock.defaultProps = {
  favor: false,
  against: false,
  abstention: false,
  novote: false,
  total: 0,
};

VoteBottomBlock.propTypes = {
  favor: PropTypes.bool,
  against: PropTypes.bool,
  abstention: PropTypes.bool,
  novote: PropTypes.bool,
  total: PropTypes.number,
};

export default React.memo(VoteBottomBlock);
