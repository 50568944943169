import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import ConfigList from '../../pieces/ConfigList';
import InfoSection from '../../pieces/InfoSection';
import isEmpty from '../../../../utils/isEmpty';
import { ConfigStateContext, ConfigDispatchContext } from '../../MenuConfigProvider';
import AddNewGroup from './AddNewGroup';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../../../store/actions/usersActions';
import { getGroups, removeGroup } from '../../../../store/actions/groupsActions';
import SeeEditGroup from './SeeEditGroup';
import CreateEditGroupProvider from './CreateEditGroupProvider';

const Groups = () => {
  const { groups = [] } = useSelector(state => state.groups);
  const { permissions } = useSelector(state => state.auth.user);
  const { activeItem, isAddNew, deleting } = useContext(ConfigStateContext);
  const [search, setSearch] = useState('');
  const dispatchConfig = useContext(ConfigDispatchContext);
  const dispatchRedux = useDispatch();

  useEffect(() => {
    dispatchRedux(getUsers());
    dispatchRedux(getGroups());
  }, [dispatchRedux]);

  const items = useMemo(() => {
    if (isEmpty(search)) {
      return groups;
    }

    return groups.filter(x => x.name.toLowerCase().indexOf(search.toLowerCase()) !== -1);
  }, [search, groups]);

  const hasPagination = useMemo(() => items.length > 10, [items]);
  const pageObj = useMemo(() => ({ isItemGroup: true }), []);

  const handleDelete = useCallback(
    e => {
      e.preventDefault();
      dispatchRedux(
        removeGroup(activeItem.id, () =>
          dispatchConfig({
            type: 'CANCEL_ADD_NEW',
          })
        )
      );
    },
    [dispatchRedux, activeItem, dispatchConfig]
  );

  return (
    <>
      <ConfigList
        items={items}
        pageObj={pageObj}
        labelButton="Novo grupo"
        noItemMessage="Nenhum grupo adicionado."
        msgDelete={`Tem a certeza que deseja excluir o grupo${activeItem && activeItem.name ? `, ${activeItem.name}` : ''}?`}
        labelSubmit="Excluir"
        canDelete={Boolean(permissions && permissions.others && permissions.others.groups)}
        canAddNew={permissions && permissions.others && permissions.others.groups}
        handleDelete={handleDelete}
        search={search}
        setSearch={setSearch}
      />
      <InfoSection hasPagination={hasPagination}>
        <CreateEditGroupProvider items={groups}>
          {!isEmpty(activeItem) && !deleting && <SeeEditGroup info={activeItem} />}
          {isAddNew && <AddNewGroup />}
        </CreateEditGroupProvider>
      </InfoSection>
    </>
  );
};

export default Groups;
