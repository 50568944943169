import React, { useContext } from 'react';
import {
  GeneralConfigStateContext,
  GeneralConfigDispatchContext,
} from './CreateEditGeneralConfigProvider';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import UpdateItem from '../../pieces/UpdateItem';
import PropTypes from 'prop-types';
import EditProposalStatuses from './subTabs/EditProposalStatuses';
import EditEmailsTemplates from './subTabs/EditEmailsTemplates';
import EditGeneralInfo from './subTabs/EditGeneralInfo';
import EditPresidentInfo from './subTabs/EditPresidentInfo';

const EditGeneralConfig = ({ info }) => {
  const {
    state: { errors, items },
    handleSubmit,
  } = useContext(GeneralConfigStateContext);
  const dispatch = useContext(GeneralConfigDispatchContext);

  return (
    <UpdateItem
      disableSubmit={checkEmptyObject(errors).length > 0}
      loading={false}
      items={items}
      dispatch={dispatch}
      handleSubmit={handleSubmit}
    >
      {info.type === 'general' && <EditGeneralInfo />}
      {info.type === 'proposals' && <EditProposalStatuses />}
      {info.type === 'templates' && <EditEmailsTemplates />}
      {info.type === 'president' && <EditPresidentInfo content={info.content} />}
    </UpdateItem>
  );
};

EditGeneralConfig.propTypes = {
  info: PropTypes.object.isRequired,
};

export default React.memo(EditGeneralConfig);
