import React, { useEffect, useReducer, useCallback, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from '../../../../utils/isEmpty';
import { clearErrors } from '../../../../store/actions/authActions';
import {
  createOrganicUnit,
  updateOrganicUnit,
  getOrganicUnitsChildren,
  getOrganicUnit,
} from '../../../../store/actions/organicUnitsActions';
import { getUsersSimplified } from '../../../../store/actions/usersActions';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import { ConfigStateContext } from '../../MenuConfigProvider';

export const OrganicUnitDispatchContext = React.createContext();
export const OrganicUnitFuncsContext = React.createContext();
export const OrganicUnitStateContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'SET_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    case 'CLEAR_STATE':
      return {
        ...state,
        name: '',
        code: '',
        abbreviation: '',
        parent: null,
        user: null,
        newParent: null,
        newUserOrganicUnit: null,
        need_verification: false,
        children: [],
        id: '',
        errors: {},
      };
    default:
      return state;
  }
};

const CreateEditOrganicUnitProvider = ({ children, items }) => {
  const errorsReducer = useSelector(state => state.errors);
  const { loading } = useSelector(state => state.organicUnits);
  const dispatchRedux = useDispatch();
  const { editing } = useContext(ConfigStateContext);
  const [state, dispatch] = useReducer(reducer, {
    name: '',
    code: '',
    abbreviation: '',
    parent: null,
    user: null,
    newParent: null,
    newUserOrganicUnit: null,
    need_verification: false,
    childs: [],
    id: '',
    items: [],
    errors: {},
  });
  const [sameName, setSameName] = useState('');
  const { name, code, abbreviation, parent, user, id, errors } = state;

  const updateField = useCallback((nameInput, value) => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: nameInput,
        value,
      },
    });
  }, []);

  useEffect(() => {
    updateField('items', items);
  }, [items, updateField]);

  useEffect(() => {
    if (!isEmpty(user) && !isEmpty(user.organic_unit)) {
      updateField('need_verification', true);
    }
  }, [user, updateField]);

  useEffect(() => {
    if (errorsReducer.name) {
      dispatch({
        type: 'SET_ERROR',
        payload: { name: 'Já existe uma Unidade Orgânica com este nome.' },
      });
      dispatchRedux(clearErrors());
      setSameName(name);
    }
  }, [errorsReducer, name, dispatchRedux]);

  useEffect(() => {
    if (!isEmpty(name) && !isEmpty(errors.name) && name !== sameName) {
      dispatch({
        type: 'SET_ERROR',
        payload: { name: '' },
      });
    }
  }, [name, errors.name, sameName]);

  useEffect(() => {
    if (!isEmpty(code) && !isEmpty(errors.code)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { code: '' },
      });
    }
  }, [code, errors.code]);

  useEffect(() => {
    if (!isEmpty(abbreviation) && !isEmpty(errors.abbreviation)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { abbreviation: '' },
      });
    }
  }, [abbreviation, errors.abbreviation]);

  useEffect(() => {
    if (!isEmpty(user) && !isEmpty(errors.user)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { user: '' },
      });
    }
  }, [user, errors.user]);

  useEffect(() => {
    if (!isEmpty(id)) {
      dispatchRedux(getUsersSimplified());
      dispatchRedux(getOrganicUnitsChildren(id));
      dispatchRedux(getOrganicUnit(id));
    }
  }, [id, dispatchRedux]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      const localErrors = {};

      if (isEmpty(name)) {
        localErrors.name = 'Insira um nome válido para a Unidade Orgânica.';
      }

      if (isEmpty(abbreviation)) {
        localErrors.abbreviation = 'Insira a abreviatura para a Unidade Orgânica.';
      }

      if (isEmpty(code)) {
        localErrors.code = 'Insira um código válido para a Unidade Orgânica.';
      }

      if (isEmpty(user)) {
        localErrors.user = 'Defina um Responsável para esta Unidade Orgânica.';
      }

      if (checkEmptyObject(localErrors).length > 0) {
        return dispatch({
          type: 'SET_ERROR',
          payload: localErrors,
        });
      }

      const infoOrganicUnit = {
        name,
        code,
        abbreviation,
        userId: user ? user.id : null,
        parentId: parent ? parent.id : null,
      };

      if (editing) {
        return dispatchRedux(updateOrganicUnit(id, infoOrganicUnit));
      }

      return dispatchRedux(createOrganicUnit(infoOrganicUnit));
    },
    [dispatchRedux, editing, id, abbreviation, name, code, parent, user]
  );

  const contextState = useMemo(
    () => ({ state, handleSubmit, loading }),
    [state, handleSubmit, loading]
  );
  const funcsState = useMemo(() => ({ updateField }), [updateField]);

  return (
    <OrganicUnitDispatchContext.Provider value={dispatch}>
      <OrganicUnitFuncsContext.Provider value={funcsState}>
        <OrganicUnitStateContext.Provider value={contextState}>
          {children}
        </OrganicUnitStateContext.Provider>
      </OrganicUnitFuncsContext.Provider>
    </OrganicUnitDispatchContext.Provider>
  );
};

CreateEditOrganicUnitProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  items: PropTypes.array.isRequired,
};

export default CreateEditOrganicUnitProvider;
