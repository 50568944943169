import React, { useEffect, useState, useCallback } from 'react';
import CreateEditMeetingsProvider from '../CreateEditMeetingsProvider';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { getMeeting, getMeetingMatrices } from '../../../store/actions/meetingsActions';
import EditMeetingForm from './EditMeetingForm';
import isEmpty from '../../../utils/isEmpty';
import FullWidthSpinner from '../../common/FullWidthSpinner';
import { makeStyles } from '@material-ui/styles';
import { changeMenuSidebar } from '../../../store/actions/navigationActions';

const useStyles = makeStyles(() => ({
  margin: {
    marginTop: '54px',
  },
}));

const EditMeeting = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [change, setChange] = useState(true);
  const { meeting, loading } = useSelector(state => state.meetings);

  useEffect(() => {
    dispatch(changeMenuSidebar('reunioes', 'Reuniões', 'Editar reunião'));
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(getMeeting(id));
      dispatch(getMeetingMatrices(id));
    }
  }, [dispatch, id]);

  const navigate = useCallback(
    page => {
      setChange(false);
      history.push(page);
    },
    [history]
  );

  return !loading && !isEmpty(meeting) ? (
    <CreateEditMeetingsProvider editing setChange={setChange} navigate={navigate}>
      <EditMeetingForm change={change} />
    </CreateEditMeetingsProvider>
  ) : (
    <FullWidthSpinner className={classes.margin} />
  );
};

export default EditMeeting;
