import isEmpty from '../isEmpty';

/**
 *
 * @param {object} permissions - Permissions object
 * @param {string} prop - Property to validate
 * @returns {boolean} True or false depending if user has permission in proposals group
 */
const hasProposalsProp = (permissions, prop) => {
  if (!isEmpty(permissions) && permissions.proposals && permissions.proposals[prop]) {
    return true;
  }

  return false;
};

export default hasProposalsProp;
