import React, { useMemo } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import isEmpty from '../../../../../utils/isEmpty';
import Body2 from '../../../../common/typographys/Body2';
import Body1 from '../../../../common/typographys/Body1';
import CardBlock from '../../../../common/forms/CardBlock';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  nameType: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  type: {
    marginLeft: '5px',
  },
  groupName: {
    marginBottom: '10px',
  },
  margin: {
    marginTop: '2px',
  },
}));

const EntryDeclaration = ({ declaration }) => {
  const classes = useStyles();
  const { name, groupName, type } = declaration;
  const hasGroupName = useMemo(() => !isEmpty(groupName), [groupName]);

  return (
    <>
      <Box className={clsx(classes.nameType, { [classes.groupName]: !hasGroupName })}>
        <Body1 oneLine>{name}</Body1>
        <Body2 secondary className={classes.type}>
          {type === 'oral' ? 'Oral' : 'Ficheiro'}
        </Body2>
      </Box>
      {hasGroupName && (
        <Body2 className={clsx(classes.groupName, classes.margin)}>{groupName}</Body2>
      )}
    </>
  );
};

EntryDeclaration.propTypes = {
  declaration: PropTypes.object.isRequired,
};

const UsersDeclaration = ({ users, declaration, isGroup }) => {
  const declarations = isGroup
    ? declaration.reduce((acc, cur) => {
        const groupObj = users.find(
          x => x && cur && x.id && cur.groupId && x.id.toString() === cur.groupId.toString()
        );
        const userObj =
          (groupObj &&
            groupObj.users.find(
              x => x && cur && x.id && cur.id && x.id.toString() === cur.id.toString()
            )) ||
          null;
        if (!isEmpty(userObj)) {
          acc.push({ ...cur, ...userObj, groupName: groupObj.name });
        }
        return acc;
      }, [])
    : declaration.reduce((acc, cur) => {
        const userObj = users.find(
          x => x && cur && x.id && cur.id && x.id.toString() === cur.id.toString()
        );
        if (!isEmpty(userObj)) {
          acc.push({ ...cur, ...userObj });
        }
        return acc;
      }, []);

  return (
    <>
      <Box height="18px" />
      <CardBlock label="Declarações de voto requeridas">
        {declarations.map(entry => (
          <EntryDeclaration key={entry.id} declaration={entry} />
        ))}
      </CardBlock>
    </>
  );
};

UsersDeclaration.propTypes = {
  users: PropTypes.array.isRequired,
  declaration: PropTypes.array.isRequired,
  isGroup: PropTypes.bool.isRequired,
};

export default React.memo(UsersDeclaration);
