import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import Subtitle1 from '../../../../common/typographys/Subtitle1';
import H6 from '../../../../common/typographys/H6';
import PropTypes from 'prop-types';
import isEmpty from '../../../../../utils/isEmpty';
import PointDescription from './PointDescription';

const useStyles = makeStyles(() => ({
  description: {
    marginTop: '10px',
  },
  proposalName: {
    marginBottom: '4px',
  },
}));

const ProposalTitleAndBody = ({ isPoint, info }) => {
  const classes = useStyles();

  const label = useMemo(() => {
    if (isPoint && info && isEmpty(info.type)) {
      if (info && info.user && info.user.name) {
        return `Ponto de discussão submetido por ${info.user.name}`;
      }

      return 'Ponto de discussão';
    }

    if (info && !isEmpty(info.type)) {
      return 'Discussão do período';
    }

    return '';
  }, [isPoint, info]);

  return (
    <>
      <H6 className={classes.proposalName}>{info.name}</H6>
      {(isPoint && info.default && <Subtitle1>Ponto de discussão predefinido.</Subtitle1>) || ''}
      {!info.default && <Subtitle1>{label}</Subtitle1>}
      {!info.default && isPoint && <PointDescription description={info.description} />}
    </>
  );
};

ProposalTitleAndBody.defaultProps = {
  isPoint: false,
  info: {},
};

ProposalTitleAndBody.propTypes = {
  isPoint: PropTypes.bool,
  info: PropTypes.object,
};

export default React.memo(ProposalTitleAndBody);
