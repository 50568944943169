import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import H4 from '../../../common/typographys/H4';
// import H5 from '../../../common/typographys/H5';

const useStyles = makeStyles(theme => ({
  mainBox: {
    backgroundColor: theme.palette.primary[100],
    padding: '30px',
  },
  maxHeight: {
    lineHeight: '1.21em',
    display: 'flex',
    WebkitLineClamp: 4,
    // eslint-disable-next-line no-dupe-keys
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  description: {
    backgroundColor: theme.palette.neutrals[1],
    boxShadow: '0 20px 20px -10px rgba(204, 216, 214, 0.7)',
    padding: '30px 40px',
  },
}));

const PlateiaTitleDescription = ({ title /* , description */ }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.mainBox}>
        <H4>{title}</H4>
        {/* {description && description.length > 0 && (
          <>
            <Box height="20px" />
            <Box className={classes.maxHeight}>
              <H5>{description}</H5>
            </Box>
          </>
        )} */}
      </Box>
    </>
  );
};

PlateiaTitleDescription.defaultProps = {
  // description: null,
};

PlateiaTitleDescription.propTypes = {
  title: PropTypes.string.isRequired,
  // description: PropTypes.string,
};

export default React.memo(PlateiaTitleDescription);
