import React, { useContext, useEffect, useMemo, useCallback } from 'react';
import ConfigList from '../../pieces/ConfigList';
import InfoSection from '../../pieces/InfoSection';
import isEmpty from '../../../../utils/isEmpty';
import { ConfigStateContext, ConfigDispatchContext } from '../../MenuConfigProvider';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTypologies, removeTypology } from '../../../../store/actions/typologiesActions';
import CreateEditTypologyProvider from './CreateEditTypologyProvider';
import AddNewTypology from './AddNewTypology';
import { getGroupsWithUsers } from '../../../../store/actions/groupsActions';
import { getUsers } from '../../../../store/actions/usersActions';
import SeeEditTypology from './SeeEditTypology';
import { getLocals } from '../../../../store/actions/localActions';

const Typologies = () => {
  const { typologies = [] } = useSelector(state => state.typologies);
  const { permissions } = useSelector(state => state.auth.user);
  const { activeItem, isAddNew, deleting } = useContext(ConfigStateContext);
  const dispatchConfig = useContext(ConfigDispatchContext);
  const dispatchRedux = useDispatch();

  useEffect(() => {
    dispatchRedux(getGroupsWithUsers());
    dispatchRedux(getUsers());
    dispatchRedux(getAllTypologies());
    dispatchRedux(getLocals());
  }, [dispatchRedux]);

  const hasPagination = useMemo(() => typologies.length > 10, [typologies]);
  const pageObj = useMemo(() => ({ isItemTypology: true }), []);

  const handleDelete = useCallback(
    e => {
      e.preventDefault();
      dispatchRedux(
        removeTypology(activeItem.id, () =>
          dispatchConfig({
            type: 'CANCEL_ADD_NEW',
          })
        )
      );
    },
    [dispatchRedux, activeItem, dispatchConfig]
  );

  return (
    <>
      <ConfigList
        items={typologies}
        pageObj={pageObj}
        labelButton="Nova tipologia"
        noItemMessage="Nenhuma tipologia adicionada."
        msgDelete={`Tem a certeza que deseja excluir a tipologia${activeItem && activeItem.name ? `, ${activeItem.name}` : ''}?`}
        labelSubmit="Excluir"
        canDelete={Boolean(permissions && permissions.typologies && permissions.typologies.delete)}
        canAddNew={permissions && permissions.typologies && permissions.typologies.create}
        handleDelete={handleDelete}
      />
      <InfoSection hasPagination={hasPagination}>
        <CreateEditTypologyProvider items={typologies}>
          {!isEmpty(activeItem) && !deleting && <SeeEditTypology info={activeItem} />}
          {isAddNew && <AddNewTypology />}
        </CreateEditTypologyProvider>
      </InfoSection>
    </>
  );
};

export default Typologies;
