import { useMemo, useState } from 'react';

const usePagination = (perPage = 10, data) => {
  const [page, setPage] = useState(0);
  const [showPagination, setShowPagination] = useState(false);

  const itemShown = useMemo(() => {
    if (data.length > perPage) {
      const begin = page * perPage;
      const end = page * perPage + perPage;
      setShowPagination(true);

      return data.slice(begin, end);
    }
    setShowPagination(false);

    return data;
  }, [page, perPage, data]);

  return [showPagination, itemShown, page, setPage];
};

export default usePagination;
