import React, { useEffect, useReducer, useCallback, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from '../../../../utils/isEmpty';
import { clearErrors } from '../../../../store/actions/authActions';
import { createTheme, updateTheme } from '../../../../store/actions/themesActions';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import { ConfigStateContext } from '../../MenuConfigProvider';

export const ThemeDispatchContext = React.createContext();
export const ThemeStateContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'SET_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    case 'CLEAR_STATE':
      return {
        ...state,
        name: '',
        id: '',
        isPublic: false,
        errors: {},
      };
    default:
      return state;
  }
};

const CreateEditThemeProvider = ({ children, items }) => {
  const errorsReducer = useSelector(state => state.errors);
  const { loading } = useSelector(state => state.themes);
  const dispatchRedux = useDispatch();
  const { editing } = useContext(ConfigStateContext);
  const [state, dispatch] = useReducer(reducer, {
    name: '',
    id: '',
    isPublic: false,
    items: [],
    errors: {},
  });
  const [sameName, setSameName] = useState('');

  const { name, id, isPublic, errors } = state;

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: 'items',
        value: items,
      },
    });
  }, [items]);

  useEffect(() => {
    if (errorsReducer.name) {
      dispatch({
        type: 'SET_ERROR',
        payload: { name: 'Já existe um tema com este nome.' },
      });
      dispatchRedux(clearErrors());
      setSameName(name);
    }
  }, [errorsReducer, name, dispatchRedux]);

  useEffect(() => {
    if (!isEmpty(name) && !isEmpty(errors.name) && name !== sameName) {
      dispatch({
        type: 'SET_ERROR',
        payload: { name: '' },
      });
    }
  }, [name, errors.name, sameName]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      const localErrors = {};

      if (isEmpty(name)) {
        localErrors.name = 'Insira um nome válido para o tema.';
      }

      if (checkEmptyObject(localErrors).length > 0) {
        return dispatch({
          type: 'SET_ERROR',
          payload: localErrors,
        });
      }

      const infoTheme = {
        name,
        isPublic,
      };

      if (editing) {
        return dispatchRedux(updateTheme(id, infoTheme));
      }

      return dispatchRedux(createTheme(infoTheme));
    },
    [dispatchRedux, editing, id, isPublic, name]
  );

  const contextState = useMemo(() => ({ state, handleSubmit, loading }), [state, handleSubmit, loading]);

  return (
    <ThemeDispatchContext.Provider value={dispatch}>
      <ThemeStateContext.Provider value={contextState}>{children}</ThemeStateContext.Provider>
    </ThemeDispatchContext.Provider>
  );
};

CreateEditThemeProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  items: PropTypes.array.isRequired,
};

export default CreateEditThemeProvider;
