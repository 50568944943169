import React from 'react';
import { makeStyles, Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    '& :not(:first-child)': {
      marginLeft: '24px',
    },
  },
  common: {
    backgroundColor: theme.palette.primary[500],
    borderRadius: '50%',
  },
  actual: {
    width: '24px',
    height: '24px',
    backgroundColor: theme.palette.primary.A400,
  },
  dif1: {
    width: '20px',
    height: '20px',
  },
  dif2: {
    width: '18px',
    height: '18px',
  },
  otherDif: {
    width: '16px',
    height: '16px',
  },
}));

const BoxSlider = ({ curIndex, length }) => {
  const classes = useStyles();
  const aux = new Array(length).fill('aux');

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box height="20px" />
        <Box className={classes.main}>
          {aux.map((_, idx) => (
            <Box
              key={Math.random()}
              className={clsx(classes.common, {
                [classes.actual]: idx === curIndex,
                [classes.dif1]: Math.abs(curIndex - idx) === 1,
                [classes.dif2]: Math.abs(curIndex - idx) === 2,
                [classes.otherDif]:
                  Math.abs(curIndex - idx) !== 1 &&
                  Math.abs(curIndex - idx) !== 2 &&
                  idx !== curIndex,
              })}
            />
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

BoxSlider.propTypes = {
  curIndex: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
};

export default React.memo(BoxSlider);
