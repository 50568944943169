import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import LabelAndValue from '../../../../common/forms/LabelAndValue';
import Subtitle2 from '../../../../common/typographys/Subtitle2';
import addSeconds from 'date-fns/addSeconds';
import startOfToday from 'date-fns/startOfToday';
import formatDate from '../../../../../utils/formatDate';
import Subtitle1 from '../../../../common/typographys/Subtitle1';
import isEmpty from '../../../../../utils/isEmpty';

const useStyles = makeStyles(theme => ({
  label: {
    marginBottom: '8px',
  },
  time: {
    marginLeft: '10px',
  },
  matrixBox: {
    padding: '10px',
    backgroundColor: theme.palette.neutrals[2],
  },
}));

const secondsToMinutes = time => {
  const dateWithSeconds = addSeconds(startOfToday(), time);

  return formatDate(dateWithSeconds, 'HH:mm:ss');
};

const UsersTime = ({ user }) => {
  const classes = useStyles();
  const { name, time } = user;

  return (
    <>
      <Box display="flex" alignItems="center">
        <Subtitle2>{name} - </Subtitle2>
        <Subtitle1 className={classes.time}>{secondsToMinutes(time)}</Subtitle1>
      </Box>
    </>
  );
};

UsersTime.propTypes = {
  user: PropTypes.object.isRequired,
};

const PointMatrix = ({ matrix }) => {
  const classes = useStyles();

  return !isEmpty(matrix) ? (
    <>
      <Box height="15px" />
      <Box className={classes.matrixBox}>
        <LabelAndValue label="Matriz atribuída" value={matrix.name} smallMargin />
        <LabelAndValue label="Tempo máximo" value={secondsToMinutes(matrix.time)} smallMargin />
        <Subtitle2 tertiary className={classes.label}>
          Tempos atribuídos:
        </Subtitle2>
        {matrix.users.map(user => (
          <UsersTime key={user.id} user={user} />
        ))}
      </Box>
    </>
  ) : null;
};

PointMatrix.defaultProps = {
  matrix: null,
};

PointMatrix.propTypes = {
  matrix: PropTypes.object,
};

export default React.memo(PointMatrix);
