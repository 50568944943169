import React, { useContext, useEffect, useMemo, useCallback } from 'react';
import ConfigList from '../../pieces/ConfigList';
import InfoSection from '../../pieces/InfoSection';
import isEmpty from '../../../../utils/isEmpty';
import { ConfigStateContext, ConfigDispatchContext } from '../../MenuConfigProvider';
import { useDispatch, useSelector } from 'react-redux';
import { removeProfile, getProfiles } from '../../../../store/actions/profilesActions';
import SeeEditProfile from './SeeEditProfile';
import CreateEditProfileProvider from './CreateEditProfileProvider';
import AddNewProfile from './AddNewProfile';

const Profiles = () => {
  const { profiles } = useSelector(state => state.profiles);
  const { permissions } = useSelector(state => state.auth.user);
  const { activeItem, isAddNew, deleting } = useContext(ConfigStateContext);
  const dispatchConfig = useContext(ConfigDispatchContext);
  const dispatchRedux = useDispatch();

  useEffect(() => {
    dispatchRedux(getProfiles());
  }, [dispatchRedux]);

  const hasPagination = useMemo(() => profiles.length > 10, [profiles]);
  const pageObj = useMemo(() => ({ isItemProfile: true }), []);

  const handleDelete = useCallback(
    e => {
      e.preventDefault();
      dispatchRedux(
        removeProfile(activeItem.id, () =>
          dispatchConfig({
            type: 'CANCEL_ADD_NEW',
          })
        )
      );
    },
    [dispatchRedux, activeItem, dispatchConfig]
  );

  return (
    <>
      <ConfigList
        items={profiles}
        pageObj={pageObj}
        labelButton="Criar novo perfil"
        noItemMessage="Nenhum perfil adicionado."
        msgDelete={`Tem a certeza que deseja excluir o perfil${activeItem && activeItem.name ? `, ${activeItem.name}` : ''}?`}
        labelSubmit="Excluir"
        canDelete={Boolean(permissions && permissions.others && permissions.others.profiles)}
        canAddNew={permissions && permissions.others && permissions.others.profiles}
        handleDelete={handleDelete}
      />
      <InfoSection hasPagination={hasPagination}>
        <CreateEditProfileProvider items={profiles}>
          {!isEmpty(activeItem) && !deleting && <SeeEditProfile info={activeItem} />}
          {isAddNew && <AddNewProfile />}
        </CreateEditProfileProvider>
      </InfoSection>
    </>
  );
};

export default Profiles;
