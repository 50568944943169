import React, { useCallback, useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { TypologyDispatchContext, TypologyFuncsContext } from '../CreateEditTypologyProvider';
import GroupMemberList from './GroupMemberList';

const useStyles = makeStyles(() => ({
  label: {
    marginTop: '30px',
    marginBottom: '8px',
  },
}));

// @ Retirado dos Docs do react-beautiful-dnd
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const OrganizeTypologyGroup = ({ id }) => {
  const classes = useStyles();
  const dispatch = useContext(TypologyDispatchContext);
  const { getNumUsers, getUsers } = useContext(TypologyFuncsContext);

  const num_users = getNumUsers(id);
  const users = getUsers(id);

  const onDragEnd = useCallback(
    (result, arr) => {
      if (!result.destination || result.destination.index === result.source.index) {
        return;
      }

      const sortedParticipants = reorder(arr, result.source.index, result.destination.index);

      dispatch({
        type: 'UPDATE_GROUP_USERS',
        payload: {
          id,
          value: sortedParticipants,
        },
      });
    },
    [id, dispatch]
  );

  return (
    <DragDropContext onDragEnd={e => onDragEnd(e, users)} className={classes.dragContext}>
      <Droppable droppableId="list">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <GroupMemberList users={users} num_users={num_users} />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

OrganizeTypologyGroup.propTypes = {
  id: PropTypes.number.isRequired,
};

export default React.memo(OrganizeTypologyGroup);
