const secondsToTime = time => {
  const absoluteTime = Math.abs(time);
  const hours = `${Math.floor(absoluteTime / 3600)}`.padStart(2, '0');
  const minutes = `${Math.floor((absoluteTime % 3600) / 60)}`.padStart(2, '0');
  const seconds = `${(absoluteTime % 3600) % 60}`.padStart(2, '0');
  const signal = time >= 0 ? '' : '-';

  return `${signal}${hours}:${minutes}:${seconds}`;
};

export default secondsToTime;
