/**
 *
 * @description Converts slug to portuguese
 * @param {string} slug value to convert
 * @returns {string}
 */
const translateSlug = slug => {
  switch (slug) {
    case 'create':
      return 'Criar';
    case 'flows':
      return 'Fluxos de aprovação';
    case 'meetings':
      return 'Reuniões';
    case 'edit':
      return 'Editar';
    case 'delete':
      return 'Eliminar';
    case 'invite':
      return 'Convidar';
    case 'show_all':
      return 'Mostrar tudo';
    case 'secretary':
      return 'Secretário';
    case 'moderator':
      return 'Moderador';
    case 'others':
      return 'Outras';
    case 'locals':
      return 'Locais';
    case 'profiles':
      return 'Perfis';
    case 'configs':
      return 'Configurações';
    case 'groups':
      return 'Grupos';
    case 'matrices':
      return 'Matrizes';
    case 'entities':
      return 'Entidades';
    case 'presentations':
      return 'Apresentações';
    case 'presenter_view':
      return 'Vista de apresentador';
    case 'redaction_view':
      return 'Vista de redação';
    case 'proposals':
      return 'Propostas';
    case 'decide':
      return 'Decisão';
    case 'typologies':
      return 'Tipologias';
    case 'users':
      return 'Utilizadores';
    case 'edit_permissions':
      return 'Editar permissões';
    case 'edit_status':
      return 'Editar estados';
    case 'generic_flows':
      return 'Fluxos de Aprovação';
    case 'themes':
      return 'Temas';
    case 'organic_units':
      return 'Unidades Orgânicas';
    case 'quality_vote':
      return 'Voto de qualidade';
    default:
      return 'N/D';
  }
};

export default translateSlug;
