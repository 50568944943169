import React, { useEffect, useContext, useMemo, useCallback } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import Subtitle2 from '../../common/typographys/Subtitle2';
import isEmpty from '../../../utils/isEmpty';
import { isValid, isPast, addMinutes, isAfter, isSameDay, differenceInMinutes } from 'date-fns';
import DateTimePicker from '../../common/forms/DateTimePicker';
import PropTypes from 'prop-types';
import { MeetingFuncsContext, MeetingDispatchContext } from '../CreateEditMeetingsProvider';

const useStyles = makeStyles(() => ({
  marginLabel: {
    marginBottom: '6px',
  },
}));

const StartEndDate = ({ state }) => {
  const classes = useStyles();
  const { date, endDate, errors, submitted } = state;
  const { updateField } = useContext(MeetingFuncsContext);
  const dispatch = useContext(MeetingDispatchContext);

  const dispatchError = useCallback(
    payload => {
      dispatch({
        type: 'SET_ERROR',
        payload,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (date !== null && endDate === null) {
      updateField('endDate', addMinutes(date, 90));
    } else if (date !== null && endDate !== null && isAfter(date, endDate)) {
      updateField('endDate', addMinutes(date, 90));
    } else if (date !== null && endDate !== null && !isSameDay(date, endDate) && differenceInMinutes(endDate, date) > 100) {
      updateField('endDate', addMinutes(date, 90));
    }
  }, [date, endDate, updateField]);

  useEffect(() => {
    if (submitted && isValid(date) && !isEmpty(errors.date)) {
      dispatchError({ date: '' });
    } else if (submitted && !isValid(date) && isEmpty(errors.date)) {
      dispatchError({ date: 'Preencha a data e hora da reunião.' });
    }
  }, [errors.date, date, submitted, dispatchError]);

  useEffect(() => {
    if (submitted && isValid(endDate) && !isEmpty(errors.endDate)) {
      dispatchError({ endDate: '' });
    } else if (submitted && !isValid(endDate) && isEmpty(errors.endDate)) {
      dispatchError({ endDate: 'Preencha a data e hora do fim da reunião.' });
    }
  }, [errors.endDate, endDate, submitted, dispatchError]);

  const helperTextDate = useMemo(() => {
    if (errors.date) {
      return errors.date;
    }

    if (date && !isEmpty(date) && !isValid(date)) {
      return 'Data inválida';
    }

    if (date && !isEmpty(date) && isPast(date)) {
      return 'A data escolhida não pode ser inferior ao momento atual';
    }

    return null;
  }, [errors.date, date]);

  const helperTextEndDate = useMemo(() => {
    if (errors.endDate) {
      return errors.endDate;
    }

    if (endDate && !isEmpty(endDate) && !isValid(endDate)) {
      return 'Data inválida';
    }

    if (endDate && !isEmpty(endDate) && isPast(endDate)) {
      return 'A data escolhida não pode ser inferior ao momento atual';
    }

    return null;
  }, [errors.endDate, endDate]);

  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <Subtitle2 className={classes.marginLabel}>Data e hora de início</Subtitle2>
        <DateTimePicker
          name="date"
          value={date}
          onChange={val => updateField('date', val)}
          error={!isEmpty(helperTextDate)}
          helperText={helperTextDate}
          fullWidth
        />
      </Grid>
      <Grid item md={6}>
        <Subtitle2 className={classes.marginLabel}>Data e hora final</Subtitle2>
        <DateTimePicker
          name="endDate"
          value={endDate}
          onChange={val => updateField('endDate', val)}
          error={!isEmpty(helperTextEndDate)}
          helperText={helperTextEndDate}
          openTo="hours"
          minDate={date ? addMinutes(date, 5) : new Date()}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

StartEndDate.defaultProps = {
  state: {},
};

StartEndDate.propTypes = {
  state: PropTypes.object,
};

export default React.memo(StartEndDate);
