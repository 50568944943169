/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useMemo, useContext } from 'react';
import { Grid, makeStyles, Paper, Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from '../../../utils/isEmpty';
import {
  setActualReunion,
  updateReunion,
  updateProposalVotes,
  updateReunionPointInfo,
  createUserDeclaration,
  updatePublicDiscussionTimer,
  updateBeforeDayOrderTimer,
  updatePointDiscussionTimer,
} from '../../../store/actions/reunionsActions';
import Body2 from '../../common/typographys/Body2';
import Title from './pieces/activeMeeting/Title';
import ThemesAndPoints from './pieces/activeMeeting/ThemesAndPoints';
import ActiveProposal from './pieces/activeMeeting/ActiveProposal';
import NoActiveProposal from './pieces/activeMeeting/NoActiveProposal';
import ViewPoint from './pieces/activeMeeting/ViewPoint';
import { useParams, Prompt, useHistory } from 'react-router-dom';
import DialogVote from './pieces/activeMeeting/DialogVote';
import { SocketReunion } from './SocketReunionWrapper';
import PropTypes from 'prop-types';
import DialogCheck from '../../common/dialogs/DialogCheck';
import { MeetingCommon } from './MeetingCommonWrapper';
import { removeReunion } from '../../../store/actions/socketActions';
import GroupUsersTimer from './pieces/activeMeeting/GroupUsersTimer';
import IndividualUsersTimer from './pieces/activeMeeting/IndividualUsersTimer';
import ProposalStatusInfo from './pieces/activeMeeting/ProposalStatusInfo';
import PublicVoteDisplay from './pieces/activeMeeting/PublicVoteDisplay';
import PublicDiscussionTimer from './pieces/activeMeeting/PublicDiscussionTimer';
import GroupInterventions from './pieces/activeMeeting/GroupInterventions';
import IndividualInterventions from './pieces/activeMeeting/IndividualInterventions';
import InputField from '../../common/forms/InputField';
import TimeTracker from './pieces/activeMeeting/TimeTracker';

const useStyles = makeStyles(() => ({
  marginContainers: {
    marginTop: '30px',
  },
  marginHeader: {
    marginBottom: '10px',
  },
  voting: {
    opacity: '0.4',
    pointerEvents: 'none',
  },
}));

const periods = [
  { type: 'public-discussion', name: 'Intervenção do público' },
  { type: 'before-day-order', name: 'Antes da ordem do dia' },
];

const MeetingParticipant = ({ change }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();
  const { id: userId } = useSelector(state => state.auth.user);
  const { reunion } = useSelector(state => state.reunions);
  const publicDiscussionTimer = useSelector(
    state => state.reunions?.reunion?.timers?.publicDiscussion || {}
  );
  const beforeDayOrderTimer = useSelector(
    state => state.reunions?.reunion?.timers?.beforeDayOrder || {}
  );
  const discussionPointsTimers = useSelector(
    state => state.reunions?.reunion?.timers?.points || {}
  );
  const {
    users = [],
    participants,
    is_group,
    is_public: isPublic,
    speakers,
    infoSpeaking,
    currentPublic = 0,
    interventions = 0,
    speakersInfo,
    reunionActiveInfo,
  } = reunion;

  const [active, setActive] = useState({});
  const [activeInfo, setActiveInfo] = useState({});
  const [openVote, setOpenVote] = useState(false);
  const [vote, setVote] = useState('');
  const [declaration, setDeclaration] = useState('');
  const [votes, setVotes] = useState([]);
  const [typeVote, setTypeVote] = useState('');
  const [voting, setVoting] = useState(false);
  const { socketNsp, name, email } = useContext(SocketReunion);
  const {
    handleInfo,
    changePage,
    confirmChange,
    cancelLeaving,
    selected,
    openWarning,
    reunionEnd,
    setReunionEnd,
  } = useContext(MeetingCommon);
  const isSelectedPoint = useMemo(
    () =>
      (selected.idxTheme === null && isEmpty(selected.type)) ||
      (selected.idxTheme !== null && selected.idxProposal !== null),
    [selected]
  );
  const isProposal = useMemo(() => activeInfo.idxProposal !== null, [activeInfo]);
  const activeHasStatus = useMemo(
    () =>
      active && active.meeting_point_status && active.meeting_point_status.point_status !== null,
    [active]
  );

  const thisParticipant = useMemo(() => {
    const objParticipant =
      participants && participants.find(x => x && x.id && x.id.toString() === userId.toString());

    return objParticipant || {};
  }, [participants, userId]);

  const participantsInfo = useMemo(() => {
    if (reunion && reunion.participants && reunion.speakers && reunion.users) {
      const { board = {} } = reunion;
      const { secretary = [], moderator = [] } = board;
      const flattenBoard = [...secretary, ...moderator];
      const isGroup = Boolean(reunion.is_group);
      const meetingParticipants = reunion.users.reduce((acc, cur) => {
        if (isGroup) {
          acc.push(
            ...cur.users.slice(0, cur.num_users).map(user => ({ ...user, group_id: cur.id }))
          );
        } else {
          acc.push({ ...cur, group_id: null });
        }

        return acc;
      }, []);

      return reunion.participants.reduce(
        (acc, cur) => {
          if (flattenBoard.find(x => x.id.toString() === cur.id.toString()) === undefined) {
            const objSpeaker = meetingParticipants.find(
              x => x && x.id && x.id.toString() === cur.id.toString()
            );
            // @ Não é secretário nem moderador
            // TODO: validar se não está bloqueado
            if (cur.checkIn && objSpeaker !== undefined) {
              acc.voters.push({ ...objSpeaker, ...cur });
            }
          }
          return acc;
        },
        { voters: [] }
      );
    }
    return [];
  }, [reunion]);

  // const isVoteBlocked = useMemo(() => {
  //   if (active.blockUsers) {
  //     if (active.blockedUsers.find(x => x.email === email) === undefined && reunion.participants.find(x => x.email === email) !== undefined) {
  //       // @ Este utilizador não está bloqueado e está na lista de participantes
  //       return false;
  //     }
  //   } else if (reunion.participants.find(x => x.email === email) !== undefined) {
  //     return false;
  //   }

  //   return true;
  // }, [active, reunion.participants, email]);

  useEffect(() => {
    if (!isEmpty(reunion.activeInfo) && reunion.sentSocket) {
      setActiveInfo(reunion.activeInfo);
    }
  }, [reunion.sentSocket, reunion.activeInfo]);

  useEffect(() => {
    if (!isEmpty(activeInfo)) {
      const { idxProposal, idxTheme, type } = activeInfo;
      if (idxProposal !== null && idxTheme !== null) {
        // @ É uma proposta inserida num tema
        const result = reunion.themes[idxTheme].points[idxProposal] || null;
        setActive(result);
      } else if (idxProposal !== null && idxTheme === null) {
        // @ É uma proposa sem tema
        const result = reunion.points[idxProposal] || null;
        setActive(result);
      } else if (idxProposal === null && idxTheme !== null) {
        // @ Tema sem pontos
        const result = reunion.themes[idxTheme] || null;
        setActive(result);
      } else if (idxProposal === null && idxTheme === null) {
        // @ É um período
        const period = periods.find(x => x.type === type) || {};
        setActive(period);
      }
    }
  }, [activeInfo, reunion.themes, reunion.points]);

  useEffect(() => {
    // socketNsp.on('begin_reunion', info => {
    //   dispatch(setActualReunion(JSON.parse(info)));
    // });

    socketNsp.on('reunion_details', info => {
      dispatch(updateReunion(JSON.parse(info)));
    });

    socketNsp.on('reunion_speakers', info => {
      const parsed = JSON.parse(info);
      dispatch(updateReunion({ speakers: parsed }));
    });

    socketNsp.on('current_public_time', info => {
      const parsed = JSON.parse(info);
      dispatch(updateReunion({ currentPublic: parsed }));
    });

    socketNsp.on('current_interventions_time', info => {
      const parsed = JSON.parse(info);
      dispatch(updateReunion({ interventions: parsed }));
    });

    socketNsp.on('reunion_info_speaking', info => {
      const parsed = JSON.parse(info);
      dispatch(updateReunion({ infoSpeaking: parsed }));
    });

    socketNsp.on('reunion_speakers_info', info => {
      const parsed = JSON.parse(info);
      dispatch(updateReunion({ speakersInfo: parsed }));
    });

    socketNsp.on('secretary_update', info => {
      const updatedBoard = JSON.parse(info);
      dispatch(updateReunion(updatedBoard));
    });

    socketNsp.on('update_meeting_point_status', info => {
      const parsed = JSON.parse(info);
      dispatch(updateReunionPointInfo(parsed));
      setVoting(false);
      setOpenVote(false);
      setActive(c => ({ ...c, meeting_point_status: parsed.meeting_point_status }));
    });

    socketNsp.on('update_votes', info => {
      const parsed = JSON.parse(info);
      setVotes(parsed);
    });

    socketNsp.on('update_active', info => {
      const parsedInfo = JSON.parse(info);
      if (JSON.stringify(activeInfo) === info) return;
      setActiveInfo(parsedInfo);
    });

    socketNsp.on('vote_canceled', () => {
      setVoting(false);
      setOpenVote(false);
    });

    socketNsp.on('start_vote', info => {
      const { type } = JSON.parse(info);
      setTypeVote(type);

      if (type !== 'secret' && thisParticipant.cc) {
        setOpenVote(true);
      }

      setVoting(true);
    });

    socketNsp.on('final_votes', info => {
      dispatch(updateProposalVotes(JSON.parse(info)));
      setOpenVote(false);
      setVoting(false);
      setVotes([]);
    });

    socketNsp.on('vote', info => {
      const parsed = JSON.parse(info);
      const { userId: userIdVoted, groupId, vote: userVote } = parsed;
      setVotes(c => [
        ...c.filter(x => x.id.toString() !== userIdVoted.toString()),
        { id: userIdVoted, group_id: groupId, vote: userVote },
      ]);
    });

    socketNsp.on('reunion_finished', () => {
      setReunionEnd(true);
      dispatch(removeReunion(id));
      return history.push(`/reunioes/consultar/${id}`);
    });

    socketNsp.on('list_presences', info => {
      dispatch(updateReunion(JSON.parse(info)));
    });

    socketNsp.on('public_discussion_timer_updated', updatedTimerPayload => {
      const timerState = JSON.parse(updatedTimerPayload).publicDiscussion;

      dispatch(updatePublicDiscussionTimer(timerState));
    });

    socketNsp.on('before_day_order_timer_updated', updatedTimerPayload => {
      const timerState = JSON.parse(updatedTimerPayload).beforeDayOrder;

      dispatch(updateBeforeDayOrderTimer(timerState));
    });

    socketNsp.on('point_discussion_timer_updated', updatedTimerPayload => {
      const { pointId, timerType, timerState } = JSON.parse(updatedTimerPayload);

      dispatch(updatePointDiscussionTimer(pointId, timerType, timerState));
    });

    socketNsp.emit('join', JSON.stringify({ id, name, email, userId, participant: true }));

    return () => {
      socketNsp.emit('leave', JSON.stringify({ id, email }));
      socketNsp.removeAllListeners();
      dispatch(setActualReunion({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      isEmpty(speakersInfo) ||
      (!isEmpty(speakersInfo) &&
        !isEmpty(active) &&
        speakersInfo.idxTheme !== undefined &&
        speakersInfo.idxTheme !== activeInfo.idxTheme &&
        speakersInfo.idxProposal !== undefined &&
        speakersInfo.idxProposal !== activeInfo.idxProposal) ||
      (!isEmpty(activeInfo) &&
        (activeInfo.idxTheme !== undefined || activeInfo.idxProposal !== undefined) &&
        JSON.stringify(activeInfo) !== JSON.stringify(reunionActiveInfo))
    ) {
      let speakersValue;

      if (is_group) {
        speakersValue = users.reduce((acc, cur) => {
          if (cur.no_limit || isEmpty(active.matrix)) {
            // @ Sem restrição de tempo OU não existe matrix de tempo
            acc.push({
              ...cur,
              users: cur.users.slice(0, cur.num_users).map(user => ({ ...user, current: 0 })),
              current: 0,
              others: cur.others || 0,
            });
          } else if (!cur.no_limit && !isEmpty(active.matrix)) {
            // @ Restrição de tempo
            if (active.matrix.is_group) {
              const userInMatrix = active.matrix.users.find(x => x.id === cur.id);
              acc.push({
                ...cur,
                ...userInMatrix,
                current: 0,
                others: cur.others || 0,
                users: cur.users.slice(0, cur.num_users).map(user => ({ ...user, current: 0 })),
              });
            } else {
              cur.users.forEach(curUser => {
                const userInMatrix = active.matrix.users.find(x => x.id === curUser.id);

                if (userInMatrix) {
                  acc.push({
                    ...curUser,
                    ...userInMatrix,
                    current: 0,
                  });
                } else {
                  acc.push({
                    ...curUser,
                    current: 0,
                  });
                }
              });
            }
          }

          return acc;
        }, []);
      } else {
        speakersValue = users.map(user => {
          if (isEmpty(active.matrix)) return { ...user, current: 0 };

          const userInMatrix = active.matrix.users.find(x => x.id === user.id);

          return { ...user, ...userInMatrix, current: 0 };
        });
      }

      dispatch(
        updateReunion({
          speakers: speakersValue,
          speakersInfo: {
            idxTheme: active.idxTheme,
            idxProposal: active.idxProposal,
          },
        })
      );
    }
  }, [active, activeInfo, dispatch, users, speakersInfo, reunionActiveInfo, is_group]);

  const submitVote = infoActive => e => {
    e.preventDefault();
    const { id: idPoint } = infoActive;

    setOpenVote(false);

    if (!isEmpty(declaration)) {
      const objTypeDeliberation = {
        meetingId: id,
        id: userId,
        type: declaration,
        activeInfo,
        file: null,
        text: '',
        groupId: thisParticipant.groupId || null,
      };

      dispatch(createUserDeclaration(id, idPoint, userId, { type: declaration }, () => null));

      socketNsp.emit('participant_declaration', JSON.stringify(objTypeDeliberation));
    }

    const infoVote = {
      vote,
      id,
      groupId: thisParticipant.groupId || null,
      userId,
    };

    socketNsp.emit('proposal_vote', JSON.stringify(infoVote));

    setVote('');
    setDeclaration('');
  };

  const getCurrentSecretary = () => {
    if (!reunion?.board?.secretary || !Array.isArray(reunion.board.secretary)) return null;

    const currentMainSecretary =
      reunion.board.secretary.find(secretary => !!secretary.main) || reunion.board.secretary[0];

    if (currentMainSecretary) {
      return currentMainSecretary.name;
    }

    return null;
  };

  const getActiveDiscussionPointTimerId = () =>
    active.proposal_id !== null ? `proposal-${active.id}` : `point-${active.id}`;

  const getActiveDiscussionPointTimer = type => {
    const timerId = getActiveDiscussionPointTimerId();

    return discussionPointsTimers[timerId][type];
  };

  return (
    <>
      {!reunionEnd && <Prompt when={change} message={changePage} />}
      <Title title={reunion.name} reunionEnd={reunionEnd} />
      <Grid container spacing={3}>
        <Grid item xs={7} />
        <Grid item xs={4}>
          <InputField label="Secretário atual" value={getCurrentSecretary()} disabled />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.marginContainers}>
        <Grid item xs={1} />
        <ThemesAndPoints
          voting={voting}
          themes={reunion.themes}
          points={reunion.points}
          activeInfo={activeInfo}
          handleInfo={handleInfo}
          isPublic={Boolean(isPublic)}
        />
        <Grid item xs={4}>
          {!isEmpty(active.type) &&
            (active.type === 'public-discussion' || active.type === 'before-day-order') && (
              <>
                <Body2 secondary className={classes.marginHeader}>
                  {!isEmpty(active.type)
                    ? active.type === 'before-day-order'
                      ? 'Tempo do período ativo'
                      : 'Tempo de fala do interveniente'
                    : 'Tempo da proposta ativa'}
                </Body2>
                {active.type === 'public-discussion' && (
                  <TimeTracker timer={publicDiscussionTimer} />
                )}
                {active.type === 'before-day-order' && <TimeTracker timer={beforeDayOrderTimer} />}
              </>
            )}
          {!isEmpty(active) &&
            active.type !== 'public-discussion' &&
            active.type !== 'before-day-order' && (
              <>
                <Body2 secondary className={classes.marginHeader}>
                  Período de apresentação da proposta
                </Body2>
                <TimeTracker timer={getActiveDiscussionPointTimer('presentation')} />
                <Body2 secondary className={classes.marginHeader}>
                  Período de protestos / defesa de honra / reações
                </Body2>
                <TimeTracker timer={getActiveDiscussionPointTimer('protests')} />
              </>
            )}
          <Body2 secondary className={classes.marginHeader}>
            {!isEmpty(active.type) ? 'Período ativo' : 'Proposta ativa'}
          </Body2>
          {!isEmpty(active) ? (
            <ActiveProposal
              active={active}
              isProposal={isProposal}
              voting={voting}
              activeInfo={activeInfo}
              reunionEnd={reunionEnd}
              activeHasStatus={activeHasStatus}
            />
          ) : (
            <NoActiveProposal />
          )}
          {!isEmpty(selected) && (
            <>
              <Box height="30px" />
              <ViewPoint
                selected={selected}
                handleInfo={handleInfo}
                isSelectedPoint={isSelectedPoint}
                idxProposal={selected.idx}
                users={users}
                isGroup={Boolean(is_group)}
              />
            </>
          )}
        </Grid>
        {activeInfo?.type !== 'public-discussion' && (
          <Grid item xs={3}>
            {!voting && !activeHasStatus ? (
              <>
                <Body2 secondary className={classes.marginHeader}>
                  Participante
                  {reunion && reunion.participants && reunion.participants.length > 1 ? 's' : ''}
                </Body2>
                <Paper>
                  {speakers && speakers.length > 0 && !voting && Boolean(is_group) && (
                    <>
                      {!isEmpty(activeInfo.type) && activeInfo.type === 'public-discussion' ? (
                        <PublicDiscussionTimer
                          current={currentPublic}
                          reunionEnd={false}
                          infoSpeaking={infoSpeaking}
                          activeInfo={activeInfo}
                        />
                      ) : (
                        <>
                          <GroupInterventions
                            current={interventions}
                            moderator={false}
                            reunionEnd={false}
                            infoSpeaking={infoSpeaking}
                            activeInfo={activeInfo}
                          />
                          {isEmpty(active.matrix) || active?.matrix?.is_group ? (
                            <GroupUsersTimer
                              groups={speakers}
                              participants={participants}
                              infoSpeaking={infoSpeaking}
                              hasMatrix={active && !isEmpty(active.matrix)}
                              activeInfo={activeInfo}
                              activePointId={active && active.proposal_id ? active.id : null}
                            />
                          ) : (
                            <IndividualUsersTimer
                              users={speakers}
                              participants={participants}
                              infoSpeaking={infoSpeaking}
                              hasMatrix={active && !isEmpty(active.matrix)}
                              activeInfo={activeInfo}
                              activePointId={active && active.proposal_id ? active.id : null}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                  {speakers && speakers.length > 0 && !voting && !is_group && (
                    <>
                      <IndividualInterventions
                        current={interventions}
                        moderator={false}
                        reunionEnd={false}
                        infoSpeaking={infoSpeaking}
                        activeInfo={activeInfo}
                      />
                      <IndividualUsersTimer
                        users={speakers}
                        participants={participants}
                        infoSpeaking={infoSpeaking}
                        hasMatrix={active && !isEmpty(active.matrix)}
                        activeInfo={activeInfo}
                        activePointId={active && active.proposal_id ? active.id : null}
                      />
                    </>
                  )}
                </Paper>
              </>
            ) : activeHasStatus ? (
              <>
                <Body2 secondary className={classes.marginHeader}>
                  Estado da proposta
                </Body2>
                <Paper>
                  <ProposalStatusInfo
                    pointStatus={active.meeting_point_status}
                    users={users}
                    isGroup={Boolean(is_group)}
                  />
                </Paper>
              </>
            ) : null}
            {voting && (
              <>
                {typeVote === 'secret' && (
                  <>
                    <Body2 secondary className={classes.marginHeader}>
                      Votação
                    </Body2>
                    <Body2 className={classes.secretLabel}>Esta votação é secreta.</Body2>
                  </>
                )}
                {typeVote === 'public' && (
                  <>
                    <Box className={classes.publicVoteBox}>
                      <Grid container>
                        <Grid item xs={10} className={classes.flexCenter}>
                          <Body2 secondary className={classes.marginHeader}>
                            Votação
                          </Body2>
                        </Grid>
                      </Grid>
                      <PublicVoteDisplay
                        votes={votes}
                        groups={speakers}
                        participantsInfo={participantsInfo}
                        isGroup={Boolean(is_group)}
                        hasMatrix={active && !isEmpty(active.matrix)}
                        isGroupMatrix={active?.matrix?.is_group}
                      />
                    </Box>
                  </>
                )}
              </>
            )}
          </Grid>
        )}
        <Grid item xs={1} />
      </Grid>
      <DialogVote
        open={openVote}
        handleSubmit={submitVote(active)}
        proposal={active}
        setVote={setVote}
        vote={vote}
        // TODO: hardcoded
        isVoteBlocked={false}
        declaration={declaration}
        setDeclaration={setDeclaration}
      />
      <DialogCheck
        open={openWarning}
        msg="Pretende sair da reunião?"
        handleCancel={cancelLeaving}
        handleSubmit={confirmChange}
        labelSubmit="Sair"
      />
    </>
  );
};

MeetingParticipant.propTypes = {
  change: PropTypes.bool.isRequired,
};

export default MeetingParticipant;
