import React from 'react';
import { makeStyles, Grid, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body1 from '../../../../common/typographys/Body1';
import Body2 from '../../../../common/typographys/Body2';
import { ReactComponent as AgainstVoteIcon } from '../../../../../assets/images/againstNoVote.svg';
import { ReactComponent as FavorVoteIcon } from '../../../../../assets/images/favorNoVote.svg';
import { ReactComponent as AbstentionVoteIcon } from '../../../../../assets/images/abstentionNoVote.svg';
import { ReactComponent as NoVoteIcon } from '../../../../../assets/images/noVote.svg';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  groupName: {
    padding: '10px 14px 6px 14px',
    backgroundColor: theme.palette.neutrals[1],
  },
  userName: {
    backgroundColor: theme.palette.primary[100],
    padding: '12px 10px 14px 14px',
  },
  voteBox: {
    backgroundColor: theme.palette.neutrals[1],
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '100%',
  },
  white: {
    height: '2px',
    backgroundColor: theme.palette.neutrals[1],
  },
  iceBlue: {
    height: '2px',
    backgroundColor: theme.palette.primary[50],
  },
  disabled: {
    opacity: '0.4',
    pointerEvents: 'none',
  },
  commonIcon: {
    '&:hover': {
      cursor: 'pointer',
      '& *': {
        transition: 'fill 0.2s ease',
        fill: theme.palette.neutrals[6],
      },
    },
  },
  votePopover: {
    backgroundColor: theme.palette.neutrals[1],
    padding: '10px 16px',
    borderRadius: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '130px',
  },
  popover: {
    pointerEvents: 'none',
  },
  favor: {
    '& *': {
      transition: 'fill 0.2s ease',
      fill: theme.palette.colorsPalette.TrueGreen,
    },
  },
  abstention: {
    '& *': {
      transition: 'fill 0.2s ease',
      fill: theme.palette.colorsPalette.Orange,
    },
  },
  against: {
    '& *': {
      transition: 'fill 0.2s ease',
      fill: theme.palette.colorsPalette.RustRed,
    },
  },
  noVote: {
    '& *': {
      transition: 'fill 0.2s ease',
      fill: theme.palette.colorsPalette.TrueBlue,
    },
  },
}));

const renderIndividualDisplay = (
  classes,
  participantsInfo,
  votes,
  moderator,
  clickVote,
  users,
  readonly
) => (
  <>
    {users
      .filter(x => x.in_meeting)
      .map((user, idx) => {
        const objUser = participantsInfo.voters.find(x => user && x.id === user.id) || {};
        const objUserVote = votes.find(x => x.id === user.id) || {};
        const isCheckedIn = objUser.checkIn || false;

        return (
          <React.Fragment key={user.id}>
            <Grid container className={clsx({ [classes.disabled]: !isCheckedIn || readonly })}>
              <Grid item xs={8}>
                <Box className={classes.userName}>
                  <Body2 active oneLine>
                    {user.name}
                  </Body2>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className={classes.voteBox}>
                  <FavorVoteIcon
                    className={clsx({
                      [classes.commonIcon]: moderator,
                      [classes.favor]: objUserVote.vote === 'favor',
                    })}
                    onClick={clickVote('favor', user.id)}
                  />
                  <AbstentionVoteIcon
                    className={clsx({
                      [classes.commonIcon]: moderator,
                      [classes.abstention]: objUserVote.vote === 'abstention',
                    })}
                    onClick={clickVote('abstention', user.id)}
                  />
                  <AgainstVoteIcon
                    className={clsx({
                      [classes.commonIcon]: moderator,
                      [classes.against]: objUserVote.vote === 'against',
                    })}
                    onClick={clickVote('against', user.id)}
                  />
                  <NoVoteIcon
                    className={clsx({
                      [classes.commonIcon]: moderator,
                      [classes.noVote]: objUserVote.vote === 'novote',
                    })}
                    onClick={clickVote('novote', user.id)}
                  />
                </Box>
              </Grid>
            </Grid>
            {idx !== users.length - 1 && (
              <Grid container>
                <Grid item xs={8}>
                  <Box className={classes.white} />
                </Grid>
                <Grid item xs={4}>
                  <Box className={classes.iceBlue} />
                </Grid>
              </Grid>
            )}
          </React.Fragment>
        );
      })}
  </>
);

const renderGroupDisplay = (
  classes,
  participantsInfo,
  votes,
  moderator,
  clickVote,
  groups,
  readonly
) => (
  <>
    {groups.map(group => (
      <React.Fragment key={group.id}>
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.groupName}>
              <Body1>{group.name}</Body1>
            </Box>
          </Grid>
        </Grid>
        {group.users
          .filter(x => x.in_meeting)
          .map((user, idx) => {
            const objUser = participantsInfo.voters.find(x => user && x.id === user.id) || {};
            const objUserVote = votes.find(x => x.id === user.id) || {};
            const isCheckedIn = objUser.checkIn || false;

            return (
              <React.Fragment key={user.id}>
                <Grid container className={clsx({ [classes.disabled]: !isCheckedIn || readonly })}>
                  <Grid item xs={8}>
                    <Box className={classes.userName}>
                      <Body2 active oneLine>
                        {user.name}
                      </Body2>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box className={classes.voteBox}>
                      <FavorVoteIcon
                        className={clsx({
                          [classes.commonIcon]: moderator,
                          [classes.favor]: objUserVote.vote === 'favor',
                        })}
                        onClick={clickVote('favor', user.id, group.id)}
                      />
                      <AbstentionVoteIcon
                        className={clsx({
                          [classes.commonIcon]: moderator,
                          [classes.abstention]: objUserVote.vote === 'abstention',
                        })}
                        onClick={clickVote('abstention', user.id, group.id)}
                      />
                      <AgainstVoteIcon
                        className={clsx({
                          [classes.commonIcon]: moderator,
                          [classes.against]: objUserVote.vote === 'against',
                        })}
                        onClick={clickVote('against', user.id, group.id)}
                      />
                      <NoVoteIcon
                        className={clsx({
                          [classes.commonIcon]: moderator,
                          [classes.noVote]: objUserVote.vote === 'novote',
                        })}
                        onClick={clickVote('novote', user.id, group.id)}
                      />
                    </Box>
                  </Grid>
                </Grid>
                {idx !== group.users.length - 1 && (
                  <Grid container>
                    <Grid item xs={8}>
                      <Box className={classes.white} />
                    </Grid>
                    <Grid item xs={4}>
                      <Box className={classes.iceBlue} />
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            );
          })}
      </React.Fragment>
    ))}
  </>
);

const PublicVoteDisplay = ({
  groups,
  isGroup,
  hasMatrix,
  isGroupMatrix,
  participantsInfo,
  votes,
  setVotes,
  moderator,
  readonly,
}) => {
  const classes = useStyles();

  const clickVote = (vote, idUser, idGroup) => e => {
    e.preventDefault();
    if (isGroup) {
      setVotes(c => {
        if (!c) {
          return [{ id: idUser, group_id: idGroup, vote }];
        }
        return [...c.filter(x => x.id !== idUser), { id: idUser, group_id: idGroup, vote }];
      });
    } else {
      setVotes(c => {
        if (!c) {
          return [{ id: idUser, vote }];
        }
        return [...c.filter(x => x.id !== idUser), { id: idUser, vote }];
      });
    }
  };

  if (isGroup && !hasMatrix) {
    return renderGroupDisplay(
      classes,
      participantsInfo,
      votes,
      moderator,
      clickVote,
      groups,
      readonly
    );
  }

  if (isGroup && hasMatrix) {
    if (isGroupMatrix) {
      return renderGroupDisplay(
        classes,
        participantsInfo,
        votes,
        moderator,
        clickVote,
        groups,
        readonly
      );
    }
  }

  return renderIndividualDisplay(
    classes,
    participantsInfo,
    votes,
    moderator,
    clickVote,
    groups,
    readonly
  );
};

PublicVoteDisplay.defaultProps = {
  moderator: false,
  setVotes: () => null,
  isGroup: true,
};

PublicVoteDisplay.propTypes = {
  groups: PropTypes.array.isRequired,
  participantsInfo: PropTypes.object.isRequired,
  votes: PropTypes.array.isRequired,
  setVotes: PropTypes.func,
  moderator: PropTypes.bool,
  isGroup: PropTypes.bool,
};

export default React.memo(PublicVoteDisplay);
