import React, { useEffect, useCallback } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { changeMenuSidebar } from '../../store/actions/navigationActions';
import { useDispatch, useSelector } from 'react-redux';
import H5 from '../common/typographys/H5';
import H6 from '../common/typographys/H6';
import NotificationEntry from '../common/layout/NotificationEntry';
import { useHistory } from 'react-router-dom';
import appSchemaStyles from '../common/layout/styles/appSchema';
import usePagination from '../../utils/hooks/usePagination';
import Pagination from '../common/SimplePagination';
import { getUserActions } from '../../store/actions/authActions';

const useStyles = makeStyles(() => ({
  marginContainer: {
    marginTop: '54px',
  },
  header: {
    marginBottom: '20px',
  },
}));

const PendingActions = () => {
  const classes = useStyles();
  const notificationsClasses = appSchemaStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { actions } = useSelector(state => state.auth);
  const [showPagination, itemShown, page, setPage] = usePagination(10, actions);

  useEffect(() => {
    dispatch(changeMenuSidebar('notificacoes', 'Ações pendentes'));
    dispatch(getUserActions());
  }, [dispatch]);

  const clickUrl = useCallback(
    url => e => {
      e.preventDefault();
      if (url) {
        history.push(url);
      }
    },
    [history]
  );

  return (
    <Grid container justify="center" spacing={3} className={classes.marginContainer}>
      <Grid item xs={11} md={9} lg={7}>
        <H5 className={classes.header}>Ações pendentes</H5>
        {actions.length === 0 ? (
          <H6>Não existem ações pendentes.</H6>
        ) : (
          itemShown.map(item => (
            <NotificationEntry
              key={item.url}
              classes={notificationsClasses}
              onClick={clickUrl}
              {...item}
              margin
            />
          ))
        )}
        {showPagination && (
          <Pagination
            total={actions.length}
            onChangePage={(_, pageNum) => {
              setPage(pageNum);
            }}
            rowsPerPage={10}
            page={page}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(PendingActions);
