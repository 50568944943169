import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import H5 from '../../typographys/H5';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}));

const HeaderLabel = ({ view }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.marginBottom}>
      <H5>{view !== 'cronologia' ? 'Calendário' : 'Cronologia'}</H5>
    </Grid>
  );
};

HeaderLabel.propTypes = {
  view: PropTypes.string.isRequired,
};

export default React.memo(HeaderLabel);
