import React, { useState, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import BoxCronologia from './pieces/BoxCronologia';
import EventInfo from './pieces/EventInfo';
import transformEvents from '../../../utils/transformEvents';
import DialogEventInfo from '../calendar/pieces/DialogEventInfo';
import useBooleanToggle from '../../../utils/hooks/useBooleanToggle';
import getEventFromArray from '../../../utils/getItemFromArray';
import H6 from '../typographys/H6';

const Cronologia = ({ events }) => {
  const [open, toggleModal] = useBooleanToggle();
  const [selected, setSelected] = useState({});

  let cronologias = [];
  if (events.length > 0) {
    cronologias = events.reduce(transformEvents, {});
  }

  const cronologiasIds = Object.keys(cronologias);

  const handleEventClick = useCallback(
    event => e => {
      e.preventDefault();
      const eventInfo = event;
      eventInfo.extendedProps = {};
      eventInfo.extendedProps.info = event.info;
      setSelected(eventInfo);
      toggleModal();
    },
    [toggleModal]
  );

  return cronologiasIds.length > 0 ? (
    <>
      <BoxCronologia />
      {cronologiasIds.map(id => (
        <Grid key={id} container>
          <EventInfo event={getEventFromArray(cronologias[id].events.past, 3)} onClick={handleEventClick} />
          <EventInfo event={getEventFromArray(cronologias[id].events.past, 2)} onClick={handleEventClick} />
          <EventInfo event={getEventFromArray(cronologias[id].events.past, 1)} onClick={handleEventClick} />
          <EventInfo event={cronologias[id].events.next} onClick={handleEventClick} next />
          <EventInfo event={getEventFromArray(cronologias[id].events.future, 1)} onClick={handleEventClick} future />
          <EventInfo event={getEventFromArray(cronologias[id].events.future, 2)} onClick={handleEventClick} future />
          <EventInfo event={getEventFromArray(cronologias[id].events.future, 3)} onClick={handleEventClick} future last />
        </Grid>
      ))}
      <DialogEventInfo open={open} handleClose={toggleModal} event={selected} />
    </>
  ) : (
    <H6>Não existem reuniões para exibir na cronologia</H6>
  );
};

Cronologia.defaultProps = {
  events: [],
};

Cronologia.propTypes = {
  events: PropTypes.array,
};

export default Cronologia;
