import React from 'react';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import Subtitle1 from '../../typographys/Subtitle1';
import formatDate from '../../../../utils/formatDate';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  boxDate: {
    border: `solid 1px ${theme.palette.primary[600]}`,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 0px',
  },
  iconButton: {
    padding: 0,
    color: theme.palette.primary[400],
    '&:hover': {
      color: theme.palette.primary[100],
      backgroundColor: 'unset',
    },
  },
}));

const BoxChangeDate = ({ date, view, handleDatePrev, handleDateNext }) => {
  const classes = useStyles();

  return (
    <Box className={classes.boxDate}>
      <IconButton className={classes.iconButton} onClick={handleDatePrev}>
        <KeyboardArrowLeft />
      </IconButton>
      {view === 'dayGridMonth' && <Subtitle1>{formatDate(date, "MMMM 'de' yyyy")}</Subtitle1>}
      {view === 'timeGridWeek' && (
        <Subtitle1>
          {`${formatDate(startOfWeek(date, { weekStartsOn: 1 }), "dd MMM ''yy")} - ${formatDate(
            endOfWeek(date, { weekStartsOn: 1 }),
            "dd MMM ''yy"
          )}`}
        </Subtitle1>
      )}
      {view === 'timeGridDay' && <Subtitle1>{formatDate(date, "dd 'de' MMMM 'de' yyyy")}</Subtitle1>}
      <IconButton className={classes.iconButton} onClick={handleDateNext}>
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  );
};

BoxChangeDate.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  view: PropTypes.string.isRequired,
  handleDatePrev: PropTypes.func.isRequired,
  handleDateNext: PropTypes.func.isRequired,
};

export default BoxChangeDate;
