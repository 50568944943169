import React, { useCallback, useContext } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import CardBlock from '../../common/forms/CardBlock';
import Subtitle2 from '../../common/typographys/Subtitle2';
import SelectOutlined from '../../common/forms/SelectOutlined';
import Body1 from '../../common/typographys/Body1';
import { PrepareMeetingDispatchContext } from '../PrepareMeetingProvider';

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: '8px',
  },
}));

const MeetingSecretaryModerator = ({ state }) => {
  const dispatch = useContext(PrepareMeetingDispatchContext);
  const classes = useStyles();
  const { board, availableSecretary = [] /* , availableModerator = [] */ } = state;
  const { secretary, moderator } = board;

  const addSecretary = useCallback(
    (value, userId) => {
      dispatch({
        type: value !== null ? 'ADD_SECRETARY' : 'REMOVE_SECRETARY',
        payload: {
          id: userId,
          value,
        },
      });
    },
    [dispatch]
  );

  // const addModerator = useCallback(
  //   (value, userId) => {
  //     dispatch({
  //       type: value !== null ? 'ADD_MODERATOR' : 'REMOVE_MODERATOR',
  //       payload: {
  //         id: userId,
  //         value,
  //       },
  //     });
  //   },
  //   [dispatch]
  // );

  return (
    <CardBlock label="Secretariado">
      <Subtitle2 className={classes.label}>Secretário{secretary.length > 1 ? 's' : ''}</Subtitle2>
      {secretary.map((user, idx) => (
        <React.Fragment key={user.id}>
          <Box className={classes.mainBox}>
            <SelectOutlined
              name={`secretary-${user.id}`}
              options={availableSecretary.filter(
                x => moderator.find(u => u.id === x.id) === undefined && secretary.find(u => u.id === x.id) === undefined
              )}
              value={user}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              otherClasses={classes}
              onChange={val => addSecretary(val, user.id)}
              noOptionsMessage={() => 'Sem secretários disponíveis para substituir.'}
              hideSelectedOptions
              isClearable
            />
          </Box>
          {idx !== secretary.length - 1 && <Box height="8px" />}
        </React.Fragment>
      ))}
      {secretary.length !== availableSecretary.length && (
        <>
          {secretary.length > 0 && <Box height="12px" />}
          <Box className={classes.mainBox}>
            <SelectOutlined
              name="secretary-new-add"
              options={availableSecretary.filter(
                x => moderator.find(u => u.id === x.id) === undefined && secretary.find(u => u.id === x.id) === undefined
              )}
              value={null}
              placeholder={`Adicionar ${secretary.length > 0 ? 'novo' : ''} secretário`}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              otherClasses={classes}
              onChange={val => addSecretary(val)}
              noOptionsMessage={() => 'Nenhum secretário disponível para adicionar.'}
              hideSelectedOptions
              hideRemove
            />
          </Box>
        </>
      )}
      {availableSecretary.length === 0 && <Body1 secondary>Não existem utilizadores com permissão de secretário.</Body1>}
      {/* <Box height="20px" />
      <Subtitle2 className={classes.label}>Moderador{moderator.length > 1 ? 'es' : ''}</Subtitle2>
      {moderator.map((user, idx) => (
        <React.Fragment key={user.id}>
          <Box className={classes.mainBox}>
            <SelectOutlined
              name={`moderator-${user.id}`}
              options={availableModerator.filter(
                x => moderator.find(u => u.id === x.id) === undefined && secretary.find(u => u.id === x.id) === undefined
              )}
              value={user}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              otherClasses={classes}
              onChange={val => addModerator(val, user.id)}
              noOptionsMessage={() => 'Sem moderadores disponíveis para substituir.'}
              isClearable
            />
          </Box>
          {idx !== moderator.length - 1 && <Box height="8px" />}
        </React.Fragment>
      ))}
      {moderator.length !== availableModerator.length && (
        <>
          {moderator.length > 0 && <Box height="12px" />}
          <Box className={classes.mainBox}>
            <SelectOutlined
              name="moderator-new-add"
              options={availableModerator.filter(
                x => moderator.find(u => u.id === x.id) === undefined && secretary.find(u => u.id === x.id) === undefined
              )}
              value={null}
              placeholder={`Adicionar ${moderator.length > 0 ? 'novo' : ''} moderador`}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              otherClasses={classes}
              onChange={val => addModerator(val)}
              noOptionsMessage={() => 'Nenhum moderador disponível para adicionar.'}
              hideRemove
            />
          </Box>
        </>
      )}
      {availableModerator.length === 0 && <Body1 secondary>Não existem utilizadores com permissão de moderador.</Body1>} */}
    </CardBlock>
  );
};

MeetingSecretaryModerator.propTypes = {
  state: PropTypes.object.isRequired,
};

export default React.memo(MeetingSecretaryModerator);
