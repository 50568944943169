import React from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import Body1 from '../../../../common/typographys/Body1';

const useStyles = makeStyles(() => ({
  activeProposal: {
    padding: '30px',
    marginBottom: '60px',
  },
  proposalName: {
    marginTop: '3px',
    marginBottom: '4px',
  },
}));

const NoActiveProposal = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.activeProposal}>
      <Body1 className={classes.proposalName}>Nenhum tema ou ponto selecionado para discussão.</Body1>
    </Paper>
  );
};

export default NoActiveProposal;
