import { GET_CONFIGS, GET_ERRORS } from './types';
import axios from 'axios';

/*
 * @desc - Atualiza o reducer de configuração
 */

// eslint-disable-next-line import/prefer-default-export
export const getConfig = () => dispatch => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/configs/autarchy`)
    .then(res => {
      dispatch({
        type: GET_CONFIGS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateGeneralConfigs = (info, resolve, reject) => dispatch => {
  axios
    .put(`${process.env.REACT_APP_API_URL}/api/configs`, info)
    .then(res => {
      dispatch({
        type: GET_CONFIGS,
        payload: res.data,
      });
      resolve();
    })
    .catch(err => {
      let payload;

      if (err && err.response && err.response.data) {
        payload = err.response.data;
      } else {
        payload = { error: null };
      }

      dispatch({
        type: GET_ERRORS,
        payload,
      });
      reject();
    });
};
