/**
 *
 * @description Transforms one phone string to the one that backend accepts -> +351XXXYYYZZZ
 * @param {string} phone
 * @returns Phone in the form that backend accepts
 */
const phoneToBackend = phone => {
  return `+351${phone.replace(/[\s()[\]]|(\+351)/g, '')}`;
};

export default phoneToBackend;
