import React, { useContext, useCallback } from 'react';
import LabelAndHelper from '../../../../common/forms/LabelAndHelper';
import { Box } from '@material-ui/core';
import TimeBox from '../../../pieces/TimeBox';
import PropTypes from 'prop-types';
import { TypologyDispatchContext } from '../CreateEditTypologyProvider';
import NewBoxItem from './NewBoxItem';
import PointItem from './PointItem';

const TypologyPoints = ({ points }) => {
  const dispatch = useContext(TypologyDispatchContext);

  const onClickAdd = useCallback(
    name => {
      dispatch({
        type: 'ADD_NEW_POINT',
        payload: {
          name,
        },
      });
    },
    [dispatch]
  );

  return (
    <>
      <LabelAndHelper label="Pontos de discussão" helper="Defina pontos de discussão predefinidos para esta tipologia." />
      <Box height="12px" />
      <TimeBox medium>
        {points.length > 0 &&
          points.map((point, idx) => (
            <React.Fragment key={point.id}>
              <PointItem point={point} idx={idx} />
              {idx + 1 !== points.length && <Box height="4px" />}
            </React.Fragment>
          ))}
        {points.length > 0 && <Box height="4px" />}
        <NewBoxItem onClick={onClickAdd} placeholder="Criar ponto de discussão" />
      </TimeBox>
    </>
  );
};

TypologyPoints.propTypes = {
  points: PropTypes.array.isRequired,
};

export default React.memo(TypologyPoints);
