import React, { useEffect, useCallback } from 'react';
import { Grid, makeStyles, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { changeMenuSidebar } from '../../store/actions/navigationActions';
import H5 from '../common/typographys/H5';
import Body1 from '../common/typographys/Body1';
import formatDateRelative from '../../utils/formatDateRelative';
import Subtitle1 from '../common/typographys/Subtitle1';
import CalendarCronology from '../common/CalendarCronology';
import clsx from 'clsx';
import { getMeetings } from '../../store/actions/meetingsActions';
import isEmpty from '../../utils/isEmpty';
import { getUserActions } from '../../store/actions/authActions';
import ActionsContainer from './pieces/ActionsContainer';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  marginContainer: {
    marginTop: '40px',
  },
  marginFirstContainer: {
    marginTop: '54px',
  },
  button: {
    maxHeight: '40px',
    minHeight: '40px',
    height: '40px',
    backgroundColor: theme.palette.primary.A400,
    color: theme.palette.neutrals[1],
    '&:hover': {
      backgroundColor: theme.palette.primary.A700,
      color: theme.palette.neutrals[1],
    },
  },
  meetingsHeader: {
    marginBottom: theme.spacing(2),
  },
  meetingsItem: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { reunions } = useSelector(state => state.socket);
  const history = useHistory();
  const {
    user: { id },
    actions,
  } = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(changeMenuSidebar('dashboard', 'Dashboard'));
    dispatch(getMeetings());
    dispatch(getUserActions());
  }, [dispatch]);

  const firstContainer = actions.length > 0 || reunions.length > 0;

  const goToMeeting = useCallback(
    (meetingId, status, user_edit) => e => {
      e.preventDefault();
      if (status === 2) {
        return history.push(`/reuniao/${meetingId}`);
      }

      if (!isEmpty(user_edit) && status !== 2 && user_edit.id === id) {
        return history.push(`/reunioes/preparar/${meetingId}`);
      }

      return history.push(`/reuniao/${meetingId}`);
    },
    [id, history]
  );

  return (
    <>
      {firstContainer && (
        <Grid container justify="center" spacing={3} className={classes.marginFirstContainer}>
          <Grid item md={10} xs={11}>
            {reunions.length > 0 ? (
              <Grid container spacing={2}>
                <Grid item xs={12} lg={8} xl={9}>
                  <ActionsContainer actions={actions} />
                </Grid>
                <Grid item xs={12} lg={4} xl={3}>
                  <H5 className={classes.meetingsHeader}>Reuniões iniciadas</H5>
                  <Grid container className={classes.meetingsItem} spacing={2}>
                    {reunions.map(item => (
                      <Grid item key={item.id} xs={12} md={6} lg={12}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Body1>{item.name}</Body1>
                          </Grid>
                          <Grid item xs={9} md={8}>
                            <Subtitle1>{formatDateRelative(item.date)}</Subtitle1>
                          </Grid>
                          <Grid item xs={3} md={4}>
                            <Button
                              variant="contained"
                              className={classes.button}
                              onClick={goToMeeting(item.id, item.status, item.user_edit)}
                              fullWidth
                            >
                              Entrar
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <ActionsContainer actions={actions} />
            )}
          </Grid>
        </Grid>
      )}
      <Grid
        container
        justify="center"
        className={clsx({
          [classes.marginContainer]: firstContainer,
          [classes.marginFirstContainer]: !firstContainer,
        })}
        spacing={3}
      >
        <CalendarCronology />
      </Grid>
    </>
  );
};

export default Dashboard;
