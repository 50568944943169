import { makeStyles } from '@material-ui/core';

const appSchemaStyles = makeStyles(theme => ({
  appBarMobile: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerMobile: {
    [theme.breakpoints.up('md')]: {
      width: theme.props.drawerWidthMobile,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    backgroundColor: theme.palette.neutrals[2],
    width: theme.props.drawerWidthMobile,
  },
  // @ Desktop
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    marginLeft: theme.props.drawerWidth,
    width: `calc(100% - ${theme.props.drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  drawer: {
    width: theme.props.drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.neutrals[1],
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: 'hidden',
  },
  // @ Common
  avatar: {
    width: 40,
    height: 40,
    backgroundColor: theme.palette.primary[900],
    '&:hover': {
      cursor: 'pointer',
    },
  },
  badge: {
    marginTop: 2,
  },
  avatarMargin: {
    marginRight: 'auto',
    borderRadius: 0,
  },
  noMarginTop: {
    marginTop: 0,
  },
  button: {
    marginLeft: 'auto',
    color: '#fff!important',
    padding: 0,
    marginRight: theme.spacing(3),
    marginTop: 6,
  },
  secondLevelItem: {
    marginLeft: '14px',
  },
  hoverLink: {
    cursor: 'pointer',
  },
  clickedNotification: {
    backgroundColor: theme.palette.neutrals[1],
  },
  seeAll: {
    width: '100%',
    padding: '8px',
  },
  boxSeeAll: {
    display: 'flex',
    justifyContent: 'center',
  },
  notificationsBoxPaper: {
    maxHeight: 400,
    overflow: 'auto',
    background: '#FFFFFF',
  },
  notiDate: {
    width: '100%',
    marginTop: '2px',
  },
  marginItem: {
    marginBottom: '6px',
  },
  entryItem: {
    marginBottom: '2px',
  },
}));

export default appSchemaStyles;
