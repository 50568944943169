import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body2 from '../../common/typographys/Body2';
import Body1 from '../../common/typographys/Body1';

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: '8px',
  },
  secondLevel: {
    paddingLeft: '8px',
    marginTop: '6px',
  },
}));

const ListMeetingThemes = ({ themes }) => {
  const classes = useStyles();

  return (
    <>
      <Body2 secondary className={classes.label}>
        Tema{themes.length > 1 && 's'}
      </Body2>
      {themes.map((theme, idx) => (
        <React.Fragment key={theme.id}>
          <Body1>{theme.name}</Body1>
          {theme.points &&
            theme.points.length > 0 &&
            theme.points.map(point => (
              <Body2 key={point.id} className={classes.secondLevel}>
                {point.name}
              </Body2>
            ))}
          {idx === themes.length - 1 ? <Box height="20px" /> : <Box height="8px" />}
        </React.Fragment>
      ))}
    </>
  );
};

ListMeetingThemes.propTypes = {
  themes: PropTypes.array.isRequired,
};

export default ListMeetingThemes;
