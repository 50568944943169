import React, { useState, useCallback } from 'react';
import useCalendarStyles from './pieces/calendarStyles';
import { Grid, CardContent } from '@material-ui/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import timelinePlugin from '@fullcalendar/timeline';
import ptLocale from '@fullcalendar/core/locales/pt';
import PropTypes from 'prop-types';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import getMonth from 'date-fns/getMonth';
import useBooleanToggle from '../../../utils/hooks/useBooleanToggle';
import DialogEventInfo from './pieces/DialogEventInfo';
import { fade, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const Calendar = ({ view, calendarRef, events }) => {
  const classes = useCalendarStyles();
  const [open, toggleModal] = useBooleanToggle();
  const [selected, setSelected] = useState({});
  const theme = useTheme();
  const useLongDayNames = useMediaQuery(theme.breakpoints.up('sm'));

  const handleEventClick = useCallback(
    info => {
      setSelected(info.event);
      toggleModal();
    },
    [toggleModal]
  );

  return (
    <>
      <Grid container>
        <CardContent className={classes.calendarContainer}>
          <FullCalendar
            ref={calendarRef}
            allDayMaintainDuration
            defaultView={view}
            events={events}
            header={false}
            height={600}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, timelinePlugin]}
            rerenderDelay={10}
            selectable
            weekends
            views={{
              dayGridMonth: {
                columnHeaderFormat: { weekday: useLongDayNames ? 'long' : 'short' },
              },
            }}
            columnHeaderFormat={{
              weekday: 'long',
              month: 'numeric',
              day: 'numeric',
              omitCommas: false,
            }}
            locale={ptLocale}
            allDaySlot={false}
            slotLabelFormat={{
              hour: '2-digit',
              minute: '2-digit',
              omitZeroMinute: false,
              meridiem: 'short',
            }}
            // minTime="08:00:00"
            // @ Mostra os eventos de meses diferentes do atual
            eventRender={info => {
              if (
                getMonth(info.event.start) !== getMonth(new Date()) ||
                info.event.extendedProps.status === -1
              ) {
                if (info.event.extendedProps.status === -1) {
                  info.el.classList.add([classes.eventCanceled]);
                }
                info.el.classList.add([classes.otherMonth]);
              } else if (
                info.event.extendedProps &&
                info.event.extendedProps.info &&
                info.event.extendedProps.info.typology &&
                info.event.extendedProps.info.typology.color &&
                info.el.id === ''
              ) {
                const borderColor = info.event.extendedProps.info.typology.color;
                const css = `#evento-${
                  info.event.id
                }:hover{ color: ${borderColor} ; background : ${fade(borderColor, 0.1)} }`;
                const aux = document.querySelector(`style#evento-${info.event.id}`);
                const style = document.createElement('style');
                style.id = `evento-${info.event.id}`;
                style.appendChild(document.createTextNode(css));
                info.el.id = `evento-${info.event.id}`;
                if (aux === null) {
                  document.getElementsByTagName('head')[0].appendChild(style);
                }
              }
            }}
            eventLimit
            eventLimitText="eventos"
            eventClick={handleEventClick}
          />
        </CardContent>
      </Grid>
      <DialogEventInfo open={open} handleClose={toggleModal} event={selected} />
    </>
  );
};

Calendar.defaultProps = {
  events: [],
  view: 'dayGridMonth',
  calendarRef: null,
};

Calendar.propTypes = {
  events: PropTypes.array,
  calendarRef: PropTypes.object,
  view: PropTypes.string,
};

export default Calendar;
