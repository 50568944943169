import React, { useCallback, useContext, useState } from 'react';
import { makeStyles, Box, Input } from '@material-ui/core';
import PropTypes from 'prop-types';
import Subtitle2 from '../../common/typographys/Subtitle2';
import CheckboxPicker from '../../common/forms/CheckboxPicker';
import EntryBox from './EntryBox';
import { MeetingDispatchContext } from '../CreateEditMeetingsProvider';
import isEmpty from '../../../utils/isEmpty';
import getDateObj from '../../../utils/getDateObj';
import MeetingPoolBox from './MeetingPoolBox';
import formatDate from '../../../utils/formatDate';
import BoxDateFilter from '../../proposals/pieces/BoxDateFilter';
import subMonths from 'date-fns/subMonths';
import isAfter from 'date-fns/isAfter';
import startOfDay from 'date-fns/startOfDay';
import isBefore from 'date-fns/isBefore';
import endOfDay from 'date-fns/endOfDay';

const useStyles = makeStyles(() => ({
  labelTheme: {
    marginBottom: '2px',
  },
  marginTop: {
    marginTop: '30px',
  },
  inputField: {
    marginBottom: '10px',
  },
}));

const stringContains = (first = '', second = '') => {
  return first.toLowerCase().indexOf(second.toLowerCase());
};

const MeetingPool = ({ hasPoolPoints, hasPoolThemes, points, themes }) => {
  const classes = useStyles();
  const dispatch = useContext(MeetingDispatchContext);
  const [search, setSearch] = useState('');
  const [date, setDate] = useState(subMonths(new Date(), 12));
  const [endDate, setEndDate] = useState(new Date());

  const togglePoolPoint = useCallback(
    info => e => {
      e.preventDefault();
      dispatch({
        type: 'REMOVE_POOL_POINT',
        payload: info,
      });
    },
    [dispatch]
  );

  const togglePoolThemePoint = useCallback(
    (infoTheme, infoPoint) => e => {
      e.preventDefault();
      // @ Só existe mais este ponto se infoTheme.points.length === 1
      dispatch({
        type: 'REMOVE_POOL_THEME_POINT',
        payload: {
          point: infoPoint,
          theme: infoTheme,
          lastOne: infoTheme.points.length === 1,
        },
      });
    },
    [dispatch]
  );

  return (
    <MeetingPoolBox>
      {(hasPoolPoints || hasPoolThemes) && (
        <Box display="flex" justifyContent="space-between">
          <Input
            className={classes.inputField}
            placeholder="Filtrar propostas"
            onChange={e => setSearch(e.target.value)}
            value={search}
          />
          <BoxDateFilter
            hideFilter
            endDate={endDate}
            date={date}
            setDate={setDate}
            setEndDate={setEndDate}
            justify="flex-end"
            marginLeft
          />
        </Box>
      )}
      {hasPoolPoints &&
        points.map(point => {
          if (
            (isEmpty(search) ||
              (!isEmpty(search) &&
                (stringContains(point.name, search) !== -1 ||
                  stringContains(point.identifier_code, search) !== -1))) &&
            isAfter(getDateObj(point.date), startOfDay(date)) &&
            isBefore(getDateObj(point.date), endOfDay(endDate))
          ) {
            return (
              <EntryBox key={point.id}>
                <CheckboxPicker
                  label={
                    point.identifier_code ? `${point.identifier_code} - ${point.name}` : point.name
                  }
                  secondLabel={point.date ? formatDate(point.date) : null}
                  checked={point.selected}
                  onClick={togglePoolPoint(point)}
                />
              </EntryBox>
            );
          }

          return null;
        })}
      {hasPoolThemes &&
        themes.map((theme, idx) => {
          if (
            theme.points.find(
              x =>
                isAfter(getDateObj(x.date), startOfDay(date)) &&
                isBefore(getDateObj(x.date), endOfDay(endDate))
            ) !== undefined &&
            (isEmpty(search) ||
              (!isEmpty(search) &&
                theme.points.find(
                  x =>
                    stringContains(x.name, search) !== -1 ||
                    stringContains(x.identifier_code, search) !== -1
                ) !== undefined))
          ) {
            return (
              <React.Fragment key={theme.id}>
                {idx === 0 ? (
                  <Box height={hasPoolPoints ? '24px' : '0px'} />
                ) : (
                  <Box height="20px" />
                )}
                <Subtitle2 className={classes.labelTheme}>{theme.name}</Subtitle2>
                {theme.points.map(point => {
                  if (
                    (isEmpty(search) ||
                      (!isEmpty(search) && stringContains(point.name, search) !== -1)) &&
                    isAfter(getDateObj(point.date), startOfDay(date)) &&
                    isBefore(getDateObj(point.date), endOfDay(endDate))
                  ) {
                    return (
                      <EntryBox key={point.id}>
                        <CheckboxPicker
                          label={
                            point.identifier_code
                              ? `${point.identifier_code} - ${point.name}`
                              : point.name
                          }
                          secondLabel={point.date ? formatDate(point.date) : null}
                          checked={point.selected}
                          onClick={togglePoolThemePoint(theme, point)}
                        />
                      </EntryBox>
                    );
                  }

                  return null;
                })}
              </React.Fragment>
            );
          }

          return null;
        })}
    </MeetingPoolBox>
  );
};

MeetingPool.propTypes = {
  hasPoolPoints: PropTypes.bool.isRequired,
  hasPoolThemes: PropTypes.bool.isRequired,
  points: PropTypes.array.isRequired,
  themes: PropTypes.array.isRequired,
};

export default React.memo(MeetingPool);
