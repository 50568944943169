import React, { useContext, useCallback } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Subtitle2 from '../../../../common/typographys/Subtitle2';
import clsx from 'clsx';
import Body1 from '../../../../common/typographys/Body1';
import { TypologyDispatchContext } from '../CreateEditTypologyProvider';
import BoxCheckbox from '../../../../common/forms/BoxCheckbox';

const useStyles = makeStyles(theme => ({
  mainBox: {
    width: '100%',
    display: 'flex',
    height: '48px',
    marginBottom: '4px',
  },
  labelBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.neutrals[2],
    padding: '12px 10px',
  },
  limitTimeBox: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '176px',
    height: '100%',
    backgroundColor: theme.palette.neutrals[2],
    marginLeft: '4px',
    padding: '8px 10px 4px 10px',
  },
  disabled: {
    pointerEvents: 'none',
  },
}));

const ParticipantMember = ({ id, name, participant, timeLimit }) => {
  const classes = useStyles();

  const dispatch = useContext(TypologyDispatchContext);

  const onClickMember = useCallback(() => {
    if (!participant) {
      dispatch({
        type: 'ADD_NEW_INDIVIDUAL',
        payload: {
          id,
          noLimit: false,
        },
      });
    } else {
      dispatch({
        type: 'REMOVE_INDIVIDUAL',
        payload: {
          id,
          noLimit: false,
        },
      });
    }
  }, [dispatch, id, participant]);

  const onClickTime = useCallback(() => {
    dispatch({
      type: 'UPDATE_INDIVIDUAL_TIME_LIMIT',
      payload: {
        id,
        value: !timeLimit,
      },
    });
  }, [dispatch, id, timeLimit]);

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.labelBox}>
        <Body1 oneLine>{name}</Body1>
        <BoxCheckbox checked={participant} onClick={onClickMember} />
      </Box>
      <Box className={clsx(classes.limitTimeBox, { [classes.disabled]: !participant })}>
        <Subtitle2 inactive={!participant}>Não possui limite de tempo oratório</Subtitle2>
        <BoxCheckbox checked={timeLimit} onClick={onClickTime} />
      </Box>
    </Box>
  );
};

ParticipantMember.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  participant: PropTypes.bool.isRequired,
  timeLimit: PropTypes.bool.isRequired,
};

export default React.memo(ParticipantMember);
