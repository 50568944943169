import React, { useCallback, useContext } from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { MeetingDispatchContext } from '../CreateEditMeetingsProvider';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MeetingPointEntry from './MeetingPointEntry';

// @ Retirado dos Docs do react-beautiful-dnd
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DraggablePoint = ({ point, availableThemes, idx, matrices }) => {
  return (
    <Draggable draggableId={`draggable-${point.id}`} index={idx}>
      {provided => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          {idx !== 0 && <Box height="8px" />}
          <MeetingPointEntry point={point} availableThemes={availableThemes} matrices={matrices} />
        </div>
      )}
    </Draggable>
  );
};

DraggablePoint.propTypes = {
  point: PropTypes.object.isRequired,
  availableThemes: PropTypes.array.isRequired,
  idx: PropTypes.number.isRequired,
  matrices: PropTypes.array.isRequired,
  isGroup: PropTypes.bool.isRequired,
};

const PointsList = ({ points, availableThemes, matrices }) => {
  return points.map((point, idx) => (
    <DraggablePoint
      key={point.id}
      point={point}
      availableThemes={availableThemes}
      idx={idx}
      matrices={matrices}
    />
  ));
};

const NoThemeMeetingPoints = ({ points, availableThemes, matrices }) => {
  const dispatch = useContext(MeetingDispatchContext);

  const onDragEnd = useCallback(
    (result, arr) => {
      if (!result.destination || result.destination.index === result.source.index) {
        return;
      }

      const sortedPoints = reorder(arr, result.source.index, result.destination.index);

      dispatch({
        type: 'UPDATE_POINTS',
        payload: sortedPoints,
      });
    },
    [dispatch]
  );

  return (
    <DragDropContext onDragEnd={e => onDragEnd(e, points)}>
      <Droppable droppableId="list">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <PointsList points={points} availableThemes={availableThemes} matrices={matrices} />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

NoThemeMeetingPoints.propTypes = {
  points: PropTypes.array.isRequired,
  availableThemes: PropTypes.array.isRequired,
  matrices: PropTypes.array.isRequired,
};

export default React.memo(NoThemeMeetingPoints);
