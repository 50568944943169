import palette from '../palette';

export default {
  root: {
    borderRadius: '4px',
    color: '#FFFFFF',
    fontSize: '16px',
    backgroundColor: palette.primary[300],
  },
  deleteIcon: {
    color: '#FFFFFF',
  },
};
