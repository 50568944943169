import { combineReducers } from 'redux';
import authReducer from './authReducer';
import cardReducer from './cardReducer';
import configReducer from './configReducer';
import downloadReducer from './downloadReducer';
import emailsReducer from './emailsReducer';
import entitiesReducer from './entitiesReducer';
import errorReducer from './errorReducer';
import flowsReducer from './flowsReducer';
import groupsReducer from './groupsReducer';
import localsReducer from './localsReducer';
import matricesReducer from './matricesReducer';
import meetingsReducer from './meetingsReducer';
import navigationReducer from './navigationReducer';
import notificationsReducer from './notificationsReducer';
import organicUnitsReducer from './organicUnitsReducer';
import profilesReducer from './profilesReducer';
import proposalsReducer from './proposalsReducer';
import reunionsReducer from './reunionsReducer';
import snackReducer from './snackReducer';
import socketReducer from './socketReducer';
import themesReducer from './themesReducer';
import typologiesReducer from './typologiesReducer';
import usersReducer from './usersReducer';

export default combineReducers({
  auth: authReducer,
  card: cardReducer,
  config: configReducer,
  download: downloadReducer,
  emails: emailsReducer,
  entities: entitiesReducer,
  errors: errorReducer,
  flows: flowsReducer,
  groups: groupsReducer,
  locals: localsReducer,
  matrices: matricesReducer,
  meetings: meetingsReducer,
  navigation: navigationReducer,
  notifications: notificationsReducer,
  organicUnits: organicUnitsReducer,
  profiles: profilesReducer,
  proposals: proposalsReducer,
  reunions: reunionsReducer,
  snack: snackReducer,
  socket: socketReducer,
  themes: themesReducer,
  typologies: typologiesReducer,
  users: usersReducer,
});
