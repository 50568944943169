import React, { useEffect } from 'react';
import SocketReunionWrapper from './SocketReunionWrapper';
import PrepareMeeting from './PrepareMeeting';
import { useDispatch, useSelector } from 'react-redux';
import { getMeeting, getAvailableSecretaries, getAvailableModerators } from '../../../store/actions/meetingsActions';
import { useParams, useHistory } from 'react-router-dom';
import FullWidthSpinner from '../../common/FullWidthSpinner';
import isEmpty from '../../../utils/isEmpty';
import { makeStyles } from '@material-ui/core';
import canStartMeeting from '../../../utils/permissions/canStartMeeting';
import PrepareMeetingProvider from '../PrepareMeetingProvider';

const useStyles = makeStyles(() => ({
  margin: {
    marginTop: '80px',
  },
}));

const WrapPrepare = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const { meeting, loading } = useSelector(state => state.meetings);
  const { permissions, id: userId } = useSelector(state => state.auth.user);

  useEffect(() => {
    dispatch(getMeeting(id));
    dispatch(getAvailableSecretaries());
    dispatch(getAvailableModerators());
  }, [dispatch, id]);

  if (!canStartMeeting(permissions)) {
    history.goBack();
  }

  if (loading || isEmpty(meeting)) {
    return <FullWidthSpinner className={classes.margin} />;
  }

  if (!loading && !isEmpty(meeting) && (isEmpty(meeting.user_edit) || (!isEmpty(meeting.user_edit) && meeting.user_edit.id === userId))) {
    return (
      <SocketReunionWrapper>
        <PrepareMeetingProvider meeting={meeting}>
          <PrepareMeeting />
        </PrepareMeetingProvider>
      </SocketReunionWrapper>
    );
  }

  return null;
};

export default WrapPrepare;
