import React from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  spinner: {
    position: 'absolute',
    right: '20px',
    top: '20px',
  },
}));

const LoadingSpinner = () => {
  const classes = useStyles();

  return <CircularProgress className={classes.spinner} />;
};

export default React.memo(LoadingSpinner);
