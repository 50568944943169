import React, { useContext, useMemo, useCallback, useState } from 'react';
import { makeStyles, Grid, Paper, Divider, Box, Input } from '@material-ui/core';
import PropTypes from 'prop-types';
import { AddCircleOutlined } from '@material-ui/icons';
import ButtonPrimaryIcon from '../../common/buttons/ButtonPrimaryIcon';
import Body2 from '../../common/typographys/Body2';
import LabelItemConfig from './LabelItemConfig';
import { ConfigStateContext, ConfigDispatchContext } from '../MenuConfigProvider';
import Pagination from '../../common/SimplePagination';
import Body1 from '../../common/typographys/Body1';
import useBooleanToggle from '../../../utils/hooks/useBooleanToggle';
import DialogConfirmAction from '../../common/dialogs/DialogConfirmAction';
import isEmpty from '../../../utils/isEmpty';
import usePagination from '../../../utils/hooks/usePagination';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  paper: {
    padding: '20px',
    paddingBottom: '65px',
  },
  labelName: {
    marginTop: '30px',
    marginBottom: '6px',
  },
  noItems: {
    marginTop: '20px',
  },
  firstDivider: {
    marginBottom: '4px',
  },
  boxButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  withSearch: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const ConfigList = ({
  items,
  labelButton,
  noItemMessage,
  pageObj,
  msgDelete,
  handleDelete,
  labelSubmit,
  search,
  setSearch,
  canDelete,
  canAddNew,
  canCreateNew,
  labelCreateButton,
}) => {
  const classes = useStyles();
  const { isAddNew, editing, deleting } = useContext(ConfigStateContext);
  const dispatchConfig = useContext(ConfigDispatchContext);
  const [popUp, setPopUp] = useState(false);
  const [deleteModal, toggleDeleteModal] = useBooleanToggle();
  const [showPagination, itemShown, page, setPage] = usePagination(10, items);

  const addNewItem = useCallback(
    e => {
      e.preventDefault();
      dispatchConfig({
        type: 'ADD_NEW_ITEM',
      });
    },
    [dispatchConfig]
  );

  const createNewItem = useCallback(
    e => {
      e.preventDefault();
      dispatchConfig({
        type: 'ADD_NEW_ITEM',
        payload: {
          altCreate: true,
        },
      });
    },
    [dispatchConfig]
  );

  const clickItem = useCallback(
    item => e => {
      e.preventDefault();
      dispatchConfig({
        type: 'SET_ACTIVE_ITEM',
        payload: item,
      });
    },
    [dispatchConfig]
  );

  const submitDelete = useCallback(
    e => {
      toggleDeleteModal();
      handleDelete(e);
    },
    [toggleDeleteModal, handleDelete]
  );

  const cancelDelete = useCallback(
    e => {
      e.preventDefault();
      toggleDeleteModal();
      dispatchConfig({
        type: 'CANCEL_DELETING',
      });
    },
    [toggleDeleteModal, dispatchConfig]
  );

  // @ Para apagar, tem de popular o activeItem, pois é preciso o ID do item a eliminar
  const setDeleteItem = useCallback(
    item => e => {
      e.preventDefault();
      dispatchConfig({
        type: 'UPDATE_ACTIVE_ITEM',
        payload: { id: item.id, name: item.name },
      });
      dispatchConfig({
        type: 'DELETING',
      });
      toggleDeleteModal();
    },
    [dispatchConfig, toggleDeleteModal]
  );

  const labelsItems = useMemo(
    () =>
      itemShown.map((item, idx) =>
        !isEmpty(item) ? (
          <LabelItemConfig
            key={item.name}
            idx={idx}
            label={item.name}
            onClick={clickItem({ ...item, ...pageObj })}
            canDelete={Boolean(item.can_delete && canDelete)}
            disabled={isAddNew || editing || deleting}
            clickDelete={setDeleteItem(item)}
          />
        ) : null
      ),
    [itemShown, clickItem, isAddNew, editing, deleting, setDeleteItem, canDelete, pageObj]
  );

  const buttonsDisabled = isAddNew || editing || deleting;

  return (
    <Grid item xs={5}>
      <Paper className={classes.paper}>
        <Box
          className={clsx({
            [classes.boxButton]: search === null,
            [classes.withSearch]: search !== null,
          })}
        >
          {search !== null && (
            <Input
              name="search"
              value={search}
              onChange={e => {
                setSearch(e.target.value);
                setPage(0);
              }}
              placeholder="Filtrar..."
            />
          )}
          {canAddNew && !canCreateNew && (
            <ButtonPrimaryIcon
              label={labelButton}
              icon={AddCircleOutlined}
              onClick={addNewItem}
              disabled={buttonsDisabled}
            />
          )}
        </Box>
        {canAddNew && canCreateNew && (
          <>
            <Box
              pt={1}
              className={clsx({
                [classes.boxButton]: true,
              })}
            >
              <ButtonPrimaryIcon
                label={labelButton}
                icon={AddCircleOutlined}
                onClick={addNewItem}
                disabled={buttonsDisabled}
              />
            </Box>
            <Box
              pt={1}
              className={clsx({
                [classes.boxButton]: true,
              })}
            >
              <ButtonPrimaryIcon
                label={labelCreateButton}
                icon={AddCircleOutlined}
                onClick={createNewItem}
                disabled={buttonsDisabled}
              />
            </Box>
          </>
        )}
        {items.length > 0 ? (
          <>
            <Body2 secondary className={classes.labelName}>
              Nome
            </Body2>
            <Divider className={classes.firstDivider} />
            {labelsItems}
          </>
        ) : (
          <Body1 className={classes.noItems}>{noItemMessage}</Body1>
        )}
      </Paper>
      {showPagination && (
        <Pagination
          total={items.length}
          onChangePage={(_, pageNum) => {
            setPage(pageNum);
          }}
          rowsPerPage={10}
          page={page}
        />
      )}
      <DialogConfirmAction
        open={deleteModal}
        handleClose={cancelDelete}
        msg={msgDelete}
        handleSubmit={submitDelete}
        labelSubmit={labelSubmit}
      />
      <DialogConfirmAction
        open={popUp}
        handleClose={e => {
          e.preventDefault();
          setPopUp(false);
        }}
        msg="Deseja abandonar o processo de eliminação da Unidade Orgânica?"
        handleSubmit={submitDelete}
        labelSubmit="Abandonar"
      />
    </Grid>
  );
};

ConfigList.defaultProps = {
  search: null,
  setSearch: () => null,
  canDelete: false,
  canAddNew: false,
  labelButton: 'Novo',
  msgDelete: 'Tem a certeza que pretende eliminar?',
  canCreateNew: false,
  labelCreateButton: '',
};

ConfigList.propTypes = {
  items: PropTypes.array.isRequired,
  noItemMessage: PropTypes.string.isRequired,
  pageObj: PropTypes.object.isRequired,
  handleDelete: PropTypes.func.isRequired,
  labelSubmit: PropTypes.string.isRequired,
  msgDelete: PropTypes.string,
  labelButton: PropTypes.string,
  search: PropTypes.string,
  setSearch: PropTypes.func,
  canDelete: PropTypes.bool,
  canAddNew: PropTypes.bool,
  canCreateNew: PropTypes.bool,
  labelCreateButton: PropTypes.string,
};

export default ConfigList;
