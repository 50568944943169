import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from '../../../utils/isEmpty';
import { clearSnack } from '../../../store/actions/navigationActions';

const ShowSnack = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { message, variant } = useSelector(state => state.snack);

  useEffect(() => {
    if (!isEmpty(message)) {
      enqueueSnackbar(message, { variant, autoHideDuration: 3000 });
      setTimeout(() => {
        dispatch(clearSnack());
      }, 1000);
    }
  }, [dispatch, enqueueSnackbar, variant, message]);

  return null;
};

export default React.memo(ShowSnack);
