import React, { useContext } from 'react';
import LabelAndValue from '../common/forms/LabelAndValue';
import { PersonalProfileStateContext } from './PersonalProfileProvider';

const ProfileFieldsInfo = () => {
  const {
    state: { user },
  } = useContext(PersonalProfileStateContext);

  return (
    <>
      <LabelAndValue label="Nome" value={user.name} />
      <LabelAndValue label="Email" value={user.email} />
      <LabelAndValue label="Número de Identificação Fiscal" value={user.nif} />
      <LabelAndValue label="Número de Identificação Civil" value={user.cc} />
      <LabelAndValue label="Contacto telefónico" value={user.phone} />
      {/* <LabelAndValue label="Empresa" value={user.company} /> */}
      {user.department && <LabelAndValue label="Departamento" value={user.department} />}
      {user.role && <LabelAndValue label="Função" value={user.role} />}
      {user.organic_unit && (
        <LabelAndValue label="Unidade Orgânica" value={`${user.organic_unit.abbreviation || user.organic_unit.code} - ${user.organic_unit.name}`} />
      )}
    </>
  );
};

export default React.memo(ProfileFieldsInfo);
