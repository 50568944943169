import React, { useMemo, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import isEmpty from '../../../utils/isEmpty';
import CheckboxPicker from '../../common/forms/CheckboxPicker';
import Creatable from '../../common/forms/Creatable';
import { MeetingFuncsContext, MeetingDispatchContext } from '../CreateEditMeetingsProvider';
import InputField from '../../common/forms/InputField';

const MeetingLocal = ({ state }) => {
  const { updateField } = useContext(MeetingFuncsContext);
  const dispatch = useContext(MeetingDispatchContext);
  const {
    typology,
    local,
    addToTypology,
    videoConference,
    videoConferenceLink,
    error,
    submitted,
  } = state;

  const dispatchError = useCallback(
    payload => {
      dispatch({
        type: 'SET_ERROR',
        payload,
      });
    },
    [dispatch]
  );

  const locals = useMemo(() => {
    if (!isEmpty(typology) && typology.locals) {
      return typology.locals.reduce((acc, cur) => {
        const optionLocal = {
          label: cur.name,
          name: cur.name,
          value: cur.id,
          id: cur.id,
        };

        acc.push(optionLocal);
        return acc;
      }, []);
    }

    return [];
  }, [typology]);

  // useEffect(() => {
  //   if (typology && typology.locals && typology.locals.length === 1) {
  //     const value = {
  //       name: typology.locals[0].name,
  //       label: typology.locals[0].name,
  //       value: typology.locals[0].id,
  //       id: typology.locals[0].id,
  //     };
  //     updateField('local', value);
  //   }
  // }, [typology, updateField]);

  useEffect(() => {
    if (submitted && (!isEmpty(local) || !isEmpty(videoConferenceLink)) && !isEmpty(error)) {
      dispatchError({ local: '' });
    } else if (submitted && isEmpty(local) && isEmpty(videoConferenceLink) && isEmpty(error)) {
      dispatchError({ local: 'Preencha o local da reunião.' });
    }
  }, [error, local, submitted, videoConferenceLink, dispatchError]);

  return (
    <>
      <CheckboxPicker
        label="Adicionar link para videoconferência"
        checked={videoConference}
        onClick={() => updateField('videoConference', !videoConference)}
      />
      <Box height="20px" />
      {videoConference && (
        <InputField
          name="videoConferenceLink"
          value={videoConferenceLink}
          onChange={e => updateField('videoConferenceLink', e.target.value)}
          placeholder="Link da videoconferência"
          label="Link da videoconferência"
        />
      )}
      <Creatable
        label="Local"
        options={locals}
        value={local}
        handleChange={val => {
          updateField('local', val);
        }}
        noOptionsMessage={() =>
          !isEmpty(typology)
            ? 'Nenhum local disponível, adicione 1 novo local'
            : 'Selecione a tipologia'
        }
        placeholder="Selecionar local ou criar novo local"
        formatCreateLabel={inputValue => `Novo local - ${inputValue}`}
        getNewOptionData={(inputValue, optionLabel) => ({
          value: inputValue,
          name: inputValue,
          label: optionLabel,
          isNew: true,
        })}
        error={error}
        isValidNewOption={inputValue => !isEmpty(typology) && inputValue.length > 1}
        isClearable
      />
      {local && local.isNew && (
        <>
          <Box height="6px" />
          <CheckboxPicker
            onClick={() => updateField('addToTypology', !addToTypology)}
            checked={addToTypology}
            label={`Associar ${local.label} à tipologia`}
          />
        </>
      )}
    </>
  );
};

MeetingLocal.defaultProps = {
  state: {},
};

MeetingLocal.propTypes = {
  state: PropTypes.object,
};

export default React.memo(MeetingLocal);
