import React, { useEffect, useContext, useCallback } from 'react';
import SeeItem from '../../pieces/SeeItem';
import { useSelector, useDispatch } from 'react-redux';
import { getProfile } from '../../../../store/actions/profilesActions';
import PropTypes from 'prop-types';
import LabelAndValue from '../../../common/forms/LabelAndValue';
import { ProfileDispatchContext } from './CreateEditProfileProvider';
import EditProfile from './EditProfile';
import { ConfigStateContext, ConfigDispatchContext } from '../../MenuConfigProvider';
import H6 from '../../../common/typographys/H6';
import { makeStyles } from '@material-ui/core';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import PermissionEntry from '../../pieces/PermissionEntry';

const useStyles = makeStyles(() => ({
  subHeader: {
    marginBottom: '16px',
  },
}));

const SeeEditProfile = ({ info }) => {
  const classes = useStyles();
  const dispatchRedux = useDispatch();
  const dispatchConfig = useContext(ConfigDispatchContext);
  const dispatch = useContext(ProfileDispatchContext);
  const { editing } = useContext(ConfigStateContext);
  const { profile } = useSelector(state => state.profiles);
  const { permissions = {} } = profile;

  useEffect(() => {
    if (info.isItemProfile) {
      dispatchRedux(getProfile(info.id));
    }
  }, [dispatchRedux, info]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: 'name',
        value: info.name,
      },
    });
  }, [info.name, dispatch]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: 'id',
        value: info.id,
      },
    });
  }, [info.id, dispatch]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: 'permissions',
        value: permissions,
      },
    });
  }, [permissions, dispatch]);

  const startEdit = useCallback(
    e => {
      e.preventDefault();
      dispatchConfig({
        type: 'SET_EDITING',
      });
    },
    [dispatchConfig]
  );

  const keys = Object.keys(permissions);

  return editing ? (
    <EditProfile />
  ) : (
    <SeeItem submitEdit={startEdit}>
      <LabelAndValue label="Nome" value={info.name} />
      <H6 className={classes.subHeader}>Características</H6>
      {keys.map(label =>
        checkEmptyObject(permissions[label]).length > 0 ? <PermissionEntry key={label} label={label} permissions={permissions[label]} /> : null
      )}
    </SeeItem>
  );
};

SeeEditProfile.propTypes = {
  info: PropTypes.object.isRequired,
};

export default React.memo(SeeEditProfile);
