import React from 'react';
import { makeStyles, Divider } from '@material-ui/core';
import BoxHeaderFeatures from '../../../pieces/BoxHeaderFeatures';
import PropTypes from 'prop-types';
import CheckboxPicker from '../../../../common/forms/CheckboxPicker';

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: '12px',
    marginBottom: '12px',
  },
}));

const StratifiedTypes = ({ stratifiedThemes, stratifiedPoints, updateField }) => {
  const classes = useStyles();

  return (
    <BoxHeaderFeatures header="Tipos" helper="Carregue temas e/ou pontos de discussão individuais predefinidos após seleção.">
      <CheckboxPicker onClick={() => updateField('stratifiedThemes', !stratifiedThemes)} checked={stratifiedThemes} label="Estratificação em temas" />
      <Divider className={classes.divider} />
      <CheckboxPicker
        onClick={() => updateField('stratifiedPoints', !stratifiedPoints)}
        checked={stratifiedPoints}
        label="Com pontos de discussão individuais"
      />
    </BoxHeaderFeatures>
  );
};

StratifiedTypes.defaultProps = {
  stratifiedThemes: false,
  stratifiedPoints: false,
};

StratifiedTypes.propTypes = {
  updateField: PropTypes.func.isRequired,
  stratifiedThemes: PropTypes.bool,
  stratifiedPoints: PropTypes.bool,
};

export default React.memo(StratifiedTypes);
