import React, { useCallback, useState, useRef, useContext } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import CardBlock from '../../common/forms/CardBlock';
import FileBox from '../../common/FileBox';
import DialogConfirmAction from '../../common/dialogs/DialogConfirmAction';
import useBooleanToggle from '../../../utils/hooks/useBooleanToggle';
import { CheckProposalDispatchContext, CheckProposalStateContext } from './CheckProposalProvider';
import BoxUpload from '../../common/BoxUpload';
import Body2 from '../../common/typographys/Body2';
import Body1 from '../../common/typographys/Body1';
import { useDispatch, useSelector } from 'react-redux';
import { downloadProposalFile } from '../../../store/actions/proposalsActions';

const useStyles = makeStyles(theme => ({
  label: {
    marginBottom: '8px',
  },
  error: {
    marginTop: '10px',
    color: theme.palette.error.main,
  },
}));

const ProposalAttachments = ({ attachments, editing }) => {
  const classes = useStyles();
  const filesRef = useRef(null);
  const [name, setName] = useState('');
  const [idFile, setIdFile] = useState('');
  const [openModal, toggleModal] = useBooleanToggle();
  const dispatch = useContext(CheckProposalDispatchContext);
  const { id: userId } = useSelector(state => state.auth.user);
  const {
    state: {
      errors,
      editedProposal: {
        proposal_id,
        user: { id },
      },
    },
  } = useContext(CheckProposalStateContext);
  const dispatchRedux = useDispatch();

  const deleteFile = useCallback(
    idAttachment => fileName => e => {
      e.preventDefault();
      setName(fileName);
      setIdFile(idAttachment);
      toggleModal();
    },
    [toggleModal]
  );

  const confirmDeleteFile = useCallback(
    (nameFile, idAttachment) => e => {
      e.preventDefault();
      toggleModal();
      dispatch({
        type: editing ? 'DELETE_EDITED_FILE' : 'DELETE_FILE',
        payload: {
          name: nameFile,
          id: idAttachment,
        },
      });
      setName('');
      setIdFile('');
    },
    [dispatch, editing, toggleModal]
  );

  const handleUpload = useCallback(
    e => {
      e.preventDefault();
      const sameFiles = [];

      const checkFilesPromise = new Promise(resolve => {
        if (filesRef.current.files.length !== 0) {
          Array.from(filesRef.current.files).forEach((x, idx) => {
            if (attachments.find(at => at.name === x.name) !== undefined) {
              sameFiles.push(x.name);
            }
            if (idx === filesRef.current.files.length - 1) {
              resolve(sameFiles);
            }
          });
        }
      });

      checkFilesPromise.then(res => {
        if (res.length > 0) {
          const plural = res.length > 1;
          const pluralS = plural ? 's' : '';
          const pluralM = plural ? 'm' : '';
          const errorMessage = `O${pluralS} ficheiro${pluralS}: ${res.join(
            ', '
          )}, já se encontra${pluralM} adicionado${pluralS} à proposta.`;
          dispatch({
            type: 'SET_ERROR',
            payload: {
              files: errorMessage,
            },
          });
        } else {
          dispatch({
            type: 'SET_ERROR',
            payload: {
              files: '',
            },
          });
        }
        dispatch({
          type: editing ? 'ADD_EDITED_FILES' : 'ADD_FILES',
          payload: {
            value:
              filesRef.current.files.length !== 0
                ? Array.from(filesRef.current.files).filter(
                    x => attachments.find(f => x.name === f.name) === undefined
                  )
                : [],
          },
        });
      });
    },
    [dispatch, editing, attachments]
  );

  const downloadFile = useCallback(
    file => e => {
      e.preventDefault();

      dispatchRedux(downloadProposalFile(proposal_id, file.id, file.name));
    },
    [dispatchRedux, proposal_id]
  );

  return (
    <>
      {attachments && attachments.length > 0 && (
        <CardBlock label="Ficheiros" singlePadding smallMargin>
          {attachments.map(file => (
            <FileBox
              key={file.id || file.name}
              file={file}
              download
              remove={
                userId === id && ((file.id && file.user && file.user.id === userId) || !file.id)
              }
              deleteFile={deleteFile(file.id)}
              downloadFile={downloadFile}
            />
          ))}
          {errors.files && errors.files.length > 0 && (
            <Body1 className={classes.error}>{errors.files}</Body1>
          )}
        </CardBlock>
      )}
      {attachments.length > 0 ? (
        <Box height="14px" />
      ) : (
        <Body2 secondary className={classes.label}>
          Ficheiro(s)
        </Body2>
      )}
      <BoxUpload filesRef={filesRef} handleUpload={handleUpload} padding />
      <DialogConfirmAction
        open={openModal}
        handleClose={toggleModal}
        handleSubmit={confirmDeleteFile(name, idFile)}
        msg={`Deseja eliminar o ficheiro ${name}?`}
        labelSubmit="Eliminar"
      />
    </>
  );
};

ProposalAttachments.defaultProps = {
  attachments: [],
  editing: false,
};

ProposalAttachments.propTypes = {
  attachments: PropTypes.array,
  editing: PropTypes.bool,
};

export default React.memo(ProposalAttachments);
