import React from 'react';
import { makeStyles, Grid, Divider } from '@material-ui/core';
import Body2 from '../../common/typographys/Body2';

const useStyles = makeStyles(theme => ({
  itemFlexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  specialItem: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  noVerticalPadding: {
    paddingTop: '0px!important',
    paddingBottom: '0px!important',
  },
  dividerProposal: {
    marginTop: '10px',
    marginBottom: '14px',
  },
  hoverable: {
    paddingTop: '14px!important',
    paddingBottom: '10px!important',
    '&:hover': {
      cursor: 'pointer',
      '& p': {
        transition: 'color 0.1s ease',
        color: theme.palette.primary.A700,
      },
    },
  },
}));

const EmptyProposalEntry = () => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} className={classes.itemFlexCenter}>
        <Body2 oneLine>Nenhuma proposta satisfaz os filtros aplicados...</Body2>
      </Grid>
      <Grid item xs={12} className={classes.noVerticalPadding}>
        <Grid container justify="flex-end">
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default EmptyProposalEntry;
