import axios from 'axios';
import {
  GET_THEMES,
  CREATE_THEME,
  UPDATE_THEME,
  GET_ERRORS,
  SHOW_SNACK,
  THEMES_LOADING,
  THEMES_NOT_LOADING,
  REMOVE_THEME,
} from './types';

export const getThemes = () => dispatch => {
  dispatch(setThemesLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/themes`)
    .then(res => {
      dispatch({
        type: GET_THEMES,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(setThemesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const createTheme = info => dispatch => {
  dispatch(setThemesLoading());

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/themes`, info)
    .then(res => {
      dispatch({
        type: CREATE_THEME,
        payload: { ...res.data, can_delete: true },
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Tema criado com sucesso.',
        },
      });
    })
    .catch(err => {
      dispatch(setThemesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateTheme = (id, info) => dispatch => {
  dispatch(setThemesLoading());

  axios
    .put(`${process.env.REACT_APP_API_URL}/api/themes/${id}`, info)
    .then(res => {
      dispatch({
        type: UPDATE_THEME,
        payload: res.data,
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Tema atualizado com sucesso.',
        },
      });
    })
    .catch(err => {
      dispatch(setThemesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const removeTheme = (id, callback) => dispatch => {
  dispatch(setThemesLoading());

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/themes/${id}/disable`)
    .then(() => {
      dispatch({
        type: REMOVE_THEME,
        payload: {
          id,
        },
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Tema excluído com sucesso.',
        },
      });
      callback();
    })
    .catch(err => {
      dispatch(setThemesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const setThemesLoading = () => {
  return {
    type: THEMES_LOADING,
  };
};

export const setThemesNotLoading = () => {
  return {
    type: THEMES_NOT_LOADING,
  };
};
