import React, { useState, useCallback } from 'react';
import { makeStyles, Box, Input } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  mainBox: {
    display: 'flex',
    width: '100%',
    padding: '8px 10px',
    backgroundColor: theme.palette.neutrals[2],
    height: '40px',
  },
  iconBox: {
    paddingLeft: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  inputBox: {
    width: '100%',
    display: 'flex',
  },
  icon: {
    color: theme.palette.colorsPalette.TrueGreen,
    transform: 'scale(0.666666666)',
    '&:hover': {
      cursor: 'pointer',
      color: '#0B8B00',
    },
  },
  noPadding: {
    minHeight: '0px',
    '& input': {
      padding: 0,
    },
  },
  hide: {
    pointerEvents: 'none',
    opacity: 0.1,
  },
}));

const NewBoxItem = ({ onClick, placeholder }) => {
  const classes = useStyles();
  const [name, setName] = useState('');

  const handleSubmit = useCallback(
    nameInput => {
      onClick(nameInput);
      setName('');
    },
    [onClick]
  );

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.inputBox}>
        <Input
          fullWidth
          name="new-theme"
          value={name}
          onChange={e => setName(e.target.value)}
          variant="standard"
          placeholder={placeholder}
          disableUnderline
          className={classes.noPadding}
          onKeyPress={e => {
            if (e.key === 'Enter' && name.length > 3) {
              handleSubmit(name);
            }
          }}
        />
      </Box>
      <Box className={clsx(classes.iconBox, { [classes.hide]: name.length < 4 })}>
        <AddCircle
          className={classes.icon}
          onClick={e => {
            e.preventDefault();
            handleSubmit(name);
          }}
        />
      </Box>
    </Box>
  );
};

NewBoxItem.defaultProps = {
  placeholder: 'Criar novo tema',
};

NewBoxItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default React.memo(NewBoxItem);
