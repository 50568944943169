import React from 'react';
import { Grid } from '@material-ui/core';
import ViewButton from './ViewButton';
import PropTypes from 'prop-types';

const ButtonsChangeView = ({ view, changeView }) => {
  const common = { view, changeView };

  return (
    <Grid container spacing={1} justify="flex-end">
      <Grid item xs={6} sm={3} xl={2}>
        <ViewButton value="dayGridMonth" label="Mês" {...common} />
      </Grid>
      <Grid item xs={6} sm={3} xl={2}>
        <ViewButton value="timeGridWeek" label="Semana" {...common} />
      </Grid>
      <Grid item xs={6} sm={3} xl={2}>
        <ViewButton value="timeGridDay" label="Dia" {...common} />
      </Grid>
      <Grid item xs={6} sm={3} xl={2}>
        <ViewButton value="cronologia" label="Cronologia" {...common} />
      </Grid>
    </Grid>
  );
};

ButtonsChangeView.propTypes = {
  view: PropTypes.string.isRequired,
  changeView: PropTypes.func.isRequired,
};

export default React.memo(ButtonsChangeView);
