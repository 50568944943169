import palette from './palette';

export default {
  h1: {
    fontFamily: 'Roboto',
    fontSize: 96,
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.08,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: palette.neutrals[7],
  },
  h2: {
    fontFamily: 'Roboto',
    fontSize: 60,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.1,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: palette.neutrals[7],
  },
  h3: {
    fontFamily: 'Roboto',
    fontSize: 48,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.13,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: palette.neutrals[7],
  },
  h4: {
    fontFamily: 'Roboto',
    fontSize: 34,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.24,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: palette.neutrals[7],
  },
  h5: {
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.33,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: palette.neutrals[7],
  },
  h6: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: palette.neutrals[7],
  },
  subtitle1: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: palette.neutrals[6],
  },
  subtitle: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.71,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: palette.neutrals[6],
  },
  subtitle2: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: palette.neutrals[7],
  },
  subtitle3: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: palette.neutrals[7],
  },
  subtitle4: {
    fontFamily: 'Roboto',
    fontSize: 11,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.45,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: palette.neutrals[7],
  },
  body1: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '24px',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: palette.neutrals[7],
  },
  body2: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.57,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: palette.neutrals[6],
  },
  body3: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.57,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: palette.neutrals[1],
  },
  button: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: palette.neutrals[1],
  },
  buttonSecondary: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#81a37b', // ! falta definir a cor
  },
  buttonSecondaryHover: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: palette.primary.main,
  },
  buttonTertiary: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 2,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: palette.neutrals[5],
  },
  caption: {
    lineHeight: '20px',
    letterSpacing: 0,
    textAlign: 'left',
    fontSize: '12px',
    color: palette.neutrals[6],
  },
  overline: {
    lineHeight: '22px',
    letterSpacing: 0,
    textAlign: 'left',
    fontSize: '12px',
    color: palette.neutrals[5],
  },
};
