import React, { useMemo, useReducer, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from '../../../utils/isEmpty';
import {
  getProposalVersions,
  getProposalMeetings,
  deleteProposalFile,
  updateProposal,
  uploadProposalVersionFiles,
  updateSummaryFile,
} from '../../../store/actions/proposalsActions';
import { useDispatch, useSelector } from 'react-redux';
import checkEmptyObject from '../../../utils/checkEmptyObject';
import { changeMenuSidebar } from '../../../store/actions/navigationActions';
import validEmail from '../../../utils/validation/validEmail';
import { getEntities } from '../../../store/actions/entitiesActions';
import { getThemes } from '../../../store/actions/themesActions';
import { getUsers } from '../../../store/actions/usersActions';
import { getOrganicUnitsParents } from '../../../store/actions/organicUnitsActions';

export const CheckProposalDispatchContext = React.createContext();
export const CheckProposalFuncsContext = React.createContext();
export const CheckProposalStateContext = React.createContext();

const transformParents = (obj, arr, flow) => {
  const auxArr = arr;
  const auxFlow = flow;
  // @ flow.find(x => x.organic_unit_id === obj.id)
  // NOTE: a condição acima apanha um utilizador desta UO no fluxo
  const userInFlow = flow.find(x => x.organic_unit_id === obj.id);
  if (!isEmpty(obj) && userInFlow !== undefined) {
    const clone = { ...obj, user: userInFlow, isApproving: true };

    delete clone.parent;
    auxArr.push(clone);
    if (obj.parent !== null) {
      return transformParents(obj.parent, auxArr, auxFlow);
    }
  }

  if (obj.parent !== null) {
    return transformParents(obj.parent, auxArr, auxFlow);
  }

  return auxArr;
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'UPDATE_PROPOSAL_FIELD':
      return {
        ...state,
        editedProposal: {
          ...state.editedProposal,
          [action.payload.name]: action.payload.value,
        },
      };
    case 'UPDATE_ENTITY_FIELD':
      return {
        ...state,
        editedProposal: {
          ...state.editedProposal,
          entity: {
            ...state.editedProposal.entity,
            [action.payload.name]: action.payload.value,
          },
        },
      };
    case 'UPDATE_ORIGINAL_PROPOSAL_FIELD':
      return {
        ...state,
        proposal: {
          ...state.proposal,
          [action.payload.name]: action.payload.value,
        },
      };
    case 'UPDATE_APPROVER_NOTES':
      return {
        ...state,
        approvers: {
          ...state.approvers,
          [action.payload.user]: {
            ...state.approvers[action.payload.user],
            decisions: state.approvers[action.payload.user].decisions.map((x, idx) =>
              idx === state.approvers[action.payload.user].decisions.length - 1
                ? { ...x, notes: action.payload.value }
                : x
            ),
          },
        },
      };
    case 'ADD_FILES':
      return {
        ...state,
        proposal: {
          ...state.proposal,
          attachments: [...state.proposal.attachments, ...Array.from(action.payload.value)],
        },
      };
    case 'DELETE_FILE':
      return {
        ...state,
        proposal: {
          ...state.proposal,
          attachments: [...state.proposal.attachments.filter(x => x.name !== action.payload.name)],
        },
        deletedFiles: [...state.deletedFiles, action.payload],
      };
    case 'ADD_EDITED_FILES':
      return {
        ...state,
        editedProposal: {
          ...state.editedProposal,
          attachments: [...state.editedProposal.attachments, ...Array.from(action.payload.value)],
        },
      };
    case 'DELETE_EDITED_FILE':
      return {
        ...state,
        editedProposal: {
          ...state.editedProposal,
          attachments: [
            ...state.editedProposal.attachments.filter(x => x.name !== action.payload.name),
          ],
        },
        deletedFiles: [...state.deletedFiles, action.payload],
      };
    case 'ADD_APPROVER_ATTACHMENTS':
      return {
        ...state,
        approvers: {
          ...state.approvers,
          [action.payload.user]: {
            ...state.approvers[action.payload.user],
            decisions: state.approvers[action.payload.user].decisions.map((x, idx) =>
              idx === state.approvers[action.payload.user].decisions.length - 1
                ? { ...x, attachments: [...x.attachments, ...Array.from(action.payload.value)] }
                : x
            ),
          },
        },
      };
    case 'DELETE_APPROVER_ATTACHMENT':
      return {
        ...state,
        approvers: {
          ...state.approvers,
          [action.payload.user]: {
            ...state.approvers[action.payload.user],
            decisions: state.approvers[action.payload.user].decisions.map((x, idx) =>
              idx === state.approvers[action.payload.user].decisions.length - 1
                ? {
                    ...x,
                    attachments: [...x.attachments.filter(f => f.name !== action.payload.name)],
                  }
                : x
            ),
          },
        },
        attachToProposal: state.attachToProposal.filter(name => name !== action.payload.name),
      };
    case 'ATTACH_TO_PROPOSAL':
      return {
        ...state,
        attachToProposal: [...state.attachToProposal, action.payload.name],
      };
    case 'REMOVE_ATTACH_TO_PROPOSAL':
      return {
        ...state,
        attachToProposal: state.attachToProposal.filter(name => name !== action.payload.name),
      };
    case 'CHANGE_PROPOSAL_SUMMARY':
      return {
        ...state,
        summaryFile: action.payload.value,
      };
    case 'UPDATE_APPROVER_ORGANIC_UNIT': {
      const objInUOs = state.organicUnits.find((_, idx) => idx === action.payload.idx);
      if (objInUOs) {
        const oldUser = objInUOs.user;
        const newChange = { in: action.payload.value.id, out: oldUser.id };
        return {
          ...state,
          flowChanges: [...state.flowChanges, newChange],
          organicUnits: state.organicUnits.map((x, idx) =>
            idx === action.payload.idx ? { ...x, user: action.payload.value } : x
          ),
        };
      }

      return state;
    }
    case 'UPDATE_APPROVER_ORGANIC_UNIT_STATUS': {
      const objInUOs = state.organicUnits.find((_, idx) => idx === action.payload.idx);
      if (objInUOs) {
        const objUser = objInUOs.user;
        const add = action.payload.value;
        const newChange = { in: add ? objUser.id : null, out: add ? null : objUser.id };

        return {
          ...state,
          flowChanges: [...state.flowChanges, newChange],
          organicUnits: state.organicUnits.map((x, idx) =>
            idx === action.payload.idx ? { ...x, isApproving: action.payload.value } : x
          ),
        };
      }

      return state;
    }
    case 'SET_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    case 'CLEAR_STATE':
      return {
        ...state,
        proposal: {},
        editedProposal: {},
        approvers: [],
        versions: [],
        attachToProposal: [],
        deletedFiles: [],
        isApprover: false,
        isCreator: false,
        editing: false,
        hasVersions: false,
        hasMeetings: false,
        lastVersionId: null,
        summaryFile: null,
        flowChanges: [],
        open: '',
        errors: {},
      };
    default:
      return state;
  }
};

const CheckProposalProvider = ({ children, id }) => {
  const dispatchRedux = useDispatch();
  const {
    proposal: proposalRedux,
    versions,
    meetings,
    decisions,
  } = useSelector(stateRedux => stateRedux.proposals);
  const { parents } = useSelector(stateRedux => stateRedux.organicUnits);
  const { id: userId } = useSelector(stateRedux => stateRedux.auth.user);
  const [state, dispatch] = useReducer(reducer, {
    proposal: {},
    editedProposal: {},
    approvers: [],
    versions: [],
    deletedFiles: [],
    attachToProposal: [],
    isApprover: false,
    isCreator: false,
    editing: false,
    hasVersions: false,
    hasMeetings: false,
    lastVersionId: null,
    summaryFile: null,
    flowChanges: [],
    organicUnits: [],
    open: '',
    errors: {},
  });
  const { editedProposal, errors, deletedFiles, summaryFile, proposal } = state;
  const {
    name,
    // budgetValue,
    hasBudget,
    budgetNumber,
    description,
    entity,
    amount,
    theme,
    attachments,
    proposal_id,
    internalLink,
    internalLinkText,
    proposal_number,
    on_behalf_of,
  } = editedProposal;
  const { identifier_code } = proposalRedux;

  const updateField = useCallback((nameInput, value) => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: nameInput,
        value,
      },
    });
  }, []);

  const updateProposalField = useCallback((nameInput, value) => {
    dispatch({
      type: 'UPDATE_PROPOSAL_FIELD',
      payload: {
        name: nameInput,
        value,
      },
    });
  }, []);

  const updateOriginalProposalField = useCallback((nameInput, value) => {
    dispatch({
      type: 'UPDATE_ORIGINAL_PROPOSAL_FIELD',
      payload: {
        name: nameInput,
        value,
      },
    });
  }, []);

  useEffect(() => {
    if (isEmpty(proposal.current_decision) && !isEmpty(decisions.current_decision)) {
      dispatch({
        type: 'UPDATE_ORIGINAL_PROPOSAL_FIELD',
        payload: {
          name: 'current_decision',
          value: decisions.current_decision,
        },
      });
    }
  }, [decisions.current_decision, proposal.current_decision]);

  useEffect(() => {
    if (!isEmpty(identifier_code)) {
      dispatchRedux(changeMenuSidebar('proposta', 'Propostas', `Proposta - ${identifier_code}`));
    }
  }, [dispatchRedux, identifier_code]);

  useEffect(() => {
    dispatchRedux(getProposalVersions(id));
    dispatchRedux(getProposalMeetings(id));
    dispatchRedux(getEntities());
    dispatchRedux(getThemes());
    dispatchRedux(getUsers());

    return () => {
      dispatch({
        type: 'CLEAR_STATE',
      });
      dispatchRedux({ type: 'GET_PROPOSAL', payload: {} });
      dispatchRedux({ type: 'GET_PROPOSAL_VERSIONS', payload: [] });
      dispatchRedux({ type: 'GET_PROPOSAL_MEETINGS', payload: [] });
      dispatchRedux({ type: 'GET_PROPOSAL_DECISIONS', payload: [] });
    };
  }, [id, dispatchRedux]);

  useEffect(() => {
    if (!isEmpty(proposalRedux)) {
      updateField('proposal', {
        ...proposalRedux,
        internalLink: proposalRedux.internal_link || '',
        internalLinkText: proposalRedux.internal_link_text || '',
        organicUnit: proposalRedux.organic_unit,
        hasBudget: proposalRedux.has_budget,
        budgetNumber: proposalRedux.budget_number,
      });
      updateField('editedProposal', {
        ...proposalRedux,
        internalLink: proposalRedux.internal_link || '',
        internalLinkText: proposalRedux.internal_link_text || '',
        organicUnit: proposalRedux.organic_unit,
        hasBudget: proposalRedux.has_budget,
        budgetNumber: proposalRedux.budget_number,
      });
    }
  }, [proposalRedux, updateField]);

  useEffect(() => {
    if (proposalRedux.organic_unit && proposalRedux.organic_unit.id) {
      dispatchRedux(getOrganicUnitsParents(proposalRedux.organic_unit.id));
    }
  }, [dispatchRedux, proposalRedux.organic_unit]);

  useEffect(() => {
    if (!isEmpty(parents) && !isEmpty(proposalRedux.flow)) {
      dispatch({
        type: 'UPDATE_FIELD',
        payload: {
          name: 'organicUnits',
          value: transformParents(parents, [], proposalRedux.flow),
        },
      });
    }
  }, [parents, proposalRedux.flow]);

  useEffect(() => {
    if (!isEmpty(proposalRedux.flow) && proposalRedux.decisions) {
      const approvers = proposalRedux.flow.reduce((acc, cur) => {
        // console.groupCollapsed('Current');
        acc[`user-${cur.id}`] = acc[`user-${cur.id}`] || {
          user: {},
          decisions: [],
          history: [],
          answered: false,
        };
        const userDecisions = proposalRedux.decisions
          .map(x => (x.user && x.user.id === cur.id ? x : null))
          .filter(x => x !== null);
        // console.log(cur);
        // console.log('userDecisions', userDecisions);
        if (userDecisions.length > 0) {
          // @ Há respostas deste utilizador
          // console.log('proposalRedux.current_decision', proposalRedux.current_decision);
          if (
            proposalRedux.current_decision &&
            proposalRedux.current_decision.user &&
            proposalRedux.current_decision.user.id === cur.id
          ) {
            // @ É este utilizador a responder
            acc[`user-${cur.id}`].decisions = [
              {
                ...proposalRedux.current_decision,
                ...cur,
                answer: null,
                notes: '',
                attachments: [],
              },
            ];
            // console.log('acc[`user-${cur.id}`]', acc[`user-${cur.id}`]);
          } else {
            acc[`user-${cur.id}`].decisions = userDecisions;
            acc[`user-${cur.id}`].answered = true;
            // console.log('Else', acc[`user-${cur.id}`]);
          }

          // @ FILTRAR DECISÕES RECEBIDAS PARA
          // ? cur.id -> ESTE UTILIZADOR:
          acc[`user-${cur.id}`].history = proposalRedux.decisions.filter(
            x =>
              (x.answer_to && x.answer_to.id === cur.id) || // * 1- Decisões cuja resposta eram direcionadas a ESTE UTILIZADOR
              (x.user && x.user.id === cur.id) || // * 2- Decisões efetuadas por ESTE UTILIZADOR
              (x.asked_to && x.asked_to.id === cur.id) || // * 3- Decisões onde foi pedida revisão a ESTE UTILIZADOR
              (x.subs && x.subs.indexOf(cur.id) !== -1) // * 4- Decisões onde ESTE UTILIZADOR não estava presente, mas entrou como substituto
          );
        } else {
          // @ Utilizador nunca respondeu
          acc[`user-${cur.id}`].decisions = [
            {
              ...cur,
              answer: null,
              notes: '',
              attachments: [],
            },
          ];
        }

        acc[`user-${cur.id}`].user = cur;

        // console.log('Fim do user');
        // console.log(acc[`user-${cur.id}`]);
        // console.groupEnd();

        return acc;
      }, {});

      const answeredApprovers = Object.keys(approvers).reduce((acc, cur) => {
        if (approvers[cur].answered) {
          acc.push(approvers[cur].user);
          return acc;
        }

        return acc;
      }, []);

      updateField('approvers', approvers);
      updateField('answeredApprovers', answeredApprovers);
    }
  }, [proposalRedux.flow, proposalRedux.decisions, proposalRedux.current_decision, updateField]);

  useEffect(() => {
    updateField('versions', versions);
    if (versions.length === 1) {
      updateField('lastVersionId', versions[0].id);
    }
  }, [versions, updateField]);

  useEffect(() => {
    updateField('meetings', meetings);
  }, [meetings, updateField]);

  useEffect(() => {
    if (proposalRedux.flow && proposalRedux.flow.findIndex(x => x.id === userId) !== -1) {
      updateField('isApprover', true);
    }
    if (proposalRedux.user && proposalRedux.user.id === userId) {
      updateField('isCreator', true);
    }
  }, [userId, proposalRedux.flow, proposalRedux.user, updateField]);

  // @ Campos no editar
  useEffect(() => {
    if (name && name.length > 2 && !isEmpty(errors.name)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { name: '' },
      });
    }
  }, [errors.name, name]);

  // useEffect(() => {
  //   if (!isEmpty(budgetValue) && !isEmpty(errors.budgetValue)) {
  //     dispatch({
  //       type: 'SET_ERROR',
  //       payload: { budgetValue: '' },
  //     });
  //   }

  //   if (!hasBudget) {
  //     dispatch({
  //       type: 'SET_ERROR',
  //       payload: { budgetValue: '' },
  //     });
  //   }
  // }, [errors.budgetValue, hasBudget, budgetValue]);

  useEffect(() => {
    if (!isEmpty(budgetNumber) && !isEmpty(errors.budgetNumber)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { budgetNumber: '' },
      });
    }

    if (!hasBudget) {
      dispatch({
        type: 'SET_ERROR',
        payload: { budgetNumber: '' },
      });
    }
  }, [errors.budgetNumber, hasBudget, budgetNumber]);

  const submitUpdateProposal = useCallback(
    e => {
      e.preventDefault();
      const localErrors = {};

      if (name.length < 3) {
        localErrors.name = 'Insira o nome para a proposta.';
      }

      // if (hasBudget && isEmpty(budgetValue)) {
      //   localErrors.budgetValue = 'Defina o valor do cabimento.';
      // }

      if (hasBudget && isEmpty(budgetNumber)) {
        localErrors.budgetNumber = 'Defina o número do cabimento.';
      }

      if (!isEmpty(entity) && entity.isNew) {
        // @ Validar erros da nova entidade
        if (isEmpty(entity.name)) {
          localErrors.entityName = 'Insira um nome válido para a entidade.';
        }

        if (isEmpty(entity.nif)) {
          localErrors.entityNif = 'Insira um NIF válido para a entidade.';
        }

        if (isEmpty(entity.email)) {
          localErrors.entityEmail = 'Insira um email para a entidade.';
        }

        if (!validEmail(entity.email)) {
          localErrors.entityEmail = 'Insira um email válido para a entidade.';
        }
      }

      if (checkEmptyObject(localErrors).length > 0) {
        return dispatch({
          type: 'SET_ERROR',
          payload: localErrors,
        });
      }

      const proposalValues = {
        name,
        description,
        entity,
        amount,
        themeId: theme ? theme.id : null,
        // budgetValue,
        hasBudget,
        budgetNumber,
        internalLink,
        internalLinkText,
        proposalNumber: proposal_number,
        onBehalfOf: on_behalf_of?.id
          ? {
              id: on_behalf_of.id,
              name: on_behalf_of.name,
            }
          : null,
      };

      const proposalPromise = new Promise((resolve, reject) => {
        dispatchRedux(updateProposal(proposal_id, proposalValues, resolve, reject));
      });

      return proposalPromise
        .then(res => {
          // @ res é a resposta da promise resolvida no createProposal (assim, obtemos o id da proposta guardada para associar os attachments a ela)
          updateField('editing', false);

          const summaryPromise = new Promise((resolve, reject) => {
            if (summaryFile && summaryFile.name) {
              //  @ Foi submetido um ficheiro de alteração do resumo
              const formData = new FormData();
              formData.append('file', summaryFile);
              dispatchRedux(updateSummaryFile(proposal_id, formData, resolve, reject));
            } else {
              resolve();
            }
          });

          summaryPromise
            .then(() => {
              const deleteFilesPromise = new Promise(resolve => {
                if (deletedFiles.length > 0) {
                  deletedFiles.forEach((x, idx) => {
                    if (x.id) {
                      dispatchRedux(deleteProposalFile(proposal_id, x.id));
                    }

                    if (idx === deletedFiles.length - 1) {
                      resolve();
                    }
                  });
                } else {
                  resolve();
                }
              });

              deleteFilesPromise.then(() => {
                const newFiles = attachments.reduce((acc, cur) => {
                  if (cur.id) {
                    return acc;
                  }

                  acc.push(cur);
                  return acc;
                }, []);

                if (newFiles.length > 0) {
                  // @ Existem novos ficheiros associados a esta proposta
                  const attachmentsPromise = new Promise(resolve => {
                    const formData = new FormData();
                    newFiles.forEach((file, idx) => {
                      formData.append('attachments[][file]', file);
                      if (idx === newFiles.length - 1) {
                        resolve(formData);
                      }
                    });
                  });

                  attachmentsPromise.then(formData => {
                    const uploadPromise = new Promise((resolve, reject) => {
                      const filesLength = newFiles.length;
                      dispatchRedux(
                        uploadProposalVersionFiles(
                          formData,
                          filesLength,
                          res.id,
                          res.version_id,
                          resolve,
                          reject
                        )
                      );
                    });

                    uploadPromise
                      .then(() => {
                        return updateField('editing', false);
                      })
                      .catch(() => {
                        dispatch({
                          type: 'NOT_SUBMITTING',
                        });
                        return dispatchRedux({
                          type: 'SHOW_SNACK',
                          payload: {
                            variant: 'error',
                            message: 'Ocorreu um erro ao carregar os ficheiros.',
                          },
                        });
                      });
                  });
                } else {
                  return dispatchRedux(getProposalVersions(res.id));
                }

                return dispatchRedux(getProposalVersions(res.id));
              });
            })
            .catch(() => {
              dispatch({
                type: 'SUBMITTING',
              });
              return dispatchRedux({
                type: 'SHOW_SNACK',
                payload: {
                  variant: 'error',
                  message: 'Ocorreu um erro ao alterar o ficheiro de resumo.',
                },
              });
            });
        })
        .catch(() => {
          dispatch({
            type: 'SUBMITTING',
          });
          return dispatchRedux({
            type: 'SHOW_SNACK',
            payload: {
              variant: 'error',
              message: 'Ocorreu um erro ao guardar a nova versão da proposta.',
            },
          });
        });
    },
    [
      name,
      hasBudget,
      budgetNumber,
      entity,
      description,
      amount,
      theme,
      internalLink,
      internalLinkText,
      proposal_number,
      on_behalf_of,
      dispatchRedux,
      proposal_id,
      updateField,
      summaryFile,
      deletedFiles,
      attachments,
    ]
  );

  const contextState = useMemo(
    () => ({ state, submitUpdateProposal }),
    [state, submitUpdateProposal]
  );

  return (
    <CheckProposalDispatchContext.Provider value={dispatch}>
      <CheckProposalFuncsContext.Provider
        value={{ updateField, updateProposalField, updateOriginalProposalField }}
      >
        <CheckProposalStateContext.Provider value={contextState}>
          {children}
        </CheckProposalStateContext.Provider>
      </CheckProposalFuncsContext.Provider>
    </CheckProposalDispatchContext.Provider>
  );
};

CheckProposalProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  id: PropTypes.string.isRequired,
};

export default CheckProposalProvider;
