import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Box, Button } from '@material-ui/core';
import clsx from 'clsx';
import loginRegisterStyles from '../../../common/layout/styles/loginRegister';
import Link from '../../../common/routing/Link';

const FormRegisterStep2 = ({ info }) => {
  const classes = loginRegisterStyles();

  return (
    <Grid container className={classes.fullHeightStep3}>
      <Grid item xl={8} xs={10} className={clsx([classes.marginHorizontal], [classes.flexColumn])}>
        <Typography variant="h6" className={classes.labelMarginRegister}>
          Registo concluído
        </Typography>
        <Box marginTop={3} marginBottom={4}>
          <Typography align="justify">
            <Typography variant="h6" component="span">
              {info.name},
            </Typography>{' '}
            o seu registo foi iniciado com sucesso. De forma a validar o seu registo foi enviado um email para{' '}
            <Typography variant="h6" component="span">
              {info.email}
            </Typography>
            .
          </Typography>
        </Box>
        <Box marginTop={3} marginBottom={4} display="flex" justify="flex-start" className="w-100">
          <Link to="/">
            <Button variant="text" color="primary">
              Página inicial
            </Button>
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
};

FormRegisterStep2.propTypes = {
  info: PropTypes.object.isRequired,
};

export default FormRegisterStep2;
