import isEmpty from './isEmpty';
import { isBefore, isEqual, compareAsc, compareDesc, parseISO, isDate } from 'date-fns';

const transformEvents = (acc, cur) => {
  if (cur.info && cur.info.typology && cur.info.typology.id) {
    acc[cur.info.typology.id] = acc[cur.info.typology.id] || {};
    acc[cur.info.typology.id].borderColor =
      acc[cur.info.typology.id].borderColor || cur.info.typology.color;
    acc[cur.info.typology.id].events = acc[cur.info.typology.id].events || {};
    acc[cur.info.typology.id].events.next = acc[cur.info.typology.id].events.next || {};
    acc[cur.info.typology.id].events.past = acc[cur.info.typology.id].events.past || [];
    acc[cur.info.typology.id].events.future = acc[cur.info.typology.id].events.future || [];

    cur.start = isDate(cur.start) ? cur.start : parseISO(cur.start);
    const dateEvent = cur.start;
    const eventEnded = cur.info.ended_at !== null || cur.status === -1 || cur.status === 3;

    // ! validação de cada evento para saber se o evento atual pertence ao passado, futuro ou próximo
    if (isEmpty(acc[cur.info.typology.id].events.next) && !eventEnded) {
      // @ Não existe nenhum evento no next ainda E o evento não foi realizado ainda

      acc[cur.info.typology.id].events.next = cur;
    } else if (
      !isEmpty(acc[cur.info.typology.id].events.next) &&
      (isBefore(dateEvent, acc[cur.info.typology.id].events.next.start) ||
        isEqual(dateEvent, acc[cur.info.typology.id].events.next.start)) &&
      !eventEnded
    ) {
      // @ Já existe um evento no next E a data do evento é inferior à data atual do next E o evento não foi realizado ainda

      const futureEvent = acc[cur.info.typology.id].events.next;
      acc[cur.info.typology.id].events.next = cur;
      acc[cur.info.typology.id].events.future.push(futureEvent);

      const newFuture = acc[cur.info.typology.id].events.future.sort((a, b) =>
        compareDesc(a.start, b.start)
      );
      acc[cur.info.typology.id].events.future = newFuture;
    } else if (
      !isEmpty(acc[cur.info.typology.id].events.next) &&
      isBefore(acc[cur.info.typology.id].events.next.start, dateEvent) &&
      !eventEnded
    ) {
      // @ A data é superior ao valor do next E o evento não foi realizado

      acc[cur.info.typology.id].events.future.push(cur);

      const newFuture = acc[cur.info.typology.id].events.future.sort((a, b) =>
        compareDesc(a.start, b.start)
      );
      acc[cur.info.typology.id].events.future = newFuture;
    } else {
      // @ Não entrou em nenhuma condição, pertence ao passado

      acc[cur.info.typology.id].events.past.push(cur);

      const newPast = acc[cur.info.typology.id].events.past.sort((a, b) =>
        compareAsc(a.start, b.start)
      );
      acc[cur.info.typology.id].events.past = newPast;
    }
  }

  return acc;
};

export default transformEvents;
