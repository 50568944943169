import React, { useContext, useEffect, useState } from 'react';
import { Box, makeStyles, Input } from '@material-ui/core';
import { MatrixFuncsContext } from '../CreateEditMatrixProvider';
import clsx from 'clsx';
import Body1 from '../../../../common/typographys/Body1';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  mainBox: {
    display: 'flex',
    marginBottom: '10px',
  },
  timeBox: {
    padding: '8px 15px',
    width: '92px',
    marginLeft: '8px',
    backgroundColor: theme.palette.neutrals[2],
    height: '40px',
    marginRight: '17.4px', // 10.8 do icon + 6.6 da margem entre a box e o icon do TimeSlot.js
  },
  labelBox: {
    width: '100%',
    height: '40px',
    padding: '8px 10px',
  },
  inputRoot: {
    ...theme.typography.body1,
    height: theme.typography.body1.lineHeight,
    minHeight: '0px',
  },
  input: {
    padding: 0,
    ...theme.typography.body1,
    height: theme.typography.body1.lineHeight,
  },
  inputApproved: {
    color: theme.palette.colorsPalette.Green,
  },
  inputDisapproved: {
    color: theme.palette.colorsPalette.Tomato,
  },
}));

const MatrixTotal = ({ time, type, individuals, groups, error }) => {
  const classes = useStyles();
  const { getTotalSecs, getTimeValue } = useContext(MatrixFuncsContext);
  const [diff, setDiff] = useState(0);

  useEffect(() => {
    const totalTime = getTotalSecs(time);

    if (type === 'individual') {
      const totalIndividuals = individuals.reduce((acc, cur) => {
        const totalTimeInd = getTotalSecs(cur.time);

        return acc + totalTimeInd;
      }, 0);
      setDiff(totalTime - totalIndividuals);
    } else {
      const totalGroups = groups.reduce((acc, cur) => {
        const totalTimeGroup = getTotalSecs(cur.time);
        return acc + totalTimeGroup;
      }, 0);
      setDiff(totalTime - totalGroups);
    }
  }, [time, type, individuals, groups, getTotalSecs]);

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.labelBox}>{error && <Body1 disapproved>{error}</Body1>}</Box>
      <Box className={classes.timeBox}>
        <Input
          classes={{
            input: clsx(classes.input, { [classes.inputApproved]: diff >= 0, [classes.inputDisapproved]: diff < 0 }),
            root: classes.inputRoot,
          }}
          value={getTimeValue(diff)}
          type="time"
          inputProps={{
            step: 5,
            disabled: true,
          }}
          disableUnderline
        />
      </Box>
    </Box>
  );
};

MatrixTotal.defaultProps = {
  error: null,
};

MatrixTotal.propTypes = {
  time: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  individuals: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  error: PropTypes.string,
};

export default React.memo(MatrixTotal);
