import React, { useContext, useEffect, useMemo, useCallback } from 'react';
import ConfigList from '../../pieces/ConfigList';
import InfoSection from '../../pieces/InfoSection';
import isEmpty from '../../../../utils/isEmpty';
import { ConfigStateContext, ConfigDispatchContext } from '../../MenuConfigProvider';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMatrices, removeMatrix } from '../../../../store/actions/matricesActions';
import CreateEditMatrixProvider from './CreateEditMatrixProvider';
import AddNewMatrix from './AddNewMatrix';
import { getGroups } from '../../../../store/actions/groupsActions';
import { getUsers } from '../../../../store/actions/usersActions';
import SeeEditMatrix from './SeeEditMatrix';

const Matrices = () => {
  const { matrices = [] } = useSelector(state => state.matrices);
  const { permissions } = useSelector(state => state.auth.user);
  const { activeItem, isAddNew, deleting } = useContext(ConfigStateContext);
  const dispatchConfig = useContext(ConfigDispatchContext);
  const dispatchRedux = useDispatch();

  useEffect(() => {
    dispatchRedux(getGroups());
    dispatchRedux(getUsers());
    dispatchRedux(getAllMatrices());
  }, [dispatchRedux]);

  const hasPagination = useMemo(() => matrices.length > 10, [matrices]);
  const pageObj = useMemo(() => ({ isItemMatrix: true }), []);

  const handleDelete = useCallback(
    e => {
      e.preventDefault();
      dispatchRedux(
        removeMatrix(activeItem.id, () =>
          dispatchConfig({
            type: 'CANCEL_ADD_NEW',
          })
        )
      );
    },
    [dispatchRedux, activeItem, dispatchConfig]
  );

  return (
    <>
      <ConfigList
        items={matrices}
        pageObj={pageObj}
        labelButton="Nova matriz"
        noItemMessage="Nenhuma matriz adicionada."
        msgDelete={`Tem a certeza que deseja excluir a matriz${activeItem && activeItem.name ? `, ${activeItem.name}` : ''}?`}
        labelSubmit="Excluir"
        canDelete={Boolean(permissions && permissions.others && permissions.others.matrices)}
        canAddNew={permissions && permissions.others && permissions.others.matrices}
        handleDelete={handleDelete}
      />
      <InfoSection hasPagination={hasPagination}>
        <CreateEditMatrixProvider items={matrices}>
          {!isEmpty(activeItem) && !deleting && <SeeEditMatrix info={activeItem} />}
          {isAddNew && <AddNewMatrix />}
        </CreateEditMatrixProvider>
      </InfoSection>
    </>
  );
};

export default Matrices;
