import { CHANGE_MENU, SHOW_SNACK } from './types';
import { getNotifications } from './notificationsActions';

/*
 * @desc - Atualiza o menu que o utilizador se encontra, mantendo após refresh
 * @param - menu atual da página onde o utilizador se encontra
 */
export const changeMenuSidebar = (sidebar, text, secondLevel = '') => dispatch => {
  dispatch(getNotifications());
  dispatch({
    type: CHANGE_MENU,
    payload: { sidebar, text, secondLevel },
  });
};

export const clearSnack = () => dispatch => {
  dispatch({
    type: SHOW_SNACK,
    payload: {},
  });
};
