import React, { useMemo } from 'react';
import { makeStyles, Box, Grid } from '@material-ui/core';
import { RecordVoiceOver } from '@material-ui/icons';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Body1 from '../../../../common/typographys/Body1';

const useStyles = makeStyles(theme => ({
  proposalEntry: {
    padding: '12px 14px',
    marginTop: '8px',
    backgroundColor: theme.palette.neutrals[3],
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.primary[100],
    },
  },
  proposalSelected: {
    background: theme.palette.primary[100],
    borderLeft: `6px solid ${theme.palette.primary.A400}`,
    padding: '12px 14px 12px 8px',
    pointerEvents: 'none',
  },
  button: {
    display: 'flex',
  },
  iconContainer: {
    height: '35px',
    width: '35px',
    borderRadius: '100%',
    '&:hover': {
      background: theme.palette.primary[200],
    },
  },
  icon: {
    margin: '5px',
  },
}));

const BoxPeriod = ({ point, handleInfo, handleImmediateDiscussion, activeInfo }) => {
  const classes = useStyles();
  const { name, type } = point;
  const onImmediateClick = cb => e => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    return cb(point, null, null)();
  };

  return (
    <Box
      className={clsx(classes.proposalEntry, {
        [classes.proposalSelected]:
          activeInfo.idxProposal === null &&
          activeInfo.idxTheme === null &&
          activeInfo.type === type,
      })}
      onClick={handleInfo(point, null, null)}
    >
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={9}>
          <Body1>{name}</Body1>
        </Grid>
        {handleImmediateDiscussion && (
          <Grid item xs={3}>
            <Grid container alignItems="flex-end" spacing={1}>
              <Grid xs={6} item />
              <Grid
                xs={6}
                item
                onClick={onImmediateClick(handleImmediateDiscussion)}
                className={classes.button}
              >
                <div className={classes.iconContainer}>
                  <RecordVoiceOver className={classes.icon} />
                </div>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

BoxPeriod.defaultProps = {
  handleImmediateDiscussion: null,
};

BoxPeriod.propTypes = {
  point: PropTypes.object.isRequired,
  handleInfo: PropTypes.func.isRequired,
  activeInfo: PropTypes.object.isRequired,
  handleImmediateDiscussion: PropTypes.func,
};

const AgendaPeriods = ({ isPublic, handleInfo, handleImmediateDiscussion, activeInfo }) => {
  const points = useMemo(() => {
    const aux = [{ type: 'before-day-order', name: 'Antes da ordem do dia' }];

    if (isPublic) {
      return [{ type: 'public-discussion', name: 'Intervenção do público' }, ...aux];
    }

    return aux;
  }, [isPublic]);

  return points.map(point => (
    <BoxPeriod
      key={point.type}
      point={point}
      handleInfo={handleInfo}
      activeInfo={activeInfo}
      handleImmediateDiscussion={handleImmediateDiscussion}
    />
  ));
};

AgendaPeriods.defaultProps = {
  activeInfo: {},
};

AgendaPeriods.propTypes = {
  isPublic: PropTypes.bool.isRequired,
  handleInfo: PropTypes.func.isRequired,
  handleImmediateDiscussion: PropTypes.func.isRequired,
  activeInfo: PropTypes.object,
};

export default React.memo(AgendaPeriods);
