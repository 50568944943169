import React from 'react';
import { Chip } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const MultiValue = props => {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<Cancel {...props.removeProps} />}
    />
  );
};

MultiValue.defaultProps = {
  children: null,
  isFocused: null,
};

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

export default MultiValue;
