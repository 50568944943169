import React, { useEffect, useContext, useCallback, useMemo } from 'react';
import SeeItem from '../../pieces/SeeItem';
import PropTypes from 'prop-types';
import LabelAndValue from '../../../common/forms/LabelAndValue';
import { OrganicUnitFuncsContext } from './CreateEditOrganicUnitProvider';
import EditOrganicUnit from './EditOrganicUnit';
import { ConfigStateContext, ConfigDispatchContext } from '../../MenuConfigProvider';
import isEmpty from '../../../../utils/isEmpty';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import OrganicUnitChildren from './pieces/OrganicUnitChildren';
import OrganicUnitUsers from './pieces/OrganicUnitUsers';
import TimeBox from '../../pieces/TimeBox';

const SeeEditOrganicUnits = ({ info }) => {
  const dispatchConfig = useContext(ConfigDispatchContext);
  const { editing } = useContext(ConfigStateContext);
  const { updateField } = useContext(OrganicUnitFuncsContext);
  const { children, users } = useSelector(state => state.organicUnits);
  const { permissions } = useSelector(state => state.auth.user);
  const hasUsers = useMemo(() => !isEmpty(users), [users]);
  const hasChildren = useMemo(() => !isEmpty(children), [children]);

  useEffect(() => {
    updateField('name', info.originalName);
  }, [info.originalName, updateField]);

  useEffect(() => {
    updateField('id', info.id);
    updateField('code', info.code);
    updateField('parent', info.parent);
    updateField('user', info.user);
    updateField('abbreviation', info.abbreviation);
  }, [info, updateField]);

  const startEdit = useCallback(
    e => {
      e.preventDefault();
      dispatchConfig({
        type: 'SET_EDITING',
      });
    },
    [dispatchConfig]
  );

  const deleteItem = useCallback(
    e => {
      e.preventDefault();
      dispatchConfig({
        type: 'DELETING',
      });
    },
    [dispatchConfig]
  );

  // @ Se o grupo tiver reuniões associadas, não pode ser eliminado
  return editing ? (
    <EditOrganicUnit />
  ) : (
    <SeeItem
      submitEdit={startEdit}
      canDelete={Boolean(permissions && permissions.meetings && permissions.meetings.secretary)}
      deleteItem={deleteItem}
    >
      <LabelAndValue label="Código" value={info.code} />
      <LabelAndValue label="Nome" value={info.originalName} />
      <LabelAndValue label="Abreviatura" value={info.abbreviation} />
      <LabelAndValue
        label="Responsável"
        value={(info.user && info.user.name) || 'Sem Responsável associado.'}
      />
      <LabelAndValue
        label="Unidade Orgânica Superior"
        value={(info.parent && info.parent.name) || 'Sem Unidade Orgânica Superior.'}
      />
      {(hasUsers || hasChildren) && (
        <TimeBox borderLeft>
          {hasChildren && <OrganicUnitChildren items={children} />}
          {hasUsers && <Box height={hasChildren ? '14px' : '0px'} />}
          {hasUsers && <OrganicUnitUsers items={users} />}
        </TimeBox>
      )}
    </SeeItem>
  );
};

SeeEditOrganicUnits.propTypes = {
  info: PropTypes.object.isRequired,
};

export default React.memo(SeeEditOrganicUnits);
