import { fade } from '@material-ui/core/styles';
import palette from '../palette';

export default {
  root: {
    '&:hover': {
      color: `${fade(palette.primary.A700, 0.7)}!important`,
    },
    '&:focus': {
      color: `${fade(palette.primary.A700, 0.7)}!important`,
    },
  },
  active: {
    color: `${fade(palette.primary.A700, 0.7)}!important`,
  },
  icon: {
    color: `${fade(palette.primary.A700, 0.7)}!important`,
  },
};
