import React from 'react';
import { makeStyles, Divider } from '@material-ui/core';
import H6 from '../../../../common/typographys/H6';
import LabelAndValue from '../../../../common/forms/LabelAndValue';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: '8px',
    marginBottom: '20px',
  },
}));

const ProposalStatuses = ({ content }) => {
  const classes = useStyles();
  const { statuses } = content;

  return (
    <>
      <H6>Estados associados a propostas</H6>
      <Divider className={classes.divider} />
      {statuses.map(entry => (
        <LabelAndValue key={entry.id} value={entry.label} label={entry.explanation} />
      ))}
    </>
  );
};

ProposalStatuses.propTypes = {
  content: PropTypes.object.isRequired,
};

export default React.memo(ProposalStatuses);
