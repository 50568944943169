import React, { useEffect, useCallback, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getMeeting,
  getMeetingMatrices,
  downloadMeetingFile,
  downloadMeetingBriefFile,
  downloadMeetingZipContent,
  downloadMeetingNotes,
  downloadMeetingMinute,
  getMeetingUserDeclarations,
  uploadMinuteFile,
  uploadAtaFile,
  downloadMeetingAta,
  uploadDeliberationsFile,
  downloadDeliberations,
} from '../../../store/actions/meetingsActions';
import isEmpty from '../../../utils/isEmpty';
import FullWidthSpinner from '../../common/FullWidthSpinner';
import { makeStyles } from '@material-ui/styles';
import { changeMenuSidebar } from '../../../store/actions/navigationActions';
import { Grid, Box } from '@material-ui/core';
import HeaderCheck from './pieces/HeaderCheck';
import CardBlock from '../../common/forms/CardBlock';
import LabelAndValue from '../../common/forms/LabelAndValue';
import formatDate from '../../../utils/formatDate';
import ThemeList from '../../common/ThemeList';
import Subtitle2 from '../../common/typographys/Subtitle2';
import MeetingBoxList from '../../common/MeetingBoxList';
import ListParticipants from './pieces/ListParticipants';
import FileBox from '../../common/FileBox';
import Body1 from '../../common/typographys/Body1';
import Subtitle1 from '../../common/typographys/Subtitle1';
import flatten from 'lodash/flatten';
import PendingDeclaration from './pieces/PendingDeclaration';
import BoxUpload from '../../common/BoxUpload';
import ButtonNormal from '../../common/buttons/ButtonNormal';
import ProposalInternalLink from '../../proposals/pieces/ProposalInternalLink';
import SocketReunionWrapper from '../meeting/SocketReunionWrapper';

const useStyles = makeStyles(() => ({
  margin: {
    marginTop: '54px',
  },
  noThemes: {
    marginBottom: '20px',
  },
  center: {
    justifyContent: 'center',
  },
  label: {
    marginBottom: '8px',
  },
  topLabel: {
    marginTop: '8px',
  },
}));

const checkUserInMeeting = (users = [], id, isGroup) => {
  if (isGroup) {
    const allUsers = flatten(users.map(group => group.users).map(user => user));
    const userObj = allUsers.find(x => x.id === id) || {};

    return {
      inMeeting: Boolean(!isEmpty(userObj) && userObj.in_meeting),
      accepted: userObj.accepted,
    };
  }

  const userObj = users.find(x => x.id === id) || {};

  return {
    inMeeting: Boolean(!isEmpty(userObj) && userObj.in_meeting),
    accepted: userObj.accepted,
  };
};

const checkUserInBoard = (board = { secretary: [], moderator: [] }, id) => {
  const flattenBoard = [...board.secretary, ...board.moderator];
  const userObj = flattenBoard.find(x => x.id === id) || {};

  return !isEmpty(userObj);
};

const CheckMeeting = () => {
  const { id } = useParams();
  const { meeting = {}, loading, declarations = [] } = useSelector(state => state.meetings);
  const { permissions, id: userId } = useSelector(state => state.auth.user);
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    name,
    typology,
    date,
    local,
    themes,
    points,
    is_group,
    users,
    attachments,
    brief_available,
    brief_file_name,
    status,
    minutes_path,
    is_public,
    brief_changed,
    board,
    updated_at,
    minutes_filename,
    ata_filename,
    ata_path,
    deliberations_notice_path,
    deliberations_notice_filename,
    video_conference_link: videoConferenceLink,
  } = meeting;
  const [checkUser, setCheckUser] = useState(true);
  const minuteFileRef = useRef(null);
  const [minuteFile, setMinuteFile] = useState(null);
  const ataFileRef = useRef(null);
  const [ataFile, setAtaFile] = useState(null);
  const deliberationsFileRef = useRef(null);
  const [deliberationsFile, setDeliberationsFile] = useState(null);
  const briefAvailable = useMemo(() => Boolean(brief_available), [brief_available]);
  const meetingFinished = useMemo(() => status === 3, [status]);
  const briefChange = useMemo(() => Boolean(brief_changed), [brief_changed]);
  const canSend = useMemo(
    () => permissions && permissions.meetings && permissions.meetings.create,
    [permissions]
  );
  const meetingUser = useMemo(
    () => checkUserInMeeting(users, userId, Boolean(is_group)),
    [users, is_group, userId]
  );
  const userInBoard = useMemo(() => checkUserInBoard(board, userId), [board, userId]);
  const isMeetingDone = useMemo(() => status === 3, [status]);
  const [downloadAgendaSpinner, setDownloadAgendaSpinner] = useState(false);
  const [downloadMinuteLoading, setDownloadMinuteLoading] = useState(false);
  const [downloadAtaLoading, setDownloadAtaLoading] = useState(false);
  const [downloadDeliberationsLoading, setDownloadDeliberationsLoading] = useState(false);
  const pendingDeclarations = useMemo(
    () => declarations.filter(x => x.type === 'file').filter(x => x.file === null),
    [declarations]
  );
  const meetingZipName = useMemo(() => `Agenda-${formatDate(date, 'yyyyMMdd')}.zip`, [date]);
  const isSecretary = useMemo(() => {
    // if (!isEmpty(board)) {
    //   return board.secretary.find(x => x.id === userId);
    // }

    return permissions && permissions.meetings && permissions.meetings.secretary;
  }, [permissions]);

  const isInBoard = useMemo(
    () => board && board.secretary && board.secretary.find(x => x.id === userId),
    [board, userId]
  );

  const showMeeting = useMemo(
    () =>
      !loading &&
      !isEmpty(meeting) &&
      (userInBoard ||
        meetingUser.inMeeting ||
        (permissions && permissions.meetings && permissions.meetings.secretary)),
    [loading, meeting, userInBoard, meetingUser, permissions]
  );

  useEffect(() => {
    if (checkUser) {
      setTimeout(() => {
        setCheckUser(false);
      }, 10000);
    }
  }, [checkUser]);

  useEffect(() => {
    dispatch(changeMenuSidebar('reunioes', 'Reuniões', 'Consultar reunião'));
    return () => {
      dispatch({
        type: 'GET_MEETING',
        payload: {},
      });
      dispatch({
        type: 'USER_MEETING_DECLARATIONS',
        payload: [],
      });
      dispatch({
        type: 'USER_MEETING_DECLARATIONS_CHANGES',
        payload: [],
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (id && userId) {
      dispatch(getMeeting(id));
      dispatch(getMeetingMatrices(id));
      dispatch(getMeetingUserDeclarations(id, userId));
    }
  }, [dispatch, id, userId]);

  const downloadFile = useCallback(
    file => e => {
      e.preventDefault();
      dispatch(downloadMeetingFile(id, file.id, file.name));
    },
    [dispatch, id]
  );

  const downloadBrief = useCallback(
    file => e => {
      e.preventDefault();

      dispatch(downloadMeetingBriefFile(id, file.name));
    },
    [dispatch, id]
  );

  const downloadZipReuniao = useCallback(
    file => async e => {
      e.preventDefault();
      setDownloadAgendaSpinner(true);
      const agendaPromise = new Promise(resolve => {
        dispatch(downloadMeetingZipContent(id, file.name, resolve));
      });

      await agendaPromise;

      return setDownloadAgendaSpinner(false);
    },
    [dispatch, id]
  );

  const downloadMinute = useCallback(
    file => async e => {
      e.preventDefault();
      setDownloadMinuteLoading(true);
      const minutePromise = new Promise(resolve => {
        dispatch(downloadMeetingMinute(id, file.name, resolve));
      });

      await minutePromise;

      return setDownloadMinuteLoading(false);
    },
    [id, dispatch]
  );

  const downloadAta = useCallback(
    file => async e => {
      e.preventDefault();

      setDownloadAtaLoading(true);
      const ataPromise = new Promise(resolve => {
        dispatch(downloadMeetingAta(id, file.name, resolve));
      });

      await ataPromise;

      return setDownloadAtaLoading(false);
    },
    [id, dispatch]
  );

  const downloadDeliberationsFile = useCallback(
    file => async e => {
      e.preventDefault();

      setDownloadDeliberationsLoading(true);
      const deliberationsPromise = new Promise(resolve => {
        dispatch(downloadDeliberations(id, file.name, resolve));
      });

      await deliberationsPromise;

      return setDownloadDeliberationsLoading(false);
    },
    [id, dispatch]
  );

  const downloadNotes = useCallback(
    file => e => {
      e.preventDefault();
      dispatch(downloadMeetingNotes(id, file.name));
    },
    [id, dispatch]
  );

  const handleChangeMinute = useCallback(e => {
    e.preventDefault();

    if (
      minuteFileRef.current &&
      minuteFileRef.current.files &&
      minuteFileRef.current.files.length === 1
    ) {
      setMinuteFile(minuteFileRef.current.files[0]);
    } else {
      setMinuteFile(null);
    }
  }, []);

  const handleChangeAta = useCallback(e => {
    e.preventDefault();

    if (ataFileRef.current && ataFileRef.current.files && ataFileRef.current.files.length === 1) {
      setAtaFile(ataFileRef.current.files[0]);
    } else {
      setAtaFile(null);
    }
  }, []);

  const handleChangeDeliberations = useCallback(e => {
    e.preventDefault();

    if (
      deliberationsFileRef.current &&
      deliberationsFileRef.current.files &&
      deliberationsFileRef.current.files.length === 1
    ) {
      setDeliberationsFile(deliberationsFileRef.current.files[0]);
    } else {
      setDeliberationsFile(null);
    }
  }, []);

  const submitFiles = useCallback(
    e => {
      e.preventDefault();

      if (minuteFile !== null) {
        const formData = new FormData();
        formData.append('file', minuteFile);
        const minutePromise = new Promise(resolve => {
          dispatch(uploadMinuteFile(formData, id, resolve));
        });

        minutePromise.then(() => {
          setMinuteFile(null);
        });
      }

      if (ataFile !== null) {
        const formDataAta = new FormData();
        formDataAta.append('file', ataFile);

        const ataPromise = new Promise(resolve => {
          dispatch(uploadAtaFile(formDataAta, id, resolve));
        });

        ataPromise.then(() => {
          setAtaFile(null);
        });
      }

      if (deliberationsFile !== null) {
        const formDataDeliberations = new FormData();
        formDataDeliberations.append('file', deliberationsFile);

        const deliberationsPromise = new Promise(resolve => {
          dispatch(uploadDeliberationsFile(formDataDeliberations, id, resolve));
        });

        deliberationsPromise.then(() => {
          setDeliberationsFile(null);
        });
      }
    },
    [minuteFile, ataFile, deliberationsFile, dispatch, id]
  );

  return showMeeting ? (
    <Grid container spacing={3} justify="center" className={classes.margin}>
      <Grid item xs={7}>
        <SocketReunionWrapper>
          <HeaderCheck
            name={name}
            canSend={
              (permissions &&
                permissions.meetings &&
                permissions.meetings.create &&
                status === 1) ||
              false
            }
            canCancel={
              (permissions &&
                permissions.meetings &&
                permissions.meetings.secretary &&
                status === 1) ||
              false
            }
            status={status}
            briefFileName={brief_file_name}
            briefAvailable={briefAvailable}
            briefChange={briefChange}
            meetingUser={meetingUser}
            isGroup={Boolean(is_group)}
            // TODO: atualizar aqui a data
            updateDate={updated_at}
          />
        </SocketReunionWrapper>
        <Box height="18px" />
        {pendingDeclarations.length > 0 && (
          <CardBlock label="Declarações de voto requeridas">
            <Body1>
              Existem declarações de voto pendentes de ficheiro. Carregue aqui o respetivo ficheiro
              para cada proposta.
            </Body1>
            {pendingDeclarations.map(declaration => (
              <PendingDeclaration key={declaration.id} declaration={declaration} userId={userId} />
            ))}
          </CardBlock>
        )}
        {brief_file_name !== null && (
          <CardBlock label="Ficheiros da Agenda da Reunião" flex>
            <FileBox file={{ name: brief_file_name }} download downloadFile={downloadBrief} />
            {briefAvailable && (
              <>
                {/* {!downloadAgendaSpinner ? (
                  <FileBox
                    file={{ name: meetingZipName }}
                    download
                    downloadFile={downloadZipReuniao}
                    blink={downloadAgendaSpinner}
                  />
                ) : (
                  <FileBoxWithProgress file={{ name: meetingZipName }} />
                )} */}
                <FileBox
                  file={{ name: meetingZipName }}
                  download
                  downloadFile={downloadZipReuniao}
                  blink={downloadAgendaSpinner}
                />
              </>
            )}
          </CardBlock>
        )}
        {status === 3 && minutes_path !== null && (
          <>
            <CardBlock label="Minuta, Ata da Reunião e Edital de Deliberações">
              <Subtitle2 tertiary className={classes.label}>
                Minuta da reunião
              </Subtitle2>
              <FileBox
                file={{
                  name: minutes_filename || `Minuta-Ata-${formatDate(date, 'yyyyMMdd')}.docx`,
                }}
                download
                downloadFile={downloadMinute}
                blink={downloadMinuteLoading}
              />
              {(isInBoard || isSecretary) && (
                <>
                  {minuteFile !== null ? (
                    <>
                      <FileBox
                        file={{ name: minuteFile.name }}
                        remove
                        deleteFile={() => e => {
                          e.preventDefault();
                          setMinuteFile(null);
                        }}
                      />
                      <Subtitle2 className={classes.topLabel}>
                        O ficheiro {minuteFile.name} será o novo ficheiro da minuta da ata da
                        reunião após submissão.
                      </Subtitle2>
                    </>
                  ) : (
                    <BoxUpload
                      filesRef={minuteFileRef}
                      handleUpload={handleChangeMinute}
                      multiple={false}
                      labelBox="Carregar ficheiro da minuta"
                      start
                    />
                  )}
                </>
              )}
              <Box height="15px" />
              <Subtitle2 tertiary className={classes.label}>
                Ata da reunião
              </Subtitle2>
              {ata_path !== null ? (
                <FileBox
                  file={{
                    name: ata_filename || `Ata-Reuniao-${formatDate(date, 'yyyyMMdd')}.docx`,
                  }}
                  download
                  downloadFile={downloadAta}
                  blink={downloadAtaLoading}
                />
              ) : (
                <Subtitle2>Ainda não foi submetido o ficheiro da Ata da reunião.</Subtitle2>
              )}
              {(isInBoard || isSecretary) && (
                <>
                  {ataFile !== null ? (
                    <>
                      <FileBox
                        file={{ name: ataFile.name }}
                        remove
                        deleteFile={() => e => {
                          e.preventDefault();
                          setAtaFile(null);
                        }}
                      />
                      <Subtitle2 className={classes.topLabel}>
                        O ficheiro {ataFile.name} será o novo ficheiro da ata da reunião após
                        submissão.
                      </Subtitle2>
                    </>
                  ) : (
                    <BoxUpload
                      filesRef={ataFileRef}
                      handleUpload={handleChangeAta}
                      multiple={false}
                      labelBox="Carregar ficheiro da ata"
                      start
                    />
                  )}
                </>
              )}
              <Box height="15px" />
              <Subtitle2 tertiary className={classes.label}>
                Edital de deliberações da reunião
              </Subtitle2>
              {deliberations_notice_path !== null ? (
                <FileBox
                  file={{
                    name:
                      deliberations_notice_filename ||
                      `Edital-Deliberações-${formatDate(date, 'yyyyMMdd')}.docx`,
                  }}
                  download
                  downloadFile={downloadDeliberationsFile}
                  blink={downloadDeliberationsLoading}
                />
              ) : (
                <Subtitle2>
                  Ainda não foi submetido o ficheiro do Edital de Deliberações da reunião.
                </Subtitle2>
              )}
              {(isInBoard || isSecretary) && (
                <>
                  {deliberationsFile !== null ? (
                    <>
                      <FileBox
                        file={{ name: deliberationsFile.name }}
                        remove
                        deleteFile={() => e => {
                          e.preventDefault();
                          setDeliberationsFile(null);
                        }}
                      />
                      <Subtitle2 className={classes.topLabel}>
                        O ficheiro {deliberationsFile.name} será o novo ficheiro do edital de
                        deliberações da reunião após submissão.
                      </Subtitle2>
                    </>
                  ) : (
                    <BoxUpload
                      filesRef={deliberationsFileRef}
                      handleUpload={handleChangeDeliberations}
                      multiple={false}
                      labelBox="Carregar ficheiro do edital de deliberações"
                      start
                    />
                  )}
                </>
              )}
            </CardBlock>
            {(minuteFile !== null || ataFile !== null) && (
              <Box display="flex" justifyContent="flex-end">
                <ButtonNormal contained pea label="Submeter ficheiro(s)" onClick={submitFiles} />
              </Box>
            )}
          </>
        )}
        {isInBoard && status === 3 && (
          <CardBlock label="Notas da reunião">
            <FileBox
              file={{ name: `Notas-Reuniao-${formatDate(date, 'yyyyMMdd')}.docx` }}
              download
              downloadFile={downloadNotes}
            />
            <Subtitle1 component="p">
              Descarregue as notas efetuadas para cada ponto/período durante a reunião.
            </Subtitle1>
          </CardBlock>
        )}
        <CardBlock label="Detalhes">
          <LabelAndValue label="Nome" value={name} />
          <LabelAndValue label="Tipologia" value={typology && typology.name} />
          <LabelAndValue
            label="Data"
            value={formatDate(date, "dd 'de' MMMM 'de' yyyy 'às' HH:mm")}
          />
          {!isEmpty(videoConferenceLink) && (
            <>
              <Subtitle2 tertiary className={classes.label}>
                Link para videoconferência
              </Subtitle2>
              <ProposalInternalLink link={videoConferenceLink} />
              <Box height="20px" />
            </>
          )}
          {!isEmpty(local) && <LabelAndValue label="Local" value={local && local.name} />}
          <LabelAndValue label="Aberta ao público" value={is_public ? 'Sim' : 'Não'} />
        </CardBlock>
        {(briefAvailable || canSend || meetingFinished) && (
          <>
            <CardBlock label="Temas e pontos de discussão">
              <Subtitle2 tertiary>Temas</Subtitle2>
              <Box height="15px" />
              {themes && themes.length > 0 ? (
                <ThemeList
                  themes={themes}
                  isMeetingDone={isMeetingDone}
                  isGroup={Boolean(is_group)}
                  users={users}
                />
              ) : (
                <Body1 className={classes.noThemes}>Nenhum tema associado a esta reunião.</Body1>
              )}
              <Subtitle2 tertiary>Pontos de discussão</Subtitle2>
              <Box height="15px" />
              {points && points.length > 0 ? (
                <MeetingBoxList
                  points={points}
                  isMeetingDone={isMeetingDone}
                  isGroup={Boolean(is_group)}
                  users={users}
                />
              ) : (
                <Body1>Nenhum ponto de discussão associado a esta reunião.</Body1>
              )}
            </CardBlock>
            <CardBlock label="Participantes">
              <ListParticipants users={users} isGroup={Boolean(is_group)} />
            </CardBlock>
            {attachments && attachments.length > 0 && (
              <CardBlock label="Ficheiros">
                {attachments.map(file => (
                  <FileBox
                    key={file.id || file.name}
                    file={file}
                    download
                    downloadFile={downloadFile}
                  />
                ))}
              </CardBlock>
            )}
          </>
        )}
      </Grid>
    </Grid>
  ) : (
    <FullWidthSpinner className={classes.margin} />
  );
};

export default CheckMeeting;
