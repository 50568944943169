import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body2 from '../../common/typographys/Body2';
import Body1 from '../../common/typographys/Body1';

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: '8px',
  },
}));

const ListMeetingPoints = ({ points }) => {
  const classes = useStyles();

  return (
    <>
      <Body2 secondary className={classes.label}>
        Pontos de discussão
      </Body2>
      {points.map((point, idx) => (
        <React.Fragment key={point.id}>
          <Body1>{point.name}</Body1>
          {idx === points.length - 1 ? <Box height="20px" /> : <Box height="8px" />}
        </React.Fragment>
      ))}
    </>
  );
};

ListMeetingPoints.propTypes = {
  points: PropTypes.array.isRequired,
};

export default ListMeetingPoints;
