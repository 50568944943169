import isEmpty from '../isEmpty';

/**
 *
 * @param {object} permissions Permissions object
 * @returns {boolean} True or false depending if user has permission to see proposals menu
 */
const canSeeProposals = permissions => {
  if (
    !isEmpty(permissions) &&
    permissions.proposals &&
    (permissions.proposals.create ||
      permissions.proposals.edit ||
      permissions.proposals.delete ||
      permissions.proposals.decide ||
      permissions.proposals.show_all)
  ) {
    return true;
  }

  return false;
};

export default canSeeProposals;
