import React, { useCallback } from 'react';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import formatDate from '../../../../utils/formatDate';
import clsx from 'clsx';
import isEmpty from '../../../../utils/isEmpty';
import { AddCircleOutlined } from '@material-ui/icons';
import Body1 from '../../typographys/Body1';
import Subtitle1 from '../../typographys/Subtitle1';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  eventBox: {
    '&:hover': {
      cursor: 'pointer',
      '& .eventColor': {
        height: '100%',
        transition: 'height ease 0.3s',
      },
    },
  },
  iconButton: {
    padding: 0,
    marginRight: '-20px',
    color: theme.palette.primary[200],
    '&:hover': {
      backgroundColor: 'unset',
      color: theme.palette.primary.A400,
    },
  },
  boxInfo: {
    paddingRight: '4px',
    width: '90%',
  },
  title: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'block',
  },
  boxBgColor: {
    width: '4px',
    height: '50%',
    marginRight: '10px',
  },
  boxWidthNext: {
    width: '86%',
  },
  boxWidthFuture: {
    width: '95%',
  },
  common: {
    width: 'calc((100% - 60px) / 7)',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '10px 0px 10px 10px',
    alignItems: 'center',
  },
  boxPast: {
    marginRight: '10px',
    backgroundImage: theme.props.pattern,
  },
  boxNext: {
    marginRight: '10px',
    background: '#FFFFFF',
  },
  lastBox: {
    backgroundImage: theme.props.pattern,
  },
}));

const EventInfo = ({ event, onClick, next, future, last }) => {
  const classes = useStyles();
  const history = useHistory();
  const { profile_id } = useSelector(state => state.auth.user);

  const addWithCronology = useCallback(
    info => e => {
      e.preventDefault();

      history.push(`/reunioes/nova?tipologia=${info.id}`);
    },
    [history]
  );

  return (
    <Box
      className={clsx(classes.common, {
        [classes.eventBox]: !isEmpty(event),
        [classes.lastBox]: last,
        [classes.boxNext]: next,
        [classes.boxPast]: !last && !next,
      })}
    >
      {next && !isEmpty(event) && <Box bgcolor={event.borderColor} className={clsx('eventColor', classes.boxBgColor)} />}
      <Box className={clsx(classes.boxInfo, { [classes.boxWidthNext]: next, [classes.boxWidthFuture]: future })} onClick={onClick(event)}>
        {event.title && <Body1 className={classes.title}>{event.title}</Body1>}
        {event.start && <Subtitle1>{formatDate(event.start, "dd 'de' MMMM 'de' yyyy")}</Subtitle1>}
      </Box>
      {(next || future) && !isEmpty(event) && profile_id === 1 && (
        <IconButton className={classes.iconButton} onClick={addWithCronology(event.info.typology)}>
          <AddCircleOutlined />
        </IconButton>
      )}
    </Box>
  );
};

EventInfo.defaultProps = {
  next: false,
  future: false,
  last: false,
};

EventInfo.propTypes = {
  event: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  next: PropTypes.bool,
  future: PropTypes.bool,
  last: PropTypes.bool,
};

export default EventInfo;
