import typography from '../typography';
import palette from '../palette';

export default {
  root: {
    ...typography.button,
    borderRadius: 0,
    boxShadow: 'none!important',
    minWidth: '90px',
  },
  contained: {
    minHeight: '44px',
  },
  outlined: {
    minHeight: '44px',
  },
  outlinedPrimary: {
    color: `${palette.primary[400]}`,
    border: `solid 1px ${palette.primary[400]}`,
  },
};
