import { GET_EMAILS, GET_EMAIL, UPDATE_EMAIL, EMAILS_LOADING, EMAILS_NOT_LOADING, CREATE_EMAIL, REMOVE_EMAIL } from '../actions/types';

const initialState = {
  templates: [],
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EMAILS:
      return {
        ...state,
        templates: action.payload,
        loading: false,
      };
    case GET_EMAIL:
      return {
        ...state,
        template: action.payload,
        loading: false,
      };
    case CREATE_EMAIL:
      return {
        ...state,
        templates: [action.payload, ...state.templates],
        loading: false,
      };
    case UPDATE_EMAIL:
      return {
        ...state,
        templates: state.templates.map(x => (x.id === action.payload.id ? action.payload : x)),
        loading: false,
      };
    case REMOVE_EMAIL:
      return {
        ...state,
        templates: state.templates.filter(x => x.id !== action.payload.id),
        loading: false,
      };
    case EMAILS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case EMAILS_NOT_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
