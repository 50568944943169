import React, { useEffect, useState, useCallback } from 'react';
import CreateEditProposalsProvider from '../CreateEditProposalsProvider';
import FormInfoProposal from './FormInfoProposal';
import { useDispatch } from 'react-redux';
import { changeMenuSidebar } from '../../../store/actions/navigationActions';
import { useHistory } from 'react-router-dom';

const NewProposal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [change, setChange] = useState(true);

  useEffect(() => {
    dispatch(changeMenuSidebar('proposta', 'Propostas', 'Nova proposta'));
  }, [dispatch]);

  const navigate = useCallback(
    page => {
      setChange(false);
      history.push(page);
    },
    [history]
  );

  return (
    <CreateEditProposalsProvider setChange={setChange} navigate={navigate}>
      <FormInfoProposal change={change} />
    </CreateEditProposalsProvider>
  );
};

export default NewProposal;
