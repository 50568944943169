import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Subtitle2 from '../../../../common/typographys/Subtitle2';
import Body1 from '../../../../common/typographys/Body1';

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: '8px',
  },
  value: {
    marginBottom: '6px',
  },
}));

const OrganicUnitChildren = ({ items }) => {
  const classes = useStyles();
  const pluralS = useMemo(() => (items.length > 1 ? 's' : ''), [items]);
  const pluralES = useMemo(() => (items.length > 1 ? 'es' : ''), [items]);

  return (
    <>
      <Subtitle2 tertiary className={classes.label}>
        Unidade{pluralS} Orgânica{pluralS} Inferior{pluralES}
      </Subtitle2>
      {items.map(child => (
        <Body1 key={child.code} secondary className={classes.value}>{`${child.abbreviation || child.code} - ${child.name}`}</Body1>
      ))}
    </>
  );
};

OrganicUnitChildren.propTypes = {
  items: PropTypes.array.isRequired,
};

export default React.memo(OrganicUnitChildren);
