import React, { useContext } from 'react';
import { MatrixStateContext, MatrixDispatchContext } from './CreateEditMatrixProvider';
import FormInfoMatrix from './FormInfoMatrix';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import UpdateItem from '../../pieces/UpdateItem';

const EditMatrix = () => {
  const {
    state: { errors, items },
    handleSubmit,
    loading,
  } = useContext(MatrixStateContext);
  const dispatch = useContext(MatrixDispatchContext);

  return (
    <UpdateItem disableSubmit={checkEmptyObject(errors).length > 0} loading={loading} items={items} dispatch={dispatch} handleSubmit={handleSubmit}>
      <FormInfoMatrix />
    </UpdateItem>
  );
};

export default EditMatrix;
