/* eslint-disable no-unused-vars */
import axios from 'axios';

import {
  GET_MEETINGS,
  GET_MEETING,
  MEETING_POOL,
  MEETING_LOCALS,
  CREATE_MEETING,
  UPDATE_MEETING,
  GET_ERRORS,
  SHOW_SNACK,
  GET_MEETING_MATRICES,
  MEETINGS_LOADING,
  MEETINGS_NOT_LOADING,
  CANCEL_MEETING,
  DELETE_MEETING,
  SET_USER_ACCEPTED,
  MEETING_SECRETARIES,
  MEETING_MODERATORS,
  BRIEF_AVAILABLE,
  SET_PROGRESS,
  REMOVE_DECLARATION,
  USER_MEETING_DECLARATIONS,
  USER_MEETING_POINT_DECLARATIONS,
  USER_MEETING_DECLARATIONS_CHANGES,
  UPDATE_MEETING_INFO,
  UPDATE_PROPOSAL_MEETING_DELIBERATION_NUMBER,
  UPDATE_PROPOSAL_MEETING_DELIBERATION_FILE_NAME,
} from './types';

const onFileDownloadSuccess = nameFile => res => {
  const filename =
    nameFile ||
    (res.headers['content-disposition'] &&
      res.headers['content-disposition'].split('filename=')[1]) ||
    'download.pdf';
  const url = window.URL.createObjectURL(new Blob([res.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

const onFileDownloadError = dispatch => err => {
  const reader = new FileReader();
  reader.addEventListener('loadend', () => {
    dispatch({
      type: GET_ERRORS,
      payload: JSON.parse(reader.result),
    });
  });

  reader.readAsText(err.response.data);
};

export const getMeetings = () => dispatch => {
  dispatch(setMeetingsLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/meetings`)
    .then(res => {
      dispatch({
        type: GET_MEETINGS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(setMeetingsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getMeeting = id => dispatch => {
  dispatch(setMeetingsLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/meetings/${id}`)
    .then(res => {
      dispatch({
        type: GET_MEETING,
        payload: res.data,
      });
      if (res.data.typology_id) {
        dispatch(getMeetingAvailablePool(res.data.typology_id));
        dispatch(getTypologyLocals(res.data.typology_id));
      }
    })
    .catch(err => {
      dispatch(setMeetingsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getMeetingMatrices = id => dispatch => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/meetings/${id}/matrices`)
    .then(res => {
      dispatch({
        type: GET_MEETING_MATRICES,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getMeetingAvailablePool = idTypology => dispatch => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/typologies/${idTypology}/pool/themes`)
    .then(res => {
      dispatch({
        type: MEETING_POOL,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getAvailableSecretaries = () => dispatch => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/permissions/users`, {
      group: 'meetings',
      permission: 'secretary',
    })
    .then(res => {
      dispatch({
        type: MEETING_SECRETARIES,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getAvailableModerators = () => dispatch => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/permissions/users`, {
      group: 'meetings',
      permission: 'moderator',
    })
    .then(res => {
      dispatch({
        type: MEETING_MODERATORS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getTypologyLocals = idTypology => dispatch => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/typologies/${idTypology}`)
    .then(res => {
      dispatch({
        type: MEETING_LOCALS,
        payload: res.data.locals.map(x => ({ ...x, label: x.name, value: x.id })),
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const changeMeetingUsers = (idMeeting, info) => dispatch => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/participants/swap`, info)
    .then(() => {})
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateMeetingBoard = (idMeeting, info, resolve, reject) => dispatch => {
  axios
    .put(`${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/board`, info)
    .then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject();
      dispatch({
        type: GET_ERRORS,
        payload: err?.response?.data,
      });
    });
};

export const updateMeetingSecretary = (idMeeting, secretary) => dispatch => {
  axios
    .put(`${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/secretary`, secretary)
    .catch(() => {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'error',
          message: 'Não foi possível alterar o secretário',
        },
      });
    });
};

export const updateMeetingPointStatus = (idMeeting, pointId, info, resolve) => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/points/${pointId}/voting`,
      info
    )
    .then(() => {
      resolve();
    })
    .catch(() => {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'error',
          message: 'Não foi possível guardar o resultado da votação',
        },
      });

      resolve();
    });
};

export const lockMeeting = idMeeting => dispatch => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/lock`)
    .then(() => null)
    .catch(() => null);
};

export const meetingManualCheckIn = (idMeeting, idUser, resolve) => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/participants/${idUser}/checkin`
    )
    .then(res => {
      resolve(res);
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const updateMeetingUserSpeakingTime = (idMeeting, pointId, idUser, info) => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/points/${pointId}/users/${idUser}/speak`,
      info
    )
    .then(() => null)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const getUserPointNotes = (idMeeting, idPoint, resolve) => dispatch => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/points/${idPoint}/notes`)
    .then(res => {
      resolve(res);
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const uploadUserPointNotes = (idMeeting, idPoint, notes) => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/points/${idPoint}/notes`,
      notes
    )
    .then(() => null)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const getUserPeriodNotes = (idMeeting, periodType, resolve) => dispatch => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/type/${periodType}/notes`)
    .then(res => {
      resolve(res);
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const uploadUserPeriodNotes = (idMeeting, periodType, notes) => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/type/${periodType}/notes`,
      notes
    )
    .then(() => null)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const updateParticipantsOthersTime = (idMeeting, idParticipant, info) => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/participants/${idParticipant}/intervention`,
      info
    )
    .then(() => null)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const updateInterventionsTotalTime = (idMeeting, info) => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/type/interventions/speak`,
      info
    )
    .then(() => null)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const updatePeriodSpeakTime = (idMeeting, type, info) => dispatch => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/type/${type}/speak`, info)
    .then(() => null)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const unlockMeeting = idMeeting => dispatch => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/unlock`)
    .then(() => null)
    .catch(() => null);
};

export const sendMeetingAgenda = (idMeeting, resolve, reject) => dispatch => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/brief_available`)
    .then(() => {
      dispatch({
        type: BRIEF_AVAILABLE,
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Agenda da reunião enviada para todos os participantes.',
        },
      });
      resolve();
    })
    .catch(() => {
      reject();
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'error',
          message: 'Ocorreu um erro ao enviar a Agenda.',
        },
      });
    });
};

export const downloadMeetingAgendaTemplate = (idMeeting, nameFile) => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/brief_template`,
      {},
      { responseType: 'blob' }
    )
    .then(onFileDownloadSuccess(nameFile))
    .catch(onFileDownloadError(dispatch));
};

export const downloadMeetingPublicNoticeTemplate = (idMeeting, nameFile) => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/public_notice_template`,
      {},
      { responseType: 'blob' }
    )
    .then(onFileDownloadSuccess(nameFile))
    .catch(onFileDownloadError(dispatch));
};

export const downloadMeetingPointDeliberation = (idMeeting, idPoint, nameFile) => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/points/${idPoint}/deliberation/download`,
      {},
      { responseType: 'blob' }
    )
    .then(onFileDownloadSuccess(nameFile))
    .catch(onFileDownloadError(dispatch));
};

export const updateDeliberationNumber = (deliberationNumber, idMeeting, idPoint) => dispatch => {
  dispatch({
    type: SHOW_SNACK,
    payload: {
      variant: 'info',
      message: 'A atualizar o número de deliberação.',
    },
  });

  axios
    .put(
      `${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/points/${idPoint}/deliberation`,
      { deliberationNumber }
    )
    .then(response => {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: response.data.message,
        },
      });
      dispatch({
        type: UPDATE_PROPOSAL_MEETING_DELIBERATION_NUMBER,
        payload: {
          id: idMeeting,
          idPoint,
          deliberationNumber,
        },
      });
    })
    .catch(err => {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'error',
          message: 'Ocorreu um erro ao atualizar o número de deliberação',
        },
      });
    });
};

export const uploadMeetingPointDeliberation = (file, idMeeting, idPoint) => dispatch => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  dispatch({
    type: SHOW_SNACK,
    payload: {
      variant: 'info',
      message: 'A carregar o ficheiro da Deliberação.',
    },
  });

  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/points/${idPoint}/deliberation/upload`,
      file,
      config
    )
    .then(() => {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Ficheiro da Deliberação carregado com sucesso.',
        },
      });
      dispatch({
        type: UPDATE_PROPOSAL_MEETING_DELIBERATION_FILE_NAME,
        payload: {
          id: idMeeting,
          idPoint,
          fileName: file.get('file').name,
        },
      });
    })
    .catch(err => {
      dispatch(setMeetingsNotLoading());
      const reader = new FileReader();
      reader.addEventListener('loadend', () => {
        dispatch({
          type: GET_ERRORS,
          payload: JSON.parse(reader.result),
        });
      });
    });
};

export const uploadBriefFile = (file, id, resolve, reject) => dispatch => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  dispatch({
    type: SHOW_SNACK,
    payload: {
      variant: 'info',
      message: 'A carregar o ficheiro da Agenda.',
    },
  });

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/meetings/${id}/upload_brief`, file, config)
    .then(() => {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Ficheiro da Agenda carregado com sucesso.',
        },
      });
      resolve();
    })
    .catch(err => {
      reject();
      dispatch(setMeetingsNotLoading());
      const reader = new FileReader();
      reader.addEventListener('loadend', () => {
        dispatch({
          type: GET_ERRORS,
          payload: JSON.parse(reader.result),
        });
      });
    });
};

export const uploadPublicNoticeFile = (file, id, resolve, reject) => dispatch => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  dispatch({
    type: SHOW_SNACK,
    payload: {
      variant: 'info',
      message: 'A carregar o ficheiro do Edital.',
    },
  });

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/meetings/${id}/upload_public_notice`, file, config)
    .then(() => {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Ficheiro do Edital carregado com sucesso.',
        },
      });
      resolve();
    })
    .catch(err => {
      reject();
      dispatch(setMeetingsNotLoading());
      const reader = new FileReader();
      reader.addEventListener('loadend', () => {
        dispatch({
          type: GET_ERRORS,
          payload: JSON.parse(reader.result),
        });
      });
    });
};

export const uploadMinuteFile = (file, id, resolve) => dispatch => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  dispatch({
    type: SHOW_SNACK,
    payload: {
      variant: 'info',
      message: 'A carregar o ficheiro da Minuta da Reunião.',
    },
  });

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/meetings/${id}/upload_minute`, file, config)
    .then(res => {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Ficheiro da Minuta carregado com sucesso.',
        },
      });
      dispatch({
        type: UPDATE_MEETING_INFO,
        payload: {
          minutes_path: res.data.path,
          minutes_filename: res.data.filename,
        },
      });
      resolve();
    })
    .catch(err => {
      dispatch(setMeetingsNotLoading());
      const reader = new FileReader();
      reader.addEventListener('loadend', () => {
        dispatch({
          type: GET_ERRORS,
          payload: JSON.parse(reader.result),
        });
      });
    });
};

export const uploadDeliberationsFile = (file, id, resolve) => dispatch => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  dispatch({
    type: SHOW_SNACK,
    payload: {
      variant: 'info',
      message: 'A carregar o ficheiro do Edital de Deliberações.',
    },
  });

  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/meetings/${id}/upload_deliberations_notice`,
      file,
      config
    )
    .then(res => {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Ficheiro do Edital de Deliberações carregado com sucesso.',
        },
      });
      dispatch({
        type: UPDATE_MEETING_INFO,
        payload: {
          deliberations_notice_path: res.data.path,
          deliberations_notice_filename: res.data.filename,
        },
      });
      resolve();
    })
    .catch(err => {
      dispatch(setMeetingsNotLoading());
      const reader = new FileReader();
      reader.addEventListener('loadend', () => {
        dispatch({
          type: GET_ERRORS,
          payload: JSON.parse(reader.result),
        });
      });
    });
};

export const uploadAtaFile = (file, id, resolve) => dispatch => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  dispatch({
    type: SHOW_SNACK,
    payload: {
      variant: 'info',
      message: 'A carregar o ficheiro da Ata da Reunião.',
    },
  });

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/meetings/${id}/upload_ata`, file, config)
    .then(res => {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Ficheiro da Ata carregado com sucesso.',
        },
      });
      dispatch({
        type: UPDATE_MEETING_INFO,
        payload: {
          ata_path: res.data.path,
          ata_filename: res.data.filename,
        },
      });
      resolve();
    })
    .catch(err => {
      dispatch(setMeetingsNotLoading());
      const reader = new FileReader();
      reader.addEventListener('loadend', () => {
        dispatch({
          type: GET_ERRORS,
          payload: JSON.parse(reader.result),
        });
      });
    });
};

export const downloadMeetingBriefFile = (idMeeting, nameFile) => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/download_brief`,
      {},
      { responseType: 'blob' }
    )
    .then(onFileDownloadSuccess(nameFile))
    .catch(onFileDownloadError(dispatch));
};

export const downloadMeetingPublicNoticeFile = (idMeeting, nameFile) => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/download_public_notice`,
      {},
      { responseType: 'blob' }
    )
    .then(onFileDownloadSuccess(nameFile))
    .catch(onFileDownloadError(dispatch));
};

export const downloadMeetingZipContent = (idMeeting, nameFile, resolve) => dispatch => {
  // let progress = 0;
  const config = {
    // onDownloadProgress: progressEvent => {
    // const newProgress = (progressEvent.loaded * 100) / progressEvent.total;
    // if (newProgress - progress > 5) {
    // progress = newProgress;
    // dispatch({
    //   type: SET_PROGRESS,
    //   payload: newProgress,
    // });
    // }
    // },
    responseType: 'blob',
  };

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/download_zip`, {}, config)
    .then(res => {
      const filename =
        nameFile ||
        (res.headers['content-disposition'] &&
          res.headers['content-disposition'].split('filename=')[1]) ||
        'download.pdf';
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      dispatch({
        type: SET_PROGRESS,
        payload: 0,
      });
      resolve();
    })
    .catch(err => {
      const reader = new FileReader();
      reader.addEventListener('loadend', () => {
        dispatch({
          type: GET_ERRORS,
          payload: JSON.parse(reader.result),
        });
      });

      reader.readAsText(err.response.data);
    });
};

export const checkoutMeetingUser = (meetingId, userId, resolve, reject) => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/meetings/${meetingId}/participants/${userId}/checkout`
    )
    .then(res => {
      resolve(res);
    })
    .catch(err => reject(err));
};

export const startMeeting = (idMeeting, resolve, reject) => dispatch => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/start`)
    .then(() => {
      resolve();
    })
    .catch(err => {
      reject();
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const createMeeting = (info, resolve, reject) => dispatch => {
  dispatch(setMeetingsLoading());

  dispatch({
    type: SHOW_SNACK,
    payload: {
      variant: 'info',
      message: 'A submeter a informação da nova reunião.',
    },
  });

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/meetings`, info)
    .then(res => {
      dispatch({
        type: CREATE_MEETING,
        payload: res.data,
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Reunião criada com sucesso.',
        },
      });
      resolve(res.data);
    })
    .catch(err => {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'error',
          message: 'Não foi possível criar a reunião.',
        },
      });
      if (err.response?.data?.users?.length) {
        dispatch({
          type: SHOW_SNACK,
          payload: {
            variant: 'error',
            message: 'O campo Participantes é obrigatório',
          },
        });
      }
      dispatch(setMeetingsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      reject(err);
    });
};

export const updateMeeting = (id, info, resolve, reject) => dispatch => {
  dispatch(setMeetingsLoading());

  dispatch({
    type: SHOW_SNACK,
    payload: {
      variant: 'info',
      message: 'A atualizar a informação da reunião.',
    },
  });

  axios
    .put(`${process.env.REACT_APP_API_URL}/api/meetings/${id}`, info)
    .then(res => {
      dispatch({
        type: UPDATE_MEETING,
        payload: res.data,
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Reunião atualizada com sucesso.',
        },
      });
      dispatch(setMeetingsNotLoading());
      resolve({ ...res.data, id });
    })
    .catch(err => {
      reject(err);
      dispatch(setMeetingsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const uploadMeetingFiles = (attachments, length, id, resolve, reject) => dispatch => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  dispatch({
    type: SHOW_SNACK,
    payload: {
      variant: 'info',
      message: `A carregar o${length > 1 ? 's' : ''} ficheiro${length > 1 ? 's' : ''} da reunião.`,
    },
  });

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/meetings/${id}/attachments`, attachments, config)
    .then(res => {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: `Ficheiro${length > 1 ? 's' : ''} carregado${
            length > 1 ? 's' : ''
          } com sucesso.`,
        },
      });
      resolve(res.data);
    })
    .catch(err => {
      dispatch(setMeetingsNotLoading());
      const reader = new FileReader();
      reader.addEventListener('loadend', () => {
        dispatch({
          type: GET_ERRORS,
          payload: JSON.parse(reader.result),
        });
      });
      reject(err);
    });
};

export const downloadMeetingFile = (idMeeting, idFile, nameFile) => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/attachments/${idFile}/download`,
      {},
      { responseType: 'blob' }
    )
    .then(onFileDownloadSuccess(nameFile))
    .catch(onFileDownloadError(dispatch));
};

export const downloadMeetingMinute = (idMeeting, nameFile, resolve) => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/download_minute`,
      {},
      { responseType: 'blob' }
    )
    .then(res => {
      onFileDownloadSuccess(nameFile)(res);
      resolve();
    })
    .catch(onFileDownloadError(dispatch));
};

export const downloadMeetingAta = (idMeeting, nameFile, resolve) => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/download_ata`,
      {},
      { responseType: 'blob' }
    )
    .then(res => {
      onFileDownloadSuccess(nameFile)(res);
      resolve();
    })
    .catch(onFileDownloadError(dispatch));
};

export const downloadDeliberations = (idMeeting, nameFile, resolve) => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/download_deliberations_notice`,
      {},
      { responseType: 'blob' }
    )
    .then(res => {
      onFileDownloadSuccess(nameFile)(res);
      resolve();
    })
    .catch(onFileDownloadError(dispatch));
};

export const downloadMeetingNotes = (idMeeting, nameFile) => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/notes`,
      {},
      { responseType: 'blob' }
    )
    .then(onFileDownloadSuccess(nameFile))
    .catch(onFileDownloadError(dispatch));
};

export const deleteMeetingFile = (idMeeting, idFile) => dispatch => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/attachments/${idFile}/delete`)
    .then(() => {})
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const cancelMeeting = (id, resolve, reject) => dispatch => {
  dispatch(setMeetingsLoading());

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/meetings/${id}/cancel`)
    .then(() => {
      dispatch({
        type: CANCEL_MEETING,
        payload: {
          id,
        },
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Reunião cancelada com sucesso.',
        },
      });
      resolve();
    })
    .catch(err => {
      dispatch(setMeetingsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      reject();
    });
};

export const deleteMeeting = (id, resolve, reject) => dispatch => {
  axios
    .delete(`${process.env.REACT_APP_API_URL}/api/meetings/${id}`)
    .then(() => {
      dispatch({
        type: DELETE_MEETING,
        payload: {
          id,
        },
      });

      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Reunião eliminada com sucesso.',
        },
      });

      resolve();
    })
    .catch(err => {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'error',
          message: 'Ocorreu um erro ao eliminar a reunião',
        },
      });

      reject();
    });
};

export const resendMeetingInvite = (id, userId) => dispatch => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/meetings/${id}/participants/${userId}/resend`)
    .then(() => {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Convite enviado com sucesso.',
        },
      });
    })
    .catch(err => {
      dispatch(setMeetingsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getMeetingUserDeclarations = (id, userId) => dispatch => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/meetings/${id}/users/${userId}/declaration`)
    .then(res => {
      dispatch({
        type: USER_MEETING_DECLARATIONS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(setMeetingsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getMeetingPointDeclarations = (id, pointId) => dispatch => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/meetings/${id}/points/${pointId}/declaration`)
    .then(res => {
      dispatch({
        type: USER_MEETING_POINT_DECLARATIONS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(setMeetingsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getMeetingPointDeclarationsChanges = (id, pointId) => dispatch => {
  axios
    .get(
      `${process.env.REACT_APP_API_URL}/api/meetings/${id}/points/${pointId}/declaration/changes`
    )
    .then(res => {
      dispatch({
        type: USER_MEETING_DECLARATIONS_CHANGES,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(setMeetingsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const downloadMeetingDeclaration = (idMeeting, pointId, userId, nameFile) => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/points/${pointId}/users/${userId}/declaration/download`,
      {},
      { responseType: 'blob' }
    )
    .then(res => {
      const filename =
        nameFile ||
        (res.headers['content-disposition'] &&
          res.headers['content-disposition'].split('filename=')[1]) ||
        'download.pdf';
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => {
      const reader = new FileReader();
      reader.addEventListener('loadend', () => {
        dispatch({
          type: GET_ERRORS,
          payload: JSON.parse(reader.result),
        });
      });

      reader.readAsText(err.response.data);
    });
};

export const deleteMeetingDeclaration = (idMeeting, pointId, userId, declarationId) => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/points/${pointId}/users/${userId}/declaration/remove`
    )
    .then(() => {
      dispatch({
        type: REMOVE_DECLARATION,
        payload: declarationId,
      });
      dispatch({
        type: 'SHOW_SNACK',
        payload: {
          variant: 'success',
          message: 'Declaração de voto removida com sucesso',
        },
      });
      dispatch(getMeetingPointDeclarationsChanges(idMeeting, pointId));
    })
    .catch(err => {
      dispatch(setMeetingsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const uploadDeclaration = (id, pointId, userId, file, resolve) => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/meetings/${id}/points/${pointId}/users/${userId}/declaration/upload`,
      file
    )
    .then(res => {
      dispatch(getMeetingPointDeclarationsChanges(id, pointId));
      resolve(res);
    })
    .catch(err => {
      dispatch(setMeetingsNotLoading());
      const reader = new FileReader();
      reader.addEventListener('loadend', () => {
        dispatch({
          type: GET_ERRORS,
          payload: JSON.parse(reader.result),
        });
      });
    });
};

export const removeDeclaration = id => {
  return {
    type: REMOVE_DECLARATION,
    payload: id,
  };
};

export const endMeeting = (id, resolve, reject) => dispatch => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/meetings/${id}/end`)
    .then(res => {
      resolve(res);
    })
    .catch(err => {
      dispatch(setMeetingsNotLoading());
      reject(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const replyMeetingInvite =
  (idMeeting, idUser, reply, resolve, isAccepting, isGroup) => dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/meetings/${idMeeting}/participants/${idUser}/replyInvite`,
        reply
      )
      .then(() => {
        let message = 'Resposta guardada com sucesso.';
        if (isAccepting) {
          message = reply.accepted
            ? 'Convite aceite com sucesso.'
            : 'Convite recusado com sucesso.';
        } else {
          message = reply.accepted
            ? 'Presença confirmada com sucesso.'
            : 'Presença recusada com sucesso.';
        }

        if (!isAccepting) {
          dispatch({
            type: SET_USER_ACCEPTED,
            payload: {
              id: idUser,
              accepted: reply.accepted ? 1 : -1,
              isGroup,
            },
          });
        }

        dispatch({
          type: SHOW_SNACK,
          payload: {
            variant: 'success',
            message,
          },
        });
        resolve();
      })
      .catch(err => {
        dispatch(setMeetingsNotLoading());
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      });
  };

export const setMeetingsLoading = () => {
  return {
    type: MEETINGS_LOADING,
  };
};

export const setMeetingsNotLoading = () => {
  return {
    type: MEETINGS_NOT_LOADING,
  };
};
