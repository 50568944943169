import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { CheckProposalStateContext } from './CheckProposalProvider';
import FullWidthSpinner from '../../common/FullWidthSpinner';
import PropTypes from 'prop-types';
import ProposalInfoDetails from './ProposalInfoDetails';

const CheckProposalDetails = ({ canSeeApprovers }) => {
  const {
    state: { proposal, hasVersions, hasMeetings, lastVersionId },
  } = useContext(CheckProposalStateContext);

  const { user } = proposal;

  return user ? (
    <Grid item xs={canSeeApprovers ? 5 : 6}>
      <ProposalInfoDetails
        proposal={proposal}
        hasVersions={hasVersions}
        hasMeetings={hasMeetings}
        lastVersionId={lastVersionId}
      />
    </Grid>
  ) : (
    <FullWidthSpinner />
  );
};

CheckProposalDetails.defaultProps = {
  canSeeApprovers: false,
};

CheckProposalDetails.propTypes = {
  canSeeApprovers: PropTypes.bool,
};

export default React.memo(CheckProposalDetails);
