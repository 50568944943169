import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body1 from '../../common/typographys/Body1';
import BoxCheckbox from '../../common/forms/BoxCheckbox';

const useStyles = makeStyles(() => ({
  boxChoice: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const RadioPicker = ({ label, onClick, checked }) => {
  const classes = useStyles();

  return (
    <Box className={classes.boxChoice}>
      <Body1>{label}</Body1>
      <BoxCheckbox circle onClick={onClick} checked={checked} />
    </Box>
  );
};

RadioPicker.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};

export default React.memo(RadioPicker);
