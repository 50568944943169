/* eslint-disable no-nested-ternary */
import React, { useCallback, useState, useMemo, useContext } from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import H5 from '../../../common/typographys/H5';
import ButtonNormal from '../../../common/buttons/ButtonNormal';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  sendMeetingAgenda,
  cancelMeeting,
  replyMeetingInvite,
} from '../../../../store/actions/meetingsActions';
import isEmpty from '../../../../utils/isEmpty';
import DialogConfirmAction from '../../../common/dialogs/DialogConfirmAction';
import Subtitle1 from '../../../common/typographys/Subtitle1';
import clsx from 'clsx';
import formatDate from '../../../../utils/formatDate';
import { SocketReunion } from '../../meeting/SocketReunionWrapper';

const useStyles = makeStyles(theme => ({
  boxCancel: {
    height: '24px',
    borderRadius: '4px',
    padding: '1px 6px',
  },
  canceled: {
    backgroundColor: theme.palette.colorsPalette.RustRed,
  },
  live: {
    backgroundColor: theme.palette.colorsPalette.TrueBlue,
  },
  done: {
    backgroundColor: theme.palette.colorsPalette.TrueGreen,
  },
  refuse: {
    backgroundColor: theme.palette.neutrals[5],
    color: theme.palette.neutrals[1],
    '&:hover': {
      backgroundColor: theme.palette.neutrals[6],
    },
  },
  accept: {
    marginLeft: '10px',
  },
}));

const HeaderCheck = ({
  name,
  canSend,
  briefAvailable,
  briefChange,
  canCancel,
  status,
  briefFileName,
  meetingUser,
  isGroup,
  updateDate,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { id: idUser } = useSelector(state => state.auth.user);
  const dispatchRedux = useDispatch();
  const [open, setOpen] = useState(false);
  const [answer, setAnswer] = useState('');
  const [openAnswer, setOpenAnswer] = useState(false);
  const [disableSendAgenda, setDisableSendAgenda] = useState(false);
  const [openAgenda, setOpenAgenda] = useState(false);
  const { inMeeting, accepted } = meetingUser;
  const { socketNsp } = useContext(SocketReunion);

  // @ accepted === null -> Não respondeu ainda
  const canceled = useMemo(() => status === -1, [status]);
  const live = useMemo(() => status === 2, [status]);
  const done = useMemo(() => status === 3, [status]);
  const pending = useMemo(() => !canceled && !live && !done, [canceled, live, done]);

  const message = useMemo(() => {
    if (canceled) {
      return 'Reunião cancelada';
    }
    if (live) {
      return 'Reunião a decorrer';
    }

    return 'Reunião realizada';
  }, [canceled, live]);

  const helperMessage = useMemo(() => {
    if (isEmpty(briefFileName)) {
      return 'Nenhum ficheiro de Agenda submetido';
    }

    if (briefChange) {
      return 'É necessário atualizar o ficheiro da Agenda';
    }

    if (!briefAvailable) {
      return 'A convocatória ainda não foi enviada';
    }

    if (briefAvailable && !briefChange) {
      return `Convocatória enviada a ${formatDate(updateDate)}`;
    }

    return null;
  }, [briefFileName, briefAvailable, briefChange, updateDate]);

  const editMeeting = useCallback(
    e => {
      e.preventDefault();
      history.push(`/reunioes/editar/${id}`);
    },
    [history, id]
  );

  const confirmCancelMeeting = useCallback(
    async e => {
      e.preventDefault();
      setOpen(false);
      const cancelPromise = new Promise((resolve, reject) =>
        dispatchRedux(cancelMeeting(id, resolve, reject))
      );

      try {
        await cancelPromise;
        socketNsp.emit('end_reunion', JSON.stringify({ id }));
        return null;
      } catch (_e) {
        return null;
      }
    },
    [dispatchRedux, socketNsp, id]
  );

  const sendAgenda = useCallback(
    async e => {
      e.preventDefault();
      setOpenAgenda(false);
      setDisableSendAgenda(true);
      const agendaPromise = new Promise((resolve, reject) => {
        dispatchRedux({
          type: 'SHOW_SNACK',
          payload: {
            variant: 'info',
            message: 'A enviar convocatória para todos os participantes.',
          },
        });
        dispatchRedux(sendMeetingAgenda(id, resolve, reject));
      });

      await agendaPromise;

      return setDisableSendAgenda(false);
    },
    [id, dispatchRedux]
  );

  const toggleAgenda = useCallback(e => {
    e.preventDefault();

    setOpenAgenda(true);
  }, []);

  const confirmAnswer = useCallback(
    e => {
      e.preventDefault();
      setOpenAnswer(true);

      const reply = { accepted: answer === 'accept' };
      const replyInvite = new Promise(resolve => {
        dispatchRedux(replyMeetingInvite(id, idUser, reply, resolve, false, isGroup));
      });

      replyInvite
        .then(() => setOpenAnswer(false))
        .catch(() => {
          setOpenAnswer(false);
          return dispatchRedux({
            type: 'SHOW_SNACK',
            payload: {
              variant: 'error',
              message: 'Aconteceu um erro, tente novamente por favor.',
            },
          });
        });
    },
    [dispatchRedux, isGroup, answer, id, idUser]
  );

  const handleAnswer = useCallback(
    value => e => {
      e.preventDefault();
      setOpenAnswer(true);
      setAnswer(value);
    },
    []
  );

  const closeModals = useCallback(e => {
    e.preventDefault();
    setOpen(false);
    setOpenAnswer(false);
    setOpenAgenda(false);
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={pending ? 5 : 8}>
        <H5>{name}</H5>
      </Grid>
      <Grid item xs={pending ? 7 : 4}>
        <Grid container justify="flex-end" spacing={1}>
          {pending && (
            <>
              {canSend && (
                <>
                  <Grid item>
                    <ButtonNormal
                      variant="outlined"
                      color="primary"
                      label="Editar reunião"
                      onClick={editMeeting}
                      small
                    />
                  </Grid>
                  <Grid item>
                    <ButtonNormal
                      contained
                      pea
                      label="Enviar convocatória"
                      onClick={toggleAgenda}
                      helper={helperMessage}
                      disabled={
                        isEmpty(briefFileName) ||
                        disableSendAgenda ||
                        briefChange ||
                        (briefAvailable && !briefChange)
                      }
                      small
                    />
                  </Grid>
                </>
              )}
              {canCancel && (
                <Grid item>
                  <ButtonNormal
                    error
                    variant="contained"
                    label="Cancelar reunião"
                    onClick={e => {
                      e.preventDefault();
                      setOpen(true);
                    }}
                    small
                  />
                </Grid>
              )}
              {inMeeting && (
                <Grid item>
                  {accepted === 1 && (
                    <ButtonNormal
                      small
                      variant="contained"
                      label="Cancelar presença"
                      onClick={handleAnswer('refuse')}
                      className={classes.refuse}
                    />
                  )}
                  {accepted === null && (
                    <Box display="flex">
                      <ButtonNormal
                        small
                        variant="contained"
                        label="Cancelar presença"
                        onClick={handleAnswer('refuse')}
                        className={classes.refuse}
                      />
                      <ButtonNormal
                        small
                        variant="contained"
                        color="primary"
                        label="Confirmar presença"
                        onClick={handleAnswer('accept')}
                        marginLeft
                      />
                    </Box>
                  )}
                </Grid>
              )}
            </>
          )}
          {!pending && (
            <Grid item>
              <Box
                className={clsx(classes.boxCancel, {
                  [classes.canceled]: canceled,
                  [classes.live]: live,
                  [classes.done]: done,
                })}
              >
                <Subtitle1 selected>{message}</Subtitle1>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
      <DialogConfirmAction
        open={open}
        handleClose={closeModals}
        msg="Deseja cancelar a reunião?"
        handleSubmit={confirmCancelMeeting}
        labelSubmit="Confirmar"
        labelCancel="Sair"
      />
      <DialogConfirmAction
        open={openAnswer}
        handleClose={closeModals}
        handleSubmit={confirmAnswer}
        msg={`Deseja ${answer === 'accept' ? 'confirmar' : 'cancelar'} a sua presença na reunião?`}
        labelSubmit="Confirmar"
        labelCancel="Sair"
      />
      <DialogConfirmAction
        open={openAgenda}
        handleClose={closeModals}
        handleSubmit={sendAgenda}
        msg="Deseja enviar a convocatória para todos os participantes?"
        labelSubmit="Enviar"
        labelCancel="Sair"
      />
    </Grid>
  );
};

HeaderCheck.defaultProps = {
  briefFileName: null,
  updateDate: null,
};

HeaderCheck.propTypes = {
  name: PropTypes.string.isRequired,
  canSend: PropTypes.bool.isRequired,
  canCancel: PropTypes.bool.isRequired,
  status: PropTypes.number.isRequired,
  meetingUser: PropTypes.object.isRequired,
  isGroup: PropTypes.bool.isRequired,
  briefAvailable: PropTypes.bool.isRequired,
  briefChange: PropTypes.bool.isRequired,
  briefFileName: PropTypes.string,
  updateDate: PropTypes.string,
};

export default React.memo(HeaderCheck);
