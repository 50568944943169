import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';

const FullWidthSpinner = props => {
  return (
    <Grid container justify="center" {...props}>
      <CircularProgress />
    </Grid>
  );
};

export default FullWidthSpinner;
