import React, { useContext, useEffect, useMemo } from 'react';
import AddNewItem from '../../pieces/AddNewItem';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import {
  OrganicUnitDispatchContext,
  OrganicUnitStateContext,
} from './CreateEditOrganicUnitProvider';
import FormInfoOrganicUnit from './FormInfoOrganicUnit';

const AddNewOrganicUnit = () => {
  const {
    state: { errors, items, user, need_verification },
    handleSubmit,
    loading,
  } = useContext(OrganicUnitStateContext);
  const dispatch = useContext(OrganicUnitDispatchContext);

  useEffect(() => {
    dispatch({
      type: 'CLEAR_STATE',
    });
  }, [dispatch]);

  const msgSubmit = useMemo(() => {
    if (need_verification) {
      // @ Utilizador escolhido pertencia a outra UO

      return `Deseja guardar a nova Unidade Orgânica? O utilizador ${user.name} pertence à Unidade Orgânica: ${user.organic_unit.name}.`;
    }

    return 'Tem a certeza que deseja gravar a nova Unidade Orgânica?';
  }, [need_verification, user]);

  return (
    <AddNewItem
      disableSubmit={checkEmptyObject(errors).length > 0}
      items={items}
      loading={loading}
      dispatch={dispatch}
      handleSubmit={handleSubmit}
      msgSubmit={msgSubmit}
    >
      <FormInfoOrganicUnit />
    </AddNewItem>
  );
};

export default React.memo(AddNewOrganicUnit);
