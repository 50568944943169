import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Subtitle2 from '../../common/typographys/Subtitle2';
import TimeBox from '../../configs/pieces/TimeBox';
import ThemeMeetingEntry from './ThemeMeetingEntry';

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: '6px',
  },
}));

const DefaultThemes = ({ themes }) => {
  const classes = useStyles();

  return (
    <>
      <Subtitle2 className={classes.label}>Temas</Subtitle2>
      <TimeBox>
        {themes.map((theme, idx) => (
          <ThemeMeetingEntry key={theme.id} theme={theme} idx={idx} last={idx === themes.length - 1} />
        ))}
      </TimeBox>
    </>
  );
};

DefaultThemes.propTypes = {
  themes: PropTypes.array.isRequired,
};

export default React.memo(DefaultThemes);
