import React from 'react';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import isEmpty from '../../../../utils/isEmpty';
import clsx from 'clsx';
import formatDate from '../../../../utils/formatDate';
import Body1 from '../../typographys/Body1';
import PropTypes from 'prop-types';
import Subtitle1 from '../../typographys/Subtitle1';
import { PlayCircleOutline } from '@material-ui/icons';
import getDateObj from '../../../../utils/getDateObj';
import { fade } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import canStartMeeting from '../../../../utils/permissions/canStartMeeting';

const useStyles = makeStyles(theme => ({
  boxBgColor: {
    width: '4px',
    height: 'auto',
    marginRight: '10px',
  },
  title: {
    marginBottom: '2px',
  },
  infoEvent: {
    paddingLeft: '10px',
  },
  marginItem: {
    marginBottom: '30px',
  },
  iconPlay: {
    color: theme.palette.primary.A200,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.colorsPalette.TrueGreen,
    },
  },
  iconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&$disabled span svg': {
      color: fade(theme.palette.primary.A200, 0.4),
    },
  },
  disabled: {},
}));

const NextEventsInfo = ({ event, last, startMeeting }) => {
  const classes = useStyles();
  const dateEvent = getDateObj(event.start);
  const { permissions, id } = useSelector(state => state.auth.user);

  return (
    !isEmpty(event) && (
      <Box display="flex" className={clsx({ [classes.marginItem]: !last })}>
        <Box className={classes.infoEvent} style={{ borderLeft: `4px solid ${event.borderColor}` }}>
          {event.title && <Body1 className={classes.title}>{event.title}</Body1>}
          {dateEvent && <Subtitle1>{formatDate(dateEvent, "dd 'de' MMMM 'de' yyyy")}</Subtitle1>}
        </Box>
        {canStartMeeting(permissions) && (
          <Box display="flex" alignItems="center" marginLeft="auto">
            <IconButton
              disabled={event.status !== 1 || (event.info && event.info.user_edit && event.info.user_edit.id !== id)}
              className={classes.iconButton}
              classes={{
                disabled: classes.disabled,
              }}
              onClick={startMeeting(event)}
            >
              <PlayCircleOutline className={classes.iconPlay} />
            </IconButton>
          </Box>
        )}
      </Box>
    )
  );
};

NextEventsInfo.defaultProps = {
  last: false,
};

NextEventsInfo.propTypes = {
  event: PropTypes.object.isRequired,
  startMeeting: PropTypes.func.isRequired,
  last: PropTypes.bool,
};

export default NextEventsInfo;
