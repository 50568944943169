import React, { useContext, useEffect, useCallback } from 'react';
import InputField from '../../../common/forms/InputField';
import { ProfileStateContext, ProfileDispatchContext } from './CreateEditProfileProvider';
import PermissionsCollapseGroup from '../../pieces/PermissionsCollapseGroup';

const FormInfoProfile = () => {
  const {
    state: { name, errors, panel, permissions },
  } = useContext(ProfileStateContext);
  const dispatch = useContext(ProfileDispatchContext);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'CLEAR_STATE',
      });
    };
  }, [dispatch]);

  const updateField = useCallback(
    (nameInput, value) => {
      dispatch({
        type: 'UPDATE_FIELD',
        payload: {
          name: nameInput,
          value,
        },
      });
    },
    [dispatch]
  );

  return (
    <>
      <InputField label="Nome" name="name" value={name} onChange={e => updateField('name', e.target.value)} error={errors.name} />
      <PermissionsCollapseGroup panel={panel} permissions={permissions} dispatch={dispatch} />
    </>
  );
};

export default FormInfoProfile;
