import React, { useCallback, useContext, useMemo, useEffect } from 'react';
import { Grid, Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { MeetingDispatchContext, MeetingFuncsContext } from '../CreateEditMeetingsProvider';
import InputField from '../../common/forms/InputField';
import SelectOutlined from '../../common/forms/SelectOutlined';
import MeetingLocal from './MeetingLocal';
import StartEndDate from './StartEndDate';
import isEmpty from '../../../utils/isEmpty';
import usePrevious from '../../../utils/hooks/usePrevious';
import CardBlock from '../../common/forms/CardBlock';
import getDateObj from '../../../utils/getDateObj';
import CheckboxPicker from '../../common/forms/CheckboxPicker';

const MeetingDetails = ({ state, editing }) => {
  const dispatch = useContext(MeetingDispatchContext);
  const { updateField } = useContext(MeetingFuncsContext);
  const { typologies } = useSelector(stateRedux => stateRedux.typologies);
  const {
    name,
    typology,
    date,
    endDate,
    local,
    isPublic,
    addToTypology,
    errors,
    submitted,
    videoConferenceLink,
    videoConference,
  } = state;
  const prevTypology = usePrevious(typology);
  const {
    location: { search },
  } = useHistory();

  useEffect(() => {
    if (search.indexOf('?tipologia=') !== -1) {
      const typologyId = search.substring(search.indexOf('?tipologia=') + 11, search.length);
      const typologyObj = typologies.find(x => x.id.toString() === typologyId.toString());
      if (typologyObj) {
        updateField('typology', typologyObj);
      }
    }
  }, [search, typologies, updateField]);

  const dispatchError = useCallback(
    payload => {
      dispatch({
        type: 'SET_ERROR',
        payload,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    // @ Quando a tipologia muda, é preciso atualizar determinados campos que mudam com ela
    if ((typology && prevTypology && typology.id !== prevTypology.id) || isEmpty(typology)) {
      updateField('addToTypology', false);
      updateField('local', null);
      updateField('themes', []);
      updateField('points', []);
      updateField('users', []);
      updateField('proposals', []);
      updateField('pool', { points: [] });
    }
  }, [typology, prevTypology, updateField]);

  // @ Efeitos
  useEffect(() => {
    if (submitted && name.length > 2 && !isEmpty(errors.name)) {
      dispatchError({ name: '' });
    } else if (submitted && name.length < 3 && isEmpty(errors.name)) {
      dispatchError({ name: 'Preencha o nome da reunião.' });
    }
  }, [errors.name, name, submitted, dispatchError]);

  useEffect(() => {
    if (submitted && !isEmpty(typology) && !isEmpty(errors.typology)) {
      dispatchError({ typology: '' });
    } else if (submitted && isEmpty(typology) && isEmpty(errors.typology)) {
      dispatchError({ typology: 'Escolha a tipologia desta reunião.' });
    }
  }, [errors.typology, typology, submitted, dispatchError]);

  const meetingLocalState = useMemo(
    () => ({
      typology,
      videoConferenceLink,
      videoConference,
      local,
      addToTypology,
      error: errors.local,
      submitted,
    }),
    [typology, videoConferenceLink, videoConference, local, addToTypology, errors.local, submitted]
  );

  const startEndDateState = useMemo(
    () => ({
      date: getDateObj(date),
      endDate: getDateObj(endDate),
      errors: { date: errors.date, endDate: errors.endDate },
      submitted,
    }),
    [date, endDate, errors.date, errors.endDate, submitted]
  );

  return (
    <CardBlock label="Detalhes">
      <InputField
        label="Nome da reunião"
        name="name"
        value={name}
        error={errors.name}
        onChange={e => updateField('name', e.target.value)}
      />
      {editing ? (
        <InputField
          label="Tipologia"
          name="typology"
          value={(typology && typology.name) || ''}
          disabled
          onChange={() => null}
        />
      ) : (
        <>
          <SelectOutlined
            fullWidth
            isClearable
            getOptionValue={option => option.id}
            getOptionLabel={option => option.name}
            value={typology}
            options={typologies}
            label="Tipologia"
            placeholder="Selecione a tipologia da reunião"
            onChange={val => updateField('typology', val)}
            name="typology"
            noOptionsMessage={() => 'Sem tipologias'}
            error={errors.typology}
          />
          <Box height="20px" />
        </>
      )}
      <StartEndDate state={startEndDateState} />
      <Box height="20px" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MeetingLocal state={meetingLocalState} />
        </Grid>
      </Grid>
      <Box height="20px" />
      <CheckboxPicker
        onClick={() => updateField('isPublic', !isPublic)}
        checked={isPublic}
        label="Reunião aberta ao público"
      />
    </CardBlock>
  );
};

MeetingDetails.defaultProps = {
  editing: false,
};

MeetingDetails.propTypes = {
  state: PropTypes.object.isRequired,
  editing: PropTypes.bool,
};

export default React.memo(MeetingDetails);
