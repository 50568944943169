import React, { useCallback, Fragment } from 'react';
import { makeStyles, Box, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body1 from '../../../../common/typographys/Body1';
import SingleUserEntry from './SingleUserEntry';
import clsx from 'clsx';
import Body2 from '../../../../common/typographys/Body2';
import addSeconds from 'date-fns/addSeconds';
import startOfToday from 'date-fns/startOfToday';
import formatDate from '../../../../../utils/formatDate';
// import GroupInterventions from './GroupInterventions';

const useStyles = makeStyles(theme => ({
  boxName: {
    padding: '0px 14px',
    marginBottom: '2px',
  },
  groupTimeBox: {
    height: '4px',
  },
  darkGreen: {
    backgroundColor: theme.palette.colorsPalette.TrueGreen,
  },
  nameWithTime: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const secondsToMinutes = (time, isNegative) => {
  const dateWithSeconds = addSeconds(startOfToday(), time);
  const result = formatDate(dateWithSeconds, 'HH:mm:ss');
  if (isNegative) {
    return `+${result}`;
  }

  return result;
};

const getTalkPercentage = (time, current) => {
  return ((parseInt(current, 10) * 100) / parseInt(time, 10)).toFixed(2);
};

const IndividualUsersTimer = ({
  users,
  participants,
  moderator,
  infoSpeaking,
  hasMatrix,
  activeInfo,
  activePointId,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const isMemberAbsent = useCallback(
    userId => {
      if (moderator) {
        const participantObj = participants.find(x => x.id.toString() === userId.toString());
        return participantObj === undefined || !participantObj.active;
      }

      return false;
    },
    [participants, moderator]
  );

  return (
    <Box paddingTop="20px">
      {users.map(user => {
        const { id, time, current, no_limit, in_meeting } = user;

        if (!in_meeting) return null;

        const userPercentage = getTalkPercentage(time, current);
        const paintedPercentage = userPercentage >= 100 ? 100 : 100 - userPercentage;
        const objUser = (participants && participants.find(x => user && x.id === user.id)) || {};
        const isCheckedIn = objUser.checkIn || false;

        const style =
          userPercentage > 0 && hasMatrix
            ? {
                background: `linear-gradient(to right,${
                  userPercentage >= 100
                    ? theme.palette.error.main
                    : theme.palette.colorsPalette.TrueBlue
                } ${paintedPercentage}%, ${theme.palette.neutrals[2]} ${paintedPercentage}%)`,
              }
            : {};

        return (
          <Fragment key={id}>
            {hasMatrix && (
              <>
                <Box className={clsx(classes.boxName, { [classes.nameWithTime]: hasMatrix })}>
                  <Body1>Total</Body1>
                  <Body2>{secondsToMinutes(Math.abs(time - current), current >= time)}</Body2>
                </Box>
                <Box
                  className={clsx(classes.groupTimeBox, {
                    [classes.darkGreen]:
                      hasMatrix && (Boolean(no_limit) === true || userPercentage <= 0),
                  })}
                  style={style}
                />
              </>
            )}
            <SingleUserEntry
              user={user}
              moderator={moderator}
              isMemberAbsent={isMemberAbsent(id)}
              reunionEnd={false}
              infoSpeaking={infoSpeaking}
              activeInfo={activeInfo}
              groupTime={time}
              isCheckedIn={isCheckedIn}
              activePointId={activePointId}
            />
          </Fragment>
        );
      })}
    </Box>
  );
};

IndividualUsersTimer.defaultProps = {
  moderator: false,
  hasMatrix: false,
  infoSpeaking: null,
  activePointId: null,
  activeInfo: null,
};

IndividualUsersTimer.propTypes = {
  users: PropTypes.array.isRequired,
  participants: PropTypes.array.isRequired,
  activePointId: PropTypes.number,
  hasMatrix: PropTypes.bool,
  moderator: PropTypes.bool,
  infoSpeaking: PropTypes.object,
  activeInfo: PropTypes.shape(),
};

export default React.memo(IndividualUsersTimer);
