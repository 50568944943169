import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { readNotifications } from '../../store/actions/notificationsActions';

const useToolbarMenus = () => {
  const [notificationsEl, setNotificationsEl] = useState(null);
  const [avatarEl, setAvatarEl] = useState(null);
  const dispatch = useDispatch();

  const handleClick = useCallback(
    e => {
      setNotificationsEl(e.currentTarget);
      dispatch(readNotifications(true));
    },
    [setNotificationsEl, dispatch]
  );

  const handleProfile = useCallback(
    e => {
      setAvatarEl(e.currentTarget);
    },
    [setAvatarEl]
  );

  const handleClose = useCallback(() => {
    setNotificationsEl(null);
    setAvatarEl(null);
  }, [setNotificationsEl, setAvatarEl]);

  return [notificationsEl, avatarEl, handleClick, handleProfile, handleClose];
};

export default useToolbarMenus;
