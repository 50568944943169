import React, { useEffect, useContext, useCallback } from 'react';
import SeeItem from '../../pieces/SeeItem';
import PropTypes from 'prop-types';
import LabelAndValue from '../../../common/forms/LabelAndValue';
import { LocalDispatchContext } from './CreateEditLocalProvider';
import EditLocal from './EditLocal';
import { ConfigStateContext, ConfigDispatchContext } from '../../MenuConfigProvider';

const SeeEditLocal = ({ info }) => {
  const dispatchConfig = useContext(ConfigDispatchContext);
  const dispatch = useContext(LocalDispatchContext);
  const { editing } = useContext(ConfigStateContext);

  const updateField = useCallback(
    (name, value) => {
      dispatch({
        type: 'UPDATE_FIELD',
        payload: {
          name,
          value,
        },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    updateField('name', info.name);
  }, [info.name, updateField]);

  useEffect(() => {
    updateField('address', info.address);
  }, [info.address, updateField]);

  useEffect(() => {
    updateField('id', info.id);
  }, [info.id, updateField]);

  const startEdit = useCallback(
    e => {
      e.preventDefault();
      dispatchConfig({
        type: 'SET_EDITING',
      });
    },
    [dispatchConfig]
  );

  return editing ? (
    <EditLocal />
  ) : (
    <SeeItem submitEdit={startEdit}>
      <LabelAndValue label="Nome" value={info.name} />
      <LabelAndValue label="Morada" value={info.address} />
    </SeeItem>
  );
};

SeeEditLocal.propTypes = {
  info: PropTypes.object.isRequired,
};

export default React.memo(SeeEditLocal);
