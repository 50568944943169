import React, { useCallback } from 'react';
import { makeStyles, Box, Divider, Collapse } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ExpandMore } from '@material-ui/icons';
import translateSlug from './translateSlug';
import clsx from 'clsx';
import Subtitle4 from '../../common/typographys/Subtitle4';
import CheckboxPicker from '../../common/forms/CheckboxPicker';

const useStyles = makeStyles(theme => ({
  panelHeader: {
    backgroundColor: theme.palette.neutrals[2],
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 10px',
  },
  icon: {
    color: theme.palette.neutrals[6],
    '&:hover': {
      cursor: 'pointer',
    },
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.short,
    }),
  },
  iconOpen: {
    transform: 'rotate(180deg)',
  },
  iconClosed: {
    transform: 'rotate(0)',
  },
  permissionsContent: {
    padding: '10px',
  },
  divider: {
    marginTop: '12px',
    marginBottom: '12px',
  },
}));

// @ Passar a dispatch para reutilizar este componente
const PermissionsCollapse = ({ slug, open, permissions = {}, dispatch }) => {
  const classes = useStyles();

  const handleChange = useCallback(
    (value, expanded) => e => {
      e.preventDefault();
      dispatch({
        type: 'UPDATE_FIELD',
        payload: {
          name: 'panel',
          value: expanded ? '' : value,
        },
      });
    },
    [dispatch]
  );

  const updatePermission = useCallback(
    (group, label, value) => {
      dispatch({
        type: 'UPDATE_PERMISSION',
        payload: {
          group,
          label,
          value,
        },
      });
    },
    [dispatch]
  );

  const keysPermissions = Object.keys(permissions);

  return (
    <>
      <Box className={classes.panelHeader} onClick={handleChange(slug, open)}>
        <Subtitle4>{translateSlug(slug)}</Subtitle4>
        <ExpandMore className={clsx(classes.icon, { [classes.iconOpen]: open, [classes.iconClosed]: !open })} />
      </Box>
      <Collapse in={open}>
        <Box height="4px" />
        <Box className={classes.permissionsContent}>
          {keysPermissions.map((permission, idx) => (
            <React.Fragment key={`${slug}-${permission}`}>
              <CheckboxPicker
                onClick={() => updatePermission(slug, permission, !permissions[permission])}
                checked={permissions[permission]}
                label={translateSlug(permission)}
              />
              {idx + 1 !== keysPermissions.length && <Divider className={classes.divider} />}
            </React.Fragment>
          ))}
        </Box>
      </Collapse>
      {open ? <Box height="8px" /> : <Box height="12px" />}
    </>
  );
};

PermissionsCollapse.defaultProps = {
  permissions: {},
};

PermissionsCollapse.propTypes = {
  slug: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  permissions: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
};

export default React.memo(PermissionsCollapse);
