import React, { useCallback } from 'react';
import { makeStyles, Box, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body1 from '../../../../common/typographys/Body1';
import SingleUserEntry from './SingleUserEntry';
import clsx from 'clsx';
import Body2 from '../../../../common/typographys/Body2';
import addSeconds from 'date-fns/addSeconds';
import startOfToday from 'date-fns/startOfToday';
import formatDate from '../../../../../utils/formatDate';
// import GroupInterventions from './GroupInterventions';

const useStyles = makeStyles(theme => ({
  boxName: {
    padding: '0px 14px',
    marginBottom: '2px',
  },
  groupTimeBox: {
    height: '4px',
  },
  darkGreen: {
    backgroundColor: theme.palette.colorsPalette.TrueGreen,
  },
  nameWithTime: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const secondsToMinutes = (time, isNegative) => {
  const dateWithSeconds = addSeconds(startOfToday(), time);
  const result = formatDate(dateWithSeconds, 'HH:mm:ss');
  if (isNegative) {
    return `+${result}`;
  }

  return result;
};

const getTalkPercentage = (time, current) => {
  return ((parseInt(current, 10) * 100) / parseInt(time, 10)).toFixed(2);
};

const GroupUsersTimer = ({
  groups,
  participants,
  moderator,
  infoSpeaking,
  hasMatrix,
  activeInfo,
  activePointId,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const isMemberAbsent = useCallback(
    userId => {
      if (moderator) {
        const participantObj = participants.find(x => x.id.toString() === userId.toString());
        return participantObj === undefined || !participantObj.active;
      }

      return false;
    },
    [participants, moderator]
  );

  return groups.map((group, idx) => {
    const { name, users, time, current, /* others, */ no_limit, id } = group;

    const groupPercentage = getTalkPercentage(time, current);
    const paintedPercentage = groupPercentage >= 100 ? 100 : 100 - groupPercentage;

    const style =
      groupPercentage > 0 && hasMatrix
        ? {
            background: `linear-gradient(to right,${
              groupPercentage >= 100
                ? theme.palette.error.main
                : theme.palette.colorsPalette.TrueBlue
            } ${paintedPercentage}%, ${theme.palette.neutrals[2]} ${paintedPercentage}%)`,
          }
        : {};

    return (
      <React.Fragment key={id}>
        <Box height={idx !== 0 ? '20px' : '24px'} />
        <Box className={clsx(classes.boxName, { [classes.nameWithTime]: hasMatrix })}>
          <Body1>{name}</Body1>
          {hasMatrix && (
            <Body2>{secondsToMinutes(Math.abs(time - current), current >= time)}</Body2>
          )}
        </Box>
        <Box
          className={clsx(classes.groupTimeBox, {
            [classes.darkGreen]: hasMatrix && (Boolean(no_limit) === true || groupPercentage <= 0),
          })}
          style={style}
        />
        {users
          ?.filter(user2 => user2.in_meeting)
          .map(user => {
            const objUser =
              (participants && participants.find(x => user && x.id === user.id)) || {};
            const isCheckedIn = objUser.checkIn || false;

            return (
              <SingleUserEntry
                key={user.id}
                user={user}
                moderator={moderator}
                isMemberAbsent={isMemberAbsent(user.id)}
                reunionEnd={false}
                infoSpeaking={infoSpeaking}
                idGroup={group.id}
                activeInfo={activeInfo}
                groupTime={time}
                isCheckedIn={isCheckedIn}
                activePointId={activePointId}
                isGroup
              />
            );
          })}
        {/* <GroupInterventions
          current={others}
          moderator={moderator}
          reunionEnd={false}
          id={group.id}
          infoSpeaking={infoSpeaking}
          activeInfo={activeInfo}
        /> */}
        {/* TODO: hardcoded reunionEnd */}
      </React.Fragment>
    );
  });
};

GroupUsersTimer.defaultProps = {
  moderator: false,
  hasMatrix: false,
  infoSpeaking: null,
  activePointId: null,
};

GroupUsersTimer.propTypes = {
  groups: PropTypes.array.isRequired,
  participants: PropTypes.array.isRequired,
  activePointId: PropTypes.number,
  hasMatrix: PropTypes.bool,
  moderator: PropTypes.bool,
  infoSpeaking: PropTypes.object,
};

export default React.memo(GroupUsersTimer);
