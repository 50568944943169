import React, { useRef, useCallback, useState } from 'react';
import { makeStyles, Divider, Grid, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body2 from '../../../common/typographys/Body2';
import Body1 from '../../../common/typographys/Body1';
import Subtitle2 from '../../../common/typographys/Subtitle2';
import BoxUpload from '../../../common/BoxUpload';
import isEmpty from '../../../../utils/isEmpty';
import ButtonNormal from '../../../common/buttons/ButtonNormal';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  uploadDeclaration,
  removeDeclaration,
  getMeetingPointDeclarations,
  getMeetingPointDeclarationsChanges,
  getMeetingUserDeclarations,
} from '../../../../store/actions/meetingsActions';
import { createUserDeclaration } from '../../../../store/actions/reunionsActions';

const useStyles = makeStyles(() => ({
  divider: {
    margin: '14px 0px 8px 0px',
  },
}));

const PendingDeclaration = ({ declaration, showLabel, meetingId, userId, clearSomething }) => {
  const classes = useStyles();
  const { point = {}, id: declarationId } = declaration;
  const { id: pointId, name } = point;
  const { id } = useParams();
  const filesRef = useRef(null);
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState('');
  const { id: idCurUser } = useSelector(state => state.auth.user);

  const handleUpload = useCallback(e => {
    e.preventDefault();
    if (filesRef.current && filesRef.current.files && filesRef.current.files.length > 0) {
      setFileName(filesRef.current.files[0].name);
    } else {
      setFileName('');
    }
  }, []);

  const submitFile = useCallback(
    async e => {
      e.preventDefault();

      dispatch({
        type: 'SHOW_SNACK',
        payload: {
          variant: 'info',
          message: 'A submeter ficheiro de declaração.',
        },
      });

      if (meetingId) {
        const createPromise = new Promise(resolve => {
          dispatch(createUserDeclaration(meetingId, pointId, userId, { type: 'file' }, resolve));
        });

        await createPromise;
      }

      const filePromise = new Promise(resolve => {
        const formData = new FormData();

        formData.append('file', filesRef.current.files[0]);
        if (meetingId) {
          dispatch(uploadDeclaration(meetingId, pointId, userId, formData, resolve));
        } else {
          dispatch(uploadDeclaration(id, pointId, userId, formData, resolve));
        }
      });

      const res = await filePromise;
      if (res && res.data && res.data.response) {
        dispatch({
          type: 'SHOW_SNACK',
          payload: {
            variant: 'success',
            message: 'Ficheiro submetido com sucesso.',
          },
        });

        if (meetingId) {
          dispatch(getMeetingUserDeclarations(meetingId, idCurUser));
          dispatch(getMeetingPointDeclarations(meetingId, pointId));
          dispatch(getMeetingPointDeclarationsChanges(meetingId, pointId));
          clearSomething();
        } else {
          dispatch(removeDeclaration(declarationId));
          clearSomething();
        }
      }
    },
    [dispatch, id, pointId, userId, declarationId, meetingId, clearSomething, idCurUser]
  );

  return (
    <>
      {showLabel ? (
        <>
          <Box height="20px" />
          <Subtitle2 tertiary>Submeter declaração de voto</Subtitle2>
        </>
      ) : (
        <Divider className={classes.divider} />
      )}
      {!isEmpty(name) && <Body2 secondary>{name}</Body2>}
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <BoxUpload
            filesRef={filesRef}
            handleUpload={handleUpload}
            labelBox="Carregar ficheiro"
            multiple={false}
            padding
          />
        </Grid>
        {!isEmpty(fileName) && (
          <Grid item>
            <Body1 secondary>{fileName}</Body1>
          </Grid>
        )}
      </Grid>
      {!isEmpty(fileName) && (
        <Grid container spacing={2}>
          <Grid item>
            <ButtonNormal
              variant="contained"
              contained
              pea
              onClick={submitFile}
              label="Enviar ficheiro"
              small
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

PendingDeclaration.defaultProps = {
  showLabel: false,
  meetingId: null,
  clearSomething: () => null,
};

PendingDeclaration.propTypes = {
  declaration: PropTypes.object.isRequired,
  meetingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  userId: PropTypes.number.isRequired,
  showLabel: PropTypes.bool,
  clearSomething: PropTypes.func,
};

export default React.memo(PendingDeclaration);
