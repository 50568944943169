import React, { useEffect, useReducer, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from '../../../../utils/isEmpty';
import {
  createUser,
  updateUser,
  inviteUser,
  updateUserProfile,
  updateUserPermissions,
  updateUserOrganicUnits,
  updateUserOrganicUnit,
} from '../../../../store/actions/usersActions';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import { ConfigStateContext, ConfigDispatchContext } from '../../MenuConfigProvider';
import validEmail from '../../../../utils/validation/validEmail';
import validateNIF from '../../../../utils/validateNIF';
import validPhone from '../../../../utils/validation/validPhone';

export const UserDispatchContext = React.createContext();
export const UserStateContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        changed_user_info: true,
        [action.payload.name]: action.payload.value,
      };
    case 'SET_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    case 'UPDATE_PERMISSION':
      return {
        ...state,
        permissions: {
          ...state.permissions,
          [action.payload.group]: {
            ...state.permissions[action.payload.group],
            [action.payload.label]: action.payload.value,
          },
        },
        changed_profile: false,
        changed_permission: true,
      };
    case 'CHANGE_USER_PROFILE':
      return {
        ...state,
        changed_profile: true,
        changed_permission: false,
      };
    case 'SET_CUSTOM_PROFILE':
      return {
        ...state,
        profile: {
          label: 'Permissões personalizadas',
          value: 'custom',
        },
      };
    case 'CLEAR_STATE':
      return {
        ...state,
        name: '',
        short_name: '',
        email: '',
        id: '',
        nif: '',
        phone: '',
        profile: null,
        councilor: false,
        role: '',
        cc: '',
        password: '',
        changed_user_info: false,
        changed_profile: false,
        changed_permission: false,
        changed_organic_units: false,
        changed_organic_unit: false,
        permissions: {
          typologies: { create: false, edit: false, delete: false },
          meetings: {
            create: false,
            edit: false,
            delete: false,
            invite: false,
            show_all: false,
            secretary: false,
            moderator: false,
          },
          flows: { create: false },
          proposals: { create: true, edit: false, delete: false, decide: false },
          presentations: { presenter_view: false, redaction_view: false },
          others: {
            locals: false,
            profiles: false,
            configs: false,
            groups: false,
            matrices: false,
            entities: false,
          },
          users: { create: false, invite: false, edit_permissions: false, edit_status: false },
        },
        panel: '',
        organicUnit: null,
        oldOrganicUnit: null,
        organicUnits: null,
        errors: {},
      };
    default:
      return state;
  }
};

const CreateEditUserProvider = ({ children, items }) => {
  const { loading } = useSelector(state => state.entities);
  const dispatchRedux = useDispatch();
  const dispatchConfig = useContext(ConfigDispatchContext);
  const { editing } = useContext(ConfigStateContext);
  const [state, dispatch] = useReducer(reducer, {
    name: '',
    email: '',
    permissions: {
      typologies: { create: false, edit: false, delete: false },
      meetings: {
        create: false,
        edit: false,
        delete: false,
        invite: false,
        show_all: false,
        secretary: false,
        moderator: false,
      },
      flows: { create: false },
      proposals: { create: true, edit: false, delete: false, decide: false },
      presentations: { presenter_view: false, redaction_view: false },
      others: {
        locals: false,
        profiles: false,
        configs: false,
        groups: false,
        matrices: false,
        entities: false,
      },
      users: { create: false, invite: false, edit_permissions: false, edit_status: false },
    },
    id: '',
    profile: null,
    short_name: '',
    nif: '',
    phone: '',
    councilor: false,
    role: '',
    cc: '',
    password: '',
    changed_profile: false,
    changed_permission: false,
    changed_organic_units: false,
    changed_organic_unit: false,
    panel: '',
    organicUnit: null,
    oldOrganicUnit: null,
    organicUnits: null,
    items: [],
    errors: {},
  });

  const {
    name,
    short_name,
    councilor,
    role,
    id,
    permissions,
    errors,
    email,
    nif,
    cc,
    phone,
    password,
    changed_user_info,
    changed_permission,
    changed_profile,
    profile,
    changed_organic_units,
    changed_organic_unit,
    organicUnits,
    organicUnit,
    oldOrganicUnit,
  } = state;

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: 'items',
        value: items,
      },
    });
  }, [items]);

  useEffect(() => {
    if (!isEmpty(name) && !isEmpty(errors.name)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { name: '' },
      });
    }
  }, [name, errors.name]);

  useEffect(() => {
    if (!isEmpty(short_name) && !isEmpty(errors.short_name)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { short_name: '' },
      });
    }
  }, [short_name, errors.short_name]);

  useEffect(() => {
    if (validEmail(email) && !isEmpty(errors.email)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { email: '' },
      });
    }
  }, [email, errors.email]);

  useEffect(() => {
    if (isEmpty(nif)) return;

    if (validateNIF(nif) && !isEmpty(errors.nif)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { nif: '' },
      });
    }
  }, [nif, errors.nif]);

  useEffect(() => {
    if ((!isEmpty(phone) && validPhone(phone) && !isEmpty(errors.phone)) || isEmpty(phone)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { phone: '' },
      });
    }
  }, [phone, errors.phone]);

  useEffect(() => {
    if (!isEmpty(password) && password.length >= 8 && !isEmpty(errors.password)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { password: '' },
      });
    }
  }, [password, errors.password]);

  useEffect(() => {
    if ((!isEmpty(role) && role.length >= 3 && !isEmpty(errors.role)) || isEmpty(role)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { role: '' },
      });
    }
  }, [role, errors.role]);

  useEffect(() => {
    if (
      (!isEmpty(cc) &&
        cc.replace(/\s+/g, '').length === 8 &&
        cc.replace(/\s+/g, '').length === 12 &&
        !isEmpty(errors.cc)) ||
      isEmpty(cc)
    ) {
      dispatch({
        type: 'SET_ERROR',
        payload: { cc: '' },
      });
    }
  }, [cc, errors.cc]);

  const handleNewUser = useCallback(
    async e => {
      e.preventDefault();

      if (!validEmail(email)) {
        return dispatch({
          type: 'SET_ERROR',
          payload: {
            email: 'Insira um email válido para o utilizador.',
          },
        });
      }

      const inviteUserPromise = new Promise((resolve, reject) => {
        dispatchRedux(inviteUser({ email }, resolve, reject));
      });

      return inviteUserPromise
        .then(() => {
          dispatch({
            type: 'CLEAR_STATE',
          });
          dispatchConfig({
            type: 'CANCEL_ADD_NEW',
          });
        })
        .catch(err => {
          dispatchRedux({
            type: 'SHOW_SNACK',
            payload: {
              variant: 'error',
              message: err,
            },
          });
        });
    },
    [dispatchConfig, dispatchRedux, email]
  );

  const handleCreateUser = useCallback(
    e => {
      e.preventDefault();
      const localErrors = {};

      if (name.length < 3) {
        localErrors.name = 'Insira um nome válido';
      }

      if (!validEmail(email)) {
        localErrors.email = 'Insira um email válido para o utilizador.';
      }

      if (!isEmpty(nif) && !validateNIF(nif)) {
        localErrors.nif = 'Insira um NIF válido';
      }

      if (!isEmpty(phone) && !validPhone(phone)) {
        localErrors.phone = 'Insira um contacto válido';
      }

      if (password.length < 8) {
        localErrors.password = 'Insira uma palavra-passe válida';
      }

      if (!isEmpty(role) && role.length < 3) {
        localErrors.role = 'Insira um Cargo válido';
      }

      if (
        !isEmpty(cc) &&
        cc.replace(/\s+/g, '').length !== 8 &&
        cc.replace(/\s+/g, '').length !== 12
      ) {
        localErrors.cc = 'Insira um Número de Identificação válido';
      }

      if (checkEmptyObject(localErrors).length > 0) {
        return dispatch({
          type: 'SET_ERROR',
          payload: localErrors,
        });
      }

      const createUserPromise = new Promise((resolve, reject) => {
        dispatchRedux(
          createUser(
            { email, password, name, short_name, role, cc, nif, phone, organicUnit },
            resolve,
            reject
          )
        );
      });

      return createUserPromise
        .then(() => {
          dispatch({
            type: 'CLEAR_STATE',
          });
          dispatchConfig({
            type: 'CANCEL_ADD_NEW',
          });
        })
        .catch(err => {
          dispatchRedux({
            type: 'SHOW_SNACK',
            payload: {
              variant: 'error',
              message: err,
            },
          });
        });
    },
    [
      cc,
      dispatchConfig,
      dispatchRedux,
      email,
      name,
      nif,
      organicUnit,
      password,
      phone,
      role,
      short_name,
    ]
  );

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      const localErrors = {};

      if (name.length < 3) {
        localErrors.name = 'Insira um nome válido';
      }

      if (!validEmail(email)) {
        localErrors.email = 'Insira um email válido para o utilizador.';
      }

      if (!isEmpty(nif) && !validateNIF(nif)) {
        localErrors.nif = 'Insira um NIF válido';
      }

      if (!isEmpty(phone) && !validPhone(phone)) {
        localErrors.phone = 'Insira um contacto válido';
      }

      if (checkEmptyObject(localErrors).length > 0) {
        return dispatch({
          type: 'SET_ERROR',
          payload: localErrors,
        });
      }

      if (editing) {
        // @ Pode editar: Unidade Orgânica, Unidades Orgânicas para o qual o user pode submeter propostas, Perfil e Permissões
        if (changed_organic_units) {
          const newOrganicUnits = organicUnits.map(x => ({ id: x.id, name: x.name, code: x.code }));
          dispatchRedux(updateUserOrganicUnits(id, { organicUnits: newOrganicUnits }));
        }

        if (changed_permission) {
          // @ Mudou permissões
          const newPermissions = { permissions };
          dispatchRedux(updateUserPermissions(id, newPermissions));
        }

        if (changed_profile) {
          await new Promise((resolve, reject) => {
            dispatchRedux(
              updateUserProfile(id, { ...profile }, resolve, reject, !changed_user_info)
            );
          });
        }

        if (changed_user_info) {
          await new Promise((resolve, reject) => {
            dispatchRedux(
              updateUser(id, { name, short_name, councilor, nif, phone }, resolve, reject)
            );
          });
        }

        if (changed_organic_unit && organicUnit?.id !== oldOrganicUnit?.id) {
          const newOrganicUnit = organicUnit
            ? { organicUnitId: organicUnit.id, ...organicUnit }
            : null;
          dispatchRedux(updateUserOrganicUnit(id, newOrganicUnit));
        }

        if (
          !changed_user_info &&
          !changed_organic_units &&
          !changed_permission &&
          !changed_profile &&
          !(changed_organic_unit && organicUnit.id !== oldOrganicUnit.id)
        ) {
          dispatchRedux({
            type: 'SHOW_SNACK',
            payload: {
              variant: 'info',
              message: 'Não foi alterada nenhuma informação do utilizador.',
            },
          });
        }
      }

      return true;
    },
    [
      dispatchRedux,
      permissions,
      editing,
      id,
      email,
      councilor,
      name,
      nif,
      phone,
      short_name,
      changed_user_info,
      changed_permission,
      organicUnits,
      changed_organic_units,
      changed_organic_unit,
      changed_profile,
      profile,
      organicUnit,
      oldOrganicUnit,
    ]
  );

  const contextState = useMemo(
    () => ({ state, handleSubmit, loading, handleNewUser, handleCreateUser }),
    [state, handleSubmit, loading, handleNewUser, handleCreateUser]
  );

  return (
    <UserDispatchContext.Provider value={dispatch}>
      <UserStateContext.Provider value={contextState}>{children}</UserStateContext.Provider>
    </UserDispatchContext.Provider>
  );
};

CreateEditUserProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  items: PropTypes.array.isRequired,
};

export default CreateEditUserProvider;
