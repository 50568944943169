import React, { useCallback } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import Subtitle2 from '../../../../common/typographys/Subtitle2';
import Subtitle1 from '../../../../common/typographys/Subtitle1';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import ParticipantsList from './ParticipantsList';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  label: {
    marginTop: '30px',
    marginBottom: '8px',
  },
}));

// @ Retirado dos Docs do react-beautiful-dnd
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const OrganizeParticipants = ({ participants, dispatch }) => {
  const classes = useStyles();

  const onDragEnd = useCallback(
    (result, arr) => {
      if (!result.destination || result.destination.index === result.source.index) {
        return;
      }

      const sortedParticipants = reorder(arr, result.source.index, result.destination.index);

      dispatch({
        type: 'UPDATE_FIELD',
        payload: {
          name: 'participants',
          value: sortedParticipants,
        },
      });
    },
    [dispatch]
  );

  return (
    <>
      <Subtitle2 className={classes.label}>Organização dos participantes</Subtitle2>
      <Subtitle1>
        Ordene a lista de participantes. Esta ordem será apresentada sempre que o grupo for
        carregado, como por exemplo, na adição do grupo a uma tipologia.
      </Subtitle1>
      <Box height="20px" />
      <DragDropContext onDragEnd={e => onDragEnd(e, participants)} className={classes.dragContext}>
        <Droppable droppableId="list">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <ParticipantsList participants={participants} />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

OrganizeParticipants.propTypes = {
  participants: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default OrganizeParticipants;
