import {
  GET_NOTIFICATIONS,
  REMOVE_NOTIFICATION,
  READ_ALL_NOTIFICATIONS,
  CLICK_NOTIFICATION,
} from '../actions/types';

const initialState = {
  notifications: [],
  read: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(x => x.id !== action.payload),
      };
    case READ_ALL_NOTIFICATIONS:
      return {
        ...state,
        notifications: state.notifications.map(x =>
          x.status === 'unread' ? { ...x, status: 'read' } : x
        ),
      };
    case CLICK_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.map(x =>
          x.id === action.payload ? { ...x, status: 'clicked' } : x
        ),
      };
    default:
      return state;
  }
};
