import React, { useContext, useEffect } from 'react';
import AddNewItem from '../../pieces/AddNewItem';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import { TypologyStateContext, TypologyDispatchContext } from './CreateEditTypologyProvider';
import FormInfoTypology from './FormInfoTypology';

const AddNewTypology = () => {
  const {
    state: { errors, items },
    handleSubmit,
    loading,
  } = useContext(TypologyStateContext);
  const dispatch = useContext(TypologyDispatchContext);

  useEffect(() => {
    dispatch({
      type: 'CLEAR_STATE',
    });
  }, [dispatch]);

  return (
    <AddNewItem
      disableSubmit={checkEmptyObject(errors).length > 0}
      items={items}
      loading={loading}
      dispatch={dispatch}
      handleSubmit={handleSubmit}
      msgSubmit="Tem a certeza que deseja gravar a nova tipologia?"
    >
      <FormInfoTypology />
    </AddNewItem>
  );
};

export default AddNewTypology;
