import React, { useEffect, useReducer, useCallback } from 'react';
import { Grid, Box, Button, Typography } from '@material-ui/core';
import InputField from '../../../common/forms/InputField';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkEmailToken,
  verifyEmailToken,
  verifyPhoneCode,
} from '../../../../store/actions/authActions';
import loginRegisterStyles from '../../../common/layout/styles/loginRegister';
import clsx from 'clsx';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import LoadingSpinner from '../../../common/LoadingSpinner';

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };

    default:
      return state;
  }
};

const FormVerify = () => {
  const [state, dispatch] = useReducer(reducer, {
    email: '',
    code: '',
    phone: '',
    token: '',
    errors: {},
  });
  const location = useLocation();
  const history = useHistory();
  const { status, loading } = useSelector(stateRedux => stateRedux.auth);
  const dispatchRedux = useDispatch();
  const classes = loginRegisterStyles();
  const { errors, email, code, phone, token } = state;

  const updateField = useCallback((name, value) => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: { name, value },
    });
  }, []);

  useEffect(() => {
    if (status.phone) {
      history.push('/');
      dispatchRedux({
        type: 'SHOW_SNACK',
        payload: {
          variant: 'success',
          message: 'Conta validada com sucesso.',
        },
      });
    }
  }, [status, history, dispatchRedux]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    updateField('email', parsed.email);
    updateField('phone', parsed.phone);
    updateField('token', parsed.token);
    dispatchRedux(checkEmailToken(parsed));
  }, [location, dispatchRedux, updateField]);

  const submitLogin = e => {
    e.preventDefault();
    if (status.email) {
      // @ Utilizador já validou o email, tem de validar o contacto
      const info = {
        phone,
        code,
        email,
      };

      return dispatchRedux(verifyPhoneCode(info));
    }

    const info = {
      token,
      email,
    };

    return dispatchRedux(verifyEmailToken(info));
  };

  const fields = [
    {
      label: 'Email',
      name: 'email',
      margin: 'none',
      type: 'email',
      disabled: true,
    },
    {
      label: 'Contacto telefónico',
      name: 'phone',
      margin: 'none',
      type: 'tel',
      disabled: true,
    },
  ];

  if (status.email) {
    fields.push({
      label: 'Código PIN',
      type: 'number',
      name: 'code',
      margin: 'none',
      placeholder: 'Insira o código recebido no seu contacto telefónico',
      onKeyPress: e => {
        if (e.key === 'Enter') {
          submitLogin(e);
        }
      },
    });
  }

  return (
    <Grid container className={classes.fullHeightLogin}>
      <Grid item lg={6} xs={10} className={clsx([classes.marginHorizontal], [classes.flexColumn])}>
        <Typography variant="h6" className={classes.labelMargin}>
          Verificar registo
        </Typography>
        {fields.map(item => (
          <InputField
            key={item.name}
            onChange={e => updateField(item.name, e.target.value)}
            value={state[item.name]}
            error={errors[item.name]}
            {...item}
          />
        ))}
        <Box width="100%">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={submitLogin}
            className={classes.buttonLogin}
            disabled={loading}
          >
            Verificar {status.email ? 'contacto telefónico' : 'email'}
          </Button>
        </Box>
        {/* <Box marginBottom={8} className={classes.marginTop} display="flex" justifyContent="center">
          <Typography variant="button" color="textSecondary">
            Ainda não tem conta?{' '}
            <Link to="/registo" classes={classes.cleanLink}>
              Registe-se aqui
            </Link>
          </Typography>
        </Box> */}
      </Grid>
      {loading && <LoadingSpinner />}
    </Grid>
  );
};

export default FormVerify;
