import { GET_FLOWS, GET_FLOW, UPDATE_FLOW, FLOWS_LOADING, FLOWS_NOT_LOADING, CREATE_FLOW, REMOVE_FLOW } from '../actions/types';

const initialState = {
  flows: [],
  flow: {
    users: [],
  },
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FLOWS:
      return {
        ...state,
        flows: action.payload,
        loading: false,
      };
    case GET_FLOW:
      return {
        ...state,
        flow: action.payload,
        loading: false,
      };
    case CREATE_FLOW:
      return {
        ...state,
        flows: [action.payload, ...state.flows],
        loading: false,
      };
    case UPDATE_FLOW:
      return {
        ...state,
        flows: state.flows.map(x => (x.id === action.payload.id ? action.payload : x)),
        loading: false,
      };
    case REMOVE_FLOW:
      return {
        ...state,
        flows: state.flows.filter(x => x.id !== action.payload.id),
        loading: false,
      };
    case FLOWS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case FLOWS_NOT_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
