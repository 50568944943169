import axios from 'axios';
import {
  GET_MATRICES,
  CREATE_MATRIX,
  UPDATE_MATRIX,
  GET_ERRORS,
  SHOW_SNACK,
  MATRICES_LOADING,
  MATRICES_NOT_LOADING,
  GET_MATRIX,
  REMOVE_MATRIX,
} from './types';

export const getAllMatrices = () => dispatch => {
  dispatch(setMatricesLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/matrices`)
    .then(res => {
      dispatch({
        type: GET_MATRICES,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(setMatricesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getMatrix = id => dispatch => {
  dispatch(setMatricesLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/matrices/${id}`)
    .then(res => {
      dispatch({
        type: GET_MATRIX,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(setMatricesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const createMatrix = info => dispatch => {
  dispatch(setMatricesLoading());

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/matrices`, info)
    .then(res => {
      dispatch({
        type: CREATE_MATRIX,
        payload: { ...res.data, can_delete: true },
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Matriz criada com sucesso.',
        },
      });
    })
    .catch(err => {
      dispatch(setMatricesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'error',
          message: 'Não foi possível criar matriz.',
        },
      });
    });
};

export const updateMatrix = (id, info) => dispatch => {
  dispatch(setMatricesLoading());

  axios
    .put(`${process.env.REACT_APP_API_URL}/api/matrices/${id}`, info)
    .then(res => {
      dispatch({
        type: UPDATE_MATRIX,
        payload: res.data,
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Matriz atualizada com sucesso.',
        },
      });
    })
    .catch(err => {
      dispatch(setMatricesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const removeMatrix = (id, callback) => dispatch => {
  dispatch(setMatricesLoading());

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/matrices/${id}/disable`)
    .then(() => {
      dispatch({
        type: REMOVE_MATRIX,
        payload: {
          id,
        },
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Matriz excluída com sucesso.',
        },
      });
      callback();
    })
    .catch(err => {
      dispatch(setMatricesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const setMatricesLoading = () => {
  return {
    type: MATRICES_LOADING,
  };
};

export const setMatricesNotLoading = () => {
  return {
    type: MATRICES_NOT_LOADING,
  };
};
