import axios from 'axios';
import {
  GET_PROPOSALS,
  GET_PROPOSAL,
  GET_ERRORS,
  SHOW_SNACK,
  PROPOSALS_LOADING,
  PROPOSALS_NOT_LOADING,
  GET_PROPOSAL_VERSIONS,
  GET_PROPOSAL_DECISIONS,
  GET_PROPOSAL_FLOW_CHANGES,
  GET_PROPOSAL_STATUSES,
  GET_PROPOSAL_MEETINGS,
} from './types';

export const getProposals = query => dispatch => {
  dispatch(setProposalsLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/proposals${query}`)
    .then(res => {
      dispatch({
        type: GET_PROPOSALS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(setProposalsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getProposalStatuses = () => dispatch => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/proposal_status`)
    .then(res => {
      dispatch({
        type: GET_PROPOSAL_STATUSES,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateProposalStatuses = (info, resolve, reject) => dispatch => {
  axios
    .put(`${process.env.REACT_APP_API_URL}/api/proposal_status`, info)
    .then(res => {
      resolve(res);
      dispatch({
        type: GET_PROPOSAL_STATUSES,
        payload: res.data,
      });
    })
    .catch(err => {
      reject(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getProposalVersions = id => dispatch => {
  dispatch(setProposalsLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/proposals/${id}/versions`)
    .then(res => {
      dispatch({
        type: GET_PROPOSAL_VERSIONS,
        payload: res.data,
      });
      const currentVersion = res.data[res.data.length - 1];
      dispatch({
        type: GET_PROPOSAL,
        payload: currentVersion,
      });
      dispatch(getProposalDecisions(id));
      dispatch(getProposalFlowChanges(id));
    })
    .catch(err => {
      dispatch(setProposalsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const submitNewProposalVersion = (idProposal, idVersion) => dispatch => {
  dispatch({
    type: SHOW_SNACK,
    payload: {
      variant: 'info',
      message: 'A submeter nova versão da proposta.',
    },
  });

  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/proposals/${idProposal}/versions/${idVersion}/submit`
    )
    .then(() => {
      dispatch(getProposalVersions(idProposal));
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Nova versão submetida com sucesso.',
        },
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const removeProposal = idProposal => dispatch => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/proposals/${idProposal}/remove`)
    .then(() => {
      dispatch(getProposalVersions(idProposal));
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Proposta removida com sucesso.',
        },
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getProposalMeetings = id => dispatch => {
  dispatch(setProposalsLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/proposals/${id}/meetings`)
    .then(res => {
      dispatch({
        type: GET_PROPOSAL_MEETINGS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(setProposalsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getProposalFlowChanges = id => dispatch => {
  dispatch(setProposalsLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/proposals/${id}/flow_changes`)
    .then(res => {
      dispatch({
        type: GET_PROPOSAL_FLOW_CHANGES,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(setProposalsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getProposalDecisions = idProposal => dispatch => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/proposals/${idProposal}/decisions`)
    .then(res => {
      dispatch({
        type: GET_PROPOSAL_DECISIONS,
        payload: res.data,
      });
    })
    .catch(() => null);
};

// eslint-disable-next-line no-unused-vars
export const startEvaluation = (idProposal, idVersion) => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/proposals/${idProposal}/versions/${idVersion}/start`
    )
    .then(() => null)
    .catch(() => null);
};

export const changeActiveVersion = info => dispatch => {
  dispatch({
    type: GET_PROPOSAL,
    payload: info,
  });
  // dispatch(getProposalDecisions(info.proposal_id));
};

export const createProposal = (info, resolve, reject) => dispatch => {
  dispatch(setProposalsLoading());

  const newProposal = {
    ...info,
    proposalNumber: info.proposal_number,
  };

  if (info?.on_behalf_of?.id) {
    newProposal.onBehalfOf = { id: info.on_behalf_of.id };
  }

  delete newProposal.proposal_number;
  delete newProposal.on_behalf_of;

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/proposals`, newProposal)
    .then(res => {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Proposta criada com sucesso.',
        },
      });
      dispatch(setProposalsNotLoading());
      resolve(res.data);
    })
    .catch(err => {
      dispatch(setProposalsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      reject(err);
    });
};

export const uploadProposalFiles = (attachments, length, id, resolve, reject) => dispatch => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  dispatch({
    type: SHOW_SNACK,
    payload: {
      variant: 'info',
      message: `A carregar o${length > 1 ? 's' : ''} ficheiro${length > 1 ? 's' : ''} da proposta.`,
    },
  });

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/proposals/${id}/attachments`, attachments, config)
    .then(res => {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: `Ficheiro${length > 1 ? 's' : ''} carregado${
            length > 1 ? 's' : ''
          } com sucesso.`,
        },
      });
      resolve(res.data);
    })
    .catch(err => {
      dispatch(setProposalsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      reject(err);
    });
};

export const updateSummaryFile = (id, data, resolve, reject) => dispatch => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  dispatch({
    type: SHOW_SNACK,
    payload: {
      variant: 'info',
      message: 'A carregar o ficheiro de resumo.',
    },
  });

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/proposals/${id}/upload_summary`, data, config)
    .then(() => {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Ficheiro de resumo carregado com sucesso.',
        },
      });
      resolve();
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      reject(err);
    });
};

export const uploadProposalVersionFiles =
  (attachments, length, idProposal, idVersion, resolve, reject) => dispatch => {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const pluralS = length > 1 ? 's' : '';
    dispatch({
      type: SHOW_SNACK,
      payload: {
        variant: 'info',
        message: `A carregar o${pluralS} novo${pluralS} ficheiro${pluralS} da proposta.`,
      },
    });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/proposals/${idProposal}/versions/${idVersion}/attachments`,
        attachments,
        config
      )
      .then(res => {
        dispatch({
          type: SHOW_SNACK,
          payload: {
            variant: 'success',
            message: `Ficheiro${pluralS} carregado${pluralS} com sucesso.`,
          },
        });
        resolve(res.data);
      })
      .catch(err => {
        dispatch(setProposalsNotLoading());
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        reject(err);
      });
  };

export const downloadProposalFile = (idProposal, idFile, nameFile) => dispatch => {
  dispatch(setProposalsLoading());

  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/proposals/${idProposal}/attachments/${idFile}/download`,
      {},
      { responseType: 'blob' }
    )
    .then(res => {
      const filename =
        nameFile ||
        (res.headers['content-disposition'] &&
          res.headers['content-disposition'].split('filename=')[1]) ||
        'download.pdf';
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => {
      dispatch(setProposalsNotLoading());
      const reader = new FileReader();
      reader.addEventListener('loadend', () => {
        dispatch({
          type: GET_ERRORS,
          payload: JSON.parse(reader.result),
        });
      });

      reader.readAsText(err.response.data);
    });
};

export const downloadProposalResume = (idProposal, code) => dispatch => {
  dispatch(setProposalsLoading());

  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/proposals/${idProposal}/docx`,
      {},
      { responseType: 'blob' }
    )
    .then(res => {
      const filename = `${code}.pdf`;
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => {
      dispatch(setProposalsNotLoading());
      const reader = new FileReader();
      reader.addEventListener('loadend', () => {
        dispatch({
          type: GET_ERRORS,
          payload: JSON.parse(reader.result),
        });
      });

      reader.readAsText(err.response.data);
    });
};

export const downloadProposalSummary = (idProposal, nameFile) => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/proposals/${idProposal}/download_summary`,
      {},
      { responseType: 'blob' }
    )
    .then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', nameFile);
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => {
      const reader = new FileReader();
      reader.addEventListener('loadend', () => {
        dispatch({
          type: GET_ERRORS,
          payload: JSON.parse(reader.result),
        });
      });

      reader.readAsText(err.response.data);
    });
};

export const updateProposal = (idProposal, info, resolve) => dispatch => {
  axios
    .put(`${process.env.REACT_APP_API_URL}/api/proposals/${idProposal}`, info)
    .then(res => {
      resolve(res.data);
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateProposalFlow = (idProposal, info, resolve) => dispatch => {
  axios
    .put(`${process.env.REACT_APP_API_URL}/api/proposals/${idProposal}/flow`, info)
    .then(res => {
      resolve(res.data);
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const deleteProposalFile = (idProposal, idFile) => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/proposals/${idProposal}/attachments/${idFile}/delete`
    )
    .then(() => null)
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const downloadProposalDecisionFile = (decisionId, idFile, nameFile) => dispatch => {
  dispatch(setProposalsLoading());

  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/decisions/${decisionId}/attachments/${idFile}/download`,
      {},
      { responseType: 'blob' }
    )
    .then(res => {
      const filename =
        nameFile ||
        (res.headers['content-disposition'] &&
          res.headers['content-disposition'].split('filename=')[1]) ||
        'download.pdf';
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => {
      dispatch(setProposalsNotLoading());
      const reader = new FileReader();
      reader.addEventListener('loadend', () => {
        dispatch({
          type: GET_ERRORS,
          payload: JSON.parse(reader.result),
        });
      });

      reader.readAsText(err.response.data);
    });
};

export const submitApproverDecision =
  (idProposal, idDecision, idVersion, info, resolve, reject) => dispatch => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/proposals/${idProposal}/decisions/${idDecision}/answer`,
        info
      )
      .then(() => {
        dispatch({
          type: SHOW_SNACK,
          payload: {
            variant: 'success',
            message: 'Resposta submetida com sucesso.',
          },
        });
        dispatch(getProposalDecisions(idProposal));
        resolve();
      })
      .catch(err => {
        reject();
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      });
  };

export const submitApproverFiles =
  (idProposal, idDecision, length, attachments, resolve, reject) => dispatch => {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    dispatch({
      type: SHOW_SNACK,
      payload: {
        variant: 'info',
        message: `A carregar o${length > 1 ? 's' : ''} ficheiro${
          length > 1 ? 's' : ''
        } da proposta.`,
      },
    });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/proposals/${idProposal}/decisions/${idDecision}/attachments`,
        attachments,
        config
      )
      .then(res => {
        dispatch({
          type: SHOW_SNACK,
          payload: {
            variant: 'success',
            message: `Ficheiro${length > 1 ? 's' : ''} carregado${
              length > 1 ? 's' : ''
            } com sucesso.`,
          },
        });
        resolve(res.data);
      })
      .catch(err => {
        dispatch(setProposalsNotLoading());
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
        reject(err);
      });
  };

export const startApprover = (idProposal, idVersion, resolve) => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/proposals/${idProposal}/versions/${idVersion}/start`
    )
    .then(() => resolve())
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const setProposalsLoading = () => {
  return {
    type: PROPOSALS_LOADING,
  };
};

export const setProposalsNotLoading = () => {
  return {
    type: PROPOSALS_NOT_LOADING,
  };
};
