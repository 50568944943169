import {
  GET_ENTITIES,
  UPDATE_ENTITY,
  GET_ENTITY_PROPOSALS,
  ENTITIES_LOADING,
  ENTITIES_NOT_LOADING,
  CREATE_ENTITY,
  REMOVE_ENTITY,
} from '../actions/types';

const initialState = {
  entities: [],
  entity: {},
  proposals: [],
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ENTITIES:
      return {
        ...state,
        entities: action.payload,
        loading: false,
      };
    case GET_ENTITY_PROPOSALS:
      return {
        ...state,
        proposals: action.payload,
      };
    case CREATE_ENTITY:
      return {
        ...state,
        entities: [action.payload, ...state.entities],
        loading: false,
      };
    case UPDATE_ENTITY:
      return {
        ...state,
        entities: state.entities.map(x => (x.id === action.payload.id ? action.payload : x)),
        loading: false,
      };
    case REMOVE_ENTITY:
      return {
        ...state,
        entities: state.entities.filter(x => x.id !== action.payload.id),
        loading: false,
      };
    case ENTITIES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ENTITIES_NOT_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
