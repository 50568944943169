import {
  UPDATE_REGISTER_INFO,
  UPDATE_USER_INFO,
  CLEAN_REGISTER_INFO,
  SET_CURRENT_USER,
  SHOW_CODE_PIN,
  TOKEN_EXPIRED,
  UPDATE_USER,
  REGISTER_STATUS,
  UPDATE_PROFILE_IMAGE,
  GET_USER_STATS,
  CLEAN_AUTH_STATUS,
  AUTH_LOADING,
  AUTH_NOT_LOADING,
  GET_USER_ACTIONS,
} from '../actions/types';
import isEmpty from '../../utils/isEmpty';

const initialState = {
  registration: {
    step: '1',
  },
  user: {
    email: '',
    name: '',
    profile: '',
    stats: [],
  },
  showPin: false,
  expired: false,
  pinToken: null,
  isAuthenticated: false,
  isEmailSet: false,
  loading: false,
  actions: [],
  status: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        isEmailSet: !isEmpty(action.payload) && !isEmpty(action.payload.email),
        user: { ...state.user, ...action.payload },
        showPin: false,
        expired: false,
        pinToken: null,
        loading: false,
      };
    case UPDATE_REGISTER_INFO:
      return {
        ...state,
        registration: {
          ...state.registration,
          ...action.payload,
        },
        loading: false,
      };
    case UPDATE_USER_INFO:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
        loading: false,
      };
    case CLEAN_REGISTER_INFO:
      return {
        ...state,
        registration: {
          step: '1',
        },
        loading: false,
      };
    case REGISTER_STATUS:
      return {
        ...state,
        status: action.payload,
        loading: false,
      };
    case UPDATE_USER:
      if (state.user.id === action.payload.idUser) {
        return {
          ...state,
          user: {
            ...state.user,
            ...action.payload,
          },
          loading: false,
        };
      }
      return state;
    case UPDATE_PROFILE_IMAGE:
      return {
        ...state,
        user: {
          ...state.user,
          image: action.payload,
        },
        loading: false,
      };
    case GET_USER_STATS:
      return {
        ...state,
        user: {
          ...state.user,
          stats: action.payload,
        },
        loading: false,
      };
    case GET_USER_ACTIONS:
      return {
        ...state,
        actions: action.payload,
        loading: false,
      };
    case CLEAN_AUTH_STATUS:
      return {
        ...state,
        status: {},
        loading: false,
      };
    case SHOW_CODE_PIN:
      return {
        ...state,
        showPin: true,
        expired: false,
        pinToken: action.payload,
        loading: false,
      };
    case TOKEN_EXPIRED:
      return {
        ...state,
        expired: true,
        loading: false,
      };
    case AUTH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case AUTH_NOT_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
