import React, { useMemo } from 'react';
import { makeStyles, Box, Button } from '@material-ui/core';
import { AddCircleOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.neutrals[4],
    marginLeft: '10px',
    width: '16px',
    height: '16px',
  },
  buttonText: {
    color: theme.palette.neutrals[5],
  },
  root: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      '& span': {
        transition: 'color 0.2s ease',
        color: theme.palette.neutrals[6],
      },
      '& svg': {
        transition: 'color 0.2s ease',
        color: theme.palette.neutrals[5],
      },
    },
  },
  input: {
    display: 'none',
  },
  box: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.neutrals[1],
  },
  padding: {
    padding: '13px',
  },
}));

const SingleBoxUpload = ({ filesRef, handleUpload, padding, labelBox }) => {
  const classes = useStyles();
  const inputId = useMemo(() => uuidv4(), []);

  return (
    <Box className={clsx(classes.box, { [classes.padding]: padding })}>
      <label htmlFor={inputId}>
        <input accept="image/*, application/*" className={classes.input} id={inputId} ref={filesRef} type="file" onChange={handleUpload} />
        <Button component="span" classes={{ label: classes.buttonText, root: classes.root }}>
          {labelBox}
          <AddCircleOutlined className={classes.icon} />
        </Button>
      </label>
    </Box>
  );
};

SingleBoxUpload.defaultProps = {
  padding: false,
  labelBox: 'Carregar ficheiros',
};

SingleBoxUpload.propTypes = {
  filesRef: PropTypes.object.isRequired,
  handleUpload: PropTypes.func.isRequired,
  padding: PropTypes.bool,
  labelBox: PropTypes.string,
};

export default React.memo(SingleBoxUpload);
