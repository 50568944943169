import React from 'react';
import { Grid } from '@material-ui/core';
import Body2 from '../../common/typographys/Body2';
import { useSelector } from 'react-redux';
import canSeePools from '../../../utils/permissions/canSeePools';

const ProposalsHeader = () => {
  const { permissions } = useSelector(state => state.auth.user);
  const checkPools = canSeePools(permissions);

  return (
    <Grid container spacing={3}>
      <Grid item xs={checkPools ? 4 : 5}>
        <Body2 secondary>Proposta</Body2>
      </Grid>
      <Grid item xs={2}>
        <Body2 secondary>Proponente</Body2>
      </Grid>
      <Grid item xs={checkPools ? 2 : 3}>
        <Body2 secondary>Reunião(ões)</Body2>
      </Grid>
      {checkPools && (
        <Grid item xs={2}>
          <Body2 secondary>Tipologia</Body2>
        </Grid>
      )}
      <Grid item xs={2}>
        <Body2 secondary>Estado</Body2>
      </Grid>
    </Grid>
  );
};

export default React.memo(ProposalsHeader);
