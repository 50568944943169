import { colors } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';

const white = '#FFFFFF';
const black = '#000000';

const colorsPalette = {
  Tomato: '#e31414',
  Green: '#2bb919',
  IceBlue: '#eaefef',
  Silver: '#ccd8d6',
  LightGreyBlue: '#acbebc',
  CoolGrey: '#8ba4a1',
  GreyishTeal: '#72908d',
  BlueGrey: '#5a7d7a',
  Slate: '#4f6e6a',
  Gunmetal: '#405a57',
  CharcoalGrey: '#324745',
  Dark: '#223230',
  OffWhite: '#f7fae6',
  GreenishBeige: '#d4e074',
  Pea: '#c6ae29',
  BrownOrange: '#bf7400',
  Color01: '#ffffff',
  VeryLightPink: '#e7e7e7',
  BrownGrey: '#9c9c9c',
  White: '#f8f8f8',
  VeryLightPinkTwo: '#d5d5d5',
  BrownishGrey: '#636363',
  Black: '#000000',
  RustRed: '#be0404',
  TrueGreen: '#13a600',
  Orange: '#f66f0d',
  TrueBlue: '#1976d2',
  RustRedDarker: '#870303',
};

export default {
  black,
  white,
  primary: {
    50: colorsPalette.IceBlue,
    100: colorsPalette.Silver,
    200: colorsPalette.LightGreyBlue,
    300: colorsPalette.CoolGrey,
    400: colorsPalette.GreyishTeal,
    500: colorsPalette.BlueGrey,
    600: colorsPalette.Slate,
    700: colorsPalette.Gunmetal,
    800: colorsPalette.CharcoalGrey,
    900: colorsPalette.Dark,
    A100: colorsPalette.OffWhite,
    A200: colorsPalette.GreenishBeige,
    A400: colorsPalette.Pea,
    A700: colorsPalette.BrownOrange,
    contrastText: white,
    dark: colorsPalette.Gunmetal,
    light: colorsPalette.CoolGrey,
    main: colorsPalette.BlueGrey,
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue.A400,
    light: colors.blue.A400,
  },
  neutrals: {
    1: colorsPalette.Color01,
    2: colorsPalette.White,
    3: colorsPalette.VeryLightPink,
    4: colorsPalette.VeryLightPinkTwo,
    5: colorsPalette.BrownGrey,
    6: colorsPalette.BrownishGrey,
    7: colorsPalette.Black,
  },
  error: {
    contrastText: colorsPalette.Color01,
    dark: colors.red[900],
    main: colorsPalette.RustRed,
    light: colors.red[400],
    darker: colorsPalette.RustRedDarker,
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  background: {
    paper: colorsPalette.Color01,
    default: fade(colorsPalette.VeryLightPink, 0.3),
  },
  divider: colors.grey[200],
  colorsPalette,
};
