import { createMuiTheme } from '@material-ui/core';
import { ptPT } from '@material-ui/core/locale';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const theme = createMuiTheme(
  {
    palette,
    props: {
      drawerWidth: 80,
      drawerWidthMobile: 80,
      pattern:
        "url(\"data:image/svg+xml,%3Csvg width='3px' height='4px' viewBox='0 0 3 3' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-1838.000000, -727.000000)' fill='%23ACBEBC' fill-rule='nonzero'%3E%3Cg transform='translate(1838.000000, 727.000000)'%3E%3Cpath d='M0,2 L1,2 L1,3 L0,3 L0,2 Z M2,0 L3,0 L3,1 L2,1 L2,0 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")",
      MuiPaper: {
        square: true,
      },
    },
    typography,
    overrides,
  },
  ptPT
);

export default theme;
