import React from 'react';
import { makeStyles, Paper, Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import H4 from '../../../common/typographys/H4';
import H5 from '../../../common/typographys/H5';
import VoteBottomBlock from './VoteBottomBlock';

const useStyles = makeStyles(() => ({
  paper: {
    width: '100%',
    height: '100%',
    padding: '23px 15px 23px 30px',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const BottomVote = ({ votes /* , maxVotes */ }) => {
  const classes = useStyles();

  const countVotes = votes.reduce(
    (acc, cur) => {
      acc[cur.vote] += 1;
      acc.total += 1;

      return acc;
    },
    { favor: 0, against: 0, abstention: 0, novote: 0, total: 0 }
  );

  return (
    <Paper className={classes.paper}>
      <Box display="flex" flexDirection="column" justifyContent="center" marginRight="30px">
        <H4>Votos</H4>
        <Box height="10px" />
        {/* <H5 inactive>
          {countVotes.total}/{maxVotes}
        </H5> */}
        <H5 inactive>Contabilizados: {countVotes.total}</H5>
      </Box>
      <Box width="800px">
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <VoteBottomBlock favor total={countVotes.favor} />
          </Grid>
          <Grid item xs={3}>
            <VoteBottomBlock against total={countVotes.against} />
          </Grid>
          <Grid item xs={3}>
            <VoteBottomBlock abstention total={countVotes.abstention} />
          </Grid>
          <Grid item xs={3}>
            <VoteBottomBlock novote total={countVotes.novote} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

BottomVote.propTypes = {
  votes: PropTypes.array.isRequired,
  // maxVotes: PropTypes.number.isRequired,
};

export default React.memo(BottomVote);
