import { makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';

const useCommonStyles = makeStyles(theme => ({
  white: {
    color: `${theme.palette.neutrals[1]}!important`,
  },
  secondary: {
    color: `${fade(theme.palette.neutrals[1], 0.4)}!important`,
  },
  center: {
    textAlign: 'center!important',
  },
  justify: {
    textAlign: 'justify!important',
  },
  right: {
    textAlign: 'right!important',
  },
  inactive: {
    color: `${theme.palette.neutrals[4]}!important`,
  },
  approved: {
    color: `${theme.palette.colorsPalette.Green}!important`,
  },
  disapproved: {
    color: `${theme.palette.colorsPalette.Tomato}!important`,
  },
  standby: {
    color: `${theme.palette.colorsPalette.Orange}!important`,
  },
  error: {
    color: `${theme.palette.error.main}!important`,
  },
  disabled: {
    pointerEvents: 'none!important',
    color: `${theme.palette.neutrals[5]}!important`,
  },
  printMultiline: {
    whiteSpace: 'pre-wrap!important',
  },
  wrapEllipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  bold: {
    fontWeight: 'bold!important',
  },
  underline: {
    textDecoration: 'underline!important',
  },
}));

export default useCommonStyles;
