import React from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import Body2 from '../../../../common/typographys/Body2';
import PropTypes from 'prop-types';
import NoThemeOrProposals from '../NoThemeOrProposals';
import ThemePoints from '../ThemePoints';
import H6 from '../../../../common/typographys/H6';
import clsx from 'clsx';
import AgendaPeriods from './AgendaPeriods';

const useStyles = makeStyles(() => ({
  voting: {
    opacity: '0.4',
    pointerEvents: 'none',
  },
  agenda: {
    marginBottom: '16px',
  },
}));

const ThemesAndPoints = ({
  voting,
  themes,
  points,
  activeInfo,
  handleInfo,
  handleImmediateVote,
  handleImmediateDiscussion,
  isPublic,
  moderator,
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={3} className={clsx({ [classes.voting]: voting })}>
      <Grid container spacing={1}>
        <Grid item xs={11}>
          <Body2 secondary className={classes.agenda}>
            Agenda
          </Body2>
          <H6>Período{isPublic && 's'}</H6>
          <AgendaPeriods
            isPublic={isPublic}
            handleInfo={handleInfo}
            handleImmediateDiscussion={handleImmediateDiscussion}
            activeInfo={activeInfo}
          />
          <Box height="30px" />
          <H6>Ordem do dia</H6>
          <Box height="25px" />
          {themes &&
            themes.length > 0 &&
            themes.map((theme, idxTheme) => (
              <React.Fragment key={theme.id}>
                <Box height={idxTheme === 0 ? '10px' : '24px'} />
                <Box>
                  <H6 secondary>{theme.name}</H6>
                </Box>
                {theme.points && theme.points.length > 0 ? (
                  <ThemePoints
                    points={theme.points}
                    activeInfo={activeInfo}
                    handleInfo={handleInfo}
                    handleImmediateVote={handleImmediateVote}
                    handleImmediateDiscussion={handleImmediateDiscussion}
                    idxTheme={idxTheme}
                  />
                ) : (
                  <NoThemeOrProposals
                    moderator={moderator}
                    activeInfo={activeInfo}
                    onClick={handleInfo}
                    idxTheme={idxTheme}
                  />
                )}
              </React.Fragment>
            ))}
          {points && points.length > 0 && (
            <>
              {themes && themes.length > 0 && <Box height="30px" />}
              <Body2 secondary>Ponto{points.length > 1 ? 's' : ''} de discussão</Body2>
              <ThemePoints
                points={points}
                activeInfo={activeInfo}
                handleInfo={handleInfo}
                handleImmediateVote={handleImmediateVote}
                handleImmediateDiscussion={handleImmediateDiscussion}
                idxTheme={null}
              />
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

ThemesAndPoints.defaultProps = {
  moderator: false,
  themes: [],
  points: [],
  handleImmediateVote: null,
  handleImmediateDiscussion: null,
};

ThemesAndPoints.propTypes = {
  voting: PropTypes.bool.isRequired,
  themes: PropTypes.array,
  activeInfo: PropTypes.object.isRequired,
  handleInfo: PropTypes.func.isRequired,
  handleImmediateVote: PropTypes.func,
  handleImmediateDiscussion: PropTypes.func,
  points: PropTypes.array,
  isPublic: PropTypes.bool.isRequired,
  moderator: PropTypes.bool,
};

export default React.memo(ThemesAndPoints);
