import React, { useEffect, useState } from 'react';
import { makeStyles, Box, Input, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';
import isEmpty from '../../../../../utils/isEmpty';

const useStyles = makeStyles(() => ({
  inputBox: {
    width: '100%',
    display: 'flex',
  },
  noPadding: {
    minHeight: '0px',
    '& input': {
      padding: 0,
    },
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
}));

const BoxInput = ({ value, onChange }) => {
  const classes = useStyles();
  const [error, setError] = useState('');

  useEffect(() => {
    if (value.length < 4 && isEmpty(error)) {
      setError('Nome inválido.');
    } else if (value.length > 3 && !isEmpty(error)) {
      setError('');
    }
  }, [value, error]);

  return (
    <Box className={classes.inputBox}>
      <Input
        autoFocus
        fullWidth
        value={value}
        onChange={onChange}
        placeholder={value}
        disableUnderline
        className={classes.noPadding}
        error={!isEmpty(error)}
      />
      {error && (
        <FormHelperText error className={classes.noWrap}>
          {error}
        </FormHelperText>
      )}
    </Box>
  );
};

BoxInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default React.memo(BoxInput);
