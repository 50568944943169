import React, { useCallback, useMemo } from 'react';
import { makeStyles, Grid, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import H5 from '../../common/typographys/H5';
import ProposalStatus from './ProposalStatus';
import CardBlock from '../../common/forms/CardBlock';
import Subtitle2 from '../../common/typographys/Subtitle2';
import LabelAndValue from '../../common/forms/LabelAndValue';
import isEmpty from '../../../utils/isEmpty';
import formatMoney from '../../../utils/formatMoney';
import ProposalInternalLink from '../pieces/ProposalInternalLink';
import FileBox from '../../common/FileBox';
import { useDispatch } from 'react-redux';
import {
  downloadProposalFile,
  downloadProposalSummary,
  downloadProposalResume,
} from '../../../store/actions/proposalsActions';
import MeetingDeliberation from './MeetingDeliberation';
import formatDate from '../../../utils/formatDate';
import ProposalDescription from '../pieces/ProposalDescription';
import EditProposalButtons from './EditProposalButtons';

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: '8px',
  },
  headerBox: {
    marginBottom: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

const ProposalInfoDetails = ({ proposal, hasVersions, hasMeetings, dialog, lastVersionId }) => {
  const classes = useStyles();
  const dispatchRedux = useDispatch();

  const {
    status,
    user = {},
    on_behalf_of,
    name,
    theme,
    organicUnit,
    hasBudget,
    budgetNumber,
    identifier_code,
    description,
    proposal_number,
    entity,
    amount,
    summary_file_name,
    internalLink,
    internalLinkText,
    proposal_id,
    meetingPointId,
    meetingPointStatus,
    meetingName,
    meetingUsers,
    meetingIsGroup,
    meetingId,
    id,
    created_at,
    attachments,
    can_edit,
    can_remove,
  } = proposal;
  const isDefault = useMemo(() => proposal && Boolean(proposal.default), [proposal]);
  const userId = user ? user.id : null;
  const isLastVersion = id === lastVersionId;
  const canEdit = can_edit && isLastVersion;
  const canRemove = can_remove && isLastVersion;

  const downloadResume = useCallback(
    () => e => {
      e.preventDefault();
      dispatchRedux(downloadProposalResume(proposal_id || id, identifier_code));
    },
    [dispatchRedux, proposal_id, id, identifier_code]
  );

  const downloadSubResume = useCallback(
    file => e => {
      e.preventDefault();
      dispatchRedux(downloadProposalSummary(proposal_id || id, file.name));
    },
    [dispatchRedux, proposal_id, id]
  );

  const downloadFile = useCallback(
    file => e => {
      e.preventDefault();
      dispatchRedux(downloadProposalFile(proposal_id || id, file.id, file.name));
    },
    [dispatchRedux, proposal_id, id]
  );

  const proposalAttachments = useMemo(() => {
    if (attachments && attachments.length > 0) {
      return attachments.reduce(
        (acc, cur) => {
          if (cur.user && cur.user.id === userId) {
            acc.user.push(cur);
          } else {
            acc.approvers.push(cur);
          }

          return acc;
        },
        { user: [], approvers: [] }
      );
    }

    return [];
  }, [attachments, userId]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box className={classes.headerBox}>
          <H5>Proposta - {identifier_code || name}</H5>
          {!dialog &&
            (!hasVersions || (hasVersions && lastVersionId === id)) &&
            (!hasMeetings || (meetingPointStatus && meetingPointStatus.status !== null)) && (
              <ProposalStatus status={status} />
            )}
        </Box>
        {Boolean(meetingPointStatus && meetingPointStatus.status !== null) && (
          <MeetingDeliberation
            meetingPointStatus={meetingPointStatus}
            meetingName={meetingName}
            meetingId={meetingId}
            meetingIsGroup={Boolean(meetingIsGroup)}
            meetingUsers={meetingUsers}
            pointId={meetingPointId || id || proposal_id}
            code={identifier_code || name}
          />
        )}
        {canEdit && <EditProposalButtons canEdit={canEdit} canRemove={canRemove} />}
        <CardBlock label="Detalhes" smallMargin>
          {user && <LabelAndValue label="Proponente" value={user.name} />}
          {on_behalf_of && (
            <LabelAndValue label="Proposta submetida em nome de" value={on_behalf_of.name} />
          )}
          {created_at && <LabelAndValue label="Data de submissão" value={formatDate(created_at)} />}
          {name && <LabelAndValue label="Nome da proposta" value={name} />}
          {theme && <LabelAndValue label="Tema" value={theme.name} />}
          {description && <ProposalDescription description={description} />}
          {proposal_number && (
            <LabelAndValue
              label="Número da proposta (Nº a atribuir pelo gabinete de apoio ao Executivo)"
              value={proposal_number}
            />
          )}
          {organicUnit && (
            <LabelAndValue
              label="Unidade Orgânica"
              value={`${organicUnit.abbreviation || organicUnit.code} - ${organicUnit.name}`}
            />
          )}
          {!isEmpty(entity) && <LabelAndValue label="Entidade" value={entity.name} />}
          {!isEmpty(internalLink) && (
            <>
              <Subtitle2 tertiary className={classes.label}>
                Referência interna (link para documentação de apoio)
              </Subtitle2>
              <ProposalInternalLink link={internalLink} text={internalLinkText} />
              <Box height="20px" />
            </>
          )}
          {Boolean(hasBudget) && <LabelAndValue label="Número do cabimento" value={budgetNumber} />}
          {!isEmpty(amount) && (
            // <LabelAndValue label="Valor da proposta" value={`${amount} €`} />
            <LabelAndValue label="Valor do cabimento" value={formatMoney(amount)} />
          )}
        </CardBlock>
        {!isDefault && (
          <CardBlock label="Ficheiro resumo">
            {summary_file_name ? (
              <FileBox
                file={{ name: summary_file_name }}
                download
                downloadFile={downloadSubResume}
              />
            ) : (
              <FileBox
                file={{ name: `${identifier_code}.pdf` }}
                download
                downloadFile={downloadResume}
              />
            )}
          </CardBlock>
        )}
        {attachments && attachments.length > 0 && (
          <>
            {proposalAttachments.user.length > 0 && (
              <CardBlock
                label={
                  proposalAttachments.approvers.length > 0
                    ? 'Ficheiros carregados pelo proponente'
                    : 'Ficheiros'
                }
                singlePadding
                smallMargin
              >
                {proposalAttachments.user.map(file => (
                  <FileBox
                    key={file.id || file.name}
                    file={file}
                    download
                    downloadFile={downloadFile}
                  />
                ))}
              </CardBlock>
            )}
            {proposalAttachments.approvers.length > 0 && (
              <CardBlock label="Ficheiros adicionados por aprovadores" singlePadding smallMargin>
                {proposalAttachments.approvers.map(file => (
                  <FileBox
                    key={file.id || file.name}
                    file={file}
                    download
                    downloadFile={downloadFile}
                  />
                ))}
              </CardBlock>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

ProposalInfoDetails.defaultProps = {
  proposal: {},
  hasVersions: false,
  hasMeetings: false,
  dialog: false,
  lastVersionId: null,
};

ProposalInfoDetails.propTypes = {
  proposal: PropTypes.object,
  hasVersions: PropTypes.bool,
  hasMeetings: PropTypes.bool,
  dialog: PropTypes.bool,
  lastVersionId: PropTypes.number,
};

export default React.memo(ProposalInfoDetails);
