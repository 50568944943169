import React from 'react';
import loginRegisterStyles from './styles/loginRegister';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const LoginRegisterSchema = ({ info, form }) => {
  const classes = loginRegisterStyles();

  return (
    <Grid container>
      <Grid item md={6} xs={12}>
        {form}
      </Grid>
      <Grid item md={6} xs={12} className={clsx([classes.bgBoldPrimary], [classes.flexColumn])}>
        {info}
      </Grid>
    </Grid>
  );
};

LoginRegisterSchema.propTypes = {
  info: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

export default LoginRegisterSchema;
