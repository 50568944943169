import { GET_ERRORS, CLEAR_ERRORS } from '../actions/types';
import setAuthToken from '../../utils/setAuthToken';

const initialState = {};

export default (state = initialState, action) => {
  if (
    action.type === GET_ERRORS &&
    (action.payload.error === 'Token not provided' ||
      action.payload.error === 'TOKEN_EXPIRED' ||
      action.payload.error === 'TOKEN_INVALID' ||
      action.payload.error === 'TOKEN_BLACKLISTED' ||
      action.payload.error === 'Token not provided') &&
    localStorage.getItem('jwtToken') !== null
  ) {
    localStorage.removeItem('jwtToken');

    setAuthToken(false);

    window.location.reload();
  }

  switch (action.type) {
    case GET_ERRORS:
      return { ...action.payload, response: false };
    case CLEAR_ERRORS:
      return {};
    default:
      return state;
  }
};
