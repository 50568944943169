import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import useCommonStyles from './useCommonStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.subtitle4,
  },
}));

const Subtitle4 = ({ children, center, right, oneLine, className, ...props }) => {
  const classes = useStyles();
  const common = useCommonStyles();

  return (
    <Typography
      variant="subtitle2"
      classes={{ root: classes.root }}
      className={clsx({
        [common.center]: center,
        [common.right]: right,
        [common.wrapEllipsis]: oneLine,
        [className]: className.length > 0,
      })}
      {...props}
    >
      {children}
    </Typography>
  );
};

Subtitle4.defaultProps = {
  center: false,
  right: false,
  oneLine: false,
  className: '',
};

Subtitle4.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]).isRequired,
  center: PropTypes.bool,
  right: PropTypes.bool,
  oneLine: PropTypes.bool,
  className: PropTypes.string,
};

export default Subtitle4;
