import React, { useCallback } from 'react';
import H5 from '../../common/typographys/H5';
import { Grid, makeStyles, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import ActionItem from './ActionItem';

const useStyles = makeStyles(() => ({
  marginHeader: {
    marginBottom: '16px',
  },
}));

const ActionsContainer = ({ actions }) => {
  const classes = useStyles();
  const history = useHistory();

  const clickUrl = useCallback(
    url => e => {
      e.preventDefault();
      return history.push(url);
    },
    [history]
  );

  const clickSeeAll = useCallback(
    e => {
      e.preventDefault();
      history.push('/acoes-pendentes');
    },
    [history]
  );

  return (
    actions.length > 0 && (
      <>
        <H5 className={classes.marginHeader}>Ações pendentes</H5>
        <Grid container spacing={2}>
          {actions.map((action, idx) =>
            idx < 6 ? <ActionItem key={action.url} action={action} clickUrl={clickUrl} /> : null
          )}
        </Grid>
        {actions && actions.length > 6 && (
          <Grid container spacing={2}>
            <Grid item>
              <Button variant="contained" color="primary" onClick={clickSeeAll}>
                ver tudo
              </Button>
            </Grid>
          </Grid>
        )}
      </>
    )
  );
};

ActionsContainer.propTypes = {
  actions: PropTypes.array.isRequired,
};

export default React.memo(ActionsContainer);
