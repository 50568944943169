import React, { useEffect, useReducer, useState, useContext, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from '../../../../utils/isEmpty';
import { clearErrors } from '../../../../store/actions/authActions';
import { updateTypology, createTypology } from '../../../../store/actions/typologiesActions';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import { ConfigStateContext } from '../../MenuConfigProvider';

export const TypologyStateContext = React.createContext();
export const TypologyFuncsContext = React.createContext();
export const TypologyDispatchContext = React.createContext();

const colors = [
  '#5a7d7a',
  '#324745',
  '#8ba4a1',
  '#d4e074',
  '#223230',
  '#c6ae29',
  '#bf7400',
  '#f66f0d',
  '#13a600',
  '#be0404',
];

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'ADD_NEW_INDIVIDUAL':
      return {
        ...state,
        participants: {
          ...state.participants,
          individuals: [...state.participants.individuals, action.payload],
        },
        deletedIndividuals: state.deletedIndividuals.filter(x => x.id !== action.payload.id),
      };
    case 'ADD_NEW_GROUP':
      return {
        ...state,
        participants: {
          ...state.participants,
          groups: [...state.participants.groups, action.payload],
        },
        deletedGroups: state.deletedGroups.filter(x => x.id !== action.payload.id),
      };
    case 'SET_INDIVIDUALS':
      return {
        ...state,
        participants: {
          ...state.participants,
          individuals: action.payload,
        },
      };
    case 'SET_GROUPS':
      return {
        ...state,
        participants: {
          ...state.participants,
          groups: action.payload,
        },
      };
    case 'REMOVE_INDIVIDUAL':
      return {
        ...state,
        participants: {
          ...state.participants,
          individuals: state.participants.individuals.filter(x => x.id !== action.payload.id),
        },
        deletedIndividuals: [...state.deletedIndividuals, { id: action.payload.id }],
      };
    case 'REMOVE_GROUP':
      return {
        ...state,
        participants: {
          ...state.participants,
          groups: state.participants.groups.filter(x => x.id !== action.payload.id),
        },
        deletedGroups: [...state.deletedGroups, { id: action.payload.id }],
      };
    case 'UPDATE_INDIVIDUAL_TIME_LIMIT':
      return {
        ...state,
        participants: {
          ...state.participants,
          individuals: state.participants.individuals.map(x =>
            x.id === action.payload.id ? { ...x, noLimit: action.payload.value } : x
          ),
        },
      };
    case 'UPDATE_GROUP_TIME_LIMIT':
      return {
        ...state,
        participants: {
          ...state.participants,
          groups: state.participants.groups.map(x =>
            x.id === action.payload.id ? { ...x, noLimit: action.payload.value } : x
          ),
        },
      };
    case 'UPDATE_GROUP_NUM_USERS':
      return {
        ...state,
        participants: {
          ...state.participants,
          groups: state.participants.groups.map(x =>
            x.id === action.payload.id ? { ...x, num_users: action.payload.value } : x
          ),
        },
      };
    case 'UPDATE_GROUP_USERS':
      return {
        ...state,
        participants: {
          ...state.participants,
          groups: state.participants.groups.map(x =>
            x.id === action.payload.id ? { ...x, users: action.payload.value } : x
          ),
        },
      };
    case 'ADD_NEW_THEME':
      return {
        ...state,
        themes: [...state.themes, action.payload],
      };
    case 'ADD_NEW_POINT':
      return {
        ...state,
        points: [...state.points, action.payload],
      };
    case 'UPDATE_THEME_NAME':
      return {
        ...state,
        themes: state.themes.map((x, idx) =>
          idx === action.payload.idx ? { ...x, name: action.payload.value } : x
        ),
      };
    case 'UPDATE_POINT_NAME':
      return {
        ...state,
        points: state.points.map((x, idx) =>
          idx === action.payload.idx ? { ...x, name: action.payload.value } : x
        ),
      };
    case 'ADD_THEME_POINT':
      return {
        ...state,
        themes: state.themes.map((x, idx) =>
          idx === action.payload.idx
            ? { ...x, points: [...x.points, { name: action.payload.value }] }
            : x
        ),
      };
    case 'UPDATE_THEME_POINT_NAME':
      return {
        ...state,
        themes: state.themes.map((x, idx) =>
          idx === action.payload.idxTheme
            ? {
                ...x,
                points: x.points.map((p, idxP) =>
                  idxP === action.payload.idxPoint ? { ...p, name: action.payload.value } : p
                ),
              }
            : x
        ),
      };
    case 'DELETE_THEME':
      return {
        ...state,
        themes: state.themes.filter((_, idx) => idx !== action.payload.idx),
        deletedThemes: action.payload.id
          ? [...state.deletedThemes, { id: action.payload.id }]
          : state.deletedThemes,
      };
    case 'DELETE_THEME_POINT':
      return {
        ...state,
        themes: state.themes.map((x, idx) =>
          idx === action.payload.idxTheme
            ? {
                ...x,
                points: x.points.filter((_, idxPoint) => idxPoint !== action.payload.idxPoint),
              }
            : x
        ),
        deletedThemePoints: action.payload.id
          ? [
              ...state.deletedThemePoints,
              { id: action.payload.id, idTheme: action.payload.idTheme },
            ]
          : state.deletedThemePoints,
      };
    case 'DELETE_POINT':
      return {
        ...state,
        points: state.points.filter((_, idx) => idx !== action.payload.idx),
        deletedPoints: action.payload.id
          ? [...state.deletedPoints, { id: action.payload.id }]
          : state.deletedPoints,
      };
    case 'LOCAL_REMOVED':
      return {
        ...state,
        deletedLocals: [...state.deletedLocals, { id: action.payload.id }],
      };
    case 'LOCAL_ADDED':
      return {
        ...state,
        deletedLocals: state.deletedLocals.filter(x => x.id !== action.payload.id),
      };
    case 'LOCALS_CLEARED':
      return {
        ...state,
        deletedLocals: state.locals?.length
          ? state.locals.map(local => ({
              id: local.id,
            }))
          : [],
      };
    case 'SET_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    case 'CLEAR_STATE':
      return {
        ...state,
        participants: {
          individuals: [],
          groups: [],
        },
        themes: [],
        points: [],
        locals: [],
        errors: {},
        color: colors[Math.floor(Math.random() * colors.length)],
        type: '',
        id: '',
        stratifiedThemes: false,
        stratifiedPoints: false,
        name: '',
        deletedIndividuals: [],
        deletedGroups: [],
        deletedThemes: [],
        deletedThemePoints: [],
        deletedPoints: [],
        deletedLocals: [],
      };
    default:
      return state;
  }
};

const CreateEditTypologyProvider = ({ children, items }) => {
  const { loading } = useSelector(state => state.typologies);
  const errorsReducer = useSelector(state => state.errors);
  const dispatchRedux = useDispatch();
  const { editing } = useContext(ConfigStateContext);
  const [state, dispatch] = useReducer(reducer, {
    items: [],
    participants: {
      individuals: [],
      groups: [],
    },
    themes: [],
    points: [],
    locals: [],
    errors: {},
    color: colors[Math.floor(Math.random() * colors.length)],
    type: '',
    id: '',
    stratifiedThemes: false,
    stratifiedPoints: false,
    name: '',
    // @ Control removed ids
    deletedIndividuals: [],
    deletedGroups: [],
    deletedThemes: [],
    deletedThemePoints: [],
    deletedPoints: [],
    deletedLocals: [],
  });
  const [sameName, setSameName] = useState('');

  const {
    id,
    errors,
    type,
    name,
    participants: { groups, individuals },
    themes,
    points,
    locals,
    color,
    stratifiedThemes,
    stratifiedPoints,
    deletedIndividuals,
    deletedGroups,
    deletedThemes,
    deletedThemePoints,
    deletedPoints,
    deletedLocals,
  } = state;

  // @ Efeitos
  useEffect(() => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: 'items',
        value: items,
      },
    });
  }, [items]);

  useEffect(() => {
    if (errorsReducer.name) {
      dispatch({
        type: 'SET_ERROR',
        payload: { name: 'Já existe uma matriz com este nome.' },
      });
      dispatchRedux(clearErrors());
      setSameName(name);
    }
  }, [errorsReducer, name, dispatchRedux]);

  useEffect(() => {
    if (!isEmpty(name) && !isEmpty(errors.name) && name !== sameName) {
      dispatch({
        type: 'SET_ERROR',
        payload: { name: '' },
      });
    }
  }, [name, errors.name, sameName]);

  useEffect(() => {
    if (!isEmpty(type) && !isEmpty(errors.type)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { type: '' },
      });
    }
  }, [type, errors.type]);

  useEffect(() => {
    if (type === 'individual' && !isEmpty(errors.participants) && !isEmpty(individuals)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { participants: '' },
      });
    }
  }, [type, errors.participants, individuals]);

  useEffect(() => {
    if (type === 'grupo' && !isEmpty(errors.participants) && !isEmpty(groups)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { participants: '' },
      });
    }
  }, [type, errors.participants, groups]);
  // @ Fim dos efeitos

  const getNumUsers = useCallback(
    idGroup => {
      const obj = groups.find(x => x.id === idGroup) || {};
      return obj.num_users || 0;
    },
    [groups]
  );

  const getUsers = useCallback(
    idGroup => {
      const obj = groups.find(x => x.id === idGroup) || {};
      return obj.users || [];
    },
    [groups]
  );

  const handleSubmit = e => {
    e.preventDefault();

    const localErrors = {};

    if (isEmpty(name)) {
      localErrors.name = 'Insira um nome válido para a tipologia.';
    }

    if (isEmpty(type)) {
      localErrors.type = 'Escolha a natureza desta tipologia.';
    }

    if ((type === 'grupo' && isEmpty(groups)) || (type === 'individual' && isEmpty(individuals))) {
      localErrors.participants = 'Seleccione um utilizador.';
    }

    if (checkEmptyObject(localErrors).length > 0) {
      return dispatch({
        type: 'SET_ERROR',
        payload: localErrors,
      });
    }

    const isGroup = type === 'grupo';

    const infoTypology = {
      name,
      color,
      isGroup,
      groups: isGroup ? groups : individuals,
      themes: stratifiedThemes ? themes : [],
      points: stratifiedPoints ? points : [],
      locals,
      stratifiedThemes,
      stratifiedPoints,
    };

    if (editing) {
      return dispatchRedux(
        updateTypology(id, {
          ...infoTypology,
          deletedIndividuals,
          deletedGroups,
          deletedThemes,
          deletedThemePoints,
          deletedPoints,
          deletedLocals,
        })
      );
    }

    return dispatchRedux(createTypology(infoTypology));
  };

  const funcsValue = useMemo(() => ({ getNumUsers, getUsers }), [getNumUsers, getUsers]);

  return (
    <TypologyDispatchContext.Provider value={dispatch}>
      <TypologyFuncsContext.Provider value={funcsValue}>
        <TypologyStateContext.Provider
          value={{ state, handleSubmit, loading, getNumUsers, getUsers, editing }}
        >
          {children}
        </TypologyStateContext.Provider>
      </TypologyFuncsContext.Provider>
    </TypologyDispatchContext.Provider>
  );
};

CreateEditTypologyProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  items: PropTypes.array.isRequired,
};

export default CreateEditTypologyProvider;
