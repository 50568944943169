import axios from 'axios';
import {
  GET_GROUPS,
  CREATE_GROUP,
  UPDATE_GROUP,
  GET_ERRORS,
  SHOW_SNACK,
  GROUPS_LOADING,
  GROUPS_NOT_LOADING,
  GET_GROUP_PARTICIPANTS,
  REMOVE_GROUP,
} from './types';

export const getGroups = () => dispatch => {
  dispatch(setGroupsLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/groups`)
    .then(res => {
      dispatch({
        type: GET_GROUPS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(setGroupsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getGroupsWithUsers = () => dispatch => {
  dispatch(setGroupsLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/groups/users`)
    .then(res => {
      dispatch({
        type: GET_GROUPS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(setGroupsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getGroupParticipants = id => dispatch => {
  dispatch(setGroupsLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/groups/${id}/participants`)
    .then(res => {
      dispatch({
        type: GET_GROUP_PARTICIPANTS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(setGroupsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const createGroup = info => dispatch => {
  dispatch(setGroupsLoading());

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/groups`, info)
    .then(res => {
      dispatch({
        type: CREATE_GROUP,
        payload: { ...res.data, can_delete: true },
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Grupo criado com sucesso.',
        },
      });
    })
    .catch(err => {
      dispatch(setGroupsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateGroup = (id, info) => dispatch => {
  dispatch(setGroupsLoading());

  axios
    .put(`${process.env.REACT_APP_API_URL}/api/groups/${id}`, info)
    .then(res => {
      dispatch({
        type: UPDATE_GROUP,
        payload: res.data,
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Grupo atualizado com sucesso.',
        },
      });
    })
    .catch(err => {
      dispatch(setGroupsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const removeGroup = (id, callback) => dispatch => {
  dispatch(setGroupsLoading());

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/groups/${id}/disable`)
    .then(() => {
      dispatch({
        type: REMOVE_GROUP,
        payload: {
          id,
        },
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Grupo excluído com sucesso.',
        },
      });
      callback();
    })
    .catch(err => {
      dispatch(setGroupsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const setGroupsLoading = () => {
  return {
    type: GROUPS_LOADING,
  };
};

export const setGroupsNotLoading = () => {
  return {
    type: GROUPS_NOT_LOADING,
  };
};
