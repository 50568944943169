import React from 'react';
import PropTypes from 'prop-types';
import SelectOutlined from '../../../common/forms/SelectOutlined';

const commonProps = {
  fullWidth: true,
  isClearable: true,
  getOptionValue: option => option.id,
};

const OrganicUnit = ({ state, updateField }) => {
  const { organicUnit, organicUnits, editing, errors } = state;

  return (
    <SelectOutlined
      {...commonProps}
      value={organicUnit}
      options={organicUnits}
      label="Unidade Orgânica"
      placeholder="Selecionar Unidade Orgânica"
      onChange={val => updateField('organicUnit', val)}
      name="organicUnit"
      noOptionsMessage={() => 'Sem Unidades Orgânicas'}
      getOptionLabel={option => `${option.abbreviation || option.code} - ${option.name}`}
      isClearable={false}
      isDisabled={editing}
      error={errors.organicUnit}
    />
  );
};

OrganicUnit.propTypes = {
  state: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
};

export default React.memo(OrganicUnit);
