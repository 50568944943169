import React, { useEffect, useContext, useCallback } from 'react';
import SeeItem from '../../pieces/SeeItem';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from '../../../../store/actions/usersActions';
import PropTypes from 'prop-types';
import LabelAndValue from '../../../common/forms/LabelAndValue';
import { UserDispatchContext, UserStateContext } from './CreateEditUserProvider';
import EditUser from './EditUser';
import { ConfigStateContext, ConfigDispatchContext } from '../../MenuConfigProvider';
import H6 from '../../../common/typographys/H6';
import { makeStyles } from '@material-ui/core';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import PermissionEntry from '../../pieces/PermissionEntry';
import isEmpty from '../../../../utils/isEmpty';
import ListOrganicUnits from './ListOrganicUnits';

const useStyles = makeStyles(() => ({
  subHeader: {
    marginBottom: '16px',
  },
}));

const SeeEditUser = ({ info }) => {
  const classes = useStyles();
  const dispatchRedux = useDispatch();
  const dispatchConfig = useContext(ConfigDispatchContext);
  const dispatch = useContext(UserDispatchContext);
  const {
    state: { organicUnit },
  } = useContext(UserStateContext);
  const { editing } = useContext(ConfigStateContext);
  const { user } = useSelector(state => state.users);
  const { organicUnits } = useSelector(state => state.organicUnits);
  const { permissions = {}, custom, organic_units } = user;

  const updateField = useCallback(
    (name, value) => {
      dispatch({
        type: 'UPDATE_FIELD',
        payload: {
          name,
          value,
        },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (info.isItemUser) {
      dispatchRedux(getUser(info.id));
    }
  }, [dispatchRedux, info.isItemUser, info.id]);

  useEffect(() => {
    if (!isEmpty(organicUnits) && info.organic_unit_id) {
      const organicUnitObj = organicUnits.find(x => x.id === info.organic_unit_id);
      updateField('organicUnit', organicUnitObj);
      updateField('oldOrganicUnit', organicUnitObj);
    }
  }, [organicUnits, info.organic_unit_id, updateField]);

  useEffect(() => {
    updateField('name', info.name);
  }, [info.name, updateField]);

  useEffect(() => {
    updateField('short_name', info.short_name);
  }, [info.short_name, updateField]);

  useEffect(() => {
    updateField('councilor', info.councilor);
  }, [info.councilor, updateField]);

  useEffect(() => {
    updateField('nif', info.nif);
  }, [info.nif, updateField]);

  useEffect(() => {
    updateField('phone', info.phone);
  }, [info.phone, updateField]);

  useEffect(() => {
    updateField('email', info.email);
  }, [info.email, updateField]);

  useEffect(() => {
    updateField('id', info.id);
  }, [info.id, updateField]);

  useEffect(() => {
    updateField('custom', !!custom);
    if (custom) {
      updateField('profile', { label: 'Permissões personalizadas', value: 'custom' });
    }
  }, [custom, updateField]);

  useEffect(() => {
    updateField('permissions', permissions);
  }, [permissions, updateField]);

  useEffect(() => {
    updateField('organicUnits', organic_units);
  }, [organic_units, updateField]);

  useEffect(() => {
    updateField('profile', info.profile ? info.profile : null);
  }, [info.profile, updateField]);

  const startEdit = useCallback(
    e => {
      e.preventDefault();
      dispatchConfig({
        type: 'SET_EDITING',
      });
    },
    [dispatchConfig]
  );

  const keys = Object.keys(permissions);

  return editing ? (
    <EditUser />
  ) : (
    <SeeItem submitEdit={startEdit}>
      <LabelAndValue label="Nome Completo" value={info.name} />
      <LabelAndValue label="Primeiro e Último Nome" value={info.short_name} />
      <LabelAndValue label="Email" value={info.email} />
      <LabelAndValue label="Vereador" value={info.councilor ? 'Sim' : 'Não'} />
      <LabelAndValue label="Número de Identificação Fiscal" value={info.nif} />
      <LabelAndValue label="Contacto telefónico" value={info.phone} />
      {organicUnit && (
        <LabelAndValue
          label="Unidade Orgânica"
          value={`${organicUnit.abbreviation || organicUnit.code} - ${organicUnit.name}`}
        />
      )}
      {!isEmpty(organic_units) && <ListOrganicUnits organicUnits={organic_units} />}
      {!custom && <LabelAndValue label="Perfil" value={info.profile && info.profile.name} />}
      <H6 className={classes.subHeader}>Permissões {custom ? 'personalizadas' : 'do perfil'}</H6>
      {keys.map(label =>
        checkEmptyObject(permissions[label]).length > 0 ? (
          <PermissionEntry key={label} label={label} permissions={permissions[label]} />
        ) : null
      )}
    </SeeItem>
  );
};

SeeEditUser.propTypes = {
  info: PropTypes.object.isRequired,
};

export default React.memo(SeeEditUser);
