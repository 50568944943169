import axios from 'axios';
import {
  SET_REUNION_CRONOLOGY,
  SET_ACTUAL_REUNION,
  UPDATE_REUNION,
  UPDATE_TIME,
  TOGGLE_SPEAKING,
  UPDATE_PROPOSAL_VOTES,
  UPDATE_GROUP_MEMBER_TIME,
  UPDATE_MEMBER_TIME,
  UPDATE_THEME_POINT_STATUS,
  UPDATE_POINT_STATUS,
  UPDATE_GROUP_OTHERS_TIME,
  UPDATE_OTHERS_TIME,
  UPDATE_GROUP_USERS_POINT_SPEAK,
  UPDATE_PUBLIC_TIME,
  UPDATE_PARTICIPANT_POINT_DELIBERATION,
  UPDATE_PARTICIPANT_THEME_POINT_DELIBERATION,
  UPDATE_INTERVENTIONS_TIME,
  UPDATE_PUBLIC_DISCUSSION_TIME,
  UPDATE_BEFORE_DAY_ORDER_TIME,
  UPDATE_POINT_DISCUSSION_TIME,
  UPDATE_MEETING_TIME,
  GET_ERRORS,
} from './types';
import isEmpty from '../../utils/isEmpty';

export const getReunionQualityVoters = id => dispatch => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/meetings/${id}/qualityVote`)
    .then(res => {
      dispatch(updateReunion({ qualityVoters: res.data }));
    })
    .catch(() => {
      dispatch({
        type: 'SHOW_SNACK',
        payload: {
          variant: 'error',
          message: 'Erro ao obter votos qualidade.',
        },
      });
    });
};

export const getPointSpeakTime = (id, idPoint) => dispatch => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/meetings/${id}/points/${idPoint}`)
    .then(res => {
      dispatch({
        type: UPDATE_GROUP_USERS_POINT_SPEAK,
        payload: res.data,
      });
    })
    .catch(() => null);
};

export const getPeriodSpeakTime = (id, type, isPublic) => dispatch => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/meetings/${id}/type/${type}/speak`)
    .then(res => {
      dispatch({
        type: isPublic ? UPDATE_PUBLIC_TIME : UPDATE_GROUP_USERS_POINT_SPEAK,
        payload: isPublic ? (res.data[0] && res.data[0].time) || 0 : res.data,
      });
    })
    .catch(() => null);
};

export const getInterventionsSpeakTime = id => dispatch => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/meetings/${id}/type/interventions/speak`)
    .then(res => {
      dispatch({
        type: UPDATE_INTERVENTIONS_TIME,
        payload: (res.data[0] && res.data[0].time) || 0,
      });
    })
    .catch(() => null);
};

export const createUserDeclaration = (meetingId, idPoint, userId, info, resolve) => dispatch => {
  axios
    .post(
      `${process.env.REACT_APP_API_URL}/api/meetings/${meetingId}/points/${idPoint}/users/${userId}/declaration/new`,
      info
    )
    .then(() => resolve())
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const newReunionWithCronology = info => dispatch => {
  if (!isEmpty(info)) {
    const { id, name, color } = info;

    return dispatch({
      type: SET_REUNION_CRONOLOGY,
      payload: {
        value: id,
        label: name,
        cronology: true,
        color,
      },
    });
  }

  return dispatch({
    type: SET_REUNION_CRONOLOGY,
    payload: null,
  });
};

export const setActualReunion = info => dispatch => {
  dispatch({
    type: SET_ACTUAL_REUNION,
    payload: info,
  });
};

export const updateReunion = info => dispatch => {
  dispatch({
    type: UPDATE_REUNION,
    payload: info,
  });
};

export const updateReunionPointInfo = info => dispatch => {
  const { meeting_point_status, infoPoint } = info;

  dispatch({
    type: infoPoint.idxTheme !== null ? UPDATE_THEME_POINT_STATUS : UPDATE_POINT_STATUS,
    payload: {
      infoPoint,
      meeting_point_status,
    },
  });
};

export const updateReunionPointDeclaration = info => dispatch => {
  const { activeInfo, id, type, file, text, groupId } = info;

  dispatch({
    type:
      activeInfo.idxTheme === null
        ? UPDATE_PARTICIPANT_POINT_DELIBERATION
        : UPDATE_PARTICIPANT_THEME_POINT_DELIBERATION,
    payload: {
      activeInfo,
      info: { id, type, file, text, groupId },
    },
  });
};

export const updateTime = (value, idx) => dispatch => {
  dispatch({
    type: UPDATE_TIME,
    payload: {
      idx,
      value,
    },
  });
};

export const updatePublicDiscussionTimer = timerState => dispatch => {
  dispatch({
    type: UPDATE_PUBLIC_DISCUSSION_TIME,
    payload: timerState,
  });
};

export const updateBeforeDayOrderTimer = timerState => dispatch => {
  dispatch({
    type: UPDATE_BEFORE_DAY_ORDER_TIME,
    payload: timerState,
  });
};

export const updateMeetingTimer = timerState => dispatch => {
  dispatch({
    type: UPDATE_MEETING_TIME,
    payload: timerState,
  });
};

export const updatePointDiscussionTimer = (pointId, timerType, timerState) => dispatch => {
  dispatch({
    type: UPDATE_POINT_DISCUSSION_TIME,
    payload: {
      pointId,
      timerType,
      timerState,
    },
  });
};

export const updateSpeakerTime = (value, userId, groupId, isGroup) => dispatch => {
  dispatch({
    type: isGroup ? UPDATE_GROUP_MEMBER_TIME : UPDATE_MEMBER_TIME,
    payload: {
      value,
      userId,
      groupId,
    },
  });
};

export const updateOthersTime = (value, userId, groupId, isGroup) => dispatch => {
  dispatch({
    type: isGroup ? UPDATE_GROUP_OTHERS_TIME : UPDATE_OTHERS_TIME,
    payload: {
      value,
      userId,
      groupId,
    },
  });
};

export const updateInterventionsTime = value => dispatch => {
  dispatch({
    type: UPDATE_INTERVENTIONS_TIME,
    payload: value,
  });
};

export const updatePublicTime = value => dispatch => {
  dispatch({
    type: UPDATE_PUBLIC_TIME,
    payload: value,
  });
};

export const toggleSpeaking = (idx, value) => dispatch => {
  dispatch({
    type: TOGGLE_SPEAKING,
    payload: {
      idx,
      value,
    },
  });
};

export const updateProposalVotes = info => dispatch => {
  dispatch({
    type: UPDATE_PROPOSAL_VOTES,
    payload: info,
  });
};
