import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const CurrencyFormat = ({ inputRef, onChange, name, ...props }) => {
  return (
    <NumberFormat
      getInputRef={inputRef}
      thousandSeparator=" "
      onValueChange={values => onChange(name, values.formattedValue.replace(/\s/g, ''))}
      decimalScale={2}
      {...props}
    />
  );
};

CurrencyFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default CurrencyFormat;
