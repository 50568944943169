/* eslint-disable no-else-return */
import React, { useReducer, useMemo, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from '../../utils/isEmpty';
import checkEmptyObject from '../../utils/checkEmptyObject';
import { getTypology } from '../../store/actions/typologiesActions';
import { isValid } from 'date-fns';
import formatDate from '../../utils/formatDate';
import {
  createMeeting,
  uploadMeetingFiles,
  updateMeeting,
  getAvailableSecretaries,
  getAvailableModerators,
  deleteMeetingFile,
  uploadBriefFile,
  uploadPublicNoticeFile,
} from '../../store/actions/meetingsActions';
import getDateObj from '../../utils/getDateObj';
import { getThemes } from '../../store/actions/themesActions';
import useBooleanToggle from '../../utils/hooks/useBooleanToggle';

export const MeetingDispatchContext = React.createContext();
export const MeetingFuncsContext = React.createContext();
export const MeetingStateContext = React.createContext();

const filterUserChanges = arr =>
  arr.reduce((acc, cur) => {
    if (cur.in === null || cur.out === null) {
      const id = cur.in || cur.out;
      const lengthIn = arr.map(x => x.in === id && x.out === null).filter(Boolean).length;
      const lengthOut = arr.map(x => x.in === null && x.out === id).filter(Boolean).length;
      const idxUserIn = arr.findIndex(x => x.in === id && x.out === null);
      const idxUserOut = arr.findIndex(x => x.in === null && x.out === id);
      if (idxUserIn !== -1 && idxUserOut !== -1) {
        if (lengthIn !== lengthOut) {
          if (lengthIn > lengthOut) {
            const obj = arr[idxUserIn];
            if (acc.indexOf(obj) === -1) {
              acc.push(obj);
            }
          } else {
            const obj = arr[idxUserOut];
            if (acc.indexOf(obj) === -1) {
              acc.push(obj);
            }
          }
        }
        return acc;
      }

      acc.push(cur);
      return acc;
    }

    acc.push(cur);
    return acc;
  }, []);

const filterGroupChanges = arr =>
  arr.reduce((acc, cur) => {
    const curFiltered = { ...cur, users: filterUserChanges(cur.users) };

    if (curFiltered.users.length > 0) {
      acc.push(curFiltered);
    }

    return acc;
  }, []);

const checkRepeatGroupUsers = (groups = []) => {
  const usersIds = [];
  const sameUsers = [];

  groups.map(
    group =>
      group &&
      group.users.forEach(user => {
        if (user && user.in_meeting) {
          const obj = { userId: user.id, groupId: group.id };
          if (usersIds.findIndex(x => x.userId === obj.userId) !== -1) {
            // @ Utilizador repetido
            if (sameUsers.findIndex(x => x.id === user.id) === -1) {
              // @ Ainda não estava inserido no array repetidos
              sameUsers.push(user);
            }
          }
          usersIds.push(obj);
        }
      })
  );

  return { usersIds, sameUsers };
};

const sortIds = (a, b) => a.id - b.id;

const getAllPointsIds = (themes = [], points = []) => {
  const allPoints = new Set();

  themes.forEach(theme => {
    if (!theme) return;

    theme.points.forEach(point => {
      if (point && point.id) allPoints.add(point.id);
    });
  });

  points.forEach(point => {
    if (point && point.id) allPoints.add(point.id);
  });

  return allPoints;
};

const isPointChecked = (allPointsIds, pointId) => {
  return allPointsIds.has(pointId);
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'UPDATE_STATE':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    case 'SELECT_ALL_THEME_POINTS':
      return {
        ...state,
        themes: state.themes.map((x, idx) => {
          if (idx === action.payload.idx) {
            return {
              ...x,
              pointsSelected: x.points.length,
              total: x.points.length,
              points: x.points.map(p => ({ ...p, selected: true })),
              selected: true,
            };
          }
          return x;
        }),
      };
    case 'UNSELECT_THEME':
      return {
        ...state,
        themes: state.themes.map((x, idx) => {
          if (idx === action.payload.idx) {
            return {
              ...x,
              pointsSelected: 0,
              total: x.points ? x.points.length : 0,
              points: x.points ? x.points.map(p => ({ ...p, selected: false })) : [],
              selected: false,
            };
          }
          return x;
        }),
      };
    case 'SELECT_THEME':
      return {
        ...state,
        themes: state.themes.map((x, idx) => {
          if (idx === action.payload.idx) {
            return {
              ...x,
              pointsSelected: 0,
              total: 0,
              points: [],
              selected: true,
            };
          }
          return x;
        }),
      };
    case 'UNSELECT_THEME_POINT':
      return {
        ...state,
        themes: state.themes.map((x, idx) => {
          if (idx === action.payload.idxTheme) {
            return {
              ...x,
              pointsSelected: x.pointsSelected - 1,
              total: x.points.length,
              points: x.points.map((p, idxP) => {
                if (idxP === action.payload.idxPoint) {
                  return {
                    ...p,
                    selected: false,
                  };
                }

                return p;
              }),
              selected: x.pointsSelected - 1 > 0,
            };
          }
          return x;
        }),
      };
    case 'SELECT_THEME_POINT':
      return {
        ...state,
        themes: state.themes.map((x, idx) => {
          if (idx === action.payload.idxTheme) {
            return {
              ...x,
              pointsSelected: x.pointsSelected + 1,
              total: x.points.length,
              points: x.points.map((p, idxP) => {
                if (idxP === action.payload.idxPoint) {
                  return {
                    ...p,
                    selected: true,
                  };
                }

                return p;
              }),
              selected: true,
            };
          }
          return x;
        }),
      };
    case 'TOGGLE_POINT':
      return {
        ...state,
        points: state.points.map(point => {
          if (point.id === action.payload.id) {
            return { ...point, selected: action.payload.value };
          }
          return point;
        }),
      };
    case 'CREATE_DEFAULT_MEETING_POINT':
      return {
        ...state,
        points: [...state.points, action.payload],
      };
    case 'CHANGE_GROUP_USER': {
      const objChangesGroup = state.changes.find(x => x.id === action.payload.idGroup);
      let changes = [];
      if (objChangesGroup !== undefined) {
        changes = state.changes.map(x =>
          x.id === action.payload.idGroup
            ? {
                ...x,
                users: [
                  ...x.users,
                  {
                    out: action.payload.oldValue.id,
                    in: action.payload.newValue.id,
                  },
                ],
              }
            : x
        );
      } else {
        changes = [
          ...state.changes,
          {
            id: action.payload.idGroup,
            users: [
              {
                out: action.payload.oldValue.id,
                in: action.payload.newValue.id,
              },
            ],
          },
        ];
      }
      return {
        ...state,
        users: state.users.map((x, idx) =>
          idx === action.payload.idxGroup
            ? {
                ...x,
                users: x.users.map(user => {
                  if (user.id === action.payload.newValue.id) {
                    return { ...action.payload.newValue, in_meeting: true };
                  }

                  if (user.id === action.payload.oldValue.id) {
                    return { ...action.payload.oldValue, in_meeting: false };
                  }

                  return user;
                }),
              }
            : x
        ),
        changes,
      };
    }
    case 'CHANGE_USER': {
      const newUserIndex = state.users.findIndex(user => user.id === action.payload.newValue.id);
      const oldUserIndex = state.users.findIndex(user => user.id === action.payload.oldValue.id);

      const oldUser = { ...state.users[oldUserIndex], in_meeting: false };
      const newUser = { ...state.users[newUserIndex], in_meeting: true };

      const users = [...state.users];
      users[oldUserIndex] = newUser;
      users[newUserIndex] = oldUser;

      return {
        ...state,
        users,
        changes: [
          ...state.changes,
          { out: action.payload.oldValue.id, in: action.payload.newValue.id },
        ],
      };
    }
    case 'REMOVE_GROUP_USER': {
      const objChangesGroup = state.changes.find(x => x.id === action.payload.idGroup);
      let changes = [];
      if (objChangesGroup !== undefined) {
        changes = state.changes.map(x =>
          x.id === action.payload.idGroup
            ? {
                ...x,
                users: [...x.users, { out: action.payload.user.id, in: null }],
              }
            : x
        );
      } else {
        changes = [
          ...state.changes,
          {
            id: action.payload.idGroup,
            users: [{ out: action.payload.user.id, in: null }],
          },
        ];
      }

      return {
        ...state,
        users: state.users.map((x, idx) =>
          idx === action.payload.idxGroup
            ? {
                ...x,
                users: x.users.map(user =>
                  user.id === action.payload.user.id
                    ? { ...action.payload.user, in_meeting: false }
                    : user
                ),
              }
            : x
        ),
        changes,
      };
    }
    case 'ADD_GROUP_USER': {
      const objChangesGroup = state.changes.find(x => x.id === action.payload.idGroup);
      let changes = [];
      if (objChangesGroup !== undefined) {
        changes = state.changes.map(x =>
          x.id === action.payload.idGroup
            ? {
                ...x,
                users: [...x.users, { in: action.payload.user.id, out: null }],
              }
            : x
        );
      } else {
        changes = [
          ...state.changes,
          {
            id: action.payload.idGroup,
            users: [{ in: action.payload.user.id, out: null }],
          },
        ];
      }

      return {
        ...state,
        users: state.users.map((x, idx) =>
          idx === action.payload.idxGroup
            ? {
                ...x,
                users: x.users.map(user =>
                  user.id === action.payload.user.id
                    ? { ...action.payload.user, in_meeting: true }
                    : user
                ),
              }
            : x
        ),
        changes,
      };
    }
    case 'ADD_NEW_USER': {
      let newUsers;

      const userInMeetingIndex = state.users.findIndex(user => user.id === action.payload.user.id);

      if (userInMeetingIndex >= 0) {
        const userToAdd = { ...state.users[userInMeetingIndex], in_meeting: true };
        newUsers = state.users.filter(user => user.id !== action.payload.user.id);
        newUsers.push(userToAdd);
      } else {
        const userToAdd = { ...action.payload.user, in_meeting: true };
        newUsers = [...state.users, userToAdd];
      }

      return {
        ...state,
        users: newUsers,
        changes: [...state.changes, { in: action.payload.user.id, out: null }],
      };
    }
    case 'REMOVE_USER': {
      return {
        ...state,
        users: state.users.map(user => {
          if (user.id !== action.payload.idUser) return user;

          return { ...user, in_meeting: false };
        }),
        changes: [...state.changes, { out: action.payload.user.id, in: null }],
      };
    }
    case 'SELECT_ALL_POOL_POINTS':
      return {
        ...state,
        proposals: [...state.proposals, ...state.pool.points.map(x => ({ ...x, selected: true }))],
        pool: {
          points: [],
          selected: 0,
          pointsSelected: 0,
          total: 0,
        },
      };
    case 'SELECT_POOL_POINT': {
      const selectedPointIndex = state.pool.points.findIndex(({ id }) => id === action.payload.id);
      const selectedPoint = state.pool.points.splice(selectedPointIndex, 1)[0];

      selectedPoint.selected = true;

      return {
        ...state,
        pool: {
          points: [...state.pool.points],
        },
        proposals: [...state.proposals, selectedPoint],
      };
    }
    case 'POINT_TO_POOL': {
      const proposalPointIndex = state.proposals.findIndex(({ id }) => id === action.payload.id);
      const proposalPoint = state.proposals.splice(proposalPointIndex, 1)[0];
      proposalPoint.selected = false;

      return {
        ...state,
        proposals: [...state.proposals],
        pool: {
          ...state.pool,
          selected: false,
          points: [...state.pool.points, proposalPoint],
          total: state.pool.total + 1,
        },
      };
    }
    case 'TOGGLE_PROPOSAL_POINT':
      return {
        ...state,
        proposals: state.proposals.map(proposal =>
          proposal.id === action.payload.id
            ? { ...proposal, selected: action.payload.value }
            : proposal
        ),
      };
    case 'ADD_FILES':
      return {
        ...state,
        files: [...state.files, ...Array.from(action.payload.value)],
      };
    case 'DELETE_FILE':
      return {
        ...state,
        files: [...state.files.filter(x => x.name !== action.payload.name)],
        deletedFiles: [...state.deletedFiles, action.payload],
      };
    case 'SET_SUBMITTED':
      return {
        ...state,
        submitted: true,
      };
    case 'REMOVE_POOL_POINT':
      return {
        ...state,
        pool: {
          ...state.pool,
          points: state.pool.points.filter(x => x.id !== action.payload.id),
        },
        points: [...state.points, action.payload],
      };
    case 'REMOVE_POOL_THEME_POINT': {
      const themeId = action.payload.theme.id;
      const pointId = action.payload.point.id;
      const objTheme = state.themes.find(x => x.id === themeId);

      // @ Se objTheme === undefined, este tema não existia
      return {
        ...state,
        themes:
          objTheme !== undefined
            ? state.themes.map(x =>
                x.id === themeId ? { ...x, points: [...x.points, action.payload.point] } : x
              )
            : [...state.themes, { ...action.payload.theme, points: [action.payload.point] }],
        pool: {
          ...state.pool,
          themes: action.payload.lastOne
            ? state.pool.themes.filter(x => x.id !== themeId)
            : state.pool.themes.map(x =>
                x.id === themeId ? { ...x, points: x.points.filter(p => p.id !== pointId) } : x
              ),
        },
      };
    }
    case 'REMOVE_POINT':
      return {
        ...state,
        points: state.points.filter(x => x.id !== action.payload.id),
        pool: {
          ...state.pool,
          points:
            action.payload.isNew || action.payload.default
              ? state.pool.points
              : [...state.pool.points, action.payload],
        },
      };
    case 'REMOVE_THEME_POINT': {
      const themeId = action.payload.theme.id;
      const pointId = action.payload.point.id;
      return {
        ...state,
        themes: state.themes
          .map(x =>
            x.id === themeId ? { ...x, points: x.points.filter(p => p.id !== pointId) } : x
          )
          .filter(theme => theme.points.length > 0),
        points: [...state.points, action.payload.point],
      };
    }
    case 'UPDATE_THEME_POINT_MATRIX':
      return {
        ...state,
        themes: state.themes.map(x =>
          x.id === action.payload.themeId
            ? {
                ...x,
                points: x.points.map(p =>
                  p.id === action.payload.pointId ? { ...p, matrix: action.payload.matrix } : p
                ),
              }
            : x
        ),
      };
    case 'UPDATE_POINT_MATRIX':
      return {
        ...state,
        points: state.points.map(x =>
          x.id === action.payload.pointId ? { ...x, matrix: action.payload.matrix } : x
        ),
      };
    case 'ADD_POINT_TO_THEME': {
      const themeId = action.payload.theme.id;
      const themeObj = state.themes.find(x => x.id === themeId);

      return {
        ...state,
        themes: themeObj
          ? state.themes.map(x =>
              x.id === action.payload.theme.id
                ? { ...x, points: [...x.points, action.payload.point] }
                : x
            )
          : [...state.themes, { ...action.payload.theme, points: [action.payload.point] }],
        points: state.points.filter(x => x.id !== action.payload.point.id),
      };
    }
    case 'UPDATE_THEME_POINTS':
      return {
        ...state,
        themes: state.themes.map(theme =>
          theme.id === action.payload.id ? { ...theme, points: action.payload.points } : theme
        ),
      };
    case 'UPDATE_POINTS':
      return {
        ...state,
        points: action.payload,
      };
    case 'MOVE_THEME': {
      const theme1 = state.themes.find((x, idx) => idx === action.payload.oldIndex);
      const theme2 = state.themes.find((x, idx) => idx === action.payload.newIndex);

      return {
        ...state,
        themes:
          !isEmpty(theme1) && !isEmpty(theme2)
            ? state.themes.map((theme, idx) => {
                if (idx === action.payload.oldIndex) {
                  return theme2;
                }

                if (idx === action.payload.newIndex) {
                  return theme1;
                }

                return theme;
              })
            : state.themes,
      };
    }
    case 'CREATE_NEW_THEME_WITH_POINT':
      return {
        ...state,
        themes: [...state.themes, action.payload.theme],
        points: state.points.filter(x => x.id !== action.payload.pointId),
      };
    case 'ADD_SECRETARY':
      return {
        ...state,
        board: {
          ...state.board,
          secretary: [...state.board.secretary, action.payload.value],
        },
      };
    case 'CHANGE_MAIN_SECRETARY':
      return {
        ...state,
        board: {
          ...state.board,
          secretary: state.board.secretary.map(secretary => {
            if (secretary.id === action.payload.id) {
              return { ...secretary, main: true };
            } else {
              return { ...secretary, main: false };
            }
          }),
        },
      };
    case 'ADD_MODERATOR':
      return {
        ...state,
        board: {
          ...state.board,
          moderator: [...state.board.moderator, action.payload.value],
        },
      };
    case 'REMOVE_SECRETARY':
      return {
        ...state,
        board: {
          ...state.board,
          secretary: state.board.secretary.filter(x => x.id !== action.payload.id),
        },
      };
    case 'REMOVE_MODERATOR':
      return {
        ...state,
        board: {
          ...state.board,
          moderator: state.board.moderator.filter(x => x.id !== action.payload.id),
        },
      };
    case 'CLEAR_STATE':
      return {
        ...state,
        name: '',
        typology: null,
        date: null,
        endDate: null,
        users: [],
        groups: [],
        themes: [],
        points: [],
        proposals: [],
        files: [],
        usersIds: [],
        sameUsers: [],
        changes: [],
        pool: { points: [] },
        local: null,
        deletedFiles: [],
        availableThemes: [],
        matrices: [],
        participants: [],
        board: {
          secretary: [],
          moderator: [],
        },
        isGroup: false,
        isPublic: false,
        addToTypology: false,
        submitting: false,
        submitted: false,
        editing: false,
        disableDownloadAgenda: false,
        disableDownloadPublicNotice: false,
        newBriefFile: null,
        newPublicNoticeFile: null,
        skeleton: null,
        videoConferenceLink: '',
        videoConference: false,
        errors: {},
      };
    default:
      return state;
  }
};

const CreateEditMeetingsProvider = ({ children, editing, setChange, navigate, newSession }) => {
  const {
    loading,
    meeting,
    pool: poolMeeting,
    matrices: matricesMeeting,
    secretaries,
    moderators,
  } = useSelector(stateRedux => stateRedux.meetings);
  const { typology: typologyRedux } = useSelector(stateRedux => stateRedux.typologies);
  const { id: userId, name: userName } = useSelector(stateRedux => stateRedux.auth.user);
  const { themes: reduxThemes } = useSelector(stateRedux => stateRedux.themes);
  const dispatchRedux = useDispatch();
  const [openWarning, toggleOpenWarning] = useBooleanToggle();
  const [nextLocation, setNextLocation] = useState('');
  const [state, dispatch] = useReducer(reducer, {
    name: '',
    typology: null,
    date: null,
    endDate: null,
    users: [],
    groups: [],
    themes: [],
    points: [],
    proposals: [],
    files: [],
    usersIds: [],
    sameUsers: [],
    changes: [],
    pool: { points: [] },
    local: null,
    deletedFiles: [],
    availableThemes: [],
    matrices: [],
    participants: [],
    board: {
      secretary: [],
      moderator: [],
    },
    isGroup: false,
    isPublic: false,
    addToTypology: false,
    submitting: false,
    submitted: false,
    editing: false,
    disableDownloadAgenda: false,
    disableDownloadPublicNotice: false,
    newBriefFile: null,
    newPublicNoticeFile: null,
    skeleton: null,
    videoConferenceLink: '',
    videoConference: false,
    errors: {},
  });

  const {
    typology,
    name,
    local,
    users,
    addToTypology,
    isGroup,
    isPublic,
    points,
    proposals,
    themes,
    date,
    endDate,
    files,
    errors,
    changes,
    board,
    deletedFiles,
    newBriefFile,
    newPublicNoticeFile,
    skeleton,
    videoConferenceLink,
    videoConference,
  } = state;
  const { id, name: nameMeeting, date: dateMeeting, end_date: endDateMeeting } = meeting;

  const briefChanged = useMemo(() => {
    if (
      name !== nameMeeting ||
      formatDate(date, 'dd/MM/yyyy HH:mm') !== formatDate(dateMeeting, 'dd/MM/yyyy HH:mm') ||
      formatDate(endDate, 'dd/MM/yyyy HH:mm') !== formatDate(endDateMeeting, 'dd/MM/yyyy HH:mm')
    ) {
      return true;
    }

    return false;
  }, [name, nameMeeting, date, dateMeeting, endDate, endDateMeeting]);

  const updateField = useCallback((nameInput, value) => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: nameInput,
        value,
      },
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(meeting)) {
      dispatch({
        type: 'UPDATE_STATE',
        payload: {
          ...meeting,
          points: newSession
            ? meeting.points.map(point => ({ ...point, selected: true }))
            : meeting.points,
          themes: newSession
            ? meeting.themes
                .map(theme => ({
                  ...theme,
                  pointsSelected: theme.points.length,
                  total: theme.points.length,
                  selected: true,
                  points: theme.points.map(point => ({
                    ...point,
                    selected: true,
                  })),
                }))
                .filter(theme => theme.points.length > 0) || []
            : meeting?.themes?.filter(theme => theme.points.length > 0) || [],
          date: newSession ? null : getDateObj(meeting.date),
          endDate: newSession ? null : getDateObj(meeting.end_date),
          isGroup: Boolean(meeting.is_group),
          isPublic: Boolean(meeting.is_public),
          files: meeting.attachments,
          briefAvailable: Boolean(meeting.brief_available),
          briefFileName: meeting.brief_file_name,
          briefFilePath: meeting.brief_file_path,
          publicNoticeFileName: meeting.public_notice_name,
          publicNoticeFilePath: meeting.public_notice_path,
          videoConferenceLink: meeting.video_conference_link,
          videoConference: !isEmpty(meeting.video_conference_link),
          local: !isEmpty(meeting.local)
            ? {
                label: meeting.local.name,
                name: meeting.local.name,
                value: meeting.local.id,
                id: meeting.local.id,
              }
            : null,
        },
      });
    }
  }, [meeting, newSession]);

  useEffect(() => {
    updateField('editing', editing);
  }, [editing, updateField]);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'CLEAR_STATE',
      });
      dispatchRedux({
        type: 'GET_TYPOLOGY',
        payload: {},
      });
      dispatchRedux({
        type: 'GET_MEETING',
        payload: {},
      });
    };
  }, [dispatchRedux]);

  useEffect(() => {
    dispatchRedux(getAvailableSecretaries());
    dispatchRedux(getAvailableModerators());
    dispatchRedux(getThemes());
  }, [dispatchRedux]);

  useEffect(() => {
    if (!editing) {
      if (!isEmpty(typology) && typology.id !== typologyRedux.id) {
        //  @ Atualizar o reducer com a tipologia selecionada
        dispatchRedux(getTypology(typology.id));
      } else if (
        !isEmpty(typology) &&
        !isEmpty(typologyRedux) &&
        typology.themes !== typologyRedux.themes &&
        typology.points !== typologyRedux.points
      ) {
        updateField('typology', typologyRedux);
        updateField('errors', {});
        updateField('sameUsers', []);
        updateField('changes', []);
      }
    }
  }, [typology, updateField, typologyRedux, dispatchRedux, editing]);

  useEffect(() => {
    if (!isEmpty(poolMeeting)) {
      const poolThemes = poolMeeting.themes
        ? poolMeeting.themes.reduce((acc, cur) => {
            acc.push({
              id: cur.id,
              name: cur.name,
              points: cur.points.map(
                ({ id: idP, name: nameP, default: defaultP, identifier_code, date: dateP }) => ({
                  id: idP,
                  name: nameP,
                  default: defaultP,
                  matrix: null,
                  identifier_code,
                  date: dateP,
                })
              ),
            });

            return acc;
          }, [])
        : [];

      const poolPoints = poolMeeting.points
        ? poolMeeting.points.reduce((acc, cur) => {
            acc.push({
              id: cur.id,
              name: cur.name,
              default: cur.default,
              matrix: null,
              identifier_code: cur.identifier_code,
              date: cur.date,
            });
            return acc;
          }, [])
        : [];

      const slimPool = {
        themes: poolThemes,
        points: poolPoints,
      };

      updateField('pool', slimPool);
    }
  }, [poolMeeting, updateField]);

  useEffect(() => {
    if (!isEmpty(matricesMeeting)) {
      updateField('matrices', matricesMeeting);
    }
  }, [updateField, matricesMeeting]);

  useEffect(() => {
    if (!isEmpty(secretaries)) {
      updateField('availableSecretary', secretaries);
    }
  }, [secretaries, updateField]);

  useEffect(() => {
    if (!isEmpty(moderators)) {
      updateField('availableModerator', moderators);
    }
  }, [moderators, updateField]);

  useEffect(() => {
    if (userId && userName && !editing) {
      dispatch({
        type: 'ADD_SECRETARY',
        payload: {
          value: {
            id: userId,
            name: userName,
            main: true,
          },
        },
      });
    }
  }, [userId, userName, editing]);

  useEffect(() => {
    if (editing) {
      const availableThemes = [...themes, ...reduxThemes].reduce((acc, cur) => {
        const themeObj = acc.find(x => x.id === cur.id);
        if (isEmpty(themeObj)) {
          acc.push({
            id: cur.id,
            name: cur.name,
          });
        }

        return acc;
      }, []);
      updateField('availableThemes', availableThemes);
    }
  }, [themes, reduxThemes, updateField, editing]);

  useEffect(() => {
    if (!editing) {
      if (typologyRedux.themes && typologyRedux.themes.length > 0) {
        // @ Tem temas predefinidos
        updateField(
          'themes',
          typologyRedux.themes
            ? typologyRedux.themes
                .map(x => ({
                  ...x,
                  pointsSelected: x.points ? x.points.length : 0,
                  total: x.points ? x.points.length : 0,
                  points: x.points ? x.points.map(p => ({ ...p, selected: true })) : [],
                  selected: true,
                }))
                .filter(theme => theme.points.length > 0)
            : []
        );
      }
    }
  }, [typologyRedux.themes, updateField, editing]);

  useEffect(() => {
    if (!editing) {
      if (typologyRedux.points && typologyRedux.points.length > 0) {
        // @ Tem pontos predefinidos
        updateField(
          'points',
          typologyRedux.points
            ? typologyRedux.points.map(x => ({
                ...x,
                selected: true,
              }))
            : []
        );
      }
    }
  }, [typologyRedux.points, updateField, editing]);

  useEffect(() => {
    if (!editing) {
      updateField(
        'users',
        typologyRedux.is_group
          ? typologyRedux.groups.map(group => ({
              ...group,
              users: group.users.map((user, idx) => ({
                ...user,
                in_meeting: idx < group.num_users,
              })),
            })) || []
          : (typologyRedux.users || []).map(user => ({ ...user, in_meeting: true }))
      );
      if (typologyRedux.is_group) {
        // @ Validar se nenhum user está inserido em grupos diferentes
        const repeatGroupUsers = checkRepeatGroupUsers(typologyRedux.groups);
        const { usersIds, sameUsers } = repeatGroupUsers;
        updateField('usersIds', usersIds || []);
        updateField('sameUsers', sameUsers || []);
      }
      updateField('isGroup', typologyRedux.is_group);
    }
  }, [typologyRedux.users, typologyRedux.groups, typologyRedux.is_group, updateField, editing]);

  useEffect(() => {
    if (!editing) {
      if (typologyRedux.pool) {
        const allPointsIds = getAllPointsIds(typologyRedux.pool.points);

        const newPoolPoints = typologyRedux.pool.reduce((acc, point) => {
          if (!isPointChecked(allPointsIds, point.id)) {
            acc.push({ ...point, selected: false, fromPool: true });
          }

          return acc;
        }, []);

        updateField('pool', {
          points: newPoolPoints,
          selected: false,
          pointsSelected: 0,
          total: newPoolPoints.length,
        });
      } else {
        updateField('pool', { points: [] });
      }
    }
  }, [typologyRedux.pool, updateField, editing]);

  useEffect(() => {
    if (users.length > 0 && isGroup) {
      const repeatGroupUsers = checkRepeatGroupUsers(users);
      const { usersIds, sameUsers } = repeatGroupUsers;
      updateField('usersIds', usersIds || []);
      updateField('sameUsers', sameUsers || []);
    }
  }, [users, updateField, isGroup]);

  useEffect(() => {
    const actualSkeleton = {};

    if (!isEmpty(themes) || !isEmpty(points)) {
      // @ Existem temas ou pontos e o esqueleto ainda não foi construído
      actualSkeleton.themes = themes
        .map(theme => ({
          id: theme.id,
          points: theme.points.map(point => ({ id: point.id })).sort(sortIds),
        }))
        .sort(sortIds)
        .filter(x => x.points.length !== 0);
      actualSkeleton.points = points.map(point => ({ id: point.id })).sort(sortIds);
    }

    const skeletonChanged =
      skeleton !== null
        ? JSON.stringify(skeleton) !== JSON.stringify(actualSkeleton)
        : !isEmpty(actualSkeleton);

    updateField('disableDownloadAgenda', skeletonChanged);
    updateField('disableDownloadPublicNotice', skeletonChanged);
  }, [points, themes, skeleton, updateField]);

  const confirmChange = useCallback(
    page => {
      navigate(page);
    },
    [navigate]
  );

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      const localErrors = {};

      dispatch({
        type: 'SET_SUBMITTED',
      });

      if (name.length < 3 && isEmpty(errors.name)) {
        localErrors.name = 'Preencha o nome da reunião.';
      }

      if (isEmpty(typology)) {
        localErrors.typology = 'Escolha a tipologia desta reunião.';
      }

      if (isEmpty(local) && isEmpty(videoConferenceLink)) {
        localErrors.local = 'Escolha o local desta reunião.';
      }

      if (!isValid(getDateObj(date))) {
        localErrors.date = 'Preencha a data e hora da reunião.';
      }

      if (!isValid(getDateObj(endDate))) {
        localErrors.endDate = 'Preencha a data e hora do fim da reunião.';
      }

      if (checkEmptyObject(localErrors).length > 0) {
        return dispatch({
          type: 'SET_ERROR',
          payload: localErrors,
        });
      }

      const actualSkeleton = {};

      if (!isEmpty(themes) || !isEmpty(points)) {
        // @ Existem temas ou pontos e o esqueleto ainda não foi construído
        actualSkeleton.themes = themes
          .map(theme => ({
            id: theme.id,
            points: theme.points.map(point => ({ id: point.id })).sort(sortIds),
          }))
          .sort(sortIds);
        actualSkeleton.points = points.map(point => ({ id: point.id })).sort(sortIds);
      }

      const skeletonChanged =
        skeleton !== null
          ? JSON.stringify(skeleton) !== JSON.stringify(actualSkeleton)
          : !isEmpty(actualSkeleton);

      const meetingInfo = {
        name,
        local: !isEmpty(local)
          ? {
              ...local,
              addToTypology,
            }
          : null,
        date: formatDate(getDateObj(date), 'yyyy-MM-dd HH:mm:ss'),
        endDate: formatDate(getDateObj(endDate), 'yyyy-MM-dd HH:mm:ss'),
        typologyId: typology.id,
        points: editing
          ? points
              .map(p => ({ ...p, matrixId: p.matrix ? p.matrix.id : null }))
              .filter(x => x.default || x.isNew)
          : points
              .filter(x => (x.selected && x.default) || (x.selected && x.isNew))
              .map(point => {
                if (point.default && point.typology_id === null) {
                  return { ...point, isNew: true };
                }

                return point;
              }),
        proposals: [...proposals, ...points.filter(x => !x.default && !x.isNew)],
        isPublic,
        themes: editing
          ? themes
              .filter(x => x.points && x.points.length > 0)
              .map(x => ({
                ...x,
                points: x.points.map(p => ({
                  ...p,
                  matrixId: p.matrix ? p.matrix.id : null,
                })),
              }))
          : themes
              .filter(x => x.selected)
              .map(theme => ({
                ...theme,
                points: theme.points
                  .filter(point => point.selected)
                  .map(point => {
                    if (point.default && point.typology_id === null) {
                      return { ...point, isNew: true };
                    }

                    return point;
                  }),
              })),
        users: isGroup
          ? users.map(x => ({
              ...x,
              users: x.users.filter(user => user.in_meeting),
            }))
          : users.filter(user => user.in_meeting),
        changes: isGroup ? filterGroupChanges(changes) : filterUserChanges(changes),
        board,
        briefChanged: briefChanged || skeletonChanged,
        videoConferenceLink: videoConference ? videoConferenceLink : '',
        newSession,
      };

      const meetingPromise = new Promise((resolve, reject) => {
        if (editing) {
          dispatchRedux(updateMeeting(id, meetingInfo, resolve, reject));
        } else {
          dispatchRedux(createMeeting(meetingInfo, resolve, reject));
        }
      });

      return meetingPromise
        .then(res => {
          const formDataAgenda = new FormData();
          const formDataPublicNotice = new FormData();
          const agendaPromise = new Promise((resolve, reject) => {
            if (newBriefFile !== null) {
              formDataAgenda.append('file', newBriefFile);

              dispatchRedux(uploadBriefFile(formDataAgenda, res.id, resolve, reject));
            } else {
              resolve();
            }
          });
          const publicNoticePromise = new Promise((resolve, reject) => {
            if (newPublicNoticeFile !== null) {
              formDataPublicNotice.append('file', newPublicNoticeFile);

              dispatchRedux(uploadPublicNoticeFile(formDataPublicNotice, res.id, resolve, reject));
            } else {
              resolve();
            }
          });

          return Promise.all([agendaPromise, publicNoticePromise])
            .then(() => {
              const filesUpload = files.filter(x => x.id === undefined);
              const deleteFiles = deletedFiles.filter(x => x.id !== undefined);

              deleteFiles.forEach(file => {
                dispatchRedux(deleteMeetingFile(res.id, file.id));
              });

              if (filesUpload.length > 0) {
                // @ Existem ficheiros associados a esta proposta
                const attachmentsPromise = new Promise(resolve => {
                  const formData = new FormData();
                  filesUpload
                    .filter(x => x.id === undefined)
                    .forEach((file, idx) => {
                      formData.append('attachments[][file]', file);
                      if (idx === filesUpload.length - 1) {
                        resolve(formData);
                      }
                    });
                });

                attachmentsPromise.then(formData => {
                  const uploadPromise = new Promise((resolve, reject) => {
                    const filesLength = filesUpload.length;
                    dispatchRedux(
                      uploadMeetingFiles(formData, filesLength, res.id, resolve, reject)
                    );
                  });

                  uploadPromise
                    .then(() => {
                      dispatch({ type: 'CLEAR_STATE' });

                      return confirmChange(`/reunioes/consultar/${res.id}`);
                    })
                    .catch(() => {
                      dispatch({
                        type: 'NOT_SUBMITTING',
                      });
                      return dispatchRedux({
                        type: 'SHOW_SNACK',
                        payload: {
                          variant: 'error',
                          message: 'Ocorreu um erro ao carregar os ficheiros.',
                        },
                      });
                    });
                });

                return null;
              }

              dispatch({ type: 'CLEAR_STATE' });

              return confirmChange(`/reunioes/consultar/${res.id}`);
            })
            .catch(() => {
              dispatchRedux({
                type: 'SHOW_SNACK',
                payload: {
                  variant: 'error',
                  message: 'Erro ao guardar informação da reunião',
                },
              });
            });
        })
        .catch(err => {
          return dispatchRedux({
            type: 'SHOW_SNACK',
            payload: {
              variant: 'error',
              message: err.response.data.message,
            },
          });
        });
    },
    [
      briefChanged,
      id,
      name,
      typology,
      users,
      endDate,
      files,
      date,
      isGroup,
      isPublic,
      points,
      proposals,
      changes,
      themes,
      addToTypology,
      confirmChange,
      local,
      errors,
      dispatchRedux,
      editing,
      board,
      deletedFiles,
      newBriefFile,
      newPublicNoticeFile,
      skeleton,
      videoConferenceLink,
      videoConference,
      newSession,
    ]
  );

  const changePage = useCallback(
    wantedPage => {
      toggleOpenWarning();
      setNextLocation(wantedPage.pathname);
      setChange(false);
      return false;
    },
    [toggleOpenWarning, setChange]
  );

  const cancelLeaving = useCallback(
    e => {
      e.preventDefault();

      toggleOpenWarning();

      setChange(true);
    },
    [setChange, toggleOpenWarning]
  );

  const contextState = useMemo(
    () => ({
      state,
      loading,
      handleSubmit,
      openWarning,
      setNextLocation,
      nextLocation,
      changePage,
      cancelLeaving,
      confirmChange,
    }),
    [
      state,
      loading,
      handleSubmit,
      openWarning,
      setNextLocation,
      nextLocation,
      changePage,
      cancelLeaving,
      confirmChange,
    ]
  );
  const funcsContext = useMemo(() => ({ updateField }), [updateField]);

  return (
    <MeetingDispatchContext.Provider value={dispatch}>
      <MeetingFuncsContext.Provider value={funcsContext}>
        <MeetingStateContext.Provider value={contextState}>{children}</MeetingStateContext.Provider>
      </MeetingFuncsContext.Provider>
    </MeetingDispatchContext.Provider>
  );
};

CreateEditMeetingsProvider.defaultProps = {
  editing: false,
  newSession: false,
};

CreateEditMeetingsProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  navigate: PropTypes.func.isRequired,
  setChange: PropTypes.func.isRequired,
  editing: PropTypes.bool,
  newSession: PropTypes.bool,
};

export default CreateEditMeetingsProvider;
