import React from 'react';
import MeetingPointStatus from './MeetingPointStatus';
import Body1 from './typographys/Body1';
import PropTypes from 'prop-types';

const getPointName = (identifier, name) => {
  if (identifier) {
    return `${identifier} - ${name}`;
  }

  return name;
};

const MeetingPointNameAndStatus = ({
  identifierCode,
  meetingPointStatus,
  name,
  onClick,
  isMeetingDone,
}) => {
  return (
    <>
      <Body1 underline hoverable onClick={onClick}>
        {getPointName(identifierCode, name)}
      </Body1>
      {isMeetingDone && meetingPointStatus && meetingPointStatus.status !== null && (
        <MeetingPointStatus status={meetingPointStatus.status} />
      )}
    </>
  );
};

MeetingPointNameAndStatus.defaultProps = {
  identifierCode: null,
  meetingPointStatus: null,
};

MeetingPointNameAndStatus.propTypes = {
  identifierCode: PropTypes.string,
  meetingPointStatus: PropTypes.object,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isMeetingDone: PropTypes.bool.isRequired,
};

export default React.memo(MeetingPointNameAndStatus);
