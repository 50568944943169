import {
  UPDATE_REGISTER_INFO,
  UPDATE_USER_INFO,
  CLEAN_REGISTER_INFO,
  SET_CURRENT_USER,
  SHOW_CODE_PIN,
  TOKEN_EXPIRED,
  SHOW_SNACK,
  UPDATE_PROFILE_IMAGE,
  REGISTER_USER,
  GET_ERRORS,
  CLEAR_ERRORS,
  GET_USER_STATS,
  REGISTER_STATUS,
  AUTH_LOADING,
  AUTH_NOT_LOADING,
  GET_USER_ACTIONS,
} from './types';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import setAuthToken from '../../utils/setAuthToken';
import isEmpty from '../../utils/isEmpty';

/*
 * @desc - Efetua o login do utilizador
 * @param - Objeto com campos `email` e `password`
 * @return success - Autentica o utilizador em caso positivo
 * @return error - Insere os erros recebidos no errorsReducer
 */
export const loginUser = loginData => dispatch => {
  dispatch(clearErrors());
  dispatch(authLoading());

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/login`, loginData)
    .then(res => {
      // Não foi enviada sms, foi recebido JWT
      if (!res.data.duration) {
        // Save token to sessionStorage
        const { token } = res.data;
        if (!isEmpty(token)) {
          localStorage.setItem('jwtToken', token);
        }

        // Set token to auth header
        setAuthToken(token);

        dispatch(getUserInfo());
      } else {
        dispatch(showCodePin(res.data.token));
      }
    })
    .catch(err => {
      dispatch(authNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: {
          username:
            (err &&
              err.response &&
              err.response &&
              err.response.data &&
              err.response.data.message) ||
            'Credenciais inexistentes ou palavra-passe errada.',
          password:
            (err &&
              err.response &&
              err.response &&
              err.response.data &&
              err.response.data.message) ||
            'Credenciais inexistentes ou palavra-passe errada.',
        },
      });
    });
};

/*
 * @desc - Efetua o login do utilizador quando 2FA está ativa
 * @param - Objeto com campos `email`, `password`, `token` e `code`
 * @return success - Autentica o utilizador em caso positivo
 * @return error - Insere os erros recebidos no errorsReducer
 */
export const loginUser2FA = loginData => dispatch => {
  dispatch(clearErrors());
  dispatch(authLoading());

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/smsCode`, loginData)
    .then(res => {
      // Save token to sessionStorage
      const { token } = res.data;
      localStorage.setItem('jwtToken', token);

      // Set token to auth header
      setAuthToken(token);

      const decoded = jwtDecode(token);
      dispatch(getUserInfo(decoded.sub));
    })
    .catch(err => {
      dispatch(authNotLoading());
      if (err.response && err.response.data && err.response.data.code) {
        if (err.response.data.code === 'expired') {
          dispatch({
            type: TOKEN_EXPIRED,
          });
        }

        dispatch({
          type: GET_ERRORS,
          payload: {
            code: err.response.data.message,
          },
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: {
            username: 'Credenciais inexistentes ou palavra-passe errada.',
            password: 'Credenciais inexistentes ou palavra-passe errada.',
            code: 'Credenciais inexistentes ou código errado.',
          },
        });
      }
    });
};

/*
 * @desc - Após o token ser recebido, obtem a informação do utilizador logado
 * @param - `id` do utilizador com login efetuado
 * @return success - Insere a informação recebida no objecto `user` do authReducer
 * @return error -
 */
export const getUserInfo = () => dispatch => {
  dispatch(clearErrors());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/user`)
    .then(res => {
      dispatch(setCurrentUser({ ...res.data, image: '' }));
      if (res.data.image) {
        dispatch(getUserProfilePic(res.data.id));
      }
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getUserStats = () => dispatch => {
  dispatch(clearErrors());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/user/stats`)
    .then(res => {
      dispatch({
        type: GET_USER_STATS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getUserActions = () => dispatch => {
  dispatch(clearErrors());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/user/actions`)
    .then(res => {
      dispatch({
        type: GET_USER_ACTIONS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const checkEmailToken = info => dispatch => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/checkToken`, info)
    .then(res => {
      dispatch({
        type: REGISTER_STATUS,
        payload: res.data,
      });
    })
    .catch(err => {
      window.location.href = '/';
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const verifyEmailToken = info => dispatch => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/verify`, info)
    .then(() => {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Email validado com sucesso.',
        },
      });
      dispatch({
        type: REGISTER_STATUS,
        payload: { email: true },
      });
    })
    .catch(err => {
      window.location.href = '/';
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const verifyPhoneCode = info => dispatch => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/verifyPhone`, info)
    .then(() => {
      dispatch({
        type: REGISTER_STATUS,
        payload: { email: true, phone: true },
      });
    })
    .catch(err => {
      window.location.href = '/';
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const forgotPassword = info => dispatch => {
  dispatch(authLoading());
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/forgotPassword`, info)
    .then(() => {
      dispatch({
        type: REGISTER_STATUS,
        payload: { email: true },
      });
      dispatch({
        type: SHOW_SNACK,
        payload: { variant: 'success', message: 'Email de recuperação enviado' },
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: {
          ...err.response.data,
          email:
            err &&
            err.response &&
            err.response &&
            err.response.data &&
            err.response.data.email &&
            err.response.data.email[0],
        },
      });
    });
};

export const resetPassword = info => dispatch => {
  dispatch(authNotLoading());
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/resetPassword`, info)
    .then(() => {
      window.location.href = '/';
      dispatch({
        type: SHOW_SNACK,
        payload: { variant: 'success', message: 'Palavra-passe alterada com sucesso' },
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: {
          ...err.response.data,
          email:
            err &&
            err.response &&
            err.response &&
            err.response.data &&
            err.response.data.email &&
            err.response.data.email[0],
        },
      });
    });
};

/*
 * @desc - Atualiza a informação pessoal do utilizador logado
 * @param - `id` do utilizador com login efetuado e `info` a atualizar
 * @return success - Insere a informação recebida no objecto `user` do authReducer
 * @return error -
 */
export const updateUser = (id, info) => dispatch => {
  dispatch({
    type: UPDATE_USER_INFO,
    payload: info,
  });
};

/*
 * @desc - Efetua o logout do utilizador, limpando o authReducer, coloca a flag `isAuthenticated` a false e remove o token do localStorage
 */
export const logoutUser = () => dispatch => {
  localStorage.removeItem('jwtToken');

  window.location.href = '/';

  setAuthToken(false);
  dispatch(setCurrentUser({}));
};

/*
 * @desc - Atualiza a informação inserida pelo utilizador no 1º passo do registo
 * @params - Objeto com campos `step`, `name`, `type`, `nif` e `address`
 *          address : { `street`, `postalCode` }
 * @return success - Atualiza o objeto `registration` no authReducer com a informação que chega no objeto `info`
 */
export const updateRegisterInfo = info => dispatch => {
  dispatch({
    type: UPDATE_REGISTER_INFO,
    payload: info,
  });
};

/*
 * @desc - Efetua o registo de um utilizador
 * @params objeto com campos `name`, `email`, `phone` e `entity_id`
 * @return success - Adiciona o utilizador ao array de utilizadores para 1 determinado estabelecimento
 */
export const registerUser = (info, resolve, reject) => dispatch => {
  dispatch(authLoading());
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/register`, info)
    .then(res => {
      dispatch(clearErrors());
      resolve();
      dispatch({
        type: REGISTER_USER,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(authNotLoading());
      reject();
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const uploadUserImage = (data, resolve, reject) => dispatch => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/user/image`, data, config)
    .then(() => {
      dispatch(clearErrors());
      resolve();
      dispatch(getUserProfilePic());
    })
    .catch(err => {
      reject();
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getUserProfilePic = () => dispatch => {
  const options = { responseType: 'blob' };

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/user/image`, options)
    .then(res => {
      dispatch({
        type: UPDATE_PROFILE_IMAGE,
        payload: URL.createObjectURL(res.data),
      });
    })
    .catch(err => {
      const reader = new FileReader();
      reader.addEventListener('loadend', () => {
        dispatch({
          type: GET_ERRORS,
          payload: JSON.parse(reader.result),
        });
      });

      reader.readAsText(err.response.data);
    });
};

export const showCodePin = token => {
  return {
    type: SHOW_CODE_PIN,
    payload: token,
  };
};

/*
 * @desc - Limpa o estado do registo (quando o utilizador acaba de registar, se entrar na página de login e voltar ao registo)
 * @param - null
 * @return success - Limpa o objeto `registration` do authReducer
 */

export const cleanRegistration = () => {
  return {
    type: CLEAN_REGISTER_INFO,
  };
};

/*
 * @desc - Atualiza o utilizador atualmente autenticado com a informação do JWT descodificado
 * @param - JSON WEB TOKEN descodificado
 * @return success - Atualiza o objeto `auth` no authReducer com a informação do JWT descodificado
 */
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

/*
 * @desc - Limpa o reducer de erros quando necessário
 */
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export const authLoading = () => {
  return {
    type: AUTH_LOADING,
  };
};

export const authNotLoading = () => {
  return {
    type: AUTH_NOT_LOADING,
  };
};
