import React, { useMemo, useCallback } from 'react';
import ParticipantMember from './ParticipantMember';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const compare = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const ParticipantsList = ({ individuals }) => {
  const { users = [] } = useSelector(state => state.users);

  const options = useMemo(() => users.sort(compare), [users]);

  const isParticipant = useCallback(
    idMember => {
      return (individuals && individuals.find(x => x.id === idMember) !== undefined) || false;
    },
    [individuals]
  );

  const hasNoTimeLimit = useCallback(
    idMember => {
      const obj = (individuals && individuals.find(x => x.id === idMember)) || {};
      return obj.noLimit || false;
    },
    [individuals]
  );

  return (
    options.length > 0 &&
    options.map(option => (
      <ParticipantMember
        key={option.id}
        id={option.id}
        name={option.name}
        participant={isParticipant(option.id)}
        timeLimit={hasNoTimeLimit(option.id)}
      />
    ))
  );
};

ParticipantsList.defaultProps = {
  individuals: [],
};

ParticipantsList.propTypes = {
  individuals: PropTypes.array,
};

export default React.memo(ParticipantsList);
