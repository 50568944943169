import React, { useMemo } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import CardBlock from '../../common/forms/CardBlock';
import MeetingPool from './MeetingPool';
import MeetingThemeEntry from './MeetingThemeEntry';
import Subtitle2 from '../../common/typographys/Subtitle2';
import Body1 from '../../common/typographys/Body1';
import MeetingPoolBox from './MeetingPoolBox';
import CreateNewPoint from './CreateNewPoint';
import NoThemeMeetingPoints from './NoThemeMeetingPoints';

const useStyles = makeStyles(theme => ({
  label: {
    marginBottom: '2px',
  },
  boxThemeBorder: {
    padding: '15px',
    border: `1px solid ${theme.palette.neutrals[3]}`,
    width: '100%',
  },
}));

const MeetingPoolPointsThemes = ({ state }) => {
  const classes = useStyles();
  const { pool, points, themes, availableThemes, matrices, isGroup } = state;
  const { points: poolPoints = [], themes: poolThemes = [] } = pool;
  const themesWithPoints = useMemo(() => {
    if (themes) {
      return themes.filter(theme => theme && theme.points && theme.points.length > 0);
    }
    return [];
  }, [themes]);
  const hasPoolPoints = useMemo(() => poolPoints.length > 0, [poolPoints]);
  const hasPoolThemes = useMemo(() => poolThemes.length > 0, [poolThemes]);
  const hasPool = useMemo(() => hasPoolPoints || hasPoolThemes, [hasPoolPoints, hasPoolThemes]);

  return (
    <CardBlock label="Temas e pontos de discussão">
      {themesWithPoints.map((theme, idx) => (
        <React.Fragment key={theme.id}>
          {idx !== 0 && <Box height="20px" />}
          <Box className={classes.boxThemeBorder}>
            <MeetingThemeEntry
              theme={theme}
              matrices={matrices}
              isGroup={isGroup}
              idx={idx}
              length={themesWithPoints.length}
            />
          </Box>
        </React.Fragment>
      ))}
      {points.length > 0 && (
        <>
          <Box height={themes.length > 0 ? '40px' : '0px'} />
          <Subtitle2 tertiary className={classes.label}>
            Pontos de discussão sem tema
          </Subtitle2>
          <NoThemeMeetingPoints
            points={points}
            availableThemes={availableThemes}
            matrices={matrices}
          />
        </>
      )}
      {themes.length === 0 && points.length === 0 && (
        <Body1>Nenhum tema ou ponto de discussão adicionado.</Body1>
      )}
      {(themes.length !== 0 || points.length !== 0) && matrices.length === 0 && (
        <>
          <Box height="20px" />
          <Subtitle2 tertiary>
            Não é possível atribuir matrizes de tempo, porque não existem matrizes compatíveis com
            os participantes desta reunião.
          </Subtitle2>
        </>
      )}
      <Box height="20px" />
      <CreateNewPoint />
      {hasPool ? (
        <MeetingPool
          hasPoolPoints={hasPoolPoints}
          hasPoolThemes={hasPoolThemes}
          points={poolPoints}
          themes={poolThemes}
        />
      ) : (
        <MeetingPoolBox>
          <Body1>Nenhuma proposta na pool.</Body1>
        </MeetingPoolBox>
      )}
    </CardBlock>
  );
};

MeetingPoolPointsThemes.propTypes = {
  state: PropTypes.object.isRequired,
};

export default React.memo(MeetingPoolPointsThemes);
