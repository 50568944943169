import React, { useCallback, useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { MeetingDispatchContext } from '../CreateEditMeetingsProvider';
import Subtitle2 from '../../common/typographys/Subtitle2';
import NewBoxItem from '../../configs/tabs/typologies/pieces/NewBoxItem';

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: '6px',
  },
}));

const CreateNewPoint = () => {
  const classes = useStyles();
  const dispatch = useContext(MeetingDispatchContext);

  const addNewPoint = useCallback(
    name => {
      dispatch({
        type: 'CREATE_DEFAULT_MEETING_POINT',
        payload: {
          id: uuidv4(),
          name,
          isNew: true,
          selected: true,
        },
      });
    },
    [dispatch]
  );

  return (
    <>
      <Subtitle2 className={classes.label}>Criar ponto de discussão para associar a esta reunião</Subtitle2>
      <NewBoxItem onClick={addNewPoint} placeholder="Adicionar ponto de discussão" />
    </>
  );
};

export default React.memo(CreateNewPoint);
