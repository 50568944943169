import axios from 'axios';
import {
  GET_ORGANIC_UNIT,
  GET_ORGANIC_UNITS,
  CREATE_ORGANIC_UNIT,
  UPDATE_ORGANIC_UNIT,
  GET_ERRORS,
  SHOW_SNACK,
  ORGANIC_UNITS_LOADING,
  ORGANIC_UNITS_NOT_LOADING,
  GET_ORGANIC_UNITS_USERS,
  REMOVE_ORGANIC_UNIT,
  GET_ORGANIC_UNIT_PARENTS,
  GET_ORGANIC_UNITS_CHILDREN,
} from './types';

export const getOrganicUnit = id => dispatch => {
  dispatch(setOrganicUnitsLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/organic_units/${id}`)
    .then(res => {
      dispatch({
        type: GET_ORGANIC_UNIT,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(setOrganicUnitsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getOrganicUnits = () => dispatch => {
  dispatch(setOrganicUnitsLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/organic_units/all`)
    .then(res => {
      dispatch({
        type: GET_ORGANIC_UNITS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(setOrganicUnitsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getPublicOrganicUnits = () => dispatch => {
  dispatch(setOrganicUnitsLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/organic_units/public`)
    .then(res => {
      dispatch({
        type: GET_ORGANIC_UNITS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(setOrganicUnitsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getOrganicUnitsUsers = id => dispatch => {
  dispatch(setOrganicUnitsLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/organic_units/${id}/users`)
    .then(res => {
      dispatch({
        type: GET_ORGANIC_UNITS_USERS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(setOrganicUnitsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getOrganicUnitsChildren = id => dispatch => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/organic_units/${id}/children`)
    .then(res => {
      dispatch({
        type: GET_ORGANIC_UNITS_CHILDREN,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getOrganicUnitsParents = id => dispatch => {
  dispatch(setOrganicUnitsLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/organic_units/${id}/parents`)
    .then(res => {
      dispatch({
        type: GET_ORGANIC_UNIT_PARENTS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(setOrganicUnitsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const createOrganicUnit = info => dispatch => {
  dispatch(setOrganicUnitsLoading());

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/organic_units`, info)
    .then(res => {
      dispatch({
        type: CREATE_ORGANIC_UNIT,
        payload: res.data,
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Unidade Orgânica criada com sucesso.',
        },
      });
    })
    .catch(err => {
      dispatch(setOrganicUnitsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateOrganicUnit = (id, info) => dispatch => {
  dispatch(setOrganicUnitsLoading());

  axios
    .put(`${process.env.REACT_APP_API_URL}/api/organic_units/${id}`, info)
    .then(res => {
      dispatch({
        type: UPDATE_ORGANIC_UNIT,
        payload: res.data,
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Unidade Orgânica atualizada com sucesso.',
        },
      });
    })
    .catch(err => {
      dispatch(setOrganicUnitsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const deleteOrganicUnit = (id, info, resolve, reject) => dispatch => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/organic_units/${id}/delete`, info)
    .then(res => {
      resolve(res);
      dispatch({
        type: REMOVE_ORGANIC_UNIT,
        payload: {
          id,
        },
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Unidade Orgânica eliminada com sucesso.',
        },
      });
    })
    .catch(err => {
      reject(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const removeOrganicUnit = (id, callback) => dispatch => {
  dispatch(setOrganicUnitsLoading());

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/organic_units/${id}/disable`)
    .then(() => {
      dispatch({
        type: REMOVE_ORGANIC_UNIT,
        payload: {
          id,
        },
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Unidade Orgânica excluída com sucesso.',
        },
      });
      callback();
    })
    .catch(err => {
      dispatch(setOrganicUnitsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const setOrganicUnitsLoading = () => {
  return {
    type: ORGANIC_UNITS_LOADING,
  };
};

export const setOrganicUnitsNotLoading = () => {
  return {
    type: ORGANIC_UNITS_NOT_LOADING,
  };
};
