import React from 'react';
import { makeStyles, Grid, Paper, Box, Chip, IconButton } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import PropTypes from 'prop-types';
import Subtitle1 from '../../common/typographys/Subtitle1';
import Body1 from '../../common/typographys/Body1';

const useStyles = makeStyles(theme => ({
  bodyCard: {
    padding: '10px 15px',
  },
  date: {
    paddingTop: '6px',
  },
  icon: {
    color: '#FFF',
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  itemIcon: {
    background: theme.palette.primary[500],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  hoverable: {
    '&:hover': {
      cursor: 'pointer',
      '& p': {
        transition: 'color 0.1s ease',
        color: theme.palette.colorsPalette.BrownOrange,
      },
    },
  },
  chipLabel: {
    background: theme.palette.primary[500],
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
  },
}));

const getLabelType = type => {
  if (type === 'flow' || type === 'proposal') {
    return 'Proposta';
  }

  return 'Declaração de voto';
};

const ActionItem = ({ action, clickUrl }) => {
  const classes = useStyles();
  const { type, text, url } = action;

  return (
    <Grid item xs={12} md={6} xl={4}>
      <Paper className={classes.hoverable} onClick={clickUrl(url)}>
        <Grid container>
          <Grid item xs={11} className={classes.bodyCard}>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Body1>{text}</Body1>
              <Chip
                color="primary"
                size="small"
                label={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Subtitle1 center selected>
                    {getLabelType(type)}
                  </Subtitle1>
                }
                classes={{ label: classes.chipLabel }}
              />
            </Box>
          </Grid>
          <Grid item xs={1} className={classes.itemIcon}>
            <IconButton className={classes.icon}>
              <Visibility />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

ActionItem.propTypes = {
  action: PropTypes.object.isRequired,
  clickUrl: PropTypes.func.isRequired,
};

export default React.memo(ActionItem);
