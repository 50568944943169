import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body1 from '../typographys/Body1';
import BoxCheckbox from './BoxCheckbox';
import clsx from 'clsx';
import isEmpty from '../../../utils/isEmpty';
import Subtitle1 from '../typographys/Subtitle1';

const useStyles = makeStyles(() => ({
  boxChoice: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    paddingRight: '10px',
  },
  disabled: {
    opacity: '0.4',
    pointerEvents: 'none',
  },
}));

const CheckboxPicker = ({
  label,
  onClick,
  checked,
  indeterminate,
  allCheck,
  disabled,
  labelProps,
  secondLabel,
}) => {
  const classes = useStyles();

  return !isEmpty(secondLabel) ? (
    <Box className={clsx({ [classes.disabled]: disabled })}>
      <Box className={classes.boxChoice}>
        <Body1 className={classes.label} {...labelProps}>
          {label}
        </Body1>
        <BoxCheckbox
          onClick={onClick}
          checked={checked}
          indeterminate={indeterminate}
          allCheck={allCheck}
        />
      </Box>
      <Subtitle1>{secondLabel}</Subtitle1>
    </Box>
  ) : (
    <Box className={clsx(classes.boxChoice, { [classes.disabled]: disabled })}>
      <Body1 className={classes.label} {...labelProps}>
        {label}
      </Body1>
      <BoxCheckbox
        onClick={onClick}
        checked={checked}
        indeterminate={indeterminate}
        allCheck={allCheck}
      />
    </Box>
  );
};

CheckboxPicker.defaultProps = {
  indeterminate: false,
  allCheck: false,
  checked: false,
  disabled: false,
  labelProps: {},
  secondLabel: null,
};

CheckboxPicker.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  allCheck: PropTypes.bool,
  disabled: PropTypes.bool,
  labelProps: PropTypes.object,
  secondLabel: PropTypes.string,
};

export default React.memo(CheckboxPicker);
