import React from 'react';
import PropTypes from 'prop-types';
import CardBlock from '../../common/forms/CardBlock';
import MeetingUsersGroupsPrepare from './MeetingUsersGroupsPrepare';
import MeetingUsersListPrepare from './MeetingUsersListPrepare';

const MeetingUsersPrepare = ({ state, editing }) => {
  const { users, participants, isGroup, meetingId } = state;

  return (
    <CardBlock label="Participantes">
      {isGroup ? (
        <MeetingUsersGroupsPrepare
          meetingId={meetingId}
          groups={users}
          participants={participants}
          editing={editing}
        />
      ) : (
        <MeetingUsersListPrepare
          meetingId={meetingId}
          users={users.filter(user => user.in_meeting)}
          participants={participants}
          allUsers={users}
          editing={editing}
        />
      )}
    </CardBlock>
  );
};

MeetingUsersPrepare.defaultProps = {
  editing: false,
};

MeetingUsersPrepare.propTypes = {
  state: PropTypes.object.isRequired,
  editing: PropTypes.bool,
};

export default React.memo(MeetingUsersPrepare);
