import isEmpty from '../isEmpty';

/**
 *
 * @param {object} permissions - Permissions object
 * @param {string} prop - Property to validate
 * @returns {boolean} True or false depending if user has permission in other group
 */
const hasOthersProp = (permissions, prop) => {
  if (!isEmpty(permissions) && permissions.others && permissions.others[prop]) {
    return true;
  }

  return false;
};

export default hasOthersProp;
