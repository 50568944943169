import React, { useContext, useState, useCallback } from 'react';
import { makeStyles, Button, Grid } from '@material-ui/core';
import { CheckProposalStateContext, CheckProposalFuncsContext } from './CheckProposalProvider';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DialogConfirmAction from '../../common/dialogs/DialogConfirmAction';
import { useDispatch } from 'react-redux';
import { submitNewProposalVersion, removeProposal } from '../../../store/actions/proposalsActions';

const useStyles = makeStyles(theme => ({
  common: {
    width: '100%',
    marginBottom: '46px',
    minHeight: '44px',
    maxHeight: '44px',
  },
  button: {
    color: theme.palette.neutrals[1],
    backgroundColor: theme.palette.primary.A400,
    '&:hover': {
      color: theme.palette.neutrals[1],
      backgroundColor: theme.palette.primary.A700,
    },
  },
  noMargin: {
    marginLeft: 0,
  },
}));

const EditProposalButtons = ({ canSubmit, canRemove, canEdit }) => {
  const classes = useStyles();
  const {
    state: {
      editing,
      proposal: { id, proposal_id },
    },
    submitUpdateProposal,
  } = useContext(CheckProposalStateContext);
  const { updateField } = useContext(CheckProposalFuncsContext);
  const [open, setOpen] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);
  const dispatch = useDispatch();

  const submitNewVersion = useCallback(
    e => {
      e.preventDefault();
      setOpen(false);
      dispatch(submitNewProposalVersion(proposal_id, id));
    },
    [dispatch, proposal_id, id]
  );

  const confirmRemove = useCallback(
    e => {
      e.preventDefault();
      setOpenRemove(false);
      dispatch(removeProposal(proposal_id));
    },
    [dispatch, proposal_id]
  );

  return editing ? (
    <Grid container spacing={2} justify="flex-end">
      <Grid item>
        <Button
          variant="outlined"
          color="primary"
          onClick={e => {
            e.preventDefault();
            updateField('editing', false);
          }}
          className={classes.common}
        >
          Cancelar
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          onClick={submitUpdateProposal}
          className={clsx(classes.button, classes.common)}
        >
          Gravar
        </Button>
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={2} justify="flex-end">
      {canEdit && (
        <Grid item>
          <Button
            variant="contained"
            onClick={e => {
              e.preventDefault();
              updateField('editing', true);
            }}
            className={clsx(classes.button, classes.common)}
          >
            Editar proposta
          </Button>
        </Grid>
      )}
      {canRemove && (
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={e => {
              e.preventDefault();
              setOpenRemove(true);
            }}
            className={classes.common}
          >
            Retirar proposta
          </Button>
        </Grid>
      )}
      {canSubmit && (
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={e => {
              e.preventDefault();
              setOpen(true);
            }}
            className={classes.common}
          >
            Submeter nova versão
          </Button>
        </Grid>
      )}
      <DialogConfirmAction
        open={open}
        msg="Deseja submeter a nova versão para a proposta?"
        labelSubmit="Submeter"
        handleClose={e => {
          e.preventDefault();
          setOpen(false);
        }}
        handleSubmit={submitNewVersion}
      />
      <DialogConfirmAction
        open={openRemove}
        msg="Deseja retirar a proposta?"
        labelSubmit="Retirar"
        handleClose={e => {
          e.preventDefault();
          setOpenRemove(false);
        }}
        handleSubmit={confirmRemove}
      />
    </Grid>
  );
};

EditProposalButtons.defaultProps = {
  canSubmit: false,
  canRemove: false,
  canEdit: false,
};

EditProposalButtons.propTypes = {
  canSubmit: PropTypes.bool,
  canRemove: PropTypes.bool,
  canEdit: PropTypes.bool,
};

export default React.memo(EditProposalButtons);
