import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import LabelAndValue from '../../../../../common/forms/LabelAndValue';
import Subtitle2 from '../../../../../common/typographys/Subtitle2';
import TemplateBox from './TemplateBox';

const useStyles = makeStyles(() => ({
  contentLabel: {
    marginBottom: '8px',
  },
  content: {
    backgroundColor: '#FFF',
    padding: '14px',
  },
}));

const createMarkup = value => {
  return { __html: value || 'Nenhum conteúdo adicionado' };
};

const TemplateInfo = ({ email }) => {
  const classes = useStyles();
  const { type, subject, content } = email;

  return (
    <TemplateBox type={type}>
      <LabelAndValue label="Assunto" value={subject || 'Nenhum assunto adicionado'} />
      <Subtitle2 tertiary className={classes.contentLabel}>
        Conteúdo
      </Subtitle2>
      <Box className={classes.content}>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={createMarkup(content)} />
      </Box>
    </TemplateBox>
  );
};

TemplateInfo.propTypes = {
  email: PropTypes.object.isRequired,
};

export default React.memo(TemplateInfo);
