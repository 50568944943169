import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body1 from './typographys/Body1';

const useStyles = makeStyles(theme => ({
  mainBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 10px',
    backgroundColor: theme.palette.neutrals[2],
    marginBottom: '12px',
  },
}));

const BoxList = ({ items }) => {
  const classes = useStyles();

  return items.map(({ name, no_limit }) => (
    <Box key={name} className={classes.mainBox}>
      <Body1 oneLine>{name}</Body1>
      {Boolean(no_limit) && <Body1 secondary>sem tempo limite oratório</Body1>}
    </Box>
  ));
};

BoxList.defaultProps = {
  items: [],
};

BoxList.propTypes = {
  items: PropTypes.array,
};

export default React.memo(BoxList);
