import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Subtitle2 from '../../common/typographys/Subtitle2';
import TimeBox from '../../configs/pieces/TimeBox';
import MeetingUsersListPrepare from './MeetingUsersListPrepare';

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: '6px',
  },
}));

const MeetingUsersGroupsPrepare = ({ groups, participants, meetingId }) => {
  const classes = useStyles();

  return groups.map((group, idx) => (
    <React.Fragment key={group.id}>
      <Subtitle2 className={classes.label}>{group.name}</Subtitle2>
      <TimeBox dense>
        <MeetingUsersListPrepare
          meetingId={meetingId}
          users={group.users.filter(user => user.in_meeting)}
          isGroup
          allUsers={group.users}
          idxGroup={idx}
          idGroup={group.id}
          participants={participants}
        />
      </TimeBox>
      {idx !== groups.length - 1 && <Box height="20px" />}
    </React.Fragment>
  ));
};

MeetingUsersGroupsPrepare.propTypes = {
  meetingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  groups: PropTypes.array.isRequired,
  participants: PropTypes.array.isRequired,
};

export default React.memo(MeetingUsersGroupsPrepare);
