import { makeStyles, colors } from '@material-ui/core';

const useCalendarStyles = makeStyles(theme => ({
  calendarContainer: {
    height: '100%',
    padding: '0',
    '& .fc-dayGrid-view td': {
      border: 0,
    },
    '& .fc-dayGrid-view th': {
      border: 0,
    },
    '& .fc-timeGrid-view th': {
      border: 0,
    },
    '& .fc-timeGrid-view  .fc-widget-content': {
      borderLeft: 0,
    },
    '& .fc-unthemed td': {
      borderColor: colors.grey[300],
    },
    '& .fc-past': {
      background: '#FFFFFF',
    },
    '& .fc-future': {
      background: '#FFFFFF',
    },
    '& .fc-sat': {
      background: '#E9EFC3',
    },
    '& .fc-sun': {
      background: '#E9EFC3',
    },
    '& .fc-widget-header': {
      backgroundColor: 'transparent',
      border: 0,
    },
    '& .fc-axis': {
      ...theme.typography.subtitle1,
    },
    '& .fc-list-item-time': {
      ...theme.typography.body2,
    },
    '& .fc-list-item-title': {
      ...theme.typography.body1,
    },
    '& .fc-list-heading-main': {
      ...theme.typography.h6,
    },
    '& .fc-list-heading-alt': {
      ...theme.typography.h6,
    },
    '& .fc th': {
      border: '0',
    },
    '& .fc-day-header': {
      ...theme.typography.subtitle1,
      textAlign: 'left',
    },
    '& .fc-day-top': {
      ...theme.typography.body2,
    },
    '& .fc-highlight': {
      backgroundColor: colors.blueGrey[50],
    },
    '& .fc-event': {
      backgroundColor: '#FFFFFF',
      color: '#636363',
      borderWidth: 4,
      border: 0,
      borderLeft: 'solid #FFFFFF',
      borderRadius: 0,
      '& .fc-time': {
        display: 'none',
      },
      '& .fc-title': {
        ...theme.typography.body1,
        color: 'inherit',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        display: 'block',
        marginRight: '20px',
      },
      '& .fc-content': {
        paddingLeft: 10,
      },
      '&:hover': {
        cursor: 'pointer',
        color: theme.palette.colorsPalette.BrownOrange,
      },
    },
    '& .fc-list-empty': {
      ...theme.typography.subtitle1,
    },
    '& .fc-other-month': {
      // background: `repeating-linear-gradient( 90deg, #ffffff, #ffffff 2px, ${colors.grey[200]} 2px, ${colors.grey[200]} 4px )`,
      backgroundImage: theme.props.pattern,
    },
    '& .fc-day-number': {
      ...theme.typography.caption,
      float: 'left!important',
    },
    '& .fc-slats tr': {
      borderLeft: 0,
    },
    '& .fc-more': {
      display: 'inline-block',
      width: '100%',
    },
  },
  otherMonth: {
    borderColor: '#BCCBC9!important',
    backgroundColor: 'transparent!important',
    '&:hover': {
      color: '#BCCBC9!important',
    },
  },
  eventCanceled: {
    textDecoration: 'line-through',
  },
  marginContainer: {
    marginTop: '50px',
  },
}));

export default useCalendarStyles;
