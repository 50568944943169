import { useState, useCallback } from 'react';

const useBooleanToggle = () => {
  const [value, setValue] = useState(false);

  const toggle = useCallback(() => {
    setValue(old => !old);
  }, [setValue]);

  return [value, toggle, setValue];
};

export default useBooleanToggle;
