//! Auth reducer / actions types
export const UPDATE_REGISTER_INFO = 'UPDATE_REGISTER_INFO';
export const CLEAN_REGISTER_INFO = 'CLEAN_REGISTER_INFO';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const REGISTER_USER = 'REGISTER_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const REGISTER_STATUS = 'REGISTER_STATUS';
export const UPDATE_PROFILE_IMAGE = 'UPDATE_PROFILE_IMAGE';
export const SHOW_CODE_PIN = 'SHOW_CODE_PIN';
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';
export const GET_USER_STATS = 'GET_USER_STATS';
export const GET_USER_ACTIONS = 'GET_USER_ACTIONS';
export const CLEAN_AUTH_STATUS = 'CLEAN_AUTH_STATUS';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_NOT_LOADING = 'AUTH_NOT_LOADING';
// export const UPDATE_USER_CONFIG = 'UPDATE_USER_CONFIG';
//! Config reducer / actions types
export const GET_CONFIGS = 'GET_CONFIGS';
//! Download reducer / actions types
export const SET_PROGRESS = 'SET_PROGRESS';
//! Navigation reducer / actions types
export const CHANGE_MENU = 'CHANGE_MENU';
//! Users reducer / actions types
export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const GET_USER_PROFILE_PERMISSIONS = 'GET_USER_PROFILE_PERMISSIONS';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_ORGANIC_UNITS = 'UPDATE_USER_ORGANIC_UNITS';
export const UPDATE_USER_ORGANIC_UNIT = 'UPDATE_USER_ORGANIC_UNIT';
export const UPDATE_USER_PERMISSIONS = 'UPDATE_USER_PERMISSIONS';
export const GET_USERS_SIMPLIFY = 'GET_USERS_SIMPLIFY';
export const REMOVE_USER = 'REMOVE_USER';
export const USERS_LOADING = 'USERS_LOADING';
export const USERS_NOT_LOADING = 'USERS_NOT_LOADING';
//! Groups reducer / actions types
export const GET_GROUPS = 'GET_GROUPS';
export const CREATE_GROUP = 'CREATE_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const GET_GROUP_PARTICIPANTS = 'GET_GROUP_PARTICIPANTS';
export const REMOVE_GROUP = 'REMOVE_GROUP';
export const GROUPS_LOADING = 'GROUPS_LOADING';
export const GROUPS_NOT_LOADING = 'GROUPS_NOT_LOADING';
//! Organic Units reducer / actions types
export const GET_ORGANIC_UNIT = 'GET_ORGANIC_UNIT';
export const GET_ORGANIC_UNITS = 'GET_ORGANIC_UNITS';
export const CREATE_ORGANIC_UNIT = 'CREATE_ORGANIC_UNIT';
export const UPDATE_ORGANIC_UNIT = 'UPDATE_ORGANIC_UNIT';
export const GET_ORGANIC_UNITS_USERS = 'GET_ORGANIC_UNITS_USERS';
export const ORGANIC_UNITS_LOADING = 'ORGANIC_UNITS_LOADING';
export const ORGANIC_UNITS_NOT_LOADING = 'ORGANIC_UNITS_NOT_LOADING';
export const GET_ORGANIC_UNIT_PARENTS = 'GET_ORGANIC_UNIT_PARENTS';
export const GET_ORGANIC_UNITS_CHILDREN = 'GET_ORGANIC_UNITS_CHILDREN';
export const REMOVE_ORGANIC_UNIT = 'REMOVE_ORGANIC_UNIT';
//! Entities reducer / actions types
export const GET_ENTITIES = 'GET_ENTITIES';
export const CREATE_ENTITY = 'CREATE_ENTITY';
export const UPDATE_ENTITY = 'UPDATE_ENTITY';
export const REMOVE_ENTITY = 'REMOVE_ENTITY';
export const GET_ENTITY_PROPOSALS = 'GET_ENTITY_PROPOSALS';
export const ENTITIES_LOADING = 'ENTITIES_LOADING';
export const ENTITIES_NOT_LOADING = 'ENTITIES_NOT_LOADING';
//! Entities reducer / actions types
export const GET_THEMES = 'GET_THEMES';
export const CREATE_THEME = 'CREATE_THEME';
export const UPDATE_THEME = 'UPDATE_THEME';
export const REMOVE_THEME = 'REMOVE_THEME';
export const THEMES_LOADING = 'THEMES_LOADING';
export const THEMES_NOT_LOADING = 'THEMES_NOT_LOADING';
//! Profiles reducer / actions types
export const GET_PROFILES = 'GET_PROFILES';
export const GET_PROFILE = 'GET_PROFILE';
export const CREATE_PROFILE = 'CREATE_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const REMOVE_PROFILE = 'REMOVE_PROFILE';
export const PROFILES_LOADING = 'PROFILES_LOADING';
export const PROFILES_NOT_LOADING = 'PROFILES_NOT_LOADING';
//! Proposals reducer / actions types
export const GET_PROPOSALS = 'GET_PROPOSALS';
export const GET_PROPOSAL = 'GET_PROPOSAL';
export const UPDATE_PROPOSAL = 'UPDATE_PROPOSAL';
export const GET_PROPOSAL_VERSIONS = 'GET_PROPOSAL_VERSIONS';
export const GET_PROPOSAL_FLOW_CHANGES = 'GET_PROPOSAL_FLOW_CHANGES';
export const GET_PROPOSAL_STATUSES = 'GET_PROPOSAL_STATUSES';
export const GET_PROPOSAL_DECISIONS = 'GET_PROPOSAL_DECISIONS';
export const GET_PROPOSAL_MEETINGS = 'GET_PROPOSAL_MEETINGS';
export const PROPOSALS_LOADING = 'PROPOSALS_LOADING';
export const PROPOSALS_NOT_LOADING = 'PROPOSALS_NOT_LOADING';
export const CREATE_PROPOSAL = 'CREATE_PROPOSAL';
export const REMOVE_PROPOSAL = 'REMOVE_PROPOSAL';
export const UPDATE_PROPOSAL_MEETING_DELIBERATION_NUMBER =
  'UPDATE_PROPOSAL_MEETING_DELIBERATION_NUMBER';
export const UPDATE_PROPOSAL_MEETING_DELIBERATION_FILE_NAME =
  'UPDATE_PROPOSAL_MEETING_DELIBERATION_FILE_NAME';
//! Meetings reducer / actions types
export const GET_MEETINGS = 'GET_MEETINGS';
export const GET_MEETING = 'GET_MEETING';
export const UPDATE_MEETING = 'UPDATE_MEETING';
export const MEETINGS_LOADING = 'MEETINGS_LOADING';
export const MEETINGS_NOT_LOADING = 'MEETINGS_NOT_LOADING';
export const CREATE_MEETING = 'CREATE_MEETING';
export const GET_MEETING_MATRICES = 'GET_MEETING_MATRICES';
export const MEETING_POOL = 'MEETING_POOL';
export const MEETING_LOCALS = 'MEETING_LOCALS';
export const MEETING_SECRETARIES = 'MEETING_SECRETARIES';
export const MEETING_MODERATORS = 'MEETING_MODERATORS';
export const SET_USER_ACCEPTED = 'SET_USER_ACCEPTED';
export const CANCEL_MEETING = 'CANCEL_MEETING';
export const DELETE_MEETING = 'DELETE_MEETING';
export const BRIEF_AVAILABLE = 'BRIEF_AVAILABLE';
export const BRIEF_NOT_CHANGED = 'BRIEF_NOT_CHANGED';
export const USER_MEETING_DECLARATIONS = 'USER_MEETING_DECLARATIONS';
export const USER_MEETING_POINT_DECLARATIONS = 'USER_MEETING_POINT_DECLARATIONS';
export const USER_MEETING_DECLARATIONS_CHANGES = 'USER_MEETING_DECLARATIONS_CHANGES';
export const REMOVE_DECLARATION = 'REMOVE_DECLARATION';
export const UPDATE_MEETING_INFO = 'UPDATE_MEETING_INFO';
//! Flows reducer / actions types
export const GET_FLOWS = 'GET_FLOWS';
export const GET_FLOW = 'GET_FLOW';
export const CREATE_FLOW = 'CREATE_FLOW';
export const UPDATE_FLOW = 'UPDATE_FLOW';
export const REMOVE_FLOW = 'REMOVE_FLOW';
export const FLOWS_LOADING = 'FLOWS_LOADING';
export const FLOWS_NOT_LOADING = 'FLOWS_NOT_LOADING';
//! Emails reducer / actions types
export const GET_EMAILS = 'GET_EMAILS';
export const GET_EMAIL = 'GET_EMAIL';
export const CREATE_EMAIL = 'CREATE_EMAIL';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const REMOVE_EMAIL = 'REMOVE_EMAIL';
export const EMAILS_LOADING = 'EMAILS_LOADING';
export const EMAILS_NOT_LOADING = 'EMAILS_NOT_LOADING';
//! Matrices reducer / actions types
export const GET_MATRICES = 'GET_MATRICES';
export const GET_MATRIX = 'GET_MATRIX';
export const CREATE_MATRIX = 'CREATE_MATRIX';
export const UPDATE_MATRIX = 'UPDATE_MATRIX';
export const REMOVE_MATRIX = 'REMOVE_MATRIX';
export const MATRICES_LOADING = 'MATRICES_LOADING';
export const MATRICES_NOT_LOADING = 'MATRICES_NOT_LOADING';
//! Typologies reducer / actions types
export const GET_TYPOLOGIES = 'GET_TYPOLOGIES';
export const GET_TYPOLOGY_POOL = 'GET_TYPOLOGY_POOL';
export const CLEAR_TYPOLOGY = 'CLEAR_TYPOLOGY';
export const GET_TYPOLOGY = 'GET_TYPOLOGY';
export const CREATE_TYPOLOGY = 'CREATE_TYPOLOGY';
export const UPDATE_TYPOLOGY = 'UPDATE_TYPOLOGY';
export const REMOVE_TYPOLOGY = 'REMOVE_TYPOLOGY';
export const TYPOLOGIES_LOADING = 'TYPOLOGIES_LOADING';
export const TYPOLOGIES_NOT_LOADING = 'TYPOLOGIES_NOT_LOADING';
//! Snack reducer / actions types
export const SHOW_SNACK = 'SHOW_SNACK';
//! Notifications reducer / actions types
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const READ_ALL_NOTIFICATIONS = 'READ_ALL_NOTIFICATIONS';
export const CLICK_NOTIFICATION = 'CLICK_NOTIFICATION';
//! Notifications reducer / actions types
export const GET_LOCALS = 'GET_LOCALS';
export const CREATE_LOCAL = 'CREATE_LOCAL';
export const UPDATE_LOCAL = 'UPDATE_LOCAL';
export const REMOVE_LOCAL = 'REMOVE_LOCAL';
export const LOCALS_LOADING = 'LOCALS_LOADING';
export const LOCALS_NOT_LOADING = 'LOCALS_NOT_LOADING';
//! Reunions reducer / actions types
export const SET_REUNION_CRONOLOGY = 'SET_REUNION_CRONOLOGY';
export const SET_ACTUAL_REUNION = 'SET_ACTUAL_REUNION';
export const UPDATE_REUNION = 'UPDATE_REUNION';
export const UPDATE_PROPOSAL_VOTES = 'UPDATE_PROPOSAL_VOTES';
export const UPDATE_TIME = 'UPDATE_TIME';
export const UPDATE_PUBLIC_DISCUSSION_TIME = 'UPDATE_PUBLIC_DISCUSSION_TIME';
export const UPDATE_BEFORE_DAY_ORDER_TIME = 'UPDATE_BEFORE_DAY_ORDER_TIME';
export const UPDATE_POINT_DISCUSSION_TIME = 'UPDATE_POINT_DISCUSSION_TIME';
export const UPDATE_MEETING_TIME = 'UPDATE_MEETING_TIME';
export const TOGGLE_SPEAKING = 'TOGGLE_SPEAKING';
export const UPDATE_GROUP_MEMBER_TIME = 'UPDATE_GROUP_MEMBER_TIME';
export const UPDATE_MEMBER_TIME = 'UPDATE_MEMBER_TIME';
export const UPDATE_GROUP_OTHERS_TIME = 'UPDATE_GROUP_OTHERS_TIME';
export const UPDATE_GROUP_USERS_POINT_SPEAK = 'UPDATE_GROUP_USERS_POINT_SPEAK';
export const UPDATE_PUBLIC_TIME = 'UPDATE_PUBLIC_TIME';
export const UPDATE_OTHERS_TIME = 'UPDATE_OTHERS_TIME';
export const UPDATE_THEME_POINT_STATUS = 'UPDATE_THEME_POINT_STATUS';
export const UPDATE_POINT_STATUS = 'UPDATE_POINT_STATUS';
export const UPDATE_INTERVENTIONS_TIME = 'UPDATE_INTERVENTIONS_TIME';
export const UPDATE_PARTICIPANT_POINT_DELIBERATION = 'UPDATE_PARTICIPANT_POINT_DELIBERATION';
export const UPDATE_PARTICIPANT_THEME_POINT_DELIBERATION =
  'UPDATE_PARTICIPANT_THEME_POINT_DELIBERATION';
//! Socket reducer / actions types
export const SOCKET_REUNIONS = 'SOCKET_REUNIONS';
export const CREATED_REUNION = 'CREATED_REUNION';
export const UPDATE_CREATED = 'UPDATE_CREATED';
export const CLEAN_ONGOING = 'CLEAN_ONGOING';
export const REMOVE_REUNION = 'REMOVE_REUNION';
//! Error reducer / actions types
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';
//! Card reducer / actions types
export const UPDATE_CARD_INFO = 'UPDATE_CARD_INFO';
export const CLEAN_CARD_INFO = 'CLEAN_CARD_INFO';
export const CARD_LOADING = 'CARD_LOADING';
export const CARD_NOT_LOADING = 'CARD_NOT_LOADING';
export const NOT_VALID_READ = 'NOT_VALID_READ';
export const VALID_READ = 'VALID_READ';

export const CLEAR_ALL_STATE = 'CLEAR_ALL_STATE';
