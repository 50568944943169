import isEmail from 'validator/lib/isEmail';

const validEmail = email => {
  if (email) {
    return isEmail(email);
  }

  return false;
};

export default validEmail;
