import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import clsx from 'clsx';
import { Remove, Check } from '@material-ui/icons';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  checkbox: {
    marginLeft: 'auto',
    minWidth: '16px',
    minHeight: '16px',
    maxWidth: '16px',
    maxHeight: '16px',
    border: `solid 1px ${theme.palette.neutrals[5]}`,
    transition: 'background-color 0.2s ease',
    '&:hover': {
      border: 'none',
      cursor: 'pointer',
      backgroundColor: theme.palette.neutrals[4],
    },
  },
  checkboxChecked: {
    border: `0px solid ${theme.palette.colorsPalette.TrueGreen}`,
    transition: '.2s',
    backgroundColor: theme.palette.colorsPalette.TrueGreen,
  },
  circle: {
    borderRadius: '50%',
  },
  statusIcon: {
    width: '16px',
    height: '16px',
    color: theme.palette.neutrals[1],
  },
}));

const BoxCheckbox = ({ checked, circle, onClick, indeterminate, allCheck }) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.checkbox, {
        [classes.checkboxChecked]: checked,
        [classes.circle]: circle,
      })}
      onClick={onClick}
    >
      {allCheck ? <Check className={classes.statusIcon} /> : null}
      {indeterminate && !allCheck ? <Remove className={classes.statusIcon} /> : null}
    </Box>
  );
};

BoxCheckbox.defaultProps = {
  circle: false,
  indeterminate: false,
  allCheck: false,
};

BoxCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  circle: PropTypes.bool,
  indeterminate: PropTypes.bool,
  allCheck: PropTypes.bool,
};

export default React.memo(BoxCheckbox);
