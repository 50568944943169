import React, { useEffect } from 'react';
import { Grid, Drawer } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { changeMenuSidebar } from '../../store/actions/navigationActions';
import { makeStyles } from '@material-ui/core/styles';
import H5 from '../common/typographys/H5';
import ProfileInfo from './ProfileInfo';
import PersonalProfileProvider from './PersonalProfileProvider';
import isEmpty from '../../utils/isEmpty';
import StatBlock from './StatBlock';
import { getPublicOrganicUnits } from '../../store/actions/organicUnitsActions';
import { getUserStats } from '../../store/actions/authActions';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
  },
  paper: {
    height: '100%',
    width: '100%',
  },
  drawer: {
    width: '100%',
    height: '100%',
    flexShrink: 0,
  },
  drawerPaper: {
    width: '100%',
    position: 'static',
    marginLeft: '12px',
    paddingTop: '12px',
    overflowX: 'hidden',
  },
  noPadding: {
    padding: '0px!important',
  },
  header: {
    marginBottom: '20px',
  },
  marginContainer: {
    marginTop: '54px',
  },
}));

const PersonalProfile = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { user } = useSelector(state => state.auth);
  const { stats = [] } = user;

  useEffect(() => {
    dispatch(changeMenuSidebar('perfilPessoal', 'Perfil pessoal'));
    dispatch(getPublicOrganicUnits());
    dispatch(getUserStats());
  }, [dispatch]);

  return (
    <Grid container spacing={3} className={classes.container}>
      <Grid item xs={1} />
      <Grid item xs={7} className={classes.marginContainer}>
        <H5 className={classes.header}>Estatísticas gerais</H5>
        <Grid container spacing={2}>
          {stats.map(({ label, value }) => (
            <StatBlock key={label} label={label} value={value} />
          ))}
        </Grid>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={3} className={classes.noPadding}>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          anchor="right"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {!isEmpty(user) && (
            <PersonalProfileProvider user={user}>
              <ProfileInfo />
            </PersonalProfileProvider>
          )}
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default PersonalProfile;
