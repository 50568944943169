import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const PrivateRoute = ({ component: Component, computedMatch, ...rest }) => {
  const auth = useSelector(state => state.auth);

  const { url } = computedMatch;
  const redirection = url ? `?voltar-para=${url}` : '/';

  return <Route {...rest} render={props => (auth.isAuthenticated ? <Component {...props} /> : <Redirect to={redirection} />)} />;
};

PrivateRoute.defaultProps = {
  computedMatch: {},
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  computedMatch: PropTypes.object,
};

export default PrivateRoute;
