import axios from 'axios';
import {
  GET_ERRORS,
  UPDATE_CARD_INFO,
  CLEAN_CARD_INFO,
  CARD_LOADING,
  CARD_NOT_LOADING,
  NOT_VALID_READ,
  VALID_READ,
} from './types';

export const getCitizenCardInfo = (resolve, reject) => dispatch => {
  dispatch(setCardLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/citizen_card`)
    .then(res => {
      dispatch({
        type: UPDATE_CARD_INFO,
        payload: res.data,
      });
      resolve();
    })
    .catch(err => {
      dispatch(setCardNotLoading());
      dispatch(notValidRead());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      reject();
    });
};

export const validateCardCheckin = (meetingId, userId, resolve, reject) => dispatch => {
  dispatch(setCardLoading());

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/meetings/${meetingId}/checkin`, { user_id: userId })
    .then(() => {
      dispatch({
        type: VALID_READ,
      });
      resolve();
    })
    .catch(err => {
      dispatch(setCardNotLoading());
      dispatch(notValidRead());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      reject();
    });
};

export const clearCardInfo = () => {
  return {
    type: CLEAN_CARD_INFO,
  };
};

export const setCardLoading = () => {
  return {
    type: CARD_LOADING,
  };
};

export const setCardNotLoading = () => {
  return {
    type: CARD_NOT_LOADING,
  };
};

export const notValidRead = () => {
  return {
    type: NOT_VALID_READ,
  };
};
