import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Subtitle1 from '../../common/typographys/Subtitle1';
import clsx from 'clsx';
import getStatusBySlug from '../../../utils/getStatusBySlug';

const useStyles = makeStyles(theme => ({
  box: {
    borderRadius: '4px',
    marginLeft: 'auto',
    maxWidth: '250px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
  },
  green: {
    backgroundColor: theme.palette.colorsPalette.TrueGreen,
  },
  red: {
    backgroundColor: theme.palette.colorsPalette.RustRed,
  },
  orange: {
    backgroundColor: theme.palette.colorsPalette.Orange,
  },
}));

const ProposalStatus = ({ status }) => {
  const classes = useStyles();
  const statusSlug = getStatusBySlug(status ? status.slug : '');

  return (
    <Box
      className={clsx(classes.box, {
        [classes.green]: statusSlug === 'approved',
        [classes.red]: statusSlug === 'disapproved',
        [classes.orange]: statusSlug === 'standby',
      })}
    >
      <Subtitle1 selected>{status && status.label}</Subtitle1>
    </Box>
  );
};

ProposalStatus.propTypes = {
  status: PropTypes.object.isRequired,
};

export default React.memo(ProposalStatus);
