import React, { useContext, useEffect } from 'react';
import AddNewItem from '../../pieces/AddNewItem';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import { MatrixStateContext, MatrixDispatchContext } from './CreateEditMatrixProvider';
import FormInfoMatrix from './FormInfoMatrix';

const AddNewMatrix = () => {
  const {
    state: { errors, items },
    handleSubmit,
    loading,
  } = useContext(MatrixStateContext);
  const dispatch = useContext(MatrixDispatchContext);

  useEffect(() => {
    dispatch({
      type: 'CLEAR_STATE',
    });
  }, [dispatch]);

  return (
    <AddNewItem
      disableSubmit={checkEmptyObject(errors).length > 0}
      items={items}
      loading={loading}
      dispatch={dispatch}
      handleSubmit={handleSubmit}
      msgSubmit="Tem a certeza que deseja gravar a nova matriz?"
    >
      <FormInfoMatrix />
    </AddNewItem>
  );
};

export default React.memo(AddNewMatrix);
