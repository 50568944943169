import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import Body1 from '../../../../common/typographys/Body1';
import isEmpty from '../../../../../utils/isEmpty';
import { BlockPicker } from 'react-color';
import PropTypes from 'prop-types';
import useBooleanToggle from '../../../../../utils/hooks/useBooleanToggle';

const colors = ['#5a7d7a', '#324745', '#8ba4a1', '#d4e074', '#223230', '#c6ae29', '#bf7400', '#f66f0d', '#13a600', '#be0404'];

const useStyles = makeStyles(theme => ({
  mainBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 10px',
    border: `solid 1px ${theme.palette.neutrals[3]}`,
    marginBottom: '20px',
  },
  blockPicker: {
    boxShadow: `0 20px 20px -10px ${theme.palette.primary[100]}!important`,
    borderRadius: '0!important',
    '& input': {
      display: 'none',
    },
    '& div div[style*="font-size: 18px;"]': {
      display: 'none',
    },
    '& div[style*="border-radius: 6px 6px 0px 0px;"]': {
      borderRadius: '0!important',
    },
  },
  boxColor: {
    height: '20px',
    width: '20px',
    border: `solid 1px ${theme.palette.neutrals[3]}`,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  popover: {
    position: 'absolute',
    zIndex: '2',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
}));

const TypologyColor = ({ color, updateField }) => {
  const classes = useStyles();
  const [open, toggleOpen] = useBooleanToggle();

  return (
    <Box className={classes.mainBox}>
      <Body1 secondary>{!isEmpty(color) ? color : 'Selecionar cor'}</Body1>
      <Box className={classes.boxColor} bgcolor={color} onClick={toggleOpen}>
        {open && (
          <Box className={classes.popover} onClick={toggleOpen}>
            <Box className={classes.cover} onClick={toggleOpen} />
            <BlockPicker
              color={color}
              className={classes.blockPicker}
              triangle="hide"
              onChange={val => updateField('color', val.hex)}
              colors={colors}
              onChangeComplete={toggleOpen}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

TypologyColor.defaultProps = {
  color: '#5a7d7a',
};

TypologyColor.propTypes = {
  updateField: PropTypes.func.isRequired,
  color: PropTypes.string,
};

export default React.memo(TypologyColor);
