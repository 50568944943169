import isEmpty from './isEmpty';

const validateNIF = (nif, type) => {
  let comparador = 0;
  if (isEmpty(nif)) {
    return false;
  }

  const strNumFiscal = nif.toString(10);
  if (strNumFiscal.length === 0) {
    return true;
  }

  if (strNumFiscal.length !== 9) {
    return false;
  }

  if (strNumFiscal[0] === '0' || strNumFiscal[0] === '3' || strNumFiscal[0] === '4') {
    return false;
  }

  if ((strNumFiscal[0] === '1' || strNumFiscal[0] === '2') && type === '2') {
    return false;
  }

  if ((strNumFiscal[0] === '5' || strNumFiscal[0] === '6' || strNumFiscal[0] === '8') && type === '1') {
    return false;
  }

  for (let i = 0; i < 8; i++) {
    if (strNumFiscal.slice(i, i + 1) !== '') {
      comparador += strNumFiscal.slice(i, i + 1) * (9 - i);
    }
  }

  comparador = 11 - (comparador % 11);

  if (comparador > 9) {
    comparador = 0;
  }

  if (comparador.toString() !== strNumFiscal.slice(8, 9)) {
    return false;
  }

  return true;
};

export default validateNIF;
