import React, { useMemo } from 'react';
import { makeStyles, Box, Paper, Button } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import Subtitle1 from '../../../../common/typographys/Subtitle1';
import PropTypes from 'prop-types';
import ProposalTitleAndBody from './ProposalTitleAndBody';
import PointFiles from './PointFiles';
import PointMatrix from './PointMatrix';
import ProposalStatusInfo from './ProposalStatusInfo';
import Body2 from '../../../../common/typographys/Body2';
import isEmpty from '../../../../../utils/isEmpty';
import PointNotes from './PointNotes';
import UsersDeclaration from './UsersDeclaration';

const useStyles = makeStyles(theme => ({
  marginHeader: {
    marginBottom: '20px',
  },
  closeInfo: {
    color: theme.palette.primary[600],
    width: '16px',
    height: '16px',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary[900],
    },
  },
  selectedItem: {
    padding: '30px',
  },
  boxInfo: {
    padding: '20px',
    backgroundColor: theme.palette.primary[50],
  },
  buttonConfirm: {
    color: theme.palette.neutrals[1],
    backgroundColor: theme.palette.primary.A400,
    '&:hover': {
      color: theme.palette.neutrals[1],
      backgroundColor: theme.palette.primary.A700,
    },
  },
  boxResult: {
    marginTop: '10px',
  },
  labelResult: {
    padding: '5px 0px 0px 0px',
    marginBottom: '6px',
  },
}));

const ViewPoint = ({
  selected,
  handleChangeActive,
  handleInfo,
  isSelectedPoint,
  idxProposal,
  moderator,
  reunionEnd,
  users,
  isGroup,
  disabled,
}) => {
  const classes = useStyles();
  const selectedHasStatus = useMemo(
    () =>
      selected &&
      selected.meeting_point_status &&
      selected.meeting_point_status.point_status !== null,
    [selected]
  );

  const hasDeclaration = useMemo(
    () =>
      selected &&
      selected.meeting_point_status &&
      selected.meeting_point_status.declaration &&
      selected.meeting_point_status.declaration.length > 0,
    [selected]
  );

  const label = useMemo(() => {
    if (isEmpty(selected.type)) {
      if (isSelectedPoint) {
        return 'Colocar ponto para discussão';
      }

      return 'Colocar tema para discussão';
    }

    if (!isEmpty(selected.type)) {
      return 'Colocar período para discussão';
    }

    return 'Colocar para discussão';
  }, [isSelectedPoint, selected]);

  return (
    <Box className={classes.boxInfo}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={classes.marginHeader}
      >
        <Subtitle1>Pré-visualização</Subtitle1>
        <CloseRounded className={classes.closeInfo} onClick={handleInfo({})} />
      </Box>
      <Paper className={classes.selectedItem}>
        <ProposalTitleAndBody isPoint={isSelectedPoint} info={selected} idxProposal={idxProposal} />
        {moderator && <PointMatrix matrix={selected.matrix} />}
        <PointFiles files={selected.attachments} proposalId={selected.proposal_id} />
        {selected.attachments && selected.attachments.length > 0 && moderator && (
          <Box height="20px" />
        )}
        {moderator && <PointNotes key={selected.id || selected.type} info={selected} />}
      </Paper>
      {selectedHasStatus && (
        <Box className={classes.boxResult}>
          {moderator && hasDeclaration && (
            <UsersDeclaration
              users={users}
              declaration={selected.meeting_point_status.declaration}
              isGroup={Boolean(isGroup)}
            />
          )}
          <Body2 secondary className={classes.labelResult}>
            Resultado da proposta
          </Body2>
          <ProposalStatusInfo
            pointStatus={selected.meeting_point_status}
            users={users}
            isGroup={isGroup}
          />
        </Box>
      )}
      {moderator && !reunionEnd && !selectedHasStatus && (
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            fullWidth
            className={classes.buttonConfirm}
            onClick={handleChangeActive}
            disabled={disabled}
          >
            {label}
          </Button>
        </Box>
      )}
    </Box>
  );
};

ViewPoint.defaultProps = {
  isSelectedPoint: false,
  moderator: false,
  handleChangeActive: () => null,
  idxProposal: null,
  reunionEnd: false,
  users: [],
  isGroup: false,
  disabled: false,
};

ViewPoint.propTypes = {
  selected: PropTypes.object.isRequired,
  handleInfo: PropTypes.func.isRequired,
  handleChangeActive: PropTypes.func,
  isSelectedPoint: PropTypes.bool,
  moderator: PropTypes.bool,
  idxProposal: PropTypes.number,
  reunionEnd: PropTypes.bool,
  users: PropTypes.array,
  isGroup: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default React.memo(ViewPoint);
