import { GET_PROFILES, GET_PROFILE, UPDATE_PROFILE, PROFILES_LOADING, PROFILES_NOT_LOADING, CREATE_PROFILE, REMOVE_PROFILE } from '../actions/types';

const initialState = {
  profiles: [],
  profile: {},
  loading: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PROFILES:
      return {
        ...state,
        profiles: action.payload,
        loading: false,
      };
    case GET_PROFILE:
      return {
        ...state,
        profile: action.payload,
        loading: false,
      };
    case CREATE_PROFILE:
      return {
        ...state,
        profiles: [action.payload, ...state.profiles],
        loading: false,
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        profiles: state.profiles.map(x => (x.id === action.payload.id ? action.payload : x)),
        loading: false,
      };
    case REMOVE_PROFILE:
      return {
        ...state,
        profiles: state.profiles.filter(x => x.id !== action.payload.id),
        loading: false,
      };
    case PROFILES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PROFILES_NOT_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
