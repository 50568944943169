import React, { useContext, useEffect } from 'react';
import AddNewItem from '../../pieces/AddNewItem';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import { LocalDispatchContext, LocalStateContext } from './CreateEditLocalProvider';
import FormInfoLocal from './FormInfoLocal';

const AddNewLocal = () => {
  const {
    state: { errors, items },
    handleSubmit,
    loading,
  } = useContext(LocalStateContext);
  const dispatch = useContext(LocalDispatchContext);

  useEffect(() => {
    dispatch({
      type: 'CLEAR_STATE',
    });
  }, [dispatch]);

  return (
    <AddNewItem
      disableSubmit={checkEmptyObject(errors).length > 0}
      items={items}
      loading={loading}
      dispatch={dispatch}
      handleSubmit={handleSubmit}
      msgSubmit="Tem a certeza que deseja gravar o novo local?"
    >
      <FormInfoLocal />
    </AddNewItem>
  );
};

export default React.memo(AddNewLocal);
