import React, { useCallback, useMemo } from 'react';
import { makeStyles, Dialog, DialogContent, fade, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import ProposalInfoDetails from '../../proposals/check/ProposalInfoDetails';
import ButtonNormal from '../buttons/ButtonNormal';
import Link from '../routing/Link';

const useStyles = makeStyles(theme => ({
  content: {
    padding: '30px',
    backgroundColor: fade(theme.palette.neutrals[3], 0.3),
  },
  marginButton: {
    marginTop: '20px',
  },
}));

const DialogCheckPoint = ({ open, setPoint, point }) => {
  const classes = useStyles();
  const isDefault = useMemo(() => point && point.default, [point]);

  const handleClose = useCallback(
    e => {
      e.preventDefault();
      setPoint({});
    },
    [setPoint]
  );

  return (
    <Dialog scroll="body" fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogContent className={classes.content}>
        <ProposalInfoDetails proposal={point} dialog />
        <Grid container spacing={2} justify="flex-end" className={classes.marginButton}>
          {!isDefault && (
            <Grid item>
              <Link
                to={`/propostas/visualizar/${point.proposal_id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ButtonNormal
                  variant="outlined"
                  color="primary"
                  label="Consultar proposta"
                  onClick={() => null}
                />
              </Link>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

DialogCheckPoint.defaultProps = {
  point: {},
};

DialogCheckPoint.propTypes = {
  setPoint: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  point: PropTypes.object,
};

export default React.memo(DialogCheckPoint);
