import React, { useContext, useMemo } from 'react';
import {
  makeStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  Box,
  ExpansionPanelDetails,
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { GeneralConfigFuncsContext } from '../../CreateEditGeneralConfigProvider';
import InputField from '../../../../../common/forms/InputField';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/pt';
import Subtitle2 from '../../../../../common/typographys/Subtitle2';
import TemplateBox from './TemplateBox';
import { ExpandMore } from '@material-ui/icons';
import Body2 from '../../../../../common/typographys/Body2';
import getEmailTemplateLabel from '../../../../../../utils/getEmailTemplateLabel';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  label: {
    marginBottom: '8px',
  },
  content: {
    margin: 0,
  },
  selected: {
    backgroundColor: theme.palette.neutrals[2],
  },
  tagLabel: {
    marginTop: '8px',
  },
}));

const EditTemplateInfo = ({ email, idxTemplate, subExpanded, handleSubChange, handlePreview }) => {
  const classes = useStyles();
  const { updateEmailSubject, updateEmailContent } = useContext(GeneralConfigFuncsContext);
  const { type, id, subject, content, available_variables } = email;
  const tags = useMemo(() => available_variables.map(tag => `{{${tag}}}`), [available_variables]);

  return (
    <ExpansionPanel
      expanded={subExpanded}
      onChange={handleSubChange(`email-${id}`)}
      TransitionProps={{ unmountOnExit: true }}
      className={clsx({ [classes.selected]: subExpanded })}
    >
      <ExpansionPanelSummary
        classes={{ content: classes.content }}
        expandIcon={<ExpandMore />}
        aria-controls={`email-${id}-content`}
        id={`email-${id}-header`}
      >
        <Body2 secondary={subExpanded}>{getEmailTemplateLabel(type)}</Body2>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <TemplateBox type={type} hideType>
          <InputField
            name={`subject-${id}`}
            label="Assunto"
            value={subject || ''}
            onChange={e => updateEmailSubject(idxTemplate, id, e.target.value)}
          />
          <Subtitle2 className={classes.label}>Conteúdo</Subtitle2>
          <CKEditor
            editor={ClassicEditor}
            data={content}
            config={{
              language: 'pt',
              toolbar: [
                'bold',
                'italic',
                '|',
                'undo',
                'redo',
                '|',
                'bulletedList',
                'numberedList',
                '|',
                'link',
                'blockQuote',
              ],
              removePlugins: ['Heading'],
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              updateEmailContent(idxTemplate, id, data);
            }}
          />
          <Subtitle2 className={classes.tagLabel}>
            Variáveis disponíveis: {tags.length > 0 ? `${tags.join(', ')}.` : 'Nenhuma.'}
          </Subtitle2>
          <Box height="10px" />
          <Button variant="outlined" color="primary" onClick={handlePreview(content)}>
            Pré-visualizar
          </Button>
        </TemplateBox>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

EditTemplateInfo.propTypes = {
  email: PropTypes.object.isRequired,
  idxTemplate: PropTypes.number.isRequired,
  subExpanded: PropTypes.bool.isRequired,
  handleSubChange: PropTypes.func.isRequired,
  handlePreview: PropTypes.func.isRequired,
};

export default React.memo(EditTemplateInfo);
