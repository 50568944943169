import React, { useEffect } from 'react';
import FormRecover from './pieces/FormRecover';
import Info from './pieces/Info';
import LoginRegisterSchema from '../../common/layout/LoginRegisterSchema';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getConfig } from '../../../store/actions/configActions';

const Recover = () => {
  const { isAuthenticated } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getConfig());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      const {
        location: { search },
      } = history;
      if (search.indexOf('?voltar-para=') !== -1) {
        const urlLocation = search.substr(search.indexOf('?voltar-para=') + 13, search.length);
        history.push(urlLocation);
      } else {
        history.push('/dashboard');
      }
    }
  }, [isAuthenticated, history]);

  return <LoginRegisterSchema info={<Info />} form={<FormRecover />} />;
};

export default Recover;
