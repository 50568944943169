import React, { useContext, useState, useCallback, useEffect } from 'react';
import { makeStyles, Grid, Box, fade } from '@material-ui/core';
import H5 from '../../common/typographys/H5';
import { CheckProposalStateContext, CheckProposalFuncsContext } from './CheckProposalProvider';
import Body2 from '../../common/typographys/Body2';
import Body1 from '../../common/typographys/Body1';
import formatDate from '../../../utils/formatDate';
import clsx from 'clsx';
import isEmpty from '../../../utils/isEmpty';
import { useDispatch } from 'react-redux';
import { changeActiveVersion } from '../../../store/actions/proposalsActions';
import { useTheme } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  header: {
    marginBottom: '60px',
  },
  marginTop: {
    marginTop: '30px',
  },
  mainBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 10px 12px 14px',
    backgroundColor: theme.palette.neutrals[3],
    marginBottom: '8px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  selectedBox: {
    backgroundColor: theme.palette.primary[100],
    borderLeft: `6px solid ${theme.palette.primary.A400}`,
    padding: '12px 10px 12px 8px',
  },
  date: {
    marginLeft: '11px',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const ProposalVersions = () => {
  const classes = useStyles();
  const dispatchRedux = useDispatch();
  const [selected, setSelected] = useState(0);
  const [meetingSelected, setMeetingSelected] = useState(null);
  const {
    state: { versions, meetings },
  } = useContext(CheckProposalStateContext);
  const { updateField } = useContext(CheckProposalFuncsContext);
  const theme = useTheme();

  const handleChange = useCallback(
    (idxBox, isMeeting) => e => {
      e.preventDefault();
      if (isMeeting) {
        const proposalMeeting = meetings[idxBox];
        setMeetingSelected(idxBox);
        setSelected(null);
        dispatchRedux(
          changeActiveVersion({
            ...versions[versions.length - 1],
            meetingPointStatus: proposalMeeting.meeting_point_status,
            status: proposalMeeting.meeting_point_status.status,
            meetingName: proposalMeeting.name,
            meetingId: proposalMeeting.id,
            meetingUsers: proposalMeeting.users,
            meetingIsGroup: proposalMeeting.is_group,
            meetingPointId: proposalMeeting.point_id,
            isMeeting: Boolean(isMeeting),
          })
        );
      } else {
        setSelected(idxBox);
        setMeetingSelected(null);
        dispatchRedux(changeActiveVersion(versions[idxBox]));
      }
      updateField('open', '');
    },
    [versions, meetings, dispatchRedux, updateField]
  );

  useEffect(() => {
    if (!isEmpty(versions)) {
      setSelected(versions.length - 1);
      updateField('open', '');
      updateField('hasVersions', versions.length > 1);
      updateField('lastVersionId', versions[versions.length - 1].id);
    }
  }, [versions, updateField]);

  useEffect(() => {
    if (!isEmpty(meetings)) {
      updateField('hasMeetings', true);
    }
  }, [meetings, updateField]);

  const getStyle = useCallback(
    (color, isSelected) => {
      if (color) {
        return { backgroundColor: fade(color, isSelected ? 0.4 : 0.2) };
      }

      if (isSelected) {
        return { backgroundColor: theme.palette.primary[100] };
      }

      return { backgroundColor: theme.palette.neutrals[3] };
    },
    [theme.palette]
  );

  return (
    <Grid item xs={2}>
      <Grid container spacing={1}>
        <Grid item xs={11}>
          {versions.length > 0 && (
            <>
              <H5 className={classes.header}>Histórico de versões</H5>
              {versions.map((version, idx) => (
                <Box
                  key={version.id}
                  className={clsx(classes.mainBox, { [classes.selectedBox]: selected === idx })}
                  onClick={handleChange(idx, false)}
                >
                  <Grid container spacing={1}>
                    <Grid item className={classes.flexCenter}>
                      <Body2>V{(idx + 1).toString().padStart(2, '0')}</Body2>
                    </Grid>
                    <Grid item className={classes.flexCenter}>
                      <Body1>{formatDate(version.created_at)}</Body1>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </>
          )}
          {meetings.length > 0 && (
            <>
              <H5 className={clsx(classes.header, classes.marginTop)}>Histórico de reuniões</H5>
              {meetings.map((meeting, idx) => (
                <Box
                  key={meeting.id}
                  className={clsx(classes.mainBox, {
                    [classes.selectedBox]: meetingSelected === idx,
                  })}
                  style={getStyle(meeting.color, meetingSelected === idx)}
                  onClick={handleChange(idx, true)}
                >
                  <Grid container spacing={1}>
                    <Grid item className={classes.flexCenter}>
                      <Body2>R{(idx + 1).toString().padStart(2, '0')}</Body2>
                    </Grid>
                    <Grid item className={classes.flexCenter}>
                      <Body1>{formatDate(meeting.date)}</Body1>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(ProposalVersions);
