import axios from 'axios';
import { GET_EMAILS, GET_EMAIL, CREATE_EMAIL, UPDATE_EMAIL, GET_ERRORS, SHOW_SNACK, EMAILS_LOADING, EMAILS_NOT_LOADING, REMOVE_EMAIL } from './types';

export const getEmailTemplates = () => dispatch => {
  dispatch(setEmailsLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/email_templates`)
    .then(res => {
      dispatch({
        type: GET_EMAILS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(setEmailsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getEmail = id => dispatch => {
  dispatch(setEmailsLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/email_templates/${id}`)
    .then(res => {
      dispatch({
        type: GET_EMAIL,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(setEmailsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const createEmail = info => dispatch => {
  dispatch(setEmailsLoading());

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/email_templates`, info)
    .then(res => {
      dispatch({
        type: CREATE_EMAIL,
        payload: res.data,
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Template de email criado com sucesso.',
        },
      });
    })
    .catch(err => {
      dispatch(setEmailsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateEmailTemplates = (info, resolve, reject) => dispatch => {
  dispatch(setEmailsLoading());

  axios
    .put(`${process.env.REACT_APP_API_URL}/api/email_templates`, info)
    .then(() => {
      resolve();

      dispatch(getEmailTemplates());
    })
    .catch(err => {
      dispatch(setEmailsNotLoading());
      reject(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateEmail = (id, info) => dispatch => {
  dispatch(setEmailsLoading());

  axios
    .put(`${process.env.REACT_APP_API_URL}/api/email_templates/${id}`, info)
    .then(res => {
      dispatch({
        type: UPDATE_EMAIL,
        payload: res.data,
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Template de email atualizado com sucesso.',
        },
      });
    })
    .catch(err => {
      dispatch(setEmailsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const removeEmail = (id, callback) => dispatch => {
  dispatch(setEmailsLoading());

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/email_templates/${id}/deactivate`)
    .then(() => {
      dispatch({
        type: REMOVE_EMAIL,
        payload: {
          id,
        },
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Template de email excluído com sucesso.',
        },
      });
      callback();
    })
    .catch(err => {
      dispatch(setEmailsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const setEmailsLoading = () => {
  return {
    type: EMAILS_LOADING,
  };
};

export const setEmailsNotLoading = () => {
  return {
    type: EMAILS_NOT_LOADING,
  };
};
