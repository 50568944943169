import React, { useCallback, useRef, useState, useEffect } from 'react';
import { makeStyles, Input, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
// import Body1 from '../../common/typographys/Body1';

const useStyles = makeStyles(theme => ({
  link: {
    // textDecoration: 'none',
    // color: 'inherit',
    // '&:hover': {
    //   '& span': {
    //     color: theme.palette.neutrals[6],
    //   },
    // },
    '& textarea': {
      color: `${theme.palette.neutrals[7]}!important`,
      textDecoration: 'underline',
      opacity: '1!important',
      '&:hover': {
        cursor: 'pointer',
        color: `${theme.palette.primary.A700}!important`,
      },
    },
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textDecoration: 'underline',
    display: 'inline-block',
    width: '100%',
  },
}));

const ProposalInternalLink = ({ link /* , text */ }) => {
  const classes = useStyles();
  const linkRef = useRef(null);
  const [open, setOpen] = useState(false);

  const onClick = useCallback(e => {
    setOpen(true);
    e.preventDefault();
    linkRef.current.select();
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
  }, []);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false);
      }, 1000);
    }
  }, [open]);

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      onClose={() => setOpen(false)}
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      placement="top"
      title="Link copiado"
    >
      <Input
        inputRef={linkRef}
        value={link}
        onClick={onClick}
        className={classes.link}
        disableUnderline
        multiline
        fullWidth
        spellCheck={false}
      />
    </Tooltip>
  );
};

ProposalInternalLink.defaultProps = {
  link: '',
  // text: null,
};

ProposalInternalLink.propTypes = {
  link: PropTypes.string,
  // text: PropTypes.string,
};

export default React.memo(ProposalInternalLink);
