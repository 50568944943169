import React, { useEffect, useCallback } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { changeMenuSidebar } from '../../store/actions/navigationActions';
import { useDispatch, useSelector } from 'react-redux';
import H5 from '../common/typographys/H5';
import H6 from '../common/typographys/H6';
import NotificationEntry from '../common/layout/NotificationEntry';
import { useHistory } from 'react-router-dom';
import { clickUserNotification } from '../../store/actions/notificationsActions';
import appSchemaStyles from '../common/layout/styles/appSchema';
import usePagination from '../../utils/hooks/usePagination';
import Pagination from '../common/SimplePagination';

const useStyles = makeStyles(() => ({
  marginContainer: {
    marginTop: '54px',
  },
  header: {
    marginBottom: '20px',
  },
}));

const Notifications = () => {
  const classes = useStyles();
  const notificationsClasses = appSchemaStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { notifications } = useSelector(state => state.notifications);
  const [showPagination, itemShown, page, setPage] = usePagination(10, notifications);

  useEffect(() => {
    dispatch(changeMenuSidebar('notificacoes', 'Notificações'));
  }, [dispatch]);

  const clickNotification = useCallback(
    (url, id) => e => {
      e.preventDefault();
      dispatch(clickUserNotification(id));
      if (url) {
        history.push(url);
      }
    },
    [dispatch, history]
  );

  return (
    <Grid container justify="center" spacing={3} className={classes.marginContainer}>
      <Grid item xs={11} md={9} lg={7}>
        <H5 className={classes.header}>As suas notificações</H5>
        {notifications.length === 0 ? (
          <H6>Não existem notificações.</H6>
        ) : (
          itemShown.map(item => (
            <NotificationEntry
              key={item.id}
              classes={notificationsClasses}
              onClick={clickNotification}
              {...item}
              margin
              showDate
            />
          ))
        )}
        {showPagination && (
          <Pagination
            total={notifications.length}
            onChangePage={(_, pageNum) => {
              setPage(pageNum);
            }}
            rowsPerPage={10}
            page={page}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(Notifications);
