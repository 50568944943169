const getEmailTemplateLabel = type => {
  switch (type) {
    case 'InviteUser':
      return 'Email enviado quando é feito um convite para o utilizador se registar na plataforma';
    case 'ResetPassword':
      return 'Email enviado quando o utilizador faz um pedido para repor a palavra-passe';
    case 'VerifyEmail':
      return 'Email enviado para o utilizador validar o seu e-mail, após registo';
    case 'ProposalDecision':
      return 'Email que notifica o proponente do resultado de análise de proposta';
    case 'ProposalNewVersion':
      return 'Email para notificar o aprovador acerca da criação de uma nova versão de proposta';
    case 'ProposalWaitingDecision':
      return 'Email para notificar o aprovador que possui uma proposta pendente de aprovação';
    case 'ProposalWaitingRevision':
      return 'Email para notificar o aprovador que possui uma decisão pendente de revisão';
    case 'BriefAvailable':
      return 'Email enviado para indicar que a agenda da reunião já se encontra disponível';
    case 'MeetingInvite':
      return 'Email com notificação de convite para participar numa reunião';
    case 'MeetingPointResult':
      return 'Email com o resultado de deliberação de um ponto em reunião';
    case 'MeetingUpdate':
      return 'Email enviado para participantes com a notificação de que a reunião foi atualizada';
    case 'TypologyInvite':
      return 'Email que notifica um utilizador que foi associado a uma tipologia';
    case 'Template':
      return 'Template do esqueleto do email';
    case 'Button':
      return 'Template do botão para ser inserido no email';
    default:
      return 'Email geral';
  }
};

export default getEmailTemplateLabel;
