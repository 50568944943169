import axios from 'axios';
import {
  GET_ENTITIES,
  CREATE_ENTITY,
  UPDATE_ENTITY,
  GET_ERRORS,
  SHOW_SNACK,
  ENTITIES_LOADING,
  ENTITIES_NOT_LOADING,
  GET_ENTITY_PROPOSALS,
  REMOVE_ENTITY,
} from './types';

export const getEntities = () => dispatch => {
  dispatch(setEntitiesLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/entities`)
    .then(res => {
      dispatch({
        type: GET_ENTITIES,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(setEntitiesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getEntityProposals = id => dispatch => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/entities/${id}/proposals`)
    .then(res => {
      dispatch({
        type: GET_ENTITY_PROPOSALS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const createEntity = info => dispatch => {
  dispatch(setEntitiesLoading());

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/entities`, info)
    .then(res => {
      dispatch({
        type: CREATE_ENTITY,
        payload: { ...res.data, can_delete: true },
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Entidade criada com sucesso.',
        },
      });
    })
    .catch(err => {
      dispatch(setEntitiesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateEntity = (id, info) => dispatch => {
  dispatch(setEntitiesLoading());

  axios
    .put(`${process.env.REACT_APP_API_URL}/api/entities/${id}`, info)
    .then(res => {
      dispatch({
        type: UPDATE_ENTITY,
        payload: res.data,
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Entidade atualizada com sucesso.',
        },
      });
    })
    .catch(err => {
      dispatch(setEntitiesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const removeEntity = (id, callback) => dispatch => {
  dispatch(setEntitiesLoading());

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/entities/${id}/disable`)
    .then(() => {
      dispatch({
        type: REMOVE_ENTITY,
        payload: {
          id,
        },
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Entidade excluída com sucesso.',
        },
      });
      callback();
    })
    .catch(err => {
      dispatch(setEntitiesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const setEntitiesLoading = () => {
  return {
    type: ENTITIES_LOADING,
  };
};

export const setEntitiesNotLoading = () => {
  return {
    type: ENTITIES_NOT_LOADING,
  };
};
