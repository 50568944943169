import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { DragHandle } from '@material-ui/icons';
import Body1 from '../../../../common/typographys/Body1';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  iconHandle: {
    color: theme.palette.neutrals[6],
    marginLeft: 'auto',
    '&:hover': {
      cursor: 'move',
    },
  },
  iconRemove: {
    width: '16px',
    height: '16px',
    color: theme.palette.neutrals[4],
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.error.main,
    },
  },
  boxParticipant: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.neutrals[2],
    padding: '8px 10px',
    marginBottom: '6px',
    '&:hover': {
      cursor: 'move',
    },
  },
  boxIndex: {
    width: '30px',
    marginRight: '14px',
  },
  isSummoned: {
    backgroundColor: theme.palette.primary[100],
  },
}));

const DraggableMember = ({ idx, id, name, num_users }) => {
  const classes = useStyles();

  return (
    <Draggable draggableId={`draggable-${id}`} index={idx}>
      {provided => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={clsx(classes.boxParticipant, {
            [classes.isSummoned]: idx + 1 <= parseInt(num_users, 10),
          })}
        >
          <Box display="flex" alignItems="center" width="100%">
            <Box className={classes.boxIndex}>
              <Body1>{parseInt(idx, 10) + 1}º</Body1>
            </Box>
            <Box>
              <Body1>{name}</Body1>
            </Box>
            <DragHandle className={classes.iconHandle} />
          </Box>
        </Box>
      )}
    </Draggable>
  );
};

DraggableMember.propTypes = {
  idx: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  num_users: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default React.memo(DraggableMember);
