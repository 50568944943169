import React from 'react';
import PropTypes from 'prop-types';
import PermissionsCollapse from './PermissionsCollapse';

const groups = ['typologies', 'meetings', 'proposals', 'presentations', 'others', 'users'];

const PermissionsCollapseGroup = ({ panel, permissions, dispatch }) => {
  return groups.map(group => (
    <PermissionsCollapse
      key={group}
      slug={group}
      open={panel === group}
      permissions={permissions[group]}
      dispatch={dispatch}
    />
  ));
};

PermissionsCollapseGroup.propTypes = {
  panel: PropTypes.string.isRequired,
  permissions: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default React.memo(PermissionsCollapseGroup);
