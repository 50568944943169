import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NextEventsInfo from './NextEventsInfo';
import Subtitle3 from '../../typographys/Subtitle3';
import { makeStyles, Box, Button } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const ITEMS_PER_PAGE = 4;

const useStyles = makeStyles(() => ({
  button: {
    width: '100%',
  },
}));

const Scroll = ({ meetings, startMeeting }) => {
  const classes = useStyles();
  const [index, setIndex] = useState(0);

  if (!meetings.length) return <Subtitle3 inactive>Nenhuma reunião agendada</Subtitle3>;

  const handleScrollDown = () => {
    const newIndex = index + 1;
    if (newIndex >= meetings.length) setIndex(0);
    else setIndex(newIndex);
  };

  const handleScrollUp = () => {
    const newIndex = index - 1;
    if (newIndex < 0) setIndex(meetings.length - 1);
    else setIndex(newIndex);
  };

  const exceedsMaxSize = meetings.length > ITEMS_PER_PAGE;
  const meetingsToDisplay = () => {
    if (!exceedsMaxSize) {
      return meetings.map((meeting, idx) => (
        <NextEventsInfo
          key={meeting.id}
          event={meeting}
          startMeeting={startMeeting}
          last={idx === meetings.length - 1}
        />
      ));
    }

    let visibleMeetings = meetings.slice(index, index + ITEMS_PER_PAGE);

    if (visibleMeetings.length < ITEMS_PER_PAGE) {
      visibleMeetings = visibleMeetings.concat(
        meetings.slice(0, ITEMS_PER_PAGE - visibleMeetings.length)
      );
    }

    return visibleMeetings.map((meeting, idx) => (
      <NextEventsInfo
        key={meeting.id}
        event={meeting}
        startMeeting={startMeeting}
        last={idx === visibleMeetings.length - 1}
      />
    ));
  };

  return (
    <Box pb={0}>
      {exceedsMaxSize && (
        <Box pb={2}>
          <Button
            variant="text"
            color="primary"
            size="small"
            className={classes.button}
            onClick={handleScrollUp}
          >
            <ExpandLess />
          </Button>
        </Box>
      )}
      {meetingsToDisplay()}
      {exceedsMaxSize && (
        <Box pt={2}>
          <Button
            variant="text"
            color="primary"
            size="small"
            className={classes.button}
            onClick={handleScrollDown}
          >
            <ExpandMore />
          </Button>
        </Box>
      )}
    </Box>
  );
};

Scroll.propTypes = {
  meetings: PropTypes.array.isRequired,
  startMeeting: PropTypes.func.isRequired,
};

export default Scroll;
