import React, { useState, useMemo, useCallback } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import Subtitle2 from '../../common/typographys/Subtitle2';
import Body1 from '../../common/typographys/Body1';
import isEmpty from '../../../utils/isEmpty';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: '8px',
  },
  value: {
    marginBottom: '20px',
  },
  button: {
    minHeight: '40px',
    maxHeight: '40px',
    height: '40px',
    margin: '20px 0px',
  },
}));

const MAX_LENGTH = 400;

const ProposalDescription = ({ description }) => {
  const classes = useStyles();
  const [showAll, setShowAll] = useState(false);
  const [biggerText, setBiggerText] = useState(false);

  const showText = useMemo(() => {
    if (description && description.length > MAX_LENGTH + 5 && !showAll) {
      setBiggerText(true);
      return description.substring(0, MAX_LENGTH);
    }

    if (isEmpty(description) && !showAll) {
      setBiggerText(false);
      return 'Sem enquadramento adicionado';
    }

    if (!showAll) {
      setBiggerText(false);
    }

    return description;
  }, [description, showAll]);

  const showAllText = useCallback(e => {
    e.preventDefault();
    setShowAll(true);
  }, []);

  const showLessText = useCallback(e => {
    e.preventDefault();
    setShowAll(false);
  }, []);

  return (
    <>
      <Subtitle2 tertiary className={classes.label}>
        Enquadramento
      </Subtitle2>
      <Body1
        printMultiline
        className={clsx({ [classes.value]: !biggerText })}
        disabled={isEmpty(description)}
      >
        {showText} {biggerText && !showAll && '...'}
      </Body1>
      {biggerText && (
        <Button
          variant="outlined"
          color="primary"
          onClick={showAll ? showLessText : showAllText}
          className={classes.button}
        >
          {showAll ? 'Mostrar menos' : 'Mostrar tudo'}
        </Button>
      )}
    </>
  );
};

ProposalDescription.propTypes = {
  description: PropTypes.string.isRequired,
};

export default React.memo(ProposalDescription);
