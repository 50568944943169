import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Subtitle2 from '../../common/typographys/Subtitle2';
import translateSlug from './translateSlug';

const useStyles = makeStyles(theme => ({
  label: {
    marginBottom: '20px',
  },
  permission: {
    padding: '8px 10px',
    backgroundColor: theme.palette.neutrals[2],
    marginBottom: '12px',
  },
}));

const PermissionEntry = ({ label, permissions }) => {
  const classes = useStyles();
  const permissionsEntry = Object.keys(permissions);

  return (
    <>
      <Subtitle2 tertiary className={classes.label}>
        {translateSlug(label)}
      </Subtitle2>
      {permissionsEntry.map(permission =>
        permissions[permission] ? (
          <Box key={permission} className={classes.permission}>
            {translateSlug(permission)}
          </Box>
        ) : null
      )}
      <Box height="8px" />
    </>
  );
};

PermissionEntry.propTypes = {
  label: PropTypes.string.isRequired,
  permissions: PropTypes.object.isRequired,
};

export default PermissionEntry;
