import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body2 from '../../../../../common/typographys/Body2';
import getEmailTemplateLabel from '../../../../../../utils/getEmailTemplateLabel';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  templateBox: {
    padding: '20px',
    marginBottom: '4px',
    width: '100%',
  },
  header: {
    marginBottom: '10px',
  },
  noType: {
    padding: '0px 20px!important',
    marginBottom: '0px!important',
  },
}));

const TemplateBox = ({ type, children, hideType }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.templateBox, { [classes.noType]: hideType })}>
      {!hideType && (
        <Body2 secondary className={classes.header}>
          {getEmailTemplateLabel(type)}
        </Body2>
      )}
      {children}
    </Box>
  );
};

TemplateBox.defaultProps = {
  hideType: false,
};

TemplateBox.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  hideType: PropTypes.bool,
};

export default React.memo(TemplateBox);
