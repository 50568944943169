import React, { useCallback } from 'react';
import { makeStyles, Grid, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ReactComponent as VoteFavor } from '../../../../assets/images/dialogVoteFavor.svg';
import { ReactComponent as VoteAgainst } from '../../../../assets/images/dialogVoteAgainst.svg';
import clsx from 'clsx';
import Subtitle2 from '../../../common/typographys/Subtitle2';
import H5 from '../../../common/typographys/H5';
import CheckboxPicker from '../../../common/forms/CheckboxPicker';

const useStyles = makeStyles(theme => ({
  favor: {
    '& *': {
      transition: 'fill 0.2s ease',
      fill: theme.palette.colorsPalette.TrueGreen,
    },
  },
  against: {
    '& *': {
      transition: 'fill 0.2s ease',
      fill: theme.palette.colorsPalette.RustRed,
    },
  },
  voteItemCommon: {
    height: '124px',
    backgroundColor: theme.palette.primary[50],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: theme.palette.primary[100],
    },
  },
  voteFavor: {
    transition: 'fill 0.2s ease',
    '&:hover': {
      cursor: 'pointer',
      '& *': {
        fill: `${theme.palette.colorsPalette.TrueGreen}!important`,
      },
    },
  },
  voteAgainst: {
    transition: 'fill 0.2s ease',
    '&:hover': {
      cursor: 'pointer',
      '& *': {
        fill: `${theme.palette.error.main}!important`,
      },
    },
  },
  qualityVoteLabel: {
    marginTop: '30px',
    marginBottom: '10px',
  },
}));

const QualityVoteContent = ({
  qualityVoters,
  qualityVoteUser,
  qualityVoteType,
  setQualityVoteType,
  setQualityVoteUser,
}) => {
  const classes = useStyles();

  const changeVote = useCallback(
    value => e => {
      e.preventDefault();

      setQualityVoteType(value);
    },
    [setQualityVoteType]
  );

  return (
    <>
      <Subtitle2 className={classes.labelVoters}>
        Participante{qualityVoters.length === 1 ? '' : 's'} com voto de qualidade
      </Subtitle2>
      {qualityVoters.map(user => (
        <CheckboxPicker
          key={user.id}
          label={user.name}
          checked={qualityVoteUser === user.id}
          onClick={e => {
            e.preventDefault();
            setQualityVoteUser(user.id);
          }}
        />
      ))}
      {qualityVoteUser !== '' && (
        <>
          <H5 className={classes.qualityVoteLabel}>Atribuir voto de qualidade:</H5>
          <Grid container spacing={1} justify="center">
            <Grid item xs={4}>
              <Box
                className={clsx(classes.voteItemCommon, classes.voteFavor, {
                  [classes.selectedBox]: qualityVoteType === 'favor',
                })}
                onClick={changeVote('favor')}
              >
                <VoteFavor className={clsx({ [classes.favor]: qualityVoteType === 'favor' })} />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                className={clsx(classes.voteItemCommon, classes.voteAgainst, {
                  [classes.selectedBox]: qualityVoteType === 'against',
                })}
                onClick={changeVote('against')}
              >
                <VoteAgainst
                  className={clsx({ [classes.against]: qualityVoteType === 'against' })}
                />
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

QualityVoteContent.propTypes = {
  qualityVoters: PropTypes.array.isRequired,
  qualityVoteUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  qualityVoteType: PropTypes.string.isRequired,
  setQualityVoteType: PropTypes.func.isRequired,
  setQualityVoteUser: PropTypes.func.isRequired,
};

export default React.memo(QualityVoteContent);
