import React from 'react';
import { DateTimePicker as Picker } from '@material-ui/pickers';
import { IconButton, makeStyles } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  root: {
    paddingRight: 0,
  },
});

const DateTimePicker = ({ value, name, onChange, ...props }) => {
  const classes = useStyles();

  return (
    <Picker
      onChange={onChange}
      value={value}
      ampm={false}
      variant="dialog"
      name={name}
      disablePast
      format="dd/MM/yyyy HH:mm"
      inputVariant="outlined"
      invalidDateMessage="Data inválida"
      leftArrowButtonProps={{ style: { backgroundColor: 'transparent' } }}
      maxDateMessage="A data escolhida ultrapassa o limite máximo"
      minDateMessage="A data escolhida não pode ser inferior ao limite mínimo"
      rightArrowButtonProps={{ style: { backgroundColor: 'transparent' } }}
      clearable
      clearLabel="Limpar"
      cancelLabel="Cancelar"
      okLabel="Confirmar"
      todayLabel="Hoje"
      hideTabs
      margin="none"
      InputProps={{
        className: classes.root,
        endAdornment: (
          <IconButton>
            <EventIcon />
          </IconButton>
        ),
      }}
      {...props}
    />
  );
};

DateTimePicker.defaultProps = {
  value: null,
};

DateTimePicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DateTimePicker;
