import React, { useEffect, useReducer, useCallback, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from '../../../../utils/isEmpty';
import { clearErrors } from '../../../../store/actions/authActions';
import { createEntity, updateEntity } from '../../../../store/actions/entitiesActions';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import { ConfigStateContext } from '../../MenuConfigProvider';
import validEmail from '../../../../utils/validation/validEmail';
import validateNIF from '../../../../utils/validateNIF';

export const EntityDispatchContext = React.createContext();
export const EntityStateContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'SET_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    case 'CLEAR_STATE':
      return {
        ...state,
        name: '',
        nif: '',
        type: '',
        email: '',
        contact: '',
        id: '',
        errors: {},
      };
    default:
      return state;
  }
};

const CreateEditEntityProvider = ({ children, items }) => {
  const errorsReducer = useSelector(state => state.errors);
  const { loading } = useSelector(state => state.entities);
  const dispatchRedux = useDispatch();
  const { editing } = useContext(ConfigStateContext);
  const [state, dispatch] = useReducer(reducer, {
    name: '',
    nif: '',
    type: '',
    email: '',
    contact: '',
    id: '',
    items: [],
    errors: {},
  });
  const [sameName, setSameName] = useState('');
  const [sameNif, setSameNif] = useState('');

  const { name, id, nif, type, email, contact, errors } = state;

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: 'items',
        value: items,
      },
    });
  }, [items]);

  useEffect(() => {
    if (errorsReducer.name) {
      dispatch({
        type: 'SET_ERROR',
        payload: { name: 'Já existe uma entidade com este nome.' },
      });
      dispatchRedux(clearErrors());
      setSameName(name);
    }
    if (errorsReducer.nif) {
      dispatch({
        type: 'SET_ERROR',
        payload: { nif: 'Já existe uma entidade com este NIF.' },
      });
      dispatchRedux(clearErrors());
      setSameNif(nif);
    }
  }, [errorsReducer, name, nif, dispatchRedux]);

  useEffect(() => {
    if (!isEmpty(name) && !isEmpty(errors.name) && name !== sameName) {
      dispatch({
        type: 'SET_ERROR',
        payload: { name: '' },
      });
    }
  }, [name, errors.name, sameName]);

  useEffect(() => {
    if (!isEmpty(nif) && validateNIF(nif) && !isEmpty(errors.nif) && nif !== sameNif) {
      dispatch({
        type: 'SET_ERROR',
        payload: { nif: '' },
      });
    }
  }, [errors.nif, nif, sameNif]);

  useEffect(() => {
    if (!isEmpty(email) && validEmail(email) && !isEmpty(errors.email)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { email: '' },
      });
    }
  }, [errors.email, email]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      const localErrors = {};

      if (isEmpty(name)) {
        localErrors.name = 'Insira um nome válido para a entidade.';
      }

      if (isEmpty(nif) || !validateNIF(nif)) {
        localErrors.nif = 'Insira um NIF válido para a entidade.';
      }

      if (isEmpty(email) || !validEmail(email)) {
        localErrors.email = 'Insira um email válido para a entidade.';
      }

      if (checkEmptyObject(localErrors).length > 0) {
        return dispatch({
          type: 'SET_ERROR',
          payload: localErrors,
        });
      }

      const infoEntity = {
        name,
        nif,
        type,
        email,
        contact,
      };

      if (editing) {
        return dispatchRedux(updateEntity(id, infoEntity));
      }

      return dispatchRedux(createEntity(infoEntity));
    },
    [dispatchRedux, editing, id, name, nif, type, email, contact]
  );

  const contextState = useMemo(() => ({ state, handleSubmit, loading }), [state, handleSubmit, loading]);

  return (
    <EntityDispatchContext.Provider value={dispatch}>
      <EntityStateContext.Provider value={contextState}>{children}</EntityStateContext.Provider>
    </EntityDispatchContext.Provider>
  );
};

CreateEditEntityProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  items: PropTypes.array.isRequired,
};

export default CreateEditEntityProvider;
