import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Subtitle2 from '../../../common/typographys/Subtitle2';
import InputField from '../../../common/forms/InputField';

const useStyles = makeStyles(theme => ({
  newInfoEntiy: {
    backgroundColor: theme.palette.neutrals[2],
    padding: '20px',
  },
  label: {
    marginBottom: '6px',
  },
}));

const NewEntityInfo = ({ entity, errors, updateEntityField }) => {
  const classes = useStyles();
  const { name = '', email = '', contact = '', nif = '' } = entity;

  return (
    <>
      <Box height="20px" />
      <Subtitle2 className={classes.label}>Informação da nova Entidade</Subtitle2>
      <Box className={classes.newInfoEntiy}>
        <InputField label="Nome" name="name" value={name} onChange={e => updateEntityField('name', e.target.value)} error={errors.entityName} />
        <InputField
          label="Email"
          name="email"
          type="email"
          value={email}
          onChange={e => updateEntityField('email', e.target.value)}
          error={errors.entityEmail}
        />
        <InputField
          label="NIF"
          name="nif"
          type="number"
          value={nif}
          onChange={e => updateEntityField('nif', e.target.value)}
          error={errors.entityNif}
        />
        <InputField label="Contacto" name="contact" type="tel" value={contact} onChange={e => updateEntityField('contact', e.target.value)} />
      </Box>
    </>
  );
};

NewEntityInfo.propTypes = {
  entity: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateEntityField: PropTypes.func.isRequired,
};

export default React.memo(NewEntityInfo);
