import {
  GET_LOCALS,
  CREATE_LOCAL,
  UPDATE_LOCAL,
  REMOVE_LOCAL,
  LOCALS_LOADING,
  LOCALS_NOT_LOADING,
} from '../actions/types';

const initialState = {
  locals: [],
  local: {},
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LOCALS:
      return {
        ...state,
        locals: action.payload,
        loading: false,
      };
    case CREATE_LOCAL:
      return {
        ...state,
        locals: [action.payload, ...state.locals],
        loading: false,
      };
    case UPDATE_LOCAL:
      return {
        ...state,
        locals: state.locals.map(x => (x.id === action.payload.id ? action.payload : x)),
        loading: false,
      };
    case REMOVE_LOCAL:
      return {
        ...state,
        locals: state.locals.filter(x => x.id !== action.payload.id),
        loading: false,
      };
    case LOCALS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case LOCALS_NOT_LOADING:
      return {
        ...state,
        locals: false,
      };
    default:
      return state;
  }
}
