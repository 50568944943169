import React from 'react';
import PropTypes from 'prop-types';
import Body1 from '../../typographys/Body1';

const Placeholder = props => {
  return (
    <Body1 secondary className={props.selectProps.classes.placeholder} {...props.innerProps}>
      {props.children}
    </Body1>
  );
};

Placeholder.defaultProps = {
  children: null,
  innerProps: null,
};

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

export default Placeholder;
