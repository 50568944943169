import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changeMenuSidebar } from '../../../store/actions/navigationActions';
import { getMeeting, replyMeetingInvite } from '../../../store/actions/meetingsActions';
import H5 from '../../common/typographys/H5';
import H6 from '../../common/typographys/H6';
import CardBlock from '../../common/forms/CardBlock';
import LabelAndValue from '../../common/forms/LabelAndValue';
import formatDate from '../../../utils/formatDate';
import ListMeetingThemes from '../pieces/ListMeetingThemes';
import ListMeetingPoints from '../pieces/ListMeetingPoints';
import ButtonNormal from '../../common/buttons/ButtonNormal';
import useBooleanToggle from '../../../utils/hooks/useBooleanToggle';
import DialogConfirmAction from '../../common/dialogs/DialogConfirmAction';
import isEmpty from '../../../utils/isEmpty';
import FullWidthSpinner from '../../common/FullWidthSpinner';
import flatten from 'lodash/flatten';

const useStyles = makeStyles(() => ({
  marginContainer: {
    marginTop: '54px',
  },
  header: {
    marginBottom: '30px',
  },
  buttonAdd: {
    // marginLeft: '10px',
    height: '100%',
  },
}));

const AcceptMeetingInvite = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { id: idUser } = useSelector(state => state.auth.user);
  const { meeting = {} } = useSelector(state => state.meetings);
  const [openModal, toggleModal] = useBooleanToggle();
  const [answer, setAnswer] = useState('');
  const { users = [], is_group: isGroup } = meeting;

  useEffect(() => {
    dispatch(changeMenuSidebar('reunioes', 'Reunião', 'Aceitar convite'));
    if (id) {
      dispatch(getMeeting(id));
    }
  }, [dispatch, id]);

  const showButtons = useMemo(() => {
    if (!isEmpty(users)) {
      const participants = flatten(
        isGroup
          ? users.map(x => x.users.filter(user2 => user2.in_meeting))
          : users.filter(user2 => user2.in_meeting)
      );

      const obj = participants.find(x => x.id === idUser) || {};

      // return !isEmpty(obj) && obj.accepted === null;
      const accepted = !isEmpty(obj) && obj.accepted === 1;
      const rejected = !isEmpty(obj) && obj.accepted === 0;
      const notAnswered = !isEmpty(obj) && obj.accepted === null;
      return { accepted, rejected, notAnswered, show: accepted || rejected || notAnswered };
    }

    // return false;
    return { accepted: false, rejected: false, notAnswered: false, show: false };
  }, [users, isGroup, idUser]);

  const handleAnswer = useCallback(
    value => e => {
      e.preventDefault();
      toggleModal();
      setAnswer(value);
    },
    [toggleModal]
  );

  const confirmAnswer = useCallback(
    e => {
      e.preventDefault();
      toggleModal();

      const reply = { accepted: answer === 'accept' };
      const replyInvite = new Promise(resolve => {
        dispatch(replyMeetingInvite(id, idUser, reply, resolve, true));
      });

      replyInvite
        .then(() => {
          return history.push('/');
        })
        .catch(() => {
          return dispatch({
            type: 'SHOW_SNACK',
            payload: {
              variant: 'error',
              message: 'Aconteceu um erro, tente novamente por favor.',
            },
          });
        });
    },
    [dispatch, history, answer, id, idUser, toggleModal]
  );

  const { name, date, themes = [], points = [], local } = meeting;

  return !isEmpty(meeting) ? (
    <Grid container spacing={3} justify="center" className={classes.marginContainer}>
      <Grid item xs={6}>
        <H5 className={classes.header}>{name}</H5>
        <CardBlock label="Detalhes" singlePadding>
          <LabelAndValue label="Nome" value={name} />
          <LabelAndValue label="Local" value={local ? local.name : 'Sem local definido'} />
          <LabelAndValue label="Data" value={formatDate(date, "dd/MM/yyyy 'às' HH:mm")} />
          {themes.length > 0 && <ListMeetingThemes themes={themes} />}
          {points.length > 0 && <ListMeetingPoints points={points} />}
        </CardBlock>
        {/* {showButtons && (
          <Grid container justify="center" spacing={1}>
            <Grid item>
              <ButtonNormal
                variant="outlined"
                color="primary"
                label="Recusar convite"
                onClick={handleAnswer('refuse')}
              />
            </Grid>
            <Grid item>
              <ButtonNormal
                contained
                pea
                onClick={handleAnswer('accept')}
                label="Aceitar convite"
                className={classes.buttonAdd}
              />
            </Grid>
          </Grid>
        )} */}
        {showButtons.show && (
          <>
            {showButtons.notAnswered && (
              <Grid container justify="center" spacing={1}>
                <Grid item>
                  <ButtonNormal
                    variant="outlined"
                    color="primary"
                    label="Recusar convite"
                    onClick={handleAnswer('refuse')}
                  />
                </Grid>
                <Grid item>
                  <ButtonNormal
                    contained
                    pea
                    onClick={handleAnswer('accept')}
                    label="Aceitar convite"
                    className={classes.buttonAdd}
                  />
                </Grid>
              </Grid>
            )}
            {showButtons.accepted && (
              <Grid container justify="center" spacing={1}>
                <Grid item>
                  <H6 approved>O convite já foi aceite</H6>
                </Grid>
              </Grid>
            )}
            {showButtons.rejected && (
              <Grid container justify="center" spacing={1}>
                <Grid item>
                  <H6 disapproved>O convite foi rejeitado</H6>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>
      <DialogConfirmAction
        open={openModal}
        handleClose={toggleModal}
        handleSubmit={confirmAnswer}
        msg={`Deseja ${answer === 'accept' ? 'aceitar' : 'recusar'} o convite para a reunião?`}
        labelSubmit="Confirmar"
      />
    </Grid>
  ) : (
    <FullWidthSpinner className={classes.marginContainer} />
  );
};

export default AcceptMeetingInvite;
