import React from 'react';
import Link from '../../routing/Link';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const ItemLink = ({ link, classes, handleDrawerToggle, menu, menuSidebar, icon: Icon, show, ...props }) => {
  return (
    show && (
      <Link to={link} onClick={handleDrawerToggle} {...props}>
        <Box className={clsx(classes.itemBox, { [classes.selectedMenu]: menuSidebar === menu })}>
          {menuSidebar === menu && <Box className={classes.selectedBox} />}
          <Icon className={classes.iconButton} />
        </Box>
      </Link>
    )
  );
};

ItemLink.defaultProps = {
  handleDrawerToggle: null,
  show: false,
};

ItemLink.propTypes = {
  link: PropTypes.string.isRequired,
  menuSidebar: PropTypes.string.isRequired,
  menu: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  icon: PropTypes.object.isRequired,
  handleDrawerToggle: PropTypes.func,
  show: PropTypes.bool,
};

export default ItemLink;
