import typography from '../typography';
import palette from '../palette';
import { fade } from '@material-ui/core/styles';

export default {
  root: {
    borderBottom: `2px solid ${fade(palette.primary[100], 0.7)}`,
  },
  head: {
    ...typography.body2,
    color: `${palette.primary.A700}!important`,
    fontWeight: '500!important',
    padding: '0px 0px 6px',
  },
  body: {
    ...typography.body2,
  },
};
