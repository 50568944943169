import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import Subtitle1 from '../../typographys/Subtitle1';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.subtitle1,
    textTransform: 'none',
    '&:hover': {
      background: theme.palette.primary[100],
      border: `1px solid ${theme.palette.primary[100]}`,
      '& span': {
        color: `${theme.palette.neutrals[6]}!important`,
      },
    },
  },
  activeButton: {
    background: theme.palette.primary.main,
  },
}));

const ViewButton = ({ label, changeView, view, value }) => {
  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      color="primary"
      className={clsx({ [classes.activeButton]: view === value })}
      classes={{ root: classes.root }}
      onClick={changeView(value)}
      fullWidth
    >
      <Subtitle1 center selected={view === value}>
        {label}
      </Subtitle1>
    </Button>
  );
};

ViewButton.propTypes = {
  label: PropTypes.string.isRequired,
  changeView: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default React.memo(ViewButton);
