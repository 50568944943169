import React, { useMemo, useCallback, useState, useContext } from 'react';
import {
  makeStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Dialog,
  Divider,
  AppBar,
  Toolbar,
  Button,
  IconButton,
} from '@material-ui/core';
import { ExpandMore, Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import Body1 from '../../../../../common/typographys/Body1';
import H5 from '../../../../../common/typographys/H5';
import TemplateInfo from './TemplateInfo';
import EditTemplateInfo from './EditTemplateInfo';
import HTMLCodeEditor from './HTMLCodeEditor';
import { v4 as uuidv4 } from 'uuid';
import { GeneralConfigFuncsContext } from '../../CreateEditGeneralConfigProvider';

const useStyles = makeStyles(theme => ({
  summary: {
    padding: '0px 14px',
    backgroundColor: theme.palette.neutrals[3],
  },
  panel: {
    marginBottom: '4px',
  },
  details: {
    display: 'block',
    padding: '8px',
  },
  divider: {
    margin: '8px 0px',
  },
  fullHeight: {
    height: '100%',
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const EmailTemplatePanel = ({
  expanded,
  idx,
  handleChange,
  template,
  editing,
  subExpanded,
  handleSubChange,
}) => {
  const classes = useStyles();
  const { updateTemplateHtml, updateButtonTemplateHtml } = useContext(GeneralConfigFuncsContext);
  const { emails, name, template: content, button } = template;
  const id = useMemo(() => uuidv4(), []);
  const idButton = useMemo(() => uuidv4(), []);
  const [open, setOpen] = useState(false);
  const [aux, setAux] = useState('');

  const handlePreview = useCallback(
    snippet => e => {
      e.preventDefault();
      setOpen(true);

      const parser = new DOMParser();
      const contentAux = snippet
        .replace(/{{button}}/g, button)
        .replace(/{{ button }}/g, button)
        .replace(/{{button }}/g, button)
        .replace(/{{ button}}/g, button);
      const doc = parser.parseFromString(content, 'text/html');
      const div = doc.querySelector('div#wrapper');
      if (div) {
        div.innerHTML = contentAux;
      }

      const span = doc.querySelector('span#link');

      if (span) {
        span.innerText = '<Link gerado pela plataforma>';
      }

      setAux(doc.documentElement.innerHTML);
    },
    [content, button]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
    setAux('');
  }, []);

  const updateMainHtml = useCallback(
    code => {
      return updateTemplateHtml(idx, code);
    },
    [idx, updateTemplateHtml]
  );

  const updateButtonHtml = useCallback(
    code => {
      return updateButtonTemplateHtml(idx, code);
    },
    [idx, updateButtonTemplateHtml]
  );

  return (
    <>
      <ExpansionPanel
        expanded={expanded}
        onChange={handleChange(`panel-${idx}`)}
        className={classes.panel}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMore />}
          aria-controls={`panel${idx}-content`}
          id={`panel${idx}-header`}
          className={classes.summary}
        >
          <Body1>{name}</Body1>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          {emails.map((email, idxEmail) => (
            <React.Fragment key={email.id}>
              {editing ? (
                <EditTemplateInfo
                  key={email.id}
                  email={email}
                  idxTemplate={idx}
                  subExpanded={subExpanded === `email-${email.id}`}
                  handleSubChange={handleSubChange}
                  handlePreview={handlePreview}
                />
              ) : (
                <TemplateInfo key={email.id} email={email} />
              )}
              {idxEmail !== emails.length - 1 && <Divider className={classes.divider} />}
            </React.Fragment>
          ))}
          {editing && (
            <>
              <Divider className={classes.divider} />
              <HTMLCodeEditor
                content={content || ''}
                onChange={updateMainHtml}
                id={id}
                subExpanded={subExpanded === `email-${id}`}
                handleSubChange={handleSubChange}
                type="Template"
              />
              <Divider className={classes.divider} />
              <HTMLCodeEditor
                content={button || ''}
                id={idButton}
                onChange={updateButtonHtml}
                subExpanded={subExpanded === `email-${idButton}`}
                handleSubChange={handleSubChange}
                type="Button"
              />
            </>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <Close />
            </IconButton>
            <H5 white className={classes.title}>
              Pré-visualização
            </H5>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Sair
            </Button>
          </Toolbar>
        </AppBar>
        {/* eslint-disable-next-line react/no-danger */}
        <div className={classes.fullHeight} dangerouslySetInnerHTML={{ __html: aux }} />
      </Dialog>
    </>
  );
};

EmailTemplatePanel.defaultProps = {
  editing: false,
  subExpanded: '',
  handleSubChange: () => null,
};

EmailTemplatePanel.propTypes = {
  expanded: PropTypes.bool.isRequired,
  idx: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
  subExpanded: PropTypes.string,
  editing: PropTypes.bool,
  handleSubChange: PropTypes.func,
};

export default React.memo(EmailTemplatePanel);
