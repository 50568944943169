import React from 'react';
import { makeStyles, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import CheckboxPicker from '../../common/forms/CheckboxPicker';

const useStyles = makeStyles(() => ({
  divider: {
    margin: '8px 0px',
  },
}));

const ListPointsCheckbox = ({ label, onClick, checked, secondLabel, divider }) => {
  const classes = useStyles();

  return (
    <>
      <CheckboxPicker label={label} onClick={onClick} secondLabel={secondLabel} checked={checked} />
      {divider && <Divider className={classes.divider} />}
    </>
  );
};

ListPointsCheckbox.defaultProps = {
  secondLabel: null,
  divider: false,
};

ListPointsCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  secondLabel: PropTypes.string,
  divider: PropTypes.bool,
};

export default React.memo(ListPointsCheckbox);
