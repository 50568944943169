import React, { useContext, useEffect, useCallback } from 'react';
import InputField from '../../../common/forms/InputField';
import { LocalStateContext, LocalDispatchContext } from './CreateEditLocalProvider';

const FormInfoLocal = () => {
  const {
    state: { name, address, errors },
  } = useContext(LocalStateContext);
  const dispatch = useContext(LocalDispatchContext);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'CLEAR_STATE',
      });
    };
  }, [dispatch]);

  const updateField = useCallback(
    (nameInput, value) => {
      dispatch({
        type: 'UPDATE_FIELD',
        payload: {
          name: nameInput,
          value,
        },
      });
    },
    [dispatch]
  );

  return (
    <>
      <InputField
        label="Nome"
        name="name"
        value={name}
        onChange={e => updateField('name', e.target.value)}
        error={errors.name}
      />
      <InputField
        label="Morada"
        name="address"
        value={address}
        onChange={e => updateField('address', e.target.value)}
        error={errors.address}
        multiline
        rows={4}
      />
    </>
  );
};

export default FormInfoLocal;
