import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import useCommonStyles from './useCommonStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.h4,
  },
}));

const H4 = ({ children, center, right, oneLine, className, ...props }) => {
  const classes = useStyles();
  const common = useCommonStyles();

  return (
    <Typography
      variant="h4"
      classes={{ root: classes.root }}
      className={clsx({ [common.center]: center, [common.right]: right, [common.wrapEllipsis]: oneLine, [className]: className.length > 0 })}
      {...props}
    >
      {children}
    </Typography>
  );
};

H4.defaultProps = {
  center: false,
  right: false,
  oneLine: false,
  className: '',
};

H4.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]).isRequired,
  center: PropTypes.bool,
  right: PropTypes.bool,
  oneLine: PropTypes.bool,
  className: PropTypes.string,
};

export default H4;
