import React, { useContext } from 'react';
import { makeStyles, Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import CardBlock from '../../common/forms/CardBlock';
import Subtitle2 from '../../common/typographys/Subtitle2';
import SelectOutlined from '../../common/forms/SelectOutlined';
import Body1 from '../../common/typographys/Body1';
import { MeetingDispatchContext } from '../CreateEditMeetingsProvider';
import uniqBy from 'lodash/uniqBy';
import CheckboxPicker from '../../common/forms/CheckboxPicker';

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: '8px',
  },
  middleCheckBox: {
    display: 'flex',
  },
  growSelect: {
    flexGrow: 1,
  },
}));

const findUser = (usersState, userId) => {
  const userGroups = usersState.users;

  for (let i = 0; i < userGroups.length; ++i) {
    const groupOrUser = userGroups[i];

    if (Array.isArray(groupOrUser.users)) {
      for (let j = 0; j < groupOrUser.users.length; ++j) {
        const user = groupOrUser.users[j];

        if (user.id === userId) {
          return {
            idxGroup: i,
            idGroup: groupOrUser.id,
            user,
          };
        }
      }
    } else if (groupOrUser.id === userId) {
      return {
        user: groupOrUser,
      };
    }
  }

  return {};
};

const NewMeetingSecretaryModerator = ({ state, usersState }) => {
  const dispatch = useContext(MeetingDispatchContext);
  const classes = useStyles();
  const { board, availableSecretary = [] } = state;
  const { secretary, moderator } = board;

  const addSecretary = (value, userId) => {
    const actionType = value !== null ? 'ADD_SECRETARY' : 'REMOVE_SECRETARY';

    dispatch({
      type: actionType,
      payload: {
        id: userId,
        value,
      },
    });

    if (actionType === 'ADD_SECRETARY') {
      const { idxGroup, idGroup, user } = findUser(usersState, value.id);

      if (!user) return;

      dispatch({
        type: usersState.isGroup ? 'REMOVE_GROUP_USER' : 'REMOVE_USER',
        payload: {
          idxGroup,
          idUser: user.id,
          user,
          idGroup,
        },
      });
    } else if (actionType === 'REMOVE_SECRETARY') {
      if (usersState.isGroup) {
        const { idxGroup, idGroup, user } = findUser(usersState, userId);

        if (!user) return;

        dispatch({
          type: 'ADD_GROUP_USER',
          payload: {
            idxGroup,
            user,
            idGroup,
          },
        });
      } else {
        dispatch({
          type: 'ADD_NEW_USER',
          payload: {
            user: board.secretary.find(sec => sec.id === userId),
          },
        });
      }
    }
  };

  const changeMainSecretary = userId => {
    dispatch({
      type: 'CHANGE_MAIN_SECRETARY',
      payload: {
        id: userId,
      },
    });
  };

  return (
    <CardBlock label="Secretariado">
      <Subtitle2 className={classes.label}>Secretário{secretary.length > 1 ? 's' : ''}</Subtitle2>
      {uniqBy(secretary, 'id').map((user, idx) => (
        <React.Fragment key={user.id}>
          <Grid container>
            <Grid item className={classes.growSelect}>
              <SelectOutlined
                name={`secretary-${user.id}`}
                options={availableSecretary.filter(
                  x =>
                    moderator.find(u => u.id === x.id) === undefined &&
                    secretary.find(u => u.id === x.id) === undefined
                )}
                value={user}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                otherClasses={classes}
                onChange={val => addSecretary(val, user.id)}
                noOptionsMessage={() => 'Sem secretários disponíveis para substituir.'}
                hideSelectedOptions
                isClearable
              />
            </Grid>
            <Grid item className={classes.middleCheckBox}>
              <CheckboxPicker onClick={() => changeMainSecretary(user.id)} checked={user.main} />
            </Grid>
          </Grid>
          {idx !== secretary.length - 1 && <Box height="8px" />}
        </React.Fragment>
      ))}
      {secretary.length !== availableSecretary.length && (
        <>
          {secretary.length > 0 && <Box height="12px" />}
          <Box className={classes.mainBox}>
            <SelectOutlined
              name="secretary-new-add"
              options={availableSecretary.filter(
                x =>
                  moderator.find(u => u.id === x.id) === undefined &&
                  secretary.find(u => u.id === x.id) === undefined
              )}
              value={null}
              placeholder={`Adicionar ${secretary.length > 0 ? 'novo' : ''} secretário`}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              otherClasses={classes}
              onChange={val => addSecretary(val)}
              noOptionsMessage={() => 'Nenhum secretário disponível para adicionar.'}
              hideSelectedOptions
              hideRemove
            />
          </Box>
        </>
      )}
      {availableSecretary.length === 0 && (
        <Body1 secondary>Não existem utilizadores com permissão de secretário.</Body1>
      )}
    </CardBlock>
  );
};

NewMeetingSecretaryModerator.propTypes = {
  state: PropTypes.object.isRequired,
  usersState: PropTypes.object.isRequired,
};

export default React.memo(NewMeetingSecretaryModerator);
