import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import ButtonNormal from '../../common/buttons/ButtonNormal';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  boxContent: {
    padding: '28px 65px',
  },
  buttonEdit: {
    marginLeft: '10px',
  },
  boxDelete: {
    justifyContent: 'space-between',
    paddingLeft: '65px',
  },
}));

const SeeItem = ({ children, submitEdit, canDelete, deleteItem }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={clsx(classes.box, { [classes.boxDelete]: canDelete })}>
        {canDelete && <ButtonNormal variant="outlined" color="primary" onClick={deleteItem} label="Eliminar" noMargin />}
        <ButtonNormal variant="contained" contained pea onClick={submitEdit} label="Editar" className={classes.buttonEdit} />
      </Box>
      <Box className={classes.boxContent}>{children}</Box>
    </>
  );
};

SeeItem.defaultProps = {
  canDelete: false,
  deleteItem: () => null,
};

SeeItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  submitEdit: PropTypes.func.isRequired,
  canDelete: PropTypes.bool,
  deleteItem: PropTypes.func,
};

export default SeeItem;
