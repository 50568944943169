import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import useCommonStyles from './useCommonStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.subtitle1,
  },
  hoverable: {
    transition: 'color 0.1s ease',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  },
  inactive: {
    color: theme.palette.neutrals[5],
  },
  error: {
    color: theme.palette.colorsPalette.RustRed,
  },
}));

const Subtitle1 = ({
  children,
  center,
  right,
  selected,
  hoverable,
  error,
  oneLine,
  inactive,
  className,
  justify,
  ...props
}) => {
  const classes = useStyles();
  const common = useCommonStyles();

  return (
    <Typography
      variant="subtitle1"
      component="span"
      classes={{ root: classes.root }}
      className={clsx({
        [common.center]: center,
        [common.justify]: justify,
        [common.right]: right,
        [common.white]: selected,
        [classes.hoverable]: hoverable,
        [classes.error]: error,
        [classes.inactive]: inactive,
        [common.wrapEllipsis]: oneLine,
        [className]: className.length > 0,
      })}
      {...props}
    >
      {children}
    </Typography>
  );
};

Subtitle1.defaultProps = {
  children: null,
  center: false,
  right: false,
  selected: false,
  hoverable: false,
  error: false,
  oneLine: false,
  inactive: false,
  justify: false,
  className: '',
};

Subtitle1.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  center: PropTypes.bool,
  right: PropTypes.bool,
  selected: PropTypes.bool,
  hoverable: PropTypes.bool,
  error: PropTypes.bool,
  oneLine: PropTypes.bool,
  inactive: PropTypes.bool,
  justify: PropTypes.bool,
  className: PropTypes.string,
};

export default Subtitle1;
