/* eslint-disable no-nested-ternary */
import React, { useContext, useMemo, useCallback, useState } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import formatDate from '../../../utils/formatDate';
import H5 from '../../common/typographys/H5';
import ButtonNormal from '../../common/buttons/ButtonNormal';
import useBooleanToggle from '../../../utils/hooks/useBooleanToggle';
import DialogConfirmAction from '../../common/dialogs/DialogConfirmAction';
import CardBlock from '../../common/forms/CardBlock';
import LabelAndValue from '../../common/forms/LabelAndValue';
import MeetingSecretaryModerator from '../pieces/MeetingSecretaryModerator';
import MeetingUsersPrepare from '../pieces/MeetingUsersPrepare';
import { PrepareMeetingStateContext } from '../PrepareMeetingProvider';
import { useSelector, useDispatch } from 'react-redux';
import flatten from 'lodash/flatten';
import isEmpty from '../../../utils/isEmpty';
import { cancelMeeting } from '../../../store/actions/meetingsActions';
import { SocketReunion } from './SocketReunionWrapper';

const useStyles = makeStyles(() => ({
  marginHeader: {
    marginBottom: '30px',
  },
  marginContainer: {
    marginTop: '54px',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  box: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonAdd: {
    marginLeft: '10px',
  },
}));

const PrepareMeeting = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { id: userId } = useSelector(state => state.auth.user);
  const [open, toggleModal] = useBooleanToggle();
  const history = useHistory();
  const dispatchRedux = useDispatch();
  const { socketNsp } = useContext(SocketReunion);
  const [newSession, setNewSession] = useState(false);
  const {
    state: {
      name,
      date,
      local,
      board,
      availableSecretary,
      availableModerator,
      users,
      participants,
      isGroup,
    },
    cancelEdit,
    handleSubmit,
  } = useContext(PrepareMeetingStateContext);
  const hasQuorum = useMemo(() => {
    const checkedIn = participants.filter(x => x.checkIn);
    const all = flatten(users.map(user => (isGroup ? [...user.users] : user))).filter(
      x => x.in_meeting
    );

    if ((checkedIn.length / all.length) * 100 >= 50) {
      return true;
    }
    return false;
  }, [participants, users, isGroup]);

  const secretaryModeratorState = useMemo(
    () => ({ board, availableSecretary, availableModerator }),
    [board, availableSecretary, availableModerator]
  );
  const usersState = useMemo(
    () => ({ users, participants, meetingId: id, isGroup }),
    [users, participants, id, isGroup]
  );

  const canStart = useMemo(() => {
    const objParticipant = participants.find(x => x.id === userId);
    const objBoard = board.secretary.find(x => x.id === userId);

    return objParticipant !== undefined || objBoard !== undefined;
  }, [participants, board, userId]);

  const confirmNewSession = useCallback(
    async e => {
      e.preventDefault();
      setNewSession(false);

      socketNsp.emit('end_reunion', JSON.stringify({ id }));
      const cancelPromise = new Promise((resolve, reject) =>
        dispatchRedux(cancelMeeting(id, resolve, reject))
      );

      try {
        await cancelPromise;
        return history.push(`/reunioes/nova-sessao/${id}`);
      } catch (_e) {
        return null;
      }
    },
    [socketNsp, id, history, dispatchRedux]
  );

  return (
    <Grid container justify="center" spacing={2} className={classes.marginContainer}>
      <Grid item xs={6}>
        <Box className={classes.header}>
          <H5 className={classes.marginHeader}>Preparar reunião</H5>
          <Box className={classes.box}>
            <ButtonNormal variant="outlined" color="primary" label="Sair" onClick={toggleModal} />
            <ButtonNormal
              helper={
                !canStart
                  ? 'Não se encontra na lista de participantes ou secretariado.'
                  : !hasQuorum
                  ? 'Falta de quórum'
                  : null
              }
              contained
              pea
              onClick={handleSubmit}
              label="Iniciar preparação"
              className={classes.buttonAdd}
              disabled={!canStart || !hasQuorum}
            />
          </Box>
        </Box>
        {!hasQuorum && (
          <Box display="flex" justifyContent="flex-end" mt={1}>
            <ButtonNormal
              contained
              pea
              onClick={e => {
                e.preventDefault();
                setNewSession(true);
              }}
              label="Agendar nova sessão"
              className={classes.buttonAdd}
            />
          </Box>
        )}
        <CardBlock label="Detalhes">
          <LabelAndValue label="Nome" value={name} />
          <LabelAndValue
            label="Data"
            value={formatDate(date, "dd 'de' MMMM 'de' yyyy 'às' HH:mm")}
          />
          {!isEmpty(local) && <LabelAndValue label="Local" value={local.name} />}
        </CardBlock>
        <Box height="20px" />
        <MeetingSecretaryModerator state={secretaryModeratorState} />
        <Box height="20px" />
        <MeetingUsersPrepare state={usersState} />
      </Grid>
      <DialogConfirmAction
        open={open}
        handleClose={toggleModal}
        handleSubmit={cancelEdit}
        msg="Deseja cancelar a preparação da reunião?"
        labelSubmit="Confirmar"
      />
      <DialogConfirmAction
        open={newSession}
        handleClose={e => {
          e.preventDefault();
          setNewSession(false);
        }}
        handleSubmit={confirmNewSession}
        msg="Deseja agendar uma nova sessão? A reunião atual será cancelada."
        labelSubmit="Agendar"
      />
    </Grid>
  );
};

export default React.memo(PrepareMeeting);
