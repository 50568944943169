/**
 *
 * @description Obtain one item from localStorage
 * @param {string} item - Item name to obtain from localStorage
 * @returns
 */
const getItemFromStorage = item => {
  return JSON.parse(localStorage.getItem(item)) || [];
};

export default getItemFromStorage;
