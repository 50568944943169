/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Grid, Paper, makeStyles, Box, Divider } from '@material-ui/core';
import { AddCircleOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { changeMenuSidebar } from '../../store/actions/navigationActions';
import ButtonPrimaryIcon from '../common/buttons/ButtonPrimaryIcon';
import { useHistory } from 'react-router-dom';
import { getProposals, getProposalStatuses } from '../../store/actions/proposalsActions';
import ProposalsHeader from './pieces/ProposalsHeader';
import FilterContainer from './pieces/FilterContainer';
import isEmpty from '../../utils/isEmpty';
import Pagination from '../common/SimplePagination';
import usePagination from '../../utils/hooks/usePagination';
import ProposalEntryInfo from './pieces/ProposalEntryInfo';
import hasProposalsProp from '../../utils/permissions/hasProposalsProp';
import EmptyProposalEntry from './pieces/EmptyProposalEntry';
import subMonths from 'date-fns/subMonths';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import FullWidthSpinner from '../common/FullWidthSpinner';
import queryString from 'query-string';
import formatDate from '../../utils/formatDate';
import BoxDateFilter from './pieces/BoxDateFilter';

const useStyles = makeStyles(() => ({
  marginContainer: {
    marginTop: '54px',
  },
  boxButton: {
    marginBottom: '30px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  paperContainer: {
    padding: '40px 30px 30px 24px',
  },
  divider: {
    marginTop: '6px',
    marginBottom: '20px',
  },
}));

const getParams = (from, to) => {
  return {
    from: formatDate(startOfMonth(from), 'yyyy-MM-dd HH:mm'),
    to: formatDate(endOfMonth(to), 'yyyy-MM-dd HH:mm'),
  };
};

const byFreeText =
  search =>
  ({ name, identifier_code, proposal_number, meetings }) => {
    const lowerSearch = search.toLowerCase();
    return (
      (name && name.toLowerCase().includes(lowerSearch)) ||
      (identifier_code && identifier_code.toLowerCase().includes(lowerSearch)) ||
      (proposal_number && proposal_number.toLowerCase().includes(lowerSearch)) ||
      (meetings?.length &&
        meetings.some(meeting => meeting.deliberation_number?.toLowerCase().includes(lowerSearch)))
    );
  };

const Proposals = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { proposals, loading } = useSelector(state => state.proposals);
  const { permissions } = useSelector(state => state.auth.user);
  const [search, setSearch] = useState('');
  const [proponenteSearch, setProponenteSearch] = useState('');
  const [status, setStatus] = useState([]);
  const [date, setDate] = useState(subMonths(new Date(), 3));
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    dispatch(changeMenuSidebar('propostas', 'Propostas'));
    const params = getParams(subMonths(new Date(), 3), new Date());

    const stringified = queryString.stringify(params);

    dispatch(getProposalStatuses());
    dispatch(getProposals(`?${stringified}`));
  }, [dispatch]);

  const entries = useMemo(() => {
    const filterStatus = !isEmpty(status);
    const filterName = !isEmpty(search);
    const filterUser = !isEmpty(proponenteSearch);

    if (filterStatus && filterName && filterUser) {
      // @ Caso 111
      return proposals
        .filter(x => status.indexOf(x.status ? x.status.slug : '') > -1)
        .filter(byFreeText(search))
        .filter(
          x =>
            x.user &&
            x.user.name &&
            x.user.name.toLowerCase().includes(proponenteSearch.toLowerCase())
        );
    }

    if (filterStatus && filterName && !filterUser) {
      // @ Caso 110
      return proposals
        .filter(x => status.indexOf(x.status ? x.status.slug : '') > -1)
        .filter(byFreeText(search));
    }

    if (filterStatus && !filterName && filterUser) {
      // @ Caso 101
      return proposals
        .filter(x => status.indexOf(x.status ? x.status.slug : '') > -1)
        .filter(
          x =>
            x.user &&
            x.user.name &&
            x.user.name.toLowerCase().includes(proponenteSearch.toLowerCase())
        );
    }

    if (filterStatus && !filterName && !filterUser) {
      // @ Caso 111
      return proposals.filter(x => status.indexOf(x.status ? x.status.slug : '') > -1);
    }

    if (!filterStatus && filterName && filterUser) {
      // @ Caso 011
      return proposals
        .filter(byFreeText(search))
        .filter(
          x =>
            x.user &&
            x.user.name &&
            x.user.name.toLowerCase().includes(proponenteSearch.toLowerCase())
        );
    }

    if (!filterStatus && filterName && !filterUser) {
      // @ Caso 010
      return proposals.filter(byFreeText(search));
    }

    if (!filterStatus && !filterName && filterUser) {
      // @ Caso 001
      return proposals.filter(
        x =>
          x.user &&
          x.user.name &&
          x.user.name.toLowerCase().includes(proponenteSearch.toLowerCase())
      );
    }

    return proposals;
  }, [search, proposals, status, proponenteSearch]);

  const [showPagination, itemShown, page, setPage] = usePagination(10, entries);

  const addNewProposal = useCallback(
    e => {
      e.preventDefault();
      history.push('/propostas/nova');
    },
    [history]
  );

  const filterProposals = useCallback(
    e => {
      e.preventDefault();
      const params = getParams(date, endDate);

      const stringified = queryString.stringify(params);
      dispatch(getProposals(`?${stringified}`));
    },
    [date, dispatch, endDate]
  );

  return (
    <Grid container justify="center" spacing={3} className={classes.marginContainer}>
      <Grid item xs={11} xl={10}>
        <Box className={classes.boxButton}>
          <BoxDateFilter
            filterProposals={filterProposals}
            endDate={endDate}
            date={date}
            setDate={setDate}
            setEndDate={setEndDate}
          />
          {hasProposalsProp(permissions, 'create') && (
            <ButtonPrimaryIcon
              label="Nova proposta"
              icon={AddCircleOutlined}
              onClick={addNewProposal}
            />
          )}
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paperContainer}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ProposalsHeader />
                  <Divider className={classes.divider} />
                  <FilterContainer
                    value={search}
                    proponente={proponenteSearch}
                    onChange={e => setSearch(e.target.value)}
                    onChangeProponente={e => setProponenteSearch(e.target.value)}
                    status={status}
                    setStatus={setStatus}
                  />
                  <Grid container spacing={3}>
                    {loading ? (
                      <FullWidthSpinner />
                    ) : itemShown.length > 0 ? (
                      itemShown.map(proposal => (
                        <ProposalEntryInfo key={proposal.id} proposal={proposal} />
                      ))
                    ) : (
                      <EmptyProposalEntry />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            {showPagination && (
              <Pagination
                total={entries.length}
                onChangePage={(_, pageNum) => {
                  setPage(pageNum);
                }}
                rowsPerPage={10}
                page={page}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Proposals;
