import axios from 'axios';
import {
  GET_NOTIFICATIONS,
  REMOVE_NOTIFICATION,
  GET_ERRORS,
  READ_ALL_NOTIFICATIONS,
  CLICK_NOTIFICATION,
} from './types';

/*
 * @desc - Atualiza o menu que o utilizador se encontra, mantendo após refresh
 * @param - menu atual da página onde o utilizador se encontra
 */
export const getNotifications = () => dispatch => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/user/notifications`)
    .then(res => {
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const clickUserNotification = id => dispatch => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/user/notifications/${id}/click`)
    .then(() => {
      dispatch({
        type: CLICK_NOTIFICATION,
        payload: id,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const removeNotification = id => dispatch => {
  dispatch({ type: REMOVE_NOTIFICATION, payload: id });
};

export const readNotifications = () => dispatch => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/user/notifications/read`)
    .then(() => {
      dispatch({
        type: READ_ALL_NOTIFICATIONS,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
