import React, { useReducer, useCallback, useEffect } from 'react';
import { Grid, Box, Button, Typography } from '@material-ui/core';
import InputField from '../../../common/forms/InputField';
import { useDispatch, useSelector } from 'react-redux';
import loginRegisterStyles from '../../../common/layout/styles/loginRegister';
import clsx from 'clsx';
import H6 from '../../../common/typographys/H6';
import { forgotPassword } from '../../../../store/actions/authActions';
import validEmail from '../../../../utils/validation/validEmail';
import isEmpty from '../../../../utils/isEmpty';
import Link from '../../../common/routing/Link';
import LoadingSpinner from '../../../common/LoadingSpinner';

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'SET_ERRORS':
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
};

const FormRecover = () => {
  const [state, dispatch] = useReducer(reducer, {
    email: '',
    errors: {},
  });
  // const location = useLocation();
  // const history = useHistory();
  const { status, loading } = useSelector(stateRedux => stateRedux.auth);
  const errorsReducer = useSelector(stateRedux => stateRedux.errors);
  const dispatchRedux = useDispatch();
  const classes = loginRegisterStyles();
  const { errors, email } = state;

  useEffect(() => {
    return () => {
      dispatchRedux({
        type: 'CLEAN_AUTH_STATUS',
      });
    };
  }, [dispatchRedux]);

  const updateField = useCallback((name, value) => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: { name, value },
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(errorsReducer.email)) {
      dispatch({
        type: 'SET_ERRORS',
        payload: {
          email: errorsReducer.email,
        },
      });
    }
  }, [errorsReducer.email]);

  const submitLogin = e => {
    e.preventDefault();

    if (!validEmail(email)) {
      return dispatch({
        type: 'SET_ERRORS',
        payload: {
          email: 'Insira um email válido',
        },
      });
    }

    const info = {
      email,
    };

    return dispatchRedux(forgotPassword(info));
  };

  return (
    <Grid container className={classes.fullHeightLogin}>
      <Grid item lg={6} xs={10} className={clsx([classes.marginHorizontal], [classes.flexColumn])}>
        <Typography variant="h6" className={classes.labelMargin}>
          Recuperar palavra-passe
        </Typography>
        {!status.email ? (
          <>
            <InputField
              onChange={e => updateField('email', e.target.value)}
              value={email}
              error={errors.email}
              label="Email"
              type="email"
              name="email"
            />
            <Box width="100%">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={submitLogin}
                className={classes.buttonLogin}
                disabled={loading}
              >
                Recuperar palavra-passe
              </Button>
            </Box>
          </>
        ) : (
          <H6>Email de recuperação enviado.</H6>
        )}
        <Box marginBottom={8} className={classes.marginTop}>
          <Box className={classes.lineExplain}>
            <Typography variant="button" color="textSecondary">
              Já sabe a sua palavra-passe?{' '}
              <Link to="/" className={classes.cleanLink}>
                Iniciar sessão
              </Link>
            </Typography>
          </Box>
        </Box>
      </Grid>
      {loading && <LoadingSpinner />}
    </Grid>
  );
};

export default FormRecover;
