import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeMenuSidebar } from '../../store/actions/navigationActions';
import { Grid, Button, makeStyles, Paper, Box } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import H6 from '../common/typographys/H6';
import transformEvents from '../../utils/transformEvents';
import isEmpty from '../../utils/isEmpty';
import Scroll from '../common/cronologia/pieces/Scroll';
import useBooleanToggle from '../../utils/hooks/useBooleanToggle';
import DialogPlayMeeting from './pieces/DialogPlayMeeting';
import { useHistory } from 'react-router-dom';
import CalendarCronology from '../common/CalendarCronology';
import { getMeetings } from '../../store/actions/meetingsActions';

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: theme.palette.primary.A400,
    color: theme.palette.neutrals[1],
    marginBottom: '20px',
    '&:hover': {
      backgroundColor: theme.palette.primary.A700,
      color: theme.palette.neutrals[1],
    },
  },
  header: {
    marginBottom: '20px',
  },
  icon: {
    width: '16px',
    height: '16px',
    marginLeft: '18px',
  },
  paper: {
    padding: '20px 20px 20px 20px',
  },
  marginAuto: {
    marginTop: '125px',
    height: '100%',
  },
  marginContainer: {
    marginTop: '54px',
  },
}));

const Meetings = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selected, setSelected] = useState({});
  const [open, toggleModal] = useBooleanToggle();
  const { permissions } = useSelector(state => state.auth.user);
  const { meetings } = useSelector(state => state.meetings);
  const history = useHistory();
  const cronologias = meetings.reduce(transformEvents, {});
  const cronologiasIds = Object.keys(cronologias);

  useEffect(() => {
    dispatch(changeMenuSidebar('reunioes', 'Reuniões'));
    dispatch(getMeetings());
  }, [dispatch]);

  const startMeeting = useCallback(
    meeting => e => {
      e.preventDefault();
      setSelected(meeting);
      toggleModal();
    },
    [toggleModal]
  );

  const newMeeting = useCallback(
    e => {
      e.preventDefault();
      return history.push('/reunioes/nova');
    },
    [history]
  );

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      return history.push(`/reunioes/preparar/${selected.id}`);
    },
    [history, selected.id]
  );

  const nextMeetings = useMemo(() => {
    if (cronologiasIds.length > 0) {
      const cronologiaMeetings = [];
      cronologiasIds.forEach(id => {
        if (!isEmpty(cronologias[id].events.next)) {
          cronologiaMeetings.push(cronologias[id].events.next);
        }

        if (!isEmpty(cronologias[id].events.future.lengh > 0)) {
          cronologiaMeetings.push(...cronologias[id].events.future);
        }
      });

      return cronologiaMeetings.sort((a, b) => new Date(a.start) - new Date(b.start));
    }

    return [];
  }, [cronologiasIds, cronologias]);

  return (
    <>
      <Grid container spacing={2} justify="center" className={classes.marginContainer}>
        <Grid item md={11} xl={10}>
          <Grid container spacing={2}>
            <Grid item md={2}>
              <Box className={classes.marginAuto}>
                {permissions && permissions.meetings && permissions.meetings.create && (
                  <Button
                    variant="contained"
                    fullWidth
                    className={classes.button}
                    onClick={newMeeting}
                  >
                    Nova reunião
                    <AddCircle className={classes.icon} />
                  </Button>
                )}
                <Paper className={classes.paper}>
                  <H6 className={classes.header}>Próximas reuniões</H6>
                  <Scroll meetings={nextMeetings} startMeeting={startMeeting} />
                </Paper>
              </Box>
            </Grid>
            <CalendarCronology />
          </Grid>
        </Grid>
      </Grid>
      <DialogPlayMeeting
        open={open}
        handleClose={toggleModal}
        handleSubmit={handleSubmit}
        event={selected}
      />
    </>
  );
};

export default Meetings;
