import axios from 'axios';
import {
  GET_USERS,
  GET_USER,
  GET_USER_PROFILE_PERMISSIONS,
  UPDATE_USER,
  UPDATE_USER_PROFILE,
  REMOVE_USER,
  USERS_LOADING,
  USERS_NOT_LOADING,
  SHOW_SNACK,
  GET_ERRORS,
  UPDATE_USER_PERMISSIONS,
  UPDATE_USER_ORGANIC_UNITS,
  UPDATE_USER_ORGANIC_UNIT,
  GET_USERS_SIMPLIFY,
} from './types';

export const getUsers = () => dispatch => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/users`)
    .then(res => {
      dispatch({
        type: GET_USERS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getUsersSimplified = () => dispatch => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/users/simplified`)
    .then(res => {
      dispatch({
        type: GET_USERS_SIMPLIFY,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getUser = id => dispatch => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/users/${id}`)
    .then(res => {
      dispatch({
        type: GET_USER,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getUserProfilePermissions = id => dispatch => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/profiles/${id}`)
    .then(res => {
      dispatch({
        type: GET_USER_PROFILE_PERMISSIONS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const inviteUser = (info, resolve, reject) => dispatch => {
  dispatch(setUsersLoading());

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/users/invite`, info)
    .then(() => {
      // dispatch({
      //   type: CREATE_USER,
      //   payload: {},
      // });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Foi enviado um email de convite para este utilizador.',
        },
      });
      resolve();
    })
    .catch(err => {
      dispatch(setUsersNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      reject('Este email já está a ser utilizado na plataforma.');
    });
};

export const createUser = (data, resolve, reject) => dispatch => {
  dispatch(setUsersLoading());

  const payload = {
    name: data.name,
    email: data.email,
    password: data.password,
    shortName: data.short_name,
    phone: data.phone,
    organicUnitId: data.organicUnit ? data.organicUnit.id : null,
    role: data.role,
    cc: data.cc,
    nif: data.nif,
  };

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/users/`, payload)
    .then(() => {
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Utilizador criado.',
        },
      });
      resolve();
    })
    .catch(err => {
      dispatch(setUsersNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      reject('Não foi possível criar o utilizador');
    });
};

export const updateUserPermissions = (id, info) => dispatch => {
  dispatch(setUsersLoading());

  axios
    .put(`${process.env.REACT_APP_API_URL}/api/users/${id}/permissions`, info)
    .then(() => {
      dispatch({
        type: UPDATE_USER_PERMISSIONS,
        payload: { idUser: id, ...info },
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Permissões do utilizador atualizadas com sucesso.',
        },
      });
    })
    .catch(err => {
      dispatch(setUsersNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateUserProfile =
  (id, profile, resolve, reject, showSnack = true) =>
  dispatch => {
    dispatch(setUsersLoading());

    axios
      .put(`${process.env.REACT_APP_API_URL}/api/users/${id}/profile`, { id: profile.id })
      .then(() => {
        dispatch({
          type: UPDATE_USER_PROFILE,
          payload: {
            idUser: id,
            profile: {
              id: profile.id,
              name: profile.name,
            },
          },
        });

        if (showSnack) {
          dispatch({
            type: SHOW_SNACK,
            payload: {
              variant: 'success',
              message: 'Perfil atualizado com sucesso.',
            },
          });
        }

        resolve();
      })
      .catch(err => {
        dispatch(setUsersNotLoading());
        reject(err);
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      });
  };

export const updateUser =
  (id, info, resolve, reject, showSnack = true) =>
  dispatch => {
    dispatch(setUsersLoading());

    axios
      .put(`${process.env.REACT_APP_API_URL}/api/users/${id}`, info)
      .then(() => {
        dispatch({
          type: UPDATE_USER,
          payload: { idUser: id, ...info },
        });

        if (showSnack) {
          dispatch({
            type: SHOW_SNACK,
            payload: {
              variant: 'success',
              message: 'Utilizador atualizado com sucesso.',
            },
          });
        }

        resolve();
      })
      .catch(err => {
        dispatch(setUsersNotLoading());
        reject(err);
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      });
  };

export const updateUserOrganicUnits = (id, info) => dispatch => {
  dispatch(setUsersLoading());

  axios
    .put(`${process.env.REACT_APP_API_URL}/api/users/${id}/organic_units`, info)
    .then(() => {
      dispatch({
        type: UPDATE_USER_ORGANIC_UNITS,
        payload: { idUser: id, ...info },
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Unidades Orgânicas atualizadas com sucesso.',
        },
      });
    })
    .catch(err => {
      dispatch(setUsersNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateUserOrganicUnit = (id, info) => dispatch => {
  dispatch(setUsersLoading());

  axios
    .put(`${process.env.REACT_APP_API_URL}/api/users/${id}/organic_unit`, info)
    .then(() => {
      dispatch({
        type: UPDATE_USER_ORGANIC_UNIT,
        payload: { idUser: id, ...info },
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Unidade Orgânica atualizada com sucesso.',
        },
      });
    })
    .catch(err => {
      dispatch(setUsersNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const removeUser = (id, callback) => dispatch => {
  dispatch(setUsersLoading());

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/users/${id}/deactivate`)
    .then(() => {
      dispatch({
        type: REMOVE_USER,
        payload: {
          id,
        },
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Utilizador excluído com sucesso.',
        },
      });
      callback();
    })
    .catch(err => {
      dispatch(setUsersNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const downloadExcel = (info, nameFile) => dispatch => {
  axios
    .post(`${process.env.REACT_APP_API_URL}/api/excel`, info, { responseType: 'blob' })
    .then(res => {
      const filename =
        nameFile ||
        (res.headers['content-disposition'] &&
          res.headers['content-disposition'].split('filename=')[1]) ||
        'download.pdf';
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => {
      const reader = new FileReader();
      reader.addEventListener('loadend', () => {
        dispatch({
          type: GET_ERRORS,
          payload: JSON.parse(reader.result),
        });
      });

      reader.readAsText(err.response.data);
    });
};

export const setUsersLoading = () => {
  return {
    type: USERS_LOADING,
  };
};

export const setUsersNotLoading = () => {
  return {
    type: USERS_NOT_LOADING,
  };
};
