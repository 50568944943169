import React, { useContext, useEffect, useCallback } from 'react';
import InputField from '../../../common/forms/InputField';
import { ThemeStateContext, ThemeDispatchContext } from './CreateEditThemeProvider';
import CheckboxPicker from '../../../common/forms/CheckboxPicker';

const FormInfoTheme = () => {
  const {
    state: { name, isPublic, errors },
  } = useContext(ThemeStateContext);
  const dispatch = useContext(ThemeDispatchContext);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'CLEAR_STATE',
      });
    };
  }, [dispatch]);

  const updateField = useCallback(
    (nameInput, value) => {
      dispatch({
        type: 'UPDATE_FIELD',
        payload: {
          name: nameInput,
          value,
        },
      });
    },
    [dispatch]
  );

  return (
    <>
      <InputField label="Nome" name="name" value={name} onChange={e => updateField('name', e.target.value)} error={errors.name} />
      <CheckboxPicker label="Disponível" checked={isPublic} onClick={() => updateField('isPublic', !isPublic)} />
    </>
  );
};

export default FormInfoTheme;
