import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Body2 from '../../../common/typographys/Body2';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  boxNoProposals: {
    marginBottom: '10px',
    backgroundColor: theme.palette.neutrals[3],
  },
  proposalEntry: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 14px',
    marginTop: '8px',
    backgroundColor: theme.palette.neutrals[3],
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.primary[100],
    },
  },
  proposalLabelNumber: {
    display: 'flex',
    alignItems: 'center',
  },
  proposalSelected: {
    background: theme.palette.primary[100],
    borderLeft: `6px solid ${theme.palette.primary.A400}`,
    padding: '7px 14px 9px 14px',
    pointerEvents: 'none',
  },
}));

const NoThemeOrProposals = ({ moderator, activeInfo, onClick, idxTheme }) => {
  const classes = useStyles();

  return (
    <Box className={classes.boxNoProposals}>
      <Box
        className={clsx(classes.proposalEntry, {
          [classes.proposalSelected]: activeInfo.idxProposal === null && activeInfo.idxTheme === idxTheme,
        })}
        onClick={onClick}
      >
        <Box className={classes.proposalLabelNumber}>
          <Body2>{moderator ? 'Discutir tema' : 'Tema sem pontos associados'}</Body2>
        </Box>
      </Box>
    </Box>
  );
};

NoThemeOrProposals.defaultProps = {
  moderator: false,
};

NoThemeOrProposals.propTypes = {
  onClick: PropTypes.func.isRequired,
  activeInfo: PropTypes.object.isRequired,
  idxTheme: PropTypes.number.isRequired,
  moderator: PropTypes.bool,
};

export default React.memo(NoThemeOrProposals);
