import { makeStyles } from '@material-ui/core';

const loginRegisterStyles = makeStyles(theme => ({
  bgBoldPrimary: {
    backgroundColor: theme.palette.primary[900],
    color: '#fff',
    minHeight: '100vh',
  },
  avatar: {
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xl')]: {
      height: '300px',
      width: '300px',
    },
    [theme.breakpoints.down('md')]: {
      height: '150px',
      width: '150px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '120px',
      width: '120px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '100px',
      width: '100px',
    },
    background: '#fff',
  },
  imgAvatar: {
    width: 'inherit',
    height: '100%',
    objectFit: 'contain',
    textAlign: 'center',
    [theme.breakpoints.down('xl')]: {
      padding: '40px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '13px',
    },
  },
  boxAvatar: {
    [theme.breakpoints.down('xl')]: {
      marginTop: '180px',
      marginBottom: '60px',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '90px',
      marginBottom: '50px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '50px',
      marginBottom: '30px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '30px',
      marginBottom: '20px',
    },
  },
  fullHeight: {
    [theme.breakpoints.up('md')]: {
      height: '100vh',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '986px',
    },
  },
  fullHeightLogin: {
    [theme.breakpoints.up('md')]: {
      height: '100vh',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '450px',
    },
  },
  fullHeightStep3: {
    [theme.breakpoints.up('md')]: {
      height: '100vh',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '186px',
    },
  },
  marginTop: {
    marginTop: 'auto',
  },
  buttonLogin: {
    padding: '10px 16px',
  },
  buttonRegister: {
    padding: '10px 16px',
    width: '200px',
  },
  labelMargin: {
    width: '100%',
    marginBottom: '34px',
    textAlign: 'left',
    [theme.breakpoints.down('xl')]: {
      marginTop: '240px',
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: '150px',
      textAlign: 'center',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '60px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '30px',
    },
  },
  labelMarginRegister: {
    width: '100%',
    marginBottom: '34px',
    textAlign: 'center',
    [theme.breakpoints.down('xl')]: {
      marginTop: '117px',
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: '110px',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '60px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '30px',
    },
  },
  marginHorizontal: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  footerInfo: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '90%',
    [theme.breakpoints.between('md', 'lg')]: {
      width: '95%',
    },
  },
  displayNone: {
    display: 'none',
  },
  entityPic: {
    height: '80px',
    [theme.breakpoints.down('xl')]: {
      width: '135px',
    },
    [theme.breakpoints.down('lg')]: {
      width: '114px',
    },
    [theme.breakpoints.down('md')]: {
      width: '84.8px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '107.1px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '254px',
      height: '110px',
    },
  },
  stepper: {
    background: 'transparent',
    padding: 0,
  },
  stepperLabel: {
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
      marginTop: '5px',
      marginBottom: '20px',
    },
  },
  buttonsRow: {
    marginTop: '40px',
    justifyContent: 'center',
    [theme.breakpoints.only('xs')]: {
      marginTop: '20px',
    },
  },
  lineExplain: {
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    marginTop: '10px',
  },
  cleanLink: { textDecoration: 'underline', color: 'inherit' },
}));

export default loginRegisterStyles;
