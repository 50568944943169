import React, { useContext, useEffect } from 'react';
import InputField from '../../../common/forms/InputField';
import {
  OrganicUnitStateContext,
  OrganicUnitDispatchContext,
  OrganicUnitFuncsContext,
} from './CreateEditOrganicUnitProvider';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';
import SelectOutlined from '../../../common/forms/SelectOutlined';

const FormInfoOrganicUnit = () => {
  const {
    state: { name, code, abbreviation, parent, user, errors },
  } = useContext(OrganicUnitStateContext);
  const { organicUnits } = useSelector(state => state.organicUnits);
  const { usersSimplify } = useSelector(state => state.users);
  const dispatch = useContext(OrganicUnitDispatchContext);
  const { updateField } = useContext(OrganicUnitFuncsContext);
  const dispatchRedux = useDispatch();

  useEffect(() => {
    return () => {
      dispatch({
        type: 'CLEAR_STATE',
      });
      dispatchRedux({
        type: 'GET_ORGANIC_UNITS_USERS',
        payload: [],
      });
    };
  }, [dispatch, dispatchRedux]);

  const onChangeParentOrganicUnit = val => {
    updateField('parent', val);
  };

  return (
    <>
      <InputField
        label="Código"
        name="code"
        value={code}
        onChange={e => updateField('code', e.target.value)}
        error={errors.code}
        placeholder="Código"
      />
      <InputField
        label="Nome"
        name="name"
        value={name}
        onChange={e => updateField('name', e.target.value)}
        error={errors.name}
        placeholder="Nome"
      />
      <InputField
        label="Abreviatura"
        name="abbreviation"
        value={abbreviation || ''}
        onChange={e => updateField('abbreviation', e.target.value)}
        error={errors.abbreviation}
        placeholder="Abreviatura"
      />
      <SelectOutlined
        options={usersSimplify}
        value={user}
        label="Responsável"
        name="user"
        placeholder="Responsável"
        onChange={val => {
          updateField('user', val);
        }}
        getOptionValue={option => option.id}
        getOptionLabel={option => `${option.name} <${option.email}>`}
        noOptionsMessage={() => 'Nenhuma opção disponível'}
        error={errors.user}
      />
      <Box height="20px" />
      <SelectOutlined
        isClearable
        options={organicUnits}
        value={parent}
        label="Unidade Orgânica superior"
        placeholder="Unidade Orgânica superior"
        name="parent"
        onChange={onChangeParentOrganicUnit}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        noOptionsMessage={() => 'Nenhuma opção disponível'}
      />
    </>
  );
};

export default FormInfoOrganicUnit;
