import React from 'react';
import { makeStyles, Paper, Grid } from '@material-ui/core';
import { PauseCircleFilled, PlayCircleFilled, LoopRounded } from '@material-ui/icons';
import PropTypes from 'prop-types';
import Body1 from '../../../../common/typographys/Body1';
import useInterval from '../../../../../utils/hooks/useInterval';
import secondsToTime from '../../../../../utils/secondsToTime';

const noop = () => {};

const useStyles = makeStyles(theme => ({
  padded: {
    padding: '30px',
    marginBottom: '60px',
  },
  buttonPlay: {
    backgroundColor: theme.palette.neutrals[1],
    borderRadius: '50%',
    fill: theme.palette.primary[300],
    '&:hover': {
      cursor: 'pointer',
      fill: theme.palette.primary[500],
    },
  },
  buttonPause: {
    backgroundColor: theme.palette.neutrals[1],
    borderRadius: '50%',
    fill: theme.palette.primary.A700,
    '&:hover': {
      cursor: 'pointer',
      fill: theme.palette.primary.A400,
    },
  },
  buttonReset: {
    backgroundColor: theme.palette.neutrals[1],
    borderRadius: '50%',
    fill: theme.palette.error.main,
    '&:hover': {
      cursor: 'pointer',
      fill: theme.palette.error.dark,
    },
  },
  overtime: {
    backgroundColor: theme.palette.error.light,
  },
}));

const TimeTracker = ({ timer, onTimeChange, isMainModerator }) => {
  const classes = useStyles();
  const { initialValue, value, state, countdown } = timer;

  useInterval(
    () => {
      const increment = countdown ? -1 : 1;
      const nextTime = parseInt(value, 10) + increment;

      onTimeChange({
        initialValue,
        value: nextTime,
        state: 'running',
        countdown,
      });
    },
    state === 'running' && isMainModerator ? 1000 : null
  );

  const onStart = () => {
    onTimeChange({
      initialValue,
      value,
      state: 'running',
      countdown,
    });
  };

  const onPause = () => {
    onTimeChange({
      initialValue,
      value,
      state: 'pause',
      countdown,
    });
  };

  const onReset = () => {
    onTimeChange({
      initialValue,
      value: initialValue,
      state: 'stop',
      countdown,
    });
  };

  return (
    <Paper className={`${classes.padded} ${value < 0 && classes.overtime}`}>
      <Grid container alignItems="center">
        <Grid item xs={8} md={10}>
          <Body1>{secondsToTime(value)}</Body1>
        </Grid>
        {isMainModerator && (
          <Grid item xs={2} md={1}>
            <LoopRounded className={classes.buttonReset} onClick={onReset} />
          </Grid>
        )}
        {isMainModerator && (state === 'stop' || state === 'pause') && (
          <Grid item xs={2} md={1}>
            <PlayCircleFilled className={classes.buttonPlay} onClick={onStart} />
          </Grid>
        )}
        {isMainModerator && state === 'running' && (
          <Grid item xs={2} md={1}>
            <PauseCircleFilled className={classes.buttonPause} onClick={onPause} />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

TimeTracker.defaultProps = {
  isMainModerator: false,
  onTimeChange: noop,
};

TimeTracker.propTypes = {
  timer: PropTypes.shape({
    initialValue: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    state: PropTypes.string.isRequired,
    countdown: PropTypes.bool.isRequired,
  }).isRequired,
  isMainModerator: PropTypes.bool,
  onTimeChange: PropTypes.func,
};

export default TimeTracker;
