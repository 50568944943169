/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo, useState, useEffect, useContext } from 'react';
import { makeStyles, Box, useTheme, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body2 from '../../../../common/typographys/Body2';
import clsx from 'clsx';
import { PauseCircleFilled, PlayCircleFilled, EventSeat } from '@material-ui/icons';
import Body1 from '../../../../common/typographys/Body1';
import addSeconds from 'date-fns/addSeconds';
import startOfToday from 'date-fns/startOfToday';
import formatDate from '../../../../../utils/formatDate';
import { useDispatch } from 'react-redux';
import isEmpty from '../../../../../utils/isEmpty';
import useInterval from '../../../../../utils/hooks/useInterval';
import { updateReunion, updateSpeakerTime } from '../../../../../store/actions/reunionsActions';
import DialogConfirmAction from '../../../../common/dialogs/DialogConfirmAction';
import { useParams } from 'react-router-dom';
import {
  checkoutMeetingUser,
  updateMeetingUserSpeakingTime,
  updatePeriodSpeakTime,
} from '../../../../../store/actions/meetingsActions';
import { SocketReunion } from '../../SocketReunionWrapper';

const useStyles = makeStyles(theme => ({
  boxUser: {
    padding: '7px 14px',
    backgroundColor: theme.palette.neutrals[2],
    display: 'flex',
    alignItems: 'center',
    marginBottom: '4px',
  },
  buttonPlay: {
    fill: theme.palette.primary[300],
    '&:hover': {
      cursor: 'pointer',
      fill: theme.palette.primary[500],
    },
  },
  buttonPause: {
    fill: theme.palette.primary.A700,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  common: {
    marginLeft: '20px',
    width: '16px',
    height: '16px',
  },
  iconDisabled: {
    fill: theme.palette.primary[100],
  },
  commonCheck: {
    marginRight: '20px',
    width: '16px',
    height: '16px',
    '&:hover': {
      cursor: 'pointer',
      fill: `${theme.palette.primary[300]}!important`,
    },
  },
  checkedIn: {
    fill: `${theme.palette.colorsPalette.TrueGreen}!important`,
  },
  notCheckedIn: {
    fill: `${theme.palette.colorsPalette.Orange}!important`,
  },
  time: {
    marginLeft: '10px',
  },
  name: {
    width: '100%',
  },
  timeLimit: {
    '& p, span': {
      color: '#FFF',
    },
    '& svg': {
      fill: '#FFF',
    },
  },
}));

const secondsToMinutes = time => {
  const dateWithSeconds = addSeconds(startOfToday(), time);

  return formatDate(dateWithSeconds, 'HH:mm:ss');
};

const getTalkPercentage = (groupTime, current) => {
  return ((parseInt(current, 10) * 100) / parseInt(groupTime, 10)).toFixed(2);
};

const SingleUserEntry = ({
  user,
  moderator,
  isMemberAbsent,
  reunionEnd,
  infoSpeaking,
  isGroup,
  idGroup,
  activeInfo,
  groupTime,
  isCheckedIn,
  activePointId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id: meetingId } = useParams();
  const [isRunning, setIsRunning] = useState(false);
  const [open, setOpen] = useState(false);
  const { name, current, id, short_name } = user;
  const { socketNsp } = useContext(SocketReunion);
  const theme = useTheme();

  useEffect(() => {
    return () => {
      if (isRunning) {
        setIsRunning(false);
      }
    };
  }, [isRunning]);

  useEffect(() => {
    setIsRunning(false);
  }, [activeInfo]);

  const isSpeaking = useMemo(() => {
    if (!isEmpty(infoSpeaking)) {
      if (
        isRunning &&
        ((infoSpeaking.userId === id && isGroup && infoSpeaking.groupId === idGroup) ||
          (!isGroup && infoSpeaking.userId === id))
      ) {
        return true;
      }

      setIsRunning(false);
      return false;
    }

    setIsRunning(false);
    return false;
  }, [infoSpeaking, isRunning, id, idGroup, isGroup]);

  const simpleIsSpeaking = useMemo(() => {
    if (!isEmpty(infoSpeaking)) {
      if (
        infoSpeaking.userId === id &&
        isGroup &&
        (infoSpeaking.groupId === idGroup || infoSpeaking.group_id === idGroup)
      ) {
        return true;
      }
    }

    return false;
  }, [id, idGroup, isGroup, infoSpeaking]);

  useInterval(
    () => {
      // @ Atualiza o tempo de quem está a falar
      dispatch(updateSpeakerTime(parseInt(current, 10) + 1, id, idGroup, isGroup));
    },
    isRunning ? 1000 : null
  );

  const pauseUser = useCallback(
    (time, userId) => e => {
      e.preventDefault();
      dispatch(updateReunion({ infoSpeaking: null }));
      const infoTime = { time, userId };
      if (activePointId && isEmpty(activeInfo.type)) {
        dispatch(updateMeetingUserSpeakingTime(meetingId, activePointId, id, infoTime));
      } else if (!isEmpty(activeInfo.type)) {
        dispatch(updatePeriodSpeakTime(meetingId, activeInfo.type, infoTime));
      }
      setIsRunning(false);
    },
    [dispatch, meetingId, activePointId, activeInfo, id]
  );

  const playUser = useCallback(
    (userId, groupId) => e => {
      e.preventDefault();

      // @ Atualiza a info de quem passou a falar
      dispatch(updateReunion({ infoSpeaking: { userId, groupId } }));
      setIsRunning(true);
    },
    [dispatch]
  );

  const handleCheckIn = useCallback(
    userId => e => {
      e.preventDefault();
      if (isCheckedIn) {
        // @ Efetuar check-out do utilizador
        setOpen(true);
      } else {
        window.open(
          `${process.env.REACT_APP_HOSTNAME}/saml-auth?meeting_id=${meetingId}&user_id=${userId}`,
          '_blank',
          'toolbar=0,location=0,menubar=0,width=700,height=600'
        );
      }
    },
    [isCheckedIn, meetingId]
  );

  const confirmCheckout = useCallback(
    (idMeeting, userId) => e => {
      e.preventDefault();
      const checkoutPromise = new Promise((resolve, reject) => {
        dispatch(checkoutMeetingUser(idMeeting, userId, resolve, reject));
      });

      checkoutPromise.then(res => {
        if (res.data.response) {
          setOpen(false);
          socketNsp.emit('checkout_user', JSON.stringify({ id: idMeeting, userId }));
        }
      });
    },
    [dispatch, socketNsp]
  );

  const percentage = isGroup ? getTalkPercentage(groupTime, current) : 0;
  const style =
    percentage > 0 && isGroup
      ? {
          background: `linear-gradient(to right,${
            percentage >= 100
              ? theme.palette.error.main
              : isRunning
              ? theme.palette.primary.A400
              : theme.palette.primary[100]
          } ${percentage}%, ${theme.palette.neutrals[2]} ${percentage}%)`,
        }
      : {};

  return (
    <Box
      className={clsx(classes.boxUser, { [classes.timeLimit]: percentage >= 100 })}
      style={style}
    >
      {moderator && (
        <Tooltip placement="top" title={isCheckedIn ? 'Efetuar check-out' : 'Efetuar check-in'}>
          <EventSeat
            className={clsx(classes.commonCheck, {
              [classes.checkedIn]: isCheckedIn,
              [classes.notCheckedIn]: !isCheckedIn,
            })}
            onClick={handleCheckIn(id)}
          />
        </Tooltip>
      )}
      <Body1
        bold={!moderator && simpleIsSpeaking}
        secondary={isMemberAbsent}
        className={classes.name}
        oneLine
      >
        {short_name || name}
      </Body1>
      <Body2 bold={!moderator && simpleIsSpeaking} className={classes.time}>
        {secondsToMinutes(current)}
      </Body2>
      {moderator && !reunionEnd ? (
        isSpeaking ? (
          <PauseCircleFilled
            className={clsx(classes.common, classes.buttonPause, {
              [classes.iconDisabled]: !isCheckedIn,
            })}
            onClick={pauseUser(current, id)}
          />
        ) : isCheckedIn ? (
          <PlayCircleFilled
            className={clsx(classes.common, classes.buttonPlay, {
              [classes.iconDisabled]: !isCheckedIn,
            })}
            onClick={playUser(id, idGroup)}
          />
        ) : (
          <Tooltip placement="top" title="Sem check-in">
            <PlayCircleFilled
              className={clsx(classes.common, classes.iconDisabled)}
              onClick={() => null}
            />
          </Tooltip>
        )
      ) : null}
      <DialogConfirmAction
        open={open}
        handleClose={e => {
          e.preventDefault();
          setOpen(false);
        }}
        msg={`Deseja marcar ${short_name || name}, como ausente da reunião?`}
        secondLevel={`Não serão contabilizados votos para ${name} a partir deste momento e será desabilitada a opção de oratória.`}
        handleSubmit={confirmCheckout(meetingId, id)}
        labelSubmit="Confirmar check-out"
      />
    </Box>
  );
};

SingleUserEntry.defaultProps = {
  infoSpeaking: null,
  isGroup: false,
  idGroup: null,
  activeInfo: null,
  groupTime: null,
  activePointId: null,
};

SingleUserEntry.propTypes = {
  user: PropTypes.object.isRequired,
  moderator: PropTypes.bool.isRequired,
  isMemberAbsent: PropTypes.bool.isRequired,
  reunionEnd: PropTypes.bool.isRequired,
  isCheckedIn: PropTypes.bool.isRequired,
  infoSpeaking: PropTypes.object,
  isGroup: PropTypes.bool,
  idGroup: PropTypes.number,
  activeInfo: PropTypes.object,
  groupTime: PropTypes.number,
  activePointId: PropTypes.number,
};

export default React.memo(SingleUserEntry);
