import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Delete, KeyboardArrowDownRounded, Email } from '@material-ui/icons';
import { Input } from '@material-ui/core';
import Body1 from '../../../common/typographys/Body1';

export const IndicatorsContainer = props => {
  const { value, toggleRemove, setRemoveInfo, hideRemove, classes } = props.selectProps;

  const handleDelete = useCallback(
    user => e => {
      e.preventDefault();
      setRemoveInfo(user);
      toggleRemove(e);
    },
    [setRemoveInfo, toggleRemove]
  );

  return (
    <div className={classes.flexCenter}>
      {!hideRemove && <Delete className={classes.iconDelete} onClick={handleDelete(value)} />}
      <KeyboardArrowDownRounded className={classes.iconArrow} />
    </div>
  );
};

IndicatorsContainer.defaultProps = {
  hideRemove: false,
};

IndicatorsContainer.propTypes = {
  selectProps: PropTypes.object.isRequired,
  hideRemove: PropTypes.bool,
};
export const IndicatorsContainerMail = props => {
  const { value, toggleRemove, setRemoveInfo, resendEmail, hideRemove, classes } = props.selectProps;

  const handleDelete = useCallback(
    user => e => {
      e.preventDefault();
      setRemoveInfo(user);
      toggleRemove(e);
    },
    [setRemoveInfo, toggleRemove]
  );

  return (
    <div className={classes.flexCenter}>
      {!hideRemove && (
        <>
          {!value.accepted && <Email className={classes.iconMail} onClick={resendEmail(value)} />}
          <Delete className={classes.iconDelete} onClick={handleDelete(value)} />
        </>
      )}
      <KeyboardArrowDownRounded className={classes.iconArrow} />
    </div>
  );
};

IndicatorsContainerMail.defaultProps = {
  hideRemove: false,
};

IndicatorsContainerMail.propTypes = {
  selectProps: PropTypes.object.isRequired,
  hideRemove: PropTypes.bool,
};

const inputComponent = ({ inputRef, ...props }) => {
  return <div ref={inputRef} {...props} />;
};

inputComponent.defaultProps = {
  inputRef: null,
};

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export const Control = props => {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes },
  } = props;

  return (
    <Input
      fullWidth
      disableUnderline
      inputComponent={inputComponent}
      className={classes.outlined}
      inputProps={{
        className: classes.input,
        ref: innerRef,
        children,
        ...innerProps,
      }}
    />
  );
};

Control.defaultProps = {
  children: null,
  innerProps: null,
  innerRef: null,
};

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
};

export const ControlEditing = props => {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes },
  } = props;

  return (
    <Input
      fullWidth
      disableUnderline
      inputComponent={inputComponent}
      className={classes.outlinedEditing}
      inputProps={{
        className: classes.input,
        ref: innerRef,
        children,
        ...innerProps,
      }}
    />
  );
};

ControlEditing.defaultProps = {
  children: null,
  innerProps: null,
  innerRef: null,
};

ControlEditing.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
};

export const SingleValue = props => {
  const { data } = props;

  return (
    <Body1
      className={props.selectProps.classes.singleValue}
      secondary={props.selectProps.menuIsOpen || data.accepted === null || data.accepted === undefined}
      approved={Boolean(data.accepted) && !props.selectProps.menuIsOpen}
      disapproved={!data.accepted && data.accepted !== null && data.accepted !== undefined && !props.selectProps.menuIsOpen}
      {...props.innerProps}
    >
      {props.children}
    </Body1>
  );
};

SingleValue.defaultProps = {
  children: null,
  innerProps: {},
  data: {},
};

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  data: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

export const SingleValuePrepare = props => {
  const { selectProps } = props;

  return (
    <Body1
      className={selectProps.classes.singleValue}
      secondary={selectProps.menuIsOpen || !selectProps.isPresent}
      approved={Boolean(selectProps.isPresent) && !selectProps.menuIsOpen}
      disapproved={!selectProps.isPresent && !selectProps.menuIsOpen}
      {...props.innerProps}
    >
      {props.children}
    </Body1>
  );
};

SingleValuePrepare.defaultProps = {
  children: null,
  innerProps: {},
  data: {},
};

SingleValuePrepare.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  data: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};
