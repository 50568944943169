import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, IconButton } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// import { items } from './sidebarItems';
import ItemLink from './ItemLink';
import AvatarLogoLetters from '../AvatarLogoLetters';
import isEmpty from '../../../../utils/isEmpty';
import { ReactComponent as Dashboard } from '../../../../assets/images/dashboard.svg';
import { ReactComponent as Reunioes } from '../../../../assets/images/reunioes.svg';
import { ReactComponent as Settings } from '../../../../assets/images/settings.svg';
import { ReactComponent as Perfil } from '../../../../assets/images/perfil.svg';
import { ReactComponent as Propostas } from '../../../../assets/images/propostas.svg';
import hasTypologyConfig from '../../../../utils/permissions/hasTypologyConfig';
import hasOthersProp from '../../../../utils/permissions/hasOthersProp';
import hasUsersConfig from '../../../../utils/permissions/hasUsersConfig';
import canSeeProposals from '../../../../utils/permissions/canSeeProposals';
// import hasMeetingsProp from '../../../../utils/permissions/hasMeetingsProp';

const sidebarStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '14px',
  },
  list: {
    marginTop: theme.spacing(5),
  },
  logout: {
    padding: 0,
    margin: 'auto auto 20px auto',
    color: theme.palette.primary[400],
    '&:hover': {
      color: theme.palette.primary[500],
      backgroundColor: 'transparent',
    },
  },
  avatar: {
    width: '52px',
    height: '52px',
    boxShadow: '0 0 10px 0 rgba(0,0,0,0.20)',
    borderRadius: '50%',
  },
  avatarLetters: {
    width: '52px',
    height: '52px',
    backgroundColor: theme.palette.primary[900],
    color: theme.palette.neutrals[1],
  },
  itemBox: {
    marginBottom: '10px',
    width: '80px',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary[300],
    '&:hover': {
      '& *': {
        fill: theme.palette.primary[500],
      },
    },
  },
  selectedMenu: {
    color: theme.palette.primary.A700,
    '& *': {
      fill: theme.palette.primary.A700,
    },
  },
  selectedBox: {
    width: '6px',
    height: '60px',
    backgroundColor: theme.palette.primary.A700,
  },
  iconButton: {
    marginLeft: 'auto',
    marginRight: '22px',
  },
  avatarInside: {
    objectFit: 'contain',
    width: '100%',
  },
}));

const Sidebar = ({ handleDrawerToggle, handleLogout }) => {
  const classes = sidebarStyles();
  const menuSidebar = useSelector(state => state.navigation.sidebar);
  const { name, image, permissions } = useSelector(state => state.auth.user);

  const items = useMemo(
    () => [
      {
        label: 'Visão geral',
        link: '/dashboard',
        menu: 'dashboard',
        icon: Dashboard,
        show: true,
      },
      {
        label: 'Reuniões',
        link: '/reunioes',
        menu: 'reunioes',
        icon: Reunioes,
        show: true,
      },
      {
        label: 'Propostas',
        link: '/propostas',
        menu: 'propostas',
        icon: Propostas,
        show: canSeeProposals(permissions),
      },
      {
        label: 'Configurações',
        link: '/configuracoes',
        menu: 'configuracoes',
        icon: Settings,
        show:
          hasTypologyConfig(permissions) ||
          hasOthersProp(permissions, 'organic_units') ||
          hasUsersConfig(permissions) ||
          hasOthersProp(permissions, 'groups') ||
          hasOthersProp(permissions, 'matrices') ||
          hasOthersProp(permissions, 'entities') ||
          // hasOthersProp(permissions, 'generic_flows') ||
          hasOthersProp(permissions, 'themes') ||
          hasOthersProp(permissions, 'profiles'), // ||
        // hasMeetingsProp(permissions, 'secretary'),
      },
      {
        label: 'Perfil pessoal',
        link: '/perfil-pessoal',
        menu: 'perfilPessoal',
        icon: Perfil,
        show: true,
      },
    ],
    [permissions]
  );

  const listItems = items.map(
    item =>
      item.show && (
        <ItemLink
          key={item.menu}
          handleDrawerToggle={handleDrawerToggle}
          menuSidebar={menuSidebar}
          classes={classes}
          {...item}
        />
      )
  );

  return (
    <>
      <div className={classes.toolbar}>
        {isEmpty(image) ? (
          <AvatarLogoLetters name={name} className={classes.avatarLetters} />
        ) : (
          <AvatarLogoLetters name={name} logo={image} className={classes.avatar} />
        )}
      </div>
      <List disablePadding className={classes.list}>
        {listItems}
      </List>
      <IconButton onClick={handleLogout} className={classes.logout}>
        <ExitToApp />
      </IconButton>
    </>
  );
};

Sidebar.defaultProps = {
  handleDrawerToggle: () => null,
};

Sidebar.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  handleDrawerToggle: PropTypes.func,
};

export default Sidebar;
