/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Subtitle1 from '../../../../common/typographys/Subtitle1';
import Body2 from '../../../../common/typographys/Body2';
import { PauseCircleFilled, PlayCircleFilled } from '@material-ui/icons';
import clsx from 'clsx';
import isEmpty from '../../../../../utils/isEmpty';
import useInterval from '../../../../../utils/hooks/useInterval';
import { updateInterventionsTotalTime } from '../../../../../store/actions/meetingsActions';
import { useDispatch } from 'react-redux';
import {
  // updateOthersTime,
  updateReunion,
  updateInterventionsTime,
} from '../../../../../store/actions/reunionsActions';
import { useParams } from 'react-router-dom';
import secondsToTime from '../../../../../utils/secondsToTime';

const useStyles = makeStyles(theme => ({
  boxUser: {
    padding: '5px 14px',
    backgroundColor: theme.palette.neutrals[2],
    display: 'flex',
    alignItems: 'center',
    marginBottom: '4px',
  },
  buttonPlay: {
    fill: theme.palette.primary[300],
    '&:hover': {
      cursor: 'pointer',
      fill: theme.palette.primary[500],
    },
  },
  buttonPause: {
    fill: theme.palette.primary.A700,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  common: {
    marginLeft: '20px',
    width: '16px',
    height: '16px',
  },
  time: {
    marginLeft: '10px',
  },
  name: {
    width: '100%',
  },
}));

const IndividualInterventions = ({ current, moderator, reunionEnd, infoSpeaking, activeInfo }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id: meetingId } = useParams();
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    return () => {
      if (isRunning) {
        setIsRunning(false);
      }
    };
  }, [isRunning]);

  useEffect(() => {
    setIsRunning(false);
  }, [activeInfo]);

  const isSpeaking = useMemo(() => {
    if (!isEmpty(infoSpeaking)) {
      if (isRunning && infoSpeaking.userId === null && infoSpeaking.groupId === null) {
        return true;
      }

      setIsRunning(false);
      return false;
    }

    setIsRunning(false);
    return false;
  }, [infoSpeaking, isRunning]);

  useInterval(
    () => {
      // dispatch(updateOthersTime(parseInt(current, 10) + 1, null, id, true));
      dispatch(updateInterventionsTime(parseInt(current, 10) + 1));
    },
    isRunning ? 1000 : null
  );

  const pauseOthers = useCallback(
    time => e => {
      e.preventDefault();
      dispatch(updateReunion({ infoSpeaking: null }));
      const infoTime = { time };
      // dispatch(updateParticipantsOthersTime(meetingId, id, infoTime));
      dispatch(updateInterventionsTotalTime(meetingId, infoTime));
      setIsRunning(false);
    },
    [dispatch, meetingId]
  );

  const playOthers = useCallback(
    e => {
      e.preventDefault();

      // @ Atualiza a info de quem passou a falar
      dispatch(updateReunion({ infoSpeaking: { userId: null, groupId: null } }));
      setIsRunning(true);
    },
    [dispatch]
  );

  return (
    <Box className={classes.boxUser}>
      <Subtitle1 className={classes.name} oneLine>
        Intervenções diversas
      </Subtitle1>
      <Body2 className={classes.time}>{secondsToTime(current)}</Body2>
      {moderator && !reunionEnd ? (
        isSpeaking ? (
          <PauseCircleFilled
            className={clsx(classes.common, classes.buttonPause)}
            onClick={pauseOthers(current)}
          />
        ) : (
          <PlayCircleFilled
            className={clsx(classes.common, classes.buttonPlay)}
            onClick={playOthers}
          />
        )
      ) : null}
    </Box>
  );
};

IndividualInterventions.defaultProps = {
  moderator: false,
  reunionEnd: false,
  activeInfo: null,
  infoSpeaking: {},
};

IndividualInterventions.propTypes = {
  current: PropTypes.number.isRequired,
  moderator: PropTypes.bool,
  reunionEnd: PropTypes.bool,
  // id: PropTypes.number.isRequired,
  activeInfo: PropTypes.object,
  infoSpeaking: PropTypes.object,
};

export default React.memo(IndividualInterventions);
