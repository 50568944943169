import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles, Box, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body2 from '../../../../common/typographys/Body2';
import isEmpty from '../../../../../utils/isEmpty';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getUserPeriodNotes,
  getUserPointNotes,
  uploadUserPeriodNotes,
  uploadUserPointNotes,
} from '../../../../../store/actions/meetingsActions';

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: '6px',
  },
}));

const PointNotes = ({ info, isActive }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const isPeriod = !isEmpty(info.type);
  const [notes, setNotes] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const getPromise = new Promise(resolve => {
      if (isPeriod) {
        dispatch(getUserPeriodNotes(id, info.type, resolve));
      } else {
        dispatch(getUserPointNotes(id, info.id, resolve));
      }
    });

    getPromise.then(res => {
      setLoading(false);
      setNotes(res.data.notes);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendNotes = useCallback(() => {
    if (isPeriod) {
      dispatch(uploadUserPeriodNotes(id, info.type, { notes }));
    } else {
      dispatch(uploadUserPointNotes(id, info.id, { notes }));
    }
  }, [dispatch, id, info.id, info.type, isPeriod, notes]);

  useEffect(() => {
    return () => {
      sendNotes();
    };
  }, [sendNotes]);

  return (
    <>
      <Box height="20px" />
      <Body2 className={classes.label}>Inserção de notas</Body2>
      <TextField
        fullWidth
        variant="outlined"
        disabled={loading}
        placeholder={loading ? 'A carregar notas...' : 'Inserir notas'}
        multiline
        rows={isPeriod && isActive ? 10 : 5}
        rowsMax={15}
        onChange={e => {
          return setNotes(e.target.value);
        }}
        value={notes}
      />
    </>
  );
};

PointNotes.defaultProps = {
  isActive: false,
};

PointNotes.propTypes = {
  info: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
};

export default React.memo(PointNotes);
