import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles, Input } from '@material-ui/core';
import Body1 from '../../../../common/typographys/Body1';
import { MatrixDispatchContext } from '../CreateEditMatrixProvider';

const useStyles = makeStyles(theme => ({
  mainBox: {
    display: 'flex',
    marginBottom: '10px',
  },
  timeBox: {
    padding: '8px 15px',
    width: '92px',
    marginLeft: '8px',
    backgroundColor: theme.palette.primary[800],
    height: '40px',
    marginRight: '17.4px', // 10.8 do icon + 6.6 da margem entre a box e o icon do TimeSlot.js
  },
  labelBox: {
    width: '100%',
    height: '40px',
    padding: '8px 10px',
    border: `solid 1px ${theme.palette.neutrals[3]}`,
  },
  inputRoot: {
    ...theme.typography.body1,
    height: theme.typography.body1.lineHeight,
    minHeight: '0px',
  },
  input: {
    padding: 0,
    ...theme.typography.body1,
    height: theme.typography.body1.lineHeight,
    color: theme.palette.neutrals[1],
  },
}));

const MatrixTime = ({ time }) => {
  const classes = useStyles();
  const dispatch = useContext(MatrixDispatchContext);

  const updateField = useCallback(
    (name, value) => {
      dispatch({
        type: 'UPDATE_FIELD',
        payload: {
          name,
          value,
        },
      });
    },
    [dispatch]
  );

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.labelBox}>
        <Body1>Tempo máximo</Body1>
      </Box>
      <Box className={classes.timeBox}>
        <Input
          classes={{ input: classes.input, root: classes.inputRoot }}
          value={time}
          type="time"
          inputProps={{
            step: 5,
          }}
          disableUnderline
          onChange={e => updateField('time', e.target.value)}
        />
      </Box>
    </Box>
  );
};

MatrixTime.propTypes = {
  time: PropTypes.string.isRequired,
};

export default React.memo(MatrixTime);
