import React, { useContext, useEffect, useMemo, useCallback } from 'react';
import ConfigList from '../../pieces/ConfigList';
import InfoSection from '../../pieces/InfoSection';
import isEmpty from '../../../../utils/isEmpty';
import { ConfigStateContext } from '../../MenuConfigProvider';
import { useDispatch, useSelector } from 'react-redux';
import { getProposalStatuses } from '../../../../store/actions/proposalsActions';
import CreateEditGeneralConfigProvider from './CreateEditGeneralConfigProvider';
import SeeEditGeneralConfig from './SeeEditGeneralConfig';
import { getEmailTemplates } from '../../../../store/actions/emailsActions';
import { getConfig } from '../../../../store/actions/configActions';

const GeneralConfigs = () => {
  const { activeItem, deleting } = useContext(ConfigStateContext);
  const { statuses } = useSelector(state => state.proposals);
  const { templates } = useSelector(state => state.emails);
  const { configs } = useSelector(state => state.config);
  const { users } = useSelector(state => state.users);
  const dispatchRedux = useDispatch();

  useEffect(() => {
    dispatchRedux(getProposalStatuses());
    dispatchRedux(getEmailTemplates());
    dispatchRedux(getConfig());
  }, [dispatchRedux]);

  let presidentID;

  try {
    presidentID = parseInt(configs.president, 10);
  } catch (e) {
    presidentID = null;
  }

  const userPresident = users.find(({ id }) => id === presidentID) || {};
  const items = useMemo(
    () => [
      { name: 'Presidente', type: 'president', content: { users, userPresident } },
      { name: 'Informação geral', type: 'general', content: { configs } },
      { name: 'Estados das propostas', type: 'proposals', content: { statuses } },
      { name: 'Templates de emails', type: 'templates', content: { templates } },
    ],
    [statuses, templates, configs, users, userPresident]
  );

  const hasPagination = useMemo(() => items.length > 10, [items]);
  const pageObj = useMemo(() => ({ isItemConfig: true }), []);

  const handleDelete = useCallback(() => null, []);

  return (
    <>
      <ConfigList
        items={items}
        pageObj={pageObj}
        noItemMessage="Nenhuma configuração geral disponível."
        labelSubmit="Excluir"
        handleDelete={handleDelete}
      />
      <InfoSection hasPagination={hasPagination}>
        <CreateEditGeneralConfigProvider items={items}>
          {!isEmpty(activeItem) && !deleting && <SeeEditGeneralConfig info={activeItem} />}
        </CreateEditGeneralConfigProvider>
      </InfoSection>
    </>
  );
};

export default GeneralConfigs;
