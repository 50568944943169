import React, { useContext, useEffect, useCallback } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import ButtonNormal from '../../common/buttons/ButtonNormal';
import { ConfigDispatchContext } from '../MenuConfigProvider';
import usePrevious from '../../../utils/hooks/usePrevious';

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonAdd: {
    marginLeft: '10px',
  },
  boxContent: {
    padding: '28px 65px',
  },
}));

const UpdateItem = ({ children, disableSubmit, loading, items, dispatch, handleSubmit }) => {
  const classes = useStyles();
  const dispatchConfig = useContext(ConfigDispatchContext);
  const prevItems = usePrevious(items);

  // @ Depois de editar, fecha o modo de edição
  useEffect(() => {
    if (prevItems !== undefined && JSON.stringify(prevItems) !== JSON.stringify(items)) {
      dispatch({
        type: 'CLEAR_STATE',
      });
      dispatchConfig({
        type: 'SET_ACTIVE_ITEM',
        payload: null,
      });
    }
  }, [items, prevItems, dispatch, dispatchConfig]);

  const cancelEditing = useCallback(
    e => {
      e.preventDefault();
      dispatchConfig({
        type: 'CANCEL_EDITING',
      });
    },
    [dispatchConfig]
  );

  return (
    <>
      <Box className={classes.box}>
        <ButtonNormal
          variant="outlined"
          color="primary"
          label="Cancelar"
          onClick={cancelEditing}
          disabled={loading}
        />
        <ButtonNormal
          contained
          pea
          onClick={handleSubmit}
          label="Guardar"
          className={classes.buttonAdd}
          disabled={loading || disableSubmit}
        />
      </Box>
      <Box className={classes.boxContent}>{children}</Box>
    </>
  );
};

UpdateItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  disableSubmit: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default UpdateItem;
