/* eslint-disable no-nested-ternary */
import React from 'react';
import { makeStyles, Grid, Box, useTheme, fade } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import isEmpty from '../../../../../utils/isEmpty';
import { setSeconds, startOfToday, addSeconds } from 'date-fns';
import formatDate from '../../../../../utils/formatDate';
import H6 from '../../../../common/typographys/H6';
import H5 from '../../../../common/typographys/H5';

const useStyles = makeStyles(theme => ({
  boxParticipant: {
    boxShadow: `0 20px 20px -10px ${fade(theme.palette.primary[100], 0.7)}`,
    height: '100%',
    minHeight: '80px',
  },
  timeLimit: {
    '& p, h6, svg': {
      color: '#FFF',
    },
  },
  boxTime: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '4px',
    padding: '0px 14px 12px 14px',
  },
  itemUser: {
    marginBottom: '20px',
  },
  participantName: {
    padding: '8px 14px 0px 14px',
  },
  voteBox: {
    padding: '7px 14px 9px 16px',
    marginTop: '15px',
    backgroundColor: theme.palette.primary[50],
  },
  common: {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
  },
  checkIcon: {
    backgroundColor: theme.palette.colorsPalette.TrueGreen,
    color: theme.palette.neutrals[1],
  },
  againstIcon: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.neutrals[1],
  },
  blankIcon: {
    backgroundColor: theme.palette.neutrals[5],
    color: theme.palette.neutrals[5],
  },
}));

const secondsToMinutes = (time, isNegative) => {
  const dateWithSeconds = addSeconds(startOfToday(), time);
  const result = formatDate(dateWithSeconds, 'HH:mm:ss');
  if (isNegative) {
    return `+${result}`;
  }

  return result;
};

const formatSeconds = seconds => {
  return formatDate(setSeconds(startOfToday(), seconds), 'HH:mm:ss');
};

const getTalkPercentage = (time, current) => {
  return ((parseInt(current, 10) * 100) / parseInt(time, 10)).toFixed(2);
};

const BlockUserInfo = ({ user, checkIsSpeaking }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { current, time, id, name, short_name } = user;

  // @ Se time === undefined -> Conta para cima porque não está limitado
  // @ Se time !== undefined -> É o limite para este ponto
  const percentage = getTalkPercentage(time, current);
  const speaking = checkIsSpeaking(id);

  return (
    <Grid item xl={4} lg={6} md={12} className={classes.itemUser}>
      <Box
        className={clsx(classes.boxParticipant, {
          [classes.timeLimit]: percentage >= 100,
        })}
        style={
          !isEmpty(time)
            ? {
                background: `linear-gradient(to right,${
                  percentage >= 100
                    ? theme.palette.error.main
                    : speaking
                    ? theme.palette.primary.A400
                    : theme.palette.primary[100]
                } ${percentage}%, ${theme.palette.neutrals[1]} ${percentage}%)`,
              }
            : { background: theme.palette.neutrals[1] }
        }
      >
        <H6 className={classes.participantName} oneLine>
          {short_name || name}
        </H6>
        <Box className={classes.boxTime}>
          {!isEmpty(time) && <H5>{secondsToMinutes(Math.abs(time - current), current >= time)}</H5>}
          {isEmpty(time) && <H5>{formatSeconds(current)}</H5>}
        </Box>
      </Box>
    </Grid>
  );
};

BlockUserInfo.propTypes = {
  user: PropTypes.object.isRequired,
  checkIsSpeaking: PropTypes.func.isRequired,
};

export default React.memo(BlockUserInfo);
