import React, { useRef, useContext } from 'react';
import { Box, makeStyles, Input } from '@material-ui/core';
import SelectOutlined from '../../../../common/forms/SelectOutlined';
import BoxIcons from './BoxIcons';
import isEmpty from '../../../../../utils/isEmpty';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { MatrixDispatchContext, MatrixFuncsContext } from '../CreateEditMatrixProvider';
import { ConfigStateContext } from '../../../MenuConfigProvider';

const useStyles = makeStyles(theme => ({
  mainBox: {
    display: 'flex',
    marginBottom: '10px',
  },
  timeBox: {
    padding: '8px 15px',
    width: '92px',
    marginLeft: '8px',
    backgroundColor: theme.palette.neutrals[3],
    height: '40px',
  },
  availableTimeBox: {
    backgroundColor: theme.palette.primary[200],
  },
  memberBox: {
    width: '100%',
  },
  inputRoot: {
    ...theme.typography.body1,
    height: theme.typography.body1.lineHeight,
    minHeight: '0px',
  },
  input: {
    padding: 0,
    ...theme.typography.body1,
    height: theme.typography.body1.lineHeight,
    color: theme.palette.neutrals[6],
  },
  inputAvailable: {
    color: theme.palette.neutrals[7],
  },
  disabled: {
    pointerEvents: 'none',
    backgroundColor: theme.palette.neutrals[2],
  },
  disabledTimebox: {
    pointerEvents: 'none',
  },
}));

const TimeSlot = ({ idx, options, value, time, type, error, chooseOnly }) => {
  const classes = useStyles();
  const inputRef = useRef(null);
  const { editing } = useContext(ConfigStateContext);
  const { getTimeValue } = useContext(MatrixFuncsContext);
  const dispatch = useContext(MatrixDispatchContext);
  const placeholder = () => {
    if (type === 'individual') return 'Adicionar participante';
    if (type === 'grupo') return 'Adicionar grupo';
    return null;
  };

  return (
    <Box className={clsx(classes.mainBox, { [classes.disabled]: isEmpty(type) })}>
      <Box className={classes.memberBox}>
        <SelectOutlined
          fullWidth
          value={value}
          options={options}
          placeholder={placeholder()}
          getOptionValue={option => option.idSel}
          getOptionLabel={option => option.name}
          onChange={e => {
            if (value === null) {
              dispatch({
                type: type === 'individual' ? 'ADD_INDIVIDUAL' : 'ADD_GROUP',
                payload: e,
              });
            } else {
              dispatch({
                type: type === 'individual' ? 'UPDATE_INDIVIDUAL' : 'UPDATE_GROUP',
                payload: {
                  idx,
                  value: e,
                },
              });
            }
          }}
          name={`member-${idx}`}
          noOptionsMessage={() => 'Sem opções'}
          error={chooseOnly && !isEmpty(error) ? error : null}
          isDisabled={editing}
        />
      </Box>
      <Box
        className={clsx(classes.timeBox, {
          [classes.availableTimeBox]: value !== null,
          [classes.disabledTimebox]: chooseOnly,
        })}
      >
        <Input
          classes={{
            root: classes.inputRoot,
            input: clsx(classes.input, { [classes.inputAvailable]: value !== null }),
          }}
          value={typeof time === 'string' ? time : getTimeValue(time)}
          type="time"
          inputProps={{
            step: 5,
            ref: inputRef,
          }}
          disableUnderline
          onChange={e => {
            dispatch({
              type: type === 'individual' ? 'UPDATE_INDIVIDUAL_TIME' : 'UPDATE_GROUP_TIME',
              payload: {
                idx,
                value: e.target.value,
              },
            });
          }}
        />
      </Box>
      <BoxIcons inputRef={inputRef} chooseOnly={chooseOnly} idx={idx} type={type} />
    </Box>
  );
};

TimeSlot.defaultProps = {
  value: null,
  time: '00:00:00',
  idx: 0,
  chooseOnly: false,
  error: null,
  type: null,
};

TimeSlot.propTypes = {
  options: PropTypes.array.isRequired,
  idx: PropTypes.number,
  value: PropTypes.object,
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  error: PropTypes.string,
  chooseOnly: PropTypes.bool,
};

export default React.memo(TimeSlot);
