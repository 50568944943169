import React, { useEffect, useState, useMemo, useContext } from 'react';
import { Grid, makeStyles, Box, Hidden } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from '../../../utils/isEmpty';
import {
  // setActualReunion,
  updateReunion,
  updateProposalVotes,
  updateReunionPointInfo,
  updatePublicDiscussionTimer,
  updateBeforeDayOrderTimer,
  updatePointDiscussionTimer,
  updateMeetingTimer,
} from '../../../store/actions/reunionsActions';
import { useParams } from 'react-router-dom';
import { SocketReunion } from './SocketReunionWrapper';
import NoProposalPlateia from './pieces/activeMeeting/NoProposalPlateia';
import ParticipantUsersPlateia from './pieces/activeMeeting/ParticipantUsersPlateia';
import H2 from '../../common/typographys/H2';
import { MeetingCommon } from './MeetingCommonWrapper';
import useForceUpdate from '../../../utils/hooks/useForceUpdate';
import PlateiaTitleDescription from './plateia/PlateiaTitleDescription';
import DiscussionOrVote from './plateia/DiscussionOrVote';
import SideXLVote from './plateia/SideXLVote';
import BottomVote from './plateia/BottomVote';
import AllProposalStatus from './plateia/AllProposalStatus';
import flatten from 'lodash/flatten';
import clsx from 'clsx';
import VotingUsersPlateia from './plateia/VotingUsersPlateia';
import PlateiaTimer from './plateia/PlateiaTimer';

const useStyles = makeStyles(theme => ({
  marginContainers: {
    marginTop: '70px',
    width: '100%',
  },
  marginHeader: {
    marginBottom: '10px',
  },
  marginPlateia: {
    marginTop: '75px',
    width: '100%',
  },
  fullSize: {
    height: '100vh',
    width: '100%',
  },
  container: {
    margin: theme.spacing(0),
    flexGrow: 0,
    maxWidth: '100%',
    flexBasis: '100%',

    overflow: 'hidden',
  },
  timeContainer: {
    margin: theme.spacing(0),
    flexGrow: 0,
    maxWidth: '100%',
  },
  fullHeight: {
    height: '100vh',
  },
  drawer: {
    width: '100%',
    height: '100%',
    flexShrink: 0,
  },
  drawerPaper: {
    width: '100%',
    position: 'static',
    marginLeft: '12px',
    paddingTop: '12px',
    overflowX: 'hidden',
  },
  noPadding: {
    padding: '0px!important',
  },
  heightBox: {
    [theme.breakpoints.up('xl')]: {
      height: '50px',
    },
    [theme.breakpoints.down('lg')]: {
      height: '10px',
    },
  },
  bottomVote: {
    padding: '0px!important',
    marginTop: 'auto',
  },
  fullWidth: {
    width: '100%',
  },
  leftMenu: {
    overflow: 'hidden',
    [theme.breakpoints.up('xl')]: {
      maxHeight: '940px',
    },
    [theme.breakpoints.down('lg')]: {
      maxHeight: '540px',
    },
  },
}));

const periods = [
  { type: 'public-discussion', name: 'Intervenção do público' },
  { type: 'before-day-order', name: 'Antes da ordem do dia' },
  // { type: 'day-order', name: 'Ordem do dia' },
];

const MeetingPlateia = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id } = useParams();
  const { reunion } = useSelector(state => state.reunions);
  const { speakers, is_group, infoSpeaking, participants } = reunion;
  const [active, setActive] = useState({});
  const [activeInfo, setActiveInfo] = useState({});
  const [typeVote, setTypeVote] = useState('');
  const [voting, setVoting] = useState(false);
  const [votes, setVotes] = useState([]);
  const [secretVotes, setSecretVotes] = useState({ favor: 0, against: 0, abstention: 0 });
  const { socketNsp, email } = useContext(SocketReunion);
  const { reunionEnd, setReunionEnd } = useContext(MeetingCommon);
  const [forceUpdate] = useForceUpdate();
  const [, setAux] = useState('');
  const publicDiscussionTimer = useSelector(
    state => state.reunions?.reunion?.timers?.publicDiscussion || {}
  );
  const beforeDayOrderTimer = useSelector(
    state => state.reunions?.reunion?.timers?.beforeDayOrder || {}
  );
  const discussionPointsTimers = useSelector(
    state => state.reunions?.reunion?.timers?.points || {}
  );
  const meetingTimer = useSelector(state => state.reunions?.reunion?.timers?.meeting || {});

  const activeParticipants = useMemo(() => participants.filter(x => x.checkIn), [participants]);

  const proposals = useMemo(() => {
    const flattenThemes = flatten(reunion.themes.map(theme => [...theme.points]));
    const allProposals = [...flattenThemes, ...reunion.points];

    return allProposals;
  }, [reunion.themes, reunion.points]);

  useEffect(() => {
    if (!isEmpty(reunion.activeInfo) && reunion.sentSocket) {
      setActiveInfo(reunion.activeInfo);
    }
  }, [reunion.sentSocket, reunion.activeInfo]);

  useEffect(() => {
    if (!isEmpty(activeInfo)) {
      const { idxProposal, idxTheme, type } = activeInfo;
      if (idxProposal !== null && idxTheme !== null) {
        // @ É uma proposta inserida num tema
        const result = reunion.themes[idxTheme].points[idxProposal] || null;
        setActive(result);
      } else if (idxProposal !== null && idxTheme === null) {
        // @ É uma proposa sem tema
        const result = reunion.points[idxProposal] || null;
        setActive(result);
      } else if (idxProposal === null && idxTheme !== null) {
        // @ Tema sem pontos
        const result = reunion.themes[idxTheme] || null;
        setActive(result);
      } else if (idxProposal === null && idxTheme === null) {
        // @ É um período
        const period = periods.find(x => x.type === type) || {};
        setActive(period);
      }
    }
  }, [activeInfo, reunion.themes, reunion.points]);

  useEffect(() => {
    const voteCanceledHandler = info => {
      const parsed = JSON.parse(info);
      setVoting(false);
      forceUpdate();
      setAux(parsed);
    };

    socketNsp.on('vote_canceled', voteCanceledHandler);

    return () => {
      socketNsp.off('vote_canceled', voteCanceledHandler);
    };
  }, [forceUpdate, socketNsp]);

  useEffect(() => {
    socketNsp.on('reunion_details', info => {
      dispatch(updateReunion(JSON.parse(info)));
    });

    socketNsp.on('reunion_speakers', info => {
      const parsed = JSON.parse(info);
      dispatch(updateReunion({ speakers: parsed }));
    });

    socketNsp.on('current_public_time', info => {
      const parsed = JSON.parse(info);
      dispatch(updateReunion({ currentPublic: parsed }));
    });

    socketNsp.on('reunion_info_speaking', info => {
      const parsed = JSON.parse(info);
      dispatch(updateReunion({ infoSpeaking: parsed }));
    });

    socketNsp.on('reunion_speakers_info', info => {
      const parsed = JSON.parse(info);
      dispatch(updateReunion({ speakersInfo: parsed }));
    });

    socketNsp.on('update_meeting_point_status', info => {
      const parsed = JSON.parse(info);
      dispatch(updateReunionPointInfo(parsed));
      setVoting(false);
      setActive(c => ({ ...c, meeting_point_status: parsed.meeting_point_status }));
    });

    socketNsp.on('update_votes', info => {
      const parsed = JSON.parse(info);
      setVotes(parsed);
    });

    socketNsp.on('update_secret_votes', info => {
      const parsed = JSON.parse(info);
      setSecretVotes(parsed);
    });

    socketNsp.on('update_active', info => {
      const parsedInfo = JSON.parse(info);
      setActiveInfo(parsedInfo);
    });

    socketNsp.on('start_vote', info => {
      const { type } = JSON.parse(info);
      setTypeVote(type);
      setVoting(true);
    });

    socketNsp.on('final_votes', info => {
      setVoting(false);
      dispatch(updateProposalVotes(JSON.parse(info)));
      setVotes([]);
    });

    socketNsp.on('vote', info => {
      const parsed = JSON.parse(info);
      const { userId: userIdVoted, groupId, vote } = parsed;
      setVotes(c => [
        ...c.filter(x => x.id.toString() !== userIdVoted.toString()),
        { id: userIdVoted, group_id: groupId, vote },
      ]);
    });

    socketNsp.on('update_votes', info => {
      const parsed = JSON.parse(info);
      setVotes(parsed);
    });

    socketNsp.on('reunion_finished', () => {
      setReunionEnd(true);
    });

    socketNsp.on('reunion_speakers', info => {
      const parsed = JSON.parse(info);
      dispatch(updateReunion({ speakers: parsed }));
    });

    socketNsp.on('reunion_info_speaking', info => {
      const parsed = JSON.parse(info);
      dispatch(updateReunion({ infoSpeaking: parsed }));
    });

    socketNsp.on('reunion_speakers_info', info => {
      const parsed = JSON.parse(info);
      dispatch(updateReunion({ speakersInfo: parsed }));
    });

    socketNsp.on('update_meeting_point_status', info => {
      const parsed = JSON.parse(info);
      dispatch(updateReunionPointInfo(parsed));
      setActive(c => ({ ...c, meeting_point_status: parsed.meeting_point_status }));
    });

    socketNsp.on('list_presences', info => {
      dispatch(updateReunion(JSON.parse(info)));
    });

    socketNsp.on('public_discussion_timer_updated', updatedTimerPayload => {
      const timerState = JSON.parse(updatedTimerPayload).publicDiscussion;

      dispatch(updatePublicDiscussionTimer(timerState));
    });

    socketNsp.on('before_day_order_timer_updated', updatedTimerPayload => {
      const timerState = JSON.parse(updatedTimerPayload).beforeDayOrder;

      dispatch(updateBeforeDayOrderTimer(timerState));
    });

    socketNsp.on('point_discussion_timer_updated', updatedTimerPayload => {
      const { pointId, timerType, timerState } = JSON.parse(updatedTimerPayload);

      dispatch(updatePointDiscussionTimer(pointId, timerType, timerState));
    });

    socketNsp.on('meeting_timer_updated', updatedTimerPayload => {
      const timerState = JSON.parse(updatedTimerPayload).meeting;

      dispatch(updateMeetingTimer(timerState));
    });

    socketNsp.emit('join', JSON.stringify({ id, email, plateia: true }));

    return () => {
      socketNsp.emit('leave', JSON.stringify({ id, email }));
      socketNsp.removeAllListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getActiveDiscussionPointTimerId = () =>
    active.proposal_id !== null ? `proposal-${active.id}` : `point-${active.id}`;

  const getActiveDiscussionPointTimer = type => {
    const timerId = getActiveDiscussionPointTimerId();

    return discussionPointsTimers[timerId][type];
  };

  return !reunionEnd ? (
    <div className={classes.fullHeight}>
      {!!meetingTimer.state && (
        <Grid container spacing={3} justify="center" className={classes.timeContainer}>
          <Grid item xs={12}>
            <PlateiaTimer
              timer={meetingTimer}
              inlineLabel
              label={`Duração da reunião de ${reunion?.date?.split(' ')[0]} - `}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={3} justify="center" className={classes.container}>
        <Grid
          item
          xl={voting ? 4 : 3}
          lg={5}
          md={6}
          className={clsx({ [classes.leftMenu]: voting })}
        >
          <Box className={classes.heightBox} />
          <Grid container justify="center" spacing={1}>
            <Grid item xs={voting ? 10 : 12}>
              {!isEmpty(active) ? (
                <>
                  <PlateiaTitleDescription title={active.name} />
                  {active.type === 'public-discussion' && (
                    <PlateiaTimer
                      timer={publicDiscussionTimer}
                      label="Tempo de fala do interveniente"
                    />
                  )}
                  {active.type === 'before-day-order' && (
                    <PlateiaTimer timer={beforeDayOrderTimer} label="Tempo do período ativo" />
                  )}
                  {active.type !== 'before-day-order' && active.type !== 'public-discussion' && (
                    <>
                      <PlateiaTimer
                        timer={getActiveDiscussionPointTimer('presentation')}
                        label="Período de apresentação da proposta"
                      />
                      <PlateiaTimer
                        timer={getActiveDiscussionPointTimer('protests')}
                        label="Período de protestos / defesa de honra / reações"
                      />
                    </>
                  )}
                  {/* <PlateiaTitleDescription title={active.name} description={active.description} /> */}
                  <AllProposalStatus proposals={proposals} />
                </>
              ) : (
                <NoProposalPlateia />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={voting ? 6 : 7} lg={7} md={6}>
          <Box className={classes.heightBox} />
          <DiscussionOrVote voting={voting} />
          <Box height="40px" />
          <Grid container spacing={2}>
            {!voting && speakers && speakers.length > 0 && (
              <ParticipantUsersPlateia
                users={speakers}
                participants={participants}
                isGroup={Boolean(is_group)}
                infoSpeaking={infoSpeaking}
              />
            )}
            {voting && activeParticipants && (
              <VotingUsersPlateia
                participants={activeParticipants}
                votes={votes}
                typeVote={typeVote}
                secretVotes={secretVotes}
                maxVotes={activeParticipants && activeParticipants.length}
              />
            )}
          </Grid>
        </Grid>
        {voting && typeVote !== 'secret' && (
          <>
            <Hidden lgDown>
              <Grid item xl={2} className={classes.noPadding}>
                <SideXLVote
                  votes={votes}
                  maxVotes={activeParticipants && activeParticipants.length}
                />
              </Grid>
            </Hidden>
            <Hidden only="xl">
              <Grid item xs={12} className={classes.bottomVote}>
                <BottomVote
                  votes={votes}
                  maxVotes={activeParticipants && activeParticipants.length}
                />
              </Grid>
            </Hidden>
          </>
        )}
      </Grid>
    </div>
  ) : (
    <Grid container justify="center" alignItems="center" className={classes.fullSize}>
      <H2>Reunião terminada</H2>
    </Grid>
  );
};

export default MeetingPlateia;
