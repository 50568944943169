import {
  GET_MEETINGS,
  GET_MEETING,
  MEETING_POOL,
  MEETING_LOCALS,
  UPDATE_MEETING,
  MEETINGS_LOADING,
  MEETINGS_NOT_LOADING,
  GET_MEETING_MATRICES,
  CREATE_MEETING,
  SET_USER_ACCEPTED,
  CANCEL_MEETING,
  DELETE_MEETING,
  MEETING_SECRETARIES,
  MEETING_MODERATORS,
  BRIEF_AVAILABLE,
  REMOVE_DECLARATION,
  USER_MEETING_DECLARATIONS,
  USER_MEETING_POINT_DECLARATIONS,
  USER_MEETING_DECLARATIONS_CHANGES,
  UPDATE_MEETING_INFO,
  UPDATE_PROPOSAL_MEETING_DELIBERATION_NUMBER,
  UPDATE_PROPOSAL_MEETING_DELIBERATION_FILE_NAME,
} from '../actions/types';

const initialState = {
  meetings: [],
  meeting: {},
  pool: {},
  locals: [],
  matrices: [],
  secretaries: [],
  moderators: [],
  declarations: [],
  pointDeclarations: [],
  changes: [],
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MEETINGS:
      return {
        ...state,
        meetings: action.payload,
        loading: false,
      };
    case GET_MEETING:
      return {
        ...state,
        meeting: action.payload,
        loading: false,
      };
    case MEETING_POOL:
      return {
        ...state,
        pool: action.payload,
        loading: false,
      };
    case GET_MEETING_MATRICES:
      return {
        ...state,
        matrices: action.payload,
        loading: false,
      };
    case MEETING_LOCALS:
      return {
        ...state,
        meeting: {
          ...state.meeting,
          typology: {
            ...state.meeting.typology,
            locals: action.payload,
          },
        },
        locals: action.payload,
        loading: false,
      };
    case CREATE_MEETING:
      return {
        ...state,
        meetings: [action.payload, ...state.meetings],
        loading: false,
      };
    case UPDATE_MEETING:
      return {
        ...state,
        meetings: state.meetings.map(x => (x.id === action.payload.id ? action.payload : x)),
        loading: false,
      };
    case UPDATE_MEETING_INFO:
      return {
        ...state,
        meeting: {
          ...state.meeting,
          ...action.payload,
        },
      };
    case MEETING_SECRETARIES:
      return {
        ...state,
        secretaries: action.payload,
      };
    case MEETING_MODERATORS:
      return {
        ...state,
        moderators: action.payload,
      };
    case CANCEL_MEETING:
      return {
        ...state,
        meeting: {
          ...state.meeting,
          status: -1,
        },
        loading: false,
      };
    case DELETE_MEETING:
      return {
        ...state,
        meetings: state.meetings.filter(meeting => `${meeting.id}` !== `${action.payload.id}`),
        loading: false,
      };
    case BRIEF_AVAILABLE:
      return {
        ...state,
        meeting: {
          ...state.meeting,
          brief_available: true,
          brief_changed: false,
        },
      };
    case SET_USER_ACCEPTED:
      return {
        ...state,
        meeting: {
          ...state.meeting,
          users: action.payload.isGroup
            ? state.meeting.users.map(group => ({
                ...group,
                users: group.users.map(user =>
                  user.id === action.payload.id
                    ? { ...user, accepted: action.payload.accepted }
                    : user
                ),
              }))
            : state.meeting.users.map(user =>
                user.id === action.payload.id
                  ? { ...user, accepted: action.payload.accepted }
                  : user
              ),
        },
      };
    case REMOVE_DECLARATION:
      return {
        ...state,
        declarations: state.declarations.filter(x => x.id !== action.payload),
        pointDeclarations: state.pointDeclarations.filter(x => x.id !== action.payload),
        loading: false,
      };
    case USER_MEETING_DECLARATIONS:
      return {
        ...state,
        declarations: action.payload,
        loading: false,
      };
    case USER_MEETING_POINT_DECLARATIONS:
      return {
        ...state,
        pointDeclarations: action.payload,
        loading: false,
      };
    case USER_MEETING_DECLARATIONS_CHANGES:
      return {
        ...state,
        changes: action.payload,
        loading: false,
      };
    case MEETINGS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case MEETINGS_NOT_LOADING:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_PROPOSAL_MEETING_DELIBERATION_NUMBER: {
      return {
        ...state,
        meeting: {
          ...state.meeting,
          points: state.meeting.points.map(point => {
            if (`${point.id}` === `${action.payload.idPoint}`) {
              return {
                ...point,
                meeting_point_status: {
                  ...point.meeting_point_status,
                  deliberation_number: action.payload.deliberationNumber,
                },
              };
            }

            return point;
          }),
        },
      };
    }
    case UPDATE_PROPOSAL_MEETING_DELIBERATION_FILE_NAME: {
      return {
        ...state,
        meeting: {
          ...state.meeting,
          points: state.meeting.points.map(point => {
            if (`${point.id}` === `${action.payload.idPoint}`) {
              return {
                ...point,
                meeting_point_status: {
                  ...point.meeting_point_status,
                  file_name: action.payload.fileName,
                },
              };
            }

            return point;
          }),
        },
      };
    }
    default:
      return state;
  }
};
