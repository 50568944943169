import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Box, Link } from '@material-ui/core';
import isEmpty from '../../utils/isEmpty';

const ConfigLinks = () => {
  const links = useSelector(state => state.config?.configs?.links);

  if (isEmpty(links)) return null;

  return (
    <Box width="100%" display="flex" justifyContent="center">
      {Object.keys(links)
        .filter(key => !isEmpty(links[key]?.url) && !isEmpty(links[key]?.label))
        .sort()
        .map((key, index, keys) => {
          const { url, label } = links[key];
          const isLast = index === keys.length - 1;

          return (
            <Fragment key={key}>
              <Link href={url} color="inherit" rel="noopener noreferrer" target="_blank">
                {label.toUpperCase()}
              </Link>
              {!isLast && <span>&nbsp;-&nbsp;</span>}
            </Fragment>
          );
        })}
    </Box>
  );
};

export default ConfigLinks;
