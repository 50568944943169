import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import ConfigList from '../../pieces/ConfigList';
import InfoSection from '../../pieces/InfoSection';
import isEmpty from '../../../../utils/isEmpty';
import { ConfigStateContext, ConfigDispatchContext } from '../../MenuConfigProvider';
import { useDispatch, useSelector } from 'react-redux';
import { removeEntity, getEntities } from '../../../../store/actions/entitiesActions';
import SeeEditEntity from './SeeEditEntity';
import CreateEditEntityProvider from './CreateEditEntityProvider';
import AddNewEntity from './AddNewEntity';

const Entities = () => {
  const { entities = [] } = useSelector(state => state.entities);
  const { permissions } = useSelector(state => state.auth.user);
  const { activeItem, isAddNew, deleting } = useContext(ConfigStateContext);
  const [search, setSearch] = useState('');
  const dispatchConfig = useContext(ConfigDispatchContext);
  const dispatchRedux = useDispatch();

  useEffect(() => {
    dispatchRedux(getEntities());
  }, [dispatchRedux]);

  const items = useMemo(() => {
    if (isEmpty(search)) {
      return entities;
    }

    return entities.filter(x => x.name.toLowerCase().indexOf(search.toLowerCase()) !== -1);
  }, [search, entities]);

  const hasPagination = useMemo(() => items.length > 10, [items]);

  const pageObj = useMemo(() => ({ isItemEntity: true }), []);

  const handleDelete = useCallback(
    e => {
      e.preventDefault();
      dispatchRedux(
        removeEntity(activeItem.id, () =>
          dispatchConfig({
            type: 'CANCEL_ADD_NEW',
          })
        )
      );
    },
    [dispatchRedux, activeItem, dispatchConfig]
  );

  return (
    <>
      <ConfigList
        items={items}
        pageObj={pageObj}
        labelButton="Nova entidade"
        noItemMessage="Nenhuma entidade adicionada."
        msgDelete={`Tem a certeza que deseja excluir a entidade${activeItem && activeItem.name ? `, ${activeItem.name}` : ''}?`}
        labelSubmit="Excluir"
        canDelete={Boolean(permissions && permissions.others && permissions.others.entities)}
        canAddNew={permissions && permissions.others && permissions.others.entities}
        handleDelete={handleDelete}
        search={search}
        setSearch={setSearch}
      />
      <InfoSection hasPagination={hasPagination}>
        <CreateEditEntityProvider items={entities}>
          {!isEmpty(activeItem) && !deleting && <SeeEditEntity info={activeItem} />}
          {isAddNew && <AddNewEntity />}
        </CreateEditEntityProvider>
      </InfoSection>
    </>
  );
};

export default Entities;
