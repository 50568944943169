import React from 'react';
import PropTypes from 'prop-types';
import DraggableParticipant from './DraggableParticipant';

const ParticipantsList = ({ participants }) => {
  return participants.map(({ name, id }, idx) => (
    <DraggableParticipant key={name} name={name} id={id} idx={idx} />
  ));
};

ParticipantsList.propTypes = {
  participants: PropTypes.array.isRequired,
};

export default ParticipantsList;
