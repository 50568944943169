import axios from 'axios';
import {
  GET_LOCALS,
  CREATE_LOCAL,
  GET_ERRORS,
  LOCALS_LOADING,
  LOCALS_NOT_LOADING,
  SHOW_SNACK,
  UPDATE_LOCAL,
  REMOVE_LOCAL,
} from './types';

export const getLocals = () => dispatch => {
  dispatch(setLocalsLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/locals`)
    .then(res => {
      dispatch({
        type: GET_LOCALS,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(setLocalsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const createLocal = info => dispatch => {
  dispatch(setLocalsLoading());

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/locals`, info)
    .then(res => {
      dispatch({
        type: CREATE_LOCAL,
        payload: { ...res.data, can_delete: true },
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Local criado com sucesso.',
        },
      });
    })
    .catch(err => {
      dispatch(setLocalsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateLocal = (id, info) => dispatch => {
  dispatch(setLocalsLoading());

  axios
    .put(`${process.env.REACT_APP_API_URL}/api/locals/${id}`, info)
    .then(res => {
      dispatch({
        type: UPDATE_LOCAL,
        payload: res.data,
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Local atualizado com sucesso.',
        },
      });
    })
    .catch(err => {
      dispatch(setLocalsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const removeLocal = (id, callback) => dispatch => {
  dispatch(setLocalsLoading());

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/locals/${id}/disable`)
    .then(() => {
      dispatch({
        type: REMOVE_LOCAL,
        payload: {
          id,
        },
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Local excluído com sucesso.',
        },
      });
      callback();
    })
    .catch(err => {
      dispatch(setLocalsNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const setLocalsLoading = () => {
  return {
    type: LOCALS_LOADING,
  };
};

export const setLocalsNotLoading = () => {
  return {
    type: LOCALS_NOT_LOADING,
  };
};
