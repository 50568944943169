import React, { useEffect, useState, useCallback } from 'react';
import CreateEditMeetingsProvider from '../CreateEditMeetingsProvider';
import { useDispatch } from 'react-redux';
import { changeMenuSidebar } from '../../../store/actions/navigationActions';
import FormInfoMeeting from '../FormInfoMeeting';
import { getAllTypologies, clearTypology } from '../../../store/actions/typologiesActions';
import { getUsers } from '../../../store/actions/usersActions';
import { useHistory } from 'react-router-dom';

const NewMeeting = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [change, setChange] = useState(true);

  useEffect(() => {
    dispatch(changeMenuSidebar('reunioes', 'Reuniões', 'Adicionar nova reunião'));
    dispatch(getAllTypologies());
    dispatch(getUsers());
    return () => {
      dispatch(clearTypology());
    };
  }, [dispatch]);

  const navigate = useCallback(
    page => {
      setChange(false);
      history.push(page);
    },
    [history]
  );

  return (
    <CreateEditMeetingsProvider setChange={setChange} navigate={navigate}>
      <FormInfoMeeting change={change} />
    </CreateEditMeetingsProvider>
  );
};

export default NewMeeting;
