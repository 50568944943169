/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body1 from '../../../../common/typographys/Body1';
import { addSeconds, startOfToday } from 'date-fns';
import formatDate from '../../../../../utils/formatDate';
import Body2 from '../../../../common/typographys/Body2';
import { PauseCircleFilled, PlayCircleFilled } from '@material-ui/icons';
import clsx from 'clsx';
import isEmpty from '../../../../../utils/isEmpty';
import useInterval from '../../../../../utils/hooks/useInterval';
import { updatePeriodSpeakTime } from '../../../../../store/actions/meetingsActions';
import { useDispatch } from 'react-redux';
import { updatePublicTime, updateReunion } from '../../../../../store/actions/reunionsActions';
import { useParams } from 'react-router-dom';
import Subtitle2 from '../../../../common/typographys/Subtitle2';

const useStyles = makeStyles(theme => ({
  boxUser: {
    padding: '0px 14px 16px 14px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '4px',
  },
  buttonPlay: {
    fill: theme.palette.primary[300],
    '&:hover': {
      cursor: 'pointer',
      fill: theme.palette.primary[500],
    },
  },
  buttonPause: {
    fill: theme.palette.primary.A700,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  common: {
    marginLeft: '20px',
    width: '16px',
    height: '16px',
  },
  time: {
    marginLeft: '10px',
  },
  name: {
    width: '100%',
  },
  label: {
    padding: '20px 14px 14px 14px',
  },
}));

const secondsToMinutes = time => {
  const dateWithSeconds = addSeconds(startOfToday(), time);

  return formatDate(dateWithSeconds, 'HH:mm:ss');
};

const PublicDiscussionTimer = ({ current, moderator, reunionEnd, infoSpeaking, activeInfo }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id: meetingId } = useParams();
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    return () => {
      if (isRunning) {
        setIsRunning(false);
      }
    };
  }, [isRunning]);

  useEffect(() => {
    setIsRunning(false);
  }, [activeInfo]);

  const isSpeaking = useMemo(() => {
    if (!isEmpty(infoSpeaking)) {
      if (isRunning && infoSpeaking.userId === null && infoSpeaking.groupId === null && infoSpeaking.public === true) {
        return true;
      }

      setIsRunning(false);
      return false;
    }

    setIsRunning(false);
    return false;
  }, [infoSpeaking, isRunning]);

  useInterval(
    () => {
      dispatch(updatePublicTime(parseInt(current, 10) + 1));
    },
    isRunning ? 1000 : null
  );

  const pausePublic = useCallback(
    time => e => {
      e.preventDefault();
      dispatch(updateReunion({ infoSpeaking: null }));
      const infoTime = { time, userId: null };
      dispatch(updatePeriodSpeakTime(meetingId, 'public-discussion', infoTime));
      setIsRunning(false);
    },
    [dispatch, meetingId]
  );

  const playPublic = useCallback(
    e => {
      e.preventDefault();

      // @ Atualiza a info de quem passou a falar
      dispatch(updateReunion({ infoSpeaking: { userId: null, groupId: null, public: true } }));
      setIsRunning(true);
    },
    [dispatch]
  );

  return (
    <>
      <Box className={classes.label}>
        <Subtitle2>Tempo oratório</Subtitle2>
      </Box>
      <Box className={classes.boxUser}>
        <Body1 className={classes.name} oneLine>
          Público
        </Body1>
        <Body2 className={classes.time}>{secondsToMinutes(current)}</Body2>
        {moderator && !reunionEnd ? (
          isSpeaking ? (
            <PauseCircleFilled className={clsx(classes.common, classes.buttonPause)} onClick={pausePublic(current)} />
          ) : (
            <PlayCircleFilled className={clsx(classes.common, classes.buttonPlay)} onClick={playPublic} />
          )
        ) : null}
      </Box>
    </>
  );
};

PublicDiscussionTimer.defaultProps = {
  moderator: false,
  reunionEnd: false,
  activeInfo: null,
  infoSpeaking: {},
};

PublicDiscussionTimer.propTypes = {
  current: PropTypes.number.isRequired,
  moderator: PropTypes.bool,
  reunionEnd: PropTypes.bool,
  activeInfo: PropTypes.object,
  infoSpeaking: PropTypes.object,
};

export default React.memo(PublicDiscussionTimer);
