import { GET_MATRICES, UPDATE_MATRIX, MATRICES_LOADING, MATRICES_NOT_LOADING, CREATE_MATRIX, GET_MATRIX, REMOVE_MATRIX } from '../actions/types';

const initialState = {
  matrices: [],
  matrix: {
    groups: [],
  },
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MATRICES:
      return {
        ...state,
        matrices: action.payload,
        loading: false,
      };
    case GET_MATRIX:
      return {
        ...state,
        matrix: action.payload,
        loading: false,
      };
    case CREATE_MATRIX:
      return {
        ...state,
        matrices: [action.payload, ...state.matrices],
        loading: false,
      };
    case UPDATE_MATRIX:
      return {
        ...state,
        matrices: state.matrices.map(x => (x.id === action.payload.id ? action.payload : x)),
        loading: false,
      };
    case REMOVE_MATRIX:
      return {
        ...state,
        matrices: state.matrices.filter(x => x.id !== action.payload.id),
        loading: false,
      };
    case MATRICES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case MATRICES_NOT_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
