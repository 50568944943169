import React from 'react';
import { /* makeStyles, InputAdornment, */ Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Creatable from '../../../common/forms/Creatable';
import { useSelector } from 'react-redux';
import isEmpty from '../../../../utils/isEmpty';
// import InputField from '../../../common/forms/InputField';
// import CurrencyFormat from './CurrencyFormat';
import NewEntityInfo from './NewEntityInfo';

// const useStyles = makeStyles(theme => ({
//   itemAmount: {
//     backgroundColor: theme.palette.neutrals[3],
//   },
// }));

const commonProps = {
  fullWidth: true,
  isClearable: true,
  getOptionValue: option => option.id,
  getOptionLabel: option => option.name,
};

const Entity = ({ state, updateField, updateEntityField }) => {
  // const classes = useStyles();
  const { entities } = useSelector(stateRedux => stateRedux.entities);
  const { entity, /* amount, */ errors } = state;

  return (
    <>
      <Creatable
        {...commonProps}
        label="Entidade"
        options={entities}
        value={entity}
        handleChange={val => updateField('entity', val)}
        placeholder="Selecionar entidade"
        name="entity"
        noOptionsMessage={() => 'Sem entidades'}
        helperText={
          isEmpty(entity) ? 'Selecionar entidade caso a proposta esteja associada a uma.' : null
        }
        formatCreateLabel={inputValue => `Criar nova entidade - ${inputValue}`}
        getNewOptionData={(inputValue, optionLabel) => ({
          id: inputValue,
          name: optionLabel,
          isNew: true,
        })}
      />
      {entity && entity.isNew && (
        <NewEntityInfo entity={entity} errors={errors} updateEntityField={updateEntityField} />
      )}
      {/* {!isEmpty(entity) ? (
        <>
          <Box height="20px" />
          <InputField
            label="Valor da proposta"
            helperText="Valor associado à proposta"
            name="amount"
            value={amount}
            margin="none"
            placeholder="0 000.00"
            onChange={(nameInput, value) => updateField(nameInput, value)}
            InputProps={{
              startAdornment: <InputAdornment position="start">€</InputAdornment>,
              inputComponent: CurrencyFormat,
              className: classes.itemAmount,
            }}
          />
        </>
      ) : ( */}
      <Box height="20px" />
      {/* )} */}
    </>
  );
};

Entity.propTypes = {
  state: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
  updateEntityField: PropTypes.func.isRequired,
};

export default React.memo(Entity);
