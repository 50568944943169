import React, { useCallback } from 'react';
import {
  makeStyles,
  Dialog,
  DialogContent,
  Box,
  Button,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import H6 from '../../../../common/typographys/H6';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Body2 from '../../../../common/typographys/Body2';
import H5 from '../../../../common/typographys/H5';
import CheckboxPicker from '../../../../common/forms/CheckboxPicker';
import { ReactComponent as VoteFavor } from '../../../../../assets/images/dialogVoteFavor.svg';
import { ReactComponent as VoteAgainst } from '../../../../../assets/images/dialogVoteAgainst.svg';
import { ReactComponent as VoteAbstention } from '../../../../../assets/images/dialogVoteAbstention.svg';

const useStyles = makeStyles(theme => ({
  content: {
    padding: '50px 60px',
  },
  paddingTitle: {
    padding: '40px 60px 55px 60px',
  },
  boxButton: {
    marginTop: '50px',
  },
  buttonCancel: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  buttonConfirm: {
    color: theme.palette.neutrals[1],
    backgroundColor: theme.palette.primary.A400,
    '&:hover': {
      color: theme.palette.neutrals[1],
      backgroundColor: theme.palette.primary.A700,
    },
  },
  voteItemCommon: {
    height: '124px',
    backgroundColor: theme.palette.primary[50],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: theme.palette.primary[100],
    },
  },
  selectedBox: {
    backgroundColor: theme.palette.primary[200],
  },
  favor: {
    '& *': {
      fill: `${theme.palette.colorsPalette.TrueGreen}!important`,
    },
  },
  against: {
    '& *': {
      fill: `${theme.palette.error.main}!important`,
    },
  },
  abstention: {
    '& *': {
      fill: `${theme.palette.colorsPalette.Orange}!important`,
    },
  },
  voteFavor: {
    transition: 'fill 0.2s ease',
    '&:hover': {
      cursor: 'pointer',
      '& *': {
        fill: `${theme.palette.colorsPalette.TrueGreen}!important`,
      },
    },
  },
  voteAgainst: {
    transition: 'fill 0.2s ease',
    '&:hover': {
      cursor: 'pointer',
      '& *': {
        fill: `${theme.palette.error.main}!important`,
      },
    },
  },
  voteAbstention: {
    transition: 'fill 0.2s ease',
    '&:hover': {
      cursor: 'pointer',
      '& *': {
        fill: `${theme.palette.colorsPalette.Orange}!important`,
      },
    },
  },
  common: {
    color: theme.palette.neutrals[3],
    backgroundColor: theme.palette.neutrals[1],
    borderRadius: '50%',
    width: '50px',
    height: '50px',
  },
  whiteColor: {
    color: `${theme.palette.neutrals[1]}!important`,
  },
  selectedVote: {
    backgroundColor: theme.palette.primary.A200,
  },
  spacingBoxes: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  marginLabel: {
    marginBottom: '6px',
  },
  marginTheme: {
    marginBottom: '1px',
  },
  boxDeclaration: {
    padding: '9px 14px',
    marginTop: '4px',
    backgroundColor: theme.palette.primary[50],
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary[100],
    },
  },
}));

const DeclarationType = ({ declaration, setDeclaration }) => {
  const classes = useStyles();
  const handleChange = useCallback(
    value => e => {
      e.preventDefault();
      if (value === declaration) {
        return setDeclaration('');
      }

      return setDeclaration(value);
    },
    [setDeclaration, declaration]
  );

  return (
    <>
      <Box className={classes.boxDeclaration} onClick={handleChange('oral')}>
        <CheckboxPicker
          label="Definir declaração de voto oral"
          checked={declaration === 'oral'}
          onClick={handleChange('oral')}
        />
      </Box>
      <Box className={classes.boxDeclaration} onClick={handleChange('file')}>
        <CheckboxPicker
          label="Definir declaração de voto por ficheiro"
          checked={declaration === 'file'}
          onClick={handleChange('file')}
        />
      </Box>
    </>
  );
};

DeclarationType.propTypes = {
  declaration: PropTypes.string.isRequired,
  setDeclaration: PropTypes.func.isRequired,
};

const DialogVote = ({
  open,
  proposal,
  handleSubmit,
  setVote,
  vote,
  isVoteBlocked,
  declaration,
  setDeclaration,
}) => {
  const classes = useStyles();

  const updateVote = useCallback(
    value => e => {
      e.preventDefault();

      setVote(value);
    },
    [setVote]
  );

  return (
    <Dialog maxWidth="md" open={open} fullWidth disableBackdropClick disableEscapeKeyDown>
      <DialogTitle className={classes.paddingTitle} disableTypography>
        <Body2 secondary className={classes.marginLabel}>
          Proposta em votação
        </Body2>
        <H5>{proposal.name || ' '}</H5>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {!isVoteBlocked ? (
          <>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Box
                  className={clsx(classes.voteItemCommon, classes.voteFavor, {
                    [classes.selectedBox]: vote === 'favor',
                  })}
                  onClick={updateVote('favor')}
                >
                  <VoteFavor className={clsx({ [classes.favor]: vote === 'favor' })} />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  className={clsx(classes.voteItemCommon, classes.voteAgainst, {
                    [classes.selectedBox]: vote === 'against',
                  })}
                  onClick={updateVote('against')}
                >
                  <VoteAgainst className={clsx({ [classes.against]: vote === 'against' })} />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  className={clsx(classes.voteItemCommon, classes.voteAbstention, {
                    [classes.selectedBox]: vote === 'abstention',
                  })}
                  onClick={updateVote('abstention')}
                >
                  <VoteAbstention
                    className={clsx({ [classes.abstention]: vote === 'abstention' })}
                  />
                </Box>
              </Grid>
            </Grid>
            <DeclarationType declaration={declaration} setDeclaration={setDeclaration} />
            <Box className={classes.boxButton}>
              <Button
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                disabled={vote === ''}
                className={classes.buttonConfirm}
              >
                Confirmar voto {declaration !== '' ? ' e pedido de declaração de voto' : null}
              </Button>
            </Box>
          </>
        ) : (
          <H6>O seu voto está bloqueado para esta proposta.</H6>
        )}
      </DialogContent>
    </Dialog>
  );
};

DialogVote.defaultProps = {
  vote: '',
  isVoteBlocked: false,
};

DialogVote.propTypes = {
  open: PropTypes.bool.isRequired,
  proposal: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setVote: PropTypes.func.isRequired,
  declaration: PropTypes.string.isRequired,
  setDeclaration: PropTypes.func.isRequired,
  vote: PropTypes.string,
  isVoteBlocked: PropTypes.bool,
};

export default DialogVote;
