import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Grid, makeStyles, Button, Divider, Box } from '@material-ui/core';
import { changeMenuSidebar } from '../../store/actions/navigationActions';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import H5 from './typographys/H5';
import AMAauth from '../../assets/images/AMAauth.png';
import isEmpty from '../../utils/isEmpty';
import H6 from './typographys/H6';
import H4 from './typographys/H4';
import { meetingManualCheckIn } from '../../store/actions/meetingsActions';
import { clearCardInfo } from '../../store/actions/cardActions';

const useStyles = makeStyles(() => ({
  container: {
    height: '90vh',
    padding: '25px',
  },
  divButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '40px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  labelManual: {
    marginBottom: '30px',
  },
  divider: {
    marginTop: '20px',
    marginBottom: '20px',
  },
}));

const SamlAuth = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const search = queryString.parse(location.search);
  const { meeting_id, user_id } = search;
  const validInfo = !isEmpty(meeting_id);
  const [validManual] = useState(false);
  const token = useMemo(() => localStorage.getItem('jwtToken'), []);

  useEffect(() => {
    dispatch(changeMenuSidebar('login', 'Login', 'Cartão de Cidadão'));

    return () => {
      dispatch(clearCardInfo());
    };
  }, [dispatch]);

  const manualCheckIn = useCallback(
    e => {
      e.preventDefault();
      const manualPromise = new Promise(resolve => {
        dispatch(meetingManualCheckIn(meeting_id, user_id, resolve));
      });

      manualPromise.then(res => {
        if (res.data.response) {
          const win = window.open('about:blank', '_self');
          win.close();
        }
      });
    },
    [dispatch, meeting_id, user_id]
  );

  return (
    <Grid container justify="center" alignItems="center" className={classes.container}>
      <Grid item>
        {validInfo && !validManual && (
          <>
            <H5 center>Efetuar check-in na reunião com Cartão de Cidadão.</H5>
            <input type="hidden" name="meeting_id" value={meeting_id} />
            {user_id && <input type="hidden" name="user_id" value={user_id} />}
            <a
              href={`${process.env.REACT_APP_CHECK_IN_URL}/${meeting_id}/check-in?user_id=${user_id}&token=${token}`}
              className={classes.divButton}
            >
              <img src={AMAauth} alt="Autenticação AMA" />
            </a>
            {!isEmpty(user_id) && (
              <>
                <Divider className={classes.divider} />
                <H4 center>OU</H4>
                <Divider className={classes.divider} />
                <H6 center className={classes.labelManual}>
                  Confirmar presença na reunião.
                </H6>
                <Box display="flex" justifyContent="center">
                  <Button variant="contained" color="primary" onClick={manualCheckIn}>
                    Marcar como presente
                  </Button>
                </Box>
              </>
            )}
          </>
        )}
        {!validInfo && <H5>Existem parâmetros em falta, tente novamente</H5>}
      </Grid>
    </Grid>
  );
};

export default React.memo(SamlAuth);
