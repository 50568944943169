import React from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import H4 from '../../../../common/typographys/H4';

const useStyles = makeStyles(theme => ({
  activeProposal: {
    padding: '40px',
    paddingBottom: '60px',
    backgroundColor: theme.palette.primary[100],
  },
  proposalName: {
    marginTop: '3px',
    marginBottom: '4px',
  },
}));

const NoProposalPlateia = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.activeProposal}>
      <H4>Nenhuma proposta em discussão</H4>
    </Paper>
  );
};

export default NoProposalPlateia;
