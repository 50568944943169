import React, { useContext, useCallback } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { RemoveCircle } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { TypologyDispatchContext } from '../CreateEditTypologyProvider';
import NewBoxItem from './NewBoxItem';
import ThemePointItem from './ThemePointItem';
import BoxInput from './BoxInput';
import DialogConfirmAction from '../../../../common/dialogs/DialogConfirmAction';
import useBooleanToggle from '../../../../../utils/hooks/useBooleanToggle';

const useStyles = makeStyles(theme => ({
  mainBox: {
    display: 'flex',
    width: '100%',
    padding: '8px 10px',
    backgroundColor: theme.palette.neutrals[2],
    height: '40px',
  },
  iconBox: {
    paddingLeft: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.neutrals[4],
    transform: 'scale(0.666666666)',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.colorsPalette.RustRed,
    },
  },
  pointBox: {
    paddingLeft: '10px',
  },
}));

const ThemeItem = ({ theme, idx }) => {
  const classes = useStyles();
  const dispatch = useContext(TypologyDispatchContext);
  const [openModal, toggleModal] = useBooleanToggle();

  const addPoint = useCallback(
    pointName => {
      dispatch({
        type: 'ADD_THEME_POINT',
        payload: {
          idx,
          value: pointName,
        },
      });
    },
    [dispatch, idx]
  );

  const handleDelete = useCallback(
    idTheme => e => {
      e.preventDefault();
      toggleModal();
      dispatch({
        type: 'DELETE_THEME',
        payload: {
          idx,
          id: idTheme,
        },
      });
    },
    [toggleModal, idx, dispatch]
  );

  return (
    <>
      <Box className={classes.mainBox}>
        <BoxInput
          value={theme.name}
          onChange={e => {
            dispatch({
              type: 'UPDATE_THEME_NAME',
              payload: {
                idx,
                value: e.target.value,
              },
            });
          }}
        />
        <Box className={classes.iconBox}>
          <RemoveCircle
            className={classes.icon}
            onClick={e => {
              e.preventDefault();
              toggleModal();
            }}
          />
        </Box>
      </Box>
      <Box height="4px" />
      {theme.points &&
        theme.points.length > 0 &&
        theme.points.map((point, idxPoint) => (
          <Box key={`ponto-${idx}-${point.name}`} className={classes.pointBox}>
            <ThemePointItem
              point={point}
              idTheme={theme.id}
              idxTheme={idx}
              idxPoint={idxPoint}
              themeName={theme.name}
            />
            {idx + 1 !== theme.points.length && <Box height="4px" />}
          </Box>
        ))}
      {theme.points && theme.points.length > 0 && <Box height="4px" />}
      <Box className={classes.pointBox}>
        <NewBoxItem onClick={addPoint} placeholder="Criar ponto de discussão" />
      </Box>
      <DialogConfirmAction
        open={openModal}
        handleClose={toggleModal}
        msg={`Deseja remover o tema, ${theme.name}? ${
          theme.points && theme.points.length > 0
            ? 'Os pontos de discussão associados, serão também removidos.'
            : ''
        }`}
        handleSubmit={handleDelete(theme.id)}
        labelSubmit="Remover"
      />
    </>
  );
};

ThemeItem.propTypes = {
  theme: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
};

export default React.memo(ThemeItem);
