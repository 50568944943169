import React from 'react';
import SocketReunionWrapper from './SocketReunionWrapper';
import Meeting from './Meeting';

const WrapMeeting = () => {
  return (
    <SocketReunionWrapper>
      <Meeting />
    </SocketReunionWrapper>
  );
};

export default WrapMeeting;
