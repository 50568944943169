/* eslint-disable no-nested-ternary */
import React from 'react';
import { emphasize, makeStyles, useTheme } from '@material-ui/core/styles';
import Control from './materialSelects/Control';
import Menu from './materialSelects/Menu';
import MultiValue from './materialSelects/MultiValue';
import NoOptionsMessage from './materialSelects/NoOptionsMessage';
import Placeholder from './materialSelects/Placeholder';
import SingleValue from './materialSelects/SingleValue';
import ValueContainer from './materialSelects/ValueContainer';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import { FormHelperText } from '@material-ui/core';
import Subtitle2 from '../typographys/Subtitle2';
import isEmpty from '../../../utils/isEmpty';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    height: 'auto',
    padding: '0px 0px 0px 8px!important',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
  },
  specialValueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700], 0.08),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 11,
  },
  paper: {
    position: 'inherit',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    marginBottom: '50px',
  },
  divider: {
    height: theme.spacing(2),
  },
  label: {
    marginBottom: '6px',
  },
}));

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  // Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const Creatable = ({
  options,
  handleChange,
  value,
  placeholder,
  helperText,
  label,
  error,
  className,
  customComponents,
  isMulti,
  noOptionsMessage,
  ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
    indicatorsContainer: base => ({
      ...base,
      color: theme.palette.neutrals[5],
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    groupHeading: base => ({
      ...base,
      ...theme.typography.subtitle2,
      textTransform: 'none',
    }),
    group: base => ({
      ...base,
      paddingBottom: '0px',
    }),
    menuList: base => ({
      ...base,
      paddingBottom: '0px',
      paddingTop: '0px',
    }),
    option: (_, { isFocused, isSelected }) => ({
      width: 'auto',
      overflow: 'hidden',
      boxSizing: 'border-box',
      fontStyle: 'normal',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      lineHeight: '24px',
      paddingTop: '12px',
      whiteSpace: 'nowrap',
      fontStretch: 'normal',
      paddingLeft: '20px',
      paddingRight: '20px',
      letterSpacing: 'normal',
      paddingBottom: '8px',
      transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      display: 'flex',
      position: 'relative',
      background: isFocused ? theme.palette.primary[200] : isSelected ? theme.palette.primary[900] : '#eaefef',
      color: isFocused ? '#636363' : isSelected ? '#fff' : '#636363',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textDecoration: 'none',
      border: '0px',
      cursor: 'pointer',
      margin: '0px',
      outline: '0px',
      userSelect: 'none',
      borderRadius: '0px',
      verticalAlign: 'middle',
      fontSize: '12px',
    }),
  };

  return (
    <>
      {label && <Subtitle2 className={classes.label}>{label}</Subtitle2>}
      <CreatableSelect
        isMulti={isMulti}
        classes={classes}
        styles={selectStyles}
        placeholder={placeholder}
        TextFieldProps={{
          InputLabelProps: {
            shrink: true,
          },
          placeholder,
          variant: 'outlined',
        }}
        onChange={handleChange}
        options={options}
        components={{ ...components, ...customComponents }}
        value={value}
        className={className}
        error={error}
        menuPortalTarget={document.querySelector('body')}
        noOptionsMessage={noOptionsMessage}
        {...props}
      />
      {(helperText || error) && <FormHelperText error={!isEmpty(error)}>{error || helperText}</FormHelperText>}
    </>
  );
};

Creatable.defaultProps = {
  options: [],
  placeholder: 'Seleccione uma opção',
  helperText: null,
  value: null,
  label: null,
  error: null,
  className: '',
  customComponents: {},
  isMulti: false,
  noOptionsMessage: () => 'Sem opções',
};

Creatable.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  options: PropTypes.array,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  customComponents: PropTypes.object,
  isMulti: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
};

export default Creatable;
