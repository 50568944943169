import isEmpty from '../isEmpty';

/**
 *
 * @param {object} permissions - Permissions object
 * @returns {boolean} True or false depending if user has permission to have users config
 */
const hasUsersConfig = permissions => {
  if (
    !isEmpty(permissions) &&
    permissions.users &&
    (permissions.users.invite || permissions.users.edit_status || permissions.users.edit_permissions)
  ) {
    return true;
  }

  return false;
};

export default hasUsersConfig;
