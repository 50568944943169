import React, { useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Hidden } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../../store/actions/authActions';
import SchemaDesktop from './SchemaDesktop';
import SchemaMobile from './SchemaMobile';
import PropTypes from 'prop-types';
import useBooleanToggle from '../../../utils/hooks/useBooleanToggle';
import { SOCKET } from '../SocketWrapper';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ...theme.mixins.toolbar,
  },
  content: {
    padding: '0px 24px',
    flexGrow: 1,
    minHeight: '94vh',
    maxWidth: '100%',
  },
}));

const AppSchema = ({ children }) => {
  const classes = useStyles();
  const [mobileOpen, handleDrawerToggle, setMobileOpen] = useBooleanToggle();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isAuthenticated } = useSelector(state => state.auth);
  const { socket, cleanSocket } = useContext(SOCKET);

  const handleLogout = useCallback(
    e => {
      e.preventDefault();

      if (socket) {
        socket.close();
      }
      cleanSocket();
      setMobileOpen(false);
      return dispatch(logoutUser());
    },
    [dispatch, cleanSocket, setMobileOpen, socket]
  );

  return isAuthenticated &&
    location.pathname !== '/saml-auth' &&
    location.pathname.indexOf('/plateia') === -1 ? (
    <div className={classes.root}>
      <Hidden smDown>
        <SchemaDesktop handleLogout={handleLogout} />
      </Hidden>
      <Hidden mdUp>
        <SchemaMobile
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          handleLogout={handleLogout}
        />
      </Hidden>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  ) : (
    children
  );
};

AppSchema.propTypes = {
  children: PropTypes.object.isRequired,
};

export default AppSchema;
