import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import ProposalCard from './ProposalCard';
import { useSelector, useDispatch } from 'react-redux';
import Subtitle2 from '../../../common/typographys/Subtitle2';
import Body1 from '../../../common/typographys/Body1';
import PropTypes from 'prop-types';
import { getOrganicUnitsParents } from '../../../../store/actions/organicUnitsActions';
import isEmpty from '../../../../utils/isEmpty';
import NameThemeDescription from './NameThemeDescription';
import Budget from './Budget';
import OrganicUnit from './OrganicUnit';
import Entity from './Entity';
import InternalLink from './InternalLink';

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: '6px',
  },
  value: {
    marginBottom: '20px',
  },
  multiline: {
    padding: '8px 10px',
  },
}));

const ProposalDetails = ({ state, editing, updateField, updateEntityField }) => {
  const classes = useStyles();
  const { name: userName, organic_units: organicUnits = [] } = useSelector(
    stateRedux => stateRedux.auth.user
  );
  const {
    name,
    entity,
    amount,
    errors,
    theme,
    organicUnit,
    hasBudget,
    budgetNumber,
    description,
    proposal_number,
    internalLink,
    internalLinkText,
    on_behalf_of,
  } = state;
  const dispatchRedux = useDispatch();

  useEffect(() => {
    if (!isEmpty(organicUnit) && !editing) {
      dispatchRedux(getOrganicUnitsParents(organicUnit.id));
    }
  }, [organicUnit, editing, dispatchRedux]);

  const firstDetailsState = { name, theme, description, proposal_number, on_behalf_of, errors };
  const budgetState = { hasBudget, budgetNumber, amount, /* budgetValue, */ errors };
  const organicUnitState = { editing, organicUnits, organicUnit, errors };
  const entityState = { entity, amount, errors };
  const internalState = { internalLink, internalLinkText, errors };

  return (
    <ProposalCard label="Detalhes">
      <Subtitle2 className={classes.label}>Proponente</Subtitle2>
      <Body1 secondary className={classes.value}>
        {userName}
      </Body1>
      <NameThemeDescription state={firstDetailsState} updateField={updateField} />
      <OrganicUnit state={organicUnitState} updateField={updateField} />
      <Budget state={budgetState} updateField={updateField} />
      <Entity state={entityState} updateField={updateField} updateEntityField={updateEntityField} />
      <InternalLink state={internalState} updateField={updateField} />
    </ProposalCard>
  );
};

ProposalDetails.defaultProps = {
  editing: false,
};

ProposalDetails.propTypes = {
  state: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
  updateEntityField: PropTypes.func.isRequired,
  editing: PropTypes.bool,
};

export default React.memo(ProposalDetails);
