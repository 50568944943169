import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import GroupMember from './GroupMember';
import PropTypes from 'prop-types';

const compare = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const GroupsList = ({ groups }) => {
  const { groups: groupsOptions = [] } = useSelector(state => state.groups);

  const options = useMemo(() => groupsOptions.sort(compare), [groupsOptions]);

  const isParticipant = useCallback(
    idMember => {
      return groups.find(x => x.id === idMember) !== undefined;
    },
    [groups]
  );

  const hasNoTimeLimit = useCallback(
    idMember => {
      const obj = groups.find(x => x.id === idMember) || {};
      return obj.noLimit || false;
    },
    [groups]
  );

  const getNumUsers = useCallback(
    idMember => {
      const obj = groups.find(x => x.id === idMember) || {};
      return obj.num_users;
    },
    [groups]
  );

  return (
    options.length > 0 &&
    options.map(option => (
      <GroupMember
        key={option.id}
        id={option.id}
        name={option.name}
        participant={isParticipant(option.id)}
        timeLimit={hasNoTimeLimit(option.id)}
        num_users={getNumUsers(option.id)}
        max={option.users.length}
        users={option.users}
      />
    ))
  );
};

GroupsList.defaultProps = {
  groups: [],
};

GroupsList.propTypes = {
  groups: PropTypes.array,
};

export default React.memo(GroupsList);
