import React, { useContext } from 'react';
import { OrganicUnitStateContext, OrganicUnitDispatchContext } from './CreateEditOrganicUnitProvider';
import FormInfoOrganicUnit from './FormInfoOrganicUnit';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import UpdateItem from '../../pieces/UpdateItem';

const EditOrganicUnit = () => {
  const {
    state: { errors, items },
    handleSubmit,
    loading,
  } = useContext(OrganicUnitStateContext);
  const dispatch = useContext(OrganicUnitDispatchContext);

  return (
    <UpdateItem disableSubmit={checkEmptyObject(errors).length > 0} loading={loading} items={items} dispatch={dispatch} handleSubmit={handleSubmit}>
      <FormInfoOrganicUnit />
    </UpdateItem>
  );
};

export default EditOrganicUnit;
