import React, { useContext, useEffect, useCallback, useMemo } from 'react';
import InputField from '../../../common/forms/InputField';
import { UserStateContext, UserDispatchContext } from './CreateEditUserProvider';
import PropTypes from 'prop-types';
import SelectOutlined from '../../../common/forms/SelectOutlined';
import CheckboxPicker from '../../../common/forms/CheckboxPicker';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Chip, makeStyles, emphasize } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { getUserProfilePermissions } from '../../../../store/actions/usersActions';
import PermissionsCollapseGroup from '../../pieces/PermissionsCollapseGroup';
import clsx from 'clsx';
import Body1 from '../../../common/typographys/Body1';

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    ),
  },
}));

function SpecialMultiValue(props) {
  const classes = useStyles();
  const indexInValue = props.selectProps.value.findIndex(x => x === props.data);
  const valueLength = props.selectProps.value.length;

  if (indexInValue < 2) {
    return (
      <Chip
        tabIndex={-1}
        label={props.children}
        className={clsx(classes.chip, {
          [classes.chipFocused]: props.isFocused,
        })}
        onDelete={props.removeProps.onClick}
        deleteIcon={<Close {...props.removeProps} />}
      />
    );
  }

  if (indexInValue === valueLength - 1) {
    return (
      <Body1 secondary style={{ paddingLeft: '5px' }}>
        (+ {valueLength - 2} Unidade{`${valueLength - 2 > 1 ? 's' : ''}`} Orgânica
        {`${valueLength - 2 > 1 ? 's' : ''}`})
      </Body1>
    );
  }

  return null;
}

SpecialMultiValue.defaultProps = {
  children: null,
  isFocused: false,
  data: [],
};

SpecialMultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

const FormInfoUser = ({ isAddNew, isCreateNew }) => {
  const {
    state: {
      email,
      name,
      short_name,
      councilor,
      nif,
      cc,
      phone,
      errors,
      panel,
      permissions,
      changed_profile,
      changed_permission,
      profile,
      organicUnit,
      role,
      password,
      organicUnits: organicUnitsUser,
    },
  } = useContext(UserStateContext);
  const dispatchRedux = useDispatch();
  const dispatch = useContext(UserDispatchContext);
  const { profiles } = useSelector(state => state.profiles);
  const { organicUnits } = useSelector(state => state.organicUnits);
  const { edit_permissions } = useSelector(state => state.auth.user.permissions.users);
  const optionsProfiles = useMemo(
    () =>
      profiles.map(item => ({ id: item.id, label: item.name, value: item.id, name: item.name })),
    [profiles]
  );
  const valueProfile = useMemo(() => {
    if (profile && !profile.id) {
      return { value: 'custom', label: 'Permissões personalizadas' };
    }
    if (profile) {
      return { id: profile.id, value: profile.id, label: profile.name };
    }

    return { value: 'custom', label: 'Permissões personalizadas' };
  }, [profile]);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'CLEAR_STATE',
      });
    };
  }, [dispatch]);

  const updateField = useCallback(
    (nameInput, value) => {
      dispatch({
        type: 'UPDATE_FIELD',
        payload: {
          name: nameInput,
          value,
        },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    // @ Participante tinha um perfil associado e utilizava essas permissões
    if (profile && profile.value !== 'custom' && changed_profile) {
      // @ Foi mudado o perfil na selectBox
      dispatchRedux(getUserProfilePermissions(profile.id));
      dispatch({
        type: 'CHANGE_USER_PROFILE',
        payload: {
          id: profile.id,
        },
      });
    }
  }, [dispatch, profile, changed_profile, dispatchRedux]);

  useEffect(() => {
    if (!changed_profile && changed_permission) {
      dispatch({
        type: 'SET_CUSTOM_PROFILE',
      });
    }
  }, [changed_profile, changed_permission, dispatch]);

  const onChangeOrganicUnit = nextOrganicUnit => {
    if (organicUnit && nextOrganicUnit && organicUnit.id === nextOrganicUnit.id) return;

    const selectedOrganicUnits = organicUnitsUser || [];

    updateField('changed_organic_unit', true);
    updateField('organicUnit', nextOrganicUnit);

    if (!nextOrganicUnit) return;

    const nextOrganicUnitSelected = selectedOrganicUnits.some(
      selectedUnit => selectedUnit.id === nextOrganicUnit.id
    );

    if (nextOrganicUnitSelected) return;

    const selected = selectedOrganicUnits.slice();
    selected.push(nextOrganicUnit);

    onSelectOrganicUnits(selected);
  };

  const onSelectOrganicUnits = selectedOrganicUnits => {
    updateField('organicUnits', selectedOrganicUnits || []);
    updateField('changed_organic_units', true);
  };

  return (
    <>
      <InputField
        label="Email"
        disabled={!isAddNew && !isCreateNew}
        name="email"
        type="email"
        value={email}
        onChange={e => updateField('email', e.target.value)}
        error={errors.email}
        placeholder="exemplo@exemplo.pt"
      />
      {!isAddNew && (
        <>
          {isCreateNew && (
            <InputField
              label="Password"
              name="password"
              type="password"
              value={password}
              onChange={e => updateField('password', e.target.value)}
              error={errors.password}
            />
          )}
          <InputField
            label="Nome Completo"
            name="name"
            value={name}
            onChange={e => updateField('name', e.target.value)}
            error={errors.name}
          />
          <InputField
            label="Primeiro e Último Nome"
            name="short_name"
            value={short_name}
            onChange={e => updateField('short_name', e.target.value)}
            error={errors.short_name}
          />
          {!isCreateNew && (
            <>
              <CheckboxPicker
                label="Vereador"
                checked={councilor}
                onClick={() => updateField('councilor', !councilor)}
              />
              <Box height="20px" />
            </>
          )}
          {isCreateNew && (
            <InputField
              label="Cargo"
              name="role"
              value={role}
              onChange={e => updateField('role', e.target.value)}
              error={errors.role}
            />
          )}
          {isCreateNew && (
            <InputField
              label="Número de Identificação Civil"
              name="cc"
              value={cc}
              onChange={e => updateField('cc', e.target.value)}
              error={errors.cc}
            />
          )}
          <InputField
            label="Número de Identificação Fiscal"
            name="nif"
            value={nif}
            onChange={e => updateField('nif', e.target.value)}
            error={errors.nif}
          />
          <InputField
            label="Contacto telefónico"
            name="phone"
            value={phone}
            onChange={e => updateField('phone', e.target.value)}
            error={errors.phone}
          />
          <SelectOutlined
            isClearable
            options={organicUnits}
            value={organicUnit}
            label="Unidade Orgânica"
            placeholder="Unidade Orgânica"
            name="organicUnit"
            onChange={onChangeOrganicUnit}
            getOptionValue={option => option.id}
            getOptionLabel={option => `${option.abbreviation || option.code} - ${option.name}`}
            noOptionsMessage={() => 'Nenhuma Unidade Orgânica disponível'}
          />
          <Box height="20px" />
          {!isCreateNew && (
            <>
              <SelectOutlined
                isMulti
                options={organicUnits}
                value={organicUnitsUser}
                label="Unidades Orgânicas permitidas para submeter propostas"
                helperText={`Selecione as Unidades Orgânicas para as quais, ${name}, pode submeter propostas.`}
                name="organicUnits"
                getOptionValue={option => option.id}
                getOptionLabel={option => `${option.abbreviation || option.code} - ${option.name}`}
                onChange={onSelectOrganicUnits}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                otherComponents={{ MultiValue: SpecialMultiValue }}
              />
              <Box height="20px" />
            </>
          )}
          {edit_permissions && !isCreateNew && (
            <>
              <SelectOutlined
                options={optionsProfiles}
                value={valueProfile}
                label="Perfil"
                name="profile"
                onChange={val => {
                  updateField('profile', val);
                  updateField('changed_profile', true);
                }}
              />
              <Box height="20px" />
              <PermissionsCollapseGroup
                panel={panel}
                permissions={permissions}
                dispatch={dispatch}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

FormInfoUser.defaultProps = {
  isAddNew: false,
  isCreateNew: false,
};

FormInfoUser.propTypes = {
  isAddNew: PropTypes.bool,
  isCreateNew: PropTypes.bool,
};

export default React.memo(FormInfoUser);
