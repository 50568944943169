import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  entryPoint: {
    backgroundColor: theme.palette.neutrals[2],
    padding: '8px 10px',
    marginTop: '8px',
  },
  noMargin: {
    marginTop: '0px!important',
  },
}));

const EntryBox = ({ children, className, noMargin }) => {
  const classes = useStyles();

  return <Box className={clsx(classes.entryPoint, { [className]: className.length > 0, [classes.noMargin]: noMargin })}>{children}</Box>;
};

EntryBox.defaultProps = {
  className: '',
  noMargin: false,
};

EntryBox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  className: PropTypes.string,
  noMargin: PropTypes.bool,
};

export default React.memo(EntryBox);
