import palette from '../palette';

export default {
  line: {
    borderColor: palette.neutrals[4],
  },
  root: {
    color: palette.neutrals[5],
  },
};
