import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';

const ConfigLinks = () => {
  const description = useSelector(state => state.config?.configs?.description);

  if (!description) return null;

  return (
    <Typography variant="h6" align="center" style={{ color: '#fff' }} paragraph>
      {description}
    </Typography>
  );
};

export default ConfigLinks;
