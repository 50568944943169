import React from 'react';
import { makeStyles, TextField, Box, Button, Grid } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import isBefore from 'date-fns/isBefore';
import addHours from 'date-fns/addHours';
import PropTypes from 'prop-types';
import ButtonNormal from '../../common/buttons/ButtonNormal';

const useStyles = makeStyles(() => ({
  boxFilter: {
    '& > div': {
      padding: '0px 20px 0px 0px',
    },
  },
  buttonFilter: {
    minHeight: '40px',
  },
  marginLeft: {
    display: 'flex',
    '& > div': {
      marginLeft: '20px',
    },
  },
}));

const commonPickers = {
  variant: 'inline',
  openTo: 'month',
  disableFuture: true,
  views: ['year', 'month'],
  TextFieldComponent: props => <TextField {...props} variant="outlined" />,
  maxDateMessage: 'Data inválida',
  minDateMessage: 'Data inferior ao ',
  invalidDateMessage: 'Data inválida',
};

const BoxDateFilter = ({
  filterProposals,
  endDate,
  date,
  setDate,
  setEndDate,
  hideFilter,
  exportToExcel,
  showExport,
  justify,
  marginLeft,
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} justify={justify}>
      <Grid item>
        <Box className={marginLeft ? classes.marginLeft : classes.boxFilter}>
          <DatePicker
            {...commonPickers}
            label="De"
            maxDate={endDate}
            value={date}
            onChange={setDate}
          />
          <DatePicker {...commonPickers} label="A" value={endDate} onChange={setEndDate} />
          {!hideFilter && (
            <Button
              variant="contained"
              color="primary"
              onClick={filterProposals}
              className={classes.buttonFilter}
              disabled={!isBefore(date, addHours(endDate, 2))}
            >
              Filtrar propostas
            </Button>
          )}
        </Box>
      </Grid>
      {showExport && (
        <Grid item>
          <ButtonNormal contained pea label="Exportar dados" onClick={exportToExcel} small />
        </Grid>
      )}
    </Grid>
  );
};

BoxDateFilter.defaultProps = {
  filterProposals: () => null,
  hideFilter: false,
  showExport: false,
  marginLeft: false,
  justify: 'space-between',
  exportToExcel: () => null,
};

BoxDateFilter.propTypes = {
  filterProposals: PropTypes.func,
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  setDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
  exportToExcel: PropTypes.func,
  showExport: PropTypes.bool,
  hideFilter: PropTypes.bool,
  marginLeft: PropTypes.bool,
  justify: PropTypes.string,
};

export default React.memo(BoxDateFilter);
