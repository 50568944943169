import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body1 from '../../../common/typographys/Body1';
import Subtitle2 from '../../../common/typographys/Subtitle2';

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: '8px',
  },
  unit: {
    marginBottom: '2px',
  },
}));

const ListOrganicUnits = ({ organicUnits }) => {
  const classes = useStyles();

  return (
    <>
      <Subtitle2 tertiary className={classes.label}>
        Unidades Orgânicas permitidas para submeter propostas
      </Subtitle2>
      {organicUnits.map(({ abbreviation, code, name }) => (
        <Body1 key={code} secondary className={classes.unit}>
          {abbreviation || code} - {name}
        </Body1>
      ))}
      <Box height="18px" />
    </>
  );
};

ListOrganicUnits.propTypes = {
  organicUnits: PropTypes.array.isRequired,
};

export default React.memo(ListOrganicUnits);
