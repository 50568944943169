import React from 'react';
import { makeStyles, Grid, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import H5 from '../../../common/typographys/H5';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  box: {
    display: 'flex',
    justifyContent: 'center',
    padding: '18px 0px',
  },
  active: {
    backgroundColor: theme.palette.primary.A400,
  },
}));

const DiscussionOrVote = ({ voting }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={6}>
        <Box className={clsx(classes.box, { [classes.active]: !voting })}>
          <H5 secondary={!voting} inactive={voting}>
            Discussão
          </H5>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box className={clsx(classes.box, { [classes.active]: voting })}>
          <H5 secondary={voting} inactive={!voting}>
            Votação
          </H5>
        </Box>
      </Grid>
    </Grid>
  );
};

DiscussionOrVote.defaultProps = {
  voting: false,
};

DiscussionOrVote.propTypes = {
  voting: PropTypes.bool,
};

export default React.memo(DiscussionOrVote);
