import axios from 'axios';
import {
  GET_TYPOLOGIES,
  GET_TYPOLOGY_POOL,
  CREATE_TYPOLOGY,
  UPDATE_TYPOLOGY,
  GET_ERRORS,
  SHOW_SNACK,
  TYPOLOGIES_LOADING,
  TYPOLOGIES_NOT_LOADING,
  GET_TYPOLOGY,
  REMOVE_TYPOLOGY,
  CLEAR_TYPOLOGY,
} from './types';

export const getAllTypologies = () => dispatch => {
  dispatch(setTypologiesLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/typologies`)
    .then(res => {
      dispatch({
        type: GET_TYPOLOGIES,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(setTypologiesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getTypology = id => dispatch => {
  dispatch(setTypologiesLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/typologies/${id}`)
    .then(res => {
      dispatch({
        type: GET_TYPOLOGY,
        payload: res.data,
      });
      dispatch(getTypologyPool(res.data.id));
    })
    .catch(err => {
      dispatch(setTypologiesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getTypologyPool = id => dispatch => {
  dispatch(setTypologiesLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/typologies/${id}/pool`)
    .then(res => {
      dispatch({
        type: GET_TYPOLOGY_POOL,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(setTypologiesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const createTypology = info => dispatch => {
  dispatch(setTypologiesLoading());

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/typologies`, info)
    .then(res => {
      dispatch({
        type: CREATE_TYPOLOGY,
        payload: { ...res.data, can_delete: true },
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Tipologia criada com sucesso.',
        },
      });
    })
    .catch(err => {
      dispatch(setTypologiesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateTypology = (id, info) => dispatch => {
  dispatch(setTypologiesLoading());

  axios
    .put(`${process.env.REACT_APP_API_URL}/api/typologies/${id}`, info)
    .then(res => {
      dispatch({
        type: UPDATE_TYPOLOGY,
        payload: res.data,
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Tipologia atualizada com sucesso.',
        },
      });
    })
    .catch(err => {
      dispatch(setTypologiesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const removeTypology = (id, callback) => dispatch => {
  dispatch(setTypologiesLoading());

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/typologies/${id}/delete`)
    .then(() => {
      dispatch({
        type: REMOVE_TYPOLOGY,
        payload: {
          id,
        },
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Tipologia excluída com sucesso.',
        },
      });
      callback();
    })
    .catch(err => {
      dispatch(setTypologiesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const clearTypology = () => {
  return {
    type: CLEAR_TYPOLOGY,
  };
};

export const setTypologiesLoading = () => {
  return {
    type: TYPOLOGIES_LOADING,
  };
};

export const setTypologiesNotLoading = () => {
  return {
    type: TYPOLOGIES_NOT_LOADING,
  };
};
