import React, { useContext, useCallback } from 'react';
import { makeStyles, FormGroup, FormControlLabel, FormControl, FormHelperText } from '@material-ui/core';
import Body1 from '../../../../common/typographys/Body1';
import { MatrixDispatchContext } from '../CreateEditMatrixProvider';
import isEmpty from '../../../../../utils/isEmpty';
import clsx from 'clsx';
import { ConfigStateContext } from '../../../MenuConfigProvider';
import CustomCheckbox from '../../../../common/forms/CustomCheckbox';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  removeHover: {
    borderRadius: 0,
    '& .checkbox': {
      '&:hover': {
        background: 'transparent',
      },
    },
  },
  root: {
    paddingLeft: '2px',
  },
  margin: {
    marginBottom: '10px',
  },
  error: {
    marginTop: 0,
  },
}));

const Checkbox = ({ checked, onChange, value }) => {
  return <CustomCheckbox checkGreen name="type" checked={checked} onChange={onChange} value={value} />;
};

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

const CheckboxType = ({ value, error }) => {
  const classes = useStyles();
  const { editing } = useContext(ConfigStateContext);

  const dispatch = useContext(MatrixDispatchContext);

  const updateField = useCallback(
    (nameInput, val) => {
      dispatch({
        type: 'UPDATE_FIELD',
        payload: {
          name: nameInput,
          value: val,
        },
      });
    },
    [dispatch]
  );

  return (
    <FormControl error={!isEmpty(error)} disabled={editing}>
      <FormGroup className={clsx(classes.root, { [classes.margin]: isEmpty(error) })}>
        <FormControlLabel
          control={<Checkbox checked={value === 'individual'} onChange={e => updateField('type', e.target.value)} value="individual" />}
          className={classes.removeHover}
          label={<Body1 secondary={value !== 'individual'}>Individual</Body1>}
        />
        <FormControlLabel
          control={<Checkbox checked={value === 'grupo'} onChange={e => updateField('type', e.target.value)} value="grupo" />}
          className={classes.removeHover}
          label={<Body1 secondary={value !== 'grupo'}>Grupo</Body1>}
        />
      </FormGroup>
      {error && <FormHelperText className={clsx(classes.margin, classes.error)}>{error}</FormHelperText>}
    </FormControl>
  );
};

CheckboxType.defaultProps = {
  error: null,
};

CheckboxType.propTypes = {
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
};

export default React.memo(CheckboxType);
