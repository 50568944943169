import React from 'react';
import PropTypes from 'prop-types';
import useToolbarMenus from '../../../utils/hooks/useToolbarMenus';
import NotificationBell from './NotificationBell';

const ToolbarActions = ({ classes }) => {
  // eslint-disable-next-line no-unused-vars
  const [notificationsEl, avatarEl, handleClick, handleProfile, handleClose] = useToolbarMenus();

  return (
    <>
      <NotificationBell
        classes={classes}
        handleClick={handleClick}
        handleClose={handleClose}
        notificationsEl={notificationsEl}
      />
    </>
  );
};

ToolbarActions.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default ToolbarActions;
