import React from 'react';
import { Paper } from '@material-ui/core';
import PropTypes from 'prop-types';

const Menu = props => {
  return (
    <Paper className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
};

Menu.defaultProps = {
  children: null,
  innerProps: null,
  selectProps: null,
};

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
};

export default Menu;
