import React from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Subtitle1 from '../../typographys/Subtitle1';

const useStyles = makeStyles(() => ({
  span3columns: {
    display: 'flex',
    width: 'calc(((100% - 20px) / 7) * 3)',
  },
  span1column: {
    display: 'flex',
    width: 'calc((100% - 20px) / 7)',
    paddingLeft: '4px',
  },
  marginRight: {
    marginRight: '10px',
  },
  marginBottom: {
    marginBottom: '5px',
  },
}));

const BoxCronologia = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.marginBottom}>
      <Box className={clsx(classes.span3columns, classes.marginRight)} justifyContent="flex-end" />
      <Box className={clsx(classes.span1column, classes.marginRight)}>
        <Subtitle1>Próximas</Subtitle1>
      </Box>
      <Box className={classes.span3columns} justifyContent="flex-start" />
    </Grid>
  );
};

export default BoxCronologia;
