import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ReactComponent as UndiscussedIcon } from '../../../../assets/images/undiscussed.svg';
import { ReactComponent as AgainstVoteIcon } from '../../../../assets/images/againstVote.svg';
import { ReactComponent as FavorVoteIcon } from '../../../../assets/images/favorVote.svg';
import { ReactComponent as DiscussedNotVotedIcon } from '../../../../assets/images/abstentionVote.svg';

const useStyles = makeStyles(() => ({
  common: {
    width: '18px',
    height: '18px',
  },
}));

const ProposalStatusIcon = ({ status }) => {
  const classes = useStyles();

  switch (status) {
    case 1:
      return <FavorVoteIcon className={classes.common} />;
    case 2:
      return <AgainstVoteIcon className={classes.common} />;
    case 3:
      return <DiscussedNotVotedIcon className={classes.common} />;
    case 4:
      return <DiscussedNotVotedIcon className={classes.common} />;
    default:
      return <UndiscussedIcon className={classes.common} />;
  }
};

ProposalStatusIcon.defaultProps = {
  status: null,
};

ProposalStatusIcon.propTypes = {
  status: PropTypes.number,
};

export default React.memo(ProposalStatusIcon);
