import React, { useMemo, useCallback, useEffect, useState, useRef } from 'react';
import { makeStyles, Box, Divider, Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import Subtitle2 from '../../common/typographys/Subtitle2';
import MeetingPointStatus from '../../common/MeetingPointStatus';
import CardBlock from '../../common/forms/CardBlock';
import Body1 from '../../common/typographys/Body1';
import isEmpty from '../../../utils/isEmpty';
import LabelAndValue from '../../common/forms/LabelAndValue';
import Link from '../../common/routing/Link';
import FileBox from '../../common/FileBox';
import { useDispatch, useSelector } from 'react-redux';
import {
  downloadMeetingPointDeliberation,
  getMeetingPointDeclarations,
  downloadMeetingDeclaration,
  deleteMeetingDeclaration,
  getMeetingPointDeclarationsChanges,
  uploadMeetingPointDeliberation,
  updateDeliberationNumber,
} from '../../../store/actions/meetingsActions';
import formatDate from '../../../utils/formatDate';
import setSeconds from 'date-fns/setSeconds';
import startOfToday from 'date-fns/startOfToday';
import Body2 from '../../common/typographys/Body2';
import PendingDeclaration from '../../meetings/check/pieces/PendingDeclaration';
import flatten from 'lodash/flatten';
import SelectOutlined from '../../common/forms/SelectOutlined';
import DialogCheck from '../../common/dialogs/DialogCheck';
import { AddCircleOutlined } from '@material-ui/icons';
import InputField from '../../common/forms/InputField';
import ButtonNormal from '../../common/buttons/ButtonNormal';

const useStyles = makeStyles(theme => ({
  label: {
    marginBottom: '8px',
  },
  labelGroup: {
    marginBottom: '6px',
  },
  divider: {
    marginBottom: '10px',
    marginTop: '10px',
  },
  user: {
    marginBottom: '4px',
  },
  secretaryDivider: {
    marginBottom: '10px',
  },
  increaseIndex: {
    zIndex: '9999',
  },
  buttonText: {
    color: theme.palette.neutrals[5],
  },
  input: {
    display: 'none',
  },
  grow: {
    flexGrow: 1,
  },
  noMargin: {
    marginTop: 0,
  },
}));

const formatSeconds = seconds => {
  return formatDate(setSeconds(startOfToday(), seconds), 'HH:mm:ss');
};

const DeliberationVote = ({ info, qualityVoteUser }) => {
  const { name, vote, id } = info;

  return (
    <Body1
      approved={vote === 'favor'}
      disapproved={vote === 'against'}
      secondary={vote === 'abstention'}
    >
      {name} {qualityVoteUser === id && '(Voto de qualidade)'}
    </Body1>
  );
};

DeliberationVote.defaultProps = {
  qualityVoteUser: null,
};

DeliberationVote.propTypes = {
  info: PropTypes.object.isRequired,
  qualityVoteUser: PropTypes.number,
};

const OratoryEntry = ({ info }) => {
  const { name, time } = info;

  return (
    <Body1>
      {name} - {formatSeconds(time)}
    </Body1>
  );
};

OratoryEntry.propTypes = {
  info: PropTypes.object.isRequired,
};

const DeliberationVotes = ({ votes, secretVote, users, isGroup, qualityVoteUser }) => {
  const classes = useStyles();

  const usersVotes = isGroup
    ? votes.reduce((acc, cur) => {
        if (!secretVote) {
          const groupObj = users.find(
            x => x && cur && x.id && cur.group_id && x.id.toString() === cur.group_id.toString()
          );
          const userObj =
            (groupObj &&
              groupObj.users.find(
                x => x && cur && x.id && cur.id && x.id.toString() === cur.id.toString()
              )) ||
            null;
          if (!isEmpty(userObj)) {
            acc.push({ ...cur, ...userObj, groupName: groupObj.name });
          }
        }
        return acc;
      }, [])
    : votes.reduce((acc, cur) => {
        if (!secretVote) {
          const userObj = users.find(
            x => x && cur && x.id && cur.id && x.id.toString() === cur.id.toString()
          );
          if (!isEmpty(userObj)) {
            acc.push({ ...cur, ...userObj });
          }
        }
        return acc;
      }, []);

  return usersVotes.map(entry =>
    entry.users ? (
      <React.Fragment key={entry.id}>
        <Subtitle2 className={classes.labelGroup}>{entry.name}</Subtitle2>
        {entry.users.map(user => (
          <DeliberationVote key={user.id} info={user} qualityVoteUser={qualityVoteUser} />
        ))}
      </React.Fragment>
    ) : (
      <DeliberationVote key={entry.id} info={entry} qualityVoteUser={qualityVoteUser} />
    )
  );
};

DeliberationVotes.defaultProps = {
  qualityVoteUser: null,
};

DeliberationVotes.propTypes = {
  votes: PropTypes.array.isRequired,
  secretVote: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
  isGroup: PropTypes.bool.isRequired,
  qualityVoteUser: PropTypes.number,
};

const OratoryUsers = ({ speak, users, isGroup }) => {
  const oratory = isGroup
    ? speak.reduce((acc, cur) => {
        const groupObj = users.find(
          x => x && cur && x.id && cur.group_id && x.id.toString() === cur.group_id.toString()
        );
        const userObj =
          (groupObj &&
            groupObj.users.find(
              x => x && cur && x.id && cur.id && x.id.toString() === cur.id.toString()
            )) ||
          null;
        if (!isEmpty(userObj)) {
          acc.push({ ...cur, ...userObj, groupName: groupObj.name });
        }

        return acc;
      }, [])
    : speak.reduce((acc, cur) => {
        const userObj = users.find(
          x => x && cur && x.id && cur.id && x.id.toString() === cur.id.toString()
        );
        if (!isEmpty(userObj)) {
          acc.push({ ...cur, ...userObj });
        }

        return acc;
      }, []);

  return oratory.map(entry => <OratoryEntry key={entry.id} info={entry} />);
};

OratoryUsers.propTypes = {
  speak: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  isGroup: PropTypes.bool.isRequired,
};

const ParticipantsBlocked = ({ blocked }) => {
  const classes = useStyles();
  return blocked.map(({ id, users, name }) =>
    users ? (
      <React.Fragment key={id}>
        <Subtitle2 className={classes.labelGroup}>{name}</Subtitle2>
        {users.map(({ id: userId, name: userName }) => (
          <Body1 key={userId}>{userName}</Body1>
        ))}
      </React.Fragment>
    ) : (
      <Body1 key={id}>{name}</Body1>
    )
  );
};

ParticipantsBlocked.propTypes = {
  blocked: PropTypes.array.isRequired,
};

const UsersDeclarations = ({ declarations, meetingId, pointId, permissions, idUser }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [auxUser, setAuxUser] = useState({});
  const [auxDeclarationId, setAuxDeclarationId] = useState({});

  const canDelete = useCallback(
    userId => {
      if (userId === idUser) {
        return true;
      }

      if (permissions && permissions.meetings && permissions.meetings.secretary) {
        return true;
      }

      return false;
    },
    [idUser, permissions]
  );

  const confirmDeleteFile = useCallback(
    (user, declarationId) => () => e => {
      e.preventDefault();
      setOpen(true);
      setAuxUser(user);
      setAuxDeclarationId(declarationId);
    },
    []
  );

  const downloadFile = useCallback(
    userId => file => e => {
      e.preventDefault();
      dispatch(downloadMeetingDeclaration(meetingId, pointId, userId, file.name));
      setOpen(false);
      setAuxUser({});
      setAuxDeclarationId({});
    },
    [meetingId, pointId, dispatch]
  );

  const deleteFile = useCallback(
    e => {
      e.preventDefault();

      dispatch(deleteMeetingDeclaration(meetingId, pointId, auxUser.id, auxDeclarationId));
      setAuxUser({});
      setAuxDeclarationId({});
    },
    [meetingId, pointId, auxUser, auxDeclarationId, dispatch]
  );

  return (
    <>
      {declarations.map(({ id, user, file_name, updated_at }, idx) => (
        <React.Fragment key={id}>
          <Body1 secondary className={classes.user}>
            Declaração de {user.name}, submetida a {formatDate(updated_at, 'dd/MM/yyyy - HH:mm')}
          </Body1>
          <FileBox
            file={{ name: file_name }}
            download
            downloadFile={downloadFile(user.id)}
            deleteFile={confirmDeleteFile(user, id)}
            remove={canDelete(user.id)}
          />
          {idx !== declarations.length - 1 && <Divider className={classes.divider} />}
        </React.Fragment>
      ))}
      <DialogCheck
        open={open}
        msg="Deseja eliminar a declaração de voto?"
        handleCancel={e => {
          e.preventDefault();
          setOpen(false);
          setAuxUser({});
          setAuxDeclarationId({});
        }}
        handleSubmit={deleteFile}
        labelSubmit="Eliminar"
      />
    </>
  );
};

UsersDeclarations.defaultProps = {
  permissions: {},
};

UsersDeclarations.propTypes = {
  declarations: PropTypes.array.isRequired,
  meetingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  pointId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  permissions: PropTypes.object,
  idUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

const SubmitUserDeclaration = ({ users, meetingId, pointId }) => {
  const classes = useStyles();
  const [userValue, setUserValue] = useState(null);

  const clearValue = useCallback(() => {
    setUserValue(null);
  }, []);

  const selectStyles = {
    menuPortal: base => ({
      ...base,
      zIndex: '9999!important',
    }),
  };

  return (
    <>
      <Divider className={classes.secretaryDivider} />
      <Subtitle2 tertiary className={classes.label}>
        Submeter declarações de participantes
      </Subtitle2>
      <SelectOutlined
        fullWidth
        isClearable
        placeholder="Selecionar participante"
        noOptionsMessage={() => 'Não existem participantes disponíveis.'}
        options={users}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        name="user-declaraction"
        otherStyles={selectStyles}
        value={userValue}
        onChange={val => setUserValue(val)}
        menuShouldBlockScroll
      />
      {!isEmpty(userValue) && (
        <UniqueUserDeclaration
          user={userValue}
          clearValue={clearValue}
          meetingId={meetingId}
          pointId={pointId}
        />
      )}
    </>
  );
};

SubmitUserDeclaration.defaultProps = {
  users: [],
};

SubmitUserDeclaration.propTypes = {
  users: PropTypes.array,
  meetingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  pointId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

const UniqueUserDeclaration = ({ user, clearValue, meetingId, pointId }) => {
  return (
    <>
      <Box height="20px" />
      <PendingDeclaration
        declaration={{ id: 'aux', point: { id: pointId } }}
        showLabel
        meetingId={meetingId}
        userId={user.id}
        clearSomething={clearValue}
      />
    </>
  );
};

UniqueUserDeclaration.propTypes = {
  user: PropTypes.object.isRequired,
  clearValue: PropTypes.func.isRequired,
  meetingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  pointId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

const DeclarationChanges = ({ changes }) => {
  const classes = useStyles();

  const changeMessage = useCallback((user, userDeclaration, action, date) => {
    if (action === 'removed') {
      if (user && userDeclaration && user.id === userDeclaration.id) {
        // @ O utilizador removeu a sua declaração
        return `${user.name} removeu a sua declaração de voto (${formatDate(
          date,
          'dd/MM/yyyy - HH:mm'
        )})`;
      }

      return `${user.name} removeu a sua declaração de voto de ${
        userDeclaration.name
      } (${formatDate(date, 'dd/MM/yyyy - HH:mm')})`;
    }

    if (user && userDeclaration && user.id === userDeclaration.id) {
      // @ O utilizador removeu a sua declaração
      return `${user.name} submeteu a sua declaração de voto (${formatDate(
        date,
        'dd/MM/yyyy - HH:mm'
      )})`;
    }

    return `${user.name} submeteu a declaração de voto de ${userDeclaration.name} (${formatDate(
      date,
      'dd/MM/yyyy - HH:mm'
    )})`;
  }, []);

  return (
    <>
      <Subtitle2 tertiary className={classes.label}>
        Histórico de declarações
      </Subtitle2>
      {changes.map(({ user, user_declaration, action, created_at }) => (
        <Body2 key={created_at}>{changeMessage(user, user_declaration, action, created_at)}</Body2>
      ))}
      <Box height="20px" />
    </>
  );
};

DeclarationChanges.propTypes = {
  changes: PropTypes.array.isRequired,
};

const MeetingDeliberation = ({
  meetingPointStatus,
  meetingName,
  meetingId,
  meetingIsGroup,
  meetingUsers,
  pointId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    status,
    votes,
    blocked_users,
    secret_vote,
    discussed,
    speak,
    favor,
    against,
    abstention,
    total,
    quality_vote_user,
    file_name = '',
    deliberation_number = '',
  } = meetingPointStatus;
  const deliberationFileRef = useRef(null);
  const [deliberationFileName, setDeliberationFileName] = useState('');
  const [deliberationNumber, setDeliberationNumber] = useState('');
  const { id: userId, permissions = {} } = useSelector(state => state.auth.user);
  const { pointDeclarations, changes } = useSelector(state => state.meetings);
  const hasVotes = useMemo(() => !secret_vote && votes.length > 0, [votes.length, secret_vote]);
  const isSecret = useMemo(() => Boolean(secret_vote), [secret_vote]);
  const hasBlockedUsers = useMemo(() => blocked_users.length > 0, [blocked_users.length]);
  const isDiscussed = useMemo(() => Boolean(discussed), [discussed]);
  const hasSpeakers = useMemo(() => speak && speak.length > 0, [speak]);
  const fileDeclarations = useMemo(() => {
    if (pointDeclarations) {
      return pointDeclarations.filter(x => x.type === 'file').filter(x => x.file !== null);
    }

    return [];
  }, [pointDeclarations]);
  const hasDeclarations = useMemo(() => fileDeclarations.length > 0, [fileDeclarations]);
  const isUserInDeclarations = useMemo(() => {
    if (pointDeclarations) {
      const declarationsAux = pointDeclarations
        .filter(x => x.type === 'file')
        .filter(x => x.file !== null);
      const objUser = declarationsAux.find(x => x.user && x.user.id === userId) || {};

      return !isEmpty(objUser);
    }

    return false;
  }, [userId, pointDeclarations]);
  const isSecretary = useMemo(() => {
    if (permissions && permissions.meetings && permissions.meetings.secretary) {
      return true;
    }

    return false;
  }, [permissions]);

  const plainUsers = useMemo(() => {
    if (meetingIsGroup) {
      if (meetingUsers) {
        const users = flatten(
          meetingUsers.map(group => group.users.filter(user => user.in_meeting))
        );

        return users.filter(
          user =>
            pointDeclarations.find(x => x.file !== null && x.user.id === user.id) === undefined
        );
      }

      return [];
    }

    if (meetingUsers) {
      const users = meetingUsers.filter(user => user.in_meeting);
      return users.filter(
        user => pointDeclarations.find(x => x.file !== null && x.user.id === user.id) === undefined
      );
    }

    return [];
  }, [meetingIsGroup, meetingUsers, pointDeclarations]);

  useEffect(() => {
    setDeliberationNumber(deliberation_number);
    setDeliberationFileName(file_name);
  }, [deliberation_number, file_name]);

  useEffect(() => {
    dispatch(getMeetingPointDeclarations(meetingId, pointId));
    dispatch(getMeetingPointDeclarationsChanges(meetingId, pointId));

    return () => {
      dispatch({
        type: 'USER_MEETING_POINT_DECLARATIONS',
        payload: [],
      });
      dispatch({
        type: 'USER_MEETING_DECLARATIONS_CHANGES',
        payload: [],
      });
    };
  }, [dispatch, meetingId, pointId]);

  const downloadFile = useCallback(
    file => e => {
      e.preventDefault();
      dispatch(downloadMeetingPointDeliberation(meetingId, pointId, file.name));
    },
    [meetingId, pointId, dispatch]
  );

  const handleDeliberationUpload = e => {
    e.preventDefault();
    if (deliberationFileRef.current.files.length > 0) {
      setDeliberationFileName(deliberationFileRef.current.files[0].name);

      const fileFormData = new FormData();
      fileFormData.append('file', deliberationFileRef.current.files[0]);

      dispatch(uploadMeetingPointDeliberation(fileFormData, meetingId, pointId));
    }
    e.target.value = null;
  };

  const handleSubmitDeliberationNumber = e => {
    e.preventDefault();

    dispatch(updateDeliberationNumber(deliberationNumber, meetingId, pointId));
  };

  return (
    <CardBlock label="Deliberação na reunião">
      {/* NOTE: meetingName é inserido no ProposalVersions /> */}
      {!isEmpty(meetingName) && (
        <>
          <Link to={`/reunioes/consultar/${meetingId}`} target="_blank" rel="noopener noreferrer">
            <LabelAndValue
              label="Reunião"
              value={meetingName}
              hoverable
              underline
              component="span"
            />
          </Link>
          <Box height="20px" />
        </>
      )}
      <Subtitle2 tertiary className={classes.label}>
        Resultado da deliberação
      </Subtitle2>
      <MeetingPointStatus status={status} clean />
      {hasVotes && (
        <>
          <Box height="20px" />
          <Subtitle2 tertiary className={classes.label}>
            Votação
          </Subtitle2>
          <DeliberationVotes
            votes={votes}
            secretVote={Boolean(secret_vote)}
            users={meetingUsers}
            isGroup={meetingIsGroup}
            qualityVoteUser={quality_vote_user}
          />
        </>
      )}
      {isSecret && (
        <>
          <Box height="20px" />
          <Subtitle2 tertiary className={classes.label}>
            Votação
          </Subtitle2>
          <Body1 className={classes.labelGroup}>
            A proposta foi deliberada através de voto secreto.
          </Body1>
          <Body2>Total de votos: {total}</Body2>
          <Body2>A favor: {favor}</Body2>
          <Body2>Contra: {against}</Body2>
          <Body2>Abstenções: {abstention}</Body2>
        </>
      )}
      {hasBlockedUsers && (
        <>
          <Box height="20px" />
          <Subtitle2 tertiary className={classes.label}>
            Participantes com voto bloqueado
          </Subtitle2>
          <ParticipantsBlocked blocked={blocked_users} />
        </>
      )}
      {isDiscussed && (
        <>
          <Box height="20px" />
          <Subtitle2 tertiary className={classes.label}>
            Ficheiro de deliberação
          </Subtitle2>
          <Grid container>
            {deliberationFileName && (
              <Grid item>
                <FileBox
                  file={{ name: deliberationFileName }}
                  download
                  downloadFile={downloadFile}
                />
              </Grid>
            )}
            <Grid item>
              <label htmlFor="input-deliberation-files">
                <input
                  accept=".pdf"
                  className={classes.input}
                  id="input-deliberation-files"
                  ref={deliberationFileRef}
                  type="file"
                  onChange={handleDeliberationUpload}
                />
                <Button
                  component="span"
                  classes={{ label: classes.buttonText, root: classes.root }}
                >
                  Carregar ficheiro de Deliberação
                  <AddCircleOutlined className={classes.icon} />
                </Button>
              </label>
            </Grid>
          </Grid>
          <Box height="20px" />
          <Subtitle2 tertiary className={classes.label}>
            Número de deliberação
          </Subtitle2>
          <Grid container alignContent="flex-end" spacing={2} justify="flex-end">
            <Grid item className={classes.grow}>
              <InputField
                name="deliberationNumber"
                value={deliberationNumber}
                onChange={e => setDeliberationNumber(e.target.value)}
                className={classes.noMargin}
              />
            </Grid>
            <Grid item>
              <ButtonNormal
                color="primary"
                pea
                contained
                small
                label="Atualizar"
                onClick={handleSubmitDeliberationNumber}
              />
            </Grid>
          </Grid>
        </>
      )}
      {hasSpeakers && (
        <>
          <Box height="20px" />
          <Subtitle2 tertiary className={classes.label}>
            Oradores
          </Subtitle2>
          <OratoryUsers speak={speak} users={meetingUsers} isGroup={meetingIsGroup} />
        </>
      )}
      {hasDeclarations && (
        <>
          <Box height="20px" />
          <Subtitle2 tertiary className={classes.label}>
            Declarações de voto
          </Subtitle2>
          <UsersDeclarations
            declarations={fileDeclarations}
            meetingId={meetingId}
            pointId={pointId}
            idUser={userId}
            permissions={permissions}
          />
        </>
      )}
      {isDiscussed && !isUserInDeclarations && !isSecretary && (
        <PendingDeclaration
          declaration={{ id: 'aux', point: { id: pointId } }}
          showLabel
          meetingId={meetingId}
          userId={userId}
        />
      )}
      {isDiscussed &&
        isSecretary &&
        ((changes && changes.length > 0) || (plainUsers && plainUsers.length > 0)) && (
          <>
            <Box height="20px" />
            {changes && changes.length > 0 && (
              <DeclarationChanges changes={changes.sort((a, b) => b.created_at - a.created_at)} />
            )}
            {plainUsers && plainUsers.length > 0 && (
              <SubmitUserDeclaration users={plainUsers} meetingId={meetingId} pointId={pointId} />
            )}
          </>
        )}
    </CardBlock>
  );
};

MeetingDeliberation.defaultProps = {
  meetingName: null,
  meetingId: null,
};

MeetingDeliberation.propTypes = {
  meetingPointStatus: PropTypes.object.isRequired,
  meetingIsGroup: PropTypes.bool.isRequired,
  meetingUsers: PropTypes.array.isRequired,
  code: PropTypes.string.isRequired,
  meetingName: PropTypes.string,
  pointId: PropTypes.number.isRequired,
  meetingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default React.memo(MeetingDeliberation);
