import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import H6 from '../../../common/typographys/H6';
import H5 from '../../../common/typographys/H5';
import secondsToTime from '../../../../utils/secondsToTime';

const useStyles = makeStyles(theme => ({
  mainBox: {
    backgroundColor: theme.palette.neutrals[1],
    padding: '15px 30px 15px 30px',
  },
  overtime: {
    backgroundColor: theme.palette.error.light,
  },
}));

const PlateiaTimer = ({ timer, label, inlineLabel }) => {
  const classes = useStyles();

  return (
    <Box className={`${classes.mainBox} ${timer.value < 0 && classes.overtime}`}>
      {!inlineLabel && <H6>{label}</H6>}
      <H5 center={inlineLabel}>{(inlineLabel ? label : '') + secondsToTime(timer.value)}</H5>
    </Box>
  );
};

PlateiaTimer.defaultProps = {
  label: '',
  inlineLabel: false,
};

PlateiaTimer.propTypes = {
  timer: PropTypes.shape({
    value: PropTypes.number,
  }).isRequired,
  label: PropTypes.string,
  inlineLabel: PropTypes.bool,
};

export default PlateiaTimer;
