import remove from 'lodash/remove';
import flattenDeep from 'lodash/flattenDeep';
import values from 'lodash/values';

const valuesObj = obj => {
  return Object.values(obj).map(item => (typeof item === 'object' ? valuesObj(item) : item));
};

const checkEmptyObject = obj => {
  if (!obj) {
    return [];
  }
  return remove(flattenDeep(values(valuesObj(obj))));
};

export default checkEmptyObject;
