import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body1 from '../../../../common/typographys/Body1';

const useStyles = makeStyles(theme => ({
  boxParticipant: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.neutrals[2],
    padding: '8px 10px',
    marginBottom: '12px',
  },
  boxIndex: {
    width: '30px',
    marginRight: '14px',
  },
}));

const GroupParticipant = ({ idx, name }) => {
  const classes = useStyles();

  return (
    <Box className={classes.boxParticipant}>
      <Box display="flex" alignItems="center" width="100%">
        <Box className={classes.boxIndex}>
          <Body1 secondary>{parseInt(idx, 10) + 1}º</Body1>
        </Box>
        <Box>
          <Body1>{name}</Body1>
        </Box>
      </Box>
    </Box>
  );
};

GroupParticipant.propTypes = {
  idx: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default React.memo(GroupParticipant);
