import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import useCommonStyles from './useCommonStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.h3,
  },
  tertiary: {
    color: theme.palette.neutrals[6],
  },
}));

const H3 = ({ children, center, right, oneLine, className, tertiary, ...props }) => {
  const classes = useStyles();
  const common = useCommonStyles();

  return (
    <Typography
      variant="h3"
      classes={{ root: classes.root }}
      className={clsx({
        [common.center]: center,
        [common.right]: right,
        [common.wrapEllipsis]: oneLine,
        [classes.tertiary]: tertiary,
        [className]: className.length > 0,
      })}
      {...props}
    >
      {children}
    </Typography>
  );
};

H3.defaultProps = {
  center: false,
  right: false,
  oneLine: false,
  tertiary: false,
  className: '',
};

H3.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]).isRequired,
  center: PropTypes.bool,
  right: PropTypes.bool,
  oneLine: PropTypes.bool,
  tertiary: PropTypes.bool,
  className: PropTypes.string,
};

export default H3;
