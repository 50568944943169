import React, { useState } from 'react';
import { makeStyles, Paper, Grid, Box, Collapse } from '@material-ui/core';
import PropTypes from 'prop-types';
import ProposalStatusBox from './ProposalStatusBox';
import LabelAndValue from '../../../../common/forms/LabelAndValue';
import isEmpty from '../../../../../utils/isEmpty';
import Subtitle2 from '../../../../common/typographys/Subtitle2';
import Body1 from '../../../../common/typographys/Body1';
import Body2 from '../../../../common/typographys/Body2';
import H6 from '../../../../common/typographys/H6';
import { ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';
import groupBy from 'lodash/groupBy';
import Subtitle1 from '../../../../common/typographys/Subtitle1';

const useStyles = makeStyles(theme => ({
  paperContent: {
    padding: '20px',
  },
  labelVote: {
    padding: '20px 10px 10px 14px',
  },
  smalLabel: {
    backgroundColor: theme.palette.neutrals[2],
    padding: '20px 0px 10px 0px',
    display: 'flex',
    justifyContent: 'center',
  },
  voteLabel: {
    backgroundColor: theme.palette.neutrals[2],
    padding: '16px 14px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  voteCount: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '16px 10px',
  },
  icon: {
    color: theme.palette.neutrals[6],
    '&:hover': {
      cursor: 'pointer',
    },
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.short,
    }),
  },
  iconOpen: {
    transform: 'rotate(180deg)',
  },
  iconClosed: {
    transform: 'rotate(0)',
  },
  boxVotes: {
    padding: '14px',
    backgroundColor: theme.palette.neutrals[2],
  },
  smallMargin: {
    marginBottom: '2px',
  },
}));

const EntryVote = ({ label, value }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.smallMargin}>
      <Grid item xs={9}>
        <Box className={classes.voteLabel}>
          <Body1>{label}</Body1>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box className={classes.voteCount}>
          <H6 center>{!isEmpty(value) ? value : 0}</H6>
        </Box>
      </Grid>
    </Grid>
  );
};

EntryVote.defaultProps = {
  value: null,
};

EntryVote.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const EntryVoteCollapse = ({ label, value, users, isGroup, qualityVoteUser }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const groupVotes = (isGroup && groupBy(users, user => user.groupName)) || {};

  return (
    <Grid container className={classes.smallMargin}>
      <Grid item xs={9}>
        <Box
          className={classes.voteLabel}
          onClick={e => {
            e.preventDefault();
            setOpen(!open);
          }}
        >
          <Body1>{label}</Body1>
          {users.length > 0 && (
            <ExpandMore
              className={clsx(classes.icon, {
                [classes.iconOpen]: open,
                [classes.iconClosed]: !open,
              })}
            />
          )}
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box className={classes.voteCount}>
          <H6 center>{!isEmpty(value) ? value : 0}</H6>
        </Box>
      </Grid>
      {users.length > 0 && (
        <Grid item xs={9}>
          <Collapse in={open}>
            <Box className={classes.boxVotes}>
              {isGroup &&
                Object.keys(groupVotes).map((group, idx) => (
                  <React.Fragment key={group}>
                    <Body2 active className={classes.smallMargin}>
                      {group}
                    </Body2>
                    {groupVotes[group].map(user => (
                      <Subtitle1 key={user.id} component="p" className={classes.smallMargin}>
                        {user.name} {qualityVoteUser === user.id && '(Voto de qualidade)'}
                      </Subtitle1>
                    ))}
                    {idx !== Object.keys(groupVotes).length - 1 && <Box height="14px" />}
                  </React.Fragment>
                ))}
              {!isGroup &&
                users.map(user => (
                  <Subtitle1 key={user.id} component="p">
                    {user.name}
                  </Subtitle1>
                ))}
            </Box>
          </Collapse>
        </Grid>
      )}
    </Grid>
  );
};

EntryVoteCollapse.defaultProps = {
  value: null,
  users: [],
  isGroup: false,
  qualityVoteUser: null,
};

EntryVoteCollapse.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  qualityVoteUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  users: PropTypes.array,
  isGroup: PropTypes.bool,
};

const HeaderVotes = () => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={9}>
        <Box className={classes.labelVote}>
          <Subtitle2>Tipo de voto</Subtitle2>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box className={classes.smalLabel}>
          <Subtitle2 center>Votos</Subtitle2>
        </Box>
      </Grid>
    </Grid>
  );
};

const ProposalStatusInfo = ({ pointStatus, users, isGroup }) => {
  const classes = useStyles();
  const {
    point_status: status,
    notes,
    votes,
    /* blocked_users, */ qualityVoteUser,
    secret_vote,
    favor,
    against,
    abstention,
    novote,
  } = pointStatus;

  const entryVotes = [
    { label: 'Votos a favor', value: favor, name: 'favor' },
    { label: 'Votos contra', value: against, name: 'against' },
    { label: 'Abstenções', value: abstention, name: 'abstention' },
    { label: 'Escusa de voto', value: novote, name: 'novote' },
  ];

  const usersByVote = isGroup
    ? votes.reduce(
        (acc, cur) => {
          if (!secret_vote) {
            const groupObj = users.find(
              x => x && cur && x.id && cur.group_id && x.id.toString() === cur.group_id.toString()
            );
            const userObj =
              (groupObj &&
                groupObj.users.find(
                  x => x && cur && x.id && cur.id && x.id.toString() === cur.id.toString()
                )) ||
              null;
            if (!isEmpty(userObj)) {
              switch (cur.vote) {
                case 'favor':
                  acc.favor.push({ ...userObj, groupName: groupObj.name });
                  break;
                case 'against':
                  acc.against.push({ ...userObj, groupName: groupObj.name });
                  break;
                case 'abstention':
                  acc.abstention.push({ ...userObj, groupName: groupObj.name });
                  break;
                case 'novote':
                  acc.novote.push({ ...userObj, groupName: groupObj.name });
                  break;
                default:
              }
            }
          }
          return acc;
        },
        { favor: [], against: [], abstention: [], novote: [] }
      )
    : votes.reduce(
        (acc, cur) => {
          if (!secret_vote) {
            const userObj = users.find(
              x => x && cur && x.id && cur.id && x.id.toString() === cur.id.toString()
            );
            if (!isEmpty(userObj)) {
              switch (userObj.vote) {
                case 'favor':
                  acc.favor.push(userObj);
                  break;
                case 'against':
                  acc.against.push(userObj);
                  break;
                case 'abstention':
                  acc.abstention.push(userObj);
                  break;
                case 'novote':
                  acc.novote.push(userObj);
                  break;
                default:
              }
            }
          }
          return acc;
        },
        { favor: [], against: [], abstention: [], novote: [] }
      );

  return (
    <>
      {(status === 1 || status === 2) && (
        <>
          {secret_vote ? (
            <Paper>
              <HeaderVotes />
              {entryVotes.map(entry => (
                <EntryVote key={entry.name} {...entry} />
              ))}
            </Paper>
          ) : (
            <Paper>
              <HeaderVotes />
              {entryVotes.map(entry => (
                <EntryVoteCollapse
                  key={entry.name}
                  users={usersByVote[entry.name]}
                  isGroup={isGroup}
                  qualityVoteUser={qualityVoteUser}
                  {...entry}
                />
              ))}
            </Paper>
          )}
        </>
      )}
      {!isEmpty(notes) && (status === 3 || status === 4) && (
        <Paper className={classes.paperContent}>
          <LabelAndValue label="Notas adicionais" value={notes} />
        </Paper>
      )}
      <ProposalStatusBox
        status={status}
        noMargin={!isEmpty(notes) && (status === 3 || status === 4)}
      />
    </>
  );
};

ProposalStatusInfo.propTypes = {
  pointStatus: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  isGroup: PropTypes.bool.isRequired,
};

export default React.memo(ProposalStatusInfo);
