import React, { useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import BlockUserInfo from './BlockUserInfo';
import BoxSlider from '../../plateia/BoxSlider';
import useCustomSlider from '../../../../../utils/hooks/useCustomSlider';

const SHOW_MD = 6;
const SHOW_LG = 12;
const SHOW_XL = 18;
const SLIDE_TIME = 10000;

const ParticipantUsersPlateia = ({ users, participants, isGroup, infoSpeaking }) => {
  const usersLength = useMemo(() => (users ? users.length : 0), [users]);
  const printSingleGroupUsers = useMemo(() => isGroup && usersLength === 1, [usersLength, isGroup]);
  const printGroupNames = useMemo(() => isGroup && usersLength > 1, [usersLength, isGroup]);
  const printSingleUsers = useMemo(() => !isGroup, [isGroup]);

  const hasCheckIn = useCallback(
    user => {
      const userObj = participants.find(x => x.id === user.id) || {};
      return userObj.checkIn === true;
    },
    [participants]
  );

  const usersBlocks = useMemo(() => {
    if (printSingleGroupUsers) {
      return users[0].users.filter(hasCheckIn);
    }

    if (printGroupNames) {
      return users;
    }

    if (printSingleUsers) {
      return users.filter(hasCheckIn);
    }

    return [];
  }, [users, hasCheckIn, printSingleGroupUsers, printGroupNames, printSingleUsers]);

  const [usersChunked, curIndex, setCurIndex] = useCustomSlider(
    usersBlocks,
    SHOW_MD,
    SHOW_LG,
    SHOW_XL,
    SLIDE_TIME,
    infoSpeaking !== null
  );

  useEffect(() => {
    if (infoSpeaking !== null) {
      // @ Alguém está a falar
      if (printGroupNames) {
        usersChunked.forEach((chunk, idx) => {
          chunk.forEach(group => {
            if (
              group.id &&
              infoSpeaking.groupId &&
              group.id.toString() === infoSpeaking.groupId.toString()
            ) {
              setCurIndex(idx);
            }
          });
        });
      } else {
        usersChunked.forEach((chunk, idx) => {
          chunk.forEach(user => {
            if (
              user.id &&
              infoSpeaking.userId &&
              user.id.toString() === infoSpeaking.userId.toString()
            ) {
              setCurIndex(idx);
            }
          });
        });
      }
    }
  }, [infoSpeaking, usersChunked, setCurIndex, printGroupNames]);

  const checkIsSpeaking = useCallback(
    id => {
      if (isGroup) {
        return (
          infoSpeaking && infoSpeaking.groupId && id.toString() === infoSpeaking.groupId.toString()
        );
      }

      return infoSpeaking && id.toString() === infoSpeaking.userId.toString();
    },
    [infoSpeaking, isGroup]
  );

  return (
    <>
      {usersChunked[curIndex] &&
        usersChunked[curIndex].map(user => (
          <BlockUserInfo key={user.id} user={user} checkIsSpeaking={checkIsSpeaking} />
        ))}
      {usersChunked.length > 1 && <BoxSlider curIndex={curIndex} length={usersChunked.length} />}
    </>
  );
};

ParticipantUsersPlateia.defaultProps = {
  infoSpeaking: null,
};

ParticipantUsersPlateia.propTypes = {
  users: PropTypes.array.isRequired,
  participants: PropTypes.array.isRequired,
  isGroup: PropTypes.bool.isRequired,
  infoSpeaking: PropTypes.object,
};

export default React.memo(ParticipantUsersPlateia);
