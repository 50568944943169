import React, { useContext, useCallback } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { RemoveCircle } from '@material-ui/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { TypologyDispatchContext } from '../CreateEditTypologyProvider';
import BoxInput from './BoxInput';
import useBooleanToggle from '../../../../../utils/hooks/useBooleanToggle';
import DialogConfirmAction from '../../../../common/dialogs/DialogConfirmAction';

const useStyles = makeStyles(theme => ({
  mainBox: {
    display: 'flex',
    width: '100%',
    padding: '8px 10px',
    backgroundColor: theme.palette.neutrals[2],
    height: '40px',
  },
  iconBox: {
    paddingLeft: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.neutrals[4],
    transform: 'scale(0.666666666)',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.colorsPalette.RustRed,
    },
  },
  disabled: {
    pointerEvents: 'none',
  },
}));

const ThemePointItem = ({ point, idTheme, idxTheme, idxPoint, themeName }) => {
  const classes = useStyles();
  const dispatch = useContext(TypologyDispatchContext);
  const [openModal, toggleModal] = useBooleanToggle();

  const handleDelete = useCallback(
    idPoint => e => {
      e.preventDefault();
      toggleModal();
      dispatch({
        type: 'DELETE_THEME_POINT',
        payload: {
          idxTheme,
          idxPoint,
          id: idPoint,
          idTheme,
        },
      });
    },
    [toggleModal, idxTheme, idTheme, idxPoint, dispatch]
  );

  return (
    <>
      <Box className={classes.mainBox}>
        <BoxInput
          value={point.name}
          onChange={e => {
            dispatch({
              type: 'UPDATE_THEME_POINT_NAME',
              payload: {
                idxTheme,
                idxPoint,
                value: e.target.value,
              },
            });
          }}
        />
        <Box className={clsx(classes.iconBox)}>
          <RemoveCircle
            className={classes.icon}
            onClick={e => {
              e.preventDefault();
              toggleModal();
            }}
          />
        </Box>
      </Box>
      <DialogConfirmAction
        open={openModal}
        handleClose={toggleModal}
        msg={`Deseja remover o ponto de discussão, ${point.name}, do tema ${themeName}?`}
        handleSubmit={handleDelete(point.id)}
        labelSubmit="Remover"
      />
    </>
  );
};

ThemePointItem.propTypes = {
  point: PropTypes.object.isRequired,
  idTheme: PropTypes.number.isRequired,
  idxTheme: PropTypes.number.isRequired,
  idxPoint: PropTypes.number.isRequired,
  themeName: PropTypes.string.isRequired,
};

export default React.memo(ThemePointItem);
