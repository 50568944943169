import isEmpty from './isEmpty';

const getInitials = name => {
  if (isEmpty(name)) {
    return '';
  }

  const parts = name.split(' ');
  let initials = '';
  for (let i = 0; i < parts.length; i++) {
    if (parts[i].length > 0 && parts[i] !== '') {
      initials += parts[i][0].toUpperCase();
    }
  }

  if (parts.length === 1 && name.length > 1) {
    if (name[1] !== '' && name[1] !== undefined) {
      initials += name[1].toUpperCase();
    }
  }

  return initials.length > 1 ? initials[0] + initials[initials.length - 1] : initials[0];
};

export default getInitials;
