import React, { useContext, useEffect, useCallback } from 'react';
import InputField from '../../../common/forms/InputField';
import { EntityStateContext, EntityDispatchContext } from './CreateEditEntityProvider';

const FormInfoEntity = () => {
  const {
    state: { name, nif, email, contact, errors },
  } = useContext(EntityStateContext);
  const dispatch = useContext(EntityDispatchContext);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'CLEAR_STATE',
      });
    };
  }, [dispatch]);

  const updateField = useCallback(
    (nameInput, value) => {
      dispatch({
        type: 'UPDATE_FIELD',
        payload: {
          name: nameInput,
          value,
        },
      });
    },
    [dispatch]
  );

  return (
    <>
      <InputField label="Nome" name="name" value={name} onChange={e => updateField('name', e.target.value)} error={errors.name} />
      <InputField label="Email" name="email" type="email" value={email} onChange={e => updateField('email', e.target.value)} error={errors.email} />
      <InputField label="NIF" name="nif" type="number" value={nif} onChange={e => updateField('nif', e.target.value)} error={errors.nif} />
      {/* <InputField label="Tipo de entidade" name="type" value={type} onChange={e => updateField('type', e.target.value)} error={errors.type} /> */}
      <InputField
        label="Contacto"
        name="contact"
        type="tel"
        value={contact}
        onChange={e => updateField('contact', e.target.value)}
        error={errors.contact}
      />
    </>
  );
};

export default FormInfoEntity;
