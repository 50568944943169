import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import Subtitle4 from '../../common/typographys/Subtitle4';
import clsx from 'clsx';
import isEmpty from '../../../utils/isEmpty';
import PropTypes from 'prop-types';
import Subtitle1 from '../../common/typographys/Subtitle1';

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.neutrals[2],
    padding: '8px 10px',
    width: '100%',
    marginBottom: '20px',
  },
  withHelper: {
    marginBottom: '4px',
  },
  helper: {
    marginBottom: '18px',
    paddingLeft: '10px',
  },
  content: {
    padding: '0px 10px',
    marginBottom: '20px',
  },
  error: {
    '& span': {
      color: theme.palette.colorsPalette.RustRed,
    },
  },
}));

const BoxHeaderFeatures = ({ children, header, helper, error }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={clsx(classes.header, { [classes.withHelper]: !isEmpty(helper) || !isEmpty(error) })}>
        <Subtitle4>{header}</Subtitle4>
      </Box>
      {(!isEmpty(helper) || !isEmpty(error)) && (
        <Box className={clsx(classes.helper, { [classes.error]: !isEmpty(error) })}>
          <Subtitle1 inactive>{!isEmpty(error) ? error : helper}</Subtitle1>
        </Box>
      )}
      <Box className={classes.content}>{children}</Box>
    </>
  );
};

BoxHeaderFeatures.defaultProps = {
  helper: null,
  error: null,
};

BoxHeaderFeatures.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  header: PropTypes.string.isRequired,
  helper: PropTypes.string,
  error: PropTypes.string,
};

export default BoxHeaderFeatures;
