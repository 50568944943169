import React, { useEffect, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import isEmpty from '../../../utils/isEmpty';
import useBooleanToggle from '../../../utils/hooks/useBooleanToggle';

export const MeetingCommon = React.createContext();

const MeetingCommonWrapper = ({ children, setChange, navigate }) => {
  const { reunion } = useSelector(state => state.reunions);
  const [selected, setSelected] = useState({});
  const [auxSelected, setAuxSelected] = useState({});
  const [openWarning, toggleOpenWarning] = useBooleanToggle();
  const [nextLocation, setNextLocation] = useState('');
  const [reunionEnd, setReunionEnd] = useState(false);

  const reunionParticipants = useMemo(
    () =>
      reunion.participants &&
      reunion.participants.reduce(
        (acc, cur) => {
          if (cur.time !== undefined) {
            acc.speakers.push({ ...cur, speaking: false, current: 0 });
          } else {
            acc.others.push(cur);
          }

          return acc;
        },
        { speakers: [], others: [] }
      ),
    [reunion.participants]
  );

  useEffect(() => {
    const exitHandler = e => {
      e.preventDefault();
      e.returnValue = 'Pretende sair do site?';

      return e;
    };

    if (!reunionEnd) {
      window.addEventListener('beforeunload', exitHandler);
    } else {
      window.removeEventListener('beforeunload', exitHandler);
    }

    return () => {
      window.removeEventListener('beforeunload', exitHandler);
    };
  }, [reunionEnd]);

  const handleInfo = useCallback(
    (info, idxTheme, idxProposal) => e => {
      if (e) e.preventDefault();
      // @ idx2 é enviado apenas se for uma proposta e corresponde ao idx do tema, se vier null é um tema a ser discutido

      if (isEmpty(info)) {
        // @ clicou para fechar, limpa o selected
        return setSelected({});
      }

      return setSelected({ ...info, idxTheme, idxProposal });
    },
    []
  );

  const handleImmediateVote = useCallback(
    (info, idxTheme, idxProposal, skipPrepareVote) => () => {
      console.log('handleImmediateVote', info, idxTheme, idxProposal, skipPrepareVote);
      return setSelected({ ...info, idxTheme, idxProposal, skipPrepareVote });
    },
    []
  );

  const handleImmediateDiscussion = useCallback(
    (info, idxTheme, idxProposal, skipPrepareDiscussion) => () => {
      console.log('handleImmediateDiscussion', info, idxTheme, idxProposal, skipPrepareDiscussion);
      return setSelected({ ...info, idxTheme, idxProposal, skipPrepareDiscussion });
    },
    []
  );

  const changePage = useCallback(
    wantedPage => {
      toggleOpenWarning();
      setNextLocation(wantedPage.pathname);
      setChange(false);
      return false;
    },
    [toggleOpenWarning, setChange]
  );

  const confirmChange = useCallback(
    e => {
      e.preventDefault();

      navigate(nextLocation);
    },
    [navigate, nextLocation]
  );

  const cancelLeaving = useCallback(
    e => {
      e.preventDefault();

      toggleOpenWarning();

      setChange(true);
    },
    [setChange, toggleOpenWarning]
  );

  return (
    <MeetingCommon.Provider
      value={{
        reunionParticipants,
        handleInfo,
        handleImmediateVote,
        handleImmediateDiscussion,
        changePage,
        confirmChange,
        cancelLeaving,
        setSelected,
        selected,
        openWarning,
        reunionEnd,
        setReunionEnd,
        auxSelected,
        setAuxSelected,
      }}
    >
      {children}
    </MeetingCommon.Provider>
  );
};

MeetingCommonWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  navigate: PropTypes.func.isRequired,
  setChange: PropTypes.func.isRequired,
};

export default MeetingCommonWrapper;
