import React from 'react';
import SocketReunionWrapper from './SocketReunionWrapper';
import Meeting from './Meeting';

const WrapPlateia = () => {
  return (
    <SocketReunionWrapper plateia>
      <Meeting plateia />
    </SocketReunionWrapper>
  );
};

export default WrapPlateia;
