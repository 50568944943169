import { useState, useEffect } from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import chunkArray from '../chunkArray';
import useInterval from './useInterval';

const useCustomSlider = (array, showMd, showLg, showXl, slideTime = 10000, stop) => {
  const [showItems, setShowItems] = useState(showMd);
  const theme = useTheme();
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isMd = useMediaQuery(theme.breakpoints.up('lg'));
  const [curIndex, setCurIndex] = useState(0);

  useEffect(() => {
    if (isMd) {
      setShowItems(showMd);
      setCurIndex(0);
    }

    if (isLg) {
      setShowItems(showLg);
      setCurIndex(0);
    }

    if (isXl) {
      setShowItems(showXl);
      setCurIndex(0);
    }
  }, [isXl, isLg, isMd, showMd, showLg, showXl]);

  const arrayChunked = chunkArray(array, showItems);

  useInterval(
    () => {
      if (arrayChunked.length > 1) {
        if (curIndex < arrayChunked.length - 1) {
          setCurIndex(curIndex + 1);
        } else {
          setCurIndex(0);
        }
      }
    },
    stop ? null : slideTime
  );

  return [arrayChunked, curIndex, setCurIndex];
};

export default useCustomSlider;
