import React, { useContext, useEffect, useMemo, useCallback } from 'react';
import ConfigList from '../../pieces/ConfigList';
import InfoSection from '../../pieces/InfoSection';
import isEmpty from '../../../../utils/isEmpty';
import { ConfigStateContext, ConfigDispatchContext } from '../../MenuConfigProvider';
import { useDispatch, useSelector } from 'react-redux';
import { removeTheme, getThemes } from '../../../../store/actions/themesActions';
import SeeEditTheme from './SeeEditTheme';
import CreateEditThemeProvider from './CreateEditThemeProvider';
import AddNewTheme from './AddNewTheme';

const Themes = () => {
  const { themes = [] } = useSelector(state => state.themes);
  const { permissions } = useSelector(state => state.auth.user);
  const { activeItem, isAddNew, deleting } = useContext(ConfigStateContext);
  const dispatchConfig = useContext(ConfigDispatchContext);
  const dispatchRedux = useDispatch();

  useEffect(() => {
    dispatchRedux(getThemes());
  }, [dispatchRedux]);

  const hasPagination = useMemo(() => themes.length > 10, [themes]);
  const pageObj = useMemo(() => ({ isItemTheme: true }), []);

  const handleDelete = useCallback(
    e => {
      e.preventDefault();
      dispatchRedux(
        removeTheme(activeItem.id, () =>
          dispatchConfig({
            type: 'CANCEL_ADD_NEW',
          })
        )
      );
    },
    [dispatchRedux, activeItem, dispatchConfig]
  );

  return (
    <>
      <ConfigList
        items={themes}
        pageObj={pageObj}
        labelButton="Novo tema"
        noItemMessage="Nenhum tema adicionado."
        msgDelete={`Tem a certeza que deseja excluir o tema${activeItem && activeItem.name ? `, ${activeItem.name}` : ''}?`}
        labelSubmit="Excluir"
        canDelete={Boolean(permissions && permissions.others && permissions.others.themes)}
        canAddNew={permissions && permissions.others && permissions.others.themes}
        handleDelete={handleDelete}
      />
      <InfoSection hasPagination={hasPagination}>
        <CreateEditThemeProvider items={themes}>
          {!isEmpty(activeItem) && !deleting && <SeeEditTheme info={activeItem} />}
          {isAddNew && <AddNewTheme />}
        </CreateEditThemeProvider>
      </InfoSection>
    </>
  );
};

export default Themes;
