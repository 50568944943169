import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  boxIcons: {
    display: 'flex',
    padding: '5.6px 0px',
    marginLeft: '6.6px',
    flexDirection: 'column',
    height: '40px',
    justifyContent: 'space-between',
  },
  icon: {
    padding: 0,
    color: theme.palette.neutrals[4],
    width: '10.8px',
    height: '10.8px',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.neutrals[6],
    },
  },
}));

const BoxIconsVote = ({ inputRef, type, changeVote }) => {
  const classes = useStyles();

  return (
    <Box className={classes.boxIcons}>
      <AddCircle
        className={classes.icon}
        onClick={() => {
          inputRef.current.stepUp();
          changeVote(type, inputRef.current.value);
        }}
      />
      <RemoveCircle
        className={classes.icon}
        onClick={() => {
          inputRef.current.stepDown();
          changeVote(type, inputRef.current.value);
        }}
      />
    </Box>
  );
};

BoxIconsVote.defaultProps = {
  inputRef: null,
};

BoxIconsVote.propTypes = {
  type: PropTypes.string.isRequired,
  changeVote: PropTypes.func.isRequired,
  inputRef: PropTypes.object,
};

export default React.memo(BoxIconsVote);
