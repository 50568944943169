import React, { useContext, useCallback, useEffect } from 'react';
import { PersonalProfileStateContext, PersonalProfileFuncsContext, PersonalProfileDispatchContext } from './PersonalProfileProvider';
import InputField from '../common/forms/InputField';
import checkEmptyObject from '../../utils/checkEmptyObject';
import ButtonNormal from '../common/buttons/ButtonNormal';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box } from '@material-ui/core';
import validEmail from '../../utils/validation/validEmail';
import validateNIF from '../../utils/validateNIF';
import isEmpty from '../../utils/isEmpty';
import validPhone from '../../utils/validation/validPhone';
import SelectOutlined from '../common/forms/SelectOutlined';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  button: {
    width: '100%',
    height: '100%',
  },
}));

const ProfileFieldsInfoEditable = () => {
  const classes = useStyles();
  const {
    state: {
      name,
      email,
      cc,
      phone,
      nif,
      role,
      errors,
      organicUnit,
      user: { id },
    },
    handleSubmit,
  } = useContext(PersonalProfileStateContext);
  const { organicUnits } = useSelector(state => state.organicUnits);
  const dispatch = useContext(PersonalProfileDispatchContext);
  const { updateField, setImage } = useContext(PersonalProfileFuncsContext);

  const common = {
    fullWidth: true,
    onChange: e => updateField(e.target.name, e.target.value),
  };

  const fields = [
    { name: 'name', value: name, label: 'Nome', error: errors.name },
    { name: 'email', value: email, label: 'Email', type: 'email', error: errors.email, disabled: true },
    { name: 'nif', value: nif, label: 'Número de Identificação Fiscal', type: 'number', error: errors.nif },
    { name: 'cc', value: cc, label: 'Número de Identificação Civil', error: errors.cc },
    { name: 'phone', value: phone, label: 'Contacto telefónico', type: 'tel', error: errors.phone },
    // { name: 'company', value: company, label: 'Empresa' },
    // { name: 'department', value: department, label: 'Departamento', error: errors.department },
    { name: 'role', value: role, label: 'Função', error: errors.role },
  ];

  const handleCancel = useCallback(
    e => {
      e.preventDefault();
      updateField('editing', false);
      setImage('', null);
    },
    [updateField, setImage]
  );

  useEffect(() => {
    if (name.length < 3 && isEmpty(errors.name)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { name: 'Insira um nome válido' },
      });
    }
  }, [name, dispatch, errors.name]);

  useEffect(() => {
    if (!validEmail(email) && isEmpty(errors.email)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { email: 'Insira um email válido' },
      });
    }
  }, [email, dispatch, errors.email]);

  useEffect(() => {
    if (isEmpty(nif)) return;

    if (!validateNIF(nif) && isEmpty(errors.nif)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { nif: 'Insira um NIF válido' },
      });
    }
  }, [nif, dispatch, errors.nif]);

  useEffect(() => {
    if (!isEmpty(phone) && validPhone(phone) && !isEmpty(errors.phone)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { phone: 'Insira um contacto válido' },
      });
    }
  }, [phone, dispatch, errors.phone]);

  return (
    <form>
      {fields.map(field => (
        <InputField key={field.name} {...field} {...common} />
      ))}
      <SelectOutlined
        options={organicUnits}
        value={organicUnit}
        getOptionLabel={option => `${option.abbreviation || option.code} - ${option.name}`}
        getOptionValue={option => option.id}
        label="Unidade Orgânica"
        isDisabled={organicUnit && organicUnit.user_id === id}
        name="organicUnit"
        onChange={val => updateField('organicUnit', val)}
      />
      <Box height="20px" />
      <Grid item>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <ButtonNormal maxWidth variant="outlined" color="primary" label="Cancelar" className={classes.button} onClick={handleCancel} />
          </Grid>
          <Grid item xs={6}>
            <ButtonNormal
              maxWidth
              contained
              pea
              onClick={handleSubmit}
              label="Guardar"
              className={classes.button}
              disabled={checkEmptyObject(errors).length > 0}
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default React.memo(ProfileFieldsInfoEditable);
