/* eslint-disable no-nested-ternary */
import React from 'react';
import { Typography, Divider, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Subtitle1 from '../typographys/Subtitle1';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  footerText: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  footerTextLogin: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  footerDivider: {
    background: theme.palette.primary[100],
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(1) + 2,
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.props.drawerWidth + theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(3),
    },
  },
  footerDividerLogin: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '10px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '20px',
      marginBottom: '10px',
    },
  },
  footerAuth: {
    paddingTop: '20px',
    paddingBottom: '15px',
    display: 'flex',
    justifyContent: 'center',
  },
}));

const Footer = ({ login }) => {
  const classes = useStyles();
  const {
    isAuthenticated,
    user: { name: nameUser },
  } = useSelector(state => state.auth);
  const { name, email, phone } = useSelector(state => state.config.configs);
  const { pathname } = useLocation();

  return login ? (
    <footer style={{ marginBottom: '15px' }}>
      <Divider className={classes.footerDividerLogin} />
      <Typography align="center" className={classes.footerTextLogin}>
        <Typography component="span" variant="overline">
          {name} |
        </Typography>
        <a href={`mailto:${email}`} style={{ textDecoration: 'none', color: 'inherit' }}>
          <Typography component="span" variant="overline">
            {' '}
            {email} |
          </Typography>
        </a>
        <Typography component="span" variant="overline">
          {' '}
          {phone}
        </Typography>
      </Typography>
    </footer>
  ) : isAuthenticated && pathname.indexOf('/plateia') === -1 ? (
    <footer className={classes.footerAuth}>
      {/* <Divider className={classes.footerDivider} /> */}
      {/* <Typography align="right" className={classes.footerText}>
        <Typography component="span" variant="overline">
          {name} |
        </Typography>
        <a href={`mailto:${email}`} style={{ textDecoration: 'none', color: 'inherit' }}>
          <Typography component="span" variant="overline">
            {' '}
            {email} |
          </Typography>
        </a>
        <Typography component="span" variant="overline">
          {' '}
          {phone}
        </Typography>
      </Typography> */}
      <Subtitle1>{pathname !== '/saml-auth' ? nameUser : name}</Subtitle1>
    </footer>
  ) : null;
};

Footer.defaultProps = {
  login: false,
};

Footer.propTypes = {
  login: PropTypes.bool,
};

export default Footer;
