import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Subtitle2 from '../typographys/Subtitle2';
import Body1 from '../typographys/Body1';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: '8px',
  },
  value: {
    marginBottom: '20px',
  },
  smallMarginLabel: {
    marginBottom: '6px!important',
  },
  smallMarginValue: {
    marginBottom: '10px!important',
  },
}));

const LabelAndValue = ({ label, value, smallMargin, ...props }) => {
  const classes = useStyles();

  return (
    <>
      <Subtitle2 tertiary className={clsx(classes.label, { [classes.smallMarginLabel]: smallMargin })}>
        {label}
      </Subtitle2>
      <Body1 className={clsx(classes.value, { [classes.smallMarginValue]: smallMargin })} {...props}>
        {value || '--'}
      </Body1>
    </>
  );
};

LabelAndValue.defaultProps = {
  value: null,
  smallMargin: false,
};

LabelAndValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  smallMargin: PropTypes.bool,
};

export default React.memo(LabelAndValue);
