import { getHours } from 'date-fns';

const getGreetingTime = () => {
  const hour = getHours(new Date());

  if (hour >= 6 && hour < 12) {
    return 'Bom dia';
  }

  if (hour >= 12 && hour < 19) {
    return 'Boa tarde';
  }

  return 'Boa noite';
};

export default getGreetingTime;
