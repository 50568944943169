import React from 'react';
import { makeStyles, Button, Box } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Subtitle4 from '../typographys/Subtitle4';
import isEmpty from '../../../utils/isEmpty';

const useStyles = makeStyles(theme => ({
  common: {
    minHeight: '40px',
    maxHeight: '40px',
  },
  commonFixedSize: {
    width: '253px',
  },
  buttonPea: {
    color: theme.palette.neutrals[1],
    backgroundColor: theme.palette.primary.A400,
    '&:hover': {
      color: theme.palette.neutrals[1],
      backgroundColor: theme.palette.primary.A700,
    },
  },
  disabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonBoxFixedSize: {
    width: '253px',
  },
  helper: {
    marginTop: '4px',
  },
  small: {
    width: '180px !important',
    marginLeft: '0px !important',
  },
  error: {
    color: theme.palette.neutrals[1],
    background: theme.palette.error.main,
    '&:hover': {
      background: theme.palette.error.dark,
    },
  },
  marginLeft: {
    marginLeft: '10px !important',
  },
  maxWidth: {
    maxWidth: '100% !important',
  },
}));

const ButtonNormal = ({
  label,
  contained,
  pea,
  primary,
  className,
  helper,
  small,
  error,
  marginLeft,
  maxWidth,
  fullWidth,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.buttonBox, {
        [classes.small]: small,
        [classes.marginLeft]: marginLeft,
        [classes.maxWidth]: maxWidth,
        [classes.buttonBoxFixedSize]: !fullWidth,
      })}
    >
      <Button
        color={primary ? 'primary' : ''}
        variant={primary ? 'contained' : ''}
        classes={{
          disabled: classes.disabled,
        }}
        className={clsx(classes.common, {
          [classes.buttonPea]: contained && pea,
          [classes.small]: small,
          [classes.error]: error,
          [classes.commonFixedSize]: !fullWidth,
          [className]: className.length > 1,
        })}
        {...props}
      >
        {label}
      </Button>
      {!isEmpty(helper) && (
        <Subtitle4 className={classes.helper} component="p" center>
          {helper}
        </Subtitle4>
      )}
    </Box>
  );
};

ButtonNormal.defaultProps = {
  contained: false,
  pea: false,
  primary: false,
  className: '',
  helper: null,
  small: false,
  error: false,
  marginLeft: false,
  maxWidth: false,
  fullWidth: false,
};

ButtonNormal.propTypes = {
  label: PropTypes.string.isRequired,
  contained: PropTypes.bool,
  pea: PropTypes.bool,
  primary: PropTypes.bool,
  className: PropTypes.string,
  helper: PropTypes.string,
  small: PropTypes.bool,
  error: PropTypes.bool,
  marginLeft: PropTypes.bool,
  maxWidth: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default React.memo(ButtonNormal);
