import React, { useContext, useEffect } from 'react';
import AddNewItem from '../../pieces/AddNewItem';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import { GroupDispatchContext, GroupStateContext } from './CreateEditGroupProvider';
import FormInfoGroup from './FormInfoGroup';

const AddNewGroup = () => {
  const {
    state: { errors, items },
    handleSubmit,
    loading,
  } = useContext(GroupStateContext);
  const dispatch = useContext(GroupDispatchContext);

  useEffect(() => {
    dispatch({
      type: 'CLEAR_STATE',
    });
  }, [dispatch]);

  return (
    <AddNewItem
      disableSubmit={checkEmptyObject(errors).length > 0}
      items={items}
      loading={loading}
      dispatch={dispatch}
      handleSubmit={handleSubmit}
      msgSubmit="Tem a certeza que deseja gravar o novo grupo?"
    >
      <FormInfoGroup />
    </AddNewItem>
  );
};

export default React.memo(AddNewGroup);
