import React from 'react';
import { makeStyles, Divider, Box } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Body1 from '../../common/typographys/Body1';
import isEmpty from '../../../utils/isEmpty';

const useStyles = makeStyles(theme => ({
  label: {
    marginTop: '20px',
    marginBottom: '12px',
    width: '100%',
    '& p': {
      transition: 'color 0.2s ease',
    },
    '&:hover': {
      cursor: 'pointer',
      '& p': {
        color: theme.palette.primary.A700,
      },
    },
  },
  itemBox: {
    display: 'flex',
    alignItems: 'center',
  },
  iconDelete: {
    color: theme.palette.neutrals[4],
    transition: 'color 0.2s ease',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.colorsPalette.RustRed,
    },
  },
  disabled: {
    pointerEvents: 'none',
  },
  hide: {
    display: 'none',
  },
  boxIcon: {
    paddingLeft: '10px',
    paddingRight: '15px',
    marginTop: '20px',
    marginBottom: '12px',
  },
}));

const LabelItemConfig = ({ label, idx, onClick, canDelete, disabled, clickDelete }) => {
  const classes = useStyles();

  return (
    <>
      {idx !== 0 && !isEmpty(label) && <Divider />}
      <Box className={classes.itemBox}>
        <Box className={clsx(classes.label, { [classes.disabled]: disabled })}>
          <Body1 onClick={onClick} disabled={disabled}>
            {label}
          </Body1>
        </Box>
        <Box className={classes.boxIcon}>
          {canDelete ? (
            <Delete className={clsx(classes.iconDelete, { [classes.disabled]: disabled, [classes.hide]: disabled })} onClick={clickDelete} />
          ) : null}
        </Box>
      </Box>
    </>
  );
};

LabelItemConfig.defaultProps = {
  canDelete: false,
};

LabelItemConfig.propTypes = {
  label: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  clickDelete: PropTypes.func.isRequired,
  canDelete: PropTypes.bool,
};

export default LabelItemConfig;
