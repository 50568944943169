/**
 *
 * @description Convert status number to label
 * @param {number} status - Proposal status to validate
 * @returns {string} String with status label
 */
const getMeetingPointStatusLabel = status => {
  switch (status) {
    case 1:
      return 'aprovada';
    case 2:
      return 'reprovada';
    case 3:
      return 'retirada';
    case 4:
      return 'adiada';
    default:
      return 'não discutida';
  }
};

export default getMeetingPointStatusLabel;
