import React, { useReducer, useMemo, useEffect, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from '../../utils/isEmpty';
import { useHistory, useParams } from 'react-router-dom';
import { changeMenuSidebar } from '../../store/actions/navigationActions';
import flatten from 'lodash/flatten';
import { SocketReunion } from './meeting/SocketReunionWrapper';
import {
  updateMeetingBoard,
  startMeeting,
  lockMeeting,
  unlockMeeting,
} from '../../store/actions/meetingsActions';

export const PrepareMeetingDispatchContext = React.createContext();
export const PrepareMeetingFuncsContext = React.createContext();
export const PrepareMeetingStateContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'UPDATE_STATE':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    case 'CHANGE_GROUP_USER': {
      const objChangesGroup = state.changes.find(x => x.id === action.payload.idGroup);
      let changes = [];
      if (objChangesGroup !== undefined) {
        changes = state.changes.map(x =>
          x.id === action.payload.idGroup
            ? {
                ...x,
                users: [
                  ...x.users,
                  { out: action.payload.oldValue.id, in: action.payload.newValue.id },
                ],
              }
            : x
        );
      } else {
        changes = [
          ...state.changes,
          {
            id: action.payload.idGroup,
            users: [{ out: action.payload.oldValue.id, in: action.payload.newValue.id }],
          },
        ];
      }
      return {
        ...state,
        users: state.users.map((x, idx) =>
          idx === action.payload.idxGroup
            ? {
                ...x,
                users: x.users.map((user, idxUser) => {
                  const newIndex = x.users.findIndex(
                    u => u.id.toString() === action.payload.newValue.id.toString()
                  );
                  if (idxUser === action.payload.idxUser) {
                    return { ...action.payload.newValue, in_meeting: true };
                  }

                  if (idxUser === newIndex) {
                    return { ...action.payload.oldValue, in_meeting: false };
                  }

                  return user;
                }),
              }
            : x
        ),
        changes,
      };
    }
    case 'CHANGE_USER':
      return {
        ...state,
        users: state.users.map((user, idxUser) => {
          const newIndex = state.users.findIndex(u => u.id === action.payload.newValue.id);
          if (idxUser === action.payload.idxUser) {
            return { ...action.payload.newValue, in_meeting: true };
          }

          if (idxUser === newIndex) {
            return { ...action.payload.oldValue, in_meeting: false };
          }

          return user;
        }),
        changes: [
          ...state.changes,
          { out: action.payload.oldValue.id, in: action.payload.newValue.id },
        ],
      };
    case 'ADD_SECRETARY':
      return {
        ...state,
        board: {
          ...state.board,
          secretary: [...state.board.secretary, action.payload.value],
        },
      };
    case 'ADD_MODERATOR':
      return {
        ...state,
        board: {
          ...state.board,
          moderator: [...state.board.moderator, action.payload.value],
        },
      };
    case 'REMOVE_SECRETARY':
      return {
        ...state,
        board: {
          ...state.board,
          secretary: state.board.secretary.filter(x => x.id !== action.payload.id),
        },
      };
    case 'REMOVE_MODERATOR':
      return {
        ...state,
        board: {
          ...state.board,
          moderator: state.board.moderator.filter(x => x.id !== action.payload.id),
        },
      };
    case 'CLEAR_STATE':
      return {
        ...state,
        name: '',
        typology: null,
        date: null,
        endDate: null,
        users: [],
        groups: [],
        themes: [],
        points: [],
        changes: [],
        files: [],
        local: null,
        participants: [],
        board: {
          secretary: [],
          moderator: [],
        },
        isGroup: false,
        errors: {},
      };
    default:
      return state;
  }
};

const PrepareMeetingProvider = ({ children, meeting }) => {
  const dispatchRedux = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { secretaries, moderators } = useSelector(stateRedux => stateRedux.meetings);
  const { id: userId, name: userName } = useSelector(stateRedux => stateRedux.auth.user);
  const { socketNsp } = useContext(SocketReunion);
  const [started, setStarted] = useState(false);
  const [state, dispatch] = useReducer(reducer, {
    name: '',
    typology: null,
    date: null,
    endDate: null,
    users: [],
    groups: [],
    themes: [],
    points: [],
    files: [],
    changes: [],
    local: null,
    participants: [],
    board: {
      secretary: [],
      moderator: [],
    },
    isGroup: false,
    errors: {},
  });

  const { board, users } = state;

  const updateField = useCallback((nameInput, value) => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: nameInput,
        value,
      },
    });
  }, []);

  useEffect(() => {
    dispatchRedux(changeMenuSidebar('reuniao', 'Reunião', 'Preparar reunião'));
    function lockOrUnlock(lock, value) {
      if (lock && isEmpty(value)) {
        dispatchRedux(lockMeeting(id));
      }

      if (!lock) {
        dispatchRedux(unlockMeeting(id));
      }
    }

    lockOrUnlock(true, meeting.user_edit);

    return () => {
      lockOrUnlock(false);
    };
  }, [dispatchRedux, id, meeting.user_edit]);

  useEffect(() => {
    if (!isEmpty(meeting) && !isEmpty(meeting.users)) {
      const newParticipants = flatten(
        meeting.is_group
          ? meeting.users.map(x =>
              x.users
                .map(user => ({
                  id: user.id,
                  name: user.name,
                  short_name: user.short_name || user.name,
                  groupId: x.id,
                  group_id: x.id,
                  active: user.id.toString() === userId.toString(),
                  cc: Boolean(user.cc) || false,
                  checkIn: (Boolean(user.check_in) && !user.check_out) || false,
                  in_meeting: user.in_meeting,
                }))
                .filter(user2 => user2.in_meeting)
            )
          : meeting.users
              .map(user => ({
                id: user.id,
                name: user.name,
                short_name: user.short_name || user.name,
                active: user.id.toString() === userId.toString(),
                cc: Boolean(user.cc) || false,
                checkIn: (Boolean(user.check_in) && !user.check_out) || false,
                in_meeting: user.in_meeting,
              }))
              .filter(user2 => user2.in_meeting)
      );

      dispatch({
        type: 'UPDATE_STATE',
        payload: {
          ...meeting,
          isGroup: Boolean(meeting.is_group),
          participants: newParticipants,
        },
      });
    }
  }, [meeting, userId]);

  useEffect(() => {
    if (!isEmpty(secretaries)) {
      updateField('availableSecretary', secretaries);
    }
  }, [secretaries, updateField]);

  useEffect(() => {
    if (!isEmpty(moderators)) {
      updateField('availableModerator', moderators);
    }
  }, [moderators, updateField]);

  useEffect(() => {
    if (started === false) {
      socketNsp.emit(
        'start',
        JSON.stringify({
          ...meeting,
          users: meeting.is_group
            ? meeting.users &&
              meeting.users.map(group => ({
                ...group,
                users: group.users.filter(user => user.in_meeting),
              }))
            : meeting.users && meeting.users.filter(user => user.in_meeting),
          user_edit: { id: userId, name: userName },
        })
      );

      setStarted(true);
    }

    socketNsp.on('list_presences', info => {
      const parsed = JSON.parse(info);
      updateField('participants', parsed.participants);
    });
  }, [updateField, started, meeting, socketNsp, userId, userName]);

  const cancelEdit = useCallback(
    e => {
      e.preventDefault();
      if (socketNsp) {
        socketNsp.emit('end_prepare', JSON.stringify({ id }));
        history.goBack();
      }
    },
    [socketNsp, history, id]
  );

  const handleSubmit = e => {
    e.preventDefault();

    const boardPromise = new Promise((resolve, reject) => {
      dispatchRedux(updateMeetingBoard(id, { board }, resolve, reject));
    });

    boardPromise
      .then(() => {
        const startPromise = new Promise((resolve, reject) => {
          dispatchRedux(startMeeting(id, resolve, reject));
        });

        const discussionTimersReducer = (acc, point) => {
          let pointId;

          if (point.proposal_id !== null) {
            pointId = `proposal-${point.id}`;
          } else {
            pointId = `point-${point.id}`;
          }

          acc[pointId] = {
            presentation: {
              initialValue: 3 * 60,
              value: 3 * 60,
              state: 'stop',
              countdown: true,
            },
            protests: {
              initialValue: 3 * 60,
              value: 3 * 60,
              state: 'stop',
              countdown: true,
            },
          };

          return acc;
        };

        const meetingPoints = state.points || [];
        const pointTimers = meetingPoints.reduce(discussionTimersReducer, {});

        const meetingThemes = state.themes || [];
        const meetingThemesPoints = meetingThemes.reduce((acc, theme) => {
          const themePoints = theme.points || [];

          return acc.concat(themePoints);
        }, []);
        const meetingThemesPointsTimers = meetingThemesPoints.reduce(discussionTimersReducer, {});

        startPromise
          .then(() => {
            const info = {
              ...state,
              users: meeting.is_group
                ? users.map(group => ({
                    ...group,
                    users: group.users.filter(user => user.in_meeting),
                  }))
                : users.filter(user => user.in_meeting),
              timers: {
                meeting: {
                  initialValue: 0,
                  value: 0,
                  state: 'stop',
                  countdown: false,
                },
                publicDiscussion: {
                  initialValue: 5 * 60,
                  value: 5 * 60,
                  state: 'stop',
                  countdown: true,
                },
                beforeDayOrder: {
                  initialValue: 0,
                  value: 0,
                  state: 'stop',
                  countdown: false,
                },
                points: {
                  ...pointTimers,
                  ...meetingThemesPointsTimers,
                },
              },
            };

            socketNsp.emit('update_pre_reunion', JSON.stringify(info));
            history.push(`/reuniao/${id}`);
          })
          .catch(() => {
            dispatchRedux({
              type: 'SHOW_SNACK',
              payload: {
                variant: 'error',
                message: 'Erro ao iniciar reunião.',
              },
            });
          });
      })
      .catch(() => {
        dispatchRedux({
          type: 'SHOW_SNACK',
          payload: {
            variant: 'error',
            message: 'Erro ao atualizar os secretários e moderadores da reunião.',
          },
        });
      });
  };

  const contextState = { state, cancelEdit, handleSubmit };
  const funcsContext = useMemo(() => ({ updateField }), [updateField]);

  return (
    <PrepareMeetingDispatchContext.Provider value={dispatch}>
      <PrepareMeetingFuncsContext.Provider value={funcsContext}>
        <PrepareMeetingStateContext.Provider value={contextState}>
          {children}
        </PrepareMeetingStateContext.Provider>
      </PrepareMeetingFuncsContext.Provider>
    </PrepareMeetingDispatchContext.Provider>
  );
};

PrepareMeetingProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  meeting: PropTypes.object.isRequired,
};

export default PrepareMeetingProvider;
