import React from 'react';
import { Grid } from '@material-ui/core';
import HeaderLabel from './calendar/pieces/HeaderLabel';
import ContainerWithButtons from './calendar/pieces/ContainerWithButtons';
import Calendar from './calendar/Calendar';
import useCalendar from '../../utils/hooks/useCalendar';
import Cronologia from './cronologia/Cronologia';
import { useSelector } from 'react-redux';

const CalendarCronology = () => {
  const [calendarRef, view, date, changeView, handleDatePrev, handleDateNext] = useCalendar('dayGridMonth');
  const { meetings } = useSelector(state => state.meetings);

  return (
    <Grid item md={10} xs={11}>
      <HeaderLabel view={view} />
      <ContainerWithButtons date={date} handleDatePrev={handleDatePrev} handleDateNext={handleDateNext} changeView={changeView} view={view} />
      {view !== 'cronologia' ? <Calendar calendarRef={calendarRef} view={view} events={meetings} /> : <Cronologia events={meetings} />}
    </Grid>
  );
};

export default CalendarCronology;
