import React from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import Body2 from '../../../common/typographys/Body2';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: '8px',
  },
  paper: {
    padding: '20px',
  },
  dense: {
    padding: '13px',
  },
}));

const ProposalCard = ({ label, dense, children }) => {
  const classes = useStyles();

  return (
    <>
      <Body2 secondary className={classes.label}>
        {label}
      </Body2>
      <Paper className={clsx(classes.paper, { [classes.dense]: dense })}>{children}</Paper>
    </>
  );
};

ProposalCard.defaultProps = {
  dense: false,
};

ProposalCard.propTypes = {
  label: PropTypes.string.isRequired,
  dense: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.element]).isRequired,
};

export default ProposalCard;
