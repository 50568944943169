import React from 'react';
import { makeStyles, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import H6 from '../../../../common/typographys/H6';
import LabelAndValue from '../../../../common/forms/LabelAndValue';

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: '8px',
    marginBottom: '20px',
  },
}));

const PresidentInfo = ({ content }) => {
  const classes = useStyles();
  const { userPresident } = content;

  return (
    <>
      <H6>Presidente</H6>
      <Divider className={classes.divider} />
      <LabelAndValue label="Nome do Presidente" value={userPresident.name} />
    </>
  );
};

PresidentInfo.propTypes = {
  content: PropTypes.object.isRequired,
};

export default React.memo(PresidentInfo);
