import React, { useRef, useCallback, useState } from 'react';
import { Box } from '@material-ui/core';
import ProposalCard from '../proposals/new/pieces/ProposalCard';
import BoxUpload from './BoxUpload';
import FileBox from './FileBox';
import useBooleanToggle from '../../utils/hooks/useBooleanToggle';
import DialogConfirmAction from './dialogs/DialogConfirmAction';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Body1 from './typographys/Body1';

const useStyles = makeStyles(theme => ({
  label: {
    marginBottom: '8px',
  },
  error: {
    marginTop: '10px',
    color: theme.palette.error.main,
  },
}));

const CardFilesUpload = ({ state, dispatch }) => {
  const classes = useStyles();
  const filesRef = useRef(null);
  const [name, setName] = useState('');
  const [idFile, setIdFile] = useState('');
  const [openModal, toggleModal] = useBooleanToggle();
  const { files, errors } = state;

  const handleUpload = useCallback(
    e => {
      e.preventDefault();
      const sameFiles = [];

      const checkFilesPromise = new Promise(resolve => {
        if (filesRef.current.files.length !== 0) {
          Array.from(filesRef.current.files).forEach((x, idx) => {
            if (files.find(at => at.name === x.name) !== undefined) {
              sameFiles.push(x.name);
            }
            if (idx === filesRef.current.files.length - 1) {
              resolve(sameFiles);
            }
          });
        }
      });

      checkFilesPromise.then(res => {
        if (res.length > 0) {
          const plural = res.length > 1;
          const pluralS = plural ? 's' : '';
          const pluralM = plural ? 'm' : '';
          const errorMessage = `O${pluralS} ficheiro${pluralS}: ${res.join(
            ', '
          )}, já se encontra${pluralM} adicionado${pluralS}.`;
          dispatch({
            type: 'SET_ERROR',
            payload: {
              files: errorMessage,
            },
          });
        } else {
          dispatch({
            type: 'SET_ERROR',
            payload: {
              files: '',
            },
          });
        }
        dispatch({
          type: 'ADD_FILES',
          payload: {
            value:
              filesRef.current.files.length !== 0
                ? Array.from(filesRef.current.files).filter(
                    x => files.find(f => x.name === f.name) === undefined
                  )
                : [],
          },
        });
      });
    },
    [dispatch, files]
  );

  const deleteFile = useCallback(
    idAttachment => fileName => e => {
      e.preventDefault();
      setName(fileName);
      setIdFile(idAttachment);
      toggleModal();
    },
    [toggleModal]
  );

  const confirmDeleteFile = useCallback(
    (nameFile, idAttachment) => e => {
      e.preventDefault();
      toggleModal();
      dispatch({
        type: 'DELETE_FILE',
        payload: {
          name: nameFile,
          id: idAttachment,
        },
      });
      setName('');
    },
    [dispatch, toggleModal]
  );

  return (
    <>
      <ProposalCard label="Ficheiro(s)">
        {files.length > 0 ? (
          files.map(file => (
            <FileBox
              key={file.id || file.name}
              file={file}
              remove
              deleteFile={deleteFile(file.id)}
            />
          ))
        ) : (
          <BoxUpload filesRef={filesRef} handleUpload={handleUpload} />
        )}
        {errors && errors.files && errors.files.length > 0 && (
          <Body1 className={classes.error}>{errors.files}</Body1>
        )}
      </ProposalCard>
      {files.length > 0 && (
        <>
          <Box height="14px" />
          <BoxUpload filesRef={filesRef} handleUpload={handleUpload} padding />
        </>
      )}
      <DialogConfirmAction
        open={openModal}
        handleClose={toggleModal}
        handleSubmit={confirmDeleteFile(name, idFile)}
        msg={`Deseja eliminar o ficheiro ${name}?`}
        labelSubmit="Eliminar"
      />
    </>
  );
};

CardFilesUpload.propTypes = {
  state: PropTypes.shape({
    files: PropTypes.array.isRequired,
    errors: PropTypes.shape({
      files: PropTypes.array,
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default React.memo(CardFilesUpload);
