import React, { useEffect } from 'react';
import { Grid, Box, Button, Typography } from '@material-ui/core';
import InputField from '../../../common/forms/InputField';
import isEmpty from '../../../../utils/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, cleanRegistration, loginUser2FA } from '../../../../store/actions/authActions';
import Link from '../../../common/routing/Link';
import loginRegisterStyles from '../../../common/layout/styles/loginRegister';
import clsx from 'clsx';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import useFormWithErrors from '../../../../utils/hooks/useFormWithErrors';
import LoadingSpinner from '../../../common/LoadingSpinner';

const FormLogin = () => {
  const [values, handleChange, errors, setErrors] = useFormWithErrors({
    email: '',
    password: '',
    code: '',
  });
  const errorsReducer = useSelector(state => state.errors);
  const { showPin, pinToken, expired, loading } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const classes = loginRegisterStyles();

  useEffect(() => {
    dispatch(cleanRegistration());
  }, [dispatch]);

  useEffect(() => {
    if (!expired) {
      handleChange({ persist: () => null, target: { name: 'code', value: '' } });
    }
  }, [expired, handleChange]);

  useEffect(() => {
    setErrors({
      email: errorsReducer.username,
      password: errorsReducer.password,
      code: errorsReducer.code,
    });
  }, [errorsReducer.username, errorsReducer.password, errorsReducer.code, setErrors]);

  const submitLogin = e => {
    e.preventDefault();
    const { email, password, code } = values;
    const localErrors = {};

    if (isEmpty(email)) {
      localErrors.email = 'Insira o email para iniciar sessão.';
    }

    if (isEmpty(password)) {
      localErrors.password = 'Insira a sua password para iniciar sessão.';
    }

    if (showPin && isEmpty(code)) {
      localErrors.code = 'Insira o código recebido no seu contacto telefónico.';
    }

    if (checkEmptyObject(localErrors).length > 0) {
      return setErrors(erros => ({ ...erros, ...localErrors }));
    }

    if (!showPin) {
      const infoData = { email, password };

      return dispatch(loginUser(infoData));
    }

    if (expired) {
      const infoData = { email, password };

      return dispatch(loginUser(infoData));
    }

    const infoData = { email, password, token: pinToken, code };

    return dispatch(loginUser2FA(infoData));
  };

  const fields = [
    {
      label: 'Email',
      name: 'email',
      margin: 'none',
      type: 'email',
    },
    {
      label: 'Palavra-passe',
      type: 'password',
      name: 'password',
      margin: 'none',
      onKeyPress: e => {
        if (e.key === 'Enter') {
          submitLogin(e);
        }
      },
    },
  ];

  if (showPin) {
    fields.push({
      label: 'Código PIN',
      type: 'number',
      name: 'code',
      margin: 'none',
      placeholder: 'Insira o código recebido no seu contacto telefónico',
      onKeyPress: e => {
        if (e.key === 'Enter') {
          submitLogin(e);
        }
      },
    });
  }

  return (
    <Grid container className={classes.fullHeightLogin}>
      <Grid item lg={6} xs={10} className={clsx([classes.marginHorizontal], [classes.flexColumn])}>
        <Typography variant="h6" className={classes.labelMargin}>
          Login
        </Typography>
        {fields.map(item => (
          <InputField
            key={item.name}
            onChange={handleChange}
            value={values[item.name]}
            error={errors[item.name]}
            {...item}
          />
        ))}
        <Box width="100%">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={submitLogin}
            className={classes.buttonLogin}
            disabled={loading}
          >
            {expired ? 'Enviar novo código' : 'Login'}
          </Button>
        </Box>
        <Box marginBottom={8} className={classes.marginTop}>
          <Box className={classes.lineExplain}>
            <Typography variant="button" color="textSecondary">
              Esqueceu a sua palavra-passe?{' '}
              <Link to="/recuperar" className={classes.cleanLink}>
                Recuperar palavra-passe
              </Link>
            </Typography>
          </Box>
        </Box>
      </Grid>
      {loading && <LoadingSpinner />}
    </Grid>
  );
};

export default FormLogin;
