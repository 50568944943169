import {
  SET_REUNION_CRONOLOGY,
  SET_ACTUAL_REUNION,
  UPDATE_REUNION,
  UPDATE_TIME,
  TOGGLE_SPEAKING,
  UPDATE_PROPOSAL_VOTES,
  UPDATE_GROUP_MEMBER_TIME,
  UPDATE_GROUP_OTHERS_TIME,
  UPDATE_OTHERS_TIME,
  UPDATE_MEMBER_TIME,
  UPDATE_THEME_POINT_STATUS,
  UPDATE_POINT_STATUS,
  UPDATE_GROUP_USERS_POINT_SPEAK,
  UPDATE_PUBLIC_TIME,
  UPDATE_PARTICIPANT_POINT_DELIBERATION,
  UPDATE_PARTICIPANT_THEME_POINT_DELIBERATION,
  UPDATE_INTERVENTIONS_TIME,
  UPDATE_PUBLIC_DISCUSSION_TIME,
  UPDATE_BEFORE_DAY_ORDER_TIME,
  UPDATE_POINT_DISCUSSION_TIME,
  UPDATE_MEETING_TIME,
} from '../actions/types';

const initialState = {
  reunions: [],
  cronology: null,
  reunion: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_REUNION_CRONOLOGY:
      return {
        ...state,
        cronology: action.payload,
      };
    case SET_ACTUAL_REUNION:
      return {
        ...state,
        reunion: {
          ...action.payload,
        },
      };
    case UPDATE_PUBLIC_DISCUSSION_TIME: {
      return {
        ...state,
        reunion: {
          ...state.reunion,
          timers: {
            ...state.reunion.timers,
            publicDiscussion: action.payload,
          },
        },
      };
    }
    case UPDATE_BEFORE_DAY_ORDER_TIME: {
      return {
        ...state,
        reunion: {
          ...state.reunion,
          timers: {
            ...state.reunion.timers,
            beforeDayOrder: action.payload,
          },
        },
      };
    }
    case UPDATE_MEETING_TIME: {
      return {
        ...state,
        reunion: {
          ...state.reunion,
          timers: {
            ...state.reunion.timers,
            meeting: action.payload,
          },
        },
      };
    }
    case UPDATE_POINT_DISCUSSION_TIME: {
      return {
        ...state,
        reunion: {
          ...state.reunion,
          timers: {
            ...state.reunion.timers,
            points: {
              ...state.reunion.timers.points,
              [action.payload.pointId]: {
                ...state.reunion.timers.points[action.payload.pointId],
                [action.payload.timerType]: action.payload.timerState,
              },
            },
          },
        },
      };
    }
    case UPDATE_REUNION: {
      const nextState = {
        ...state,
        reunion: {
          ...state.reunion,
          ...action.payload,
        },
      };

      if (JSON.stringify(nextState) === JSON.stringify(state)) {
        return state;
      }

      return nextState;
    }
    case UPDATE_TIME:
      return {
        ...state,
        reunion: {
          ...state.reunion,
          users: state.reunion.users.map((x, idx) =>
            idx !== action.payload.idx ? x : { ...x, current: action.payload.value }
          ),
        },
      };
    case UPDATE_GROUP_MEMBER_TIME:
      return {
        ...state,
        reunion: {
          ...state.reunion,
          speakers: state.reunion.speakers.map(group =>
            group.id === action.payload.groupId
              ? {
                  ...group,
                  users: group.users.map(user =>
                    user.id === action.payload.userId
                      ? { ...user, current: action.payload.value }
                      : user
                  ),
                  current: parseInt(group.current, 10) + 1,
                }
              : group
          ),
        },
      };
    case UPDATE_OTHERS_TIME:
      return {
        ...state,
        reunion: {
          ...state.reunion,
          speakers: state.reunion.speakers.map((x, idx) =>
            idx !== action.payload.idx ? x : { ...x, others: action.payload.value }
          ),
          users: state.reunion.users.map((x, idx) =>
            idx !== action.payload.idx ? x : { ...x, others: action.payload.value }
          ),
        },
      };
    case UPDATE_GROUP_OTHERS_TIME:
      return {
        ...state,
        reunion: {
          ...state.reunion,
          speakers: state.reunion.speakers.map(group =>
            group.id === action.payload.groupId
              ? {
                  ...group,
                  others: action.payload.value,
                }
              : group
          ),
          users: state.reunion.users.map(group =>
            group.id === action.payload.groupId
              ? {
                  ...group,
                  others: action.payload.value,
                }
              : group
          ),
        },
      };
    case UPDATE_GROUP_USERS_POINT_SPEAK: {
      const newSpeakers = state.reunion.speakers.map(group => ({
        ...group,
        users: group.users.map(user => {
          const userTimeEntry = action.payload.find(obj => obj.user_id === user.id) || {
            time: 0,
          };
          return { ...user, current: userTimeEntry.time };
        }),
        current: action.payload.reduce((acc, cur) => {
          if (cur.group_id === group.id) {
            const time = acc + cur.time;

            return time;
          }

          return acc;
        }, 0),
      }));
      return {
        ...state,
        reunion: {
          ...state.reunion,
          speakers: newSpeakers,
        },
      };
    }
    case UPDATE_MEMBER_TIME:
      return {
        ...state,
        reunion: {
          ...state.reunion,
          speakers: state.reunion.speakers.map(user =>
            user.id === action.payload.userId ? { ...user, current: action.payload.value } : user
          ),
        },
      };
    case UPDATE_PUBLIC_TIME:
      return {
        ...state,
        reunion: {
          ...state.reunion,
          currentPublic: action.payload,
        },
      };
    case UPDATE_INTERVENTIONS_TIME:
      return {
        ...state,
        reunion: {
          ...state.reunion,
          interventions: action.payload,
        },
      };
    case UPDATE_THEME_POINT_STATUS:
      return {
        ...state,
        reunion: {
          ...state.reunion,
          themes: state.reunion.themes.map((theme, idxT) =>
            idxT === action.payload.infoPoint.idxTheme
              ? {
                  ...theme,
                  points: theme.points.map((point, idxP) =>
                    idxP === action.payload.infoPoint.idxProposal
                      ? {
                          ...point,
                          meeting_point_status: { ...action.payload.meeting_point_status },
                        }
                      : point
                  ),
                }
              : theme
          ),
        },
      };
    case UPDATE_POINT_STATUS:
      return {
        ...state,
        reunion: {
          ...state.reunion,
          points: state.reunion.points.map((point, idxP) =>
            idxP === action.payload.infoPoint.idxProposal
              ? { ...point, meeting_point_status: { ...action.payload.meeting_point_status } }
              : point
          ),
        },
      };
    case TOGGLE_SPEAKING:
      return {
        ...state,
        reunion: {
          ...state.reunion,
          users: state.reunion.users.map((x, idx) =>
            idx !== action.payload.idx ? x : { ...x, speaking: action.payload.value }
          ),
        },
      };
    case UPDATE_PROPOSAL_VOTES:
      return {
        ...state,
        reunion: {
          ...state.reunion,
          themes: state.reunion.themes.map((x, idxT) =>
            idxT === action.payload.activeInfo.idxTheme
              ? {
                  ...x,
                  proposals: x.proposals.map((p, idxP) =>
                    idxP === action.payload.activeInfo.idxProposal
                      ? { ...p, meetingStatus: action.payload.meetingStatus }
                      : p
                  ),
                }
              : x
          ),
        },
      };
    case UPDATE_PARTICIPANT_POINT_DELIBERATION:
      return {
        ...state,
        reunion: {
          ...state.reunion,
          points: state.reunion.points.map((point, idxP) => {
            if (idxP === action.payload.activeInfo.idxProposal) {
              const { declaration = [] } = point.meeting_point_status;

              return {
                ...point,
                meeting_point_status: {
                  ...point.meeting_point_status,
                  declaration: [...declaration, action.payload.info],
                },
              };
            }

            return point;
          }),
        },
      };
    case UPDATE_PARTICIPANT_THEME_POINT_DELIBERATION:
      return {
        ...state,
        reunion: {
          ...state.reunion,
          themes: state.reunion.themes.map((theme, idxT) =>
            idxT === action.payload.activeInfo.idxTheme
              ? {
                  ...theme,
                  points: theme.points.map((point, idxP) => {
                    if (idxP === action.payload.activeInfo.idxProposal) {
                      const { declaration = [] } = point.meeting_point_status;

                      return {
                        ...point,
                        meeting_point_status: {
                          ...point.meeting_point_status,
                          declaration: [...declaration, action.payload.info],
                        },
                      };
                    }

                    return point;
                  }),
                }
              : theme
          ),
        },
      };
    default:
      return state;
  }
};
