import React, { useCallback, useMemo, useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, makeStyles, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import Subtitle1 from '../../typographys/Subtitle1';
import H5 from '../../typographys/H5';
import formatDate from '../../../../utils/formatDate';
import isEmpty from '../../../../utils/isEmpty';
import SubtitleWithEntries from './SubtitleWithEntries';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ButtonNormal from '../../buttons/ButtonNormal';
import { SOCKET } from '../../SocketWrapper';
import { deleteMeeting } from '../../../../store/actions/meetingsActions';

const useStyles = makeStyles(() => ({
  marginTitle: {
    marginBottom: '4px',
  },
  marginSubTitle: {
    marginBottom: '10px',
  },
  marginEntry: {
    marginBottom: '8px',
    overflowWrap: 'break-word',
  },
  buttonEdit: {
    width: '253px',
  },
  buttonAdd: {
    marginLeft: '10px',
    minHeight: '44px',
    maxHeight: '44px',
  },
}));

const DialogEventInfo = ({ open, handleClose, event }) => {
  const classes = useStyles();
  const history = useHistory();
  const { permissions } = useSelector(state => state.auth.user);
  const { status } = event.extendedProps || {};
  const { syncSocket } = useContext(SOCKET);
  const dispatchRedux = useDispatch();

  const editMeeting = useCallback(
    id => e => {
      e.preventDefault();

      history.push(`/reunioes/editar/${id}`);
    },
    [history]
  );

  const checkMeeting = useCallback(
    id => e => {
      e.preventDefault();

      history.push(`/reunioes/consultar/${id}`);
    },
    [history]
  );

  const deleteMeetingHandler = id => async e => {
    e.preventDefault();

    dispatchRedux(
      deleteMeeting(
        id,
        () => {
          syncSocket.emit('delete_reunion', JSON.stringify({ id }));
          handleClose();
        },
        () => {}
      )
    );
  };

  const canEdit = useMemo(
    () => status === 1 && permissions && permissions.meetings && permissions.meetings.create,
    [status, permissions]
  );

  const canDelete = useMemo(() => event?.extendedProps?.can_delete, [event]);

  return (
    !isEmpty(event) && (
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>
          <H5 className={classes.marginTitle}>{event.title ? event.title : 'Reunião'}</H5>
          <Subtitle1 component="p">
            {formatDate(event.start, "dd 'de' MMMM 'de' yyyy, HH'h'mm")}
          </Subtitle1>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {event.extendedProps && event.extendedProps.info && event.extendedProps.info.typology && (
              <Grid item xs={6}>
                <SubtitleWithEntries
                  classes={classes}
                  label="Tipologia"
                  entries={[event.extendedProps.info.typology]}
                />
              </Grid>
            )}
            {event.extendedProps && event.extendedProps.info && event.extendedProps.info.location && (
              <Grid item xs={6}>
                <SubtitleWithEntries
                  classes={classes}
                  label="Local"
                  entries={[{ name: event.extendedProps.info.location }]}
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={1} justify="center">
            {canEdit && (
              <Grid item xs={12} sm={6}>
                <ButtonNormal
                  contained
                  primary
                  onClick={editMeeting(event.id)}
                  label="Editar reunião"
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={12} sm={canEdit ? 6 : 12}>
              <ButtonNormal
                contained
                pea
                onClick={checkMeeting(event.id)}
                label="Detalhes da reunião"
                fullWidth
              />
            </Grid>
            {canDelete && (
              <Grid item xs={12}>
                <ButtonNormal
                  contained
                  error
                  onClick={deleteMeetingHandler(event.id)}
                  label=" Eliminar reunião"
                  fullWidth
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    )
  );
};

DialogEventInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
};

export default React.memo(DialogEventInfo);
