import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import ConfigList from '../../pieces/ConfigList';
import InfoSection from '../../pieces/InfoSection';
import isEmpty from '../../../../utils/isEmpty';
import { ConfigStateContext, ConfigDispatchContext } from '../../MenuConfigProvider';
import AddNewOrganicUnit from './AddNewOrganicUnit';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOrganicUnits,
  removeOrganicUnit,
  getOrganicUnitsUsers,
} from '../../../../store/actions/organicUnitsActions';
import SeeEditOrganicUnit from './SeeEditOrganicUnit';
import CreateEditOrganicUnitProvider from './CreateEditOrganicUnitProvider';
import DeleteOrganicUnit from './DeleteOrganicUnit';

const OrganicUnits = () => {
  const { organicUnits = [] } = useSelector(state => state.organicUnits);
  const { permissions } = useSelector(state => state.auth.user);
  const { activeItem, isAddNew, deleting } = useContext(ConfigStateContext);
  const [search, setSearch] = useState('');
  const dispatchConfig = useContext(ConfigDispatchContext);
  const dispatchRedux = useDispatch();

  useEffect(() => {
    if (activeItem?.id && !isAddNew) {
      dispatchRedux(getOrganicUnitsUsers(activeItem.id));
    }

    dispatchRedux(getOrganicUnits());
  }, [activeItem, dispatchRedux, isAddNew]);

  const items = useMemo(() => {
    if (isEmpty(search)) {
      return organicUnits.map(x => ({
        ...x,
        name: `${x.abbreviation || x.code} - ${x.name}`,
        originalName: x.name,
      }));
    }

    return organicUnits
      .filter(
        x =>
          x.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
          (x.code && x.code.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
          (x.abbreviation && x.abbreviation.toLowerCase().indexOf(search.toLowerCase()) !== -1)
      )
      .map(x => ({ ...x, name: `${x.abbreviation || x.code} - ${x.name}`, originalName: x.name }));
  }, [search, organicUnits]);

  const hasPagination = useMemo(() => items.length > 10, [items]);
  const pageObj = useMemo(() => ({ isItemOrganicUnit: true }), []);

  const handleDelete = useCallback(
    e => {
      e.preventDefault();
      dispatchRedux(
        removeOrganicUnit(activeItem.id, () =>
          dispatchConfig({
            type: 'CANCEL_ADD_NEW',
          })
        )
      );
    },
    [dispatchRedux, activeItem, dispatchConfig]
  );

  return (
    <>
      <ConfigList
        items={items}
        pageObj={pageObj}
        labelButton="Nova Unidade Orgânica"
        noItemMessage="Nenhuma Unidade Orgânica adicionada."
        msgDelete={`Tem a certeza que deseja excluir a Unidade Orgânica${
          activeItem && activeItem.name ? `, ${activeItem.name}` : ''
        }?`}
        labelSubmit="Excluir"
        canDelete={Boolean(permissions && permissions.others && permissions.others.organic_units)}
        canAddNew={permissions && permissions.others && permissions.others.organic_units}
        handleDelete={handleDelete}
        search={search}
        setSearch={setSearch}
      />
      <InfoSection hasPagination={hasPagination}>
        <CreateEditOrganicUnitProvider items={organicUnits}>
          {!isEmpty(activeItem) && !deleting && <SeeEditOrganicUnit info={activeItem} />}
          {isAddNew && <AddNewOrganicUnit />}
          {deleting && <DeleteOrganicUnit id={activeItem.id} />}
        </CreateEditOrganicUnitProvider>
      </InfoSection>
    </>
  );
};

export default OrganicUnits;
