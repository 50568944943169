import React, { useCallback } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import H5 from '../../common/typographys/H5';
import ButtonNormal from '../../common/buttons/ButtonNormal';
import checkEmptyObject from '../../../utils/checkEmptyObject';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  withMargin: {
    marginBottom: theme.spacing(2),
  },
}));

const AddMeetingHeader = ({ state }) => {
  const classes = useStyles();
  const theme = useTheme();
  const showHeaderMargin = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const history = useHistory();
  const { errors, sameUsers, submitting, loading, handleSubmit, newSession } = state;

  const cancelMeeting = useCallback(
    e => {
      e.preventDefault();

      return history.goBack();
    },
    [history]
  );

  return (
    <Grid container>
      <Grid item xs={12} md={6} lg={8} className={showHeaderMargin && classes.withMargin}>
        <H5>{newSession ? 'Agendar nova sessão' : 'Adicionar nova reunião'}</H5>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <ButtonNormal
              variant="outlined"
              color="primary"
              label="Cancelar"
              onClick={cancelMeeting}
              disabled={loading}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ButtonNormal
              contained
              pea
              onClick={handleSubmit}
              label="Gravar"
              disabled={
                loading || checkEmptyObject(errors).length > 0 || submitting || sameUsers.length > 0
              }
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

AddMeetingHeader.defaultProps = {
  state: {},
};

AddMeetingHeader.propTypes = {
  state: PropTypes.object,
};

export default React.memo(AddMeetingHeader);
