import React, { useContext } from 'react';
import { makeStyles, Divider } from '@material-ui/core';
import H6 from '../../../../common/typographys/H6';
import { GeneralConfigStateContext, GeneralConfigFuncsContext } from '../CreateEditGeneralConfigProvider';
import InputField from '../../../../common/forms/InputField';

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: '8px',
    marginBottom: '20px',
  },
}));

const EditingProposalStatuses = () => {
  const classes = useStyles();
  const {
    state: { statuses = [] },
  } = useContext(GeneralConfigStateContext);
  const { updateStatusesLabel } = useContext(GeneralConfigFuncsContext);

  return (
    <>
      <H6>Estados associados a propostas</H6>
      <Divider className={classes.divider} />
      {statuses.map(entry => (
        <InputField
          key={entry.id}
          name={entry.slug}
          value={entry.label}
          label={entry.explanation}
          onChange={e => updateStatusesLabel(entry.id, e.target.value)}
        />
      ))}
    </>
  );
};

export default React.memo(EditingProposalStatuses);
