import React from 'react';
import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body1 from '../typographys/Body1';
import Body2 from '../typographys/Body2';
import DialogButtonsConfirmation from './DialogButtonsConfirmation';

const useStyles = makeStyles(() => ({
  marginContent: {
    margin: '30px',
    padding: '0!important',
  },
  secondLevel: {
    marginTop: '12px',
  },
}));

const DialogConfirmAction = ({ open, handleClose, handleSubmit, msg, labelSubmit, labelCancel, secondLevel }) => {
  const classes = useStyles();

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogContent className={classes.marginContent}>
        <Body1 component="p">{msg}</Body1>
        {secondLevel && (
          <Body2 component="p" className={classes.secondLevel}>
            {secondLevel}
          </Body2>
        )}
      </DialogContent>
      <DialogButtonsConfirmation handleClose={handleClose} handleSubmit={handleSubmit} labelSubmit={labelSubmit} labelCancel={labelCancel} />
    </Dialog>
  );
};

DialogConfirmAction.defaultProps = {
  labelSubmit: 'Remover',
  labelCancel: 'Cancelar',
  secondLevel: null,
};

DialogConfirmAction.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  msg: PropTypes.string.isRequired,
  labelSubmit: PropTypes.string,
  labelCancel: PropTypes.string,
  secondLevel: PropTypes.string,
};

export default DialogConfirmAction;
