import React from 'react';
import { Toolbar, IconButton } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import ToolbarActions from './ToolbarActions';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import H5 from '../typographys/H5';

const ToolbarApp = ({ classes, handleLogout, handleDrawerToggle, name, logo, mobile }) => {
  const { text, secondLevel } = useSelector(state => state.navigation);

  return (
    <Toolbar>
      {mobile && (
        <IconButton color="inherit" aria-label="Open drawer" edge="start" onClick={handleDrawerToggle}>
          <Menu />
        </IconButton>
      )}
      <H5 white tertiary={secondLevel !== ''}>
        {text}
      </H5>
      {secondLevel && (
        <H5 white className={classes.secondLevelItem}>
          {secondLevel}
        </H5>
      )}
      <ToolbarActions classes={classes} handleLogout={handleLogout} name={name} logo={logo} />
    </Toolbar>
  );
};

ToolbarApp.defaultProps = {
  name: null,
  logo: null,
  mobile: false,
  handleDrawerToggle: null,
};

ToolbarApp.propTypes = {
  classes: PropTypes.object.isRequired,
  handleLogout: PropTypes.func.isRequired,
  handleDrawerToggle: PropTypes.func,
  name: PropTypes.string,
  logo: PropTypes.string,
  mobile: PropTypes.bool,
};

export default ToolbarApp;
