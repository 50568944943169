const validPhone = phone => {
  const regex = /(\+?351)?9[1236]\d{7}|2[1-9]\d{7}$/;
  const auxPhone = phone.replace(/[\s()[\]]/g, '');
  let validLength = 9;

  if (auxPhone.indexOf('+351') === 0) {
    validLength = 13;
  }

  if (auxPhone.indexOf('00351') === 0) {
    validLength = 14;
  }

  if (auxPhone.length !== validLength) {
    return false;
  }

  return regex.test(auxPhone);
};

export default validPhone;
