import React, { useContext } from 'react';
import { ThemeStateContext, ThemeDispatchContext } from './CreateEditThemeProvider';
import FormInfoTheme from './FormInfoTheme';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import UpdateItem from '../../pieces/UpdateItem';

const EditTheme = () => {
  const {
    state: { errors, items },
    handleSubmit,
    loading,
  } = useContext(ThemeStateContext);
  const dispatch = useContext(ThemeDispatchContext);

  return (
    <UpdateItem disableSubmit={checkEmptyObject(errors).length > 0} loading={loading} items={items} dispatch={dispatch} handleSubmit={handleSubmit}>
      <FormInfoTheme />
    </UpdateItem>
  );
};

export default EditTheme;
