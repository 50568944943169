import React, { useEffect, useContext, useCallback } from 'react';
import SeeItem from '../../pieces/SeeItem';
import { useSelector, useDispatch } from 'react-redux';
import { getGroupParticipants } from '../../../../store/actions/groupsActions';
import PropTypes from 'prop-types';
import LabelAndValue from '../../../common/forms/LabelAndValue';
import { GroupDispatchContext } from './CreateEditGroupProvider';
import EditGroup from './EditGroup';
import GroupParticipant from './pieces/GroupParticipant';
import { ConfigStateContext, ConfigDispatchContext } from '../../MenuConfigProvider';

const SeeEditGroup = ({ info }) => {
  const { participants = [] } = useSelector(state => state.groups.group);
  const dispatchRedux = useDispatch();
  const dispatchConfig = useContext(ConfigDispatchContext);
  const dispatch = useContext(GroupDispatchContext);
  const { editing } = useContext(ConfigStateContext);

  useEffect(() => {
    if (info.isItemGroup) {
      dispatchRedux(getGroupParticipants(info.id));
    }
    return () => {
      dispatchRedux({
        type: 'GET_GROUP_PARTICIPANTS',
        payload: [],
      });
    };
  }, [dispatchRedux, info]);

  const updateField = useCallback(
    (name, value) => {
      dispatch({
        type: 'UPDATE_FIELD',
        payload: { name, value },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    updateField('participants', participants);
    updateField(
      'idsParticipants',
      participants.map(x => x.id)
    );
  }, [participants, updateField]);

  useEffect(() => {
    updateField('name', info.name);
  }, [info.name, updateField]);

  useEffect(() => {
    updateField('id', info.id);
  }, [info.id, updateField]);

  useEffect(() => {
    updateField('showFirst', info.show_first);
  }, [info.show_first, updateField]);

  const startEdit = useCallback(
    e => {
      e.preventDefault();
      dispatchConfig({
        type: 'SET_EDITING',
      });
    },
    [dispatchConfig]
  );

  // @ Se o grupo tiver reuniões associadas, não pode ser eliminado
  return editing ? (
    <EditGroup />
  ) : (
    <SeeItem submitEdit={startEdit}>
      <LabelAndValue label="Nome" value={info.name} />
      <LabelAndValue label="Número de participantes" value={participants.length} />
      {participants.map((user, idx) => (
        <GroupParticipant key={user.id} idx={idx} name={user.name} />
      ))}
    </SeeItem>
  );
};

SeeEditGroup.propTypes = {
  info: PropTypes.object.isRequired,
};

export default React.memo(SeeEditGroup);
