import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import ConfigList from '../../pieces/ConfigList';
import InfoSection from '../../pieces/InfoSection';
import isEmpty from '../../../../utils/isEmpty';
import { ConfigStateContext, ConfigDispatchContext } from '../../MenuConfigProvider';
import { useDispatch, useSelector } from 'react-redux';
import { removeUser, getUsers } from '../../../../store/actions/usersActions';
import SeeEditUser from './SeeEditUser';
import CreateEditUserProvider from './CreateEditUserProvider';
import AddNewUser from './AddNewUser';
import CreateNewUser from './CreateNewUser';
import { getProfiles } from '../../../../store/actions/profilesActions';
import { getOrganicUnits } from '../../../../store/actions/organicUnitsActions';

const Users = () => {
  const { users } = useSelector(state => state.users);
  const { activeItem, isAddNew, deleting, alternate } = useContext(ConfigStateContext);
  const { permissions } = useSelector(state => state.auth.user);
  const [search, setSearch] = useState('');
  const dispatchConfig = useContext(ConfigDispatchContext);
  const dispatchRedux = useDispatch();

  useEffect(() => {
    dispatchRedux(getUsers());
    dispatchRedux(getProfiles());
    dispatchRedux(getOrganicUnits());
  }, [dispatchRedux]);

  const items = useMemo(() => {
    if (isEmpty(search)) {
      return users;
    }

    return users.filter(
      x =>
        x.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        x.email.toLowerCase().indexOf(search.toLowerCase()) !== -1
    );
  }, [search, users]);

  const hasPagination = useMemo(() => items.length > 10, [items]);
  const pageObj = useMemo(() => ({ isItemUser: true }), []);

  const handleDelete = useCallback(
    e => {
      e.preventDefault();
      dispatchRedux(
        removeUser(activeItem.id, () =>
          dispatchConfig({
            type: 'CANCEL_ADD_NEW',
          })
        )
      );
    },
    [dispatchRedux, activeItem, dispatchConfig]
  );

  const canInviteCreateUser = permissions && permissions.users && permissions.users.invite;

  return (
    <>
      <ConfigList
        items={items}
        pageObj={pageObj}
        labelButton="Convidar novo utilizador"
        noItemMessage="Nenhum utilizador adicionado."
        msgDelete={`Tem a certeza que deseja excluir o utilizador${
          activeItem && activeItem.name ? `, ${activeItem.name}` : ''
        }?`}
        labelSubmit="Excluir"
        canDelete={Boolean(permissions && permissions.users && permissions.users.edit_status)}
        canAddNew={canInviteCreateUser}
        handleDelete={handleDelete}
        search={search}
        setSearch={setSearch}
        canCreateNew={canInviteCreateUser}
        labelCreateButton="Criar novo utilizador"
      />
      <InfoSection hasPagination={hasPagination}>
        <CreateEditUserProvider items={users}>
          {!isEmpty(activeItem) && !deleting && <SeeEditUser info={activeItem} />}
          {isAddNew && !alternate && <AddNewUser />}
          {isAddNew && alternate && <CreateNewUser />}
        </CreateEditUserProvider>
      </InfoSection>
    </>
  );
};

export default Users;
