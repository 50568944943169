import React from 'react';
import Body2 from '../typographys/Body2';
import { Card, CardContent } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  marginCard: {
    marginBottom: '30px',
    width: '100%',
    minHeight: '58px',
  },
  smallMargin: {
    marginBottom: '20px',
    width: '100%',
  },
  marginSubHeader: {
    marginBottom: '8px',
  },
  singlePadding: {
    padding: '20px!important',
  },
  smallPadding: {
    padding: '8px!important',
  },
  flex: {
    display: 'flex',
  },
}));

const CardBlock = ({ children, label, singlePadding, smallMargin, smallPadding, flex }) => {
  const classes = useStyles();

  return (
    <>
      <Body2 secondary className={classes.marginSubHeader}>
        {label}
      </Body2>
      <Card
        className={clsx({ [classes.marginCard]: !smallMargin, [classes.smallMargin]: smallMargin })}
      >
        <CardContent
          className={clsx({
            [classes.singlePadding]: singlePadding,
            [classes.smallPadding]: smallPadding,
            [classes.flex]: flex,
          })}
        >
          {children}
        </CardContent>
      </Card>
    </>
  );
};

CardBlock.defaultProps = {
  children: null,
  singlePadding: false,
  smallMargin: false,
  smallPadding: false,
  flex: false,
};

CardBlock.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  label: PropTypes.string.isRequired,
  singlePadding: PropTypes.bool,
  smallMargin: PropTypes.bool,
  smallPadding: PropTypes.bool,
  flex: PropTypes.bool,
};

export default CardBlock;
