import React from 'react';
import { makeStyles, Grid, Divider, Box, Input } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body2 from '../../../common/typographys/Body2';
import Body1 from '../../../common/typographys/Body1';
import formatDate from '../../../../utils/formatDate';
import formatMoney from '../../../../utils/formatMoney';
import Pagination from '../../../common/SimplePagination';
import usePagination from '../../../../utils/hooks/usePagination';

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: '6px',
    marginBottom: '8px',
  },
  link: {
    textDecoration: 'none!important',
  },
  underline: {
    textDecoration: 'underline!important',
  },
  search: {
    marginBottom: '20px',
  },
}));

const ProposalEntry = ({ proposal }) => {
  const classes = useStyles();
  const { id, name, identifier_code, approved, amount, date } = proposal;

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <a
          href={`/propostas/visualizar/${id}`}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          <Body2 hoverable oneLine className={classes.underline}>
            {identifier_code} - {name}
          </Body2>
        </a>
      </Grid>
      <Grid item xs={2}>
        <Body2>{formatMoney(amount)}</Body2>
      </Grid>
      <Grid item xs={2}>
        <Body2>{formatDate(date)}</Body2>
      </Grid>
      <Grid item xs={2}>
        <Body2>{approved ? 'Sim' : 'Não'}</Body2>
      </Grid>
    </Grid>
  );
};

ProposalEntry.propTypes = {
  proposal: PropTypes.object.isRequired,
};

const EntityProposals = ({ proposals, search, setSearch }) => {
  const classes = useStyles();
  const [showPagination, itemShown, page, setPage] = usePagination(10, proposals);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Body2 secondary>Proposta</Body2>
        </Grid>
        <Grid item xs={2}>
          <Body2 secondary>Valor</Body2>
        </Grid>
        <Grid item xs={2}>
          <Body2 secondary>Data</Body2>
        </Grid>
        <Grid item xs={2}>
          <Body2 secondary>Aprovada</Body2>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <Input
            name="search"
            value={search}
            onChange={e => {
              setSearch(e.target.value);
              setPage(0);
            }}
            placeholder="Filtrar..."
            className={classes.search}
          />
        </Grid>
      </Grid>
      {itemShown.length > 0 ? (
        itemShown.map((proposal, idx) => (
          <React.Fragment key={proposal.id}>
            <ProposalEntry proposal={proposal} />
            {idx !== itemShown.length - 1 && <Divider className={classes.divider} />}
          </React.Fragment>
        ))
      ) : (
        <Body1>Nenhuma proposta satisfaz o filtro aplicado</Body1>
      )}
      {showPagination && (
        <>
          <Box height="10px" />
          <Pagination
            total={proposals.length}
            onChangePage={(_, pageNum) => {
              setPage(pageNum);
            }}
            rowsPerPage={10}
            page={page}
          />
        </>
      )}
    </>
  );
};

EntityProposals.propTypes = {
  proposals: PropTypes.array.isRequired,
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
};

export default React.memo(EntityProposals);
