import palette from '../palette';

export default {
  thumb: {
    backgroundColor: palette.neutrals[5],
  },
  track: {
    opacity: '0.2',
  },
  switchBase: {
    '&.Mui-checked > .MuiIconButton-label > .MuiSwitch-thumb': {
      backgroundColor: palette.primary[500],
    },
    '&.Mui-disabled.Mui-checked > .MuiIconButton-label > .MuiSwitch-thumb': {
      backgroundColor: palette.primary[300],
    },
  },
};
