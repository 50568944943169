import React from 'react';
import { makeStyles, Box, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  buttonConfirm: {
    color: theme.palette.neutrals[1],
    backgroundColor: theme.palette.primary.A400,
    height: '44px',
    '&:hover': {
      color: theme.palette.neutrals[1],
      backgroundColor: theme.palette.primary.A700,
    },
  },
}));

const BoxUpdatePointStatus = ({ handlePointStatus, disabled }) => {
  const classes = useStyles();

  return (
    <>
      <Box height="30px" />
      <Box>
        <Button
          variant="contained"
          fullWidth
          className={classes.buttonConfirm}
          onClick={handlePointStatus(4)}
          disabled={disabled}
        >
          Adiar proposta
        </Button>
        <Box height="8px" />
        <Button
          variant="contained"
          fullWidth
          className={classes.buttonConfirm}
          onClick={handlePointStatus(3)}
          disabled={disabled}
        >
          Retirar proposta
        </Button>
      </Box>
    </>
  );
};

BoxUpdatePointStatus.defaultProps = {
  disabled: false,
};

BoxUpdatePointStatus.propTypes = {
  handlePointStatus: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default React.memo(BoxUpdatePointStatus);
