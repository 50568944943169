import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import {
  AssignmentLateRounded,
  EuroSymbolRounded,
  AlarmRounded,
  WarningRounded,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import isEmpty from '../../../utils/isEmpty';
import clsx from 'clsx';
import Subtitle1 from '../typographys/Subtitle1';
import { pt } from 'date-fns/locale';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import getDateObj from '../../../utils/getDateObj';

const getIcon = type => {
  // @ TODO: validar types
  switch (type) {
    case 1:
      return <AssignmentLateRounded />;
    case 2:
      return <EuroSymbolRounded />;
    case 3:
      return <AlarmRounded />;
    case 4:
      return <WarningRounded />;
    default:
      return <AssignmentLateRounded />;
  }
};

const createMarkup = value => {
  return { __html: value || 'Nenhum conteúdo adicionado' };
};

const NotificationEntry = ({
  classes,
  id,
  text,
  url,
  type,
  status,
  onClick,
  showDate,
  date,
  margin,
}) => {
  return (
    <ListItem
      onClick={onClick(url, id)}
      className={clsx({
        [classes.marginItem]: margin,
        [classes.entryItem]: !margin,
        [classes.hoverLink]: !isEmpty(url),
        [classes.clickedNotification]: status === 'clicked',
      })}
    >
      {type && <ListItemIcon>{getIcon(type)}</ListItemIcon>}
      <ListItemText>
        <Subtitle1 dangerouslySetInnerHTML={createMarkup(text)} />
        {showDate && (
          <Subtitle1 component="p" className={classes.notiDate}>
            {formatDistanceToNow(getDateObj(date), { locale: pt })}
          </Subtitle1>
        )}
      </ListItemText>
    </ListItem>
  );
};

NotificationEntry.defaultProps = {
  url: null,
  type: null,
  status: null,
  id: null,
  date: null,
  showDate: false,
  margin: false,
};

NotificationEntry.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  status: PropTypes.string,
  id: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
  date: PropTypes.string,
  showDate: PropTypes.bool,
  margin: PropTypes.bool,
};

export default React.memo(NotificationEntry);
