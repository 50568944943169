import React from 'react';
import PropTypes from 'prop-types';
import Body1 from '../../typographys/Body1';

const SpecialMultiValue = props => {
  const indexInValue = props.selectProps.value.findIndex(x => x === props.data);
  const valueLength = props.selectProps.value.length;

  if (indexInValue < 3) {
    return (
      <Body1>
        {indexInValue !== 0 ? ',' : null} {props.data.name}
      </Body1>
    );
  }

  if (indexInValue === valueLength - 1) {
    return (
      <Body1 secondary style={{ paddingLeft: '5px' }}>
        (+ {valueLength - 3} loca{`${valueLength - 3 > 1 ? 'is' : 'l'}`})
      </Body1>
    );
  }

  return null;
};

SpecialMultiValue.defaultProps = {
  data: null,
};

SpecialMultiValue.propTypes = {
  data: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

export default SpecialMultiValue;
