import React, { useEffect, useReducer, useCallback, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updateProposalStatuses } from '../../../../store/actions/proposalsActions';
import { ConfigDispatchContext } from '../../MenuConfigProvider';
import { updateEmailTemplates } from '../../../../store/actions/emailsActions';
import { updateGeneralConfigs } from '../../../../store/actions/configActions';

export const GeneralConfigDispatchContext = React.createContext();
export const GeneralConfigFuncsContext = React.createContext();
export const GeneralConfigStateContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'UPDATE_GENERAL_FIELD':
      return {
        ...state,
        general: {
          ...state.general,
          [action.payload.name]: action.payload.value,
        },
      };
    case 'ADD_URL_FIELDS': {
      const links = state.general.links || {};

      return {
        ...state,
        general: {
          ...state.general,
          links: {
            ...links,
            [Date.now()]: {
              url: null,
              label: null,
            },
          },
        },
      };
    }
    case 'REMOVE_URL_FIELD': {
      const links = state.general.links || {};
      const fieldToRemove = action.payload.id;
      delete links[fieldToRemove];

      return {
        ...state,
        general: {
          ...state.general,
          links: {
            ...links,
          },
        },
      };
    }
    case 'UPDATE_URL_FIELD': {
      const fieldToUpdate = action.payload.id;

      return {
        ...state,
        general: {
          ...state.general,
          links: {
            ...state.general.links,
            [fieldToUpdate]: {
              url: action.payload.url,
              label: action.payload.label,
            },
          },
        },
      };
    }
    case 'UPDATE_STATUS_LABEL':
      return {
        ...state,
        statuses: state.statuses.map(x =>
          x.id === action.payload.id ? { ...x, label: action.payload.value } : x
        ),
      };
    case 'UPDATE_EMAIL_SUBJECT':
      return {
        ...state,
        templates: state.templates.map((x, idx) =>
          idx === action.payload.idxTemplate
            ? {
                ...x,
                emails: x.emails.map(email =>
                  email.id === action.payload.idEmail
                    ? { ...email, subject: action.payload.value }
                    : email
                ),
              }
            : x
        ),
      };
    case 'UPDATE_EMAIL_CONTENT':
      return {
        ...state,
        templates: state.templates.map((x, idx) =>
          idx === action.payload.idxTemplate
            ? {
                ...x,
                emails: x.emails.map(email =>
                  email.id === action.payload.idEmail
                    ? { ...email, content: action.payload.value }
                    : email
                ),
              }
            : x
        ),
      };
    case 'UPDATE_EMAIL_HTML':
      return {
        ...state,
        templates: state.templates.map((x, idx) =>
          idx === action.payload.idxTemplate
            ? {
                ...x,
                template: action.payload.value,
              }
            : x
        ),
      };
    case 'UPDATE_BUTTON_HTML':
      return {
        ...state,
        templates: state.templates.map((x, idx) =>
          idx === action.payload.idxTemplate
            ? {
                ...x,
                button: action.payload.value,
              }
            : x
        ),
      };
    case 'SET_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    case 'CLEAR_STATE':
      return {
        ...state,
        type: '',
        statuses: [],
        templates: [],
        general: {},
        president: {},
        errors: {},
      };
    default:
      return state;
  }
};

const CreateEditGeneralConfigProvider = ({ children, items }) => {
  const dispatchRedux = useDispatch();
  const dispatchConfig = useContext(ConfigDispatchContext);
  const [state, dispatch] = useReducer(reducer, {
    items: [],
    type: '',
    statuses: [],
    templates: [],
    general: {},
    president: {},
    errors: {},
  });

  const { type, statuses, templates, general = {}, president = {} } = state;
  const { name, phone, email, description, links } = general;

  const updateField = useCallback((nameInput, value) => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: { name: nameInput, value },
    });
  }, []);

  const updateStatusesLabel = useCallback((id, value) => {
    dispatch({
      type: 'UPDATE_STATUS_LABEL',
      payload: { id, value },
    });
  }, []);

  const updateEmailSubject = useCallback((idxTemplate, idEmail, value) => {
    dispatch({
      type: 'UPDATE_EMAIL_SUBJECT',
      payload: { idxTemplate, idEmail, value },
    });
  }, []);

  const updateEmailContent = useCallback((idxTemplate, idEmail, value) => {
    dispatch({
      type: 'UPDATE_EMAIL_CONTENT',
      payload: { idxTemplate, idEmail, value },
    });
  }, []);

  const updateTemplateHtml = useCallback((idxTemplate, value) => {
    dispatch({
      type: 'UPDATE_EMAIL_HTML',
      payload: { idxTemplate, value },
    });
  }, []);

  const updateButtonTemplateHtml = useCallback((idxTemplate, value) => {
    dispatch({
      type: 'UPDATE_BUTTON_HTML',
      payload: { idxTemplate, value },
    });
  }, []);

  useEffect(() => {
    updateField('items', items);
  }, [items, updateField]);

  const showSnackError = useCallback(
    message => {
      dispatchRedux({
        type: 'SHOW_SNACK',
        payload: { variant: 'error', message },
      });
    },
    [dispatchRedux]
  );

  const showSnackSuccess = useCallback(
    message => {
      dispatchRedux({
        type: 'SHOW_SNACK',
        payload: { variant: 'success', message },
      });
    },
    [dispatchRedux]
  );

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      if (type === 'proposals') {
        // @ Atualizar proposal_statuses
        const info = {
          statuses: statuses.map(({ id, label }) => ({ id, label })),
        };

        try {
          const res = await new Promise((resolve, reject) => {
            dispatchRedux(updateProposalStatuses(info, resolve, reject));
          });
          showSnackSuccess('Estados das propostas atualizados com sucesso.');
          dispatchConfig({
            type: 'SET_ACTIVE_ITEM',
            payload: { type, content: { statuses: res.data } },
          });
          dispatchConfig({ type: 'CANCEL_EDITING' });
        } catch (err) {
          return showSnackError('Ocorreu um erro ao guardar os estados das propostas.');
        }
      }

      if (type === 'templates') {
        const info = {
          templates: templates.map(({ emails, template, geral, typology_id, button }) => ({
            emails,
            template,
            button,
            geral,
            typology_id,
          })),
        };

        try {
          await new Promise((resolve, reject) => {
            dispatchRedux(updateEmailTemplates(info, resolve, reject));
          });
          showSnackSuccess('Templates de emails atualizados com sucesso.');
          dispatchConfig({ type: 'CANCEL_EDITING' });
        } catch (err) {
          return showSnackError('Ocorreu um erro ao guardar os templates de emails.');
        }
      }

      if (type === 'general') {
        const info = {
          type: 'autarchy_info',
          configs: [
            { key: 'name', value: name },
            { key: 'phone', value: phone },
            { key: 'email', value: email },
            { key: 'description', value: description },
            { key: 'links', value: links || {} },
          ],
        };

        try {
          await new Promise((resolve, reject) => {
            dispatchRedux(updateGeneralConfigs(info, resolve, reject));
          });
          showSnackSuccess('Informações gerais atualizadas com sucesso.');
          dispatchConfig({ type: 'CANCEL_EDITING' });
        } catch (err) {
          return showSnackError('Ocorreu um erro ao guardar as informações gerais.');
        }
      }

      if (type === 'president') {
        const info = {
          type: 'autarchy_info',
          configs: [{ key: 'president', value: president.id }],
        };

        try {
          await new Promise((resolve, reject) => {
            dispatchRedux(updateGeneralConfigs(info, resolve, reject));
          });
          showSnackSuccess('Presidente atualizado com sucesso.');
          dispatchConfig({ type: 'CANCEL_EDITING' });
        } catch (err) {
          return showSnackError('Ocorreu um erro ao alterar o Presidente.');
        }
      }

      return null;
    },
    [
      type,
      statuses,
      showSnackSuccess,
      showSnackError,
      dispatchConfig,
      dispatchRedux,
      templates,
      name,
      email,
      description,
      phone,
      links,
      president,
    ]
  );

  const contextState = useMemo(() => ({ state, handleSubmit }), [state, handleSubmit]);

  return (
    <GeneralConfigDispatchContext.Provider value={dispatch}>
      <GeneralConfigFuncsContext.Provider
        value={{
          updateField,
          updateStatusesLabel,
          updateEmailSubject,
          updateEmailContent,
          updateTemplateHtml,
          updateButtonTemplateHtml,
        }}
      >
        <GeneralConfigStateContext.Provider value={contextState}>
          {children}
        </GeneralConfigStateContext.Provider>
      </GeneralConfigFuncsContext.Provider>
    </GeneralConfigDispatchContext.Provider>
  );
};

CreateEditGeneralConfigProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.bool]).isRequired,
  items: PropTypes.array.isRequired,
};

export default CreateEditGeneralConfigProvider;
