import React, { useState, useCallback, useContext } from 'react';
import { makeStyles, Divider } from '@material-ui/core';
import H6 from '../../../../common/typographys/H6';
import EmailTemplatePanel from './pieces/EmailTemplatePanel';
import Subtitle1 from '../../../../common/typographys/Subtitle1';
import { GeneralConfigStateContext } from '../CreateEditGeneralConfigProvider';

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: '8px',
    marginBottom: '20px',
  },
}));

const EditEmailsTemplates = () => {
  const classes = useStyles();
  const {
    state: { templates = [] },
  } = useContext(GeneralConfigStateContext);
  const [expanded, setExpanded] = useState(false);
  const [subExpanded, setSubExpanded] = useState('');

  const handleChange = useCallback(
    panel => (_, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
      setSubExpanded('');
    },
    []
  );

  const handleSubChange = useCallback(
    panel => (_, isExpanded) => {
      setSubExpanded(isExpanded ? panel : '');
    },
    []
  );

  return (
    <>
      <H6>Templates de emails</H6>
      <Subtitle1>
        Os valores {'{{entre chavetas}}'} são substituídos pelo valor correspondente. Não altere o conteúdo {'{{entre chavetas}}'}.
      </Subtitle1>
      <Divider className={classes.divider} />
      {templates.length > 0 &&
        templates.map((template, idx) => (
          <EmailTemplatePanel
            key={template.name}
            expanded={expanded === `panel-${idx}`}
            subExpanded={subExpanded}
            idx={idx}
            handleChange={handleChange}
            handleSubChange={handleSubChange}
            template={template}
            editing
          />
        ))}
    </>
  );
};

export default React.memo(EditEmailsTemplates);
