import React, { useContext, useEffect } from 'react';
import AddNewItem from '../../pieces/AddNewItem';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import { EntityDispatchContext, EntityStateContext } from './CreateEditEntityProvider';
import FormInfoEntity from './FormInfoEntity';

const AddNewEntity = () => {
  const {
    state: { errors, items },
    handleSubmit,
    loading,
  } = useContext(EntityStateContext);
  const dispatch = useContext(EntityDispatchContext);

  useEffect(() => {
    dispatch({
      type: 'CLEAR_STATE',
    });
  }, [dispatch]);

  return (
    <AddNewItem
      disableSubmit={checkEmptyObject(errors).length > 0}
      items={items}
      loading={loading}
      dispatch={dispatch}
      handleSubmit={handleSubmit}
      msgSubmit="Tem a certeza que deseja gravar a nova entidade?"
    >
      <FormInfoEntity />
    </AddNewItem>
  );
};

export default React.memo(AddNewEntity);
