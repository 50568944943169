import {
  GET_ORGANIC_UNIT,
  GET_ORGANIC_UNITS,
  UPDATE_ORGANIC_UNIT,
  GET_ORGANIC_UNITS_USERS,
  GROUPS_LOADING,
  GROUPS_NOT_LOADING,
  CREATE_ORGANIC_UNIT,
  REMOVE_ORGANIC_UNIT,
  GET_ORGANIC_UNIT_PARENTS,
  GET_ORGANIC_UNITS_CHILDREN,
} from '../actions/types';

const initialState = {
  organicUnits: [],
  users: [],
  parents: {},
  organicUnit: {},
  children: [],
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ORGANIC_UNIT:
      return {
        ...state,
        organicUnit: action.payload,
      };
    case GET_ORGANIC_UNITS:
      return {
        ...state,
        organicUnits: action.payload,
        loading: false,
      };
    case GET_ORGANIC_UNITS_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case GET_ORGANIC_UNITS_CHILDREN:
      return {
        ...state,
        children: action.payload,
        loading: false,
      };
    case GET_ORGANIC_UNIT_PARENTS:
      return {
        ...state,
        parents: action.payload,
        loading: false,
      };
    case CREATE_ORGANIC_UNIT:
      return {
        ...state,
        organicUnits: [action.payload, ...state.organicUnits],
        loading: false,
      };
    case UPDATE_ORGANIC_UNIT:
      return {
        ...state,
        organicUnits: state.organicUnits.map(x =>
          x.id === action.payload.id ? action.payload : x
        ),
        loading: false,
      };
    case REMOVE_ORGANIC_UNIT:
      return {
        ...state,
        organicUnits: state.organicUnits.filter(x => x.id !== action.payload.id),
        loading: false,
      };
    case GROUPS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GROUPS_NOT_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
