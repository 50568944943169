import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Subtitle2 from '../../common/typographys/Subtitle2';
import TimeBox from '../../configs/pieces/TimeBox';
import ListPointsCheckbox from './ListPointsCheckbox';
import { MeetingDispatchContext } from '../CreateEditMeetingsProvider';

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: '6px',
  },
}));

const DefaultPoints = ({ points }) => {
  const classes = useStyles();
  const dispatch = useContext(MeetingDispatchContext);

  const togglePoint = (selected, isNew, id) => e => {
    e.preventDefault();
    dispatch({
      type: isNew ? 'REMOVE_POINT' : 'TOGGLE_POINT',
      payload: { value: !selected, id },
    });
  };

  return (
    <>
      <Subtitle2 className={classes.label}>Pontos de discussão</Subtitle2>
      <TimeBox>
        <Subtitle2>
          {points.map((point, index) => (
            <ListPointsCheckbox
              key={point.id || point.name}
              label={
                point.identifier_code ? `${point.identifier_code} - ${point.name}` : point.name
              }
              onClick={togglePoint(point.selected, point.isNew, point.id)}
              checked={point.selected}
              divider={index < points.length - 1}
            />
          ))}
        </Subtitle2>
      </TimeBox>
    </>
  );
};

DefaultPoints.propTypes = {
  points: PropTypes.array.isRequired,
};

export default React.memo(DefaultPoints);
