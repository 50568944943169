import isEmpty from '../isEmpty';

/**
 *
 * @param {object} permissions - Permissions object
 * @param {string} prop - Property to validate
 * @returns {boolean} True or false depending if user has permission in meetings group
 */
const hasMeetingsProp = (permissions, prop) => {
  if (!isEmpty(permissions) && permissions.meetings && permissions.meetings[prop]) {
    return true;
  }

  return false;
};

export default hasMeetingsProp;
