import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const NoOptionsMessage = props => {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
      {props.children}
    </Typography>
  );
};

NoOptionsMessage.defaultProps = {
  children: null,
  innerProps: null,
};

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

export default NoOptionsMessage;
