import React, { useEffect, useReducer, useState, useContext, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from '../../../../utils/isEmpty';
import { clearErrors } from '../../../../store/actions/authActions';
import { updateMatrix, createMatrix } from '../../../../store/actions/matricesActions';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import { addSeconds, subSeconds, setSeconds, setMinutes, setHours } from 'date-fns';
import formatDate from '../../../../utils/formatDate';
import { ConfigStateContext } from '../../MenuConfigProvider';

export const MatrixDispatchContext = React.createContext();
export const MatrixStateContext = React.createContext();
export const MatrixFuncsContext = React.createContext();

const getTotalSecs = (timeMatrix = '') => {
  if (typeof timeMatrix !== 'string') {
    return timeMatrix;
  }

  const fractions = timeMatrix.split(':');
  const hours = parseInt(fractions[0] || 0, 10);
  const minutes = parseInt(fractions[1] || 0, 10);
  const seconds = parseInt(fractions[2] || 0, 10);

  return parseInt(hours * 3600 + minutes * 60 + seconds, 10);
};

const getTimeValue = sec => {
  if (sec < 0) {
    return formatDate(
      subSeconds(setHours(setMinutes(setSeconds(new Date(), 0), 0), 0), sec),
      'HH:mm:ss'
    );
  }

  return formatDate(
    addSeconds(setHours(setMinutes(setSeconds(new Date(), 0), 0), 0), sec),
    'HH:mm:ss'
  );
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'SET_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    case 'UPDATE_INDIVIDUAL_TIME':
      return {
        ...state,
        members: {
          ...state.members,
          individuals: state.members.individuals.map((x, idx) =>
            idx === action.payload.idx ? { ...x, time: action.payload.value } : x
          ),
        },
      };
    case 'UPDATE_GROUP_TIME':
      return {
        ...state,
        members: {
          ...state.members,
          groups: state.members.groups.map((x, idx) =>
            idx === action.payload.idx ? { ...x, time: action.payload.value } : x
          ),
        },
      };
    case 'ADD_INDIVIDUAL':
      return {
        ...state,
        members: {
          ...state.members,
          individuals: [...state.members.individuals, action.payload],
        },
      };
    case 'ADD_GROUP':
      return {
        ...state,
        members: {
          ...state.members,
          groups: [...state.members.groups, action.payload],
        },
      };
    case 'SET_MEMBERS':
      return {
        ...state,
        members: {
          ...state.members,
          [action.payload.type]: action.payload.value,
        },
      };
    case 'UPDATE_INDIVIDUAL':
      return {
        ...state,
        members: {
          ...state.members,
          individuals: state.members.individuals.map((x, idx) =>
            idx === action.payload.idx ? action.payload.value : x
          ),
        },
      };
    case 'UPDATE_GROUP':
      return {
        ...state,
        members: {
          ...state.members,
          groups: state.members.groups.map((x, idx) =>
            idx === action.payload.idx ? action.payload.value : x
          ),
        },
      };
    case 'CLEAR_STATE':
      return {
        ...state,
        name: '',
        time: '00:00:00',
        members: {
          individuals: [],
          groups: [],
        },
        errors: {},
        type: null,
        id: '',
      };
    default:
      return state;
  }
};

const CreateEditMatrixProvider = ({ children, items }) => {
  const { loading } = useSelector(state => state.matrices);
  const errorsReducer = useSelector(state => state.errors);
  const dispatchRedux = useDispatch();
  const { editing } = useContext(ConfigStateContext);

  const [state, dispatch] = useReducer(reducer, {
    items: [],
    name: '',
    time: '00:00:00',
    members: {
      individuals: [],
      groups: [],
    },
    errors: {},
    type: null,
    id: '',
  });
  const [sameName, setSameName] = useState('');

  const {
    name,
    id,
    errors,
    type,
    members: { individuals, groups },
    time,
  } = state;

  // @ Efeitos
  useEffect(() => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: 'items',
        value: items,
      },
    });
  }, [items]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: 'type',
        value: type,
      },
    });
  }, [type]);

  useEffect(() => {
    if (errorsReducer.name) {
      dispatch({
        type: 'SET_ERROR',
        payload: { name: 'Já existe uma matriz com este nome.' },
      });
      dispatchRedux(clearErrors());
      setSameName(name);
    }
  }, [errorsReducer, name, dispatchRedux]);

  useEffect(() => {
    if (!isEmpty(name) && !isEmpty(errors.name) && name !== sameName) {
      dispatch({
        type: 'SET_ERROR',
        payload: { name: '' },
      });
    }
  }, [name, errors.name, sameName]);

  useEffect(() => {
    if (!isEmpty(type) && !isEmpty(errors.type)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { type: '' },
      });
    }
  }, [type, errors.type]);

  useEffect(() => {
    const totalTime = getTotalSecs(time);
    let total = 0;

    if (type === 'individual') {
      const totalIndividuals = individuals.reduce((acc, cur) => {
        const totalTimeInd = getTotalSecs(cur.time);

        return acc + totalTimeInd;
      }, 0);
      total = totalTime - totalIndividuals;
    } else {
      const totalGroups = groups.reduce((acc, cur) => {
        const totalTimeGroup = getTotalSecs(cur.time);
        return acc + totalTimeGroup;
      }, 0);
      total = totalTime - totalGroups;
    }

    if (total > 0 && !isEmpty(errors.total)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { total: '' },
      });
    }
  }, [type, errors.total, groups, individuals, time]);

  useEffect(() => {
    if (type === 'individual' && individuals.length > 0 && !isEmpty(errors.members)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { members: '' },
      });
    }
  }, [type, individuals, errors.members]);

  useEffect(() => {
    if (type === 'grupo' && groups.length > 0 && !isEmpty(errors.members)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { members: '' },
      });
    }
  }, [type, groups, errors.members]);
  // @ Fim dos efeitos

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      const localErrors = {};

      if (isEmpty(name)) {
        localErrors.name = 'Insira um nome válido para a matriz.';
      }

      if (isEmpty(type)) {
        localErrors.type = 'Escolha a natureza desta matriz.';
      }

      const totalTime = getTotalSecs(time);
      let total = 0;

      if (type === 'individual') {
        if (individuals.length === 0) {
          localErrors.members = 'Escolha pelo menos 1 membro para atribuir a matriz.';
        }
        const totalIndividuals = individuals.reduce((acc, cur) => {
          const totalTimeInd = getTotalSecs(cur.time);

          return acc + totalTimeInd;
        }, 0);
        total = totalTime - totalIndividuals;
      } else {
        if (groups.length === 0) {
          localErrors.members = 'Escolha pelo menos 1 membro para atribuir a matriz.';
        }
        const totalGroups = groups.reduce((acc, cur) => {
          const totalTimeGroup = getTotalSecs(cur.time);
          return acc + totalTimeGroup;
        }, 0);
        total = totalTime - totalGroups;
      }

      if (total < 0) {
        localErrors.total = 'Tempo em excesso.';
      }

      if (checkEmptyObject(localErrors).length > 0) {
        return dispatch({
          type: 'SET_ERROR',
          payload: localErrors,
        });
      }

      let infoMatrix;

      if (type === 'grupo') {
        infoMatrix = {
          name,
          time: getTotalSecs(time),
          isGroup: true,
          groups: groups.map(x => ({ id: x.id, time: getTotalSecs(x.time) })),
        };
      } else {
        infoMatrix = {
          name,
          time: getTotalSecs(time),
          isGroup: false,
          groups: individuals.map(x => ({ id: x.id, time: getTotalSecs(x.time) })),
        };
      }

      if (editing) {
        return dispatchRedux(updateMatrix(id, infoMatrix));
      }

      return dispatchRedux(createMatrix(infoMatrix));
    },
    [dispatchRedux, editing, groups, id, individuals, name, time, type]
  );

  const contextState = useMemo(
    () => ({ state, loading, handleSubmit }),
    [state, loading, handleSubmit]
  );
  const contextFuncs = useMemo(() => ({ getTotalSecs, getTimeValue }), []);

  return (
    <MatrixDispatchContext.Provider value={dispatch}>
      <MatrixFuncsContext.Provider value={contextFuncs}>
        <MatrixStateContext.Provider value={contextState}>{children}</MatrixStateContext.Provider>
      </MatrixFuncsContext.Provider>
    </MatrixDispatchContext.Provider>
  );
};

CreateEditMatrixProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  items: PropTypes.array.isRequired,
};

export default CreateEditMatrixProvider;
