import React, { useContext } from 'react';
import { LocalStateContext, LocalDispatchContext } from './CreateEditLocalProvider';
import FormInfoLocal from './FormInfoLocal';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import UpdateItem from '../../pieces/UpdateItem';

const EditLocal = () => {
  const {
    state: { errors, items },
    handleSubmit,
    loading,
  } = useContext(LocalStateContext);
  const dispatch = useContext(LocalDispatchContext);

  return (
    <UpdateItem
      disableSubmit={checkEmptyObject(errors).length > 0}
      loading={loading}
      items={items}
      dispatch={dispatch}
      handleSubmit={handleSubmit}
    >
      <FormInfoLocal />
    </UpdateItem>
  );
};

export default EditLocal;
