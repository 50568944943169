import React, { useCallback, useContext } from 'react';
import { makeStyles, Grid, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import EntryBox from './EntryBox';
import Body1 from '../../common/typographys/Body1';
import { Delete } from '@material-ui/icons';
import SelectOutlined from '../../common/forms/SelectOutlined';
import { MeetingDispatchContext } from '../CreateEditMeetingsProvider';
import isEmpty from '../../../utils/isEmpty';
import Subtitle1 from '../../common/typographys/Subtitle1';
import formatDate from '../../../utils/formatDate';

const useStyles = makeStyles(theme => ({
  mainBox: {
    display: 'flex',
  },
  iconDelete: {
    fill: theme.palette.neutrals[4],
    '&:hover': {
      cursor: 'pointer',
      fill: theme.palette.colorsPalette.RustRed,
    },
  },
  pointBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  centerAlign: {
    display: 'flex',
    alignItems: 'center',
  },
  fullWidth: {
    width: '100%',
  },
}));

const MeetingPointEntry = ({ point, availableThemes, matrices }) => {
  const classes = useStyles();
  const { name, matrix, id, identifier_code, date } = point;
  const dispatch = useContext(MeetingDispatchContext);
  const showMatrices = matrices && matrices.length > 0;

  const removePoint = useCallback(
    infoPoint => e => {
      e.preventDefault();
      dispatch({
        type: 'REMOVE_POINT',
        payload: infoPoint,
      });
    },
    [dispatch]
  );

  const updateMatrix = useCallback(
    (matrixVal, pointId) => {
      dispatch({
        type: 'UPDATE_POINT_MATRIX',
        payload: {
          matrix: matrixVal,
          pointId,
        },
      });
    },
    [dispatch]
  );

  const movePointToTheme = useCallback(
    (theme, value) => {
      dispatch({
        type: 'ADD_POINT_TO_THEME',
        payload: {
          theme,
          point: value,
        },
      });
    },
    [dispatch]
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={showMatrices ? 6 : 8}>
        <EntryBox noMargin className={classes.pointBox}>
          <Box>
            <Body1 component="p">{identifier_code ? `${identifier_code} - ${name}` : name}</Body1>
            {!isEmpty(date) && <Subtitle1>{formatDate(date)}</Subtitle1>}
          </Box>
          <Delete className={classes.iconDelete} onClick={removePoint(point)} />
        </EntryBox>
      </Grid>
      {showMatrices && (
        <Grid item xs={3} className={classes.centerAlign}>
          <SelectOutlined
            className={classes.fullWidth}
            name={`matrix-${id}`}
            value={matrix}
            onChange={val => updateMatrix(val, point.id)}
            options={matrices}
            placeholder="Atribuir matriz"
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            isClearable
          />
        </Grid>
      )}
      <Grid item xs={showMatrices ? 3 : 4} className={classes.centerAlign}>
        <SelectOutlined
          className={classes.fullWidth}
          name={`theme-${id}`}
          value={null}
          onChange={val => movePointToTheme(val, point)}
          options={availableThemes}
          placeholder="Associar a tema"
          noOptionsMessage={() => 'Nenhum tema disponível'}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
        />
      </Grid>
    </Grid>
  );
};

MeetingPointEntry.propTypes = {
  point: PropTypes.object.isRequired,
  availableThemes: PropTypes.array.isRequired,
  matrices: PropTypes.array.isRequired,
};

export default React.memo(MeetingPointEntry);
