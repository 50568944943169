import {
  GET_GROUPS,
  GET_GROUP_PARTICIPANTS,
  UPDATE_GROUP,
  GROUPS_LOADING,
  GROUPS_NOT_LOADING,
  CREATE_GROUP,
  REMOVE_GROUP,
} from '../actions/types';

const initialState = {
  groups: [],
  group: {
    participants: [],
  },
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_GROUPS:
      return {
        ...state,
        groups: action.payload,
        loading: false,
      };
    case CREATE_GROUP:
      return {
        ...state,
        groups: [action.payload, ...state.groups],
        loading: false,
      };
    case UPDATE_GROUP:
      return {
        ...state,
        groups: state.groups.map(x => (x.id === action.payload.id ? action.payload : x)),
        loading: false,
      };
    case GET_GROUP_PARTICIPANTS:
      return {
        ...state,
        group: {
          ...state.group,
          participants: action.payload,
        },
        loading: false,
      };
    case REMOVE_GROUP:
      return {
        ...state,
        groups: state.groups.filter(x => x.id !== action.payload.id),
        loading: false,
      };
    case GROUPS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GROUPS_NOT_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
