import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const Link = props => {
  // eslint-disable-next-line react/prop-types
  if (props.disabled) {
    return (
      <div style={{ pointerEvents: 'none' }}>
        <RouterLink style={{ textDecoration: 'none', color: 'inherit' }} {...props} />
      </div>
    );
  }

  return <RouterLink style={{ textDecoration: 'none', color: 'inherit' }} {...props} />;
};

export default Link;
