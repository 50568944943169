import React, { useMemo } from 'react';
import { makeStyles, Paper, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import H6 from '../../../common/typographys/H6';
import { ReactComponent as Against } from '../../../../assets/images/againstSmallPlateia.svg';
import { ReactComponent as Favor } from '../../../../assets/images/checkSmallPlateia.svg';
import { ReactComponent as Abstention } from '../../../../assets/images/abstentionSmallPlateia.svg';
import clsx from 'clsx';
import BoxSlider from './BoxSlider';
import useCustomSlider from '../../../../utils/hooks/useCustomSlider';

const SHOW_MD = 4;
const SHOW_LG = 5;
const SHOW_XL = 7;
const SLIDE_TIME = 10000;

const useStyles = makeStyles(theme => ({
  paperStatus: {
    width: '100%',
    padding: '20px 30px',
  },
  mainBox: {
    padding: '22px 9px 22px 12px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  undiscussed: {
    backgroundColor: theme.palette.neutrals[3],
  },
  favor: {
    backgroundColor: theme.palette.colorsPalette.TrueGreen,
    '& *': {
      color: theme.palette.neutrals[1],
    },
  },
  against: {
    backgroundColor: theme.palette.colorsPalette.RustRed,
    '& *': {
      color: theme.palette.neutrals[1],
    },
  },
  abstention: {
    backgroundColor: theme.palette.colorsPalette.Orange,
    '& *': {
      color: theme.palette.neutrals[1],
    },
  },
  rounded: {
    width: '22px',
    height: '22px',
    borderRadius: '50%',
    backgroundColor: theme.palette.neutrals[1],
    marginLeft: '10px',
  },
  icon: {
    marginLeft: '10px',
  },
}));

const ProposalEntry = ({ meetingStatus, name }) => {
  const { point_status, discussed } = meetingStatus;
  const classes = useStyles();

  const icon = useMemo(() => {
    if (!discussed) {
      return <Box className={classes.rounded} />;
    }

    if (point_status === 1) {
      return <Favor className={classes.icon} />;
    }

    if (point_status === 2) {
      return <Against className={classes.icon} />;
    }

    return <Abstention className={classes.icon} />;
  }, [point_status, discussed, classes]);

  return (
    <>
      <Box
        className={clsx(classes.mainBox, {
          [classes.favor]: point_status === 1,
          [classes.against]: point_status === 2,
          [classes.abstention]: discussed && point_status !== 1 && point_status !== 2,
          [classes.undiscussed]: !discussed,
        })}
      >
        <H6 secondary={Boolean(discussed)} tertiary={!discussed} oneLine>
          {name}
        </H6>
        {icon}
      </Box>
      <Box height="14px" />
    </>
  );
};

const AllProposalStatus = ({ proposals }) => {
  const classes = useStyles();
  const [proposalsChunked, curIndex] = useCustomSlider(
    proposals,
    SHOW_MD,
    SHOW_LG,
    SHOW_XL,
    SLIDE_TIME
  );

  return (
    <Paper className={classes.paperStatus}>
      {proposalsChunked[curIndex] &&
        proposalsChunked[curIndex].map(proposal => (
          <ProposalEntry
            key={proposal.id}
            name={proposal.name}
            meetingStatus={proposal.meeting_point_status}
          />
        ))}
      {proposalsChunked.length > 1 && (
        <BoxSlider curIndex={curIndex} length={proposalsChunked.length} />
      )}
    </Paper>
  );
};

AllProposalStatus.propTypes = {
  proposals: PropTypes.array.isRequired,
};

ProposalEntry.propTypes = {
  meetingStatus: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

export default React.memo(AllProposalStatus);
