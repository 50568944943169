import React, { useEffect, useReducer, useCallback, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from '../../../../utils/isEmpty';
import { clearErrors } from '../../../../store/actions/authActions';
import { createGroup, updateGroup } from '../../../../store/actions/groupsActions';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import { ConfigStateContext } from '../../MenuConfigProvider';

export const GroupDispatchContext = React.createContext();
export const GroupStateContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'REMOVE_USER':
      return {
        ...state,
        deletedUsers: [...state.deletedUsers, { id: action.payload.id }],
      };
    case 'CANCEL_REMOVE_USER':
      return {
        ...state,
        deletedUsers: state.deletedUsers.filter(x => x.id !== action.payload.id),
      };
    case 'SET_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    case 'CLEAR_STATE':
      return {
        ...state,
        name: '',
        showFirst: false,
        participants: [],
        idsParticipants: [],
        deletedUsers: [],
        id: '',
        errors: {},
      };
    default:
      return state;
  }
};

const CreateEditGroupProvider = ({ children, items }) => {
  const errorsReducer = useSelector(state => state.errors);
  const { loading } = useSelector(state => state.groups);
  const dispatchRedux = useDispatch();
  const { editing } = useContext(ConfigStateContext);
  const [state, dispatch] = useReducer(reducer, {
    name: '',
    showFirst: false,
    participants: [],
    idsParticipants: [],
    deletedUsers: [],
    id: '',
    items: [],
    errors: {},
  });
  const [sameName, setSameName] = useState('');

  const { name, participants, deletedUsers, id, errors, showFirst } = state;

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: 'items',
        value: items,
      },
    });
  }, [items]);

  useEffect(() => {
    if (errorsReducer.name) {
      dispatch({
        type: 'SET_ERROR',
        payload: { name: 'Já existe um grupo com este nome.' },
      });
      dispatchRedux(clearErrors());
      setSameName(name);
    }
  }, [errorsReducer, name, dispatchRedux]);

  useEffect(() => {
    if (!isEmpty(name) && !isEmpty(errors.name) && name !== sameName) {
      dispatch({
        type: 'SET_ERROR',
        payload: { name: '' },
      });
    }
  }, [name, errors.name, sameName]);

  useEffect(() => {
    if (!isEmpty(participants) && !isEmpty(errors.participants)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { participants: '' },
      });
    }
  }, [participants, errors.participants]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      const localErrors = {};

      if (isEmpty(name)) {
        localErrors.name = 'Insira um nome válido para o grupo.';
      }

      if (isEmpty(participants)) {
        localErrors.participants = 'Escolha pelo menos 1 utilizador para o grupo.';
      }

      if (checkEmptyObject(localErrors).length > 0) {
        return dispatch({
          type: 'SET_ERROR',
          payload: localErrors,
        });
      }

      const infoGroup = {
        name,
        showFirst,
        participants: participants.map(user => ({
          id: user.id,
        })),
      };

      if (editing) {
        return dispatchRedux(updateGroup(id, { ...infoGroup, deletedUsers }));
      }

      return dispatchRedux(createGroup(infoGroup));
    },
    [dispatchRedux, deletedUsers, editing, id, name, participants, showFirst]
  );

  const contextState = useMemo(() => ({ state, handleSubmit, loading }), [
    state,
    handleSubmit,
    loading,
  ]);

  return (
    <GroupDispatchContext.Provider value={dispatch}>
      <GroupStateContext.Provider value={contextState}>{children}</GroupStateContext.Provider>
    </GroupDispatchContext.Provider>
  );
};

CreateEditGroupProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  items: PropTypes.array.isRequired,
};

export default CreateEditGroupProvider;
