import React from 'react';
import { makeStyles, Grid, Paper, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import VoteBlock from './VoteBlock';
import H4 from '../../../common/typographys/H4';
import H5 from '../../../common/typographys/H5';

const useStyles = makeStyles(() => ({
  fullHeight: {
    height: '100%',
  },
  fullWidth: {
    width: '100%',
  },
}));

const SideXLVote = ({ votes /* , maxVotes */ }) => {
  const classes = useStyles();

  const countVotes = votes.reduce(
    (acc, cur) => {
      acc[cur.vote] += 1;
      acc.total += 1;

      return acc;
    },
    { favor: 0, against: 0, abstention: 0, total: 0 }
  );

  return (
    <Grid container justify="flex-end" className={classes.fullHeight}>
      <Grid item xs={9}>
        <Grid container className={classes.fullHeight}>
          <Paper elevation={0} className={classes.fullWidth}>
            <Grid container justify="center" className={classes.fullHeight}>
              <Grid item xs={10}>
                <Box
                  height="100%"
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                >
                  <Box height="36px" />
                  <H4>Votos</H4>
                  <Box height="10px" />
                  {/* <H5 inactive>
                    {countVotes.total}/{maxVotes}
                  </H5> */}
                  <H5 inactive>Contabilizados: {countVotes.total}</H5>
                  <Box height="50px" />
                  <VoteBlock favor total={countVotes.favor} />
                  <Box height="36px" />
                  <VoteBlock against total={countVotes.against} />
                  <Box height="36px" />
                  <VoteBlock abstention total={countVotes.abstention} />
                  <Box height="36px" />
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

SideXLVote.propTypes = {
  votes: PropTypes.array.isRequired,
  // maxVotes: PropTypes.number.isRequired,
};

export default React.memo(SideXLVote);
