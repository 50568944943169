import React, { useContext } from 'react';
import { ProfileStateContext, ProfileDispatchContext } from './CreateEditProfileProvider';
import FormInfoProfile from './FormInfoProfile';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import UpdateItem from '../../pieces/UpdateItem';

const EditProfile = () => {
  const {
    state: { errors, items },
    handleSubmit,
    loading,
  } = useContext(ProfileStateContext);
  const dispatch = useContext(ProfileDispatchContext);

  return (
    <UpdateItem disableSubmit={checkEmptyObject(errors).length > 0} loading={loading} items={items} dispatch={dispatch} handleSubmit={handleSubmit}>
      <FormInfoProfile />
    </UpdateItem>
  );
};

export default EditProfile;
