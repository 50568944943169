import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { changeMenuSidebar } from '../../store/actions/navigationActions';
import { makeStyles } from '@material-ui/core/styles';
import H5 from '../common/typographys/H5';
import BoxConfig from './pieces/BoxConfig';
import Groups from './tabs/groups/Groups';
import MenuConfigProvider from './MenuConfigProvider';
import Matrices from './tabs/matrices/Matrices';
import Typologies from './tabs/typologies/Typologies';
import Entities from './tabs/entities/Entities';
import Profiles from './tabs/profiles/Profiles';
import Themes from './tabs/themes/Themes';
// import Flows from './tabs/flows/Flows';
import Users from './tabs/users/Users';
import Locals from './tabs/locals/Locals';
import OrganicUnits from './tabs/organicUnits/OrganicUnits';
import hasTypologyConfig from '../../utils/permissions/hasTypologyConfig';
import hasUsersConfig from '../../utils/permissions/hasUsersConfig';
import hasOthersProp from '../../utils/permissions/hasOthersProp';
import hasMeetingsProp from '../../utils/permissions/hasMeetingsProp';
import GeneralConfigs from './tabs/general/GeneralConfigs';

const useStyles = makeStyles(() => ({
  marginContainer: {
    marginTop: '54px',
  },
  marginLabel: {
    marginBottom: '55px',
  },
  marginBoxes: {
    marginBottom: '2px',
  },
}));

const allowedButtonSizesPerBreakpoint = {
  xs: [6, 12],
  sm: [4, 6],
  lg: [2, 3, 4],
  xl: [2, 3, 4, 6],
};

const getUserFirstConfigMenu = permissions => {
  if (hasTypologyConfig(permissions)) {
    return 0;
  }

  if (hasOthersProp(permissions, 'organic_units')) {
    return 1;
  }

  if (hasUsersConfig(permissions)) {
    return 2;
  }

  if (hasOthersProp(permissions, 'groups')) {
    return 3;
  }

  if (hasOthersProp(permissions, 'matrices')) {
    return 4;
  }

  if (hasOthersProp(permissions, 'entities')) {
    return 5;
  }

  if (hasOthersProp(permissions, 'themes')) {
    return 6;
  }

  // if (hasOthersProp(permissions, 'generic_flows')) {
  //   return 6;
  // }

  if (hasOthersProp(permissions, 'profiles')) {
    return 7;
  }

  if (hasMeetingsProp(permissions, 'secretary')) {
    return 8;
  }

  return '';
};

const visible = ({ show }) => show;

const calculateButtonSize = (numButtons, buttonSizes) => {
  let buttonSizeIndex = 0;
  const maxButtonsPerRow = 12 / buttonSizes[buttonSizeIndex];
  const hasCompleteFinalRow = numButtons % maxButtonsPerRow === 0;
  const initialNumberOfRows = Math.ceil(numButtons / maxButtonsPerRow);

  if (hasCompleteFinalRow || initialNumberOfRows === 1) return buttonSizes[buttonSizeIndex];

  let buttonSize;

  while (buttonSizeIndex < buttonSizes.length) {
    const nextButtonSize = buttonSizes[buttonSizeIndex];
    const nextNumberOfRows = Math.ceil(numButtons / (12 / nextButtonSize));

    if (nextNumberOfRows > initialNumberOfRows) break;
    else {
      ++buttonSizeIndex;
      buttonSize = nextButtonSize;
    }
  }

  return buttonSize;
};

const Configs = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState(null);
  const { permissions } = useSelector(state => state.auth.user);

  useEffect(() => {
    dispatch(changeMenuSidebar('configuracoes', 'Configurações'));
  }, [dispatch]);

  useEffect(() => {
    const firstMenu = getUserFirstConfigMenu(permissions);
    setActiveIndex(firstMenu);
  }, [permissions]);

  const changeMenu = useCallback(
    idx => e => {
      e.preventDefault();
      setActiveIndex(idx);
    },
    []
  );

  const configs = useMemo(
    () => [
      { idx: 0, label: 'Tipologias', show: hasTypologyConfig(permissions) },
      { idx: 1, label: 'Unidades Orgânicas', show: hasOthersProp(permissions, 'organic_units') },
      { idx: 2, label: 'Utilizadores', show: hasUsersConfig(permissions) },
      { idx: 3, label: 'Grupos', show: hasOthersProp(permissions, 'groups') },
      { idx: 4, label: 'Matrizes', show: hasOthersProp(permissions, 'matrices') },
      { idx: 5, label: 'Entidades', show: hasOthersProp(permissions, 'entities') },
      { idx: 6, label: 'Temas', show: hasOthersProp(permissions, 'themes') },
      // { idx: 6, label: 'Fluxos de Aprovação', show: hasOthersProp(permissions, 'generic_flows') }, -> not included
      { idx: 7, label: 'Perfis', show: hasOthersProp(permissions, 'profiles') },
      { idx: 8, label: 'Locais', show: hasOthersProp(permissions, 'locals') },
      { idx: 9, label: 'Configurações gerais', show: hasMeetingsProp(permissions, 'secretary') },
    ],
    [permissions]
  );

  const renderButtons = buttonConfigs => {
    const visibleButtons = buttonConfigs.filter(visible);

    return visibleButtons.map(({ label, idx }) => {
      const numberOfButtons = visibleButtons.length;
      const { xs, sm, lg, xl } = allowedButtonSizesPerBreakpoint;

      return (
        <BoxConfig
          key={label}
          selected={activeIndex === idx}
          label={label}
          onClick={changeMenu(idx)}
          xs={calculateButtonSize(numberOfButtons, xs)}
          sm={calculateButtonSize(numberOfButtons, sm)}
          lg={calculateButtonSize(numberOfButtons, lg)}
          xl={calculateButtonSize(numberOfButtons, xl)}
        />
      );
    });
  };

  return (
    <Grid container spacing={2} justify="center" className={classes.marginContainer}>
      <Grid item xs={10}>
        <H5 className={classes.marginLabel}>Informações principais</H5>
        <Grid container spacing={2} className={classes.marginBoxes}>
          {renderButtons(configs)}
        </Grid>
        <Grid container spacing={4}>
          <MenuConfigProvider activeIndex={activeIndex}>
            {activeIndex === 0 && <Typologies />}
            {activeIndex === 1 && <OrganicUnits />}
            {activeIndex === 2 && <Users />}
            {activeIndex === 3 && <Groups />}
            {activeIndex === 4 && <Matrices />}
            {activeIndex === 5 && <Entities />}
            {activeIndex === 6 && <Themes />}
            {/* {activeIndex === 6 && <Flows />} */}
            {activeIndex === 7 && <Profiles />}
            {activeIndex === 8 && <Locals />}
            {activeIndex === 9 && <GeneralConfigs />}
          </MenuConfigProvider>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Configs;
