import React from 'react';
// import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import InputField from '../../../common/forms/InputField';
// import Subtitle2 from '../../../common/typographys/Subtitle2';
// import isEmpty from '../../../../utils/isEmpty';
// import ProposalInternalLink from '../../pieces/ProposalInternalLink';

// const useStyles = makeStyles(() => ({
//   label: {
//     marginBottom: '8px',
//   },
// }));

const InternalLink = ({ state, updateField }) => {
  // const classes = useStyles();
  const { internalLink /* , internalLinkText */, errors } = state;

  return (
    <>
      <InputField
        label="Referência interna"
        name="internalLink"
        value={internalLink}
        onChange={e => updateField('internalLink', e.target.value)}
        error={errors.internalLink}
        placeholder={process.env.REACT_APP_CLIENT_URL}
      />
      {/* <InputField
        label="Texto da referência"
        name="internalLinkText"
        value={internalLinkText}
        onChange={e => updateField('internalLinkText', e.target.value)}
        error={errors.internalLinkText}
        placeholder={process.env.REACT_APP_CLIENT_NAME}
      />
      {!isEmpty(internalLink) && (
        <>
          <Subtitle2 tertiary className={classes.label}>
            Testar referência
          </Subtitle2>
          <ProposalInternalLink link={internalLink} text={internalLinkText} />
          <Box height="20px" />
        </>
      )} */}
    </>
  );
};

InternalLink.propTypes = {
  state: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
};

export default InternalLink;
