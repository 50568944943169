import React, { useCallback, useState, useContext } from 'react';
import { makeStyles, Box, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import SelectOutlined from '../../common/forms/SelectOutlined';
import useBooleanToggle from '../../../utils/hooks/useBooleanToggle';
import DialogConfirmAction from '../../common/dialogs/DialogConfirmAction';
import { IndicatorsContainer, ControlEditing, SingleValuePrepare } from './selectComponents';
import { useDispatch } from 'react-redux';
import { changeMeetingUsers, checkoutMeetingUser } from '../../../store/actions/meetingsActions';
import { PrepareMeetingDispatchContext } from '../PrepareMeetingProvider';
import clsx from 'clsx';
import { EventSeat } from '@material-ui/icons';
import { SocketReunion } from '../meeting/SocketReunionWrapper';

const useStyles = makeStyles(theme => ({
  mainBox: {
    marginBottom: '12px',
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '10px',
  },
  iconDelete: {
    fill: theme.palette.neutrals[4],
    '&:hover': {
      cursor: 'pointer',
      fill: theme.palette.colorsPalette.RustRed,
    },
  },
  iconMail: {
    fill: theme.palette.neutrals[4],
    marginRight: '19px',
    '&:hover': {
      cursor: 'pointer',
      fill: theme.palette.neutrals[6],
    },
  },
  iconArrow: {
    fill: theme.palette.neutrals[5],
    '&:hover': {
      cursor: 'pointer',
      fill: theme.palette.neutrals[6],
    },
  },
  outlined: {
    backgroundColor: theme.palette.neutrals[2],
    '& fieldset': {
      display: 'none',
    },
  },
  outlinedEditing: {
    border: `solid 1px ${theme.palette.neutrals[3]}`,
  },
  commonCheck: {
    marginRight: '10px',
    width: '16px',
    height: '16px',
    '&:hover': {
      cursor: 'pointer',
      fill: `${theme.palette.primary[300]}!important`,
    },
  },
  checkedIn: {
    fill: `${theme.palette.colorsPalette.TrueGreen}!important`,
  },
  notCheckedIn: {
    fill: `${theme.palette.colorsPalette.Orange}!important`,
  },
}));

const otherComponents = {
  IndicatorsContainer,
  Control: ControlEditing,
  SingleValue: SingleValuePrepare,
};

const MeetingUsersListPrepare = ({
  meetingId,
  users,
  allUsers,
  idxGroup,
  isGroup,
  idGroup,
  participants,
}) => {
  const classes = useStyles();
  const [openChange, toggleChange] = useBooleanToggle();
  const [open, setOpen] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [oldUser, setOldUser] = useState({});
  const [newUser, setNewUser] = useState({});
  const [index, setIndex] = useState(0);
  const dispatch = useContext(PrepareMeetingDispatchContext);
  const { socketNsp } = useContext(SocketReunion);
  const dispatchRedux = useDispatch();

  const handleChange = useCallback(
    (oldValue, newValue, idxValue) => {
      setOldUser(oldValue);
      setNewUser(newValue);
      setIndex(idxValue);
      toggleChange();
    },
    [toggleChange]
  );

  const confirmChange = useCallback(() => {
    dispatch({
      type: isGroup ? 'CHANGE_GROUP_USER' : 'CHANGE_USER',
      payload: {
        idxGroup,
        idxUser: index,
        newValue: newUser,
        oldValue: oldUser,
        idGroup,
      },
    });
    setOldUser({});
    setNewUser({});
    setIndex(0);
    toggleChange();
    dispatchRedux(changeMeetingUsers(meetingId, { userIn: newUser.id, userOut: oldUser.id }));
  }, [
    index,
    idxGroup,
    idGroup,
    isGroup,
    newUser,
    oldUser,
    dispatch,
    toggleChange,
    dispatchRedux,
    meetingId,
  ]);

  const checkIfIsPresent = useCallback(
    userId => {
      const obj = participants.find(x => x.id === userId);

      return (obj && (obj.active || obj.checkIn)) || false;
    },
    [participants]
  );

  const checkIfIsCheckedIn = useCallback(
    userId => {
      const obj = participants.find(x => x.id === userId);

      return (obj && obj.checkIn) || false;
    },
    [participants]
  );

  const handleCheckIn = useCallback(
    (user, isCheckedIn) => e => {
      e.preventDefault();
      setUserInfo(user);
      if (isCheckedIn) {
        // @ Efetuar check-out do utilizador
        setOpen(true);
      } else {
        window.open(
          `${process.env.REACT_APP_HOSTNAME}/saml-auth?meeting_id=${meetingId}&user_id=${user.id}`,
          '_blank',
          'toolbar=0,location=0,menubar=0,width=700,height=600'
        );
      }
    },
    [meetingId]
  );

  const confirmCheckout = useCallback(
    (idMeeting, userId) => e => {
      e.preventDefault();

      const checkoutPromise = new Promise((resolve, reject) => {
        dispatchRedux(checkoutMeetingUser(idMeeting, userId, resolve, reject));
      });

      checkoutPromise.then(res => {
        if (res.data.response) {
          setOpen(false);
          socketNsp.emit('checkout_user', JSON.stringify({ id: idMeeting, userId }));
        }
      });
    },
    [dispatchRedux, socketNsp]
  );

  return (
    <>
      {users.map((user, idx) => (
        <React.Fragment key={user.id}>
          <Box className={classes.mainBox}>
            <Tooltip
              placement="top"
              title={checkIfIsCheckedIn(user.id) ? 'Efetuar check-out' : 'Efetuar check-in'}
            >
              <EventSeat
                className={clsx(classes.commonCheck, {
                  [classes.checkedIn]: checkIfIsCheckedIn(user.id),
                  [classes.notCheckedIn]: !checkIfIsCheckedIn(user.id),
                })}
                onClick={handleCheckIn(user, checkIfIsCheckedIn(user.id))}
              />
            </Tooltip>
            <SelectOutlined
              fullWidth
              name={`user-${user.id}`}
              options={allUsers.filter(x => users.find(u => u.id === x.id) === undefined)}
              value={user}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              otherComponents={otherComponents}
              otherClasses={classes}
              onChange={val => handleChange(user, val, idx)}
              noOptionsMessage={() => 'Sem participantes disponíveis para substituir.'}
              hideRemove
              isPresent={checkIfIsPresent(user.id)}
            />
          </Box>
        </React.Fragment>
      ))}
      <DialogConfirmAction
        open={openChange}
        handleClose={toggleChange}
        labelSubmit="Substituir"
        msg={`Deseja substituir ${oldUser.name} por ${newUser.name}?`}
        handleSubmit={confirmChange}
      />
      <DialogConfirmAction
        open={open}
        handleClose={e => {
          e.preventDefault();
          setOpen(false);
        }}
        msg={`Deseja marcar ${userInfo.name}, como ausente da reunião?`}
        secondLevel={`Não serão contabilizados votos para ${userInfo.name} a partir deste momento e será desabilitada a opção de oratória.`}
        handleSubmit={confirmCheckout(meetingId, userInfo.id)}
        labelSubmit="Confirmar check-out"
      />
    </>
  );
};

MeetingUsersListPrepare.defaultProps = {
  idxGroup: null,
  isGroup: false,
  idGroup: null,
  participants: [],
};

MeetingUsersListPrepare.propTypes = {
  users: PropTypes.array.isRequired,
  allUsers: PropTypes.array.isRequired,
  meetingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  idxGroup: PropTypes.number,
  idGroup: PropTypes.number,
  isGroup: PropTypes.bool,
  participants: PropTypes.array,
};

export default React.memo(MeetingUsersListPrepare);
