import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import { Grid, CircularProgress, Box } from '@material-ui/core';
import H5 from '../../common/typographys/H5';
import isEmpty from '../../../utils/isEmpty';
import MeetingParticipant from './MeetingParticipant';
import MeetingModerator from './MeetingModerator';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { updateReunion, getReunionQualityVoters } from '../../../store/actions/reunionsActions';
import { changeMenuSidebar } from '../../../store/actions/navigationActions';
import getItemFromStorage from '../../../utils/getItemFromStorage';
import MeetingPlateia from './MeetingPlateia';
import PropTypes from 'prop-types';
import MeetingCommonWrapper from './MeetingCommonWrapper';
import { SocketReunion } from './SocketReunionWrapper';

const Meeting = ({ plateia }) => {
  const { reunion } = useSelector(state => state.reunions);
  const { reunions } = useSelector(state => state.socket);
  const { id: userId, name, email } = useSelector(state => state.auth.user);
  const [change, setChange] = useState(true);
  const { socketNsp } = useContext(SocketReunion);
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const isModerator = useMemo(() => {
    if (isEmpty(reunion)) return false;

    const { board = {} } = reunion;
    const { secretary = [], moderator = [] } = board;
    const flattenBoard = [...secretary, ...moderator];

    return flattenBoard.find(x => x.id === userId) !== undefined && !plateia;
  }, [plateia, reunion, userId]);

  useEffect(() => {
    dispatch(changeMenuSidebar('reuniao', 'Reunião'));
    const reunionInfo = reunions.find(x => x.id.toString() === id.toString());
    if (!isEmpty(reunionInfo) && !plateia) {
      socketNsp.emit('join', JSON.stringify({ id, name, email, userId }));
      if (reunionInfo.status === 2) {
        dispatch(updateReunion(reunionInfo));
      }
    } else if (plateia) {
      dispatch(updateReunion(getItemFromStorage('plateia')));
    }
    // else if (reunionInfo === undefined) {
    //   history.goBack();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reunions, history, dispatch, id, plateia]);

  useEffect(() => {
    if (isModerator) {
      dispatch(getReunionQualityVoters(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModerator]);

  const navigate = useCallback(
    page => {
      setChange(false);
      history.push(page);
    },
    [history]
  );

  if (!isEmpty(reunion)) {
    if (isModerator) {
      return (
        <MeetingCommonWrapper setChange={setChange} navigate={navigate}>
          <MeetingModerator change={change} />
        </MeetingCommonWrapper>
      );
    }

    if (plateia) {
      return (
        <MeetingCommonWrapper setChange={setChange} navigate={navigate}>
          <MeetingPlateia />
        </MeetingCommonWrapper>
      );
    }

    return (
      <MeetingCommonWrapper setChange={setChange} navigate={navigate}>
        <MeetingParticipant change={change} />
      </MeetingCommonWrapper>
    );
  }

  return (
    <Grid container spacing={3} style={{ height: '80vh' }} justify="center" alignItems="center">
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <H5>Reunião prestes a iniciar, aguarde um momento...</H5>
        <CircularProgress style={{ margin: '30px auto' }} />
      </Box>
    </Grid>
  );
};

Meeting.defaultProps = {
  plateia: false,
};

Meeting.propTypes = {
  plateia: PropTypes.bool,
};

export default Meeting;
