import React, { useEffect, useState, useCallback } from 'react';
import CreateEditMeetingsProvider from '../CreateEditMeetingsProvider';
import { useDispatch } from 'react-redux';
import { changeMenuSidebar } from '../../../store/actions/navigationActions';
import FormInfoMeeting from '../FormInfoMeeting';
import { getAllTypologies, clearTypology } from '../../../store/actions/typologiesActions';
import { getUsers } from '../../../store/actions/usersActions';
import { useHistory, useParams } from 'react-router-dom';
import { getMeeting } from '../../../store/actions/meetingsActions';

const NewSession = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [change, setChange] = useState(true);

  useEffect(() => {
    dispatch(changeMenuSidebar('reunioes', 'Reuniões', 'Agendar nova sessão'));
    dispatch(getAllTypologies());
    dispatch(getUsers());
    dispatch(getMeeting(id));
    return () => {
      dispatch(clearTypology());
    };
  }, [dispatch, id]);

  const navigate = useCallback(
    page => {
      setChange(false);
      history.push(page);
    },
    [history]
  );

  return (
    <CreateEditMeetingsProvider setChange={setChange} navigate={navigate} newSession>
      <FormInfoMeeting change={change} newSession />
    </CreateEditMeetingsProvider>
  );
};

export default NewSession;
