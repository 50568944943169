import React, { useCallback } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import H5 from '../../../common/typographys/H5';
import ButtonNormal from '../../../common/buttons/ButtonNormal';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
// import useBooleanToggle from '../../../../utils/hooks/useBooleanToggle';
// import DialogCheck from '../../../common/dialogs/DialogCheck';

const useStyles = makeStyles(theme => ({
  withMargin: {
    marginBottom: theme.spacing(2),
  },
}));

const AddProposalHeader = ({ state }) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const showHeaderMargin = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const { loading, errors, submitProposal, submitting } = state;
  // const [openCancel, toggleCancel] = useBooleanToggle();

  const handleBack = useCallback(
    e => {
      e.preventDefault();
      history.goBack();
    },
    [history]
  );

  return (
    <Grid container>
      <Grid item xs={12} md={6} lg={8} className={showHeaderMargin && classes.withMargin}>
        <H5>Adicionar nova proposta</H5>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <ButtonNormal
              variant="outlined"
              color="primary"
              label="Cancelar"
              onClick={handleBack}
              disabled={loading}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <ButtonNormal
              contained
              pea
              onClick={submitProposal}
              label="Gravar"
              disabled={loading || checkEmptyObject(errors).length > 0 || submitting}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

AddProposalHeader.propTypes = {
  state: PropTypes.object.isRequired,
};

export default React.memo(AddProposalHeader);
