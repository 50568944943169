import React from 'react';
import { makeStyles, Grid, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import H5 from '../common/typographys/H5';
import Body2 from '../common/typographys/Body2';

const useStyles = makeStyles(() => ({
  paperStat: {
    width: '100%',
    minHeight: '250px',
    padding: '104px 20px 14px 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

const StatBlock = ({ label, value }) => {
  const classes = useStyles();

  return (
    <Grid item xs={3}>
      <Paper className={classes.paperStat}>
        <H5 center>{value || 0}</H5>
        <Body2>{label}</Body2>
      </Paper>
    </Grid>
  );
};

StatBlock.defaultProps = {
  value: 0,
};

StatBlock.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
};

export default React.memo(StatBlock);
