import React, { useEffect } from 'react';
import CheckProposalProvider from './CheckProposalProvider';
import { useDispatch } from 'react-redux';
import { changeMenuSidebar } from '../../../store/actions/navigationActions';
import { useParams } from 'react-router-dom';
import ProposalInfo from './ProposalInfo';

const CheckProposal = () => {
  const dispatchRedux = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatchRedux(changeMenuSidebar('proposta', 'Propostas', 'Proposta'));
  }, [dispatchRedux]);

  return (
    <CheckProposalProvider id={id}>
      <ProposalInfo />
    </CheckProposalProvider>
  );
};

export default CheckProposal;
