import React, { useContext, useEffect, useCallback } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import ButtonNormal from '../../common/buttons/ButtonNormal';
import { ConfigDispatchContext } from '../MenuConfigProvider';
import usePrevious from '../../../utils/hooks/usePrevious';
import useBooleanToggle from '../../../utils/hooks/useBooleanToggle';
import DialogConfirmAction from '../../common/dialogs/DialogConfirmAction';

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonAdd: {
    marginLeft: '10px',
  },
  boxContent: {
    padding: '28px 65px 0px 65px',
  },
}));

const AddNewItem = ({
  children,
  disableSubmit,
  loading,
  items,
  dispatch,
  handleSubmit,
  labelSubmit,
  msgSubmit,
}) => {
  const classes = useStyles();
  const dispatchConfig = useContext(ConfigDispatchContext);
  const prevItems = usePrevious(items);
  const [openModal, toggleModal] = useBooleanToggle();

  // @ Depois de submeter novo item, fecha o modo de adição
  useEffect(() => {
    if (prevItems !== undefined && JSON.stringify(prevItems) !== JSON.stringify(items)) {
      dispatch({
        type: 'CLEAR_STATE',
      });
      dispatchConfig({
        type: 'CANCEL_ADD_NEW',
      });
    }
  }, [items, prevItems, dispatch, dispatchConfig]);

  const cancelAddNew = useCallback(
    e => {
      e.preventDefault();
      dispatchConfig({
        type: 'CANCEL_ADD_NEW',
      });
    },
    [dispatchConfig]
  );

  const onSubmit = useCallback(
    e => {
      toggleModal();
      handleSubmit(e);
    },
    [toggleModal, handleSubmit]
  );

  return (
    <>
      <Box className={classes.box}>
        <ButtonNormal
          variant="outlined"
          color="primary"
          label="Cancelar"
          onClick={cancelAddNew}
          disabled={loading}
        />
        <ButtonNormal
          contained
          pea
          onClick={toggleModal}
          label={labelSubmit}
          className={classes.buttonAdd}
          disabled={loading || disableSubmit}
        />
      </Box>
      <Box className={classes.boxContent}>{children}</Box>
      <DialogConfirmAction
        open={openModal}
        handleClose={toggleModal}
        msg={msgSubmit}
        handleSubmit={onSubmit}
        labelSubmit={labelSubmit}
      />
    </>
  );
};

AddNewItem.defaultProps = {
  labelSubmit: 'Gravar',
  msgSubmit: 'Tem a certeza que deseja gravar a nova informação?',
};

AddNewItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  disableSubmit: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  msgSubmit: PropTypes.string,
  labelSubmit: PropTypes.string,
};

export default AddNewItem;
