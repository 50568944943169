import isEmpty from '../isEmpty';

/**
 *
 * @param {object} permissions - Permissions object
 * @returns {boolean} True or false depending if user has permission to have typology config
 */
const hasTypologyConfig = permissions => {
  if (
    !isEmpty(permissions) &&
    permissions.typologies &&
    (permissions.typologies.create || permissions.typologies.edit || permissions.typologies.delete)
  ) {
    return true;
  }

  return false;
};

export default hasTypologyConfig;
