/**
 *
 * @description Obtain one item from array
 * @param {array} arr - Array to obtain item from
 * @param {number} index - Index of item to obtain
 * @returns
 */
const getEventFromArray = (arr, index) => {
  return arr.length >= index ? arr[arr.length - index] : {};
};

export default getEventFromArray;
