import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body2 from '../../../../common/typographys/Body2';
import FileBox from '../../../../common/FileBox';
import { useDispatch } from 'react-redux';
import { downloadProposalFile } from '../../../../../store/actions/proposalsActions';
import isEmpty from '../../../../../utils/isEmpty';

const useStyles = makeStyles(() => ({
  fileLabel: {
    marginTop: '25px',
    marginBottom: '10px',
  },
}));

const PointFiles = ({ files, proposalId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const downloadFile = useCallback(
    file => e => {
      e.preventDefault();

      dispatch(downloadProposalFile(proposalId, file.id, file.name));
    },
    [dispatch, proposalId]
  );

  const hasFiles = useMemo(() => files && files.length > 0, [files]);
  const pluralS = useMemo(() => (hasFiles && files.length > 0 ? 's' : ''), [hasFiles, files]);

  return hasFiles ? (
    <>
      <Body2 className={classes.fileLabel}>Ficheiro{pluralS}</Body2>
      {!isEmpty(proposalId) && files.map(file => <FileBox key={file.id || file.name} download downloadFile={downloadFile} file={file} />)}
    </>
  ) : null;
};

PointFiles.defaultProps = {
  proposalId: null,
  files: [],
};

PointFiles.propTypes = {
  proposalId: PropTypes.number,
  files: PropTypes.array,
};

export default React.memo(PointFiles);
