import React, { useEffect, useReducer, useCallback, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from '../../../../utils/isEmpty';
import { clearErrors } from '../../../../store/actions/authActions';
import { createLocal, updateLocal } from '../../../../store/actions/localActions';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import { ConfigStateContext } from '../../MenuConfigProvider';

export const LocalDispatchContext = React.createContext();
export const LocalStateContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'SET_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    case 'CLEAR_STATE':
      return {
        ...state,
        name: '',
        id: '',
        address: '',
        errors: {},
      };
    default:
      return state;
  }
};

const CreateEditLocalProvider = ({ children, items }) => {
  const errorsReducer = useSelector(state => state.errors);
  const { loading } = useSelector(state => state.locals);
  const dispatchRedux = useDispatch();
  const { editing } = useContext(ConfigStateContext);
  const [state, dispatch] = useReducer(reducer, {
    name: '',
    id: '',
    address: '',
    items: [],
    errors: {},
  });
  const [sameName, setSameName] = useState('');

  const { name, id, address, errors } = state;

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: 'items',
        value: items,
      },
    });
  }, [items]);

  useEffect(() => {
    if (errorsReducer.name) {
      dispatch({
        type: 'SET_ERROR',
        payload: { name: 'Já existe um local com este nome.' },
      });
      dispatchRedux(clearErrors());
      setSameName(name);
    }
  }, [errorsReducer, name, dispatchRedux]);

  useEffect(() => {
    if (!isEmpty(name) && !isEmpty(errors.name) && name !== sameName) {
      dispatch({
        type: 'SET_ERROR',
        payload: { name: '' },
      });
    }
  }, [name, errors.name, sameName]);

  useEffect(() => {
    if (!isEmpty(address) && !isEmpty(errors.address)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { address: '' },
      });
    }
  }, [address, errors.address]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      const localErrors = {};

      if (isEmpty(name)) {
        localErrors.name = 'Insira um nome válido para o local.';
      }

      if (isEmpty(address)) {
        localErrors.address = 'Insira uma morada válida para o local.';
      }

      if (checkEmptyObject(localErrors).length > 0) {
        return dispatch({
          type: 'SET_ERROR',
          payload: localErrors,
        });
      }

      const infoLocal = {
        name,
        address,
      };

      if (editing) {
        return dispatchRedux(updateLocal(id, infoLocal));
      }

      return dispatchRedux(createLocal(infoLocal));
    },
    [dispatchRedux, editing, id, address, name]
  );

  const contextState = useMemo(
    () => ({ state, handleSubmit, loading }),
    [state, handleSubmit, loading]
  );

  return (
    <LocalDispatchContext.Provider value={dispatch}>
      <LocalStateContext.Provider value={contextState}>{children}</LocalStateContext.Provider>
    </LocalDispatchContext.Provider>
  );
};

CreateEditLocalProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  items: PropTypes.array.isRequired,
};

export default CreateEditLocalProvider;
