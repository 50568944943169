import React from 'react';
import { Box, Typography } from '@material-ui/core';
import loginRegisterStyles from '../../../common/layout/styles/loginRegister';
import Link from '../../../common/routing/Link';

const FooterForm = () => {
  const classes = loginRegisterStyles();
  return (
    <Box marginBottom={8} className={classes.marginTop} display="flex" justifyContent="center">
      <Typography variant="button" color="textSecondary">
        Já tem conta? <Link to="/">Faça login aqui</Link>
      </Typography>
    </Box>
  );
};

export default FooterForm;
