import React, { useContext, useCallback } from 'react';
import { makeStyles, Box, Input } from '@material-ui/core';
import PropTypes from 'prop-types';
import Subtitle2 from '../../../../common/typographys/Subtitle2';
import clsx from 'clsx';
import Body1 from '../../../../common/typographys/Body1';
import { TypologyDispatchContext } from '../CreateEditTypologyProvider';
import { fade } from '@material-ui/core/styles';
import OrganizeTypologyGroup from './OrganizeTypologyGroup';
import BoxCheckbox from '../../../../common/forms/BoxCheckbox';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
  },
  input: {
    textAlign: 'center',
    color: theme.palette.neutrals[7],
    width: '100%',
  },
  mainBox: {
    width: '100%',
    display: 'flex',
    height: '48px',
    marginBottom: '4px',
  },
  labelBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.neutrals[2],
    padding: '12px 10px',
    maxWidth: '336px',
  },
  limitTimeBox: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '176px',
    height: '100%',
    backgroundColor: theme.palette.neutrals[2],
    marginLeft: '4px',
    padding: '8px 10px 4px 10px',
  },
  summonBox: {
    height: '100%',
    padding: '12px 10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '106px',
    minWidth: '106px',
    marginLeft: '4px',
    backgroundColor: theme.palette.neutrals[2],
  },
  disabled: {
    pointerEvents: 'none',
  },
  disabledInput: {
    ...theme.typography.body1,
    textAlign: 'center',
    color: fade(theme.palette.neutrals[6], 0.7),
  },
  usersBox: {
    marginTop: '8px',
    padding: '0px 10px',
    marginBottom: '14px',
  },
}));

const GroupMember = ({ id, name, participant, num_users, timeLimit, max, users }) => {
  const classes = useStyles();
  const dispatch = useContext(TypologyDispatchContext);

  const onClickMember = useCallback(() => {
    if (!participant) {
      dispatch({
        type: 'ADD_NEW_GROUP',
        payload: {
          id,
          noLimit: false,
          num_users: max,
          users,
        },
      });
    } else {
      dispatch({
        type: 'REMOVE_GROUP',
        payload: {
          id,
          noLimit: false,
        },
      });
    }
  }, [dispatch, id, participant, users, max]);

  const onClickTime = useCallback(() => {
    dispatch({
      type: 'UPDATE_GROUP_TIME_LIMIT',
      payload: {
        id,
        value: !timeLimit,
      },
    });
  }, [dispatch, id, timeLimit]);

  const updateNumUsers = useCallback(
    value => {
      if (parseInt(value, 10) === 0) {
        dispatch({
          type: 'REMOVE_GROUP',
          payload: {
            id,
            noLimit: false,
          },
        });
      } else {
        dispatch({
          type: 'UPDATE_GROUP_NUM_USERS',
          payload: {
            id,
            value: value > max ? max : value,
          },
        });
      }
    },
    [id, max, dispatch]
  );

  return (
    <>
      <Box className={classes.mainBox}>
        <Box className={classes.labelBox}>
          <Body1 oneLine>{name}</Body1>
          <BoxCheckbox checked={participant} onClick={onClickMember} />
        </Box>
        <Box className={clsx(classes.limitTimeBox, { [classes.disabled]: !participant })}>
          <Subtitle2 inactive={!participant}>Não possui limite de tempo oratório</Subtitle2>
          <BoxCheckbox checked={timeLimit} onClick={onClickTime} />
        </Box>
        <Box className={clsx(classes.summonBox, { [classes.disabled]: !participant })}>
          <Input
            type="number"
            value={num_users}
            onChange={e => updateNumUsers(e.target.value)}
            classes={{ root: classes.root, input: classes.input, disabled: classes.disabledInput }}
            disableUnderline
            disabled={!participant}
            inputProps={{ min: 0, max }}
            placeholder="0"
          />
        </Box>
      </Box>
      {participant && (
        <Box className={classes.usersBox}>
          <OrganizeTypologyGroup id={id} />
        </Box>
      )}
    </>
  );
};

GroupMember.defaultProps = {
  num_users: '',
};

GroupMember.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  participant: PropTypes.bool.isRequired,
  timeLimit: PropTypes.bool.isRequired,
  num_users: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max: PropTypes.number.isRequired,
  users: PropTypes.array.isRequired,
};

export default GroupMember;
