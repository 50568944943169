import React, { useCallback, useContext, useMemo } from 'react';
import { makeStyles, Box, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import H6 from '../../common/typographys/H6';
import MeetingThemePointEntry from './MeetingThemePointEntry';
import { Droppable, DragDropContext, Draggable } from 'react-beautiful-dnd';
import { MeetingDispatchContext } from '../CreateEditMeetingsProvider';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  label: {
    marginBottom: '2px',
  },
  nameAndArrows: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  arrows: {
    display: 'flex',
    justifyContent: 'center',
    marginleft: '10px',
  },
  buttonArrow: {
    color: theme.palette.neutrals[1],
    backgroundColor: theme.palette.primary.A400,
    '&:hover': {
      backgroundColor: theme.palette.primary.A700,
    },
  },
  disabledButton: {
    backgroundColor: `${theme.palette.primary.A100}!important`,
  },
  marginButton: {
    marginRight: '10px',
  },
}));

// @ Retirado dos Docs do react-beautiful-dnd
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DraggablePoint = ({ point, themeId, themeName, idx, matrices }) => {
  return (
    <Draggable draggableId={`draggable-${point.id}`} index={idx}>
      {provided => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <Box height="8px" />
          <MeetingThemePointEntry
            themeId={themeId}
            themeName={themeName}
            point={point}
            matrices={matrices}
          />
        </div>
      )}
    </Draggable>
  );
};

DraggablePoint.propTypes = {
  point: PropTypes.object.isRequired,
  themeId: PropTypes.number.isRequired,
  themeName: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
  matrices: PropTypes.array.isRequired,
  isGroup: PropTypes.bool.isRequired,
};

const PointsList = ({ points, themeId, themeName, matrices }) => {
  return points.map((point, idx) => (
    <DraggablePoint
      key={point.id}
      point={point}
      idx={idx}
      themeName={themeName}
      themeId={themeId}
      matrices={matrices}
    />
  ));
};

PointsList.propTypes = {
  points: PropTypes.array.isRequired,
  themeId: PropTypes.number.isRequired,
  themeName: PropTypes.string.isRequired,
  matrices: PropTypes.array.isRequired,
};

const MeetingThemeEntry = ({ theme, matrices, idx, length }) => {
  const classes = useStyles();
  const { name, id } = theme;
  const dispatch = useContext(MeetingDispatchContext);

  const onDragEnd = useCallback(
    (result, arr) => {
      if (!result.destination || result.destination.index === result.source.index) {
        return;
      }

      const sortedPoints = reorder(arr, result.source.index, result.destination.index);

      dispatch({
        type: 'UPDATE_THEME_POINTS',
        payload: {
          id,
          points: sortedPoints,
        },
      });
    },
    [dispatch, id]
  );

  const commonProps = useMemo(
    () => ({
      disableFocusRipple: true,
      disableRipple: true,
      classes: { disabled: classes.disabledButton },
      color: 'primary',
      size: 'small',
    }),
    [classes]
  );

  const moveTheme = useCallback(
    (direction, idxTheme) => e => {
      e.preventDefault();

      dispatch({
        type: 'MOVE_THEME',
        payload: {
          oldIndex: idxTheme,
          newIndex: direction === 'up' ? idxTheme - 1 : idxTheme + 1,
        },
      });
    },
    [dispatch]
  );

  return (
    <>
      <Box className={classes.nameAndArrows}>
        <H6 className={classes.label}>{name}</H6>
        <Box className={classes.arrows}>
          <IconButton
            disabled={idx === 0}
            className={clsx(classes.buttonArrow, classes.marginButton)}
            onClick={moveTheme('up', idx)}
            {...commonProps}
          >
            <ArrowUpward />
          </IconButton>
          <IconButton
            disabled={idx === length - 1}
            className={classes.buttonArrow}
            onClick={moveTheme('down', idx)}
            {...commonProps}
          >
            <ArrowDownward />
          </IconButton>
        </Box>
      </Box>
      <DragDropContext onDragEnd={e => onDragEnd(e, theme.points)}>
        <Droppable droppableId="list">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <PointsList themeName={name} themeId={id} points={theme.points} matrices={matrices} />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

MeetingThemeEntry.propTypes = {
  theme: PropTypes.object.isRequired,
  matrices: PropTypes.array.isRequired,
  idx: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
};

export default React.memo(MeetingThemeEntry);
