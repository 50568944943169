import React from 'react';
import Body1 from '../../typographys/Body1';
import PropTypes from 'prop-types';

const SubtitleWithEntries = ({ label, classes, entries }) => {
  return (
    <>
      <Body1 hover className={classes.marginSubTitle}>
        {label}
      </Body1>
      {entries.map(({ name }) => (
        <Body1 key={name} className={classes.marginEntry}>
          {name}
        </Body1>
      ))}
    </>
  );
};

SubtitleWithEntries.defaultProps = {
  entries: [],
};

SubtitleWithEntries.propTypes = {
  label: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  entries: PropTypes.array,
};

export default SubtitleWithEntries;
