import React from 'react';
import { AppBar, Drawer } from '@material-ui/core';
import Sidebar from './sidebar/Sidebar';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ToolbarApp from './ToolbarApp';
import appSchemaStyles from './styles/appSchema';

const SchemaMobile = ({ mobileOpen, handleDrawerToggle, handleLogout }) => {
  const classes = appSchemaStyles();
  const { image, name } = useSelector(state => state.auth.user);

  const picture = (image && image.indexOf('blob') !== -1 && image) || '';

  return (
    <>
      <AppBar position="fixed" className={classes.appBarMobile}>
        <ToolbarApp mobile classes={classes} handleLogout={handleLogout} name={name} logo={picture} handleDrawerToggle={handleDrawerToggle} />
      </AppBar>
      <nav className={classes.drawerMobile} aria-label="Menu lateral">
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Sidebar handleDrawerToggle={handleDrawerToggle} handleLogout={handleLogout} />
        </Drawer>
      </nav>
    </>
  );
};

SchemaMobile.propTypes = {
  mobileOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default SchemaMobile;
