import React, { useContext, useMemo } from 'react';
import { makeStyles, Grid, Box } from '@material-ui/core';
import MeetingDetails from './pieces/MeetingDetails';
import { MeetingStateContext, MeetingDispatchContext } from './CreateEditMeetingsProvider';
import AddMeetingHeader from './pieces/AddMeetingHeader';
import DefaultPoints from './pieces/DefaultPoints';
import DefaultThemes from './pieces/DefaultThemes';
import MeetingUsers from './pieces/MeetingUsers';
import MeetingTypologyPool from './new/pieces/MeetingTypologyPool';
import SubmittedProposals from './pieces/SubmittedProposals';
import CardFilesUpload from '../common/CardFilesUpload';
import CardBlock from '../common/forms/CardBlock';
import NewMeetingSecretaryModerator from './pieces/NewMeetingSecretaryModerator';
import CreateNewPoint from './pieces/CreateNewPoint';
import { Prompt } from 'react-router-dom';
import DialogCheck from '../common/dialogs/DialogCheck';
import PropTypes from 'prop-types';

const DIVIDER_HEIGHT = '20px';

const useStyles = makeStyles(() => ({
  marginContainer: {
    marginTop: '54px',
  },
  label: {
    marginBottom: '6px',
  },
  marginContent: {
    marginTop: '30px',
  },
}));

const FormInfoMeeting = ({ change, newSession }) => {
  const classes = useStyles();
  const dispatch = useContext(MeetingDispatchContext);
  const {
    state: {
      name,
      typology,
      date,
      endDate,
      local,
      errors,
      addToTypology,
      submitting,
      themes = [],
      points = [],
      submitted,
      users = [],
      pool,
      proposals = [],
      files = [],
      sameUsers,
      isGroup,
      isPublic,
      board,
      availableSecretary = [],
      availableModerator = [],
      videoConferenceLink,
      videoConference,
    },
    loading,
    handleSubmit,
    openWarning,
    changePage,
    cancelLeaving,
    confirmChange,
  } = useContext(MeetingStateContext);

  const headerState = useMemo(
    () => ({ errors, sameUsers, submitting, handleSubmit, loading, newSession }),
    [errors, sameUsers, submitting, loading, handleSubmit, newSession]
  );

  const secretaryModeratorState = { board, availableSecretary, availableModerator };

  const usersState = { users, sameUsers, isGroup };

  const detailsState = useMemo(
    () => ({
      name,
      typology,
      date,
      endDate,
      videoConferenceLink,
      videoConference,
      local,
      errors,
      addToTypology,
      isPublic,
      submitted,
    }),
    [
      name,
      typology,
      date,
      endDate,
      videoConferenceLink,
      videoConference,
      local,
      errors,
      addToTypology,
      isPublic,
      submitted,
    ]
  );
  const hasThemes = themes.length > 0;
  const hasPoints = points.length > 0;
  const hasProposals = proposals.length > 0;

  const cancelEdit = e => {
    e.preventDefault();

    return confirmChange('/reunioes');
  };

  return (
    <>
      <Prompt when={change} message={changePage} />
      <Grid container justify="center" className={classes.marginContainer}>
        <Grid item xs={9} md={10} lg={9}>
          <AddMeetingHeader state={headerState} />
          <Grid container className={classes.marginContent} spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container direction="column">
                <MeetingDetails state={detailsState} />
                <NewMeetingSecretaryModerator
                  state={secretaryModeratorState}
                  usersState={usersState}
                />
                <CardFilesUpload state={{ files }} dispatch={dispatch} />
                <Box height={DIVIDER_HEIGHT} />
                {users.length > 0 && <MeetingUsers state={usersState} />}
                {pool.points && pool.points.length > 0 && <MeetingTypologyPool state={{ pool }} />}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <CardBlock label="Temas e pontos de discussão">
                {hasThemes && <DefaultThemes themes={themes} />}
                {hasThemes && <Box height={DIVIDER_HEIGHT} />}
                {hasPoints && <DefaultPoints points={points} />}
                {hasPoints && <Box height={DIVIDER_HEIGHT} />}
                <CreateNewPoint />
                {hasProposals && <Box height={DIVIDER_HEIGHT} />}
                {hasProposals && <SubmittedProposals proposals={proposals} />}
              </CardBlock>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogCheck
        open={openWarning}
        msg="Pretende sair do formulário? Os dados inseridos serão perdidos."
        handleCancel={cancelLeaving}
        handleSubmit={cancelEdit}
        labelSubmit="Sair"
      />
    </>
  );
};

FormInfoMeeting.defaultProps = {
  newSession: false,
};

FormInfoMeeting.propTypes = {
  change: PropTypes.bool.isRequired,
  newSession: PropTypes.bool,
};

export default FormInfoMeeting;
