import React, { useContext } from 'react';
import { UserStateContext, UserDispatchContext } from './CreateEditUserProvider';
import FormInfoUser from './FormInfoUser';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import UpdateItem from '../../pieces/UpdateItem';

const EditUser = () => {
  const {
    state: { errors, items },
    handleSubmit,
    loading,
  } = useContext(UserStateContext);
  const dispatch = useContext(UserDispatchContext);

  return (
    <UpdateItem disableSubmit={checkEmptyObject(errors).length > 0} loading={loading} items={items} dispatch={dispatch} handleSubmit={handleSubmit}>
      <FormInfoUser />
    </UpdateItem>
  );
};

export default EditUser;
