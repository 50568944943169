import React, { useMemo, useCallback, useContext } from 'react';
import Creatable from '../../../../common/forms/Creatable';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SpecialMultiValueLocais from '../../../../common/forms/materialSelects/SpecialMultiValueLocais';
import { TypologyDispatchContext } from '../CreateEditTypologyProvider';

const ValueContainer = props => {
  return (
    <div className={props.selectProps.classes.specialValueContainer}>
      {[props.children[1], props.children[0]]}
    </div>
  );
};

ValueContainer.defaultProps = {
  children: null,
};

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
};

const customComponents = {
  MultiValue: SpecialMultiValueLocais,
  ValueContainer,
};

const TypologyLocals = ({ locals, updateField }) => {
  const { locals: optionsLocals } = useSelector(state => state.locals);
  const dispatch = useContext(TypologyDispatchContext);

  const options = useMemo(
    () =>
      locals
        ? [
            ...optionsLocals.map(option => ({
              id: option.id,
              label: option.name,
              value: option.id,
              name: option.name,
            })),
            ...locals.filter(x => x.isNew),
          ]
        : optionsLocals.map(option => ({
            id: option.id,
            label: option.name,
            value: option.id,
            name: option.name,
          })),
    [optionsLocals, locals]
  );

  const handleCreate = useCallback(
    label => {
      const newOption = {
        label,
        value: Math.floor(Math.random() * 100),
        name: label,
        isNew: true,
      };

      updateField('locals', [...locals, newOption]);
    },
    [updateField, locals]
  );

  return (
    <Creatable
      isMulti
      label="Local(ais)"
      options={options}
      value={locals}
      handleChange={(val, event) => {
        if (event.action === 'deselect-option' && event.option.id) {
          dispatch({
            type: 'LOCAL_REMOVED',
            payload: {
              id: event.option.id,
            },
          });
        } else if (event.action === 'select-option') {
          dispatch({
            type: 'LOCAL_ADDED',
            payload: {
              id: event.option.id,
            },
          });
        } else if (event.action === 'clear') {
          dispatch({
            type: 'LOCALS_CLEARED',
          });
        }
        updateField('locals', val);
      }}
      customComponents={customComponents}
      onCreateOption={handleCreate}
      formatCreateLabel={input => `Criar - ${input}`}
      noOptionsMessage={() => {
        return 'Nenhum local disponível';
      }}
      hideSelectedOptions={false}
    />
  );
};

TypologyLocals.propTypes = {
  locals: PropTypes.array.isRequired,
  updateField: PropTypes.func.isRequired,
};

export default React.memo(TypologyLocals);
