import React from 'react';
import { AppBar, Drawer } from '@material-ui/core';
import Sidebar from './sidebar/Sidebar';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ToolbarApp from './ToolbarApp';
import appSchemaStyles from './styles/appSchema';

const SchemaDesktop = ({ handleLogout }) => {
  const classes = appSchemaStyles();
  const { image, name } = useSelector(state => state.auth.user);

  const picture = (image && image.indexOf('blob') !== -1 && image) || '';

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <ToolbarApp classes={classes} handleLogout={handleLogout} name={name} logo={picture} />
      </AppBar>
      <Drawer
        variant="permanent"
        className={classes.drawer}
        classes={{
          paper: classes.drawer,
        }}
        open
      >
        <Sidebar handleLogout={handleLogout} />
      </Drawer>
    </>
  );
};

SchemaDesktop.propTypes = {
  handleLogout: PropTypes.func.isRequired,
};

export default SchemaDesktop;
