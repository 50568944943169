import React, { useContext, useCallback } from 'react';
import LabelAndHelper from '../../../../common/forms/LabelAndHelper';
import { Box } from '@material-ui/core';
import TimeBox from '../../../pieces/TimeBox';
import PropTypes from 'prop-types';
import { TypologyDispatchContext } from '../CreateEditTypologyProvider';
import NewBoxItem from './NewBoxItem';
import ThemeItem from './ThemeItem';

const TypologyThemes = ({ themes }) => {
  const dispatch = useContext(TypologyDispatchContext);

  const onClickAdd = useCallback(
    name => {
      dispatch({
        type: 'ADD_NEW_THEME',
        payload: {
          name,
          points: [],
        },
      });
    },
    [dispatch]
  );

  return (
    <>
      <LabelAndHelper label="Temas" helper="Defina temas predefinidos para esta tipologia." />
      <Box height="12px" />
      {themes.length > 0 &&
        themes.map((theme, idx) => (
          <React.Fragment key={`tema-${theme.name}`}>
            <TimeBox medium>
              <ThemeItem theme={theme} idx={idx} />
            </TimeBox>
            {idx + 1 !== themes.length && <Box height="4px" />}
          </React.Fragment>
        ))}
      {themes.length > 0 && <Box height="10px" />}
      <TimeBox medium>
        <NewBoxItem onClick={onClickAdd} />
      </TimeBox>
      <Box height="10px" />
    </>
  );
};

TypologyThemes.propTypes = {
  themes: PropTypes.array.isRequired,
};

export default React.memo(TypologyThemes);
