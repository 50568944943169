import React from 'react';
import { Grid, Hidden, makeStyles } from '@material-ui/core';
import BoxChangeDate from './BoxChangeDate';
import ButtonsChangeView from './ButtonsChangeView';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  changeDateToButtonsMargin: {
    marginBottom: theme.spacing(1),
  },
}));

const ContainerWithButtons = ({ date, handleDatePrev, handleDateNext, view, changeView }) => {
  const classes = useStyles();
  const common = { view, changeView };

  return (
    <Grid container justify="space-between" alignItems="center" className={classes.marginBottom}>
      <Grid item xs={12} md={3} lg={3} xl={2}>
        {view !== 'cronologia' && (
          <BoxChangeDate
            date={date}
            view={view}
            handleDatePrev={handleDatePrev}
            handleDateNext={handleDateNext}
          />
        )}
      </Grid>
      <Hidden mdUp>
        <Grid item xs={12} className={classes.changeDateToButtonsMargin} />
      </Hidden>
      <Grid item xs={12} md={8} lg={7} xl={9}>
        <ButtonsChangeView {...common} />
      </Grid>
    </Grid>
  );
};

ContainerWithButtons.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  handleDatePrev: PropTypes.func.isRequired,
  handleDateNext: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  changeView: PropTypes.func.isRequired,
};

export default ContainerWithButtons;
