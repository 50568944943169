import palette from '../palette';

export default {
  root: {
    height: 'auto',
    minHeight: 40,
    '& fieldset': {
      borderRadius: 0,
    },
  },
  input: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    color: palette.neutrals[7],
    '&$disabled': {
      opacity: '0.38',
    },
  },
};
