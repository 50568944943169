import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, makeStyles } from '@material-ui/core';
import Subtitle1 from '../../typographys/Subtitle1';

const useStyles = makeStyles(theme => ({
  hoverSelected: {
    '&:hover > span': {
      color: `${theme.palette.neutrals[6]}!important`,
    },
  },
}));

const Option = props => {
  const classes = useStyles();

  return (
    <MenuItem ref={props.innerRef} selected={props.isSelected} component="div" className={classes.hoverSelected} {...props.innerProps}>
      <Subtitle1 selected={props.isSelected}>{props.children}</Subtitle1>
    </MenuItem>
  );
};

Option.defaultProps = {
  children: null,
  innerProps: null,
  innerRef: null,
  isFocused: null,
  isSelected: null,
};

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
};

export default Option;
