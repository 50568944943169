import React from 'react';
import { makeStyles, Grid, Paper } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  paper: {
    padding: '20px',
    minHeight: '100%',
  },
  pagination: {
    minHeight: 'calc(100% - 52px)',
  },
}));

const InfoSection = ({ children, hasPagination }) => {
  const classes = useStyles();

  return (
    <Grid item xs={7}>
      <Paper className={clsx(classes.paper, { [classes.pagination]: hasPagination })}>{children}</Paper>
    </Grid>
  );
};

InfoSection.defaultProps = {
  children: null,
};

InfoSection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  hasPagination: PropTypes.bool.isRequired,
};

export default InfoSection;
