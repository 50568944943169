import { isDate, parseISO } from 'date-fns';

/**
 *
 * @param {string} val - Data a formatar
 * @returns Formata data recebida para objeto e ser utilizada com o date-fns
 */
const getDateObj = val => {
  if (isDate(val)) {
    return val;
  }

  if (val && parseISO(val)) {
    return parseISO(val);
  }

  return null;
};

export default getDateObj;
