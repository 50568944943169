import { useState, useEffect, useCallback, useRef } from 'react';

const useCalendar = (defaultView = 'dayGridMonth') => {
  const [view, setView] = useState(defaultView);
  const [date, setDate] = useState(new Date());
  const calendarRef = useRef();

  useEffect(() => {
    if (view !== 'cronologia') {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView(view);
    }
  }, [view]);

  const changeView = useCallback(
    value => e => {
      e.preventDefault();
      setView(value);
    },
    []
  );

  const handleDatePrev = () => {
    const calendarApi = calendarRef.current.getApi();

    calendarApi.prev();
    setDate(calendarApi.getDate());
  };

  const handleDateNext = () => {
    const calendarApi = calendarRef.current.getApi();

    calendarApi.next();
    setDate(calendarApi.getDate());
  };

  return [calendarRef, view, date, changeView, handleDatePrev, handleDateNext];
};

export default useCalendar;
