import React from 'react';
import isEmpty from '../../../utils/isEmpty';
import { Dialog, DialogTitle, DialogContent, makeStyles, Grid, Button } from '@material-ui/core';
import H5 from '../../common/typographys/H5';
import formatDate from '../../../utils/formatDate';
import Subtitle1 from '../../common/typographys/Subtitle1';
import H6 from '../../common/typographys/H6';
import Body2 from '../../common/typographys/Body2';
import PropTypes from 'prop-types';
import ButtonNormal from '../../common/buttons/ButtonNormal';

const useStyles = makeStyles(() => ({
  marginTitle: {
    marginBottom: '4px',
  },
  center: {
    justifyContent: 'center',
  },
  buttonEdit: {
    width: '253px',
  },
  padding: {
    padding: '20px',
  },
  warning: {
    marginTop: '8px',
  },
  buttonAdd: {
    marginLeft: '10px',
    minHeight: '44px',
    maxHeight: '44px',
  },
}));

const DialogPlayMeeting = ({ open, handleClose, handleSubmit, event }) => {
  const classes = useStyles();

  const { info = {} } = event;
  const { brief_available } = info;

  return (
    !isEmpty(event) && (
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>
          <H5 className={classes.marginTitle}>
            {event.title ? `Iniciar reunião: ${event.title}` : 'Iniciar reunião'}
          </H5>
          <Subtitle1>{formatDate(event.start, "dd 'de' MMMM 'de' yyyy, HH'h'mm")}</Subtitle1>
        </DialogTitle>
        <DialogContent>
          <H6>Pretende iniciar a reunião?</H6>
          {!brief_available && (
            <Body2 secondary className={classes.warning}>
              A convocatória para esta reunião não foi enviada pela plataforma.
            </Body2>
          )}
        </DialogContent>
        <Grid container justify="center" className={classes.padding}>
          {/* <ButtonNormal variant="outlined" color="primary" label="Cancelar" onClick={handleClose} /> */}
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonEdit}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <ButtonNormal
            contained
            pea
            onClick={handleSubmit}
            label="Iniciar"
            className={classes.buttonAdd}
          />
        </Grid>
      </Dialog>
    )
  );
};

DialogPlayMeeting.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
};

export default React.memo(DialogPlayMeeting);
