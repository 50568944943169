import React from 'react';
import PropTypes from 'prop-types';
import DraggableMember from './DraggableMember';

const GroupMemberList = ({ users, num_users }) => {
  return users.map(({ name, id }, idx) => (
    <DraggableMember key={id} id={id} name={name} idx={idx} num_users={num_users} />
  ));
};

GroupMemberList.propTypes = {
  users: PropTypes.array.isRequired,
  num_users: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default GroupMemberList;
