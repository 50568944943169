import palette from '../palette';

export default {
  root: {
    background: palette.primary[50],
    paddingTop: '12px',
    paddingRight: '10px',
    paddingBottom: '8px',
    paddingLeft: '20px',
    color: palette.primary[900],
    '&$selected': {
      background: palette.primary[900],
      color: palette.neutrals[1],
      '&:hover': {
        background: palette.primary[200],
        color: palette.primary[900],
        '&:hover > .MuiListItemText-root > .MuiListItemText-primary': {
          color: palette.primary[900],
        },
      },
    },
    '&$selected > .MuiListItemText-root > .MuiListItemText-primary': {
      color: palette.neutrals[1],
    },
    '&:hover': {
      background: palette.primary[200],
    },
  },
  button: {
    '&:hover': {
      backgroundColor: `${palette.primary[200]}!important`,
    },
  },
};
