import { CLEAN_ONGOING, REMOVE_REUNION } from './types';

export const cleanOngoingReunions = () => dispatch => {
  dispatch({
    type: CLEAN_ONGOING,
  });
};

export const removeReunion = id => dispatch => {
  dispatch({
    type: REMOVE_REUNION,
    payload: { id },
  });
};
