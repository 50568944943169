import React from 'react';
import PropTypes from 'prop-types';
import CardBlock from '../../common/forms/CardBlock';
import MeetingUsersGroups from './MeetingUsersGroups';
import MeetingUsersList from './MeetingUsersList';
import { Grid, Box } from '@material-ui/core';
import Body2 from '../../common/typographys/Body2';

const MeetingUsers = ({ state, editing }) => {
  const { users, sameUsers, isGroup } = state;

  return (
    <CardBlock label="Participantes">
      {isGroup ? (
        <>
          <MeetingUsersGroups groups={users} editing={editing} />
          {sameUsers.length > 0 && (
            <>
              <Box height="20px" />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {sameUsers.map(user => (
                    <Body2 key={user.id} error>
                      O participante {user.name} está associado a mais do que um grupo.
                    </Body2>
                  ))}
                </Grid>
              </Grid>
            </>
          )}
        </>
      ) : (
        <MeetingUsersList
          users={users.filter(user => user.in_meeting)}
          allUsers={users}
          editing={editing}
        />
      )}
    </CardBlock>
  );
};

MeetingUsers.defaultProps = {
  editing: false,
};

MeetingUsers.propTypes = {
  state: PropTypes.object.isRequired,
  editing: PropTypes.bool,
};

export default React.memo(MeetingUsers);
