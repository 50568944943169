import React, { useContext, useEffect } from 'react';
import AddNewItem from '../../pieces/AddNewItem';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import { UserDispatchContext, UserStateContext } from './CreateEditUserProvider';
import FormInfoUser from './FormInfoUser';

const CreateNewUser = () => {
  const {
    state: { errors, items },
    loading,
    handleCreateUser,
  } = useContext(UserStateContext);
  const dispatch = useContext(UserDispatchContext);

  useEffect(() => {
    dispatch({
      type: 'CLEAR_STATE',
    });
  }, [dispatch]);

  return (
    <AddNewItem
      disableSubmit={checkEmptyObject(errors).length > 0}
      items={items}
      loading={loading}
      dispatch={dispatch}
      handleSubmit={handleCreateUser}
      labelSubmit="Criar"
      msgSubmit="Tem a certeza que deseja criar o novo utilizador?"
    >
      <FormInfoUser isCreateNew />
    </AddNewItem>
  );
};

export default React.memo(CreateNewUser);
