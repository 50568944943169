import React, { useEffect, useState, useMemo, useCallback, useReducer } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from '../../../../utils/isEmpty';
import {
  updateRegisterInfo,
  clearErrors,
  registerUser,
} from '../../../../store/actions/authActions';
import loginRegisterStyles from '../../../common/layout/styles/loginRegister';
import clsx from 'clsx';
import FooterForm from './FooterForm';
import ButtonsForm from './ButtonsForm';
import validPhone from '../../../../utils/validation/validPhone';
import validEmail from '../../../../utils/validation/validEmail';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import phoneToBackend from '../../../../utils/validation/phoneToBackend';
import InputField from '../../../common/forms/InputField';
import { getPublicOrganicUnits } from '../../../../store/actions/organicUnitsActions';
import SelectOutlined from '../../../common/forms/SelectOutlined';
import validateNIF from '../../../../utils/validateNIF';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import LoadingSpinner from '../../../common/LoadingSpinner';

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'SET_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    case 'SET_ERRORS':
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
};

const FormRegister = () => {
  const dispatchRedux = useDispatch();
  const classes = loginRegisterStyles();
  const location = useLocation();
  const { organicUnits } = useSelector(stateRedux => stateRedux.organicUnits);
  const errorsReducer = useSelector(stateRedux => stateRedux.errors);
  const { loading } = useSelector(stateRedux => stateRedux.auth);
  const [submitted, setSubmitted] = useState(false);
  const { search } = location;
  const parsedSearch = useMemo(() => queryString.parse(search), [search]);
  const [state, dispatch] = useReducer(reducer, {
    name: '',
    email: '',
    phone: '',
    password: '',
    checkPassword: '',
    organicUnit: null,
    role: '',
    cc: '',
    nif: '',
    disableEmail: false,
    errors: {},
  });

  const updateField = useCallback((name, value) => {
    dispatch({ type: 'UPDATE_FIELD', payload: { name, value } });
  }, []);

  const setError = useCallback(value => {
    dispatch({ type: 'SET_ERROR', payload: value });
  }, []);

  const setErrors = useCallback(value => {
    dispatch({ type: 'SET_ERRORS', payload: value });
  }, []);

  const {
    disableEmail,
    name,
    email,
    phone,
    password,
    checkPassword,
    role,
    organicUnit,
    cc,
    nif,
    errors,
  } = state;

  useEffect(() => {
    dispatchRedux(getPublicOrganicUnits());
  }, [dispatchRedux]);

  useEffect(() => {
    if (!isEmpty(parsedSearch) && !isEmpty(parsedSearch.email) && validEmail(parsedSearch.email)) {
      updateField('email', parsedSearch.email);
      updateField('disableEmail', true);
    }
  }, [parsedSearch, updateField]);

  // * Efeito para remover erros após inserção novamente dos dados
  useEffect(() => {
    if (submitted && name.length < 3 && isEmpty(errors.name)) {
      setError({ name: 'Insira um nome válido' });
    } else if (submitted && name.length >= 3) {
      setError({ name: '' });
    }
  }, [name, errors.name, submitted, setError]);

  useEffect(() => {
    if (submitted && !validEmail(email) && isEmpty(errors.email)) {
      setError({ email: 'Insira um email válido' });
    } else if (submitted && validEmail(email) && isEmpty(errorsReducer.email)) {
      setError({ email: '' });
    }
  }, [email, errors.email, submitted, errorsReducer.email, setError]);

  useEffect(() => {
    if (submitted && !validPhone(phone) && isEmpty(errors.phone)) {
      setError({ phone: 'Insira um contacto telefónico válido' });
    } else if (submitted && validPhone(phone) && isEmpty(errorsReducer.phone)) {
      setError({ phone: '' });
    }
  }, [phone, errors.phone, submitted, errorsReducer.phone, setError]);

  useEffect(() => {
    if (submitted && password.length < 6 && isEmpty(errors.password)) {
      setError({ password: 'Insira uma palavra-passe válida' });
    } else if (submitted && password.length >= 6 && password !== checkPassword) {
      setError({
        password: 'As palavras-passe não são iguais',
        checkPassword: 'As palavras-passe não são iguais',
      });
    } else if (submitted && password.length >= 6 && password === checkPassword) {
      setError({ password: '', checkPassword: '' });
    }
  }, [password, errors.password, checkPassword, submitted, setError]);

  useEffect(() => {
    if (submitted && isEmpty(organicUnit) && isEmpty(errors.organicUnit)) {
      setError({ organicUnit: 'Insira uma Unidade Orgânica válida' });
    } else if (submitted && !isEmpty(organicUnit)) {
      setError({ organicUnit: '' });
    }
  }, [organicUnit, errors.organicUnit, submitted, setError]);

  useEffect(() => {
    if (submitted && role.length < 3 && isEmpty(errors.role)) {
      setError({ role: 'Insira um Cargo válido' });
    } else if (submitted && role.length >= 3) {
      setError({ role: '' });
    }
  }, [role, errors.role, submitted, setError]);

  useEffect(() => {
    if (
      submitted &&
      cc.replace(/\s+/g, '').length !== 8 &&
      cc.replace(/\s+/g, '').length !== 12 &&
      isEmpty(errors.cc)
    ) {
      setError({ cc: 'Insira um Número de Identificação válido' });
    } else if (
      submitted &&
      (cc.replace(/\s+/g, '').length === 8 || cc.replace(/\s+/g, '').length === 12) &&
      isEmpty(errorsReducer.cc)
    ) {
      setError({ cc: '' });
    }
  }, [cc, errors.cc, errorsReducer.cc, submitted, setError]);

  useEffect(() => {
    if (submitted && !validateNIF(nif) && isEmpty(errors.nif)) {
      setError({ nif: 'Insira um NIF válido' });
    } else if (submitted && validateNIF(nif) && isEmpty(errorsReducer.nif)) {
      setError({ nif: '' });
    }
  }, [nif, errors.nif, errorsReducer.nif, submitted, setError]);
  // * Fim dos efeitos

  // * Atualiza os errors conforme os erros presentes no reducer de erros
  useEffect(() => {
    if (!isEmpty(errorsReducer)) {
      const localErrors = {};
      if (errorsReducer.email) {
        localErrors.email = 'Já existe um utilizador com este email';
      }

      if (errorsReducer.nif) {
        localErrors.nif = 'Já existe um utilizador com este NIF';
      }

      if (errorsReducer.cc) {
        localErrors.cc = 'Já existe um utilizador com este CC';
      }

      setErrors(localErrors);
    }
  }, [errorsReducer, setErrors, dispatchRedux]);

  // * Função executada após o clique para avançar
  const submitRegister = e => {
    e.preventDefault();
    const localErrors = {};
    dispatchRedux(clearErrors());

    if (name.length < 3) {
      localErrors.name = 'Insira um nome válido';
    } else {
      localErrors.name = '';
    }

    if (!validEmail(email)) {
      localErrors.email = 'Insira um email válido';
    } else {
      localErrors.email = '';
    }

    if (!validPhone(phone)) {
      localErrors.phone = 'Insira um contacto telefónico válido';
    } else {
      localErrors.phone = '';
    }

    if (!validateNIF(nif)) {
      localErrors.nif = 'Insira um NIF válido';
    } else {
      localErrors.nif = '';
    }

    if (password.length < 8) {
      localErrors.password = 'Insira uma palavra-passe válida';
    } else {
      localErrors.password = '';
    }

    if (password.length >= 8 && password !== checkPassword) {
      localErrors.password = 'As palavras-passe não são iguais';
      localErrors.checkPassword = 'As palavras-passe não são iguais';
    } else {
      localErrors.password = '';
      localErrors.checkPassword = '';
    }

    if (role.length < 3) {
      localErrors.role = 'Insira um Cargo válido';
    } else {
      localErrors.role = '';
    }

    if (cc.replace(/\s+/g, '').length !== 8 && cc.replace(/\s+/g, '').length !== 12) {
      localErrors.cc = 'Insira um Número de Identificação válido';
    } else {
      localErrors.cc = '';
    }

    if (checkEmptyObject(localErrors).length !== 0) {
      setSubmitted(true);
      return setErrors(localErrors);
    }

    const info = {
      step: '2',
      name,
      email,
      password,
      phone: phoneToBackend(phone),
      role,
      cc,
      nif,
    };

    if (!isEmpty(organicUnit)) {
      info.organicUnitId = organicUnit.id;
    }

    const registerPromise = new Promise((resolve, reject) => {
      dispatchRedux(registerUser(info, resolve, reject));
    });

    return registerPromise
      .then(() => {
        return dispatchRedux(updateRegisterInfo(info));
      })
      .catch(() => {
        return dispatchRedux({
          type: 'SHOW_SNACK',
          payload: {
            variant: 'error',
            message: 'Erro ao efetuar registo.',
          },
        });
      });
  };

  return (
    <Grid container className={classes.fullHeight}>
      <Grid item xl={8} xs={10} className={clsx([classes.marginHorizontal], [classes.flexColumn])}>
        <Typography variant="h6" className={classes.labelMarginRegister}>
          Registo
        </Typography>
        <InputField
          value={name}
          error={errors.name}
          onChange={e => updateField(e.target.name, e.target.value)}
          label="Nome"
          name="name"
          placeholder="Insira o seu nome"
        />
        <Grid container spacing={1}>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <InputField
              value={email}
              error={errors.email}
              onChange={e => updateField(e.target.name, e.target.value)}
              label="Email"
              type="email"
              name="email"
              placeholder="email@exemplo.pt"
              disabled={disableEmail}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <InputField
              value={phone}
              error={errors.phone}
              onChange={e => updateField(e.target.name, e.target.value)}
              label="Contacto telefónico"
              type="tel"
              name="phone"
              placeholder="+351 --- --- ---"
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <InputField
              value={password}
              error={errors.password}
              onChange={e => updateField(e.target.name, e.target.value)}
              label="Palavra-passe"
              type="password"
              name="password"
              placeholder="Insira a sua palavra-passe"
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <InputField
              value={checkPassword}
              error={errors.checkPassword}
              onChange={e => updateField(e.target.name, e.target.value)}
              label="Confirmar palavra-passe"
              type="password"
              name="checkPassword"
              placeholder="Confirme a sua palavra-passe"
            />
          </Grid>
        </Grid>
        <SelectOutlined
          value={organicUnit}
          error={errors.organicUnit}
          onChange={val => updateField('organicUnit', val)}
          label="Unidade Orgânica"
          name="organicUnit"
          placeholder="Unidade Orgânica à qual pertence"
          options={organicUnits}
          getOptionValue={option => option.id}
          getOptionLabel={option => `${option.abbreviation || option.code} - ${option.name}`}
        />
        <Box height="20px" />
        <InputField
          value={role}
          error={errors.role}
          onChange={e => updateField(e.target.name, e.target.value)}
          label="Cargo"
          name="role"
          placeholder="Insira o seu cargo"
        />
        <Grid container spacing={1}>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <InputField
              value={cc}
              error={errors.cc}
              onChange={e => updateField(e.target.name, e.target.value)}
              label="Número de Identificação Civil"
              name="cc"
              placeholder="Insira o seu número do CC"
            />
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <InputField
              value={nif}
              error={errors.nif}
              onChange={e => updateField(e.target.name, e.target.value)}
              label="Número de Identificação Fiscal"
              name="nif"
              placeholder="Insira o seu NIF"
            />
          </Grid>
        </Grid>
        <ButtonsForm handleSubmit={submitRegister} submitDisabled={loading} />
        <FooterForm />
      </Grid>
      {loading && <LoadingSpinner />}
    </Grid>
  );
};

export default FormRegister;
