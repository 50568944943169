const getStatusBySlug = slug => {
  if (
    slug === 'flow_approved' ||
    slug === 'approved_no_am' ||
    slug === 'approved' ||
    slug === 'approved_pending_am'
  ) {
    return 'approved';
  }

  if (
    slug === 'failed' ||
    slug === 'flow_approved_meeting_failed' ||
    slug === 'approved_failed_am' ||
    slug === 'meeting_cancelled' ||
    slug === 'removed'
  ) {
    return 'disapproved';
  }

  return 'standby';
};

export default getStatusBySlug;
