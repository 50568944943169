import palette from '../palette';

export default {
  root: {
    paddingTop: '12px',
    paddingRight: '20px',
    paddingBottom: '8px',
    paddingLeft: '20px',
    color: palette.primary[900],
  },
};
