import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.neutrals[1],
    backgroundColor: theme.palette.primary.A400,
    '&:hover': {
      color: theme.palette.neutrals[1],
      backgroundColor: theme.palette.primary.A700,
    },
    width: '280px',
    height: '40px!important',
  },
  icon: {
    marginLeft: '10px',
    height: '16px',
    width: '16px',
  },
}));

const ButtonPrimaryIcon = ({ onClick, label, icon: Icon, ...props }) => {
  const classes = useStyles();

  return (
    <Button variant="contained" className={classes.button} onClick={onClick} {...props}>
      {label}
      <Icon className={classes.icon} />
    </Button>
  );
};

ButtonPrimaryIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
};

export default ButtonPrimaryIcon;
