import React from 'react';
import { TablePagination, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  caption: {
    ...theme.typography.subtitle1,
  },
  actions: {
    '& button': {
      padding: 0,
      color: theme.palette.primary[400],
    },
  },
}));

const Pagination = ({ total, onChangePage, page, rowsPerPage }) => {
  const classes = useStyles();

  return (
    <TablePagination
      classes={classes}
      component="div"
      count={total}
      onChangePage={onChangePage}
      page={page}
      labelRowsPerPage=""
      rowsPerPageOptions={[]}
      rowsPerPage={rowsPerPage}
      labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} de ${count}`}
    />
  );
};

Pagination.propTypes = {
  total: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default Pagination;
