import React, { useEffect, useReducer, useCallback, useState } from 'react';
import { Grid, Box, Button, Typography } from '@material-ui/core';
import InputField from '../../../common/forms/InputField';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../../../store/actions/authActions';
import loginRegisterStyles from '../../../common/layout/styles/loginRegister';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import isEmpty from '../../../../utils/isEmpty';
import LoadingSpinner from '../../../common/LoadingSpinner';

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'SET_ERRORS':
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
};

const FormReset = () => {
  const [state, dispatch] = useReducer(reducer, {
    email: '',
    password: '',
    checkPassword: '',
    token: '',
    errors: {},
  });
  const location = useLocation();
  const dispatchRedux = useDispatch();
  const classes = loginRegisterStyles();
  const { loading } = useSelector(stateRedux => stateRedux.auth);
  const [submitted, setSubmitted] = useState(false);
  const { email, password, checkPassword, token, errors } = state;

  const updateField = useCallback((name, value) => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: { name, value },
    });
  }, []);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    updateField('email', parsed.email);
    updateField('token', parsed.token);
  }, [location, updateField]);

  useEffect(() => {
    if (submitted && password.length < 6 && isEmpty(errors.password)) {
      dispatch({ type: 'SET_ERRORS', payload: { password: 'Insira uma palavra-passe válida' } });
    } else if (submitted && password.length >= 6 && password !== checkPassword) {
      dispatch({
        type: 'SET_ERRORS',
        payload: {
          password: 'As palavras-passe não são iguais',
          checkPassword: 'As palavras-passe não são iguais',
        },
      });
    } else if (submitted && password.length >= 6 && password === checkPassword) {
      dispatch({ type: 'SET_ERRORS', payload: { password: '', checkPassword: '' } });
    }
  }, [submitted, password, errors.password, checkPassword]);

  const submitLogin = useCallback(
    e => {
      e.preventDefault();
      setSubmitted(true);
      const localErrors = {};

      if (password.length < 8) {
        localErrors.password = 'Insira uma palavra-passe válida';
      } else {
        localErrors.password = '';
      }

      if (password.length >= 8 && password !== checkPassword) {
        localErrors.password = 'As palavras-passe não são iguais';
        localErrors.checkPassword = 'As palavras-passe não são iguais';
      } else {
        localErrors.password = '';
        localErrors.checkPassword = '';
      }

      if (checkEmptyObject(localErrors).length !== 0) {
        return dispatch({
          type: 'SET_ERRORS',
          payload: localErrors,
        });
      }

      const info = {
        token,
        email,
        password,
      };

      return dispatchRedux(resetPassword(info));
    },
    [dispatchRedux, checkPassword, password, email, token]
  );

  const fields = [
    {
      label: 'Email',
      name: 'email',
      margin: 'none',
      type: 'email',
      disabled: true,
    },
    {
      label: 'Palavra-passe',
      name: 'password',
      margin: 'none',
      type: 'password',
    },
    {
      label: 'Confirmar palavra-passe',
      name: 'checkPassword',
      margin: 'none',
      type: 'password',
    },
  ];

  return (
    <Grid container className={classes.fullHeightLogin}>
      <Grid item lg={6} xs={10} className={clsx([classes.marginHorizontal], [classes.flexColumn])}>
        <Typography variant="h6" className={classes.labelMargin}>
          Repor palavra-passe
        </Typography>
        {fields.map(item => (
          <InputField
            key={item.name}
            onChange={e => updateField(item.name, e.target.value)}
            value={state[item.name]}
            error={errors[item.name]}
            {...item}
          />
        ))}
        <Box width="100%">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={submitLogin}
            className={classes.buttonLogin}
            disabled={loading}
          >
            Repor palavra-passe
          </Button>
        </Box>
        {/* <Box marginBottom={8} className={classes.marginTop} display="flex" justifyContent="center">
          <Typography variant="button" color="textSecondary">
            Ainda não tem conta?{' '}
            <Link to="/registo" className={classes.cleanLink}>
              Registe-se aqui
            </Link>
          </Typography>
        </Box> */}
      </Grid>
      {loading && <LoadingSpinner />}
    </Grid>
  );
};

export default FormReset;
