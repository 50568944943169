import axios from 'axios';
import {
  GET_PROFILES,
  GET_PROFILE,
  CREATE_PROFILE,
  UPDATE_PROFILE,
  GET_ERRORS,
  SHOW_SNACK,
  PROFILES_LOADING,
  PROFILES_NOT_LOADING,
  REMOVE_PROFILE,
} from './types';

export const getProfiles = () => dispatch => {
  dispatch(setProfilesLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/profiles`)
    .then(res => {
      dispatch({
        type: GET_PROFILES,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(setProfilesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getProfile = id => dispatch => {
  dispatch(setProfilesLoading());

  axios
    .get(`${process.env.REACT_APP_API_URL}/api/profiles/${id}`)
    .then(res => {
      dispatch({
        type: GET_PROFILE,
        payload: res.data,
      });
    })
    .catch(err => {
      dispatch(setProfilesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const createProfile = info => dispatch => {
  dispatch(setProfilesLoading());

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/profiles`, info)
    .then(res => {
      dispatch({
        type: CREATE_PROFILE,
        payload: { ...res.data, can_delete: true },
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Perfil criado com sucesso.',
        },
      });
    })
    .catch(err => {
      dispatch(setProfilesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const updateProfile = (id, info) => dispatch => {
  dispatch(setProfilesLoading());

  axios
    .put(`${process.env.REACT_APP_API_URL}/api/profiles/${id}`, info)
    .then(res => {
      dispatch({
        type: UPDATE_PROFILE,
        payload: res.data,
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Perfil atualizado com sucesso.',
        },
      });
    })
    .catch(err => {
      dispatch(setProfilesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const removeProfile = (id, callback) => dispatch => {
  dispatch(setProfilesLoading());

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/profiles/${id}/deactivate`)
    .then(() => {
      dispatch({
        type: REMOVE_PROFILE,
        payload: {
          id,
        },
      });
      dispatch({
        type: SHOW_SNACK,
        payload: {
          variant: 'success',
          message: 'Perfil excluído com sucesso.',
        },
      });
      callback();
    })
    .catch(err => {
      dispatch(setProfilesNotLoading());
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const setProfilesLoading = () => {
  return {
    type: PROFILES_LOADING,
  };
};

export const setProfilesNotLoading = () => {
  return {
    type: PROFILES_NOT_LOADING,
  };
};
