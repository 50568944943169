export default {
  input: {
    padding: '8px 10px',
  },
  multiline: {
    padding: '6px 0 7px',
  },
  inputMultiline: {
    padding: '8px 10px',
  },
};
