import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import useCommonStyles from './useCommonStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.body2,
  },
  secondary: {
    color: `${theme.palette.primary.A700}!important`,
    fontWeight: '500!important',
  },
  active: {
    color: `${theme.palette.neutrals[7]}!important`,
  },
  hoverable: {
    transition: 'color 0.1s ease',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.A700,
    },
  },
}));

const Body2 = ({
  children,
  center,
  right,
  secondary,
  active,
  white,
  oneLine,
  className,
  hoverable,
  printMultiline,
  error,
  approved,
  disapproved,
  bold,
  ...props
}) => {
  const classes = useStyles();
  const common = useCommonStyles();

  return (
    <Typography
      variant="body2"
      classes={{ root: classes.root }}
      className={clsx({
        [common.center]: center,
        [common.right]: right,
        [common.white]: white,
        [classes.secondary]: secondary,
        [classes.active]: active,
        [common.wrapEllipsis]: oneLine,
        [common.approved]: approved,
        [common.disapproved]: error || disapproved,
        [common.bold]: bold,
        [classes.hoverable]: hoverable,
        [classes.printMultiline]: printMultiline,
        [className]: className.length > 0,
      })}
      {...props}
    >
      {children}
    </Typography>
  );
};

Body2.defaultProps = {
  center: false,
  right: false,
  secondary: false,
  active: false,
  white: false,
  oneLine: false,
  hoverable: false,
  error: false,
  printMultiline: false,
  approved: false,
  disapproved: false,
  bold: false,
  className: '',
};

Body2.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]).isRequired,
  center: PropTypes.bool,
  right: PropTypes.bool,
  secondary: PropTypes.bool,
  active: PropTypes.bool,
  white: PropTypes.bool,
  oneLine: PropTypes.bool,
  hoverable: PropTypes.bool,
  error: PropTypes.bool,
  printMultiline: PropTypes.bool,
  approved: PropTypes.bool,
  disapproved: PropTypes.bool,
  bold: PropTypes.bool,
  className: PropTypes.string,
};

export default Body2;
