import React, { useContext } from 'react';
import { TypologyStateContext, TypologyDispatchContext } from './CreateEditTypologyProvider';
import FormInfoTypology from './FormInfoTypology';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import UpdateItem from '../../pieces/UpdateItem';

const EditTypology = () => {
  const {
    state: { errors, items },
    handleSubmit,
    loading,
  } = useContext(TypologyStateContext);
  const dispatch = useContext(TypologyDispatchContext);

  return (
    <UpdateItem disableSubmit={checkEmptyObject(errors).length > 0} loading={loading} items={items} dispatch={dispatch} handleSubmit={handleSubmit}>
      <FormInfoTypology />
    </UpdateItem>
  );
};

export default EditTypology;
