import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import isEmpty from '../../../../utils/isEmpty';

const inputComponent = ({ inputRef, ...props }) => {
  return <div ref={inputRef} {...props} />;
};

inputComponent.defaultProps = {
  inputRef: null,
};

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

const Control = props => {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps, error },
  } = props;

  return (
    <TextField
      error={!isEmpty(error)}
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
};

Control.defaultProps = {
  children: null,
  innerProps: null,
  innerRef: null,
};

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
};

export default Control;
