import {
  UPDATE_CARD_INFO,
  CLEAN_CARD_INFO,
  CARD_LOADING,
  CARD_NOT_LOADING,
  NOT_VALID_READ,
  VALID_READ,
} from '../actions/types';

const initialState = {
  nr_cc: '',
  nome: '',
  nif: '',
  loading: false,
  valid: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CARD_INFO:
      return {
        ...state,
        ...action.payload,
        loading: false,
        valid: true,
      };
    case CLEAN_CARD_INFO:
      return {
        ...state,
        nr_cc: '',
        nome: '',
        nif: '',
        valid: false,
      };
    case CARD_LOADING:
      return {
        ...state,
        loading: true,
      };
    case NOT_VALID_READ:
      return {
        ...state,
        valid: false,
      };
    case VALID_READ:
      return {
        ...state,
        valid: true,
      };
    case CARD_NOT_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
