import { CHANGE_MENU } from '../actions/types';

const initialState = {
  sidebar: 'dashboard',
  text: 'Dashboard',
  secondLevel: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_MENU:
      return {
        ...state,
        sidebar: action.payload.sidebar,
        text: action.payload.text,
        secondLevel: action.payload.secondLevel,
      };
    default:
      return state;
  }
};
