import palette from '../palette';

export default {
  root: {
    width: 'unset',
  },
  img: {
    width: 'unset',
  },
  colorDefault: {
    backgroundColor: palette.primary[50],
  },
};
