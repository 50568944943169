const chunkArray = (array, chunkSize) => {
  const aux = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    aux.push(array.slice(i, i + chunkSize));
  }

  return aux;
};

export default chunkArray;
