import React, { useCallback, useContext } from 'react';
import { makeStyles, Grid, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import EntryBox from './EntryBox';
import Body1 from '../../common/typographys/Body1';
import { Delete } from '@material-ui/icons';
import SelectOutlined from '../../common/forms/SelectOutlined';
import { MeetingDispatchContext } from '../CreateEditMeetingsProvider';
import Subtitle1 from '../../common/typographys/Subtitle1';
import formatDate from '../../../utils/formatDate';
import isEmpty from '../../../utils/isEmpty';

const useStyles = makeStyles(theme => ({
  mainBox: {
    display: 'flex',
  },
  iconDelete: {
    fill: theme.palette.neutrals[4],
    '&:hover': {
      cursor: 'pointer',
      fill: theme.palette.colorsPalette.RustRed,
    },
  },
  pointBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  centerAlign: {
    display: 'flex',
    alignItems: 'center',
  },
  fullWidth: {
    width: '100%',
  },
}));

const MeetingThemePointEntry = ({ themeId, themeName, point, matrices }) => {
  const classes = useStyles();
  const { id, name, matrix, identifier_code, date } = point;
  const dispatch = useContext(MeetingDispatchContext);
  const showMatrices = matrices && !!matrices.length;

  const removeThemePoint = useCallback(
    infoPoint => e => {
      e.preventDefault();
      dispatch({
        type: 'REMOVE_THEME_POINT',
        payload: {
          point: infoPoint,
          theme: {
            id: themeId,
            name: themeName,
          },
        },
      });
    },
    [dispatch, themeId, themeName]
  );

  const updateMatrix = useCallback(
    (matrixVal, pointId) => {
      dispatch({
        type: 'UPDATE_THEME_POINT_MATRIX',
        payload: {
          matrix: matrixVal,
          themeId,
          pointId,
        },
      });
    },
    [dispatch, themeId]
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={showMatrices ? 8 : 12}>
        <EntryBox noMargin className={classes.pointBox}>
          <Box>
            <Body1 component="p">{identifier_code ? `${identifier_code} - ${name}` : name}</Body1>
            {!isEmpty(date) && <Subtitle1>{formatDate(date)}</Subtitle1>}
          </Box>
          <Delete className={classes.iconDelete} onClick={removeThemePoint(point)} />
        </EntryBox>
      </Grid>
      {showMatrices && (
        <Grid item xs={4} className={classes.centerAlign}>
          <SelectOutlined
            className={classes.fullWidth}
            name={`matrix-${id}`}
            value={matrix}
            onChange={val => updateMatrix(val, point.id)}
            options={matrices}
            placeholder="Atribuir matriz"
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            isClearable
          />
        </Grid>
      )}
    </Grid>
  );
};

MeetingThemePointEntry.propTypes = {
  themeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  themeName: PropTypes.string.isRequired,
  point: PropTypes.object.isRequired,
  matrices: PropTypes.array.isRequired,
};

export default React.memo(MeetingThemePointEntry);
