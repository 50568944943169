import React, { useContext, useEffect, useCallback } from 'react';
import InputField from '../../../common/forms/InputField';
import Subtitle2 from '../../../common/typographys/Subtitle2';
import MultiInputSelect from '../../../common/forms/MultiInputSelect';
import { GroupStateContext, GroupDispatchContext } from './CreateEditGroupProvider';
import OrganizeParticipants from './pieces/OrganizeParticipants';
import SpecialMultiValue from '../../../common/forms/materialSelects/SpecialMultiValue';
import { useSelector } from 'react-redux';
import { makeStyles, Box } from '@material-ui/core';
import CheckboxPicker from '../../../common/forms/CheckboxPicker';

const otherComponents = {
  MultiValue: SpecialMultiValue,
};

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: '6px',
  },
}));

const FormInfoGroup = () => {
  const classes = useStyles();
  const {
    state: { name, showFirst, participants, idsParticipants, errors },
  } = useContext(GroupStateContext);
  const dispatch = useContext(GroupDispatchContext);
  const { users } = useSelector(reduxState => reduxState.users);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'CLEAR_STATE',
      });
    };
  }, [dispatch]);

  const updateField = useCallback(
    (nameInput, value) => {
      dispatch({
        type: 'UPDATE_FIELD',
        payload: {
          name: nameInput,
          value,
        },
      });
    },
    [dispatch]
  );

  return (
    <>
      <InputField
        label="Nome"
        name="name"
        value={name}
        onChange={e => updateField('name', e.target.value)}
        error={errors.name}
      />
      <CheckboxPicker
        onClick={() => updateField('showFirst', !showFirst)}
        checked={showFirst}
        label="Exibir grupo no topo da lista"
      />
      <Box height="20px" />
      <Subtitle2 className={classes.label}>Utilizadores</Subtitle2>
      <MultiInputSelect
        value={participants}
        placeholder="Selecionar os utilizadores"
        options={users}
        getOptionLabel={option => `${option.name} <${option.email}>`}
        getOptionValue={option => option.id}
        handleChange={(val, { action, option }) => {
          if (option && idsParticipants.find(x => x === option.id) !== undefined) {
            dispatch({
              type: action === 'deselect-option' ? 'REMOVE_USER' : 'CANCEL_REMOVE_USER',
              payload: {
                id: option.id,
              },
            });
          }
          updateField('participants', val);
        }}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        otherComponents={otherComponents}
        error={errors.participants}
        helperText={errors.participants || ''}
      />
      {participants && participants.length > 1 && (
        <OrganizeParticipants participants={participants} dispatch={dispatch} />
      )}
    </>
  );
};

export default FormInfoGroup;
