import {
  GET_USERS,
  GET_USER,
  GET_USER_PROFILE_PERMISSIONS,
  UPDATE_USER,
  UPDATE_USER_PROFILE,
  USERS_LOADING,
  USERS_NOT_LOADING,
  CREATE_USER,
  REMOVE_USER,
  UPDATE_USER_PERMISSIONS,
  UPDATE_USER_ORGANIC_UNITS,
  UPDATE_USER_ORGANIC_UNIT,
  GET_USERS_SIMPLIFY,
} from '../actions/types';

const initialState = {
  users: [],
  usersSimplify: [],
  user: { profile_id: {} },
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case GET_USERS_SIMPLIFY:
      return {
        ...state,
        usersSimplify: action.payload,
        loading: false,
      };
    case GET_USER:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case GET_USER_PROFILE_PERMISSIONS:
      return {
        ...state,
        user: {
          ...state.user,
          permissions: { ...action.payload.permissions, changed_profile: true },
        },
      };
    case CREATE_USER:
      return {
        ...state,
        users: [action.payload, ...state.users],
        loading: false,
      };
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        users: state.users.map(x =>
          x.id === action.payload.idUser ? { ...x, ...action.payload } : x
        ),
        loading: false,
      };
    case UPDATE_USER:
      return {
        ...state,
        users: state.users.map(x =>
          x.id === action.payload.idUser ? { ...x, ...action.payload } : x
        ),
        loading: false,
      };
    case UPDATE_USER_ORGANIC_UNITS:
      return {
        ...state,
        users: state.users.map(x =>
          x.id === action.payload.idUser
            ? { ...x, organic_units: { ...action.payload.organicUnits } }
            : x
        ),
        loading: false,
      };
    case UPDATE_USER_ORGANIC_UNIT:
      return {
        ...state,
        users: state.users.map(x =>
          x.id === action.payload.idUser ? { ...x, organic_unit_id: action.payload.id } : x
        ),
        loading: false,
      };
    case UPDATE_USER_PERMISSIONS:
      return {
        ...state,
        users: state.users.map(x =>
          x.id === action.payload.idUser ? { ...x, permissions: action.payload.permissions } : x
        ),
        loading: false,
      };
    case REMOVE_USER:
      return {
        ...state,
        users: state.users.filter(x => x.id !== action.payload.id),
        loading: false,
      };
    case USERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USERS_NOT_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
