import React, { useEffect, useContext, useCallback } from 'react';
import SeeItem from '../../pieces/SeeItem';
import PropTypes from 'prop-types';
import LabelAndValue from '../../../common/forms/LabelAndValue';
import { ThemeDispatchContext } from './CreateEditThemeProvider';
import EditTheme from './EditTheme';
import { ConfigStateContext, ConfigDispatchContext } from '../../MenuConfigProvider';

const SeeEditTheme = ({ info }) => {
  const dispatchConfig = useContext(ConfigDispatchContext);
  const dispatch = useContext(ThemeDispatchContext);
  const { editing } = useContext(ConfigStateContext);

  const updateField = useCallback(
    (name, value) => {
      dispatch({
        type: 'UPDATE_FIELD',
        payload: {
          name,
          value,
        },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    updateField('name', info.name);
  }, [info.name, updateField]);

  useEffect(() => {
    updateField('isPublic', Boolean(info.is_public));
  }, [info.is_public, updateField]);

  useEffect(() => {
    updateField('id', info.id);
  }, [info.id, updateField]);

  const startEdit = useCallback(
    e => {
      e.preventDefault();
      dispatchConfig({
        type: 'SET_EDITING',
      });
    },
    [dispatchConfig]
  );

  return editing ? (
    <EditTheme />
  ) : (
    <SeeItem submitEdit={startEdit}>
      <LabelAndValue label="Nome" value={info.name} />
      <LabelAndValue label="Disponível" value={info.is_public ? 'Sim' : 'Não'} />
    </SeeItem>
  );
};

SeeEditTheme.propTypes = {
  info: PropTypes.object.isRequired,
};

export default React.memo(SeeEditTheme);
