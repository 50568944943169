/**
 *
 * @description Update one item from localStorage
 * @param {string} item - Item name to obtain from localStorage
 * @param {object} info - Values to set in localStorage
 * @returns
 */
const replaceItemFromStorage = (item, info) => {
  return info !== undefined ? localStorage.setItem(item, JSON.stringify(info)) : null;
};

export default replaceItemFromStorage;
