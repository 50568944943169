import React from 'react';
import { makeStyles, Divider } from '@material-ui/core';
import BoxHeaderFeatures from '../../../pieces/BoxHeaderFeatures';
import RadioPicker from '../../../pieces/RadioPicker';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: '12px',
    marginBottom: '12px',
  },
}));

const TypologyType = ({ type, updateField, error }) => {
  const classes = useStyles();

  return (
    <BoxHeaderFeatures header="Natureza da tipologia" error={error}>
      <RadioPicker onClick={() => updateField('type', 'individual')} checked={type === 'individual'} label="Utilizadores individuais" />
      <Divider className={classes.divider} />
      <RadioPicker onClick={() => updateField('type', 'grupo')} checked={type === 'grupo'} label="Grupos" />
    </BoxHeaderFeatures>
  );
};

TypologyType.defaultProps = {
  error: null,
};

TypologyType.propTypes = {
  type: PropTypes.string.isRequired,
  updateField: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default React.memo(TypologyType);
