import React from 'react';
import io from 'socket.io-client';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { CREATED_REUNION, UPDATE_CREATED } from '../../../store/actions/types';
import { setActualReunion } from '../../../store/actions/reunionsActions';

export const SocketReunion = React.createContext();

let socketNsp;

const cleanSocket = () => {
  socketNsp = null;
};

const SocketReunionWrapper = ({ children, plateia }) => {
  const { isEmailSet } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  if (!socketNsp && (isEmailSet || plateia)) {
    socketNsp = io(`${process.env.REACT_APP_SOCKET_URL}/reunions`);

    socketNsp.on('created_reunion', info => {
      dispatch({
        type: CREATED_REUNION,
        payload: JSON.parse(info),
      });
    });

    socketNsp.on('update_created', info => {
      dispatch({
        type: UPDATE_CREATED,
        payload: JSON.parse(info),
      });
    });

    socketNsp.on('begin_reunion', info => {
      dispatch(setActualReunion(JSON.parse(info)));
    });

    // socketNsp.on('end_preparation', info => {
    //   const parsed = JSON.parse(info);
    //   dispatch({
    //     type: 'REMOVE_REUNION',
    //     payload: {
    //       id: parsed.id,
    //     },
    //   });
    //   dispatch({
    //     type: 'SHOW_SNACK',
    //     payload: {
    //       variant: 'warning',
    //       message: 'O início desta reunião foi cancelado.',
    //     },
    //   });
    // });
  }

  return (
    <SocketReunion.Provider value={{ socketNsp, cleanSocket }}>{children}</SocketReunion.Provider>
  );
};

SocketReunionWrapper.defaultProps = {
  plateia: false,
};

SocketReunionWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  plateia: PropTypes.bool,
};

export default SocketReunionWrapper;
