import React, { useCallback, useMemo } from 'react';
import { makeStyles, Grid, Box, Paper, fade } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ReactComponent as Against } from '../../../../assets/images/whiteAgainstVote.svg';
import { ReactComponent as Favor } from '../../../../assets/images/whiteFavorVote.svg';
import { ReactComponent as Abstention } from '../../../../assets/images/whiteAbstentionVote.svg';
import { ReactComponent as NoVote } from '../../../../assets/images/whiteNoVote.svg';
import { ReactComponent as AbstentionV2 } from '../../../../assets/images/abstentionPlateiaV2.svg';
import { ReactComponent as SecretCheck } from '../../../../assets/images/checkPlateiaBig.svg';
import { ReactComponent as SecretAgainst } from '../../../../assets/images/againstPlateiaBig.svg';
import isEmpty from '../../../../utils/isEmpty';
import H5 from '../../../common/typographys/H5';
import clsx from 'clsx';
import useCustomSlider from '../../../../utils/hooks/useCustomSlider';
import BoxSlider from './BoxSlider';
import H4 from '../../../common/typographys/H4';
import H6 from '../../../common/typographys/H6';
import H2 from '../../../common/typographys/H2';
import H3 from '../../../common/typographys/H3';
import { getTotalSecretVotes } from '../MeetingModerator';

const SHOW_MD = 3;
const SHOW_LG = 6;
const SHOW_XL = 12;
const SLIDE_TIME = 7000;

const useStyles = makeStyles(theme => ({
  mainBox: {
    padding: '26px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  noVote: {
    padding: '34px 20px',
    backgroundColor: theme.palette.neutrals[1],
    boxShadow: '0 20px 20px -10px rgba(204, 216, 214, 0.7)',
  },
  margin: {
    marginRight: '10px',
  },
  favor: {
    backgroundColor: theme.palette.colorsPalette.TrueGreen,
  },
  against: {
    backgroundColor: theme.palette.colorsPalette.RustRed,
  },
  abstention: {
    backgroundColor: theme.palette.colorsPalette.Orange,
  },
  novote: {
    backgroundColor: theme.palette.colorsPalette.TrueBlue,
  },
  voteBox: {
    width: '50px',
  },
  paperSecret: {
    width: '100%',
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      marginTop: '50px',
      marginBottom: '40px',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '30px',
      marginBottom: '20px',
    },
  },
  heightBox: {
    [theme.breakpoints.up('lg')]: {
      height: '40px',
    },
    [theme.breakpoints.down('md')]: {
      height: '20px',
    },
  },
  heightBox2: {
    [theme.breakpoints.up('lg')]: {
      height: '60px',
    },
    [theme.breakpoints.down('md')]: {
      height: '20px',
    },
  },
  borderFavor: {
    border: `solid 1px ${theme.palette.colorsPalette.TrueGreen}`,
  },
  borderAgainst: {
    border: `solid 1px ${theme.palette.colorsPalette.RustRed}`,
  },
  bgFavor: {
    backgroundColor: theme.palette.colorsPalette.TrueGreen,
    '& *': {
      fill: '#fff',
      color: '#fff',
    },
  },
  bgAgainst: {
    backgroundColor: theme.palette.colorsPalette.RustRed,
    '& *': {
      fill: '#fff',
      color: '#fff',
    },
  },
  mainBoxVote: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      minHeight: '356px',
    },
    [theme.breakpoints.down('md')]: {
      minHeight: '234px',
    },
    width: '100%',
    justifyContent: 'space-around',
  },
  abstentionBox: {
    backgroundColor: fade(theme.palette.neutrals[3], 0.3),
    [theme.breakpoints.up('lg')]: {
      padding: '40px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '20px 0px 25px 0px',
    },
    width: '100%',
  },
  countIcon: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
    '& :last-child': {
      marginLeft: '30px',
    },
  },
  itemLabel: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  winning: {
    fill: theme.palette.neutrals[1],
  },
  labelItem: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '20px',
    },
  },
}));

const VoteIcon = ({ vote }) => {
  switch (vote) {
    case 'favor':
      return <Favor />;
    case 'against':
      return <Against />;
    case 'abstention':
      return <Abstention />;
    default:
      return <NoVote />;
  }
};

const BoxVote = ({ type, count, winning }) => {
  const classes = useStyles();
  const label = useMemo(() => {
    switch (type) {
      case 'favor':
        return 'A favor';
      case 'against':
        return 'Contra';
      case 'abstention':
        return 'Abstenções';
      default:
        return 'Escusa de voto';
    }
  }, [type]);

  // TODO: acabar a linha da votação de abstenção com Grid container sem ser Boxmeeting
  return type !== 'abstention' ? (
    <Box
      className={clsx(classes.mainBoxVote, {
        [classes.borderFavor]: type === 'favor',
        [classes.borderAgainst]: type === 'against',
        [classes.bgFavor]: type === 'favor' && winning === 'favor',
        [classes.bgAgainst]: type === 'against' && winning === 'against',
      })}
    >
      <H5 inactive={!(type === winning)} secondary={type === winning}>
        {label}
      </H5>
      <H2 secondary={type === winning}>{count}</H2>
      {type === 'favor' && <SecretCheck />}
      {type === 'against' && <SecretAgainst />}
    </Box>
  ) : (
    <Box className={classes.abstentionBox}>
      <Grid container justify="space-between">
        <Grid item xs={12} lg={4} className={classes.labelItem}>
          <H6 tertiary>{label}</H6>
        </Grid>
        <Grid item xs={12} lg={4} className={classes.countIcon}>
          <H3 tertiary>{count}</H3>
          <AbstentionV2 />
        </Grid>
      </Grid>
    </Box>
  );
};

const SecretVoteDisplay = ({ secretVotes /* , maxVotes */ }) => {
  const classes = useStyles();

  const total = useMemo(() => {
    return getTotalSecretVotes(secretVotes);
  }, [secretVotes]);

  const winning = useMemo(() => {
    const favor = parseInt(secretVotes.favor, 10) || 0;
    const against = parseInt(secretVotes.against, 10) || 0;
    if (favor > against) {
      return 'favor';
    }

    if (favor < against) {
      return 'against';
    }

    return 'tie';
  }, [secretVotes]);

  return (
    <Paper elevation={0} className={classes.paperSecret}>
      <Grid container justify="center" className={classes.container}>
        <Grid item md={10}>
          <H4 center>Votos</H4>
          <Box height="10px" />
          {/* <H5 center inactive>{`${total}/${maxVotes}`}</H5> */}
          <H5 center inactive>
            Contabilizados: {total}
          </H5>
          <Box className={classes.heightBox} />
          <Grid container spacing={3}>
            <Grid item md={12} lg={6}>
              <BoxVote type="favor" winning={winning} count={secretVotes.favor} />
            </Grid>
            <Grid item md={12} lg={6}>
              <BoxVote type="against" winning={winning} count={secretVotes.against} />
            </Grid>
            <Grid item md={12}>
              <BoxVote type="abstention" winning={winning} count={secretVotes.abstention} />
            </Grid>
            <Grid item md={12}>
              <BoxVote type="novote" winning={winning} count={secretVotes.novote} />
            </Grid>
          </Grid>
          <Box className={classes.heightBox2} />
          <H5 center inactive>
            Votação secreta
          </H5>
        </Grid>
      </Grid>
    </Paper>
  );
};

const VotingUsersPlateia = ({ participants, votes, secretVotes, typeVote /* , maxVotes */ }) => {
  const classes = useStyles();
  const [participantsChunked, curIndex] = useCustomSlider(
    participants,
    SHOW_MD,
    SHOW_LG,
    SHOW_XL,
    SLIDE_TIME
  );
  const getVote = useCallback(
    id => {
      return votes.find(x => x.id === id || x.id.toString() === id.toString()) || {};
    },
    [votes]
  );

  return (
    <>
      {typeVote !== 'secret' && (
        <>
          {participantsChunked[curIndex] &&
            participantsChunked[curIndex].map(participant => {
              const vote = getVote(participant.id);

              return (
                <Grid key={participant.id} item lg={6} md={12} className={classes.itemUser}>
                  <Box
                    className={clsx(classes.mainBox, {
                      [classes.favor]: !isEmpty(vote) && vote.vote === 'favor',
                      [classes.against]: !isEmpty(vote) && vote.vote === 'against',
                      [classes.abstention]: !isEmpty(vote) && vote.vote === 'abstention',
                      [classes.novote]: !isEmpty(vote) && vote.vote === 'novote',
                      [classes.noVote]: isEmpty(vote),
                    })}
                  >
                    <H5
                      secondary={!isEmpty(vote)}
                      inactive={isEmpty(vote)}
                      className={classes.margin}
                      oneLine
                    >
                      {participant.name}
                    </H5>
                    {!isEmpty(vote) && (
                      <Box className={classes.voteBox}>
                        <VoteIcon vote={vote.vote} />
                      </Box>
                    )}
                  </Box>
                </Grid>
              );
            })}
          {participantsChunked.length > 1 && (
            <BoxSlider curIndex={curIndex} length={participantsChunked.length} />
          )}
        </>
      )}
      {typeVote === 'secret' && <SecretVoteDisplay secretVotes={secretVotes} />}
      {/* {typeVote === 'secret' && <SecretVoteDisplay secretVotes={secretVotes} maxVotes={maxVotes} />} */}
    </>
  );
};

VotingUsersPlateia.defaultProps = {
  typeVote: null,
};

VotingUsersPlateia.propTypes = {
  participants: PropTypes.array.isRequired,
  votes: PropTypes.array.isRequired,
  typeVote: PropTypes.string,
  secretVotes: PropTypes.object.isRequired,
  // maxVotes: PropTypes.number.isRequired,
};

SecretVoteDisplay.propTypes = {
  secretVotes: PropTypes.object.isRequired,
  // maxVotes: PropTypes.number.isRequired,
};

BoxVote.propTypes = {
  type: PropTypes.string.isRequired,
  winning: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

VoteIcon.propTypes = {
  vote: PropTypes.string.isRequired,
};

export default React.memo(VotingUsersPlateia);
