import React, { useEffect, useReducer, useCallback, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from '../../../../utils/isEmpty';
import { clearErrors } from '../../../../store/actions/authActions';
import { createProfile, updateProfile } from '../../../../store/actions/profilesActions';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import { ConfigStateContext } from '../../MenuConfigProvider';

export const ProfileDispatchContext = React.createContext();
export const ProfileStateContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'SET_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    case 'UPDATE_PERMISSION':
      return {
        ...state,
        permissions: {
          ...state.permissions,
          [action.payload.group]: {
            ...state.permissions[action.payload.group],
            [action.payload.label]: action.payload.value,
          },
        },
      };
    case 'CLEAR_STATE':
      return {
        ...state,
        name: '',
        id: '',
        permissions: {
          typologies: { create: false, edit: false, delete: false },
          meetings: { create: false, edit: false, delete: false, invite: false, show_all: false, secretary: false, moderator: false },
          flows: { create: false },
          proposals: { create: true, edit: false, delete: false, decide: false },
          presentations: { presenter_view: false, redaction_view: false },
          others: { locals: false, profiles: false, configs: false, groups: false, matrices: false, entities: false },
          users: { create: false, invite: false, edit_permissions: false, edit_status: false },
        },
        panel: '',
        errors: {},
      };
    default:
      return state;
  }
};

const CreateEditProfileProvider = ({ children, items }) => {
  const errorsReducer = useSelector(state => state.errors);
  const { loading } = useSelector(state => state.entities);
  const dispatchRedux = useDispatch();
  const { editing } = useContext(ConfigStateContext);
  const [state, dispatch] = useReducer(reducer, {
    name: '',
    permissions: {
      typologies: { create: false, edit: false, delete: false },
      meetings: { create: false, edit: false, delete: false, invite: false, show_all: false, secretary: false, moderator: false },
      flows: { create: false },
      proposals: { create: true, edit: false, delete: false, decide: false },
      presentations: { presenter_view: false, redaction_view: false },
      others: { locals: false, profiles: false, configs: false, groups: false, matrices: false, entities: false },
      users: { create: false, invite: false, edit_permissions: false, edit_status: false },
    },
    id: '',
    panel: '',
    items: [],
    errors: {},
  });
  const [sameName, setSameName] = useState('');
  const { name, id, permissions, errors } = state;

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: 'items',
        value: items,
      },
    });
  }, [items]);

  useEffect(() => {
    if (errorsReducer.name) {
      dispatch({
        type: 'SET_ERROR',
        payload: { name: 'Já existe um perfil com este nome.' },
      });
      dispatchRedux(clearErrors());
      setSameName(name);
    }
  }, [errorsReducer, name, dispatchRedux]);

  useEffect(() => {
    if (!isEmpty(name) && !isEmpty(errors.name) && name !== sameName) {
      dispatch({
        type: 'SET_ERROR',
        payload: { name: '' },
      });
    }
  }, [name, errors.name, sameName]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      const localErrors = {};

      if (isEmpty(name)) {
        localErrors.name = 'Insira um nome válido para o perfil.';
      }

      if (checkEmptyObject(localErrors).length > 0) {
        return dispatch({
          type: 'SET_ERROR',
          payload: localErrors,
        });
      }

      const infoProfile = {
        name,
        permissions,
      };

      if (editing) {
        return dispatchRedux(updateProfile(id, infoProfile));
      }

      return dispatchRedux(createProfile(infoProfile));
    },
    [dispatchRedux, permissions, editing, id, name]
  );

  const contextState = useMemo(() => ({ state, handleSubmit, loading }), [state, handleSubmit, loading]);

  return (
    <ProfileDispatchContext.Provider value={dispatch}>
      <ProfileStateContext.Provider value={contextState}>{children}</ProfileStateContext.Provider>
    </ProfileDispatchContext.Provider>
  );
};

CreateEditProfileProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  items: PropTypes.array.isRequired,
};

export default CreateEditProfileProvider;
