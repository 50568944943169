import React, { useContext, useCallback, useEffect } from 'react';
import SeeItem from '../../pieces/SeeItem';
import PropTypes from 'prop-types';
import { ConfigStateContext, ConfigDispatchContext } from '../../MenuConfigProvider';
import EditGeneralConfig from './EditGeneralConfig';
import { GeneralConfigFuncsContext } from './CreateEditGeneralConfigProvider';
import GeneralInfo from './subTabs/GeneralInfo';
import ProposalStatuses from './subTabs/ProposalStatuses';
import EmailsTemplates from './subTabs/EmailsTemplates';
import PresidentInfo from './subTabs/PresidentInfo';

const SeeEditGeneralConfig = ({ info }) => {
  const dispatchConfig = useContext(ConfigDispatchContext);
  const { editing } = useContext(ConfigStateContext);
  const { updateField } = useContext(GeneralConfigFuncsContext);

  const startEdit = useCallback(
    e => {
      e.preventDefault();
      dispatchConfig({
        type: 'SET_EDITING',
      });
    },
    [dispatchConfig]
  );

  useEffect(() => {
    if (info.type === 'proposals') {
      updateField('statuses', info.content.statuses);
    }

    if (info.type === 'templates') {
      updateField('templates', info.content.templates);
    }

    if (info.type === 'general') {
      updateField('general', info.content.configs);
    }

    if (info.type === 'president') {
      updateField('president', info.content.userPresident);
    }

    updateField('type', info.type);
  }, [info, updateField]);

  return editing ? (
    <EditGeneralConfig info={info} />
  ) : (
    <SeeItem submitEdit={startEdit}>
      {info.type === 'general' && <GeneralInfo content={info.content} />}
      {info.type === 'proposals' && <ProposalStatuses content={info.content} />}
      {info.type === 'templates' && <EmailsTemplates content={info.content} />}
      {info.type === 'president' && <PresidentInfo content={info.content} />}
    </SeeItem>
  );
};

SeeEditGeneralConfig.propTypes = {
  info: PropTypes.object.isRequired,
};

export default React.memo(SeeEditGeneralConfig);
