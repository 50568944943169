import React, { useMemo } from 'react';
import { makeStyles, Box, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import Subtitle1 from './typographys/Subtitle1';
import { AttachFile, RemoveCircle, CreateNewFolder, GetApp } from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  box: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    border: `solid 1px ${theme.palette.neutrals[3]}`,
    marginRight: '14px',
    marginBottom: '8px',
    maxWidth: '100%',
    maxHeight: '40px',
  },
  attachmentIcon: {
    marginRight: '8px',
    transform: 'scale(0.9)',
    color: theme.palette.neutrals[5],
  },
  deleteIcon: {
    marginLeft: '8px',
    transform: 'scale(0.8)',
    color: theme.palette.neutrals[4],
    transition: 'color 0.2s ease',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.error.main,
    },
  },
  downloadIcon: {
    marginLeft: '8px',
    transform: 'scale(0.8)',
    color: theme.palette.neutrals[4],
    transition: 'color 0.2s ease',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.A700,
    },
  },
  label: {
    textDecoration: 'underline',
  },
  changeBG: {
    backgroundColor: theme.palette.neutrals[2],
  },
  flex: {
    display: 'flex',
  },
  onlyDownload: {
    '&:hover': {
      cursor: 'pointer',
      '& *': {
        transition: 'color 0.2s ease',
        color: theme.palette.primary.A700,
        pointerEvents: 'none',
      },
    },
  },
  blinkAnimation: {
    pointerEvents: 'none',
    animation: `$pulseAnimation 1.5s ${theme.transitions.easing.easeInOut} infinite`,
  },
  '@keyframes pulseAnimation': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.2,
    },
    '100%': {
      opacity: 1,
    },
  },
  disabled: {
    pointerEvents: 'none',
    opacity: '0.4',
  },
}));

const FileBox = ({
  file,
  remove,
  deleteFile,
  download,
  downloadFile,
  both,
  changeBG,
  attach,
  attachFile,
  blink,
  disabled,
}) => {
  const classes = useStyles();

  const actions = useMemo(() => {
    return (
      <>
        {both && (
          <>
            <RemoveCircle className={classes.deleteIcon} onClick={deleteFile(file.name)} />
            {file.id && <GetApp className={classes.downloadIcon} onClick={downloadFile(file)} />}
          </>
        )}
        {download && !both && (
          <GetApp className={classes.downloadIcon} onClick={downloadFile(file)} />
        )}
        {attach && (
          <Tooltip title="Anexar também à proposta" placement="top">
            <span className={classes.flex}>
              <CreateNewFolder className={classes.downloadIcon} onClick={attachFile(file.name)} />
            </span>
          </Tooltip>
        )}
        {remove && <RemoveCircle className={classes.deleteIcon} onClick={deleteFile(file.name)} />}
      </>
    );
  }, [classes, both, file, deleteFile, download, downloadFile, attach, remove, attachFile]);

  const onlyDownload = useMemo(() => download && !attach && !both && !remove, [
    download,
    attach,
    both,
    remove,
  ]);

  return (
    <Box
      className={clsx(classes.box, {
        [classes.changeBG]: changeBG,
        [classes.onlyDownload]: onlyDownload,
        [classes.blinkAnimation]: blink,
        [classes.disabled]: disabled,
      })}
      onClick={onlyDownload ? downloadFile(file) : () => null}
    >
      <AttachFile className={classes.attachmentIcon} />
      <Subtitle1 oneLine className={classes.label}>
        {file.name}
      </Subtitle1>
      {actions}
    </Box>
  );
};

FileBox.defaultProps = {
  both: false,
  download: false,
  downloadFile: () => null,
  deleteFile: () => null,
  changeBG: false,
  attach: false,
  remove: false,
  blink: false,
  disabled: false,
  attachFile: () => null,
};

FileBox.propTypes = {
  file: PropTypes.object.isRequired,
  deleteFile: PropTypes.func,
  download: PropTypes.bool,
  downloadFile: PropTypes.func,
  both: PropTypes.bool,
  changeBG: PropTypes.bool,
  attach: PropTypes.bool,
  remove: PropTypes.bool,
  blink: PropTypes.bool,
  disabled: PropTypes.bool,
  attachFile: PropTypes.func,
};

export default React.memo(FileBox);
