import React from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import isEmpty from '../../../utils/isEmpty';
import Subtitle2 from '../typographys/Subtitle2';

const useStyles = makeStyles(() => ({
  textField: {
    marginTop: '6px',
    marginBottom: '20px',
    borderRadius: 0,
  },
}));

const InputField = ({ value, fullWidth, label, onChange, margin, variant, type, error, helperText, placeholder, ...props }) => {
  const classes = useStyles();

  return (
    <>
      {label && <Subtitle2 color={!isEmpty(error) ? 'error' : 'initial'}>{label}</Subtitle2>}
      <TextField
        fullWidth={fullWidth}
        value={value}
        onChange={onChange}
        margin={margin}
        variant={variant}
        type={type}
        error={!isEmpty(error)}
        helperText={!isEmpty(error) ? error : helperText || null}
        placeholder={label || placeholder}
        className={classes.textField}
        {...props}
      />
    </>
  );
};

InputField.defaultProps = {
  fullWidth: true,
  placeholder: null,
  margin: 'normal',
  variant: 'outlined',
  type: 'text',
  error: null,
  helperText: null,
  label: null,
};

InputField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.string,
  helperText: PropTypes.string,
};

export default React.memo(InputField);
