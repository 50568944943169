import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import Subtitle2 from '../../common/typographys/Subtitle2';

const useStyles = makeStyles(theme => ({
  boxHeaderPool: {
    backgroundColor: theme.palette.neutrals[4],
    padding: '12px 10px',
    marginTop: '30px',
  },
  poolContent: {
    border: `solid 1px ${theme.palette.neutrals[3]}`,
    padding: '12px 10px 10px 10px',
  },
}));

const MeetingPoolBox = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.boxHeaderPool}>
        <Subtitle2>Propostas da tipologia</Subtitle2>
      </Box>
      <Box className={classes.poolContent}>{children}</Box>
    </>
  );
};

MeetingPoolBox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default React.memo(MeetingPoolBox);
