import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

export const ConfigDispatchContext = React.createContext();
export const ConfigStateContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_ITEM':
      return {
        ...state,
        activeItem: action.payload,
        isAddNew: false,
        editing: false,
        // deleting: false,
      };
    case 'UPDATE_ACTIVE_ITEM':
      return {
        ...state,
        activeItem: {
          ...state.activeItem,
          ...action.payload,
        },
      };
    case 'ADD_NEW_ITEM':
      return {
        ...state,
        activeItem: null,
        isAddNew: true,
        editing: false,
        alternate: action.payload && action.payload.altCreate,
      };
    case 'CANCEL_ADD_NEW':
      return {
        ...state,
        activeItem: null,
        isAddNew: false,
        editing: false,
        deleting: false,
        alternate: false,
      };
    case 'SET_EDITING':
      return {
        ...state,
        editing: true,
        isAddNew: false,
      };
    case 'CANCEL_EDITING':
      return {
        ...state,
        activeItem: null,
        editing: false,
        isAddNew: false,
      };
    case 'CANCEL_DELETING':
      return {
        ...state,
        activeItem: null,
        isAddNew: false,
        editing: false,
        deleting: false,
      };
    case 'SUAVE_CANCEL_DELETING':
      return {
        ...state,
        isAddNew: false,
        editing: false,
        deleting: false,
      };
    case 'DELETING':
      return {
        ...state,
        deleting: true,
      };
    default:
      return state;
  }
};

const MenuConfigProvider = ({ children, activeIndex }) => {
  const [state, dispatch] = useReducer(reducer, {
    activeItem: null,
    isAddNew: false,
    editing: false,
    deleting: false,
  });

  useEffect(() => {
    dispatch({
      type: 'CANCEL_ADD_NEW',
    });
  }, [activeIndex]);

  return (
    <ConfigDispatchContext.Provider value={dispatch}>
      <ConfigStateContext.Provider value={state}>{children}</ConfigStateContext.Provider>
    </ConfigDispatchContext.Provider>
  );
};

MenuConfigProvider.defaultProps = {
  activeIndex: null,
};

MenuConfigProvider.propTypes = {
  activeIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default MenuConfigProvider;
