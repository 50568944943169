import React from 'react';
import { Menu } from '@material-ui/core';
import PropTypes from 'prop-types';

const CustomMenu = ({ children, anchorEl, onClose, menuListProps, ...props }) => {
  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      getContentAnchorEl={null}
      MenuListProps={{ disablePadding: true, ...menuListProps }}
      PaperProps={{ style: { background: '#FFFFFF', transition: 'unset' } }}
      {...props}
    >
      {children}
    </Menu>
  );
};

CustomMenu.defaultProps = {
  anchorEl: null,
  menuListProps: {},
};

CustomMenu.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  onClose: PropTypes.func.isRequired,
  menuListProps: PropTypes.object,
  anchorEl: PropTypes.object,
};

export default CustomMenu;
