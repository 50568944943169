import React, { useMemo } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import InputField from '../../../common/forms/InputField';
import SelectOutlined from '../../../common/forms/SelectOutlined';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  multiline: {
    paddingTop: '4px',
  },
}));

const commonProps = {
  fullWidth: true,
  isClearable: true,
  getOptionValue: option => option.id,
  getOptionLabel: option => option.name,
};

const NameThemeDescription = ({ state, updateField }) => {
  const classes = useStyles();
  const { themes } = useSelector(stateRedux => stateRedux.themes);
  const { users } = useSelector(stateRedux => stateRedux.users);
  const { user } = useSelector(stateRedux => stateRedux.auth);
  const { name, theme, description, proposal_number, on_behalf_of, errors } = state;

  const councilorUsers = useMemo(() => {
    return users.filter(platformUser => platformUser.id !== user.id && platformUser.councilor);
  }, [users, user]);

  return (
    <>
      <SelectOutlined
        {...commonProps}
        value={on_behalf_of}
        options={councilorUsers}
        label="Proposta submetida em nome de:"
        placeholder="Selecionar utilizador"
        onChange={val => updateField('on_behalf_of', val)}
        name="on_behalf_of"
        noOptionsMessage={() => 'Nenhum utilizador disponível'}
      />
      <Box height="20px" />
      <InputField
        multiline
        rows="1"
        rowsMax="3"
        label="Nome da proposta"
        name="name"
        value={name}
        onChange={e => updateField('name', e.target.value)}
        error={errors.name}
      />
      <SelectOutlined
        {...commonProps}
        value={theme}
        options={themes}
        label="Tema"
        placeholder="Associar proposta a tema"
        onChange={val => updateField('theme', val)}
        name="theme"
        noOptionsMessage={() => 'Nenhum tema disponível'}
      />
      <Box height="20px" />
      <InputField
        multiline
        rows="1"
        rowsMax="1"
        label="Número da proposta (Nº a atribuir pelo gabinete de apoio ao Executivo)"
        name="proposal_number"
        value={proposal_number}
        onChange={e => updateField('proposal_number', e.target.value)}
        error={errors.proposal_number}
      />
      <InputField
        multiline
        value={description}
        rows="4"
        rowsMax="15"
        label="Enquadramento"
        name="name"
        error={errors.description}
        onChange={e => updateField('description', e.target.value)}
        InputProps={{
          classes: { inputMultiline: classes.multiline },
        }}
      />
    </>
  );
};

NameThemeDescription.propTypes = {
  state: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
};

export default React.memo(NameThemeDescription);
