import React from 'react';
import { Checkbox, Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  checkbox: {
    zIndex: 2,
    width: '16px',
    height: '16px',
    border: `solid 1px ${theme.palette.neutrals[5]}`,
    '&:hover': {
      backgroundColor: `${theme.palette.neutrals[4]}!important`,
      border: `solid 1px ${theme.palette.neutrals[4]}`,
    },
  },
  checkboxChecked: {
    width: '16px',
    height: '16px',
    backgroundColor: theme.palette.primary.A400,
  },
  checkGreen: {
    backgroundColor: `${theme.palette.colorsPalette.TrueGreen}!important`,
  },
}));

const CustomCheckbox = ({ name, checked, onChange, value, checkGreen }) => {
  const classes = useStyles();

  return (
    <Checkbox
      icon={<Box className={classes.checkbox} />}
      checkedIcon={<Box className={clsx(classes.checkboxChecked, { [classes.checkGreen]: checkGreen })} />}
      name={name}
      checked={checked}
      onChange={onChange}
      value={value}
      className="checkbox"
      disableRipple
    />
  );
};

CustomCheckbox.defaultProps = {
  checkGreen: false,
};

CustomCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  checkGreen: PropTypes.bool,
};

export default CustomCheckbox;
