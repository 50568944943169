import { SOCKET_REUNIONS, CLEAN_ONGOING, CREATED_REUNION, UPDATE_CREATED, REMOVE_REUNION } from '../actions/types';

const initialState = {
  reunions: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SOCKET_REUNIONS:
      return {
        ...state,
        reunions: action.payload,
      };
    case CREATED_REUNION:
      return {
        ...state,
        reunions: [...state.reunions, action.payload],
      };
    case UPDATE_CREATED:
      return {
        ...state,
        reunions: state.reunions.map(x => (x.id === action.payload.id ? { ...action.payload } : x)),
        reunion: action.payload,
      };
    case REMOVE_REUNION:
      return {
        ...state,
        reunions: state.reunions.filter(x => x.id.toString() !== action.payload.id.toString()),
      };
    // ! TODO: APENAS PARA DEV
    case CLEAN_ONGOING:
      return {
        ...state,
        reunions: [],
      };
    default:
      return state;
  }
};
