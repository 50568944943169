import React, { useMemo, useContext, useEffect, useCallback, useRef } from 'react';
import { makeStyles, Grid, Box, Button, fade } from '@material-ui/core';
import H5 from '../../common/typographys/H5';
import Subtitle4 from '../../common/typographys/Subtitle4';
import MeetingDetails from '../pieces/MeetingDetails';
import {
  MeetingStateContext,
  MeetingDispatchContext,
  MeetingFuncsContext,
} from '../CreateEditMeetingsProvider';
import MeetingUsers from '../pieces/MeetingUsers';
import ButtonNormal from '../../common/buttons/ButtonNormal';
import CardFilesUpload from '../../common/CardFilesUpload';
import MeetingPoolPointsThemes from '../pieces/MeetingPoolPointsThemes';
import { useParams, Prompt } from 'react-router-dom';
import NewMeetingSecretaryModerator from '../pieces/NewMeetingSecretaryModerator';
import { useDispatch } from 'react-redux';
import {
  downloadMeetingAgendaTemplate,
  downloadMeetingBriefFile,
  downloadMeetingPublicNoticeTemplate,
  downloadMeetingPublicNoticeFile,
} from '../../../store/actions/meetingsActions';
import CardBlock from '../../common/forms/CardBlock';
import FileBox from '../../common/FileBox';
import clsx from 'clsx';
import { AddCircleOutlined } from '@material-ui/icons';
import isEmpty from '../../../utils/isEmpty';
import PropTypes from 'prop-types';
import DialogCheck from '../../common/dialogs/DialogCheck';

const useStyles = makeStyles(theme => ({
  marginHeader: {
    marginBottom: '30px',
  },
  marginContainer: {
    marginTop: '54px',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  box: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonAdd: {
    marginLeft: '10px',
  },
  agendaButton: {
    marginTop: '10px',
    marginBottom: '20px',
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  boxUpload: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.neutrals[1],
    boxShadow: `0 10px 20px -10px ${fade(theme.palette.primary[100], 0.7)}`,
  },
  padding: {
    padding: '13px',
  },
  icon: {
    color: theme.palette.neutrals[4],
    marginLeft: '10px',
    width: '16px',
    height: '16px',
  },
  buttonText: {
    color: theme.palette.neutrals[5],
  },
  root: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
      '& span': {
        transition: 'color 0.2s ease',
        color: theme.palette.neutrals[6],
      },
      '& svg': {
        transition: 'color 0.2s ease',
        color: theme.palette.neutrals[5],
      },
    },
  },
  input: {
    display: 'none',
  },
}));

const sortIds = (a, b) => a.id - b.id;

const EditMeetingForm = ({ change }) => {
  const classes = useStyles();
  const {
    state: {
      name,
      typology,
      date,
      endDate,
      local,
      errors,
      addToTypology,
      themes = [],
      points = [],
      submitted,
      users = [],
      pool,
      files = [],
      sameUsers,
      isGroup,
      isPublic,
      availableThemes,
      matrices,
      board,
      availableSecretary = [],
      availableModerator = [],
      briefFileName,
      publicNoticeFileName,
      newBriefFile,
      newPublicNoticeFile,
      skeleton,
      disableDownloadAgenda,
      disableDownloadPublicNotice,
      videoConferenceLink,
      videoConference,
    },
    handleSubmit,
    changePage,
    openWarning,
    cancelLeaving,
    confirmChange,
  } = useContext(MeetingStateContext);
  const { id } = useParams();
  const dispatch = useContext(MeetingDispatchContext);
  const dispatchRedux = useDispatch();
  const agendaFileRef = useRef(null);
  const publicNoticeFileRef = useRef(null);
  const { updateField } = useContext(MeetingFuncsContext);

  const detailsState = useMemo(
    () => ({
      name,
      typology,
      date,
      videoConferenceLink,
      videoConference,
      endDate,
      local,
      isPublic,
      errors,
      addToTypology,
      submitted,
    }),
    [
      name,
      typology,
      videoConferenceLink,
      videoConference,
      date,
      endDate,
      isPublic,
      local,
      errors,
      addToTypology,
      submitted,
    ]
  );

  const usersState = { users, sameUsers, isGroup };
  const poolPointsThemesState = useMemo(
    () => ({ pool, themes, points, isGroup, availableThemes, matrices }),
    [pool, themes, points, isGroup, availableThemes, matrices]
  );
  const filesState = useMemo(() => ({ files, errors }), [files, errors]);
  const secretaryModeratorState = useMemo(
    () => ({ board, availableSecretary, availableModerator }),
    [board, availableSecretary, availableModerator]
  );

  useEffect(() => {
    if ((!isEmpty(themes) || !isEmpty(points)) && skeleton === null) {
      // @ Existem temas ou pontos e o esqueleto ainda não foi construído
      const firstSkeleton = {};
      firstSkeleton.themes = themes
        .map(theme => ({
          id: theme.id,
          points: theme.points.map(point => ({ id: point.id })).sort(sortIds),
        }))
        .sort(sortIds)
        .filter(x => x.points.length !== 0);
      firstSkeleton.points = points.map(point => ({ id: point.id })).sort(sortIds);

      updateField('skeleton', firstSkeleton);
    }
  }, [themes, points, skeleton, updateField]);

  const cancelEdit = useCallback(() => {
    confirmChange('/reunioes');
  }, [confirmChange]);

  const downloadBriefTemplate = useCallback(
    () => e => {
      e.preventDefault();

      dispatchRedux(downloadMeetingAgendaTemplate(id, 'Template_Agenda.docx'));
    },
    [id, dispatchRedux]
  );

  const downloadPublicNoticeTemplate = useCallback(
    () => e => {
      e.preventDefault();

      dispatchRedux(downloadMeetingPublicNoticeTemplate(id, 'Template_Edital.docx'));
    },
    [id, dispatchRedux]
  );

  const downloadBriefFile = useCallback(
    fileInfo => e => {
      e.preventDefault();

      dispatchRedux(downloadMeetingBriefFile(id, fileInfo.name));
    },
    [id, dispatchRedux]
  );

  const downloadPublicNoticeFile = useCallback(
    fileInfo => e => {
      e.preventDefault();

      dispatchRedux(downloadMeetingPublicNoticeFile(id, fileInfo.name));
    },
    [id, dispatchRedux]
  );

  const handleBriefUpload = useCallback(
    e => {
      e.preventDefault();
      if (agendaFileRef.current.files.length > 0) {
        updateField('newBriefFile', agendaFileRef.current.files[0]);
      } else {
        updateField('newBriefFile', null);
      }
      e.target.value = null;
    },
    [updateField]
  );

  const handlePublicNoticeUpload = useCallback(
    e => {
      e.preventDefault();
      if (publicNoticeFileRef.current.files.length > 0) {
        updateField('newPublicNoticeFile', publicNoticeFileRef.current.files[0]);
      } else {
        updateField('newPublicNoticeFile', null);
      }
      e.target.value = null;
    },
    [updateField]
  );

  const deleteNewBrief = useCallback(
    () => e => {
      e.preventDefault();

      updateField('newBriefFile', null);
    },
    [updateField]
  );

  const deleteNewPublicNotice = useCallback(
    () => e => {
      e.preventDefault();

      updateField('newPublicNoticeFile', null);
    },
    [updateField]
  );

  return (
    <>
      <Prompt when={change} message={changePage} />
      <Grid container justify="center" spacing={2} className={classes.marginContainer}>
        <Grid item xs={6}>
          <Box className={classes.header}>
            <H5 className={classes.marginHeader}>Editar reunião</H5>
            <Box className={classes.box}>
              <ButtonNormal
                variant="outlined"
                color="primary"
                label="Cancelar"
                onClick={cancelEdit}
              />
              <ButtonNormal
                contained
                pea
                onClick={handleSubmit}
                label="Guardar"
                className={classes.buttonAdd}
              />
            </Box>
          </Box>
          <CardBlock label="Agenda da reunião" smallMargin>
            <FileBox
              file={{ name: 'Template de Agenda' }}
              download
              downloadFile={downloadBriefTemplate}
              disabled={disableDownloadAgenda}
              changeBG
            />
            {disableDownloadAgenda && (
              <Subtitle4>
                A organização de pontos/temas foi alterada. Guarde a nova informação para que seja
                possível efetuar o download do Template da Agenda.
              </Subtitle4>
            )}
            {briefFileName && newBriefFile === null && (
              <>
                <FileBox file={{ name: briefFileName }} download downloadFile={downloadBriefFile} />
                <Subtitle4>
                  Para subtituir o ficheiro da agenda, carregue um novo ficheiro.
                </Subtitle4>
              </>
            )}
            {newBriefFile !== null && (
              <FileBox file={{ name: newBriefFile.name }} remove deleteFile={deleteNewBrief} />
            )}
          </CardBlock>
          <Box className={clsx(classes.boxUpload, classes.padding)}>
            <label htmlFor="input-brief-files">
              <input
                accept="application/*,.xlsx,.xls,image/*,.doc,.docx,.ppt,.pptx,.txt,.pdf"
                className={classes.input}
                id="input-brief-files"
                ref={agendaFileRef}
                type="file"
                onChange={handleBriefUpload}
              />
              <Button component="span" classes={{ label: classes.buttonText, root: classes.root }}>
                Carregar ficheiro da Agenda
                <AddCircleOutlined className={classes.icon} />
              </Button>
            </label>
          </Box>
          <Box height="20px" />
          <CardBlock label="Edital de publicitação" smallMargin>
            <FileBox
              file={{ name: 'Template de Edital' }}
              download
              downloadFile={downloadPublicNoticeTemplate}
              disabled={disableDownloadPublicNotice}
              changeBG
            />
            {disableDownloadPublicNotice && (
              <Subtitle4>
                A organização de pontos/temas foi alterada. Guarde a nova informação para que seja
                possível efetuar o download do Template de Edital.
              </Subtitle4>
            )}
            {publicNoticeFileName && newPublicNoticeFile === null && (
              <>
                <FileBox
                  file={{ name: publicNoticeFileName }}
                  download
                  downloadFile={downloadPublicNoticeFile}
                />
                <Subtitle4>
                  Para subtituir o ficheiro do edital, carregue um novo ficheiro.
                </Subtitle4>
              </>
            )}
            {newPublicNoticeFile !== null && (
              <FileBox
                file={{ name: newPublicNoticeFile.name }}
                remove
                deleteFile={deleteNewPublicNotice}
              />
            )}
          </CardBlock>
          <Box className={clsx(classes.boxUpload, classes.padding)}>
            <label htmlFor="input-public-notice-files">
              <input
                accept="application/*,.xlsx,.xls,image/*,.doc,.docx,.ppt,.pptx,.txt,.pdf"
                className={classes.input}
                id="input-public-notice-files"
                ref={publicNoticeFileRef}
                type="file"
                onChange={handlePublicNoticeUpload}
              />
              <Button component="span" classes={{ label: classes.buttonText, root: classes.root }}>
                Carregar ficheiro do Edital
                <AddCircleOutlined className={classes.icon} />
              </Button>
            </label>
          </Box>
          <Box height="20px" />
          <MeetingDetails state={detailsState} editing />
          <Box height="20px" />
          <NewMeetingSecretaryModerator state={secretaryModeratorState} />
          <Box height="20px" />
          <MeetingPoolPointsThemes state={poolPointsThemesState} />
          <Box height="20px" />
          <MeetingUsers state={usersState} />
          <Box height="20px" />
          <CardFilesUpload state={filesState} dispatch={dispatch} />
        </Grid>
        {/* <DialogConfirmAction
          open={open}
          handleClose={toggleModal}
          handleSubmit={cancelEdit}
          msg="Deseja cancelar a edição da reunião?"
          labelSubmit="Confirmar"
        /> */}
      </Grid>
      <DialogCheck
        open={openWarning}
        msg="Pretende sair do formulário? Os dados atualizados serão perdidos."
        handleCancel={cancelLeaving}
        handleSubmit={cancelEdit}
        labelSubmit="Sair"
      />
    </>
  );
};

EditMeetingForm.propTypes = {
  change: PropTypes.bool.isRequired,
};

export default EditMeetingForm;
