import React from 'react';
import { DialogActions, Button, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body2 from '../typographys/Body2';

const useStyles = makeStyles(() => ({
  buttonConfirm: {
    marginRight: '22px',
    marginBottom: '14px',
    textTransform: 'none',
  },
  buttonCancel: {
    marginBottom: '14px',
    textTransform: 'none',
  },
  noPadding: {
    padding: '0',
  },
}));

const DialogButtonsConfirmation = ({ handleClose, labelCancel, handleSubmit, labelSubmit }) => {
  const classes = useStyles();

  return (
    <DialogActions className={classes.noPadding}>
      <Button onClick={handleClose} className={classes.buttonCancel}>
        <Body2>{labelCancel}</Body2>
      </Button>
      <Button onClick={handleSubmit} className={classes.buttonConfirm}>
        <Body2 secondary>{labelSubmit}</Body2>
      </Button>
    </DialogActions>
  );
};

DialogButtonsConfirmation.defaultProps = {
  labelCancel: 'Cancelar',
  labelSubmit: 'Confirmar',
};

DialogButtonsConfirmation.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  labelCancel: PropTypes.string,
  labelSubmit: PropTypes.string,
};

export default DialogButtonsConfirmation;
