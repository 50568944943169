import React, { useContext } from 'react';
import { GroupStateContext, GroupDispatchContext } from './CreateEditGroupProvider';
import FormInfoGroup from './FormInfoGroup';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import UpdateItem from '../../pieces/UpdateItem';

const EditGroup = () => {
  const {
    state: { errors, items },
    handleSubmit,
    loading,
  } = useContext(GroupStateContext);
  const dispatch = useContext(GroupDispatchContext);

  return (
    <UpdateItem disableSubmit={checkEmptyObject(errors).length > 0} loading={loading} items={items} dispatch={dispatch} handleSubmit={handleSubmit}>
      <FormInfoGroup />
    </UpdateItem>
  );
};

export default EditGroup;
