import React, { useContext, useMemo } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import CheckProposalDetails from './CheckProposalDetails';
import { CheckProposalStateContext } from './CheckProposalProvider';
// import FlowApproversList from './FlowApproversList';
import ProposalVersions from './ProposalVersions';
import ReviewProposalNotes from './ReviewProposalNotes';
import EditProposalDetails from './EditProposalDetails';
import { useSelector } from 'react-redux';
import isEmpty from '../../../utils/isEmpty';
import FullWidthSpinner from '../../common/FullWidthSpinner';

const useStyles = makeStyles(() => ({
  marginContainer: {
    marginTop: '54px',
  },
}));

const ProposalInfo = () => {
  const classes = useStyles();
  const {
    state: { isApprover, isCreator, versions, meetings, proposal, editing, lastVersionId },
  } = useContext(CheckProposalStateContext);
  const { permissions } = useSelector(state => state.auth.user);
  const { status = {}, user = {}, can_edit, can_submit, can_remove, id } = proposal;
  const isLastVersion = id === lastVersionId;
  const canEdit = can_edit && isLastVersion;
  const canSubmit = can_submit && isLastVersion;
  const canRemove = can_remove && isLastVersion;

  const canSeeApprovers = useMemo(
    () =>
      isApprover ||
      (status && status.slug !== 'correction' && isCreator) ||
      (permissions &&
        permissions.proposals &&
        permissions.proposals.show_all &&
        status &&
        status.slug !== 'correction' &&
        !isCreator),
    [isApprover, status, isCreator, permissions]
  );

  // Temporary removal of proposal flow
  /* return (
    <Grid container justify="center" spacing={3} className={classes.marginContainer}>
      {!isEmpty(user) ? (
        <>
          {((versions && versions.length > 1) || (meetings && meetings.length > 0)) && !editing && (
            <ProposalVersions />
          )}
          {editing ? (
            <EditProposalDetails />
          ) : (
            <CheckProposalDetails canSeeApprovers={canSeeApprovers} />
          )}
          {canSeeApprovers && (
            <FlowApproversList canEdit={canEdit} canSubmit={canSubmit} canRemove={canRemove} />
          )}
          {can_edit && status && status && status.slug === 'correction' && isCreator && (
            <ReviewProposalNotes
              canEdit={canEdit}
              canSubmit={canSubmit}
              canRemove={canRemove}
              isCreator={isCreator}
            />
          )}
        </>
      ) : (
        <FullWidthSpinner />
      )}
    </Grid>
  ); */

  return (
    <Grid container justify="center" spacing={3} className={classes.marginContainer}>
      {!isEmpty(user) ? (
        <>
          {((versions && versions.length > 1) || (meetings && meetings.length > 0)) && !editing && (
            <ProposalVersions />
          )}
          {editing ? (
            <EditProposalDetails />
          ) : (
            <CheckProposalDetails canSeeApprovers={canSeeApprovers} />
          )}
          {can_edit && status && status && status.slug === 'correction' && isCreator && (
            <ReviewProposalNotes
              canEdit={canEdit}
              canSubmit={canSubmit}
              canRemove={canRemove}
              isCreator={isCreator}
            />
          )}
        </>
      ) : (
        <FullWidthSpinner />
      )}
    </Grid>
  );
};

export default React.memo(ProposalInfo);
