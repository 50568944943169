import React, { useMemo } from 'react';
import { makeStyles, Grid, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import Body2 from '../../common/typographys/Body2';
import Body1 from '../../common/typographys/Body1';
import { useSelector } from 'react-redux';
import canSeePools from '../../../utils/permissions/canSeePools';
import getStatusBySlug from '../../../utils/getStatusBySlug';

const useStyles = makeStyles(theme => ({
  itemFlexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  noVerticalPadding: {
    paddingTop: '0px!important',
    paddingBottom: '0px!important',
  },
  dividerProposal: {
    marginTop: '10px',
    marginBottom: '14px',
  },
  hoverable: {
    paddingTop: '14px!important',
    paddingBottom: '10px!important',
    '&:hover': {
      cursor: 'pointer',
      '& p': {
        transition: 'color 0.1s ease',
        color: theme.palette.primary.A700,
      },
    },
  },
}));

const ProposalEntryInfo = ({ proposal }) => {
  const classes = useStyles();
  const history = useHistory();
  const { permissions } = useSelector(state => state.auth.user);
  const checkPools = canSeePools(permissions);

  const { id, identifier_code, name, user, meetings, typology, status } = proposal;

  const statusSlug = useMemo(() => getStatusBySlug(status ? status.slug : ''), [status]);

  return (
    <>
      <Grid
        item
        xs={checkPools ? 4 : 5}
        className={clsx(classes.itemFlexCenter, classes.hoverable)}
        onClick={() => {
          history.push(`/propostas/visualizar/${id}`);
        }}
      >
        <Body2 oneLine>{`${identifier_code} - ${name}`}</Body2>
      </Grid>
      <Grid item xs={2} className={classes.itemFlexCenter}>
        <Body2 oneLine>{user.name}</Body2>
      </Grid>
      <Grid item xs={checkPools ? 2 : 3} className={classes.itemFlexCenter}>
        <Body2 oneLine>{meetings.map(meeting => meeting.name).join(', ')}</Body2>
      </Grid>
      {checkPools && (
        <Grid item xs={2} className={classes.itemFlexCenter}>
          <Body2 oneLine>{(typology && typology.name) || ''}</Body2>
        </Grid>
      )}
      <Grid item xs={2} className={classes.itemFlexCenter}>
        <Body1
          approved={statusSlug === 'approved'}
          disapproved={statusSlug === 'disapproved'}
          standby={statusSlug === 'standby'}
        >
          {status ? status.label : 'Não definido'}
        </Body1>
      </Grid>
      <Grid item xs={12} className={classes.noVerticalPadding}>
        <Grid container justify="flex-end">
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

ProposalEntryInfo.propTypes = {
  proposal: PropTypes.object.isRequired,
};

export default ProposalEntryInfo;
