import React from 'react';
import {
  makeStyles,
  Divider,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import H6 from '../../../../common/typographys/H6';
import LabelAndValue from '../../../../common/forms/LabelAndValue';
import isEmpty from '../../../../../utils/isEmpty';
import { Link as LinkIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: '8px',
    marginBottom: '20px',
  },
  link: {
    ...theme.typography.body2,
    transition: 'color 0.1s ease',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.A700,
    },
    textDecoration: 'none !important',
  },
}));

const GeneralInfo = ({ content }) => {
  const classes = useStyles();
  const {
    configs: { name, phone, email, description, links },
  } = content;

  return (
    <>
      <H6>Informação geral</H6>
      <Divider className={classes.divider} />
      <LabelAndValue label="Nome da plataforma" value={name} />
      <LabelAndValue label="Email de contacto" value={email} />
      <LabelAndValue label="Contacto telefónico" value={phone} />
      <LabelAndValue label="Descrição" value={description} />
      {!isEmpty(links) && (
        <>
          <H6>Ligações no ecrã de login</H6>
          <Divider className={classes.divider} />
          <List>
            {Object.keys(links)
              .filter(key => !isEmpty(links[key]?.url) && !isEmpty(links[key]?.label))
              .sort()
              .map(key => {
                const { url, label } = links[key];

                return (
                  <ListItem hoverable key={key}>
                    <ListItemIcon>
                      <LinkIcon />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{ noWrap: true }}>
                      <Link href={url} target="_blank" rel="noopener noreferrer" color="inherit">
                        {label} - {url}
                      </Link>
                    </ListItemText>
                  </ListItem>
                );
              })}
          </List>
        </>
      )}
    </>
  );
};

GeneralInfo.propTypes = {
  content: PropTypes.object.isRequired,
};

export default React.memo(GeneralInfo);
