import React, { useContext, useCallback, useMemo, useState } from 'react';
import { OrganicUnitStateContext, OrganicUnitFuncsContext, OrganicUnitDispatchContext } from './CreateEditOrganicUnitProvider';
import { makeStyles, Box } from '@material-ui/core';
import ButtonNormal from '../../../common/buttons/ButtonNormal';
import { ConfigDispatchContext } from '../../MenuConfigProvider';
import H6 from '../../../common/typographys/H6';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from '../../../../utils/isEmpty';
import OrganicUnitChildren from './pieces/OrganicUnitChildren';
import OrganicUnitUsers from './pieces/OrganicUnitUsers';
import SelectOutlined from '../../../common/forms/SelectOutlined';
import PropTypes from 'prop-types';
import { deleteOrganicUnit, getOrganicUnits } from '../../../../store/actions/organicUnitsActions';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import LabelAndValue from '../../../common/forms/LabelAndValue';
import DialogConfirmAction from '../../../common/dialogs/DialogConfirmAction';
import TimeBox from '../../pieces/TimeBox';

const useStyles = makeStyles(() => ({
  box: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  boxContent: {
    padding: '28px 65px',
  },
  buttonEdit: {
    marginLeft: '10px',
  },
  header: {
    marginTop: '10px',
    marginBottom: '20px',
  },
}));

const getChildrenIds = (obj, arr = []) => {
  arr.push(obj.id);
  if (obj.children && obj.children.length > 0) {
    obj.children.map(x => getChildrenIds(x, arr));
  }

  return arr;
};

const commonProps = {
  getOptionLabel: option => `${option.abbreviation || option.code} - ${option.name}`,
  getOptionValue: option => option.id,
};

const DeleteOrganicUnit = ({ id }) => {
  const classes = useStyles();
  const dispatchRedux = useDispatch();
  const dispatchConfig = useContext(ConfigDispatchContext);
  const dispatch = useContext(OrganicUnitDispatchContext);
  const {
    state: { newParent, newUserOrganicUnit },
  } = useContext(OrganicUnitStateContext);
  const { updateField } = useContext(OrganicUnitFuncsContext);
  const { children, users, organicUnit, organicUnits } = useSelector(state => state.organicUnits);
  const pluralES = useMemo(() => (users.length > 1 ? 'es' : ''), [users]);
  const pluralS = useMemo(() => (users.length > 1 ? 's' : ''), [users]);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const hasUsers = useMemo(() => !isEmpty(users), [users]);
  const hasChildren = useMemo(() => !isEmpty(children), [children]);

  const deleteCurrentOrganicUnit = useCallback(
    e => {
      e.preventDefault();

      const localErrors = {};

      if (isEmpty(newParent) && hasChildren) {
        localErrors.newParent = 'Selecione uma nova Unidade Orgânica superior';
      }

      if (isEmpty(newUserOrganicUnit) && hasUsers) {
        localErrors.newUserOrganicUnit = `Selecione uma Unidade Orgânica para o${pluralS} utilizador${pluralES}`;
      }

      if (checkEmptyObject(localErrors).length > 0) {
        setOpen(false);
        return setErrors(localErrors);
      }

      const deletePromise = new Promise((resolve, reject) => {
        const info = {
          newParentId: newParent ? newParent.id : null,
          newUserOrganicUnitId: newUserOrganicUnit ? newUserOrganicUnit.id : null,
        };
        dispatchRedux(deleteOrganicUnit(id, info, resolve, reject));
      });

      return deletePromise
        .then(() => {
          dispatchConfig({
            type: 'CANCEL_DELETING',
          });
          dispatchRedux(getOrganicUnits());
          dispatch({ type: 'CLEAR_STATE' });
        })
        .catch(() => {
          dispatchRedux({
            type: 'SHOW_SNACK',
            payload: {
              variant: 'error',
              message: 'Ocorreu um erro ao eliminar a Unidade Orgânica',
            },
          });
        });
    },
    [newParent, dispatchRedux, dispatch, hasChildren, hasUsers, pluralES, pluralS, dispatchConfig, newUserOrganicUnit, id]
  );

  const filterIds = useMemo(() => getChildrenIds(organicUnit), [organicUnit]);

  const cancelDelete = useCallback(
    e => {
      e.preventDefault();
      dispatchConfig({ type: 'SUAVE_CANCEL_DELETING' });
    },
    [dispatchConfig]
  );

  return (
    <>
      <Box className={classes.box}>
        <ButtonNormal variant="outlined" color="primary" onClick={cancelDelete} label="Cancelar" className={classes.buttonRemove} />
        <ButtonNormal
          variant="contained"
          contained
          pea
          onClick={e => {
            e.preventDefault();
            setOpen(true);
          }}
          label="Eliminar"
          className={classes.buttonEdit}
        />
      </Box>
      <Box className={classes.boxContent}>
        <H6 className={classes.header}>Eliminar Unidade Orgânica</H6>
        <LabelAndValue label="Nome" value={organicUnit.name} />
        {hasChildren && (
          <TimeBox medium>
            <OrganicUnitChildren items={children} deleting />
            <Box height="12px" />
            <SelectOutlined
              name="newParent"
              value={newParent}
              onChange={val => updateField('newParent', val)}
              options={organicUnits.filter(x => filterIds.indexOf(x.id) === -1)}
              label="Nova Unidade Orgânica superior"
              placeholder="Definir nova Unidade Orgânica Superior"
              error={errors.newParent}
              {...commonProps}
            />
          </TimeBox>
        )}
        {hasUsers && <Box height={hasChildren ? '20px' : '0px'} />}
        {hasUsers && (
          <TimeBox medium>
            <OrganicUnitUsers items={users} deleting />
            <Box height="12px" />
            <SelectOutlined
              name="newUserOrganicUnit"
              value={newUserOrganicUnit}
              onChange={val => updateField('newUserOrganicUnit', val)}
              options={organicUnits.filter(x => x.id !== organicUnit.id)}
              label={`Associar utilizador${pluralES} a Unidade Orgânica`}
              placeholder={`Definir nova Unidade Orgânica para utilizador${pluralES}`}
              error={errors.newUserOrganicUnit}
              {...commonProps}
            />
          </TimeBox>
        )}
      </Box>
      <DialogConfirmAction
        open={open}
        handleClose={e => {
          e.preventDefault();
          setOpen(false);
        }}
        handleSubmit={deleteCurrentOrganicUnit}
        msg={`Deseja eliminar a Unidade Orgânica, ${organicUnit.name}?`}
      />
    </>
  );
};

DeleteOrganicUnit.propTypes = {
  id: PropTypes.number.isRequired,
};

export default React.memo(DeleteOrganicUnit);
