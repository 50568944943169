import React, { useReducer, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from '../../utils/isEmpty';
import validEmail from '../../utils/validation/validEmail';
import validateNIF from '../../utils/validateNIF';
import validPhone from '../../utils/validation/validPhone';
import checkEmptyObject from '../../utils/checkEmptyObject';
import { updateUser } from '../../store/actions/usersActions';
import { useDispatch } from 'react-redux';
import { uploadUserImage } from '../../store/actions/authActions';

export const PersonalProfileDispatchContext = React.createContext();
export const PersonalProfileFuncsContext = React.createContext();
export const PersonalProfileStateContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'SET_NEW_IMAGE':
      return {
        ...state,
        newImage: action.payload.object,
        newFileImage: action.payload.file,
      };
    case 'SET_ERRORS':
      return {
        ...state,
        errors: action.payload,
      };
    case 'SET_ERROR':
      return {
        ...state,
        errors: { ...state.errors, ...action.payload },
      };
    default:
      return state;
  }
};

const PersonalProfileProvider = ({ user, children }) => {
  const dispatchRedux = useDispatch();
  const [state, dispatch] = useReducer(reducer, {
    user: {},
    name: '',
    email: '',
    cc: '',
    nif: '',
    phone: '',
    company: '',
    organicUnit: null,
    role: '',
    editing: false,
    newImage: '',
    newFileImage: null,
    errors: {},
  });

  const { name, email, phone, nif, cc, role, errors, newFileImage, organicUnit } = state;

  const updateField = useCallback((nameInput, value) => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: nameInput,
        value,
      },
    });
  }, []);

  const setImage = useCallback(
    (object, file) => {
      dispatch({
        type: 'SET_NEW_IMAGE',
        payload: {
          object,
          file,
        },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isEmpty(user)) {
      updateField('user', user);
      updateField('name', user.name || '');
      updateField('email', user.email || '');
      updateField('cc', user.cc || '');
      updateField('nif', user.nif || '');
      updateField('organicUnit', user.organic_unit || null);
      updateField('phone', user.phone || '');
      updateField('company', user.company || '');
      updateField('role', user.role || '');
    }
  }, [user, updateField]);

  useEffect(() => {
    if (name.length > 2 && !isEmpty(errors.name)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { name: '' },
      });
    }
  }, [name, errors.name]);

  useEffect(() => {
    if (validEmail(email) && !isEmpty(errors.email)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { email: '' },
      });
    }
  }, [email, errors.email]);

  useEffect(() => {
    if (isEmpty(nif)) return;

    if (validateNIF(nif) && !isEmpty(errors.nif)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { nif: '' },
      });
    }
  }, [nif, errors.nif]);

  useEffect(() => {
    if (!isEmpty(phone) && validPhone(phone) && !isEmpty(errors.phone)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { phone: '' },
      });
    }
  }, [phone, errors.phone]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      const localErrors = {};

      if (name.length < 3) {
        localErrors.name = 'Insira um nome válido';
      }

      if (!validEmail(email)) {
        localErrors.email = 'Insira um email válido';
      }

      if (!isEmpty(nif) && !validateNIF(nif)) {
        localErrors.nif = 'Insira um NIF válido';
      }

      if (!isEmpty(phone) && !validPhone(phone)) {
        localErrors.phone = 'Insira um contacto válido';
      }

      if (checkEmptyObject(localErrors).length > 0) {
        return dispatch({
          type: 'SET_ERRORS',
          payload: localErrors,
        });
      }

      const userInfo = {
        email,
        nif,
        phone,
        name,
        cc,
        role,
        organicUnitId: organicUnit ? organicUnit.id : null,
      };

      const updatePromise = new Promise((resolve, reject) => {
        dispatchRedux(updateUser(user.id, userInfo, resolve, reject));
      });

      return updatePromise
        .then(() => {
          if (!isEmpty(newFileImage)) {
            const imagePromise = new Promise((resolve, reject) => {
              const formData = new FormData();
              Array.from(newFileImage).forEach(file => {
                formData.append('file', file);
              });

              dispatchRedux({
                type: 'SHOW_SNACK',
                payload: {
                  variant: 'info',
                  message: 'A carregar ficheiro para foto de perfil.',
                },
              });

              dispatchRedux(uploadUserImage(formData, resolve, reject));
            });

            return imagePromise
              .then(() => {
                dispatchRedux({
                  type: 'SHOW_SNACK',
                  payload: {
                    variant: 'success',
                    message: 'Foto de perfil atualizada com sucesso.',
                  },
                });
              })
              .catch(() => {
                dispatchRedux({
                  type: 'SHOW_SNACK',
                  payload: {
                    variant: 'error',
                    message: 'Erro ao atualizar foto de perfil.',
                  },
                });
              });
          }

          return dispatch({
            type: 'UPDATE_FIELD',
            payload: {
              name: 'editing',
              value: false,
            },
          });
        })
        .catch(() => {
          dispatchRedux({
            type: 'SHOW_SNACK',
            payload: {
              variant: 'error',
              message: 'Erro ao atualizar o perfil',
            },
          });
        });
    },
    [name, user.id, email, nif, phone, cc, role, newFileImage, organicUnit, dispatchRedux]
  );

  const funcsContext = useMemo(() => ({ updateField, setImage }), [updateField, setImage]);
  const stateContext = useMemo(() => ({ state, handleSubmit }), [state, handleSubmit]);

  return (
    <PersonalProfileDispatchContext.Provider value={dispatch}>
      <PersonalProfileFuncsContext.Provider value={funcsContext}>
        <PersonalProfileStateContext.Provider value={stateContext}>
          {children}
        </PersonalProfileStateContext.Provider>
      </PersonalProfileFuncsContext.Provider>
    </PersonalProfileDispatchContext.Provider>
  );
};

PersonalProfileProvider.propTypes = {
  user: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default PersonalProfileProvider;
