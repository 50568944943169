/* eslint-disable no-nested-ternary */
import React, { useCallback, useRef, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import CardBlock from '../../common/forms/CardBlock';
import FileBox from '../../common/FileBox';
import SingleBoxUpload from '../../common/SingleBoxUpload';
import {
  downloadProposalResume,
  downloadProposalSummary,
} from '../../../store/actions/proposalsActions';
import { useDispatch } from 'react-redux';
import { CheckProposalDispatchContext, CheckProposalStateContext } from './CheckProposalProvider';
import DialogConfirmAction from '../../common/dialogs/DialogConfirmAction';

const ProposalSummary = ({ id, code }) => {
  const dispatchRedux = useDispatch();
  const fileSummaryRef = useRef(null);
  const dispatch = useContext(CheckProposalDispatchContext);
  const {
    state: {
      summaryFile,
      proposal: { summary_file_name },
    },
  } = useContext(CheckProposalStateContext);
  const [open, setOpen] = useState(false);

  const downloadResume = useCallback(
    // eslint-disable-next-line no-unused-vars
    _aux => e => {
      e.preventDefault();
      dispatchRedux(downloadProposalResume(id, code));
    },
    [dispatchRedux, id, code]
  );

  const downloadSubResume = useCallback(
    file => e => {
      e.preventDefault();
      dispatchRedux(downloadProposalSummary(id, file.name));
    },
    [dispatchRedux, id]
  );

  const deleteSummaryFile = useCallback(
    // eslint-disable-next-line no-unused-vars
    _aux => e => {
      e.preventDefault();
      setOpen(true);
    },
    []
  );

  const handleUpload = useCallback(
    e => {
      e.preventDefault();
      return fileSummaryRef.current.files.length > 0
        ? dispatch({
            type: 'CHANGE_PROPOSAL_SUMMARY',
            payload: {
              value: Array.from(fileSummaryRef.current.files)[0],
            },
          })
        : null;
    },
    [dispatch]
  );

  const removeSummaryFile = useCallback(
    e => {
      e.preventDefault();
      dispatch({
        type: 'CHANGE_PROPOSAL_SUMMARY',
        payload: {
          value: null,
        },
      });
      setOpen(false);
    },
    [dispatch]
  );

  return (
    <>
      <CardBlock label="Ficheiro resumo">
        {summaryFile && summaryFile.name ? (
          <FileBox file={{ name: summaryFile.name }} remove deleteFile={deleteSummaryFile} />
        ) : summary_file_name ? (
          <FileBox file={{ name: summary_file_name }} download downloadFile={downloadSubResume} />
        ) : (
          <FileBox file={{ name: `${code}.pdf` }} download downloadFile={downloadResume} />
        )}
      </CardBlock>
      <SingleBoxUpload
        filesRef={fileSummaryRef}
        handleUpload={handleUpload}
        padding
        labelBox="Substituir ficheiro resumo"
      />
      <DialogConfirmAction
        open={open}
        handleClose={e => {
          e.preventDefault();
          setOpen(false);
        }}
        msg={`Deseja retirar o ficheiro ${(summaryFile && summaryFile.name) || ''}?`}
        labelSubmit="Retirar"
        handleSubmit={removeSummaryFile}
      />
    </>
  );
};

ProposalSummary.propTypes = {
  id: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,
};

export default React.memo(ProposalSummary);
