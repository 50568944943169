import {
  GET_TYPOLOGIES,
  GET_TYPOLOGY_POOL,
  UPDATE_TYPOLOGY,
  TYPOLOGIES_LOADING,
  TYPOLOGIES_NOT_LOADING,
  CREATE_TYPOLOGY,
  GET_TYPOLOGY,
  REMOVE_TYPOLOGY,
  CLEAR_TYPOLOGY,
} from '../actions/types';

const initialState = {
  typologies: [],
  typology: {
    locals: [],
    pool: [],
  },
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TYPOLOGIES:
      return {
        ...state,
        typologies: action.payload,
        loading: false,
      };
    case GET_TYPOLOGY:
      return {
        ...state,
        typology: {
          ...state.typology,
          locals: [],
          pool: [],
          ...action.payload,
        },
        loading: false,
      };
    case GET_TYPOLOGY_POOL:
      return {
        ...state,
        typology: {
          ...state.typology,
          pool: action.payload,
        },
        loading: false,
      };
    case CREATE_TYPOLOGY:
      return {
        ...state,
        typologies: [action.payload, ...state.typologies],
        loading: false,
      };
    case UPDATE_TYPOLOGY:
      return {
        ...state,
        typologies: state.typologies.map(x => (x.id === action.payload.id ? action.payload : x)),
        loading: false,
      };
    case REMOVE_TYPOLOGY:
      return {
        ...state,
        typologies: state.typologies.filter(x => x.id !== action.payload.id),
        loading: false,
      };
    case CLEAR_TYPOLOGY:
      return {
        ...state,
        typology: {
          locals: [],
          pool: [],
        },
      };
    case TYPOLOGIES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case TYPOLOGIES_NOT_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
