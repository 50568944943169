import React, { useState, useMemo, useCallback } from 'react';
import { makeStyles, Button, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Body1 from '../../../../common/typographys/Body1';
import isEmpty from '../../../../../utils/isEmpty';

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: '8px',
  },
  value: {
    marginBottom: '20px',
  },
  button: {
    minHeight: '40px',
    maxHeight: '40px',
    height: '40px',
    margin: '20px 0px',
  },
}));

const MAX_LENGTH = 400;

const PointDescription = ({ description }) => {
  const classes = useStyles();
  const [showAll, setShowAll] = useState(false);
  const [biggerText, setBiggerText] = useState(false);

  const showText = useMemo(() => {
    if (description && description.length > MAX_LENGTH + 5 && !showAll) {
      setBiggerText(true);
      return description.substring(0, MAX_LENGTH);
    }

    if (isEmpty(description) && !showAll) {
      setBiggerText(false);
      return 'Sem enquadramento adicionado';
    }

    if (!showAll) {
      setBiggerText(false);
    }

    return description;
  }, [description, showAll]);

  const showAllText = useCallback(e => {
    e.preventDefault();
    setShowAll(true);
  }, []);

  const showLessText = useCallback(e => {
    e.preventDefault();
    setShowAll(false);
  }, []);

  return (
    <>
      <Box height="10px" />
      <Body1
        printMultiline
        className={clsx({ [classes.value]: !biggerText })}
        disabled={isEmpty(description)}
      >
        {showText} {biggerText && !showAll && '...'}
      </Body1>
      {biggerText && (
        <Button
          variant="outlined"
          color="primary"
          onClick={showAll ? showLessText : showAllText}
          className={classes.button}
        >
          {showAll ? 'Mostrar menos' : 'Mostrar tudo'}
        </Button>
      )}
    </>
  );
};

PointDescription.propTypes = {
  description: PropTypes.string.isRequired,
};

export default PointDescription;
