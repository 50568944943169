import React from 'react';
import { makeStyles } from '@material-ui/core';
import Subtitle2 from '../typographys/Subtitle2';
import Subtitle1 from '../typographys/Subtitle1';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: '6px',
  },
  helper: {
    marginBottom: '12px',
  },
}));

const LabelAndHelper = ({ label, helper, tertiary }) => {
  const classes = useStyles();

  return (
    <>
      <Subtitle2 tertiary={tertiary} className={classes.label}>
        {label}
      </Subtitle2>
      <Subtitle1 inactive className={classes.helper}>
        {helper}
      </Subtitle1>
    </>
  );
};

LabelAndHelper.defaultProps = {
  tertiary: false,
};

LabelAndHelper.propTypes = {
  label: PropTypes.string.isRequired,
  helper: PropTypes.string.isRequired,
  tertiary: PropTypes.bool,
};

export default LabelAndHelper;
