import React from 'react';
import { Box, Button } from '@material-ui/core';
import loginRegisterStyles from '../../../common/layout/styles/loginRegister';
import PropTypes from 'prop-types';

const ButtonsForm = ({ submitDisabled, handleSubmit }) => {
  const classes = loginRegisterStyles();

  return (
    <Box width="100%" display="inline-flex" className={classes.buttonsRow}>
      <Button fullWidth disabled={submitDisabled} variant="contained" color="primary" onClick={handleSubmit} className={classes.buttonRegister}>
        Registar
      </Button>
    </Box>
  );
};

ButtonsForm.defaultProps = {
  submitDisabled: false,
};

ButtonsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool,
};

export default ButtonsForm;
