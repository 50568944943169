/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React from 'react';
import clsx from 'clsx';
import Select from 'react-select';
import { emphasize, makeStyles, useTheme } from '@material-ui/core/styles';
import { FormHelperText, Typography, TextField, Paper, Chip } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import isEmpty from '../../../utils/isEmpty';
import Subtitle2 from '../typographys/Subtitle2';
import Subtitle1 from '../typographys/Subtitle1';
import Body1 from '../typographys/Body1';

const useStyles = makeStyles(theme => ({
  input: {
    display: 'flex',
    height: 'auto',
    padding: '0px 0px 0px 8px!important',
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
  },
  multiValueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
    padding: '8px 0px',
  },
  placeholder: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    opacity: '0.8',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  paper: {
    position: 'inherit',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    marginBottom: '50px',
  },
  divider: {
    height: theme.spacing(2),
  },
  marginLabel: {
    marginBottom: '6px',
  },
  hoverSelected: {
    '&:hover > span': {
      color: `${theme.palette.neutrals[6]}!important`,
    },
  },
  isFocused: {
    backgroundColor: theme.palette.primary[200],
  },
  fadeColor: {},
}));

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps, error },
  } = props;

  return (
    <TextField
      fullWidth
      variant="outlined"
      error={error}
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
};

function Placeholder(props) {
  return (
    <Subtitle1 className={props.selectProps.classes.placeholder} {...props.innerProps}>
      {props.children || ''}
    </Subtitle1>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function SingleValue(props) {
  return (
    <Body1
      className={props.selectProps.classes.singleValue}
      secondary={props.selectProps.menuIsOpen || props.selectProps.isDisabled}
      variant={props.selectProps.variant || 'body1'}
      {...props.innerProps}
    >
      {props.children}
    </Body1>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

const ValueContainer = props => {
  return (
    <div
      className={clsx({
        [props.selectProps.classes.valueContainer]: !props.isMulti,
        [props.selectProps.classes.multiValueContainer]: props.isMulti,
      })}
    >
      {props.children}
    </div>
  );
};

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
  isMulti: PropTypes.bool.isRequired,
};

function MultiValue(props) {
  return (
    !props.selectProps.hideSelections && (
      <Chip
        tabIndex={-1}
        label={props.children}
        className={clsx(props.selectProps.classes.chip, {
          [props.selectProps.classes.chipFocused]: props.isFocused,
        })}
        onDelete={props.removeProps.onClick}
        deleteIcon={<Close {...props.removeProps} />}
      />
    )
  );
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

function Menu(props) {
  return (
    <Paper className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
};

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const SelectOutlined = ({
  value,
  options,
  onChange,
  label,
  name,
  error,
  helperText,
  placeholder,
  marginDivider,
  hideDivider,
  className,
  isDisabled,
  noOptionsMessage,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
    indicatorsContainer: base => ({
      ...base,
      color: theme.palette.neutrals[5],
      display: isDisabled ? 'none' : 'inherit',
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    menuList: base => ({
      ...base,
      paddingBottom: '0px',
      paddingTop: '0px',
    }),
    option: (_, { isFocused, isSelected }) => ({
      width: 'auto',
      overflow: 'hidden',
      boxSizing: 'border-box',
      fontStyle: 'normal',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      lineHeight: '24px',
      paddingTop: '12px',
      whiteSpace: 'nowrap',
      fontStretch: 'normal',
      paddingLeft: '20px',
      paddingRight: '20px',
      letterSpacing: 'normal',
      paddingBottom: '8px',
      transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      display: 'flex',
      position: 'relative',
      background: isFocused
        ? theme.palette.primary[200]
        : isSelected
        ? theme.palette.primary[900]
        : '#eaefef',
      color: isFocused ? '#636363' : isSelected ? '#fff' : '#636363',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textDecoration: 'none',
      border: '0px',
      cursor: 'pointer',
      margin: '0px',
      outline: '0px',
      userSelect: 'none',
      borderRadius: '0px',
      verticalAlign: 'middle',
      fontSize: '12px',
    }),
  };

  return (
    <>
      {label && (
        <Subtitle2 color={!isEmpty(error) ? 'error' : 'initial'} className={classes.marginLabel}>
          {label}
        </Subtitle2>
      )}
      <Select
        classes={{ ...classes, ...props.otherClasses }}
        styles={{ ...selectStyles, ...props.otherStyles }}
        options={options}
        components={{ ...components, ...props.otherComponents }}
        value={value}
        onChange={onChange}
        placeholder={placeholder || label}
        name={name}
        error={!isEmpty(error)}
        className={className}
        menuPortalTarget={document.querySelector('body')}
        isDisabled={isDisabled}
        noOptionsMessage={noOptionsMessage}
        {...props}
      />
      {!isEmpty(error) || helperText ? (
        <FormHelperText error={!isEmpty(error)}>
          {!isEmpty(error) ? error : helperText}
        </FormHelperText>
      ) : null}
    </>
  );
};

SelectOutlined.defaultProps = {
  label: null,
  error: null,
  helperText: null,
  placeholder: null,
  value: null,
  marginDivider: true,
  hideDivider: false,
  isDisabled: false,
  className: '',
  otherComponents: {},
  otherClasses: {},
  otherStyles: {},
  noOptionsMessage: () => 'Sem opções',
};

SelectOutlined.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  marginDivider: PropTypes.bool,
  hideDivider: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  otherComponents: PropTypes.object,
  otherClasses: PropTypes.object,
  otherStyles: PropTypes.object,
  noOptionsMessage: PropTypes.func,
};

export default SelectOutlined;
