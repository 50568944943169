import React, { useRef } from 'react';
import { makeStyles, Input, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import BoxIconsVote from './BoxIconsVote';

const useStyles = makeStyles(theme => ({
  boxValue: {
    backgroundColor: theme.palette.neutrals[1],
    display: 'flex',
    padding: '8px 10px',
    alignItems: 'center',
  },
  inputRoot: {
    ...theme.typography.body1,
    height: theme.typography.body1.lineHeight,
    minHeight: '0px',
  },
  input: {
    padding: 0,
    ...theme.typography.body1,
    height: theme.typography.body1.lineHeight,
    color: theme.palette.neutrals[6],
    textAlign: 'center',
  },
  inputAvailable: {
    color: theme.palette.neutrals[7],
  },
}));

const SecretVotesCounterIndividual = ({ type, value, changeVote }) => {
  const classes = useStyles();
  const inputRef = useRef(null);

  return (
    <Box className={classes.boxValue}>
      <Input
        classes={{
          root: classes.inputRoot,
          input: clsx(classes.input, { [classes.inputAvailable]: value !== 0 }),
        }}
        value={value}
        type="number"
        inputProps={{
          step: 1,
          min: 0,
          ref: inputRef,
        }}
        disableUnderline
        onChange={e => changeVote(type, parseInt(e.target.value, 10) || 0)}
      />
      <BoxIconsVote inputRef={inputRef} type={type} changeVote={changeVote} />
    </Box>
  );
};

SecretVotesCounterIndividual.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  changeVote: PropTypes.func.isRequired,
};

export default React.memo(SecretVotesCounterIndividual);
