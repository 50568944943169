import isEmpty from '../isEmpty';

/**
 *
 * @param {object} permissions Permissions object
 * @returns {boolean} True or false depending if user has permission to check pools
 */
const canSeePools = permissions => {
  if (!isEmpty(permissions) && permissions.meetings && (permissions.meetings.create || permissions.meetings.edit)) {
    return true;
  }

  return false;
};

export default canSeePools;
