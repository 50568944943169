import React, { useCallback, useState, useContext, useMemo } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import SelectOutlined from '../../common/forms/SelectOutlined';
import useBooleanToggle from '../../../utils/hooks/useBooleanToggle';
import DialogConfirmAction from '../../common/dialogs/DialogConfirmAction';
import { MeetingDispatchContext } from '../CreateEditMeetingsProvider';
import {
  IndicatorsContainer,
  Control,
  ControlEditing,
  SingleValue,
  IndicatorsContainerMail,
} from './selectComponents';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resendMeetingInvite } from '../../../store/actions/meetingsActions';
import { isNumber } from 'lodash';

const useStyles = makeStyles(theme => ({
  mainBox: {
    marginBottom: '12px',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '10px',
  },
  iconDelete: {
    fill: theme.palette.neutrals[4],
    '&:hover': {
      cursor: 'pointer',
      fill: theme.palette.colorsPalette.RustRed,
    },
  },
  iconMail: {
    fill: theme.palette.neutrals[4],
    marginRight: '19px',
    '&:hover': {
      cursor: 'pointer',
      fill: theme.palette.neutrals[6],
    },
  },
  iconArrow: {
    fill: theme.palette.neutrals[5],
    '&:hover': {
      cursor: 'pointer',
      fill: theme.palette.neutrals[6],
    },
  },
  outlined: {
    backgroundColor: theme.palette.neutrals[2],
    '& fieldset': {
      display: 'none',
    },
  },
  outlinedEditing: {
    border: `solid 1px ${theme.palette.neutrals[3]}`,
  },
}));

const MeetingUsersList = ({ users, allUsers, idxGroup, isGroup, groupLimit, idGroup, editing }) => {
  const classes = useStyles();
  const [openChange, toggleChange] = useBooleanToggle();
  const [openRemove, toggleRemove] = useBooleanToggle();
  const [openResend, toggleResend] = useBooleanToggle();
  const [removeInfo, setRemoveInfo] = useState({});
  const [resendInfo, setResendInfo] = useState({});
  const [oldUser, setOldUser] = useState({});
  const [newUser, setNewUser] = useState({});
  // const [index, setIndex] = useState(0);
  const dispatch = useContext(MeetingDispatchContext);
  const dispatchRedux = useDispatch();
  const { id: meetingId } = useParams();
  const showAddNewParticipant =
    (isNumber(groupLimit) && users.length < groupLimit) || !isNumber(groupLimit);

  const otherComponents = useMemo(
    () =>
      !editing
        ? {
            IndicatorsContainer,
            Control,
          }
        : {
            IndicatorsContainer: IndicatorsContainerMail,
            Control: ControlEditing,
            SingleValue,
          },
    [editing]
  );

  const handleChange = useCallback(
    (oldValue, newValue /* idxValue */) => {
      setOldUser(oldValue);
      setNewUser(newValue);
      // setIndex(idxValue);
      toggleChange();
    },
    [toggleChange]
  );

  const confirmChange = useCallback(() => {
    dispatch({
      type: isGroup ? 'CHANGE_GROUP_USER' : 'CHANGE_USER',
      payload: {
        idxGroup,
        // idxUser: index,
        newValue: newUser,
        oldValue: oldUser,
        idGroup,
      },
    });
    setOldUser({});
    setNewUser({});
    // setIndex(0);
    toggleChange();
  }, [idxGroup, idGroup, /* index, */ isGroup, newUser, oldUser, dispatch, toggleChange]);

  const confirmRemove = useCallback(() => {
    const { id } = removeInfo;
    dispatch({
      type: isGroup ? 'REMOVE_GROUP_USER' : 'REMOVE_USER',
      payload: {
        idxGroup,
        idUser: id,
        user: removeInfo,
        idGroup,
      },
    });
    setRemoveInfo({});
    toggleRemove();
  }, [dispatch, isGroup, removeInfo, toggleRemove, idxGroup, idGroup]);

  const addNewParticipant = useCallback(
    (indexGroup, user) => {
      dispatch({
        type: indexGroup !== null ? 'ADD_GROUP_USER' : 'ADD_NEW_USER',
        payload: {
          idxGroup: indexGroup,
          user,
          idGroup,
        },
      });

      dispatch({
        type: 'REMOVE_SECRETARY',
        payload: {
          id: user.id,
        },
      });
    },
    [dispatch, idGroup]
  );

  const resendEmail = useCallback(
    val => e => {
      e.preventDefault();
      setResendInfo(val);
      toggleResend();
    },
    [toggleResend]
  );

  const confirmResend = useCallback(() => {
    const { id } = resendInfo;
    setResendInfo({});
    dispatchRedux(resendMeetingInvite(meetingId, id));
    toggleResend();
  }, [toggleResend, meetingId, dispatchRedux, resendInfo]);

  return (
    <>
      {users.map((user, idx) => (
        <React.Fragment key={user.id}>
          <Box className={classes.mainBox}>
            <SelectOutlined
              name={`user-${user.id}`}
              options={allUsers.filter(x => users.find(u => u.id === x.id) === undefined)}
              value={user}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              otherComponents={otherComponents}
              otherClasses={classes}
              onChange={val => handleChange(user, val, idx)}
              noOptionsMessage={() => 'Sem participantes disponíveis para substituir.'}
              toggleRemove={toggleRemove}
              resendEmail={resendEmail}
              setRemoveInfo={setRemoveInfo}
            />
          </Box>
        </React.Fragment>
      ))}
      {users.length !== allUsers.length && showAddNewParticipant && (
        <Box className={classes.mainBox}>
          <SelectOutlined
            name="user-new-add"
            options={allUsers.filter(x => users.find(u => u.id === x.id) === undefined)}
            value={null}
            placeholder="Adicionar novo participante"
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            otherComponents={otherComponents}
            otherClasses={classes}
            onChange={val => addNewParticipant(idxGroup, val)}
            noOptionsMessage={() => 'Nenhum participante disponível para adicionar.'}
            hideRemove
          />
        </Box>
      )}
      <DialogConfirmAction
        open={openChange}
        handleClose={toggleChange}
        labelSubmit="Substituir"
        msg={`Deseja substituir ${oldUser.name} por ${newUser.name}?`}
        handleSubmit={confirmChange}
      />
      <DialogConfirmAction
        open={openRemove}
        handleClose={toggleRemove}
        labelSubmit="Remover"
        msg={`Deseja remover o participante ${removeInfo.name}?`}
        handleSubmit={confirmRemove}
      />
      <DialogConfirmAction
        open={openResend}
        handleClose={toggleResend}
        labelSubmit={resendInfo && resendInfo.accepted === null ? 'Reenviar' : 'Enviar'}
        msg={`Deseja ${
          resendInfo && resendInfo.accepted === null ? 'reenviar' : 'enviar'
        } o email de convite para o participante ${resendInfo.name}?`}
        handleSubmit={confirmResend}
      />
    </>
  );
};

MeetingUsersList.defaultProps = {
  idxGroup: null,
  isGroup: false,
  idGroup: null,
  groupLimit: null,
};

MeetingUsersList.propTypes = {
  users: PropTypes.array.isRequired,
  allUsers: PropTypes.array.isRequired,
  editing: PropTypes.bool.isRequired,
  idxGroup: PropTypes.number,
  idGroup: PropTypes.number,
  isGroup: PropTypes.bool,
  groupLimit: PropTypes.number,
};

export default React.memo(MeetingUsersList);
