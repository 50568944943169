import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import { clearErrors } from '../../../store/actions/authActions';
import isEmpty from '../../../utils/isEmpty';

const ErrorWarning = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { response, message } = useSelector(state => state.errors);

  useEffect(() => {
    if (!response && !isEmpty(message) && message !== 'Server Error') {
      enqueueSnackbar(message, { variant: 'error', autoHideDuration: 4500 });
      dispatch(clearErrors());
    }
  }, [response, dispatch, enqueueSnackbar, message]);

  return null;
};

export default React.memo(ErrorWarning);
