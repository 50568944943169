import React, { useContext, useRef, useCallback } from 'react';
import { makeStyles, Grid, Box, Fab } from '@material-ui/core';
import AvatarLogoLetters from '../common/layout/AvatarLogoLetters';
import { Edit, PhotoCamera } from '@material-ui/icons';
import {
  PersonalProfileStateContext,
  PersonalProfileFuncsContext,
} from './PersonalProfileProvider';
import ProfileFieldsInfo from './ProfileFieldsInfo';
import ProfileFieldsInfoEditable from './ProfileFieldsInfoEditable';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  icon: {
    fill: theme.palette.neutrals[5],
    '&:hover': {
      cursor: 'pointer',
      fill: theme.palette.neutrals[6],
    },
  },
  labelCamera: {
    marginLeft: '-40px',
    display: 'flex',
  },
  marginContainer: {
    marginTop: '54px',
  },
  avatar: {
    width: '120px',
    height: '120px',
    margin: 'auto',
    backgroundColor: theme.palette.primary[600],
    color: theme.palette.neutrals[1],
  },
  itemEdit: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  iconChangeAvatar: {
    display: 'flex',
  },
  editItem: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  input: {
    display: 'none',
  },
}));

const ProfileInfo = () => {
  const classes = useStyles();
  const dispatchRedux = useDispatch();
  const inputRef = useRef(null);
  const {
    state: {
      editing,
      newImage,
      user: { name, image },
    },
  } = useContext(PersonalProfileStateContext);
  const { updateField, setImage } = useContext(PersonalProfileFuncsContext);

  const handleUpload = useCallback(
    e => {
      e.preventDefault();

      // @ tamanho do ficheiro superior a 1 MB
      if (e.target.files[0] && inputRef.current.files[0].size < 1000000) {
        setImage(URL.createObjectURL(e.target.files[0]), inputRef.current.files);
      } else if (e.target.files[0]) {
        dispatchRedux({
          type: 'SHOW_SNACK',
          payload: {
            variant: 'error',
            message: 'Ficheiro superior a 1 MB.',
          },
        });
        setImage('', null);
      } else {
        setImage('', null);
      }
    },
    [dispatchRedux, setImage]
  );

  return (
    <>
      <Grid container spacing={1} justify="center" className={classes.marginContainer}>
        <Grid item xs={4} />
        <Grid item xs={4} className={classes.editItem}>
          <AvatarLogoLetters name={name} logo={newImage || image} className={classes.avatar} />
          {editing && (
            <>
              <input
                accept="image/*"
                className={classes.input}
                ref={inputRef}
                onChange={handleUpload}
                id="icon-button-file"
                type="file"
              />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="icon-button-file" className={classes.labelCamera}>
                <Fab size="small" color="primary" component="span">
                  <PhotoCamera />
                </Fab>
              </label>
            </>
          )}
        </Grid>
        <Grid item xs={3} className={classes.itemEdit}>
          {!editing && (
            <Edit
              className={classes.icon}
              onClick={e => {
                e.preventDefault();
                updateField('editing', !editing);
              }}
            />
          )}
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Box height="20px" />
      <Grid container spacing={1} justify="center">
        <Grid item xs={10}>
          {!editing && <ProfileFieldsInfo />}
          {editing && <ProfileFieldsInfoEditable />}
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(ProfileInfo);
