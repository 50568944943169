import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import H6 from '../../../../common/typographys/H6';
import getMeetingPointStatusLabel from '../../../../../utils/getMeetingPointStatusLabel';
import { CheckRounded, CloseRounded, Schedule, FiberManualRecord } from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  mainBox: {
    marginTop: '10px',
    backgroundColor: theme.palette.primary[50],
    padding: '16px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  commonIcon: {
    color: theme.palette.neutrals[1],
    width: '30px',
    height: '30px',
    marginLeft: '13px',
    borderRadius: '50%',
  },
  againsIcon: {
    backgroundColor: theme.palette.error.main,
  },
  favorIcon: {
    backgroundColor: theme.palette.colorsPalette.TrueGreen,
  },
  otherIcon: {
    backgroundColor: theme.palette.colorsPalette.Orange,
  },
  removeIcon: {
    width: '30px',
    height: '30px',
    marginLeft: '13px',
    color: theme.palette.colorsPalette.Orange,
  },
  noMargin: {
    marginTop: '0px!important',
  },
}));

const IconStatus = ({ status }) => {
  const classes = useStyles();

  if (status === 1) {
    return <CheckRounded className={clsx(classes.commonIcon, classes.favorIcon)} />;
  }

  if (status === 2) {
    return <CloseRounded className={clsx(classes.commonIcon, classes.againsIcon)} />;
  }

  if (status === 4) {
    return <Schedule className={clsx(classes.commonIcon, classes.otherIcon)} />;
  }

  return <FiberManualRecord className={classes.removeIcon} />;
};

IconStatus.propTypes = {
  status: PropTypes.number.isRequired,
};

const ProposalStatusBox = ({ status, noMargin }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.mainBox, { [classes.noMargin]: noMargin })}>
      <H6>Proposta {getMeetingPointStatusLabel(status).toLowerCase()}</H6>
      <IconStatus status={status} />
    </Box>
  );
};

ProposalStatusBox.defaultProps = {
  noMargin: false,
};

ProposalStatusBox.propTypes = {
  status: PropTypes.number.isRequired,
  noMargin: PropTypes.bool,
};

export default React.memo(ProposalStatusBox);
