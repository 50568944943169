import React, { useContext } from 'react';
import { EntityStateContext, EntityDispatchContext } from './CreateEditEntityProvider';
import FormInfoEntity from './FormInfoEntity';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import UpdateItem from '../../pieces/UpdateItem';

const EditEntity = () => {
  const {
    state: { errors, items },
    handleSubmit,
    loading,
  } = useContext(EntityStateContext);
  const dispatch = useContext(EntityDispatchContext);

  return (
    <UpdateItem disableSubmit={checkEmptyObject(errors).length > 0} loading={loading} items={items} dispatch={dispatch} handleSubmit={handleSubmit}>
      <FormInfoEntity />
    </UpdateItem>
  );
};

export default EditEntity;
