import React from 'react';
import { makeStyles, Grid, Box } from '@material-ui/core';
import Body1 from '../../common/typographys/Body1';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { fade } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  box: {
    height: '60px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.neutrals[1],
    boxShadow: `0 10px 20px -10px ${fade(theme.palette.primary[100], 0.7)}`,
    padding: '6px 20px',
    transition: 'background-color 0.2s ease',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: fade(theme.palette.primary[100], 0.7),
      '& p': {
        color: `${fade(theme.palette.neutrals[6], 0.7)}!important`,
      },
    },
  },
  selected: {
    backgroundColor: theme.palette.primary[600],
  },
}));

const BoxConfig = ({ selected, label, onClick, xs, sm, mb, lg, xl }) => {
  const classes = useStyles();

  return (
    <Grid item xs={xs} sm={sm} mb={mb} lg={lg} xl={xl}>
      <Box className={clsx(classes.box, { [classes.selected]: selected })} onClick={onClick}>
        <Body1 selected={selected} secondary={!selected} center>
          {label}
        </Body1>
      </Box>
    </Grid>
  );
};

BoxConfig.defaultProps = {
  xs: null,
  sm: null,
  mb: null,
  lg: null,
  xl: null,
};

BoxConfig.propTypes = {
  selected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  xs: PropTypes.number,
  sm: PropTypes.number,
  mb: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
};

export default BoxConfig;
