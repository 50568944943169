import React from 'react';
import Select from 'react-select';
import { emphasize, makeStyles, useTheme } from '@material-ui/core/styles';
import { FormHelperText } from '@material-ui/core';
import Control from './materialSelects/Control';
import Menu from './materialSelects/Menu';
import MultiValue from './materialSelects/MultiValue';
import NoOptionsMessage from './materialSelects/NoOptionsMessage';
import Option from './materialSelects/Option';
import Placeholder from './materialSelects/Placeholder';
import SingleValue from './materialSelects/SingleValue';
import ValueContainer from './materialSelects/ValueContainer';
import PropTypes from 'prop-types';
import isEmpty from '../../../utils/isEmpty';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: '0px 8px',
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700], 0.08),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 11,
  },
  paper: {
    position: 'relative',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
}));

const defaultComponents = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const MultiInputSelect = ({ options, value, handleChange, placeholder, noOptionsMessage, label, helperText, ...props }) => {
  const classes = useStyles();
  const theme = useTheme();

  const selectStyles = {
    input: () => ({
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: '0px 8px',
    }),
  };

  return (
    <>
      <Select
        classes={classes}
        styles={selectStyles}
        placeholder={placeholder}
        noOptionsMessage={() => {
          return noOptionsMessage;
        }}
        TextFieldProps={{
          label,
          InputLabelProps: {
            shrink: !isEmpty(label),
          },
          placeholder,
          variant: 'outlined',
        }}
        options={options}
        components={{ ...defaultComponents, ...props.otherComponents }}
        value={value}
        onChange={handleChange}
        isMulti
        menuPortalTarget={document.querySelector('body')}
        label={label}
        {...props}
      />
      {helperText && (
        // eslint-disable-next-line react/prop-types
        <FormHelperText error={!isEmpty(props.error)} className={classes.helperText}>
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};

MultiInputSelect.defaultProps = {
  options: [],
  value: null,
  placeholder: 'Selecione uma opção',
  noOptionsMessage: 'Nenhuma opção disponível',
  helperText: null,
  label: null,
  otherComponents: {},
};

MultiInputSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.array,
  placeholder: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  helperText: PropTypes.string,
  otherComponents: PropTypes.object,
};

export default MultiInputSelect;
