import { GET_THEMES, UPDATE_THEME, THEMES_LOADING, THEMES_NOT_LOADING, CREATE_THEME, REMOVE_THEME } from '../actions/types';

const initialState = {
  themes: [],
  theme: {},
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_THEMES:
      return {
        ...state,
        themes: action.payload,
        loading: false,
      };
    case CREATE_THEME:
      return {
        ...state,
        themes: [action.payload, ...state.themes],
        loading: false,
      };
    case UPDATE_THEME:
      return {
        ...state,
        themes: state.themes.map(x => (x.id === action.payload.id ? action.payload : x)),
        loading: false,
      };
    case REMOVE_THEME:
      return {
        ...state,
        themes: state.themes.filter(x => x.id !== action.payload.id),
        loading: false,
      };
    case THEMES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case THEMES_NOT_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
