import { format, parseISO, isValid } from 'date-fns';
import { pt } from 'date-fns/locale';

/**
 *
 * @param {string} date - Data a formatar
 * @param {string} [style=dd/MM/yyyy] - Estilo de formatação
 * @returns Formata data recebida em tipo string para estilo passado como argumento
 */
const formatDate = (date, style = 'dd/MM/yyyy') => {
  // eslint-disable-next-line no-nested-ternary
  return isValid(parseISO(date)) ? format(parseISO(date), style, { locale: pt }) : isValid(date) ? format(date, style, { locale: pt }) : 'N/D';
};

export default formatDate;
