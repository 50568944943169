import React, { useEffect, useContext, useCallback } from 'react';
import SeeItem from '../../pieces/SeeItem';
import { useSelector, useDispatch } from 'react-redux';
import { getMatrix } from '../../../../store/actions/matricesActions';
import PropTypes from 'prop-types';
import LabelAndValue from '../../../common/forms/LabelAndValue';
import { MatrixDispatchContext, MatrixFuncsContext } from './CreateEditMatrixProvider';
import EditMatrix from './EditMatrix';
import MatrixMember from './pieces/MatrixMember';
import { ConfigStateContext, ConfigDispatchContext } from '../../MenuConfigProvider';

const SeeEditMatrix = ({ info }) => {
  const { groups } = useSelector(state => state.matrices.matrix);
  const dispatchRedux = useDispatch();
  const dispatchConfig = useContext(ConfigDispatchContext);
  const dispatch = useContext(MatrixDispatchContext);
  const { getTimeValue } = useContext(MatrixFuncsContext);
  const { editing } = useContext(ConfigStateContext);

  useEffect(() => {
    if (info.isItemMatrix) {
      dispatchRedux(getMatrix(info.id));
    }
    return () => {
      dispatchRedux({
        type: 'GET_MATRIX',
        payload: { groups: [] },
      });
    };
  }, [dispatchRedux, info]);

  const updateField = useCallback(
    (name, value) => {
      dispatch({
        type: 'UPDATE_FIELD',
        payload: {
          name,
          value,
        },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    updateField('name', info.name);
  }, [info.name, updateField]);

  useEffect(() => {
    updateField('id', info.id);
  }, [info.id, updateField]);

  useEffect(() => {
    updateField('time', getTimeValue(info.time));
  }, [info.time, getTimeValue, updateField]);

  useEffect(() => {
    updateField('type', info.is_group ? 'grupo' : 'individual');
  }, [info.is_group, updateField]);

  useEffect(() => {
    dispatch({
      type: 'SET_MEMBERS',
      payload: {
        type: info.is_group ? 'groups' : 'individuals',
        value: groups,
      },
    });
  }, [groups, info.is_group, dispatch]);

  const startEdit = useCallback(
    e => {
      e.preventDefault();
      dispatchConfig({
        type: 'SET_EDITING',
      });
    },
    [dispatchConfig]
  );

  return editing ? (
    <EditMatrix />
  ) : (
    <SeeItem submitEdit={startEdit}>
      <LabelAndValue label="Nome" value={info.name} />
      <LabelAndValue label="Tempo máximo" value={getTimeValue(info.time)} />
      {groups.map(user => (
        <MatrixMember key={user.id} name={user.name} time={getTimeValue(user.time)} />
      ))}
    </SeeItem>
  );
};

SeeEditMatrix.propTypes = {
  info: PropTypes.object.isRequired,
};

export default React.memo(SeeEditMatrix);
