import React, { useMemo } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import H6 from '../../../common/typographys/H6';
import H2 from '../../../common/typographys/H2';
import { ReactComponent as Against } from '../../../../assets/images/againstPlateiaBig.svg';
import { ReactComponent as Favor } from '../../../../assets/images/checkPlateiaBig.svg';
import { ReactComponent as Abstention } from '../../../../assets/images/abstentionPlateiaBig.svg';

const useStyles = makeStyles(theme => ({
  mainBox: {
    display: 'flex',
    padding: '30px 10px',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: '236px',
    width: '100%',
    justifyContent: 'space-between',
  },
  borderFavor: {
    border: `solid 1px ${theme.palette.colorsPalette.TrueGreen}`,
  },
  borderAgainst: {
    border: `solid 1px ${theme.palette.colorsPalette.RustRed}`,
  },
  borderAbstention: {
    border: `solid 1px ${theme.palette.colorsPalette.Orange}`,
  },
}));

const VoteBlock = ({ favor, against, abstention, total }) => {
  const classes = useStyles();
  const label = useMemo(() => {
    if (favor) {
      return 'A favor';
    }

    if (against) {
      return 'Contra';
    }

    return 'Abstinências';
  }, [favor, against]);

  const icon = useMemo(() => {
    if (favor) {
      return <Favor />;
    }

    if (against) {
      return <Against />;
    }

    return <Abstention />;
  }, [favor, against]);

  return (
    <Box
      className={clsx(classes.mainBox, {
        [classes.borderFavor]: favor,
        [classes.borderAgainst]: against,
        [classes.borderAbstention]: abstention,
      })}
    >
      <H6 tertiary>{label}</H6>
      <H2>{total}</H2>
      {icon}
    </Box>
  );
};

VoteBlock.defaultProps = {
  favor: false,
  against: false,
  abstention: false,
  total: 0,
};

VoteBlock.propTypes = {
  favor: PropTypes.bool,
  against: PropTypes.bool,
  abstention: PropTypes.bool,
  total: PropTypes.number,
};

export default React.memo(VoteBlock);
