import React, { useContext, useState, useMemo } from 'react';
import { makeStyles, Box, Input } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ListPointsCheckbox from '../../pieces/ListPointsCheckbox';
import CheckboxPicker from '../../../common/forms/CheckboxPicker';
import { MeetingDispatchContext } from '../../CreateEditMeetingsProvider';
import CardBlock from '../../../common/forms/CardBlock';
import isEmpty from '../../../../utils/isEmpty';
import getDateObj from '../../../../utils/getDateObj';
import subMonths from 'date-fns/subMonths';
import isAfter from 'date-fns/isAfter';
import startOfDay from 'date-fns/startOfDay';
import isBefore from 'date-fns/isBefore';
import endOfDay from 'date-fns/endOfDay';
import BoxDateFilter from '../../../proposals/pieces/BoxDateFilter';
import formatDate from '../../../../utils/formatDate';
import usePagination from '../../../../utils/hooks/usePagination';
import Pagination from '../../../common/SimplePagination';

const useStyles = makeStyles(theme => ({
  mainBox: {
    backgroundColor: theme.palette.neutrals[2],
    padding: '8px 10px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  marginBottom: {
    marginBottom: '12px',
  },
  smallMarginBottom: {
    marginBottom: '8px',
  },
  content: {
    padding: '0px 10px',
  },
  divider: {
    margin: '8px 0px',
  },
  inputField: {
    marginBottom: '10px',
  },
}));

const stringContains = (first = '', second = '') => {
  return first.toLowerCase().indexOf(second.toLowerCase());
};

const MeetingTypologyPool = ({ state }) => {
  const classes = useStyles();
  const dispatch = useContext(MeetingDispatchContext);
  const {
    pool: { points, selected, pointsSelected, total },
  } = state;
  const [search, setSearch] = useState('');
  const [date, setDate] = useState(subMonths(new Date(), 12));
  const [endDate, setEndDate] = useState(new Date());

  const toggleAll = () => {
    dispatch({
      type: 'SELECT_ALL_POOL_POINTS',
    });
  };

  const togglePoint = id => e => {
    e.preventDefault();
    dispatch({
      type: 'SELECT_POOL_POINT',
      payload: { id },
    });
  };

  const availablePoints = useMemo(() => {
    if (!isEmpty(search)) {
      return points
        .filter(
          x =>
            stringContains(x.name, search) !== -1 ||
            stringContains(x.identifier_code, search) !== -1
        )
        .filter(
          point =>
            isAfter(getDateObj(point.date), startOfDay(date)) &&
            isBefore(getDateObj(point.date), endOfDay(endDate))
        );
    }

    return points.filter(
      point =>
        isAfter(getDateObj(point.date), startOfDay(date)) &&
        isBefore(getDateObj(point.date), endOfDay(endDate))
    );
  }, [search, points, date, endDate]);

  const [showPagination, itemShown, page, setPage] = usePagination(10, availablePoints);

  return (
    <CardBlock label="Propostas da tipologia">
      <Box display="flex" justifyContent="space-between">
        <Input
          className={classes.inputField}
          placeholder="Filtrar propostas"
          onChange={e => setSearch(e.target.value)}
          value={search}
        />
        <BoxDateFilter
          hideFilter
          endDate={endDate}
          date={date}
          setDate={setDate}
          setEndDate={setEndDate}
          justify="flex-end"
          marginLeft
        />
      </Box>
      <Box className={classes.marginBottom}>
        <Box className={clsx(classes.mainBox, classes.smallMarginBottom)}>
          <CheckboxPicker
            label="Todas"
            onClick={toggleAll}
            checked={selected}
            indeterminate={pointsSelected > 0}
            allCheck={pointsSelected === total}
            disabled={availablePoints.length === 0}
          />
        </Box>
        <Box className={classes.content}>
          {itemShown.map((point, idxPoint) => (
            <ListPointsCheckbox
              key={`${point.id || '__id__'}_${point.name}`}
              label={
                point.identifier_code ? `${point.identifier_code} - ${point.name}` : point.name
              }
              secondLabel={point.date ? formatDate(point.date) : null}
              onClick={togglePoint(point.id)}
              checked={point.selected}
              divider={idxPoint < itemShown.length - 1}
            />
          ))}
        </Box>
      </Box>
      {showPagination && (
        <Pagination
          total={availablePoints.length}
          onChangePage={(_, pageNum) => {
            setPage(pageNum);
          }}
          rowsPerPage={10}
          page={page}
        />
      )}
    </CardBlock>
  );
};

MeetingTypologyPool.defaultProps = {
  state: {},
};

MeetingTypologyPool.propTypes = {
  state: PropTypes.object,
};

export default MeetingTypologyPool;
