import React, { useContext, useEffect } from 'react';
import AddNewItem from '../../pieces/AddNewItem';
import checkEmptyObject from '../../../../utils/checkEmptyObject';
import { ProfileDispatchContext, ProfileStateContext } from './CreateEditProfileProvider';
import FormInfoProfile from './FormInfoProfile';

const AddNewProfile = () => {
  const {
    state: { errors, items },
    handleSubmit,
    loading,
  } = useContext(ProfileStateContext);
  const dispatch = useContext(ProfileDispatchContext);

  useEffect(() => {
    dispatch({
      type: 'CLEAR_STATE',
    });
  }, [dispatch]);

  return (
    <AddNewItem
      disableSubmit={checkEmptyObject(errors).length > 0}
      items={items}
      loading={loading}
      dispatch={dispatch}
      handleSubmit={handleSubmit}
      msgSubmit="Tem a certeza que deseja gravar o novo perfil?"
    >
      <FormInfoProfile />
    </AddNewItem>
  );
};

export default React.memo(AddNewProfile);
