import { formatRelative, parseISO, isValid, startOfToday } from 'date-fns';
import { pt } from 'date-fns/locale';

/**
 *
 * @param {string} date - Data a formatar
 * @param {string} [style=dd/MM/yyyy] - Estilo de formatação
 * @returns Formata data recebida em tipo string para estilo passado como argumento
 */
const formatDateRelative = date => {
  return isValid(parseISO(date))
    ? formatRelative(parseISO(date), startOfToday(), { locale: pt })
    : isValid(date)
    ? formatRelative(date, startOfToday(), { locale: pt })
    : 'N/D';
};

export default formatDateRelative;
