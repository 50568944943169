import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import useCommonStyles from './useCommonStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.body1,
  },
  secondary: {
    color: fade(theme.palette.neutrals[6], 0.7),
  },
  hoverable: {
    transition: 'color 0.1s ease',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.colorsPalette.BrownOrange,
    },
  },
  hover: {
    color: theme.palette.colorsPalette.BrownOrange,
  },
}));

const Body1 = ({
  children,
  center,
  right,
  approved,
  disapproved,
  standby,
  hoverable,
  secondary,
  selected,
  hover,
  oneLine,
  className,
  disabled,
  printMultiline,
  error,
  justify,
  underline,
  bold,
  ...props
}) => {
  const classes = useStyles();
  const common = useCommonStyles();

  return (
    <Typography
      variant="body1"
      classes={{ root: classes.root }}
      className={clsx({
        [common.center]: center,
        [common.right]: right,
        [common.approved]: approved,
        [common.disapproved]: disapproved,
        [common.standby]: standby,
        [classes.hoverable]: hoverable,
        [classes.secondary]: secondary,
        [common.white]: selected,
        [classes.hover]: hover,
        [common.wrapEllipsis]: oneLine,
        [common.disabled]: disabled,
        [common.printMultiline]: printMultiline,
        [common.error]: error,
        [common.justify]: justify,
        [common.underline]: underline,
        [common.bold]: bold,
        [className]: className.length > 0,
      })}
      {...props}
    >
      {children}
    </Typography>
  );
};

Body1.defaultProps = {
  center: false,
  right: false,
  approved: false,
  disapproved: false,
  standby: false,
  hoverable: false,
  secondary: false,
  selected: false,
  hover: false,
  oneLine: false,
  disabled: false,
  printMultiline: false,
  error: false,
  justify: false,
  underline: false,
  bold: false,
  className: '',
};

Body1.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]).isRequired,
  center: PropTypes.bool,
  right: PropTypes.bool,
  approved: PropTypes.bool,
  disapproved: PropTypes.bool,
  standby: PropTypes.bool,
  hoverable: PropTypes.bool,
  secondary: PropTypes.bool,
  selected: PropTypes.bool,
  hover: PropTypes.bool,
  oneLine: PropTypes.bool,
  disabled: PropTypes.bool,
  printMultiline: PropTypes.bool,
  error: PropTypes.bool,
  justify: PropTypes.bool,
  underline: PropTypes.bool,
  bold: PropTypes.bool,
  className: PropTypes.string,
};

export default Body1;
