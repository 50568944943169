import React from 'react';
import { makeStyles, Dialog, DialogContent, Box, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import H6 from '../../../../common/typographys/H6';

const useStyles = makeStyles(theme => ({
  content: {
    padding: '36px 65px!important',
    justifyContent: 'center',
  },
  boxButtons: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  commonButton: {
    minWidth: '200px',
    maxWidth: '200px',
    minHeight: '44px',
    maxHeight: '44px',
  },
  publicVote: {
    backgroundColor: theme.palette.primary.A700,
    '&:hover': {
      backgroundColor: '#A95700',
    },
  },
  secretVote: {
    backgroundColor: theme.palette.primary.A400,
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: '#B19419',
    },
  },
}));

const DialogVoteType = ({ open, voteType, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent className={classes.content}>
        <H6 center>Que tipo de votação é esta?</H6>
        <Box className={classes.boxButtons}>
          <Button className={clsx(classes.commonButton, classes.publicVote)} onClick={voteType('public')}>
            Pública
          </Button>
          <Button className={clsx(classes.commonButton, classes.secretVote)} onClick={voteType('secret')}>
            Secreta
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

DialogVoteType.propTypes = {
  open: PropTypes.bool.isRequired,
  voteType: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default React.memo(DialogVoteType);
