import React, { useContext, useEffect, useCallback } from 'react';
import ConfigList from '../../pieces/ConfigList';
import InfoSection from '../../pieces/InfoSection';
import isEmpty from '../../../../utils/isEmpty';
import { ConfigStateContext, ConfigDispatchContext } from '../../MenuConfigProvider';
import { useDispatch, useSelector } from 'react-redux';
import { getLocals, removeLocal } from '../../../../store/actions/localActions';
import CreateEditLocalProvider from './CreateEditLocalProvider';
import AddNewLocal from './AddNewLocal';
import SeeEditLocal from './SeeEditLocal';

const Locals = () => {
  const { locals = [] } = useSelector(state => state.locals);
  const { permissions } = useSelector(state => state.auth.user);
  const { activeItem, isAddNew, deleting } = useContext(ConfigStateContext);
  const dispatchConfig = useContext(ConfigDispatchContext);
  const dispatchRedux = useDispatch();

  useEffect(() => {
    dispatchRedux(getLocals());
  }, [dispatchRedux]);

  const handleDelete = useCallback(
    e => {
      e.preventDefault();
      dispatchRedux(
        removeLocal(activeItem.id, () =>
          dispatchConfig({
            type: 'CANCEL_ADD_NEW',
          })
        )
      );
    },
    [dispatchRedux, activeItem, dispatchConfig]
  );

  return (
    <>
      <ConfigList
        items={locals}
        pageObj={{ isItemLocal: true }}
        labelButton="Novo local"
        noItemMessage="Nenhum local adicionado."
        msgDelete={`Tem a certeza que deseja excluir o local${
          activeItem && activeItem.name ? `, ${activeItem.name}` : ''
        }?`}
        labelSubmit="Excluir"
        canDelete={Boolean(permissions && permissions.others && permissions.others.locals)}
        canAddNew={permissions && permissions.others && permissions.others.locals}
        handleDelete={handleDelete}
      />
      <InfoSection hasPagination={locals.length > 10}>
        <CreateEditLocalProvider items={locals}>
          {!isEmpty(activeItem) && !deleting && <SeeEditLocal info={activeItem} />}
          {isAddNew && <AddNewLocal />}
        </CreateEditLocalProvider>
      </InfoSection>
    </>
  );
};

export default Locals;
