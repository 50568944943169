import React, { useCallback, useMemo } from 'react';
import { IconButton, Badge, Paper, List, Box, Button } from '@material-ui/core';
import CustomMenu from './CustomMenu';
import { NotificationsOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import NotificationEntry from './NotificationEntry';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { clickUserNotification } from '../../../store/actions/notificationsActions';
import { useHistory } from 'react-router-dom';

const NotificationBell = ({ classes, handleClick, handleClose, notificationsEl }) => {
  const { notifications } = useSelector(state => state.notifications);
  const dispatch = useDispatch();
  const history = useHistory();
  const totalUnread = useMemo(
    () => notifications && notifications.filter(x => x.status === 'unread').length,
    [notifications]
  );

  const clickNotification = useCallback(
    (url, id) => e => {
      e.preventDefault();
      handleClose();
      dispatch(clickUserNotification(id));
      if (url) {
        history.push(url);
      }
    },
    [handleClose, dispatch, history]
  );

  const seeAll = useCallback(
    e => {
      e.preventDefault();
      handleClose();
      history.push('/notificacoes');
    },
    [handleClose, history]
  );

  return (
    <>
      <IconButton
        aria-label={`Mostrar ${notifications.length} notificações`}
        color="inherit"
        className={clsx(classes.button, {
          [classes.noMarginTop]: totalUnread === 0,
        })}
        onClick={handleClick}
      >
        <Badge className={classes.badge} badgeContent={totalUnread} color="secondary">
          <NotificationsOutlined />
        </Badge>
      </IconButton>
      <CustomMenu
        anchorEl={notificationsEl}
        onClose={handleClose}
        menuListProps={{ style: { width: '350px', maxWidth: '100%' } }}
      >
        <Paper className={classes.notificationsBoxPaper} elevation={0}>
          <List disablePadding>
            {notifications.length === 0 ? (
              <NotificationEntry
                text="Não existem notificações."
                classes={classes}
                onClick={clickNotification}
              />
            ) : (
              notifications.map(item => (
                <NotificationEntry
                  key={item.id}
                  {...item}
                  classes={classes}
                  onClick={clickNotification}
                />
              ))
            )}
          </List>
        </Paper>
        {notifications.length > 5 && (
          <Paper className={classes.seeAll} elevation={0}>
            <Box className={classes.boxSeeAll}>
              <Button variant="text" color="primary" onClick={seeAll}>
                Ver tudo
              </Button>
            </Box>
          </Paper>
        )}
      </CustomMenu>
    </>
  );
};

NotificationBell.defaultProps = {
  notificationsEl: null,
};

NotificationBell.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  notificationsEl: PropTypes.object,
};

export default NotificationBell;
