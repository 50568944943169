import React from 'react';
import PropTypes from 'prop-types';
import Body1 from '../../typographys/Body1';

const SingleValue = props => {
  return (
    <Body1
      className={props.selectProps.classes.singleValue}
      secondary={props.selectProps.menuIsOpen}
      variant={props.selectProps.variant || 'body1'}
      {...props.innerProps}
    >
      {props.children}
    </Body1>
  );
};

SingleValue.defaultProps = {
  children: null,
  innerProps: null,
};

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

export default SingleValue;
